<section class="content-header" style="padding: 5px !important;">
    <div class="container-fluid">
      <div class="row mb2">
        <div class="col-md-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/admin/']"><i class="fas fa-home"></i>Trang chủ</a>
            </li>
            <li class="breadcrumb-item "><a [routerLink]="['/room-charge']">Quy định khoản nộp</a></li>
            <li class="breadcrumb-item active">Thêm mới quy định khoản nộp</li>
          </ol>
        </div>
        <div class="col-md-6">
           <button class="btn btn-success btn-sm"  style="float: right;">Lưu lại</button>
          </div>
        
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <section class="content-header" style="padding: 5px !important;">
    <div class="container-fluid">
      <div class="row mb2">
        <div class="col-md-6">
            <a [routerLink]="['/admin/room-charge']"> <button class="btn btn-danger btn-sm"><i class="fas fa-long-arrow-alt-left"></i></button></a>
        </div>
      </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <form class="form-horizontal" [formGroup]="Insert">
        <div class="row">
            <div class="col-md-3">
                <label class="col-md-10 require">Hình thức thu</label>
                <select class="form-control"  formControlName="Hinh_thuc_thu" (change)="changeHinh_thuc()">
                  <option value="true">Theo tháng</option>
                  <option value="false">Theo học kỳ</option>
                </select>
              </div>
            
           
              <div class="col-md-3" *ngIf="Hinh_thuc_thu">
                <label class="col-md-10 require">Tháng</label>
                <select class="form-control"  formControlName="Thang">
                  <option *ngFor="let p of MonthOption;" value="{{p.value}}">{{p.name}}</option>
                </select>
               
              </div>
              <div class="col-md-3" *ngIf="Hinh_thuc_thu">
                <label class="col-md-10 require">Năm</label>
                <select class="form-control" formControlName="Nam">
                  <option>Chọn năm</option>
                </select>
              </div>
         
          
              <div class="col-md-3" *ngIf="!Hinh_thuc_thu">
                <label class="col-md-10 require">Học kỳ</label>
                <select class="form-control" formControlName="Hoc_ky">
                  <option value="1">Học kỳ I</option>
                  <option value="2">Học kỳ II</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="!Hinh_thuc_thu">
                <label class="col-md-10 require">Năm học</label>
                <select class="form-control" formControlName="Nam_hoc">
                  <option>Chọn năm học...</option>
                </select>
              </div>
              <div class="col-md-3">
                <label class="col-md-10 require">Loại khoản nộp</label>
                <select class="form-control" formControlName="ID_tham_so">
                  <option value="ID_khoan_thu_so_dien">Tiền điện</option>
                  <option value="ID_khoan_thu_so_nuoc">Tiền nước</option>
                  <option value="ID_khoan_thu_tien_phong" selected>Tiền phòng</option>
                </select>
              </div>
    
              <div class="col-md-3">
                <label class="col-md-10 require">Số tiền</label>
                <input type="number"  class="form-control" formControlName="So_tien" (change)="changeSoTien()" placeholder="Nhập số tiền...">
              </div>
              <div class="col-md-3">
                <label class="col-md-10 ">Phần trăm miễn giảm</label>
                <input type="number"  class="form-control" formControlName="Phan_tram_mien_giam" (change)="changeSoTien()"  placeholder="Nhập phần trăm miễn giảm...">
              </div>
              <div class="col-md-3">
                <label class="col-md-10 ">Số tiền miễn giảm</label>
                <input type="number"  class="form-control" formControlName="So_tien_mien_giam" (change)="changeSoTien()" placeholder="Nhập số tiền miễn giảm...">
              </div>
              <div class="col-md-3">
                <label class="col-md-10 require">Thành tiền</label>
                <input type="number" class="form-control" formControlName="Thanh_tien"  disabled>
              </div>
              <div class="col-md-3">
                <label class="col-md-10 ">Nội dung</label>
                <textarea type="text" class="form-control" cols="4" formControlName="Noi_dung" placeholder="Nhập nội dung..."></textarea>
              </div>
        </div>
        </form>
      </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <label>Cở sở</label>
            <select class="form-control">
              <option value="0">Tìm kiếm theo cơ sở</option>
            </select>
          </div>
          <div class="col-sm-3">
            <label>Tòa nhà</label>
            <select class="form-control">
              <option value="0">Tìm kiếm theo tòa nhà</option>
            </select>
          </div>
          <div class="col-sm-3">
            <label>Phòng</label>
            <select class="form-control">
              <option value="0">Tìm kiếm theo phòng</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane">
            <div class="row">
              <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer">
                  <thead>
                    <tr>
                      <th><input type="checkbox"></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable;">
                      <th><input type="checkbox"></th>
                      <td>{{p.ID_sv}}</td>
                      <td>{{ p.Ma_sinh_vien }}</td>
                      <td>{{ p.Ho_ten }}</td>
                      <td>{{ p.Ngay_sinh }}</td>
                      <td>{{ p.Que_quan }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  


import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ListpeopledormService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DormList/ListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getDangKyQuaTrinhLast(ID_dang_ky, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'DormList/ListLastQuaTrinh?ID_dang_ky=' +
          ID_dang_ky,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListDes(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DormList/DormDesLoadList', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  } // lấy dánh sách ra khỏi KTX

  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Building/BuildingAdd', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  UpdateLeadRoom(ID_dky_new, ID_phong, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
          'DormList/LeadRoomUpdate?ID_dky_new=' +
          ID_dky_new +
          '&&ID_phong=' +
          ID_phong,
        0,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DormList/DormListUpdate', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  UpdateSV(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DormList/SinhVienUpdate', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  UpdateRaKhoiKTX(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'DormList/DormListRaKhoiKTX',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  UpdateQuayLaiKTX(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'DormList/DormListQuayLaiKTX',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  UpdateRoom(res: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSort/ChangeRoom', res, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListReceipt(dt, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'DormList/DormListDetail?ID_Dang_Ky=' + dt,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  ChoRaKTX(data: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
          'StudentSort/GetOut?Ngay_cho_ra=' +
          data.Ngay_cho_ra +
          '&Ly_do=' +
          data.Ly_do +
          '&Ra_truoc_han=' +
          data.Ra_truoc_han +
          '&Danh_sach_den=' +
          data.Danh_sach_den,
        data.lstID_dang_ky,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  ExportDanhSachO(data: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'DormList/ExportExcelListDorm',
        data,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getDormList(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DormList/List', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DormList/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DormList/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getDanhSachRa(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DormList/DanhSachRa', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getDanhSachRaCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DormList/DanhSachRa_Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getDanhSachRaCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'DormList/DanhSachRa_CountFilter',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  GiaHanNgayRa(req, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'DormList/GiaHanNgayRa?ID=' +
          req.ID +
          '&Ngay_ket_thuc=' +
          req.Ngay_ket_thuc +
          '&So_thang_o=' +
          req.So_thang_o,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiGiaHanNgayRa(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
          'DormList/GiaHanNgayRaMulti?Ngay_ket_thuc=' +
          req.Ngay_ket_thuc,
        req.listSinhVien,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Sendmail(data: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSort/SendMail', data, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  ExportDanhSachRaSinhVien(token, req): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'DormList/ExportDanhSachRaSinhVien',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-diem-sinh-vien',
  templateUrl: './diem-sinh-vien.component.html',
  styleUrls: ['./diem-sinh-vien.component.scss']
})
export class DiemSinhVienComponent extends BaseComponent implements OnInit {
  Ma_ho_ten: string = ''
  Ngay_sinh: any = ''
  Toa_nha_phong: any = ''
  Tu_diem: any = ''
  Den_diem: any = ''
  Hoc_ky: any = 1
  Nam_hoc: any
  Nam_hoc_arr = []
  dataViPham: any = []
  Data:any
  ngOnInit(): void {
 
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.Nam_hoc = new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
    this.getToken()
    this.getCount()
    this.getList()
  }
  getCount() {
    this.spinner.show()
    this.DiemSinhVienService.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
      this.spinner.hide()
    });
  }
  getList() {
    if(this.Tu_diem != '' && this.Den_diem === ''||this.Tu_diem === '' && this.Den_diem != '' ){
      this.toastr.warning("Bạn phải nhập từ điểm, đến điểm")
      return false  
    }
    if((this.Tu_diem >= this.Den_diem) &&this.Tu_diem != '' && this.Den_diem != '' ){
      this.toastr.warning("Từ điểm phải nhỏ hơn đến điểm")
      return false  
    }
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ma_ho_ten: this.Ma_ho_ten.trim(),
      Ngay_sinh: this.Ngay_sinh,
      Toa_nha_phong: this.Toa_nha_phong.trim(),
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Tu_diem: this.Tu_diem,
      Den_diem: this.Den_diem
    };
    this.DiemSinhVienService.getList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.DiemSinhVienService.getCountFilter(dataFilter, this.Token).subscribe(
        (z) => {
          this.totalItemFilter = z.Tong_so_ban_ghi_filter;
          this.numberPage = this.createNumberPage(z.Tong_so_ban_ghi_filter, this.pageSize).numberPage
          this.arrNumberPage_chil = this.createNumberPage(z.Tong_so_ban_ghi_filter, this.pageSize).arrNumberPage_chil
          this.spinner.hide();
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
      this.spinner.hide();
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList()
  }
  refesh() {

    this.Ma_ho_ten = ''
    this.Ngay_sinh = ''
    this.Toa_nha_phong = ''
    this.Tu_diem = ''
    this.Den_diem = ''
      this.getList()
  }
  changPage(event) {
    if((this.Tu_diem != '' && this.Den_diem == '')||(this.Tu_diem == '' && this.Den_diem != '')){
      this.toastr.warning("Bạn phải nhập từ điểm, đến điểm")
      return false
    }
    this.handlePageChange(event)
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ma_ho_ten: this.Ma_ho_ten.trim(),
      Ngay_sinh: this.Ngay_sinh,
      Toa_nha_phong: this.Toa_nha_phong,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Tu_diem: this.Tu_diem,
      Den_diem: this.Den_diem
    };
    this.DiemSinhVienService.getList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.spinner.hide();
    },
    (err) => {
      console.log(err);
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  modalDetail(content, sizm, type, data) {
    this.Data = data
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    let req = {
      ID_sv: data.ID_sv,
      Hoc_ky: data.Hoc_ky,
      Nam_hoc: data.Nam_hoc
    }
    this.DiemSinhVienService.getViPham(req, this.Token).subscribe((z) => {
      this.dataViPham = z.Data
    });
  }
  Confirm() {
    let req = {
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc
    }
    this.spinner.show()
    this.DiemSinhVienService.ChotDiem(req, this.Token).subscribe((z) => {
      if(z.Status == 1){
        this.getCount()
        this.getList()
        this.toastr.success("Chốt điểm thành công")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }else{
        this.toastr.error("Chốt điểm thất bại")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  ConfirmUnlock(){
    let req = {
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc
    }
    this.spinner.show()
    this.DiemSinhVienService.MoDiem(req, this.Token).subscribe((z) => {
      if(z.Status == 1){
        this.getCount()
        this.getList()
        this.toastr.success("Mở điểm thành công")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }else{
        this.toastr.error("Mở điểm thất bại")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  lock(){
    let req = {
      ID_sv: this.Data.ID_sv,
      Hoc_ky: this.Data.Hoc_ky,
      Nam_hoc: this.Data.Nam_hoc
    }
    this.spinner.show()
    this.DiemSinhVienService.KhoaDiemSV(req, this.Token).subscribe((z) => {
      if(z.Status == 1){
        this.getCount()
        this.getList()
        this.toastr.success("Khoá điểm thành công")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }else{
        this.toastr.error("Khoá điểm thất bại")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  unlock(){
    let req = {
      ID_sv: this.Data.ID_sv,
      Hoc_ky: this.Data.Hoc_ky,
      Nam_hoc: this.Data.Nam_hoc
    }
    this.spinner.show()
    this.DiemSinhVienService.MoDiemSV(req, this.Token).subscribe((z) => {
      if(z.Status == 1){
        this.getCount()
        this.getList()
        this.toastr.success("Mở điểm thành công")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }else{
        this.toastr.error("Mở điểm thất bại")
        this.modalService.dismissAll('AddModal');
        this.spinner.hide()
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  XuatExcel(){
    if(this.Tu_diem != '' && this.Den_diem === ''||this.Tu_diem === '' && this.Den_diem != '' ){
      this.toastr.warning("Bạn phải nhập từ điểm, đến điểm")
      return false  
    }
    if(this.Tu_diem >= this.Den_diem &&this.Tu_diem != '' && this.Den_diem != '' ){
      this.toastr.warning("Từ điểm phải nhỏ hơn đến điểm")
      return false  
    }
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: 10000000,
      Trang: 1,
      Ma_ho_ten: this.Ma_ho_ten.trim(),
      Ngay_sinh: this.Ngay_sinh,
      Toa_nha_phong: this.Toa_nha_phong.trim(),
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Tu_diem: this.Tu_diem,
      Den_diem: this.Den_diem
    };
    this.DiemSinhVienService.Excel(dataFilter, this.Token).subscribe((z) => {
      if(z.Status==1){
        this.exportService.exportExcelByte(z.FileData, 'Diem_sinh_vien');
        this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
        this.spinner.hide();
      }else{
        this.toastr.error(z.Message)
        this.spinner.hide();
      }
    },
    (err) => {
      console.log(err);
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    }
    );
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_DiemSV;
    window.open(urlHelp,'_blank');
  }

}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-lich-su-email',
  templateUrl: './lich-su-email.component.html',
  styleUrls: ['./lich-su-email.component.scss'],
})
export class LichSuEmailComponent extends BaseComponent implements OnInit {
  record: any;
  Email_gui = '';
  Email_nhan = '';
  Nam: number;
  Ma_sv = '';
  detail = '';
  ngOnInit(): void {
    this.Nam = new Date().getFullYear();
    this.getToken();
    this.getList();
    this.getCount();
  }
  getCount() {
    this.QuanTriEmailService.LichSuCount(this.Token).subscribe((z) => {
      this.totalItem = z.So_ban_ghi;
    });
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Email_gui: this.Email_gui == '' ? null : this.Email_gui,
      Email_sv: this.Email_nhan == '' ? null : this.Email_nhan,
      Nam: this.Nam,
      Ma_sv: this.Ma_sv == '' ? null : this.Ma_sv,
    };
    this.QuanTriEmailService.getLichSuEmail(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.router.navigate(['/admin']);
          this.spinner.hide();
        } else {
          this.dataTable = z.DataEmail;
          this.QuanTriEmailService.LichSuCountFilter(
            dataFilter,
            this.Token
          ).subscribe((z) => {
            this.totalItemFilter = z.So_ban_ghi;
            this.numberPage = this.createNumberPage(
              z.So_ban_ghi,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.So_ban_ghi,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 10000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Email_gui: this.Email_gui == '' ? null : this.Email_gui,
      Email_sv: this.Email_nhan == '' ? null : this.Email_nhan,
      Nam: this.Nam,
      Ma_sv: this.Ma_sv == '' ? null : this.Ma_sv,
    };
    this.QuanTriEmailService.getLichSuEmail(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        } else {
          this.dataTable = z.DataEmail;
          this.spinner.hide();
        }
      }
    );
  }
  open(content, sizm, Loai, Data) {
    this.record = Data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.detail = Data.Noi_dung_email;
  }
  reSend() {
    this.QuanTriEmailService.Resend(this.record, this.Token).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.toastr.success('Gửi thành công');
        } else {
          this.toastr.warning(res.Message);
        }
        this.modalService.dismissAll('');
      }
    );
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InvoinceService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}
  CountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Invoince/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Excel(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Invoince/Excel', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Count(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Invoince/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getList(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Invoince/Pagination', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  updateStatus(req: any, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Invoince/UpdateStatus?ID_bien_lai='+req.ID_bien_lai+'&Huy_phieu='+req.Huy_phieu, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getdetail(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Invoince/GetData?ID_bien_lai=' + req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(rq: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Invoince/LapPhieuChi' , rq, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ImportExcel(rq: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Invoince/ImportExcel' , rq, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  InsertKhoanThu(rq: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Invoince/LapPhieuThu' , rq, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getChiTietSinhVien(code: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Invoince/ChiTietSinhVien?Code=' + code,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  
  getSinhVien(code: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Invoince/SinhVien?Code=' + code,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getThongTinKhoanThu(ID_sv: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Invoince/KhoanThu?ID_sv=' + ID_sv,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getThongTinKhoanChi(ID_sv: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Invoince/KhoanChi?ID_sv=' + ID_sv,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

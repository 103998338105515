<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-3">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quản lý đề xuất</li>
        </ol>
      </div>
      <div class="col-md-9">
        <button
          class="btn btn-primary btn-sm"
          style="float: right;"
          (click)="filterById()"
          style="float: right; margin: 0 0 5px 5px;"
        >
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button
          class="btn btn-success btn-sm"
          style="float: right;"
          (click)="open(ThongKe, 'xl', 'PhanHoi', '')"
        >
          <i class="fas fa-balance-scale-left"></i> Thống kê
        </button>
        <!-- <button class="btn btn-success btn-sm" style="float: right; margin-right: 7px;" (click)="XuatExcel()">
          <i class="fas fa-file-excel"></i> Xuất excel
        </button> -->
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="searchForm">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label>Mã sinh viên</label>
              <input
                type="text"
                class="form-control"
                formControlName="Ma_sv"
                placeholder="Nhập mã sinh viên ..."
              />
            </div>
            <div class="col-md-4">
              <label>Tên sinh viên</label>
              <input
                type="text"
                class="form-control"
                formControlName="Ho_ten"
                placeholder="Nhập tên sinh viên..."
              />
            </div>
            <div class="col-md-4">
              <label>Toà nhà/Phòng</label>
              <select class="form-control" formControlName="ID_phong">
                <option value="">Tất cả</option>
                <option *ngFor="let p of dataToaNha" value="{{ p.ID_ToaNha }}">
                  {{ p.Ten_ToaNha }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Tháng </label>
              <ng-select formControlName="Thang">
                <ng-select></ng-select>
                <ng-option [value]="0">Chọn tháng</ng-option>
                <ng-option *ngFor="let i of MonthOption" [value]="i.value">{{
                  i.name
                }}</ng-option>
              </ng-select>
            </div>
            <div class="col-md-4">
              <label>Loại đề xuất</label>
              <ng-select formControlName="ID_loai_de_xuat">
                <ng-option [value]="0">Chọn loại đề xuất</ng-option>
                <ng-option
                  *ngFor="let i of dataLoaiDeXuat"
                  [value]="i.ID_loai_de_xuat"
                  >{{ i.Ten_loai_de_xuat }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-4">
              <label>Trạng thái hoàn thành</label>
              <ng-select formControlName="Trang_thai_hoan_thanh">
                <ng-option [value]="0">Chọn trạng thái hoàn thành</ng-option>
                <ng-option [value]="'Chưa hoàn thành'"
                  >Chưa hoàn thành</ng-option
                >
                <ng-option [value]="'Đã hoàn thành'">Đã hoàn thành</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.Ma_sv }}</td>
                      <td>{{ p.Ho_ten }}</td>
                      <td>{{ p.So_dien_thoai }}</td>
                      <td>{{ p.ID_ToaNha }}</td>
                      <td>{{ p.Ten_phong }}--{{ p.Ten_toa_nha }}</td>
                      <td>{{ p.Noi_dung }}</td>
                      <td>{{ p.Ngay_de_xuat | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ p.ID_loai_de_xuat }}</td>
                      <td>{{ p.Ten_loai_de_xuat }}</td>
                      <td>{{ p.Noi_dung_phan_hoi }}</td>
                      <td>
                        {{
                          p.Trang_thai_hoan_thanh == 0
                            ? 'Chưa hoàn thành'
                            : 'Đã hoàn thành'
                        }}
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-warning"
                          style="margin-right: 7px;"
                          (click)="open(TuChoiStatus, 'xs', 'PhanHoi', p)"
                        >
                          <i
                            class="far fa-comments"
                            title="Phản hồi đề xuất"
                          ></i>
                        </button>
                        <button
                          *ngIf="p.Trang_thai_hoan_thanh == 0"
                          class="btn btn-sm btn-success"
                          style="margin-right: 7px;"
                          (click)="open(Xacnhanhoanthanh, 'lg', 'XacNhan', p)"
                        >
                          <i
                            class="fa fa-check"
                            title="Xác nhận hoàn thành"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #TuChoiStatus let-modal>
  <div class="modal-content">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="modal-body">
        <label class="col-md-10">Nội dung phản hồi</label>
        <textarea
          class="form-control"
          formControlName="Noi_dung_phan_hoi"
          cols="100"
          rows="4"
          placeholder="Nhập nội dung phản hổi"
        ></textarea>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-md btn-success"
            (click)="PhanHoi()"
          >
            Đồng ý
          </button>
          <button
            type="button"
            class="btn btn-md btn-danger"
            (click)="modal.dismiss('Cross click')"
            style="margin-left: 1em;"
          >
            Hủy bỏ
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #ThongKe>
  <div class="modal-content">
    <app-chart-de-xuat></app-chart-de-xuat>
  </div>
</ng-template>
<ng-template #Xacnhanhoanthanh let-modal>
  <div class="modal-content">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="modal-body">
        <label class="text-lg">Xác nhận hoàn thành</label>
        <p>
          Bạn có chắc muốn thực hiện thao tác xác nhận đã hoàn thành hoàn thành
          ?
        </p>
        <input
          type="checkbox"
          id="chitiet"
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
        />
        <label class="px-4" for="chitiet">Thêm chi tiết sửa chữa</label>
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            <form class="form-horizontal" [formGroup]="UpdateForm">
              <div class="row mb-2">
                <div class="col-md-6">
                  <label>Toà nhà</label>
                  <input
                    class="form-control"
                    value="{{ dataSuaChua.Ten_toa_nha }}"
                    type="text"
                  />
                </div>
                <div class="col-md-6">
                  <label>Tên phòng</label>
                  <input
                    class="form-control"
                    value="{{ dataSuaChua.Ten_phong }}"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-6">
                  <label class="col-md-10">Tên thiết bị</label>
                  <select
                    class="form-control"
                    (change)="changeTB()"
                    formControlName="ID_thiet_bi"
                    [ngClass]="{
                      'is-invalid': submitted && checkvalue.ID_thiet_bi.errors
                    }"
                  >
                    <option value="">Chọn thiết bị</option>
                    <option
                      *ngFor="let p of DataThietBi"
                      value="{{ p.ID_thiet_bi }}"
                      >{{ p.Ten_thiet_bi }}</option
                    >
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Số lượng</label>
                  <input
                    class="form-control"
                    formControlName="So_luong"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-6">
                  <label>Người thực hiện</label>
                  <input
                    class="form-control"
                    formControlName="Nguoi_thuc_hien"
                    type="text"
                  />
                </div>
                <div class="col-md-6">
                  <label>Ngày hoàn thành</label>
                  <input
                    class="form-control"
                    formControlName="Ngay_hoan_thanh"
                    type="date"
                  />
                </div>
              </div>
              <div>
                <label>Nội dung</label>
                <textarea
                  class="form-control"
                  cols="100"
                  rows="5"
                  formControlName="Noi_dung"
                ></textarea>
              </div>
            </form>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-md btn-success"
            (click)="updateXacNhan()"
          >
            Xác nhận
          </button>
          <button
            type="button"
            class="btn btn-md btn-danger"
            (click)="modal.dismiss('Cross click')"
            style="margin-left: 1em;"
          >
            Hủy bỏ
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListallService } from 'src/app/utils/services/listall.service';
import { NumberWaterService } from 'src/app/utils/services/number-water.service';
import { RegulationPaymentService } from 'src/app/utils/services/regulation-payment.service';
import { AccessHistoryService } from 'src/app/utils/services/accesshistory.service';
import * as XLSX from 'xlsx';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { flatten } from '@angular/compiler';
import { QuyDinhKhoanThuService } from 'src/app/utils/services/quy-dinh-khoan-thu.service';
import { BaseComponent } from '../base/base.component';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-number-water',
  templateUrl: './number-water.component.html',
  styleUrls: ['./number-water.component.scss'],
})
export class NumberWaterComponent extends BaseComponent implements OnInit {
  dtOptions: any;
  Token: string;
  fileName = 'So_dien.xlsx';
  Res: any;
  req: any;
  check: any;
  titleConfirm: any;
  contentConfirm: any;
  checkSoQD: any;
  dataTable: any = [];
  dataManager: any = [];
  dataListStudent: any = [];
  dataTableOld: any = [];
  dataCampus: any = [];
  dataBuilding: any = [];
  dataRoom: any = [];
  DetailData: any = [];
  closeResult: string;
  ClassData: any;
  ID_co_so: any;
  ID_toa_nha: any;
  ID_phong: any;
  ID: any;
  checkStatus: any;
  status: any;
  month: any;
  year: any;
  ID_room: any;
  dateConfirm: any;
  numberOld: any;
  numberNew: any;
  price: any;
  note: any;
  radio: any;
  record: any;
  titleModal: string;
  default: any;
  MonthOption: any = [];
  yearArr: any = [];
  datadetail: any = [];
  datadetail2: any = [];
  datadetail3: any = [];
  dtOptions2: any;
  dataKhoanThu = [];
  TieuThuDauKy = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: any;
  checkConfirm: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải mức học phí tín chỉ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  modalRef: any;
  requestKhoanThu: any;

  Insert = new FormGroup({
    ID: new FormControl(null),
    status: new FormControl(null),
    month: new FormControl(null),
    year: new FormControl(null),
    ID_room: new FormControl(null),
    dateConfirm: new FormControl(null, [Validators.required]),
    numberOld: new FormControl(null, [Validators.required, Validators.min(0)]),
    numberNew: new FormControl(null, [Validators.required, Validators.min(0)]),
    price: new FormControl(null),
    note: new FormControl(null, [Validators.maxLength(500), removeSpaces]),
    So_du_dau_ky: new FormControl(0, [Validators.min(0)]),
  });
  filter = new FormGroup({
    selectCampus: new FormControl(null),
    selectBuilding: new FormControl(null),
    selectRoom: new FormControl(null),
    month: new FormControl(null),
    year: new FormControl(null),
    roomFilter: new FormControl(null),
    floorFilter: new FormControl(null),
    status: new FormControl(''),
    Thanh_toan: new FormControl(''),
  });
  Check = new FormGroup({
    radio: new FormControl(null),
  });
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    var d = new Date();
    var n = d.getFullYear();
    this.filter.patchValue({ year: d.getFullYear() });
    this.filter.patchValue({ month: d.getMonth() });
    this.Request = {
      month: this.filter.controls.month.value,
      year: this.filter.controls.year.value,
    };
    this.MonthOption = [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' },
      { value: 4, name: '4' },
      { value: 5, name: '5' },
      { value: 6, name: '6' },
      { value: 7, name: '7' },
      { value: 8, name: '8' },
      { value: 9, name: '9' },
      { value: 10, name: '10' },
      { value: 11, name: '11' },
      { value: 12, name: '12' },
    ];
    var d = new Date();
    var n = d.getFullYear();

    for (var i = 0; i < 10; i++) {
      let object = {
        value: n,
        name: n,
      };
      this.yearArr.push(object);
      n--;
    }
    this.getToken();
    this.getListCampus();
    this.getList();
    this.getCount();
  }
  refesh() {
    this.filter.get('selectBuilding').setValue(null);
    this.filter.get('selectRoom').setValue(null);
    this.filter.get('status').setValue('');
    this.getList();
    this.getCount();
  }
  getCount() {
    this.NumberWaterService.Count(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }
  getList() {
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_room:
        this.filter.value.selectRoom == ''
          ? null
          : this.filter.value.selectRoom,
      ID_building:
        this.filter.value.selectBuilding == ''
          ? null
          : this.filter.value.selectBuilding,
      month: this.filter.value.month == '' ? null : this.filter.value.month,
      year: this.filter.value.year == '' ? null : this.filter.value.year,
      status: this.filter.value.status == '' ? null : this.filter.value.status,
      Trang_thai_thanh_toan:
        this.filter.value.Thanh_toan == ''
          ? null
          : this.filter.value.Thanh_toan,
    };
    this.NumberWaterService.GetPagination(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.router.navigate(['/admin']);
          this.spinner.hide();
        } else {
          this.dataTable = z.Data;
          this.NumberWaterService.CountFilter(dataFilter, this.Token).subscribe(
            (z) => {
              this.totalItemFilter = z.Tong_so_ban_ghi_filter;
              this.numberPage = this.createNumberPage(
                z.Tong_so_ban_ghi_filter,
                this.pageSize
              ).numberPage;
              this.arrNumberPage_chil = this.createNumberPage(
                z.Tong_so_ban_ghi_filter,
                this.pageSize
              ).arrNumberPage_chil;
            }
          );
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 10000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_room:
        this.filter.value.selectRoom == ''
          ? null
          : this.filter.value.selectRoom,
      ID_building:
        this.filter.value.selectBuilding == ''
          ? null
          : this.filter.value.selectBuilding,

      month: this.filter.value.month == '' ? null : this.filter.value.month,
      year: this.filter.value.year == '' ? null : this.filter.value.year,
      status: this.filter.value.status == '' ? null : this.filter.value.status,
      Trang_thai_thanh_toan:
        this.filter.value.Thanh_toan == ''
          ? null
          : this.filter.value.Thanh_toan,
    };
    this.NumberWaterService.GetPagination(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        } else {
          this.dataTable = z.Data;
          this.spinner.hide();
        }
      }
    );
  }

  changeTieuThu() {
    this.TieuThuDauKy = !this.TieuThuDauKy;
  }
  getData() {
    this.Request = {
      month: this.filter.controls.month.value,
      year: this.filter.controls.year.value,
    };
    this.NumberWaterService.getListFill(this.Request, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        if (this.dataTable.length != 0) {
          this.dataTable.forEach((value, index) => {
            this.dataTable[index]['differenceNumber'] =
              value.numberNew - value.numberOld + value.So_du_dau_ky;
            this.dataRoom.forEach((valueRoom, index) => {
              if (value.ID_room == valueRoom.ID_Phong) {
                this.dataRoom[index] = Object.assign(valueRoom, value);
                if (valueRoom.status == 2) {
                  valueRoom.checkStatus = true;
                } else {
                  valueRoom.checkStatus = false;
                }
              }
            });
          });
        }
      }
    );
  }
  getListRoom() {
    this.ListAllService.getListRoom(
      this.filter.value.selectBuilding,
      this.Token
    ).subscribe((z) => {
      this.dataRoom = z.Data;
    });
  }
  //List tòa nhà
  getListBuilding(ID_Campus) {
    this.ListAllService.getListBuilding(ID_Campus, this.Token).subscribe(
      (z) => {
        this.dataBuilding = z.Data;
        this.default = this.dataBuilding[0].ID_ToaNha;
      }
    );
  }
  //List Cơ sở
  getListCampus() {
    this.ListAllService.getListCampus(this.Token).subscribe((z) => {
      this.dataCampus = z.Data;
      this.default = this.dataCampus[0].ID;
      this.filter.patchValue({ selectCampus: this.default });
      this.getListBuilding(this.filter.controls.selectCampus.value);
      this.dtTrigger.next();
    });
  }
  changeCampus() {
    this.getListBuilding(this.filter.controls.selectCampus.value);
  } // Fill data combobox toa nha

  changeBuilding() {
    this.getListRoom();
  } // Fill data combobox phong

  //get
  openModal(content, sizm, Loai, Data) {
    this.record = Data;
    if (Loai == 'KhoanThu') {
      this.requestKhoanThu = {
        month: this.record.month,
        year: this.record.year,
        ID_room: this.record.ID_room,
      };
      // this.NumberWaterService.getKHoanThu(this.requestKhoanThu, this.Token).subscribe((z) => {
      //   this.dataKhoanThu = z.Data
      // });
      this.NumberWaterService.getThongKeKHoanThu(
        this.requestKhoanThu,
        this.Token
      ).subscribe((z) => {
        this.dataKhoanThu = z.Data;
      });
    } else if (Loai == 'Sua') {
      this.titleModal = 'Quản lý số nước';
      if (Data.status == 1 || Data.status == 2) {
        this.toastr.warning(
          'Không thể sửa số liệu vì đã khoá hoặc đã phân bổ!'
        );
        return false;
      }
      if (Data.ID_number_water != 0) {
        this.checkInsert = 0;
        var dateConfirm = Data.dateConfirm.split('T');
        var newDate = dateConfirm[0];
        this.Insert.get('numberOld').setValue(Data.numberOld);
        this.Insert.get('numberNew').setValue(Data.numberNew);
        this.Insert.get('price').setValue(Data.price);
        this.Insert.get('dateConfirm').setValue(newDate);
        this.Insert.get('note').setValue(Data.note);
        this.Insert.get('ID_room').setValue(Data.ID_room);
        this.Insert.get('So_du_dau_ky').setValue(Data.So_du_dau_ky);

        this.ID_room = Data.ID_room;
        this.ID_phong = Data.ID_room;
      } else {
        this.Insert.reset();
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let date = yyyy + '-' + mm + '-' + dd;
        this.Insert.get('dateConfirm').setValue(date);
        this.Insert.get('numberOld').setValue(Data.numberOld);
        this.Insert.get('ID_room').setValue(Data.ID_room);
        this.checkInsert = 1;
      }
    } else if (Loai == 'Chot') {
      if (Data.status == 1) {
        this.toastr.warning('Phòng đã được chốt số nước!');
        return false;
      } else if (Data.status == 2) {
        this.toastr.warning('Phòng đã được phân bổ!');
        return false;
      } else if (Data.status == null) {
        this.toastr.warning('Phòng chưa được nhập số liệu');
        return false;
      }
    } else if (Loai == 'Mo') {
      if (Data.status == 0) {
        this.toastr.warning('Phòng chưa chốt số nước!');
        return false;
      } else if (Data.status == null) {
        this.toastr.warning('Phòng chưa được nhập số liệu');
        return false;
      }
    } else if (Loai == 'PhanBo') {
      if (Data.status == 0) {
        this.toastr.warning('Phòng chưa chốt số nước!');
        return false;
      } else if (Data.status == 2) {
        this.toastr.warning('Phòng đã được phân bổ!');
        return false;
      }
      if (Data.status == null) {
        this.toastr.warning('Phòng chưa được nhập số liệu');
        return false;
      }
    }
    this.submitted = false;
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: sizm,
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  //check value
  get checkvalue() {
    return this.Insert.controls;
  }

  onSubmit() {
    if (
      this.Insert.controls.numberOld.value >
      this.Insert.controls.numberNew.value
    ) {
      this.toastr.warning('Chỉ số mới không được nhỏ hơn chỉ số cũ!');
      return false;
    }
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  onSubmit2() {
    if (this.Check.controls.radio.value == null && this.checkInsert == 3) {
      this.toastr.warning('Bạn chưa chọn loại phân phối!');
      return false;
    }
    this.submitted = true;
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
  }
  // create
  create() {
    let InputDatas = {
      status: 0,
      month: this.filter.controls.month.value,
      year: this.filter.controls.year.value,
      ID_room: this.Insert.controls.ID_room.value,
      dateConfirm: this.Insert.controls.dateConfirm.value,
      numberOld: this.Insert.controls.numberOld.value,
      numberNew: this.Insert.controls.numberNew.value,
      price: this.Insert.controls.price.value,
      note: this.Insert.controls.note.value,
      So_du_dau_ky: this.Insert.controls.So_du_dau_ky.value,
    };

    if (this.checkInsert == 1) {
      this.NumberWaterService.Insert(InputDatas, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.getList();
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error('Cập nhật không thành công!');
        }
        this.modalService.dismissAll('AddModal');
      });
    } else if (this.checkInsert == 0) {
      this.NumberWaterService.Update(InputDatas, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.getList();

          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error('Cập nhật không thành công!');
        }
        this.modalService.dismissAll('AddModal');
      });
    }
  }

  //modal
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }

  OnSubmit2() {
    var lst = this.dataTable.filter((x) => x.checked == true);
    if (lst.length < 1) {
      this.toastr.warning('Bạn chưa chọn phòng!');
    } else {
      this.NumberWaterService.MultiChot(lst, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.getList();

          this.toastr.success(z.Message);
          this.modalService.dismissAll('AddModal');
        }
      });
      this.modalService.dismissAll('AddModal');
    }
  }
  OnSubmit3() {
    if (this.Check.value.radio == null) {
      this.toastr.warning('Bạn chưa chọn loại phân bổ!');
      return false;
    }
    var lst = this.dataTable.filter((x) => x.checked == true);

    if (lst.length < 1) {
      this.toastr.warning('Bạn chưa chọn phòng!');
    } else {
      this.NumberWaterService.MultiPhanBo(
        lst,
        this.Check.value.radio,
        this.Token
      ).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.getList();

          this.toastr.success(z.Message);
          this.modalService.dismissAll('AddModal');
        }
      });
    }
  }
  OnSubmit4() {
    var lst = this.dataTable.filter((x) => x.checked == true);
    if (lst.length < 1) {
      this.toastr.warning('Bạn chưa chọn phòng!');
    } else {
      this.NumberWaterService.MultiMoChot(lst, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.getList();

          this.toastr.success(z.Message);
          this.modalService.dismissAll('AddModal');
        }
      });
    }
  }
  chonall() {
    var table = $('#dataTable').DataTable().rows({ filter: 'applied' }).data();
    var length = table.rows({ filter: 'applied' }).nodes().length;
    var lstID = [];
    for (var i = 0; i < length; i++) {
      var ID = parseInt(table[i][1]);
      lstID.push(ID);
    }
    for (let index = 0; index < this.dataRoom.length; index++) {
      if (lstID.includes(this.dataRoom[index].ID_Phong)) {
        this.dataRoom[index].Selected = $('#SelectALL').prop('checked');
      }
    }
  }
  SelectRow(ID) {
    for (let index = 0; index < this.dataRoom.length; index++) {
      if (this.dataRoom[index].ID_number_water == ID) {
        if ($('#checkbox' + ID).prop('checked')) {
          {
            this.dataRoom[index].Selected = true;
          }
        } else {
          this.dataRoom[index].Selected = false;
        }
        break;
      }
    }
    if (this.dataRoom.every((p) => p.Selected)) {
      $('#SelectALL').prop('checked', true);
    } else {
      $('#SelectALL').prop('checked', false);
    }
  }
  MoSubmit() {
    let req = {
      ID_number_water: this.record.ID_number_water,
      status: 0,
      month: this.record.month,
      year: this.record.year,
      ID_room: this.record.ID_room,
      dateConfirm: this.record.dateConfirm,
      numberOld: this.record.numberOld,
      numberNew: this.record.numberNew,
      price: this.record.price,
      note: this.record.note,
      So_du_dau_ky: this.record.So_du_dau_ky,
    };
    if (this.record.status == 2) {
      this.NumberWaterService.MoChot(req, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.getList();
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.modalService.dismissAll('AddModal');
      });
    } else if (this.record.status == 1) {
      this.NumberWaterService.Update(req, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.getList();

          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.modalService.dismissAll('AddModal');
      });
    }
  }
  ChotSubmit() {
    let req = {
      ID_number_water: this.record.ID_number_water,
      status: 1,
      month: this.record.month,
      year: this.record.year,
      ID_room: this.record.ID_room,
      dateConfirm: this.record.dateConfirm,
      numberOld: this.record.numberOld,
      numberNew: this.record.numberNew,
      price: this.record.price,
      note: this.record.note,
      So_du_dau_ky: this.record.So_du_dau_ky,
    };
    this.NumberWaterService.Update(req, this.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.error(z.Message);
      } else if (z.Status == 1) {
        this.getList();

        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.modalService.dismissAll('AddModal');
    });
  }
  PhanBoSubmit() {
    let req = {
      month: this.filter.value.month,
      year: this.filter.value.year,
      ID_room: this.record.ID_room,
      numberOld: this.record.numberOld,
      numberNew: this.record.numberNew,
      type: this.Check.value.radio,
      So_du_dau_ky: this.record.So_du_dau_ky,
      ID_number_water: this.record.ID_number_water,
    };
    this.NumberWaterService.PhanBo(req, this.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.error(z.Message);
      } else if (z.Status == 1) {
        this.getList();

        this.toastr.success(z.Message);
      } else {
        this.toastr.warning(z.Message);
      }
      this.modalService.dismissAll('AddModal');
    });
  }
  Delete() {
    let req = {
      Hoc_ky: this.record.Hoc_ky,
      Nam_hoc: this.record.Nam_hoc,
      Dot: this.record.Dot,
      ID_sv: this.record.ID_sv,
      ID_thu_chi: this.record.ID_thu_chi,
      ID_mon: this.record.ID_mon,
      So_du_dau_ky: this.record.So_du_dau_ky,
      Thang: this.record.Thang,
    };
    this.QuyDinhKhoanThuService.Delete(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.modalRef.close();
          this.NumberWaterService.getKHoanThu(
            this.requestKhoanThu,
            this.Token
          ).subscribe((z) => {
            this.dataKhoanThu = z.Data;
          });
        } else {
          this.toastr.error(z.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      if (this.filter.value.month > 0) {
        let req = {
          So_ban_ghi: 100000,
          Trang: 1,
          ID_room:
            this.filter.value.selectRoom == ''
              ? null
              : this.filter.value.selectRoom,
          ID_building:
            this.filter.value.selectBuilding == ''
              ? null
              : this.filter.value.selectBuilding,

          month: this.filter.value.month == '' ? null : this.filter.value.month,
          year: this.filter.value.year == '' ? null : this.filter.value.year,
          status:
            this.filter.value.status == '' ? null : this.filter.value.status,
          Trang_thai_thanh_toan:
            this.filter.value.Thanh_toan == ''
              ? null
              : this.filter.value.Thanh_toan,
        };
        this.NumberWaterService.ExcelSoNuoc(req, a.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.exportService.exportExcelByte(z.FileData, 'DanhSachSoNuoc');
            this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, 'Tác vụ thất bại');
            this.spinner.hide();
          }
          (err) => {
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            }
            if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          };
        });
      }
    }
  }
}

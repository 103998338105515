import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  GetList(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/LoadList', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Get_ChiaSe_ByID(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/Load_ChiaSe_byID', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Get_File_ByID(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/Load_File_byID', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  SaveDocument(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/SaveDocument', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  CreateFile(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/CreateFile', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  DeleteDocument(ID_tai_lieu: any, token: any): Observable<any> {
    return this.http
      .delete<any>(
        this.appConfig.UniDormAPI +
          'Document/DeleteDocument?ID_tai_lieu=' +
          ID_tai_lieu,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  DeleteFile(ID_file: any, token: any): Observable<any> {
    return this.http
      .delete<any>(
        this.appConfig.UniDormAPI + 'Document/DeleteFile?ID_file=' + ID_file,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  SaveFile(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/SaveFile', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  SaveShare(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Document/SaveShare', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  GetPeople(token: any): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Document/GetPeople', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

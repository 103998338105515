<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Lịch xử vào ra KTX</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row" style="padding-left: 10px; padding-right: 10px;">
          <div class="col-md-6">
            <label class="col-md-10">Mã sinh viên:</label>
            <input
              class="form-control"
              type="text"
              id="Ma_sv_ft"
              formControlName="Ma_sv_ft"
              (keyup)="filterById()"
              [(ngModel)]="Ma_sv_ft"
              placeholder="Nhập mã sinh viên cần tìm"
            />
          </div>
          <div class="col-md-6">
            <label class="col-md-10">Tên sinh viên:</label>
            <input
              class="form-control"
              type="text"
              id="Ten_sv_ft"
              formControlName="Ten_sv_ft"
              (keyup)="filterById()"
              [(ngModel)]="Ten_sv_ft"
              placeholder="Nhập tên sinh viên cần tìm"
            />
          </div>
          <!-- <div class="col-md-3">
                        <label class="col-md-10">Tên phòng:</label>
                        <input class="form-control" type="text" id="Ten_phong_ft" formControlName="Ten_phong_ft"
                            (keyup)="filterById()" [(ngModel)]="Ten_phong_ft" placeholder="Nhập tên phòng cần tìm">
                    </div> -->
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.ID_sv }}</td>
                      <td>{{ p.Ma_sv }}</td>
                      <td>{{ p.Ho_ten }}</td>
                      <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                      <td>
                        <!-- <a [routerLink]="['/access-history-detail']" style="margin-right: 7px;"
                                                    class="btn btn-success btn-sm"
                                                    [queryParams]="{ID_sv:p.ID_sv,Ma_sv:p.Ma_sv}"
                                                    queryParamsHandling="merge">
                                                    <i class="fas fa-eye" ngbTooltip="Xem"></i>
                                                </a> -->
                        <button
                          class="btn btn-success btn-sm"
                          (click)="open(ViewModal, 'lg', 'View', p.ID_sv)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-eye" ngbTooltip="Xem"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ViewModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Chi tiết lịch sử ra vào</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div
          class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
        >
          <table
            datatable
            [dtOptions]="dtOptions2"
            id="sinhviendt"
            class="table-bordered dataTables_wrapper no-footer"
          >
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of dataStudent">
                <td>{{ p.Ten_phong }}</td>
                <td>{{ p.Ngay_bat_dau | date: 'dd/MM/yyyy' }}</td>
                <td>{{ p.Ngay_ket_thuc | date: 'dd/MM/yyyy' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.col -->
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <!-- <button type="button" class="btn btn-md btn-success" (click)="onSubmit()" style="margin-left: 1em;">
                <i class="fas fa-save"></i> Lưu
            </button> -->
    </div>
  </div>
</ng-template>

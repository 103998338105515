import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, from, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';

@Component({
  selector: 'app-portal-dang-ky-total-page',
  templateUrl: './portal-dang-ky-total-page.component.html',
  styleUrls: ['./portal-dang-ky-total-page.component.scss'],
})
export class PortalDangKyTotalPageComponent implements OnInit {
  public com: common;
  Token: string;
  UserName_get: string;
  Ma_sv_get: string;
  FullName_get: string;

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RoomCategoryRegister: RoomCategoryRegisterService,
    private System: SystemService,
    private RegisterDorm: RegisterDormService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    var a = this.com.getPortalInfo();
    this.Token = a.Token;
    this.UserName_get = a.Info.UserName;
    this.Ma_sv_get = a.Info.UserName;
    this.FullName_get = a.Info.FullName;
    this.GetDataMail();
  }

  So_thong_bao: number;
  dataLichSuEmail: any;

  //-------------------------Lấy mail thông tin sv theo mã----------------------------//
  GetDataMail() {
    this.spinner.show();
    this.RegisterDorm.getSVbyMa(this.Ma_sv_get, this.Token).subscribe((res) => {
      if (res.Status == 1) {
        this.dataLichSuEmail = res.DataEmail;
        this.So_thong_bao =
          this.dataLichSuEmail.length > 0 ? this.dataLichSuEmail.length : 0;
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }
}

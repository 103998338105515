<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/invoince']"> &nbsp;Biên lai hóa đơn</a>
                    </li>
                    <li class="breadcrumb-item active">Lập biên lai thu</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" (click)="OnSubmit()" style="float: right; margin-left: 10px;">
                    <i class="fas fa-plus-circle"></i> Lưu phiếu
                </button>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="Insert">
                <div class="row">
                    <div class="col-md-3">
                        <label class="require">Mã SV/SBD/Mã hồ sơ</label>
                        <ng-select (search)=OnSearch($event) formControlName="code" (change)="changeSinhVien()" [ngClass]="{'is-invalid': submitted && checkvalue.code.errors}">
                            <ng-option value="">Tìm kiếm sinh viên</ng-option>
                            <ng-option *ngFor="let value of dataSinhVien" [value]="value.ID_sv">{{value.Ho_ten}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="submitted && checkvalue.code.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.code.errors.required">
                            Sinh viên không được bỏ trống!
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Học kỳ</label>
                        <select class="form-control" formControlName="Hoc_ky">
                            <option value="1">Học kỳ I</option>
                            <option value="2">Học kỳ II</option>
                            <option value="3">Học kỳ III</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Năm học</label>
                        <select class="form-control" formControlName="Nam_hoc">
                            <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{ i.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Hình thức</label>
                        <select class="form-control" formControlName="Gian_thu">
                            <option value="1">Gián tiếp</option>
                            <option value="0">Trực tiếp</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Lần thu</label>
                        <select class="form-control" formControlName="Lan_thu">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="require">Ngày thu</label>
                        <input type="date" class="form-control" formControlName="Ngay_chi" [ngClass]="{'is-invalid': submitted && checkvalue.Ngay_chi.errors}" />
                        <div *ngIf="submitted && checkvalue.Ngay_chi.errors" class="invalid-feedback">
                            <div *ngIf="checkvalue.Ngay_chi.errors.required">
                                Ngày chi không được bỏ trống!
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Số phiếu</label>
                        <input type="number" class="form-control" formControlName="So_phieu" placeholder="Số phiếu" />
                    </div>
                    <div class="col-md-3">
                        <label>Loại tiền</label>
                        <select class="form-control" formControlName="Loai_tien">
                            <option value="VND">VND</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Nội dung</label>
                        <textarea class="form-control" cols="100" rows="4" formControlName="Noi_dung"></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">
                            <input type="checkbox" id="SelectALL" (change)="CheckAll()" value="SelectAll" />
                        </th>
                        <th scope="col">Học kỳ</th>
                        <th scope="col">Năm học</th>
                        <th scope="col">Đợt</th>
                        <th scope="col">Tháng</th>
                        <th scope="col">Khoản thu</th>
                        <th scope="col">Số tiền</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let element of KhoanThuData; index as i">
                        <th>
                            <input type="checkbox" (change)="selectHoanTraData(element, i)" [checked]="element.Selected"
                                id="ID_{{ i }}" value="ID_{{ i }}" />
                        </th>
                        <td>{{ element.Hoc_ky }}</td>
                        <td>{{ element.Nam_hoc }}</td>
                        <td>{{ element.Dot }}</td>
                        <td>{{ element.Thang }}</td>
                        <td>{{ element.Ten_thu_chi }}</td>
                        <td class="text-right">{{ element.So_tien_thu |number }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Khoản nộp</th>
                        <th scope="col">Số tiền phải nộp</th>
                        <th scope="col">Số tiền đã nộp</th>
                        <th scope="col">Số tiền Hoàn trả</th>
                        <th scope="col">Số tiền thiếu/thừa</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let i of TongHopData">
                        <th>{{ i.Ten_thu_chi }}</th>
                        <td  class="text-right">{{ i.So_tien_phai_nop|number }}</td>
                        <td  class="text-right">{{ i.So_tien_da_nop |number}}</td>
                        <td  class="text-right">{{ i.So_tien_hoan_tra |number}}</td>
                        <td class="text-right">{{ i.So_tien_thieu_thua |number}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>
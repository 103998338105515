import { UsersToaNhaComponent } from './components/users-toa-nha/users-toa-nha.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { BlankComponent } from './views/blank/blank.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CampusComponent } from './components/campus/campus.component';

import { BuildingComponent } from './components/building/building.component';
import { RoomtypesComponent } from './components/roomtypes/roomtypes.component';
import { RoomComponent } from './components/room/room.component';
import { DeviceComponent } from './components/device/device.component';
import { RoomService } from './utils/services/room.service';
import { RoomdetailComponent } from './components/room/roomdetail/roomdetail.component';
import { GrantcardComponent } from './components/grantcard/grantcard.component';
import { ListpeopledormComponent } from './components/listpeopledorm/listpeopledorm.component';
import { StudentsSortComponent } from './components/students-sort/students-sort.component';
import { NumberElectricComponent } from './components/number-electric/number-electric.component';
import { AccessHistoryComponent } from './components/access-history/access-history.component';
import { AccessHistoryDetailComponent } from './components/access-history/access-history-detail/access-history-detail.component';
import { RegisterDormComponent } from './components/register-dorm/register-dorm.component';
import { RoomChargeComponent } from './components/room-charge/room-charge.component';
import { NumberWaterComponent } from './components/number-water/number-water.component';
import { CollectionReceiptComponent } from './components/collection-receipt/collection-receipt.component';
import { ReceiptDetailComponent } from './components/collection-receipt/receipt-detail/receipt-detail.component';
import { StatisticalComponent } from './components/statistical/statistical.component';
import { ReceiptDestroyComponent } from './components/collection-receipt/receipt-destroy/receipt-destroy.component';
import { ReceiptDestroyDetailComponent } from './components/collection-receipt/receipt-destroy/receipt-destroy-detail/receipt-destroy-detail.component';
import { ListPeopleDesComponent } from './components/list-people-des/list-people-des.component';
import { RoomCategoryComponent } from './components/room-category/room-category.component';
import { DangNhapComponent } from './pages/dang-nhap/dang-nhap.component';
import { XacNhanComponent } from './pages/xac-nhan/xac-nhan.component';
import { PortalComponent } from './pages/portal/portal.component';
import { PortalDangKyComponent } from './pages/portal/portal-dang-ky/portal-dang-ky.component';
import { AddPaymentRegulationsComponent } from './components/room-charge/add-payment-regulations/add-payment-regulations.component';
import { StudentsSortDetailComponent } from './components/students-sort/students-sort-detail/students-sort-detail.component';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
import { PlanRoomComponent } from './components/plan-room/plan-room.component';
import { RegulationObjectComponent } from './components/regulation-object/regulation-object.component';
import { BlackListComponent } from './components/black-list/black-list.component';
import { InvoinceComponent } from './components/invoince/invoince.component';
import { StatisticalMoneyService } from './utils/services/statistical-money.service';
import { StatisticalMoneyComponent } from './components/statistical-money/statistical-money.component';
import { InvoiceAddComponent } from './components/invoice-add/invoice-add.component';
import { PortalChangePassComponent } from './pages/portal/portal-change-pass/portal-change-pass.component';
import { SearchLogComponent } from './components/search-log/search-log.component';
import { VersionDetailComponent } from './components/version-detail/version-detail.component';
import { QuyDinhKhoanThuComponent } from './components/quy-dinh-khoan-thu/quy-dinh-khoan-thu.component';
import { DeXuatSinhVienComponent } from './components/de-xuat-sinh-vien/de-xuat-sinh-vien.component';
import { DiemSinhVienComponent } from './components/diem-sinh-vien/diem-sinh-vien.component';
import { DiemPhongComponent } from './components/diem-phong/diem-phong.component';
import { DeXuatComponent } from './pages/portal/de-xuat/de-xuat.component';
import { LichSuSuaChuaComponent } from './components/lich-su-sua-chua/lich-su-sua-chua.component';
import { ViPhamComponent } from './components/vi-pham/vi-pham.component';
import { DeveloperSQLComponent } from './components/developer-sql/developer-sql.component';
import { HinhThucComponent } from './components/hinh-thuc/hinh-thuc.component';
import { KhenThuongKyLuatComponent } from './components/khen-thuong-ky-luat/khen-thuong-ky-luat.component';
import { DangKyQuaTrinhComponent } from './components/dang-ky-qua-trinh/dang-ky-qua-trinh.component';
import { XacNhanSauDaiHocComponent } from './pages/xac-nhan-sau-dai-hoc/xac-nhan-sau-dai-hoc.component';
import { DonGiaDienNuocComponent } from './components/don-gia-dien-nuoc/don-gia-dien-nuoc.component';
import { QuanTriEmailComponent } from './components/quan-tri-email/quan-tri-email.component';
import { TienIchComponent } from './components/tien-ich/tien-ich.component';
import { KhoanThuAddComponent } from './components/invoince/khoan-thu-add/khoan-thu-add.component';
import { PortalDangKyTotalPageComponent } from './pages/portal/portal-dang-ky-total-page/portal-dang-ky-total-page.component';
import { PortalCapNhatThongTinComponent } from './pages/portal/portal-cap-nhat-thong-tin/portal-cap-nhat-thong-tin.component';
import { LichSuEmailComponent } from './components/lich-su-email/lich-su-email.component';
import { ThamSoHeThongComponent } from './components/tham-so-he-thong/tham-so-he-thong.component';
import { LoaiDeXuatComponent } from './components/loai-de-xuat/loai-de-xuat.component';
import { ThongTinSinhVienComponent } from './pages/portal/thong-tin-sinh-vien/thong-tin-sinh-vien.component';
import { TemplateEmailComponent } from './components/template-email/template-email.component';
import { DoiTuongChinhSachComponent } from './components/doi-tuong-chinh-sach/doi-tuong-chinh-sach.component';
import { IntegratedStatisticsComponent } from './components/integrated-statistics/integrated-statistics.component';
import { DocumentComponent } from './components/document/document.component';
const routes: Routes = [
  {
    path: 'admin',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'QuyDinhKhoanThu',
        component: QuyDinhKhoanThuComponent,
      },
      {
        path: 'tham-so-he-thong',
        component: ThamSoHeThongComponent,
      },
      {
        path: 'doi-tuong-chinh-sach',
        component: DoiTuongChinhSachComponent,
      },
      {
        path: 'KhoanThu-add',
        component: KhoanThuAddComponent,
      },
      {
        path: 'Users-Toa-Nha',
        component: UsersToaNhaComponent,
      },
      {
        path: 'black-list',
        component: BlackListComponent,
      },
      {
        path: 'statistical-money',
        component: StatisticalMoneyComponent,
      },
      {
        path: 'add-payment-regulation',
        component: AddPaymentRegulationsComponent,
      },
      {
        path: 'campus',
        component: CampusComponent,
      },
      {
        path: 'room-category',
        component: RoomCategoryComponent,
      },
      {
        path: 'room-charge',
        component: RoomChargeComponent,
      },
      {
        path: 'electricnumber',
        component: NumberElectricComponent,
      },
      {
        path: 'waternumber',
        component: NumberWaterComponent,
      },
      {
        path: 'device',
        component: DeviceComponent,
      },
      {
        path: 'building',
        component: BuildingComponent,
      },
      {
        path: 'roomtypes',
        component: RoomtypesComponent,
      },
      {
        path: 'room',
        component: RoomComponent,
      },
      {
        path: 'roomdetail',
        component: RoomdetailComponent,
      },
      {
        path: 'grantcard',
        component: GrantcardComponent,
      },
      {
        path: 'listpeopledorm',
        component: ListpeopledormComponent,
      },
      {
        path: 'students-sort',
        component: StudentsSortComponent,
      },
      {
        path: 'sort-detail',
        component: StudentsSortDetailComponent,
      },
      {
        path: 'register-dorm',
        component: RegisterDormComponent,
      },
      {
        path: 'access-history',
        component: AccessHistoryComponent,
      },
      {
        path: 'access-history-detail',
        component: AccessHistoryDetailComponent,
      },
      {
        path: 'app-collection-receipt',
        component: CollectionReceiptComponent,
      },
      {
        path: 'receipt-detail',
        component: ReceiptDetailComponent,
      },
      {
        path: 'statistical',
        component: StatisticalComponent,
      },
      {
        path: 'receipt-destroy',
        component: ReceiptDestroyComponent,
      },
      {
        path: 'receipt-destroy-detail',
        component: ReceiptDestroyDetailComponent,
      },
      {
        path: 'listpeopledes',
        component: ListPeopleDesComponent,
      },
      {
        path: 'change-pass',
        component: ChangePassComponent,
      },
      {
        path: 'plan-room',
        component: PlanRoomComponent,
      },
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'regulation-object',
        component: RegulationObjectComponent,
      },
      {
        path: 'invoince',
        component: InvoinceComponent,
      },
      {
        path: 'invoince-add',
        component: InvoiceAddComponent,
      },
      {
        path: 'search-log',
        component: SearchLogComponent,
      },
      {
        path: 'version-detail',
        component: VersionDetailComponent,
      },
      {
        path: 'de-xuat-sinh-vien',
        component: DeXuatSinhVienComponent,
      },
      {
        path: 'loai-de-xuat',
        component: LoaiDeXuatComponent,
      },
      {
        path: 'diem-sinh-vien',
        component: DiemSinhVienComponent,
      },
      {
        path: 'diem-phong',
        component: DiemPhongComponent,
      },
      {
        path: 'vi-pham',
        component: ViPhamComponent,
      },
      {
        path: 'lich-su-sua-chua',
        component: LichSuSuaChuaComponent,
      },
      {
        path: 'developer-sql-technical-only',
        component: DeveloperSQLComponent,
      },
      {
        path: 'hinhthuc',
        component: HinhThucComponent,
      },
      {
        path: 'khenthuongkyluat',
        component: KhenThuongKyLuatComponent,
      },
      {
        path: 'bien-dong-quy-dinh-thu',
        component: DangKyQuaTrinhComponent,
      },
      {
        path: 'don-gia-dien-nuoc',
        component: DonGiaDienNuocComponent,
      },
      {
        path: 'quan-tri-email',
        component: QuanTriEmailComponent,
      },
      {
        path: 'lich-su-email',
        component: LichSuEmailComponent,
      },
      {
        path: 'template-email',
        component: TemplateEmailComponent,
      },
      {
        path: 'tien-ich',
        component: TienIchComponent,
      },
      {
        path: 'integrated-statistics',
        component: IntegratedStatisticsComponent,
      },
      {
        path: 'document',
        component: DocumentComponent,
      },
    ],
  },
  {
    path: '',
    component: PortalComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'thong-tin-sv',
        component: ThongTinSinhVienComponent,
      },

      {
        path: 'dang-ky',
        component: PortalDangKyTotalPageComponent,
      },

      {
        path: 'portal-change-pass',
        component: PortalChangePassComponent,
      },
      {
        path: 'de-xuat',
        component: DeXuatComponent,
      },
      {
        path: 'cap-nhat-thong-tin',
        component: PortalCapNhatThongTinComponent,
      },
    ],
  },

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'dang-nhap',
    component: DangNhapComponent,
    canActivate: [NonAuthGuard],
  },

  {
    path: 'xac-nhan',
    component: XacNhanComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'xac-nhan-sau-dai-hoc',
    component: XacNhanSauDaiHocComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserModule, HttpClientModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

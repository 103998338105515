<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Biên lai hủy</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body cus-fs">
  <div class="card">
    <div class="card-body">
      <div class="row" style="margin-top: 5px;">
        <div class="col-md-8">
          <label>Nội dung</label>
          <input
            type="text"
            class="form-control cus-fs"
            [(ngModel)]="FillNoiDung"
            (keyup)="filterTable()"
            placeholder="Nhập nội dung ..."
          />
        </div>
        <div class="col-md-4">
          <label>Ngày thu </label>
          <span>
            <input
              type="date"
              [(ngModel)]="FillNgayThu"
              (change)="filterTable()"
              class="form-control cus-fs"
            />
          </span>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-md-4">
          <label>Số phiếu</label>
          <input
            type="text"
            class="form-control cus-fs"
            [(ngModel)]="FillSoPhieu"
            (keyup)="filterTable()"
            placeholder="Nhập số phiếu ..."
          />
        </div>
        <div class="col-md-4">
          <label>Tên sinh viên</label>
          <input
            type="text"
            class="form-control cus-fs"
            [(ngModel)]="FillHoTen"
            (keyup)="filterTable()"
            placeholder="Nhập tên sinh viên..."
          />
        </div>
        <div class="col-md-4">
          <label>Số phòng</label>
          <input
            type="text"
            class="form-control cus-fs"
            [(ngModel)]="FillPhong"
            (keyup)="filterTable()"
            placeholder="Nhập số phòng ..."
          />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div
              class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
            >
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable">
                    <td>{{ p.ID_bien_lai }}</td>
                    <td>{{ p.So_phieu }}</td>
                    <td>{{ p.Noi_dung }}</td>
                    <td>{{ p.Ly_Do }}</td>
                    <td>{{ p.Ten_sv }}</td>
                    <td>{{ p.Ten_phong }}</td>
                    <td>{{ p.Ngay_thu | date: 'dd-MM-yyyy' }}</td>
                    <td>{{ p.Nguoi_thu }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-warning"
                        style="margin-right: 7px;"
                        [routerLink]="['/admin/receipt-destroy-detail']"
                        [queryParams]="{ ID_bien_lai: p.ID_bien_lai }"
                      >
                        <i class="far fa-eye" ngbTooltip="Xem"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ActionModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Action">
      <div class="row">
        <div class="col-md-4 form-group">
          <label class="control-label">
            Tên sinh viên
          </label>
          <input
            formControlName="Ten_sv"
            class="form-control"
            placeholder="Tên sinh viên"
            type="text"
          />
        </div>
        <div class="col-md-4 form-group">
          <label class="control-label">
            Mã sinh viên
          </label>
          <input
            formControlName="Ma_sv"
            class="form-control"
            placeholder="Nhập mã sinh viên"
            type="text"
          />
        </div>
        <div class="col-md-4 form-group">
          <label class="control-label require">Số biên lai</label>
          <input
            class="form-control"
            required
            placeholder="Nhập Số biên lai"
            type="text"
            formControlName="So_phieu"
            id="So_phieu"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.So_phieu.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.So_phieu.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.So_phieu.errors.required">
              Số biên lai không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.So_phieu.errors.maxlength">
              Số biên lai tối đa 20 ký tự!
            </div>
          </div>
        </div>

        <div class="col-md-4 form-group">
          <label class="control-label">Phòng</label>
          <select class="form-control" formControlName="ID_phong">
            <option
              *ngFor="let item of dataRooms"
              value="{{ item.ID_Phong }}"
              >{{ item.Ten_phong }}</option
            >
          </select>
        </div>

        <div class="col-md-4 form-group">
          <label class="control-label">
            Ngày thu
          </label>
          <input class="form-control" formControlName="Ngay_thu" type="date" />
        </div>
        <div class="col-md-4 form-group">
          <label class="control-label">
            Người thu
          </label>
          <input class="form-control" formControlName="Nguoi_thu" type="text" />
        </div>
        <div class="col-md-12 form-group">
          <label class="control-label require">Nội dung biên lai</label>
          <textarea
            class="form-control"
            required
            placeholder="Nhập Nội dung biên lai"
            type="text"
            formControlName="Noi_dung"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Noi_dung.errors
            }"
          ></textarea>
          <div
            *ngIf="submitted && checkvalue.Noi_dung.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Noi_dung.errors.required">
              Nội dung biên lai không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Noi_dung.errors.maxlength">
              Nội dung biên lai tối đa 255 ký tự!
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div
        class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
      >
        <table datatable class="table-bordered dataTables_wrapper no-footer">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of dataDetailReceipt">
              <td>{{ p.Noi_dung }}</td>
              <td>{{ p.Ten_Khoan_thu }}</td>
              <td>{{ p.So_tien }}</td>
              <td>{{ p.Phan_tram_mien_giam }}</td>
              <td>{{ p.So_tien_mien_giam }}</td>
              <td>{{ p.Thanh_tien }}</td>
              <td>
                <button
                  class="btn btn-sm btn-warning"
                  (click)="open(ActionModal, 'xl', 'Edit', p)"
                  style="margin-right: 7px;"
                >
                  <i class="fas fa-edit" title="Sửa biên lai"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onSubmit()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { DashBoardService } from 'src/app/utils/services/dash-board.service';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import * as CanvasJS from './canvasjs.min';
import * as Canvas from './canvasjs.min.js';
import { Title } from '@angular/platform-browser';
import { StudentSortService } from 'src/app/utils/services/student-sort.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  data: any;
  Token: any;
  dataChart: any = [];
  chart: any;
  SinhVienDangO: any = 0;
  QuyDinhKhoanNopChuaNop: any = 0;
  DangKyChuaDuyet: any = 0;
  DeXuatTrongNgay: any = 0;
  DeXuatChuaDuyet: any = 0;
  DeXuatChuaHoanThanh: any = 0;
  SinhVienChuaXepPhong: any = 0;
  TongSucChua: any = 0;
  SoChoTrong: any = 0;

  PieChart = [];
  BD1: any = 1;
  BD2: any = 1;
  data_thoihoc: any = [];
  data_khoaHoc: any;
  khoaSlected: any;

  public com: common;
  constructor(
    private DashBoardService: DashBoardService,
    public router: Router,
    private titleService: Title,
    public Service: StudentSortService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.getList();
    this.getListKhoaHoc();
    this.titleService.setTitle('Trang chủ | UniDormitory');
  }

  getListKhoaHoc() {
    this.DashBoardService.getListKhoaHoc(this.Token).subscribe((res) => {
      this.data_khoaHoc = res.Data;
    });
  }

  async getDataDashboard() {
    this.spinner.show();
    this.DashBoardService.getList(
      this.com.getUserinfo().Info.UserID,
      this.khoaSlected,
      this.Token
    ).subscribe((z) => {
      this.spinner.hide();
      this.data = z.Data;
      this.SinhVienDangO = this.data.SinhVienDangO;
      this.QuyDinhKhoanNopChuaNop = this.data.QuyDinhKhoanNopChuaNop;
      this.DangKyChuaDuyet = this.data.DangKyChuaDuyet;
      this.DeXuatTrongNgay = this.data.DeXuatTrongNgay;
      this.DeXuatChuaDuyet = this.data.DeXuatChuaDuyet;
      this.DeXuatChuaHoanThanh = this.data.DeXuatChuaHoanThanh;
      this.data_thoihoc = this.data.DashBoard_ThoiHocNgungHoc;
      this.TongSucChua = this.data.TongSucChua;
      this.SoChoTrong = this.data.SoChoTrong;
      this.renderChart1();
      this.renderChart2();
    });
  }

  renderChart1() {
    let chart = new CanvasJS.Chart('chartContainer', {
      theme: 'light1',
      animationEnabled: true,
      title: {
        text: 'Giới tính',
        fontFamily: 'sans-serif',

        fontSize: 20,
      },
      data: [
        {
          type: 'pie',
          showInLegend: true,
          toolTipContent: '<b>{name}</b>: {y} (#percent%)',
          indexLabel: '{name}: {y} người - (#percent%)',
          dataPoints: [
            { y: this.data.Nam, name: 'Nam' },
            { y: this.data.Nu, name: 'Nữ' },
          ],
        },
      ],
    });
    chart.render();
    let chart3 = new CanvasJS.Chart('chartContainer3', {
      theme: 'light1',
      animationEnabled: true,
      title: {
        text: 'Biểu đồ thống kê số lượng sinh viên theo khoa',
        fontFamily: 'sans-serif',

        fontSize: 20,
      },
      data: [
        {
          type: 'pie',
          showInLegend: true,
          toolTipContent: '<b>Khoa {name}</b>: {y} sinh viên ~ (#percent%)',
          // indexLabel: '{name}: {y} sinh viên - (#percent%)',
          dataPoints: [...this.data.Data_SinhVien_Khoa],
        },
      ],
    });
    chart3.render();
    // if (this.BD1 == 2) {
    //   let chart = new CanvasJS.Chart('chartContainer', {
    //     theme: 'light1',
    //     animationEnabled: true,
    //     title: {
    //       text: 'Đối tượng nội trú',
    //       fontFamily: 'sans-serif',

    //       fontSize: 20,
    //     },
    //     data: [
    //       {
    //         type: 'pie',
    //         showInLegend: true,
    //         toolTipContent: '<b>{name}</b>: {y} (#percent%)',
    //         indexLabel: '{name}: {y} người - (#percent%)',
    //         dataPoints: [
    //           { y: this.data.DoiTuongChinhSach, name: 'Đối tượng chính sách' },
    //           { y: this.data.DoiTuongKhac, name: 'Đối tượng khác' },
    //         ],
    //       },
    //     ],
    //   });
    //   chart.render();
    // } else {
    //   let chart = new CanvasJS.Chart('chartContainer', {
    //     theme: 'light1',
    //     animationEnabled: true,
    //     title: {
    //       text: 'Giới tính',
    //       fontFamily: 'sans-serif',

    //       fontSize: 20,
    //     },
    //     data: [
    //       {
    //         type: 'pie',
    //         showInLegend: true,
    //         toolTipContent: '<b>{name}</b>: {y} (#percent%)',
    //         indexLabel: '{name}: {y} người - (#percent%)',
    //         dataPoints: [
    //           { y: this.data.Nam, name: 'Nam' },
    //           { y: this.data.Nu, name: 'Nữ' },
    //         ],
    //       },
    //     ],
    //   });
    //   chart.render();
    // }
  }

  renderChart2() {
    var dataPoints1 = [];
    var dataPoints2 = [];
    var tittle = '';
    if (this.BD2 == 1) {
      tittle = 'Biểu đồ sinh viên theo giới tính - toà nhà';
      for (var i = 0; i < this.data.Data_GT_ToaNha.length; i++) {
        dataPoints1.push({
          label: this.data.Data_GT_ToaNha[i].Ten_cot,
          y: this.data.Data_GT_ToaNha[i].Nam,
        });
        dataPoints2.push({
          label: this.data.Data_GT_ToaNha[i].Ten_cot,
          y: this.data.Data_GT_ToaNha[i].Nu,
        });
      }
    }
    if (this.BD2 == 2) {
      tittle = 'Biểu đồ sinh viên theo giới tính - Hệ đào tạo';
      for (var i = 0; i < this.data.Data_GT_He.length; i++) {
        dataPoints1.push({
          label: this.data.Data_GT_He[i].Ten_cot,
          y: this.data.Data_GT_He[i].Nam,
        });
        dataPoints2.push({
          label: this.data.Data_GT_He[i].Ten_cot,
          y: this.data.Data_GT_He[i].Nu,
        });
      }
    }
    if (this.BD2 == 3) {
      tittle = 'Biểu đồ sinh viên theo giới tính - Khoa đào tạo';
      for (var i = 0; i < this.data.Data_GT_Khoa.length; i++) {
        dataPoints1.push({
          label: this.data.Data_GT_Khoa[i].Ten_cot,
          y: this.data.Data_GT_Khoa[i].Nam,
        });
        dataPoints2.push({
          label: this.data.Data_GT_Khoa[i].Ten_cot,
          y: this.data.Data_GT_Khoa[i].Nu,
        });
      }
    }
    var chart = new CanvasJS.Chart('chartContainer2', {
      animationEnabled: true,
      title: {
        text: tittle,
        fontFamily: 'sans-serif',
        fontweight: 'bold',
        fontSize: 20,
      },
      axisY: {
        title: 'Số sinh viên',
        titleFontColor: '#4F81BC',
        lineColor: '#4F81BC',
        labelFontColor: '#4F81BC',
        tickColor: '#4F81BC',
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: 'pointer',
        //itemclick: toggleDataSeries
      },
      data: [
        {
          type: 'column',
          name: 'Nam',
          legendText: 'Nam',
          showInLegend: true,
          dataPoints: dataPoints1,
        },
        {
          type: 'column',
          name: 'Nữ',
          legendText: 'Nữ',
          showInLegend: true,
          dataPoints: dataPoints2,
        },
      ],
    });
    chart.render();
  }

  getList() {
    this.getDataDashboard();
    this.Service.getCount(this.Token).subscribe((z) => {
      this.SinhVienChuaXepPhong = z.Tong_so_ban_ghi;
    });
  }

  Change_BD1() {
    this.getDataDashboard();
    this.renderChart1();
  }
  Change_BD2() {
    this.renderChart2();
  }
}

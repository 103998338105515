import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { common } from 'src/app/app.common';
import { ExportService } from 'src/app/utils/services/export.service';
import { SystemService } from 'src/app/utils/services/system.service';

@Component({
  selector: 'app-developer-sql',
  templateUrl: './developer-sql.component.html',
  styleUrls: ['./developer-sql.component.scss'],
})
export class DeveloperSQLComponent implements OnInit {
  Fillter: FormGroup;
  constructor(
    public Service: SystemService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public exportService: ExportService,
    public route: ActivatedRoute
  ) {}
  Message: any;
  Token: any;
  Code: any;
  IsValid: any = false;
  public com: common;
  ngOnInit(): void {
    this.Message = '';
    this.Fillter = new FormGroup({
      Password: new FormControl(null),
      QuerryString: new FormControl(null),
    });
    this.route.queryParams.subscribe((params) => {
      this.Code = params.Code;
      this.CheckCode(this.Code);
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
  }
  Execute() {
    this.spinner.show();
    this.Message = 'Processing...';
    let req = {
      Password: this.Fillter.value.Password,
      QuerryString: this.Fillter.value.QuerryString,
    };
    this.Service.ExecuteQuerryString(req, this.Token).subscribe(
      (z) => {
        this.spinner.hide();
        if (z.Status == 1) {
          this.Message = JSON.stringify(z.Content);
        } else {
          this.Message = z.Message;
          this.toastr.warning(z.Message, 'Querry with error');
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  ExportExcel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.spinner.show();
      this.Message = 'Processing...';
      let req = {
        Password: this.Fillter.value.Password,
        QuerryString: this.Fillter.value.QuerryString,
      };
      this.Service.ExecuteQuerryStringExport(req, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'SQLResult');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.Message = 'File đã được tải xuống';
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.Message = z.Message;
          this.spinner.hide();
        }
      });
    }
  }
  CheckCode(c) {
    var Code = 'a3ab939ec58f69bb7e85d59b340452476d8c34a5';
    if (Code == c) {
      this.IsValid = true;
    } else {
      this.router.navigate(['/admin']);
    }
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  NgModel,
} from '@angular/forms';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
import { BaseComponent } from 'src/app/components/base/base.component';
import { groupBy } from 'rxjs/operators';
@Component({
  selector: 'app-lich-su-sua-chua',
  templateUrl: './lich-su-sua-chua.component.html',
  styleUrls: ['./lich-su-sua-chua.component.scss'],
})
export class LichSuSuaChuaComponent extends BaseComponent implements OnInit {
  titleModal: any = '';
  isInsert: any;
  FillPhong: any = '';
  DataToaNha: any;
  show_search: any = true;
  List_phong_ban: any;
  Ma_kho_checkexist: any;
  ID_kho: any;
  FillToaNha: any;
  ID_sua_chua: any;
  DataRoom: any = [];
  DataThietBi: any = [];
  DataView: any = [];
  DataFilter: any = [];
  dataRoomView: any;
  FilterForm = new FormGroup({
    ID_phong_ft: new FormControl(null),
    Ten_nha_ft: new FormControl(null),
    Ten_thiet_bi_ft: new FormControl(null, [removeSpaces]),
    Tu_ngay_ft: new FormControl(null),
    Den_ngay_ft: new FormControl(null),
  });
  InsertForm = new FormGroup({
    ID_phong: new FormControl(null),
    Ten_toa_nha: new FormControl(null),
    ID_thiet_bi: new FormControl(null, [Validators.required]),
    Noi_dung: new FormControl(null, [Validators.required, removeSpaces]),
    So_luong: new FormControl(null),
    Nguoi_thuc_hien: new FormControl(null),
    Ngay_hoan_thanh: new FormControl(null),
    Create_date: new FormControl(null),
  });
  UpdateForm = new FormGroup({
    ID_sua_chua: new FormControl(null),
    ID_thiet_bi: new FormControl(null),
    Ten_thiet_bi: new FormControl(null),
    ID_phong: new FormControl(null),
    Ten_phong: new FormControl(null),
    Ten_toa_nha: new FormControl(null),
    Ghi_chu: new FormControl(null),
    So_luong: new FormControl(null),
    Noi_dung: new FormControl(null),
    Nguoi_thuc_hien: new FormControl(null),
    Ngay_hoan_thanh: new FormControl(null),
    Modify_user: new FormControl(null),
  });
  dtTrigger = new Subject();
  public com: common;
  ngOnInit(): void {
    this.getToken();
    this.getListRoom();
    this.getListBuilding();
    // this.getListTB();
    this.GetAll();
  }
  hidesearch() {
    if (this.show_search == true) {
      return (this.show_search = false);
    }
    if (this.show_search == false) {
      return (this.show_search = true);
    }
  }
  getListTB(ID_phong : any) {
    this.RoomService.PhongThietBiGetAll(ID_phong, this.Token).subscribe((z) => {
      this.DataThietBi = z.Data;
    })
  }

  getListTBInsert() {
    this.getListTB( this.InsertForm.value.ID_phong);
  }
 
  changeTB() {
    let a = this.InsertForm.value.ID_thiet_bi
    let b = this.DataThietBi.filter(x => x.ID_thiet_bi == a)
    this.InsertForm.get('So_luong').setValue(b[0].So_luong);
  }
  GetAll() {
    this.page = 1;
    this.spinner.show();

    this.LichSuSuaChuaService.GetAll(this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.DataFilter = z.Data;
      this.DataView = this.dataTable.filter(
        (x) =>
          this.pageSize * (this.page - 1) < x.STT &&
          x.STT <= this.pageSize * this.page
      );
      this.numberPage = this.createNumberPage(
        z.Data.length,
        this.pageSize
      ).numberPage;
      this.arrNumberPage_chil = this.createNumberPage(
        z.Data.length,
        this.pageSize
      ).arrNumberPage_chil;
      this.totalItem = z.Data.length;
      this.totalItemFilter = z.Data.length;
      this.spinner.hide();
    });
  }
  getListBuilding() {
    this.FillToaNha = '';
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data;
    });
  }
  ChangeToaNha() {
    let id_toa_nha = this.FilterForm.value.Ten_nha_ft;
    if (id_toa_nha != '') {
      this.dataRoomView = this.dataRoom.filter(
        (x) => x.ID_Toa_Nha == id_toa_nha
      );
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  ChangeToaNhaInsert() {
    let id_toa_nha = this.InsertForm.value.Ten_toa_nha;
    if (id_toa_nha != '') {
      this.dataRoomView = this.dataRoom.filter(
        (x) => x.ID_Toa_Nha == id_toa_nha
      );
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  getListRoom() {
    this.FillPhong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data;
      this.dataRoomView = z.Data;
      //this.Filter();
    });
  } // list phòng
  GetCount(req) {
    this.LichSuSuaChuaService.Count(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.totalItem = z.Message;
      }
    });
  }
  getListThietBi() {
    this.DeviceService.getList(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.DataThietBi = z.Data;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  openModal(content, sizm, category, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (category == 'Insert') {
      this.DataThietBi=[]
      this.titleModal = '  ';
      this.isInsert = true;
      this.InsertForm.setValue({
        ID_thiet_bi: '',
        ID_phong: '',
        Ten_toa_nha: '',
        Noi_dung: '',
        So_luong: '',
        Nguoi_thuc_hien: '',
        Ngay_hoan_thanh: '',
        Create_date: '',
      });
    }
    if (category == 'Update') {
      this.titleModal = 'Sửa thông tin';
      let Room = this.dataRoom.filter((x) => {
        return x.ID_Phong == Data.ID_phong;
      });
      
      this.getListTB( Data.ID_phong);
      this.UpdateForm.setValue({
        ID_sua_chua: Data.ID_sua_chua,
        ID_thiet_bi: Data.ID_thiet_bi,
        Ten_thiet_bi: Data.Ten_thiet_bi,
        ID_phong: Data.ID_phong,
        Ten_phong: Data.Ten_phong,
        Ten_toa_nha: Data.Ten_toa_nha,
        So_luong: Data.So_luong,
        Ghi_chu: "",
        Noi_dung: Data.Noi_dung,
        Nguoi_thuc_hien: Data.Nguoi_thuc_hien,
        Ngay_hoan_thanh: Data.Ngay_hoan_thanh==null?"":Data.Ngay_hoan_thanh.substr(0, 10),
        Modify_user: Data.Modify_user,
      });
    }
    if (category == 'Delete') {
      this.ID_sua_chua = Data.ID_sua_chua;
    }
  }

  changeRoomUpdate(){
    this.getListTB( this.UpdateForm.value.ID_phong);
  }
  changPage(event) {
    this.handlePageChange(event);
    //this.spinner.show();
    this.DataView = this.DataFilter.filter(
      (x) =>
        this.pageSize * (this.page - 1) < x.STT &&
        x.STT <= this.pageSize * this.page
    );
    // this.arrNumberPage_chil = this.createNumberPage(
    //   this.DataFilter.length,
    //   this.pageSize
    // ).arrNumberPage_chil;
  }

  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.GetAll();
  }
  get checkvalue() {
    return this.InsertForm.controls;
  }
  Delete() {
    let req = {
      ID_sua_chua: this.ID_sua_chua,
    };
    this.modalService.dismissAll();
    this.LichSuSuaChuaService.Delete(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
    },  
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  Insert() {
    this.submitted = true;
    if (this.InsertForm.invalid) {
      this.toastr.warning('Vui lòng nhập đủ các thông tin cần thiết');
      return false;
    }
    let req = {
      ID_phong: this.InsertForm.value.ID_phong,
      Ten_toa_nha: this.InsertForm.value.Ten_toa_nha,
      ID_thiet_bi: this.InsertForm.value.ID_thiet_bi,
      Noi_dung: this.InsertForm.value.Noi_dung,
      So_luong: this.InsertForm.value.So_luong,
      Nguoi_thuc_hien: this.InsertForm.value.Nguoi_thuc_hien,
      Ngay_hoan_thanh: this.InsertForm.value.Ngay_hoan_thanh,
    };
    if (this.isInsert == true) {
      this.LichSuSuaChuaService.Insert(req, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.GetAll();
          this.GetCount(req);
        } else {
          this.toastr.error(z.Message);
        }
      
      },  (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
      this.modalService.dismissAll();
    }
  }

  update() {
    let req = {
      ID_sua_chua: this.UpdateForm.value.ID_sua_chua,
      ID_thiet_bi: this.UpdateForm.value.ID_thiet_bi,
      So_luong: this.UpdateForm.value.So_luong,
      Noi_dung: this.UpdateForm.value.Noi_dung,
      ID_phong: this.UpdateForm.value.ID_phong,
      Ten_phong: this.UpdateForm.value.Ten_phong,
      Ten_toa_nha: this.UpdateForm.value.Ten_toa_nha,
      Nguoi_thuc_hien: this.UpdateForm.value.Nguoi_thuc_hien,
      Ngay_hoan_thanh: this.UpdateForm.value.Ngay_hoan_thanh,
      Modify_user: this.UpdateForm.value.Modify_user,
    };
    this.LichSuSuaChuaService.Update(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
      
    },  (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
    this.modalService.dismissAll();
  }

  Filter() {
    this.DataFilter = this.dataTable;
    if (this.FilterForm.value.Ten_nha_ft != '' && this.FilterForm.value.Ten_nha_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.ID_toa_nha == this.FilterForm.value.Ten_nha_ft);
    }
    if (this.FilterForm.value.ID_phong_ft != '' && this.FilterForm.value.ID_phong_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.ID_phong == this.FilterForm.value.ID_phong_ft);
    }
    if (this.FilterForm.value.Ten_thiet_bi_ft != '' && this.FilterForm.value.Ten_thiet_bi_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.Ten_thiet_bi == this.FilterForm.value.Ten_thiet_bi_ft);
    }

    if (this.FilterForm.value.Tu_ngay_ft != '' && this.FilterForm.value.Tu_ngay_ft != undefined) {
      if (this.FilterForm.value.Den_ngay_ft != '' && this.FilterForm.value.Den_ngay_ft != undefined) {
        if (this.FilterForm.value.Tu_ngay_ft > this.FilterForm.value.Den_ngay_ft) {
          this.toastr.warning('Từ ngày không lớn hơn đến ngày!');
        } else {
          this.DataFilter = this.DataFilter.filter((x) => x.Ngay_hoan_thanh == null ? "" : x.Ngay_hoan_thanh.substr(0, 10) >= this.FilterForm.value.Tu_ngay_ft && x.Ngay_hoan_thanh == null ? "" : x.Ngay_hoan_thanh.substr(0, 10) <= this.FilterForm.value.Den_ngay_ft);
        }
      }
    }

    this.page = 1;
    for (var i = 0; i < this.DataFilter.length; i++) {
      this.DataFilter[i].STT = i + 1;
    }
    this.totalItemFilter = this.DataFilter.length;
    this.DataView = this.DataFilter.filter(
      (x) =>
        x.STT > (this.page - 1) * this.pageSize &&
        x.STT <= this.page * this.pageSize
    );
    this.numberPage = this.createNumberPage(
      this.DataFilter.length,
      this.pageSize
    ).numberPage;
    this.arrNumberPage_chil = this.createNumberPage(
      this.DataFilter.length,
      this.pageSize
    ).arrNumberPage_chil;
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.LichSuSuaChuaService.Excel(a.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(
            z.FileData,
            'Lịch sử sửa chữa thiết bị'
          );
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
    
      },  (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
    }
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_LichSuSuaChua;
    window.open(urlHelp,'_blank');
  }
}

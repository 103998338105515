import { Injectable, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RegulationPaymentService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  MultipleInsert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'RegulationPayment/MultiInsert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getAll(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'RegulationPayment/getAll', {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  SystemParameterRP_List(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'RegulationPayment/SystemParameterRP_List', {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  getStudent(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'Register/Register_getConfirm', {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Delete(ID: any, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'RegulationPayment/Delete?ID_quy_dinh_khoan_nop=' + ID, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

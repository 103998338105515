<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Đối tượng chính sách</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(InsertModal, 'lg', 'Add', '')"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th class="text-center">Mã đối tượng chính sách</th>
                <th class="text-center">Tên đối tượng chính sách</th>
                <th class="text-center">Thao tác</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of dataTable
                      | paginate: { itemsPerPage: 10, currentPage: page }
                  "
                >
                  <td>{{ data.Ma_dt }}</td>
                  <td>{{ data.Ten_dt }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="open(DeleteModal, '', 'Delete', data)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="4" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
            <pagination-controls
              class="float-right"
              (pageChange)="page = $event"
              previousLabel="Trước"
              nextLabel="Sau"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #InsertModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Thêm đối tượng chính sách</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label class="col-md-10 require">Tên tham số</label>
        <ng-select [(ngModel)]="ID_doi_tuong">
          <!-- <option value="-1" selected> Là đối tượng chính sách bất kỳ</option> -->
          <ng-option *ngFor="let p of DSDoiTuong" [value]="p.ID_dt">{{
            p.Ten_dt
          }}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

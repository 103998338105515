import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QuanTriEmailService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/ListAll', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Count(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getLichSuEmail(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/LichSuEmail', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LichSuCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'QuanTriEmail/LichSuEmailCountFilter',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Resend(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/ResendEmail', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LichSuCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/LichSuEmailCount', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/Insert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID: any, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/Delete?ID=' + ID, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MacDinh(ID: any, Mac_dinh: boolean, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'QuanTriEmail/Change?ID=' +
          ID +
          '&Mac_dinh=' +
          Mac_dinh,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'QuanTriEmail/Update', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

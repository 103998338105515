import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject, from } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  AbstractControl,
} from '@angular/forms';
import { BaseComponent } from '../base/base.component';
@Component({
  selector: 'app-quy-dinh-khoan-thu',
  templateUrl: './quy-dinh-khoan-thu.component.html',
  styleUrls: ['./quy-dinh-khoan-thu.component.scss'],
})
export class QuyDinhKhoanThuComponent extends BaseComponent implements OnInit {
  ID_toa_nha_filter: string = '';
  ID_co_so_filter: string = '';
  ID_phong_filter: string = '';
  ID_loai_phong_filter: string = '';
  Tang_filter: string = '';
  Ma_sv_filter: string = '';
  Ho_ten_filter: string = '';
  ID_gioi_tinh_filter: string = '';
  Ngay_sinh_filter: string = '';
  Hoc_ky_filter: string = '';
  Nam_hoc_filter: string = '';
  ID_thu_chi_filter: string = '';
  Thang_filter: string = '';
  Nam_hoc_arr: any = [];
  dataSV: any = [];
  dataSV2: any = [];
  beforeFilter: any = [];
  dataCampus: any = [];
  dataBuilding: any = [];
  dataRoom = [];
  dataCampusAfter: any = [];
  dataBuildingAfter: any = [];
  dataRoomAfter: any = [];
  numberChecked: any;
  numberPage2: any;
  public arrNumberPage2_child: any;
  totalRecord2: any = 0;
  page_sv: any = 1;
  pageSize2: any = 10;
  Display: any = false;
  disable = false;
  Ngay_sinh: any;
  Insert = new FormGroup({
    Hoc_ky: new FormControl(1, [Validators.required]),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1),
      [Validators.required]
    ),
    Dot: new FormControl(1, [Validators.required, Validators.min(1)]),
    ID_thu_chi: new FormControl('', [Validators.required]),
    So_tien: new FormControl(0, [Validators.required, Validators.min(1)]),
    Ghi_chu: new FormControl(''),
    Thang: new FormControl(''),
    Co_so: new FormControl(''),
    Toa_nha: new FormControl(''),
    Phong: new FormControl(''),
  });
  Ho_ten: any = '';
  Ma_sv: any = '';
  ID_he: any = '';
  ID_khoa: any = '';
  Khoa_hoc: any = '';
  ID_thu_chi: any = '';
  ID_chuyen_nganh: any = '';
  dataLoaiKhoanThu: any = [];
  dataHe: any = [];
  dataKhoa: any = [];
  dataKhoaHoc: any = [];
  dataKhoaHoc2: any = [];
  dataChuyenNganh: any = [];
  dataChuyenNganh2: any = [];
  dataLop: any = [];
  dataLop2: any = [];
  ngOnInit(): void {
    if (new Date().getMonth() + 1 > 8) {
      this.Insert.get('Nam_hoc').setValue(
        new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
      );
    } else {
      this.Insert.get('Nam_hoc').setValue(
        new Date().getFullYear() - 1 + '-' + new Date().getFullYear()
      );
    }
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.getCount();
    this.getList();
    this.getListCampus();
    this.getLoaiKhoanThu();
    this.getListTypeRoom();
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  getLoaiKhoanThu() {
    this.QuyDinhKhoanThuService.getLoaiKhoanThu(this.Token).subscribe((z) => {
      this.dataLoaiKhoanThu = z.Data;
    });
  }
  getCount() {
    this.QuyDinhKhoanThuService.Count(this.Token).subscribe(
      (z) => {
        this.totalItem = z.Tong_so_ban_ghi;
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_toa_nha: this.ID_toa_nha_filter ? this.ID_toa_nha_filter : '',
      ID_co_so: this.ID_co_so_filter ? this.ID_co_so_filter : '',
      ID_phong: this.ID_phong_filter,
      Tang: this.Tang_filter.trim(),
      ID_loai_phong: this.ID_loai_phong_filter,
      Ma_sv: this.Ma_sv_filter.trim(),
      ID_gioi_tinh: this.ID_gioi_tinh_filter,
      Ngay_sinh: this.Ngay_sinh_filter,
      Hoc_ky: this.Hoc_ky_filter,
      Nam_hoc: this.Nam_hoc_filter,
      ID_thu_chi: this.ID_thu_chi_filter,
      Thang: this.Thang_filter,
    };
    this.QuyDinhKhoanThuService.getAll(dataFilter, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.QuyDinhKhoanThuService.CountFilter(
          dataFilter,
          this.Token
        ).subscribe(
          (z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            } else if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
              this.spinner.hide();
            }
          }
        );
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getCount();
    this.getList();
  }
  handlePageSizeChange2(event) {
    this.page_sv = 1;
    if (event.target.value == 'Tất cả') {
      this.pageSize2 = 100000;
    } else {
      this.pageSize2 = event.target.value;
    }
    this.numberPage2 = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).numberPage;
    this.arrNumberPage2_child = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).arrNumberPage_chil;
    this.changepage2(1);
  }
  refesh() {
    this.ID_toa_nha_filter = '';
    this.ID_co_so_filter = '';
    this.ID_phong_filter = '';
    this.ID_loai_phong_filter = '';
    this.Tang_filter = '';
    this.Ma_sv_filter = '';
    this.Ho_ten_filter = '';
    this.ID_gioi_tinh_filter = '';
    this.Ngay_sinh_filter = '';
    this.Hoc_ky_filter = '';
    this.Nam_hoc_filter = '';
    this.ID_thu_chi_filter = '';
    this.Thang_filter = '';

    this.getCount();
    this.getList();
  }
  next() {
    if (this.dataSV2.filter((x) => x.checked == true).length == 0) {
      this.toastr.warning('Bạn chưa chọn sinh viên');
      return false;
    }
    this.Display = true;
  }
  prev() {
    this.Display = false;
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_toa_nha: this.ID_toa_nha_filter ? this.ID_toa_nha_filter : '',
      ID_co_so: this.ID_co_so_filter ? this.ID_co_so_filter : '',
      ID_phong: this.ID_phong_filter,
      Tang: this.Tang_filter,
      ID_loai_phong: this.ID_loai_phong_filter,
      Ma_sv: this.Ma_sv_filter,
      ID_gioi_tinh: this.ID_gioi_tinh_filter,
      Ngay_sinh: this.Ngay_sinh_filter,
      Hoc_ky: this.Hoc_ky_filter,
      Nam_hoc: this.Nam_hoc_filter,
      ID_thu_chi: this.ID_thu_chi_filter,
      Thang: this.Thang_filter,
    };
    this.QuyDinhKhoanThuService.getAll(dataFilter, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  open(content, sizm, category, Data) {
    if (category == 'DeleteM') {
      this.numberChecked = this.dataTable.filter((x) => x.checked == true);
      if (this.numberChecked.length == 0) {
        this.toastr.warning('Bạn chưa chọn sinh viên!');
        return false;
      }
    }
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.dataPopup = Data;
  }
  openModal(content, sizm, category, Data) {
    this.Insert.get('Dot').setValue(1);
    this.Insert.get('Thang').setValue('');
    this.Insert.get('ID_thu_chi').setValue('');
    this.Insert.get('So_tien').setValue(0);
    this.Insert.get('Toa_nha').setValue('');
    this.Insert.get('Phong').setValue('');

    this.dataSV2 = [];
    this.Display = false;
    this.submitted = false;
    this.Ngay_sinh = '';
    this.Ma_sv = '';
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.dataPopup = Data;
    if (category == 'Insert') {
      this.page_sv = 1;
      this.CampusService.getList(this.Token).subscribe((z) => {
        this.dataCampus = z.Data;
        this.Insert.get('Co_so').setValue(z.Data[0].ID);
        this.BuildingService.getList(this.Token).subscribe((z) => {
          this.dataBuildingAfter = z.Data;
          this.dataBuilding = z.Data.filter(
            (x) => x.ID_CoSo == this.Insert.value.Co_so
          );
          this.RoomService.GetAll(this.Token).subscribe((z) => {
            this.dataRoomAfter = z.Data;
          });
        });
      });
      this.getHoSoSV();
      this.getHeKhoaKhoa();
    }
  }
  changeCampusInsert() {
    this.dataBuilding = this.dataBuildingAfter.filter(
      (x) => x.ID_CoSo == this.Insert.value.Co_so
    );
    this.Insert.get('Toa_nha').setValue(this.dataBuilding[0].ID_CoSo);
    this.dataRoom = this.dataRoomAfter.filter(
      (x) => x.ID_Toa_Nha == this.Insert.value.Toa_nha
    );
    this.Insert.get('Phong').setValue('');
  }
  changeBuildingInsert() {
    this.dataRoom = this.dataRoomAfter.filter(
      (x) => x.ID_Toa_Nha == this.Insert.value.Toa_nha
    );
    this.Insert.get('Phong').setValue('');
  }
  getHeKhoaKhoa() {
    this.spinner.show();
    let IDhe;
    let IDKhoahoc;
    this.QuyDinhKhoanThuService.getHe(this.Token).subscribe((z) => {
      this.dataHe = z;
      IDhe = z[0].ID_he;
      this.QuyDinhKhoanThuService.getKhoa(this.Token).subscribe((z) => {
        this.dataKhoa = z;
        this.QuyDinhKhoanThuService.getKhoaHoc(this.Token).subscribe((z) => {
          this.dataKhoaHoc = z.Data;
          this.dataKhoaHoc2 = this.dataKhoaHoc.filter((i) => i.ID_he == IDhe);
          this.dataKhoaHoc2 = this.dataKhoaHoc2.filter(
            (item, i, arr) =>
              arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
          );

          this.QuyDinhKhoanThuService.getLop(this.Token).subscribe((z) => {
            this.dataChuyenNganh = z;
            this.dataChuyenNganh2 = this.dataChuyenNganh;
            this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
              (i) => i.ID_he == IDhe
            );
            this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
              (item, i, arr) =>
                arr.findIndex(
                  (t) => t.ID_chuyen_nganh === item.ID_chuyen_nganh
                ) === i
            );
            this.dataLop = z;
            this.dataLop2 = this.dataLop.filter((i) => i.ID_he == IDhe);
          });
          this.spinner.hide();
        });
      });
    });
  }
  changeHe() {
    this.dataKhoaHoc2 = this.dataKhoaHoc.filter((i) => i.ID_he == this.ID_he);
    this.dataKhoaHoc2 = this.dataKhoaHoc2.filter(
      (item, i, arr) => arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
    );
    this.dataChuyenNganh2 = this.dataChuyenNganh.filter(
      (i) => i.ID_he == this.ID_he
    );
    this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
      (item, i, arr) =>
        arr.findIndex((t) => t.ID_chuyen_nganh === item.ID_chuyen_nganh) === i
    );
    this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
  }
  changeKhoa() {
    if (this.ID_khoa != '') {
      this.dataKhoaHoc2 = this.dataKhoaHoc.filter((i) => i.ID_he == this.ID_he);
      this.dataKhoaHoc2 = this.dataKhoaHoc2.filter(
        (i) => i.ID_khoa == this.ID_khoa
      );
      this.dataKhoaHoc2 = this.dataKhoaHoc2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh.filter(
        (i) => i.ID_he == this.ID_he
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (i) => i.ID_khoa == this.ID_khoa
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.ID_chuyen_nganh === item.ID_chuyen_nganh) === i
      );
      this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
      this.dataLop2 = this.dataLop2.filter((i) => i.ID_khoa == this.ID_khoa);
    } else {
      this.dataKhoaHoc2 = this.dataKhoaHoc.filter((i) => i.ID_he == this.ID_he);
      this.dataKhoaHoc2 = this.dataKhoaHoc2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh.filter(
        (i) => i.ID_he == this.ID_he
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.ID_chuyen_nganh === item.ID_chuyen_nganh) === i
      );
      this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
    }
  }
  changeKhoaHoc() {
    if (this.Khoa_hoc != '') {
      this.dataChuyenNganh2 = this.dataChuyenNganh.filter(
        (i) => i.ID_he == this.ID_he
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (i) => i.ID_khoa == this.ID_khoa
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (i) => i.Khoa_hoc == parseInt(this.Khoa_hoc)
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.ID_chuyen_nganh === item.ID_chuyen_nganh) === i
      );
      this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
      this.dataLop2 = this.dataLop2.filter((i) => i.ID_khoa == this.ID_khoa);
      this.dataLop2 = this.dataLop2.filter(
        (i) => i.Khoa_hoc == parseInt(this.Khoa_hoc)
      );
    } else {
      this.dataChuyenNganh2 = this.dataChuyenNganh.filter(
        (i) => i.ID_he == this.ID_he
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (i) => i.ID_khoa == this.ID_khoa
      );
      this.dataChuyenNganh2 = this.dataChuyenNganh2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.ID_chuyen_nganh === item.ID_chuyen_nganh) === i
      );
      this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
      this.dataLop2 = this.dataLop2.filter((i) => i.ID_khoa == this.ID_khoa);
    }
  }
  changeChuyenNganh() {
    if (this.ID_chuyen_nganh != '') {
      this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
      this.dataLop2 = this.dataLop2.filter((i) => i.ID_khoa == this.ID_khoa);
      this.dataLop2 = this.dataLop2.filter(
        (i) => i.Khoa_hoc == parseInt(this.Khoa_hoc)
      );
      this.dataLop2 = this.dataLop2.filter(
        (i) => i.ID_chuyen_nganh == this.ID_chuyen_nganh
      );
    } else {
      this.dataLop2 = this.dataLop.filter((i) => i.ID_he == this.ID_he);
      this.dataLop2 = this.dataLop2.filter((i) => i.ID_khoa == this.ID_khoa);
      this.dataLop2 = this.dataLop2.filter(
        (i) => i.Khoa_hoc == parseInt(this.Khoa_hoc)
      );
    }
  }
  getHoSoSV() {
    this.spinner.show();
    this.QuyDinhKhoanThuService.getListSV(this.Token).subscribe((z) => {
      this.dataSV = z.Data;
      this.totalRecord2 = z.Data.length;
      this.beforeFilter = z.Data;
      this.numberPage2 = this.createNumberPage(
        this.dataSV.length,
        this.pageSize2
      ).numberPage;
      this.arrNumberPage2_child = this.createNumberPage(
        this.dataSV.length,
        this.pageSize2
      ).arrNumberPage_chil;
      this.dataSV2 = z.Data.filter((x) => 0 < x.STT && x.STT <= this.pageSize2);
      this.spinner.hide();
    });
  }
  checkAllCheckBox2(ev) {
    this.dataSV2.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked2() {
    if (this.dataSV2 != undefined) return this.dataSV2.every((p) => p.checked);
  }
  changepage2(event: any) {
    this.spinner.show();
    if (event == 'pre') {
      this.page_sv = this.page_sv - 1;
    } else if (event == 'next') {
      this.page_sv = this.page_sv + 1;
    } else {
      this.page_sv = event;
      this.arrNumberPage2_child = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage2) {
          this.arrNumberPage2_child.push(i);
        }
      }
    }
    this.dataSV2 = this.dataSV.filter(
      (x) =>
        this.pageSize2 * (this.page_sv - 1) < x.STT &&
        x.STT <= this.pageSize2 * this.page_sv
    );

    this.spinner.hide();
  }
  filterModal() {
    this.dataSV = this.beforeFilter;
    if (this.ID_he != '') {
      this.dataSV = this.dataSV.filter((x) => x.ID_he == this.ID_he);
    }
    if (this.ID_khoa != '') {
      this.dataSV = this.dataSV.filter((x) => x.ID_khoa == this.ID_khoa);
    }
    if (this.Khoa_hoc != '') {
      this.dataSV = this.dataSV.filter((x) => x.Khoa_hoc == this.Khoa_hoc);
    }
    if (this.ID_chuyen_nganh != '') {
      this.dataSV = this.dataSV.filter(
        (x) => x.ID_chuyen_nganh == this.ID_chuyen_nganh
      );
    }

    if (this.Ma_sv != '') {
      let a = this.dataSV;
      let b = this.dataSV;
      if (a != []) {
        a = this.dataSV.filter((i) => i.Ma_sv.includes(this.Ma_sv.trim()));
      }
      if (b != []) {
        b = this.dataSV.filter((i) =>
          this.xoa_dau(i.Ho_ten.toLowerCase()).includes(
            this.xoa_dau(this.Ma_sv.trim().toLowerCase())
          )
        );
      }
      this.dataSV = [];
      if (a.length != 0) {
        a.forEach((i) => {
          this.dataSV.push(i);
        });
      }
      if (b.length != 0) {
        b.forEach((i) => {
          this.dataSV.push(i);
        });
      }
    }
    if (
      this.Ngay_sinh != undefined &&
      this.Ngay_sinh != null &&
      this.Ngay_sinh != ''
    ) {
      this.dataSV = this.dataSV.filter(
        (x) => x.Ngay_sinh.toString().substring(0, 10) == this.Ngay_sinh
      );
    }
    for (var j = 0; j < this.dataSV.length; j++) {
      this.dataSV[j].STT = j + 1;
    }
    this.totalRecord2 = this.dataSV.length;
    this.numberPage2 = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).numberPage;
    this.arrNumberPage2_child = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).arrNumberPage_chil;
    this.changepage2(1);
  }
  onSubmit() {}
  buttonMultiDelete() {
    this.spinner.show();
    let arr = [];
    for (var i = 0; i < this.numberChecked.length; i++) {
      let req = {
        Hoc_ky: this.numberChecked[i].Hoc_ky,
        Nam_hoc: this.numberChecked[i].Nam_hoc,
        Dot: this.numberChecked[i].Dot,
        ID_sv: this.numberChecked[i].ID_sv,
        ID_thu_chi: this.numberChecked[i].ID_thu_chi,
        ID_mon: this.numberChecked[i].ID_mon,
        Thang: this.numberChecked[i].Thang,
      };
      arr.push(req);
    }
    this.QuyDinhKhoanThuService.MultiDelete(arr, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );

    this.getCount();
    this.getList();
    this.modalService.dismissAll();
    this.spinner.hide();
  }
  changeRoom() {
    this.getListRoom(this.ID_toa_nha_filter);
  }
  Delete() {
    this.spinner.show();
    let req = {
      Hoc_ky: this.dataPopup.Hoc_ky,
      Nam_hoc: this.dataPopup.Nam_hoc,
      Dot: this.dataPopup.Dot,
      ID_sv: this.dataPopup.ID_sv,
      ID_thu_chi: this.dataPopup.ID_thu_chi,
      ID_mon: this.dataPopup.ID_mon,
      Thang: this.dataPopup.Thang,
    };
    this.QuyDinhKhoanThuService.Delete(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.getCount();
          this.getList();
        } else {
          this.toastr.error(z.Message);
        }
        this.modalService.dismissAll();

        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  changeKhoanThu() {
    let filter = this.dataLoaiKhoanThu.filter(
      (x) => x.ID_thu_chi == this.Insert.value.ID_thu_chi
    );
    if (
      filter[0].Khoan_tien_dien == true ||
      filter[0].Khoan_tien_nuoc == true
    ) {
      this.disable = false;
    } else {
      this.disable = true;
    }
  }
  save() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    if (!this.disable && this.Insert.value.Thang == '') {
      this.toastr.warning('Bạn chưa chọn tháng!');
      return false;
    } else if (!this.disable && this.Insert.value.Phong == '') {
      this.toastr.warning('Bạn chưa chọn phòng!');
      return false;
    }
    this.spinner.show();
    let arr = [];
    let dataChecked = this.dataSV2.filter((x) => x.checked == true);
    for (var i = 0; i < dataChecked.length; i++) {
      let req = {
        ID_sv: dataChecked[i].ID_sv,
        Hoc_ky: this.Insert.value.Hoc_ky,
        Nam_hoc: this.Insert.value.Nam_hoc,
        Dot: this.Insert.value.Dot,
        ID_thu_chi: this.Insert.value.ID_thu_chi,
        So_tien: this.Insert.value.So_tien,
        Ghi_chu: this.Insert.value.Ghi_chu,
        ID_lop: dataChecked[i].ID_lop,
        Thang: this.Insert.value.Thang == '' ? 0 : this.Insert.value.Thang,
        ID_phong: this.Insert.value.Phong == '' ? 0 : this.Insert.value.Phong,
      };
      arr.push(req);
    }
    this.QuyDinhKhoanThuService.MultiInsert(arr, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.getCount();
          this.getList();
        } else {
          this.toastr.error(z.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
    this.modalService.dismissAll();
    this.spinner.hide();
  }
}

import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService, ToastrComponentlessModule } from 'ngx-toastr';
import { RoomService } from 'src/app/utils/services/room.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { CampusService } from 'src/app/utils/services/campus.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { RoomcategoryService } from 'src/app/utils/services/room-category.service';
import { AbstractControl } from '@angular/forms';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { data } from 'jquery';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent implements OnInit {
  dtOptions: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  DSToaNha3: any;
  DSLoaiPhong: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  ID_phong: any;
  ID_co_so: any;
  ID_toa_nha: any;
  Ma_phong: any;
  Ten_phong: any;
  Loai_phong: any;
  ID_loai_phong: any;
  Khoa_phong: any;
  Tang: any;
  Suc_chua: any;
  Ghi_chu: any;
  ID_toa_nha_ft: any = '';
  Ten_phong_ft: any;
  gioi_tinh_ft: any = '';
  loai_phong_ft: any = '';
  ID_co_so_ft: any = '';
  Tang_ft: any = '';
  titleModal: string;
  default: any;
  DataSelect: any;
  Dang_o: any;
  filter: any = false;

  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách phòng...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Thu_tu: any;
  Insert = new FormGroup({
    ID_phong: new FormControl(null),
    ID_co_so: new FormControl('', [Validators.required]),
    ID_toa_nha: new FormControl('', [Validators.required]),
    Ma_phong: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    Ten_phong: new FormControl(null, [
      Validators.required,
      Validators.maxLength(10),
      removeSpaces,
    ]),
    Loai_phong: new FormControl(null),
    Tang: new FormControl(null, [Validators.maxLength(20), removeSpaces]),
    Suc_chua: new FormControl(null, [Validators.required]),
    Ghi_chu: new FormControl(null, [Validators.maxLength(500), removeSpaces]),
    ID_loai_phong: new FormControl(null, [Validators.required]),
    Khoa_phong: new FormControl(null),
    Bao_phong: new FormControl(null),
    Thu_tu: new FormControl(null),
  });

  CheckEdit: boolean = false;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private RoomService: RoomService,
    private CampusService: CampusService,
    private BuildingService: BuildingService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RoomcategoryService: RoomcategoryService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let ID_toa_nha_dt = data[3];
      let Ten_phong_dt = data[5];
      let gioi_tinh_dt = data[7];
      let ID_co_so_dt = data[2];
      let Tang_dt = data[6];
      let Loai_phong_dt = data[15];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;
      let fillter = true;
      if (
        this.ID_toa_nha_ft != undefined &&
        this.ID_toa_nha_ft != '' &&
        this.ID_toa_nha_ft != null
      ) {
        var a = ID_toa_nha_dt;
        var b = this.ID_toa_nha_ft;
        bool1 = a == b;
      }
      if (
        this.Tang_ft != undefined &&
        this.Tang_ft != null &&
        this.Tang_ft != ''
      ) {
        var a = Tang_dt;
        var b = this.Tang_ft;
        bool5 = a == b;
      }
      if (
        this.Ten_phong_ft != undefined &&
        this.Ten_phong_ft != '' &&
        this.Ten_phong_ft != null
      ) {
        var a = Ten_phong_dt;
        var b = this.Ten_phong_ft;
        bool2 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.gioi_tinh_ft != '' && this.gioi_tinh_ft != undefined) {
        var a = gioi_tinh_dt;
        var b = this.gioi_tinh_ft;
        bool3 = a == b;
      }
      if (this.ID_co_so_ft != '' && this.ID_co_so_ft != undefined) {
        var a = ID_co_so_dt;
        var b = this.ID_co_so_ft;
        bool4 = parseInt(a) == parseInt(b);
      }
      if (this.loai_phong_ft != '' && this.loai_phong_ft != undefined) {
        var a = Loai_phong_dt;
        var b = this.loai_phong_ft;
        bool6 = a == b;
      }

      fillter = bool1 && bool2 && bool3 && bool4 && bool5 && bool6;

      return fillter;
    });

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          className: 'dt-center',
        },
        {
          title: 'ID phòng',
          visible: false,
        },
        {
          title: 'ID cơ sở',
          visible: false,
        },
        {
          title: 'ID toà nhà',
          visible: false,
        },
        {
          title: 'Mã phòng',
        },
        {
          title: 'Tên phòng',
        },
        {
          title: 'Tầng',
        },
        {
          title: 'Giới tính',
          visible: false,
        },
        {
          title: 'Giới tính',
        },
        {
          title: 'Loại phòng',
        },
        {
          title: 'Sức chứa',
        },
        {
          title: 'Đang ở',
        },
        {
          title: 'Giá tiền',
          className: 'dt-right',
        },

        {
          title: 'Khoá phòng',
          className: 'dt-center',
        },
        {
          title: 'Bao phòng',
          className: 'dt-center',
        },
        {
          title: 'ID_loai_phong',
          visible: false,
        },
        {
          title: 'Thứ tự',
        },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
      order: [[3, 'desc']],
    };

    this.getList(a.Token);

    this.CampusService.getList(a.Token).subscribe((z) => {
      this.DSCoSo = z.Data;
      this.BuildingService.getList(a.Token).subscribe((z) => {
        this.DSToaNha = z.Data;
        this.DSToaNha2 = z.Data;
        this.DSToaNha3 = z.Data;
      });
      this.RoomcategoryService.getList(1, a.Token).subscribe((z) => {
        this.DSLoaiPhong = z.Data;
      });
    });
  }
  ngAfterViewInit() {
    this.dataTable = [];
    this.dtTrigger.next();
  }
  locft() {
    var table = $('#maindata').DataTable();
    table.on('search.dt', function () {
      var tongsucchua = 0;
      var dasudung = 0;
      var controng = 0;
      var length = table.rows({ filter: 'applied' }).nodes().length;
      for (var i = 0; i < length; i++) {
        tongsucchua =
          tongsucchua +
          parseInt(table.rows({ filter: 'applied' }).data()[i][10]);
        dasudung =
          dasudung + parseInt(table.rows({ filter: 'applied' }).data()[i][11]);
        //controng = controng + parseInt(table.rows({ filter: 'applied' }).data()[i][11])
      }
      controng = tongsucchua - dasudung;
      $('#tongsucchua').val(tongsucchua);
      $('#svdango').val(dasudung);
    });
  }
  cosochange() {
    let ID_coso = this.Insert.value.ID_co_so;

    if (ID_coso != '') {
      this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
        return pa.ID_CoSo == ID_coso;
      });
    } else {
      this.DSToaNha2 = this.DSToaNha;
    }
    let ID_coso2 = this.ID_co_so_ft;
    if (ID_coso2 != '') {
      this.DSToaNha3 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
        return pa.ID_CoSo == ID_coso2;
      });
    } else {
      this.DSToaNha3 = this.DSToaNha;
    }
  }

  getList(token) {
    this.spinner.show();

    this.RoomService.GetAll(token).subscribe(
      (z) => {
        var tongsucchua = 0;
        var dasudung = 0;
        var controng = 0;
        for (var i = 0; i < z.Data.length; i++) {
          if (z.Data[i].Loai_phong == false) {
            z.Data[i].Loai_phong_text = 'Nam';
          } else {
            z.Data[i].Loai_phong_text = 'Nữ';
          }
          // if (z.Data[i].ID_Phong % 2 == 0) { z.Data[i].Loai_phong_kt = 'Loại 1'; z.Data[i].Ghi_chu = '350000' } else { z.Data[i].Loai_phong_kt = 'Loại 2'; z.Data[i].Ghi_chu = '240000' }
          dasudung = dasudung + z.Data[i].Dang_o;
          controng = controng + z.Data[i].Con_trong;
          tongsucchua = tongsucchua + z.Data[i].Suc_chua;
          $('#tongsucchua').val(tongsucchua);
          $('#svdango').val(dasudung);
        }
        this.dataTable = z.Data;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.dtTrigger.next();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 401) {
          this.dataTable = [];
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  //get
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.Insert.reset();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.Insert.reset();
        }
      );
    this.CheckEdit = false;
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa phòng';
      this.checkInsert = false;
      this.Insert.get('ID_co_so').setValue(Data.ID_Co_So);
      this.Insert.get('ID_toa_nha').setValue(Data.ID_Toa_Nha);
      this.Insert.get('Ma_phong').setValue(Data.Ma_phong);
      this.Insert.get('Ten_phong').setValue(Data.Ten_phong);
      this.Insert.get('Loai_phong').setValue(Data.Loai_phong);
      this.Insert.get('Tang').setValue(Data.Tang);
      this.Insert.get('Suc_chua').setValue(Data.Suc_chua);
      this.Insert.get('Ghi_chu').setValue(Data.Ghi_chu);
      this.Insert.get('Thu_tu').setValue(Data.Thu_tu);
      this.Insert.get('Bao_phong').setValue(Data.Bao_phong);
      this.Insert.get('Khoa_phong').setValue(Data.Khoa_phong);
      this.Insert.get('ID_loai_phong').setValue(Data.ID_loai_phong);
      this.check = Data.Ma_phong;
      this.ID_phong = Data.ID_Phong;
      this.ID_co_so = Data.ID_Co_So;
      let IDcs = Data.ID_Co_So;
      this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
        return pa.ID_CoSo == IDcs;
      });
      this.ID_toa_nha = Data.ID_Toa_Nha;
      this.Ma_phong = Data.Ma_phong;
      this.Ten_phong = Data.Ten_phong;
      this.Tang = Data.Tang;
      this.Loai_phong = Data.Loai_phong;
      this.Suc_chua = Data.Suc_chua;
      this.Ghi_chu = Data.Ghi_chu;
      this.Thu_tu = Data.Thu_tu;
      this.Dang_o = Data.Dang_o;
      this.ID_loai_phong = Data.ID_loai_phong;
      this.Insert.controls['Bao_phong'].enable({
        onlySelf: true,
        emitEvent: true,
      });
      if (this.Dang_o > 0) {
        this.Insert.controls['Bao_phong'].disable({
          onlySelf: true,
          emitEvent: false,
        });
        this.CheckEdit = true;
      }
    } else if (Loai == 'Add') {
      this.onReset();
      this.titleModal = 'Thêm mới phòng';
      this.checkInsert = true;
      this.Insert.reset();
      this.Insert.controls['Bao_phong'].enable({
        onlySelf: true,
        emitEvent: true,
      });
      // this.ID_co_so = this.DSCoSo[0].ID;
      let IDcs = this.DSCoSo[0].ID;
      this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
        return pa.ID_CoSo == IDcs;
      });
      //  this.ID_toa_nha = this.DSToaNha2[0].ID_ToaNha;
      this.Loai_phong = false;
      this.Insert.get('Bao_phong').setValue(false);
      this.Insert.get('Khoa_phong').setValue(false);
    } else if (Loai == 'Delete') {
      this.ID_phong = Data.ID_Phong;
    }
  }
  //check value
  get checkvalue() {
    return this.Insert.controls;
  }
  filterById() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
    $('#SelectALL').prop('checked', false);
    this.locft();
  }

  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
    this.ID_phong = '';
    this.ID_co_so = '';
    this.ID_toa_nha = '';
    this.Ma_phong = '';
    this.Ten_phong = '';
    this.Tang = '';
    this.Loai_phong = '';
    this.Suc_chua = '';
    this.Ghi_chu = '';
    this.Thu_tu = '';
  }
  // create
  create() {
    let InputDatas = {
      ID_Phong: this.ID_phong,
      ID_Co_So: this.Insert.value.ID_co_so,
      ID_Toa_Nha: this.Insert.value.ID_toa_nha,
      Ma_phong: this.Insert.value.Ma_phong,
      Ten_phong: this.Insert.value.Ten_phong,
      Loai_phong: this.Insert.value.Loai_phong,
      Tang: this.Insert.value.Tang,
      Suc_chua: this.Insert.value.Suc_chua,
      Ghi_chu: this.Insert.value.Ghi_chu,
      Thu_tu: this.Insert.value.Thu_tu,
      ID_loai_phong: this.Insert.value.ID_loai_phong,
      Khoa_phong: this.Insert.value.Khoa_phong,
      Bao_phong: this.Insert.value.Bao_phong,
    };
    let code = {
      Ma_phong: this.Insert.value.Ma_phong,
    };
    this.modalService.dismissAll('AddModal');

    if (this.checkInsert == false) {
      if (this.check == this.Insert.value.Ma_phong) {
        this.RoomService.Update(InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
          this.getList(this.Token);
        });
      } else {
        this.RoomService.CheckExist(code, this.Token).subscribe((z) => {
          if (z.Status == 9) {
            this.RoomService.Update(InputDatas, this.Token).subscribe((z) => {
              if (z.Status == 2) {
                this.toastr.error('Cập nhật thất bại!');
              } else if (z.Status == 1) {
                this.toastr.success('Cập nhật thành công!');
              } else {
                this.toastr.warning(z.Message);
              }
              this.modalService.dismissAll('AddModal');
              this.getList(this.Token);
            });
          } else if (z.Status == 8) {
            this.toastr.warning('Đã tồn tại mã phòng!');
          }
        });
      }
    } else {
      this.RoomService.CheckExist(code, this.Token).subscribe((z) => {
        if (z.Status == 9) {
          this.RoomService.Insert(InputDatas, this.Token).subscribe(
            (z) => {
              if (z.Status != 1) {
                this.toastr.warning(z.Message);
              } else if (z.Status == 1) {
                this.toastr.success('Thêm mới thành công!');
                this.modalService.dismissAll('AddModal');
                this.getList(this.Token);
              }
            },
            (err) => {
              this.spinner.hide();
              if (err.status == 0) {
                localStorage.removeItem('UserInfo');
                this.router.navigate(['/login']);
              } else if (err.status == 401) {
                this.toastr.warning(
                  'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                );
              }
            }
          );
        } else if (z.Status == 8) {
          this.toastr.warning('Đã tồn tại mã phòng!');
        }
      });
    }
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let Data = {
      ID_phong: this.ID_phong,
    };
    this.RoomService.Delete(Data, a.Token).subscribe((z) => {
      this.modalService.dismissAll('AddModal');
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.warning(z.Message);
      }
      this.getList(a.Token);
    });
  }
  SelectRow(ID_Phong) {
    for (let index = 0; index < this.dataTable.length; index++) {
      if (this.dataTable[index].ID_Phong == ID_Phong) {
        if ($('#checkbox' + ID_Phong).prop('checked')) {
          {
            this.dataTable[index].Selected = true;
          }
        } else {
          this.dataTable[index].Selected = false;
        }
        break;
      }
    }
  }
  chonall() {
    var table = $('#maindata').DataTable().rows({ filter: 'applied' }).data();
    var length = table.rows({ filter: 'applied' }).nodes().length;
    var lstID = [];
    for (var i = 0; i < length; i++) {
      var IDPhong = parseInt(table[i][1]);
      lstID.push(IDPhong);
    }
    for (let index = 0; index < this.dataTable.length; index++) {
      if (lstID.includes(this.dataTable[index].ID_Phong)) {
        this.dataTable[index].Selected = $('#SelectALL').prop('checked');
      }
    }
  }
  KhoaPhong() {
    var dt = jQuery.grep(this.dataTable, function (pa: any, i) {
      return pa.Selected;
    });
    var dtstring = this.GetStringID(dt);
    let data = {
      dsID: dtstring,
    };
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.RoomService.Lock(data, a.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.success(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.modalService.dismissAll('AddModal');
        this.getList(a.Token);
      } else {
        this.toastr.warning(z.Message);
      }
    });
  }
  GetStringID(m) {
    var rs = '';
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_Phong;
      } else {
        rs += ',' + m[k].ID_Phong;
      }
    }
    return rs;
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QLPhong;
    window.open(urlHelp, '_blank');
  }
}

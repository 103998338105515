import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roomtypes',
  templateUrl: './roomtypes.component.html',
  styleUrls: ['./roomtypes.component.scss']
})
export class RoomtypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ViPhamService {
  constructor(@Inject(AppConfig) private readonly appConfig: AppConfiguration, private router: Router, private http: HttpClient) { }
  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Building/BuildingListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  GetAll(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'ViPham/List_All', {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  
  Insert(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'ViPham/Add',req,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
    
  Update(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'ViPham/Update',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  UpdateStatus(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'ViPham/Update_Status',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  UnUpdateStatus(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'ViPham/Unupdate_Status', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'ViPham/Delete',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Excel(token): Observable<any> {
    return this.http
        .get<any>(this.appConfig.UniDormAPI + 'ViPham/Excel', {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }).pipe(map((z) => {return z;}));
  }
}
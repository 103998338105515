import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';

import { CollectionReceiptService } from 'src/app/utils/services/collection-receipt.service';
import { RoomService } from 'src/app/utils/services/room.service';
@Component({
  selector: 'app-collection-receipt',
  templateUrl: './collection-receipt.component.html',
  styleUrls: ['./collection-receipt.component.scss'],
})
export class CollectionReceiptComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Token: string;
  Res: any;
  req: any;
  check: any;
  LyDo: string = '';
  ID_ToaNha: any;
  dataTable: any = [];
  dataRooms: any = [];
  dataDetailReceipt: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  So_phieu: any;
  Noi_dung: any;
  address: any;
  titleModal: string;
  default: any;
  Id_bien_lai: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkAction: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Action = new FormGroup({
    So_phieu: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
    ]),
    Noi_dung: new FormControl(null, [
      Validators.required,
      Validators.maxLength(255),
    ]),
    ID_phong: new FormControl(null),
    Ten_sv: new FormControl(null),
    Ma_sv: new FormControl(null),
    Ngay_thu: new FormControl(null),
    Nguoi_thu: new FormControl(null),
    // address: new FormControl(null, [Validators.maxLength(255)]),
  });

  FillSoPhieu: any;
  FillNoiDung: any;
  FillDoiTuong: any = '';
  FillTrangThaiO: any = '';
  FillHoTen: any;
  FillPhong: any;
  FillNgayThu: any;
  FillThoiGianVisa: any;

  constructor(
    private modalService: NgbModal,
    private ReceiptService: CollectionReceiptService,
    private RoomService: RoomService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Số phiếu',
        },
        {
          title: 'Nội dung',
        },
        {
          title: 'Tên sinh viên',
        },
        {
          title: 'Phòng',
        },

        {
          title: 'Ngày thu',
        },
        {
          title: 'Người thu',
        },
        {
          title: 'Thao tác',
          className: 'dt-center',
        },
      ],
      order: [[0, 'desc']],
    };
    this.getList(a.Token);
  }
  getList(token) {
    this.spinner.show();
    this.ReceiptService.getList(token).subscribe((z) => {
      if (z.Status == 1) {
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.getListRoom(this.Token);
      } else {
        this.toastr.error(z.Message);
      }
      this.spinner.hide();
    });
  }
  //get list table all

  getListDetailReceipt(ID, ID_phong) {
    // this.spinner.show();
    this.ReceiptService.GetAllDetailreceipt(ID, ID_phong, this.Token).subscribe(
      (z) => {
        this.dataDetailReceipt = z.Data;
      }
    );
  }

  getListRoom(token) {
    // this.spinner.show();
    this.RoomService.GetAll(token).subscribe((z) => {
      this.dataRooms = z.Data;
    });
  }
  //get list table all

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa biên lai';
      this.check = Data.So_phieu;
      this.checkAction = true;
      this.Action.setValue({
        So_phieu: Data.So_phieu,
        Noi_dung: Data.Noi_dung,
        ID_phong: Data.ID_phong,
        Ten_sv: Data.Ten_sv,
        Ma_sv: Data.Ma_sv,
        Ngay_thu: Data.Ngay_thu.substring(0, 10),
        Nguoi_thu: Data.Nguoi_thu,
      });
      this.getListDetailReceipt(Data.ID_bien_lai, Data.ID_phong);
    } else {
      this.Id_bien_lai = Data.ID_bien_lai;
    }
  }
  //check value
  get checkvalue() {
    return this.Action.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Action.reset();
  }
  // create
  create() {
    let InputDatas = {
      ID_ToaNha: this.ID_ToaNha,
      So_phieu: this.Action.value.So_phieu,
      Noi_dung: this.Action.value.Noi_dung,
      ID_phong: this.Action.value.ID_phong,
    };
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    if (this.LyDo != '') {
      const dt = {
        ID_bien_lai: this.Id_bien_lai,
        Ly_Do: this.LyDo,
      };
      this.ReceiptService.Delete(dt, this.Token).subscribe((z) => {
        this.modalService.dismissAll('AddModal');
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList(this.Token);
      });
    } else {
      this.toastr.warning('Nhập lý do hủy biên lai');
    }
  }

  filterTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fill();
  }

  fill() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let bool1 = true,
        bool2 = true,
        bool3 = true,
        bool4 = true,
        bool5 = true;
      if (this.FillSoPhieu != undefined) {
        bool1 = data[1].toLowerCase().includes(this.FillSoPhieu.toLowerCase());
      }
      if (this.FillNoiDung != undefined) {
        bool2 = data[2].toLowerCase().includes(this.FillNoiDung.toLowerCase());
      }
      if (this.FillHoTen != undefined) {
        bool3 = data[3].toLowerCase().includes(this.FillHoTen.toLowerCase());
      }
      if (this.FillPhong != undefined) {
        bool4 = data[4].toLowerCase().includes(this.FillPhong.toLowerCase());
      }
      if (this.FillNgayThu != undefined) {
        const date1 = this.FillNgayThu.split('-').reverse().join('-');
        // const date2 = data[5].substring(3, 10);
        bool5 = data[5].toLowerCase().includes(date1.toLowerCase());
      }
      if (bool1 && bool2 && bool3 && bool4 && bool5) {
        return true;
      }
      return false;
    });
  }
}

import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CampusService } from 'src/app/utils/services/campus.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { AppConfig, AppConfiguration } from 'src/configuration';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-campus',
  templateUrl: './campus.component.html',
  styleUrls: ['./campus.component.scss'],
})
export class CampusComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  code: any;
  name: any;
  address: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải cơ sở...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Insert = new FormGroup({
    ID: new FormControl(null),
    code: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(255),
      removeSpaces,
    ]),
    address: new FormControl(null, [Validators.maxLength(255), removeSpaces]),
  });

  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private CampusService: CampusService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Mã cơ sở',
        },
        {
          title: 'Tên cơ sở',
        },
        {
          title: 'Địa chỉ',
        },
        {
          title: 'Thao tác',
          width: '75px',
        },
      ],
      order: [[0, 'desc']],
    };

    this.getList(a.Token);
  }

  getList(token) {
    this.spinner.show();
    this.CampusService.getList(token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.Title = '';
        this.TitleClass = '';
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  //get
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa cơ sở';
      this.checkInsert = true;
      this.Insert.get('ID').setValue(Data.ID);
      this.Insert.get('code').setValue(Data.code);
      this.Insert.get('name').setValue(Data.name);
      this.Insert.get('address').setValue(Data.address);
      this.check = Data.code;
      this.ID = Data.ID;
      this.code = Data.code;
      this.name = Data.name;
      this.address = Data.address;
    } else if (Loai == 'Add') {
      this.titleModal = 'Thêm mới cơ sở';
      this.checkInsert = false;
      this.Insert.reset();
    } else if (Loai == 'Delete') {
      this.ID = Data.ID;
    }
  }
  //check value
  get checkvalue() {
    return this.Insert.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
  }
  // create
  create() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let InputDatas = {
      ID: this.ID,
      code: this.code,
      name: this.name,
      address: this.address,
    };
    let code = $('#code').val();

    if (this.checkInsert == true) {
      if (this.check == $('#code').val()) {
        this.CampusService.Update(InputDatas, a.Token).subscribe(
          (z) => {
            if (z.Status == 2) {
              this.toastr.warning(z.Message);
            } else if (z.Status == 1) {
              this.toastr.success(z.Message);
            } else if (z.Status == 4) {
              this.toastr.error('Cập nhật thất bại!');
            }
            this.modalService.dismissAll('AddModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList(a.Token);
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            }
            if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          }
        );
      } else {
        this.CampusService.CheckExist(code, a.Token).subscribe(
          (z) => {
            if (z.Status == 9) {
              this.CampusService.Update(InputDatas, a.Token).subscribe(
                (z) => {
                  if (z.Status == 2) {
                    this.toastr.error('Cập nhật thất bại!');
                  } else if (z.Status == 1) {
                    this.toastr.success('Cập nhật thành công!');
                    this.modalService.dismissAll('AddModal');
                    this.datatableElement.dtInstance.then(
                      (dtInstance: DataTables.Api) => {
                        dtInstance.destroy();
                      }
                    );
                    this.getList(a.Token);
                  }
                },
                (err) => {
                  console.log(err);
                  this.spinner.hide();
                  if (err.status == 0) {
                    localStorage.removeItem('UserInfo');
                    this.router.navigate(['/login']);
                  }
                  if (err.status == 401) {
                    this.toastr.warning(
                      'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                    );
                  }
                }
              );
            } else if (z.Status == 8) {
              this.toastr.warning('Đã tồn tại mã cơ sở!');
            }
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            }
            if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          }
        );
      }
    } else {
      this.CampusService.CheckExist(code, a.Token).subscribe(
        (z) => {
          if (z.Status == 9) {
            this.CampusService.Insert(InputDatas, a.Token).subscribe(
              (z) => {
                if (z.Status == 2) {
                  this.toastr.error('Thêm mới thất bại!');
                } else if (z.Status == 1) {
                  this.toastr.success('Thêm mới thành công!');
                  this.modalService.dismissAll('AddModal');
                  this.datatableElement.dtInstance.then(
                    (dtInstance: DataTables.Api) => {
                      dtInstance.destroy();
                    }
                  );
                  this.getList(a.Token);
                }
              },
              (err) => {
                console.log(err);
                this.spinner.hide();
                if (err.status == 0) {
                  localStorage.removeItem('UserInfo');
                  this.router.navigate(['/login']);
                }
                if (err.status == 401) {
                  this.toastr.warning(
                    'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                  );
                }
              }
            );
          } else if (z.Status == 8) {
            this.toastr.warning('Đã tồn tại mã cơ sở!');
          }
        },
        (err) => {
          console.log(err);
          this.spinner.hide();
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let ID = this.ID;
    this.CampusService.CheckDelete(ID, a.Token).subscribe(
      (z) => {
        if (z.Status == 9) {
          this.CampusService.Delete(ID, a.Token).subscribe(
            (z) => {
              this.modalService.dismissAll('AddModal');
              if (z.Status == 2) {
                this.toastr.warning(z.Message);
              } else if (z.Status == 1) {
                this.toastr.success(z.Message);
              } else {
                this.toastr.error(z.Message);
              }
              this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                }
              );
              this.getList(a.Token);
            },
            (err) => {
              console.log(err);
              this.spinner.hide();
              if (err.status == 0) {
                localStorage.removeItem('UserInfo');
                this.router.navigate(['/login']);
              }
              if (err.status == 401) {
                this.toastr.warning(
                  'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                );
              }
            }
          );
        } else if (z.Status == 8) {
          this.toastr.warning(
            'Không thể xóa do đã tồn tại tòa nhà trong cơ sở!'
          );
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QLCoSo;
    window.open(urlHelp, '_blank');
  }
}

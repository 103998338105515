import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AccessHistoryService } from 'src/app/utils/services/accesshistory.service';
@Component({
  selector: 'app-access-history',
  templateUrl: './access-history.component.html',
  styleUrls: ['./access-history.component.scss'],
})
export class AccessHistoryComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dtOptions2: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  ID_ToaNha: any;
  Ma_sv_ft: any;
  Ten_sv_ft: any;
  Ten_phong_ft: any;
  dataTable: any = [];
  dataStudent: any = [];
  dataCampus: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  Ma_ToaNha: any;
  Ten_ToaNha: any;
  address: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  datatableElement2: DataTableDirective;
  checkAction: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Action = new FormGroup({
    Ma_ToaNha: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
    ]),
    Ten_ToaNha: new FormControl(null, [
      Validators.required,
      Validators.maxLength(255),
    ]),
    ID_CoSo: new FormControl(null),
    // address: new FormControl(null, [Validators.maxLength(255)]),
  });
  Fillter = new FormGroup({
    Ma_sv_ft: new FormControl(null),
    Ten_sv_ft: new FormControl(null),
    Ten_phong_ft: new FormControl(null),
  });
  constructor(
    private modalService: NgbModal,
    private AccessHistoryService: AccessHistoryService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    let element, name, arr;
    element = document.getElementById('menu1');
    arr = element.className.split(' ');
    name = 'menu-open';
    if (arr.indexOf(name) == -1) {
      element.className += ' ' + name;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID sinh viên',
          visible: false,
        },
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Tên sinh viên',
        },
        {
          title: 'Ngày sinh',
        },
        // {
        //   title: 'Tên phòng',
        // },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          text: ' <i class="far fa-file-excel"></i>&nbspXuất excel ',
          titleAttr: 'Xuất excel ',
          className: 'btn-sm',
          exportOptions: {
            columns: [0, 1, 2],
            orthogonal: 'export',
            className: 'btn  btn-success',
            format: {
              body: function (data, row, column, node) {
                return data;
              },
            },
          },
        },
      ],
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'Tên phòng',
        },
        {
          title: 'Ngày vào phòng',
        },
        {
          title: 'Ngày rời phòng',
        },
      ],
    };
    this.getList(a.Token);
  }

  fillTable() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_sv_dt = data[1];
      let Ten_sv_dt = data[2];
      let Ten_phong_dt = data[4];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let fillter = true;
      if (this.Ma_sv_ft != undefined) {
        let a = this.removeAccents(Ma_sv_dt);
        let b = this.removeAccents(this.Ma_sv_ft);
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Ten_sv_ft != undefined) {
        let a = this.removeAccents(Ten_sv_dt);
        let b = this.removeAccents(this.Ten_sv_ft);
        bool2 = a.toLowerCase().includes(b.toLowerCase());
      }

      fillter = bool1 && bool2;
      return fillter;
    });
  }

  getList(token) {
    // this.spinner.show();
    this.AccessHistoryService.Access_History(token).subscribe((z) => {
      this.dataTable = z.Data;
      this.dtTrigger.next();
      this.Title = '';
      this.TitleClass = '';
      // this.spinner.hide();
    });
  }
  filterById() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fillTable();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.dt2Trigger) {
      this.dt2Trigger.unsubscribe();
    }
    $.fn['dataTable'].ext.search.pop();
  }
  removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  open(content, sizm, Loai, Data) {
    // this.datatableElement.dtInstance.then(
    //   (dtInstance:any=$('#sinhviendt').DataTable() ) => {
    //     dtInstance.destroy();
    //   }
    // );
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'View') {
      // this.dataStudent = [];
      // var table = $('#sinhviendt').DataTable();
      // table.destroy();
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.reopen(Data, a);
    }
  }
  reopen(Data, a) {
    this.AccessHistoryService.Access_History_Detail(Data, a.Token).subscribe(
      (z) => {
        this.dataStudent = z.Data;
        //  this.dt2Trigger.next();
      }
    );
  }
}

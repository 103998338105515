import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeXuatSinhVienService } from 'src/app/utils/services/de-xuat-sinh-vien.service';
import { LoaiDeXuatService } from 'src/app/utils/services/loaidexuat.service';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-de-xuat',
  templateUrl: './de-xuat.component.html',
  styleUrls: ['./de-xuat.component.scss'],
})
export class DeXuatComponent implements OnInit {
  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  Token: string;
  dataTable: any = [];
  submitted = false;
  NameTitle: string;
  titleModal: string;
  closeResult: string;
  Data: any;
  check: any;
  DataLoaiDeXuat: any;
  public com: common;
  dtTrigger = new Subject();
  Insert = new FormGroup({
    Noi_dung: new FormControl(null, [
      Validators.required,
      Validators.maxLength(500),
      removeSpaces,
    ]),
    ID_loai_de_xuat: new FormControl(null, [Validators.required]),
  });
  constructor(
    private modalService: NgbModal,
    private DeXuatSinhVienService: DeXuatSinhVienService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LoaiDeXuatService: LoaiDeXuatService
  ) {}

  ngOnInit(): void {
    let element, name, arr;
    element = document.getElementById('menu1');
    arr = element.className.split(' ');
    name = 'menu-open';
    if (arr.indexOf(name) == -1) {
      element.className += ' ' + name;
    }
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    var a = this.com.getPortalInfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Nội dung đề xuất',
        },
        {
          title: 'Ngày đề xuất',
        },
        {
          title: 'Loại đề xuất',
          visible: false,
        },
        {
          title: 'Loại đề xuất',
        },
        {
          title: 'Nội dung phản hồi',
        },
        {
          title: 'Trạng thái hoàn thành',
        },
        {
          title: 'Thao tác',
          width: '75px',
        },
      ],
      order: [[0, 'desc']],
    };
    this.getList();
    this.getLoaiDeXuat();
  }
  getList() {
    this.spinner.show();
    let ID_sv = this.com.getPortalInfo().Info.UserID;
    this.DeXuatSinhVienService.getAllBySv(ID_sv, this.Token).subscribe((z) => {
      this.dataTable = z.Data;

      this.dtTrigger.next();
      this.spinner.hide();
    });
  }
  clear() {
    this.Insert.value.Noi_dung = '';
  }

  open(content, sizm, Loai, Data) {
    this.Data = Data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.check = true;
      this.titleModal = 'Sửa đề xuất';
      this.Insert.patchValue({
        Noi_dung: Data.Noi_dung,
        ID_loai_de_xuat: Data.ID_loai_de_xuat,
      });
    } else {
      this.check = false;
      this.titleModal = 'Thêm đề xuất';
      this.Insert.reset();
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  save() {
    if (this.check == false) {
      let req = {
        ID_sv: this.com.getPortalInfo().Info.UserID,
        Noi_dung: this.Insert.value.Noi_dung,
        ID_loai_de_xuat: this.Insert.value.ID_loai_de_xuat,
        Ngay_xu_ly: null,
      };
      this.DeXuatSinhVienService.Insert(req, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success('Thêm mới thành công!');
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
        }
      });
    } else {
      let req = {
        ID_de_xuat: this.Data.ID_de_xuat,
        Noi_dung: this.Insert.value.Noi_dung,
        ID_loai_de_xuat: this.Insert.value.ID_loai_de_xuat,
      };
      this.DeXuatSinhVienService.UpdateBySV(req, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success('Sửa thành công!');
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
        }
      });
    }
  }
  Delete() {
    this.DeXuatSinhVienService.Delete(
      this.Data.ID_de_xuat,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.error('Xoá thất bại!');
      } else if (z.Status == 1) {
        this.toastr.success('Xoá thành công');
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      }
    });
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  getLoaiDeXuat() {
    this.LoaiDeXuatService.getList(this.Token).subscribe((z) => {
      this.DataLoaiDeXuat = z.Data;
    });
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { InvoinceService } from 'src/app/utils/services/invoince.service';
import { formatDate } from '@angular/common';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-invoice-add',
  templateUrl: './invoice-add.component.html',
  styleUrls: ['./invoice-add.component.scss'],
})
export class InvoiceAddComponent implements OnInit {
  HoanTraData: any = [];
  SinhVienData: any = [];
  TongHopData: any = [];
  Nam_hoc_arr: any = [];
  inputData: any = [];
  KhoanThuData: any = [];
  dataSinhVien: any = [];
  submitted = false;
  public com: common;
  Token: string;
  default: any;
  date_now = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  Insert = new FormGroup({
    Hoc_ky: new FormControl(1),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
    ),
    Gian_thu: new FormControl(1),
    Lan_thu: new FormControl(1),
    Ngay_chi: new FormControl(this.date_now, [Validators.required]),
    So_phieu: new FormControl(null),
    Loai_tien: new FormControl('VND'),
    code: new FormControl('', [Validators.required]),

    Ho_ten: new FormControl(null),
    Lop: new FormControl(null),
    Noi_dung: new FormControl('Hoàn trả tiền phòng'),
  });
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public InvoinceService: InvoinceService
  ) {}

  ngOnInit(): void {
    if (new Date().getMonth() > 8) {
      this.Insert.get('Nam_hoc').setValue(
        new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
      );
    } else {
      this.Insert.get('Nam_hoc').setValue(
        new Date().getFullYear() - 1 + '-' + new Date().getFullYear()
      );
    }

    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
  }
  OnSearch($event) {
    this.InvoinceService.getSinhVien($event.term, this.Token).subscribe((z) => {
      this.dataSinhVien = z.Data;
    });
  }
  search() {
    this.InvoinceService.getChiTietSinhVien(
      this.Insert.value.code,
      this.Token
    ).subscribe((z) => {
      this.Insert.patchValue({ Ho_ten: z.SinhVienData.Ho_ten });
      this.Insert.patchValue({ Lop: z.SinhVienData.Ten_lop });
      this.Insert.patchValue({ So_phieu: z.SinhVienData.So_phieu });
      this.SinhVienData = z.SinhVienData;
      this.HoanTraData = z.HoanTraData;
      this.TongHopData = z.TongHopData;
    });
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  CheckAll() {
    if ($('#SelectALL').prop('checked')) {
      for (let index = 0; index < this.HoanTraData.length; index++) {
        this.HoanTraData[index].Selected = true;
      }
    } else {
      for (let index = 0; index < this.HoanTraData.length; index++) {
        this.HoanTraData[index].Selected = false;
      }
    }
  }
  selectHoanTraData(data, i) {
    for (let index = 0; index < this.HoanTraData.length; index++) {
      if (index == i) {
        if ($('#ID_' + i).prop('checked')) {
          {
            this.HoanTraData[index].Selected = true;
          }
        } else {
          this.HoanTraData[index].Selected = false;
        }
        break;
      }
    }
  }
  changeSinhVien() {
    this.InvoinceService.getThongTinKhoanChi(
      this.Insert.value.code,
      this.Token
    ).subscribe((z) => {
      this.SinhVienData = z.SinhVienData;
      this.HoanTraData = z.KhoanChiData;
      this.TongHopData = z.TongHopData;
      this.Insert.patchValue({ So_phieu: z.So_phieu });
    });
  }
  OnSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    var lst = jQuery.grep(this.HoanTraData, function (pa: any, i) {
      return pa.Selected;
    });

    if (lst.length < 1) {
      this.toastr.warning('Bạn chưa chọn khoản chi!');
    } else {
      var So_tien = 0;
      lst.forEach((value) => {
        So_tien += value.So_tien_tra;
      });
      let input = {
        lstKhoanHoanTra: lst,
        BienLaiData: {
          Hoc_ky: this.Insert.value.Hoc_ky,
          Nam_hoc: this.Insert.value.Nam_hoc,
          Lan_thu: this.Insert.value.Lan_thu,
          ID_sv: this.Insert.value.code,
          Thu_chi: false,
          ID_lop: 0,
          So_phieu: this.Insert.value.So_phieu,
          Ngay_thu: this.Insert.value.Ngay_chi,
          Noi_dung: this.Insert.value.Noi_dung,
          Ngoai_te: this.Insert.value.Loai_tien,
          So_tien: So_tien,
          Ghi_chu: '',
        },
      };
      this.InvoinceService.Insert(input, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success('Thêm thành công');
          this.search();
        } else {
          this.toastr.error('Thêm thất bại');
        }
      });
    }
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl } from '@angular/forms';
import { LoaiDeXuatService} from 'src/app/utils/services/loaidexuat.service';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-loai-de-xuat',
  templateUrl: './loai-de-xuat.component.html',
  styleUrls: ['./loai-de-xuat.component.scss']
})
export class LoaiDeXuatComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public LoaiDeXuat:LoaiDeXuatService
  ) { }
  dtOptions: DataTables.Settings;
  Token: string;
  dataTable: any = []
  closeResult: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  checkInsert: boolean
  submitted = false
  dtTrigger = new Subject();
  public com: common;
  titleModal:any
  IDRecord:any
  Insert = new FormGroup({
    Ten_loai_de_xuat: new FormControl(null, [Validators.required,Validators.maxLength(200),removeSpaces,]),
    Trang_thai: new FormControl(null, [Validators.required,]),
    Thu_tu: new FormControl(null, [Validators.required,]),
  });
  ngOnInit(): void {
    let element, name, arr;
    element = document.getElementById('menu1');
    arr = element.className.split(' ');
    name = 'menu-open';
    if (arr.indexOf(name) == -1) {
      element.className += ' ' + name;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'Tên loại đề xuất',
        },
        {
          title: 'Trạng thái',
        },
        {
          title: 'Thứ tự',
        },
        {
          title: 'Thao tác',
          width: '75px',
          className:'dt-center'
        },
      ],
    }
    this.getList()
  }
  getList(){
    this.spinner.show();
    this.LoaiDeXuat.getList(this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  open(content, sizm, Loai, Data) {
    this.IDRecord = Data.ID_loai_de_xuat
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.checkInsert= false
      this.titleModal = 'Sửa loại đề xuất';
      this.Insert.get('Ten_loai_de_xuat').setValue(Data.Ten_loai_de_xuat);
      this.Insert.get('Trang_thai').setValue(Data.Trang_thai);
      this.Insert.get('Thu_tu').setValue(Data.Thu_tu);
    } else if (Loai == 'Add') {
      this.Insert.reset();
      this.checkInsert= true
      this.titleModal = 'Thêm mới loại đề xuất';
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    let req = {
      ID_loai_de_xuat: this.IDRecord,
      Ten_loai_de_xuat: this.Insert.value.Ten_loai_de_xuat,
      Trang_thai: this.Insert.value.Trang_thai,
      Thu_tu: this.Insert.value.Thu_tu,
    }
    if(this.checkInsert){
      this.LoaiDeXuat.Insert(req,this.Token).subscribe((res)=>{
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Thêm mới thất bại!');
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getList();
      },
      (err) => {
         
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
    }else{
      this.LoaiDeXuat.Update(req,this.Token).subscribe((res)=>{
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Cập nhật thất bại!');
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getList();
      },
      (err) => {
         
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
    }
  }
  Delete(){
    this.LoaiDeXuat.Delete(this.IDRecord,this.Token).subscribe((res)=>{
      if (res.Status == 2) {
        this.toastr.warning(res.Message);
      } else if (res.Status == 1) {
        this.toastr.success(res.Message);
      } else if (res.Status == 4) {
        this.toastr.error('Xoá thất bại!');
      }
      this.modalService.dismissAll('');
      this.datatableElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        }
      );
      this.getList();
    },
    (err) => {
       
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    })
  }

}

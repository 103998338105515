import { Component, OnInit } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-integrated-statistics',
  templateUrl: './integrated-statistics.component.html',
  styleUrls: ['./integrated-statistics.component.scss'],
})
export class IntegratedStatisticsComponent extends BaseComponent
  implements OnInit {
  Ma_sv: any = '';
  Ho_ten: any = '';
  ID_khoa: any = 0;
  Khoa_hoc: any = 0;
  Nam_hoc: any = '';
  Trang_thai_hoc: any = -1;
  Tu_ngay: any;
  Den_ngay: any;
  ID_co_so: any = 0;
  ID_toa_nha: any = 0;
  Tang: any = '';
  ID_gioi_tinh: any = -1;

  DSKhoa: any;
  dataKhoaHoc: any;
  Token: string;
  dataKhoanNopKTX: any = [];
  dataRoom: any = [];
  Nam_arr: any = [];
  So_tien_con_phai_thu: any = 0;
  So_tien_con_phai_tra: any = 0;
  So_tien_da_nop: any = 0;
  So_tien_da_tra: any = 0;
  So_tien_phai_thu: any = 0;
  TatCa: string = '';
  Thieu_thua: any = 0;
  yeardate: any = [];
  public com: common;
  ngOnInit() {
    var yearNow = new Date().getFullYear();
    for (var i = 0; i < 9; i++) {
      let object = {
        value: yearNow,
        name: yearNow,
      };
      this.Nam_arr.push(object);
      yearNow--;
    }

    var Nam_hoc = new Date().getFullYear();
    for (var i = 0; i < 9; i++) {
      let object = {
        value: Nam_hoc + '-' + (Nam_hoc + 1),
        name: Nam_hoc,
      };
      this.yeardate.push(object);
      Nam_hoc--;
    }

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.Service.List_Khoa(this.Token).subscribe((z) => {
      this.DSKhoa = z;
    });
    this.StatisticalService.ThongKe_List_Count(this.Token).subscribe((z) => {
      this.totalItem = z.Total;
    });
    this.getKhoaHoc(1);
    this.getListCampus();
    this.getList();
  }
  getKhoaHoc(ID_he) {
    this.dataKhoaHoc = [];
    this.StatisticalService.LoadKhoaHoc(ID_he, this.Token).subscribe((z) => {
      let arr = [];
      z.Data.forEach((x) => {
        if (arr.indexOf(x.Khoa_hoc) < 0) {
          arr.push(x.Khoa_hoc);
        }
      });
      this.dataKhoaHoc = arr;
    });
  }
  ExportExcel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.spinner.show();
      this.page = 1;
      let dataFilter = {
        So_ban_ghi: 1000000000,
        Trang: 1,
        Ma_sv: this.Ma_sv.trim(),
        Ho_ten: this.Ho_ten.trim(),
        ID_khoa: this.ID_khoa,
        Khoa_hoc: this.Khoa_hoc,
        Nam_hoc: this.Nam_hoc,
        Trang_thai_hoc: this.Trang_thai_hoc,
        Tu_ngay: this.Tu_ngay,
        Den_ngay: this.Den_ngay,
        ID_co_so: this.ID_co_so,
        ID_toa_nha: this.ID_toa_nha,
        Tang: this.Tang,
        ID_gioi_tinh: this.ID_gioi_tinh,
      };
      this.StatisticalService.Export_BaoCaoThongKe(
        dataFilter,
        this.Token
      ).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'BaoCaoThongKe');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
          this.modalService.dismissAll('ExportExcel');
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      });
    }
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];

    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ma_sv: this.Ma_sv.trim(),
      Ho_ten: this.Ho_ten.trim(),
      ID_khoa: this.ID_khoa,
      Khoa_hoc: this.Khoa_hoc,
      Nam_hoc: this.Nam_hoc,
      Trang_thai_hoc: this.Trang_thai_hoc,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
      ID_co_so: this.ID_co_so,
      ID_toa_nha: this.ID_toa_nha,
      Tang: this.Tang,
      ID_gioi_tinh: this.ID_gioi_tinh,
    };
    this.StatisticalService.ThongKe_List(dataFilter, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.spinner.hide();
      }
    );
    this.StatisticalService.ThongKe_List_CountFilter(
      dataFilter,
      this.Token
    ).subscribe((z) => {
      this.totalItemFilter = z.Total;
      this.numberPage = this.createNumberPage(
        z.Total,
        this.pageSize
      ).numberPage;
      this.arrNumberPage_chil = this.createNumberPage(
        z.Total,
        this.pageSize
      ).arrNumberPage_chil;
      this.spinner.hide();
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.Ma_sv = '';
    this.Ho_ten = '';
    this.ID_khoa = 0;
    this.Khoa_hoc = 0;
    this.Nam_hoc = '';
    this.Trang_thai_hoc = -1;
    this.Tu_ngay;
    this.Den_ngay;
    this.ID_co_so = 0;
    this.ID_toa_nha = 0;
    this.Tang = '';
    this.ID_gioi_tinh = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ma_sv: this.Ma_sv.trim(),
      Ho_ten: this.Ho_ten.trim(),
      ID_khoa: this.ID_khoa,
      Khoa_hoc: this.Khoa_hoc,
      Nam_hoc: this.Nam_hoc,
      Trang_thai_hoc: this.Trang_thai_hoc,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
      ID_co_so: this.ID_co_so,
      ID_toa_nha: this.ID_toa_nha,
      Tang: this.Tang,
      ID_gioi_tinh: this.ID_gioi_tinh,
    };
    this.StatisticalService.ThongKe_List(dataFilter, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.spinner.hide();
      }
    );
  }
  open(content, sizm) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  changeCampus() {
    this.getListBuilding(this.ID_co_so);
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QDKhoanNop;
    window.open(urlHelp, '_blank');
  }
}

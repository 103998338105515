import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DeviceService } from 'src/app/utils/services/device.service';
import { RoomService } from 'src/app/utils/services/room.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { ExportService } from 'src/app/utils/services/export.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dtOptions2: any;
  Token: string;
  datadetail: any;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  code: any;
  name: any;
  unit: any;
  price: any;
  note: any;
  code_filter: any;
  name_filter: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách thiết bị...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Insert = new FormGroup({
    ID: new FormControl(null),
    code: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(70),
      removeSpaces,
    ]),
    unit: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    price: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      Validators.min(0),
    ]),
    note: new FormControl(null, [Validators.maxLength(500), removeSpaces]),
  });
  Fillter = new FormGroup({
    code_filter: new FormControl(null, [removeSpaces]),
    name_filter: new FormControl(null, [removeSpaces]),
  });
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private DeviceService: DeviceService,
    private RoomService: RoomService,
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public exportService: ExportService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Mã thiết bị',
        },
        {
          title: 'Tên thiết bị',
        },
        {
          title: 'Đơn vị',
        },
        {
          title: 'Đơn giá',
        },
        {
          title: 'Ghi chú',
        },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
      order: [[0, 'desc']],
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          width: '5%',
          className: 'dt-center',
        },
        {
          title: 'Mã phòng',
        },
        {
          title: 'Tên phòng',
        },
        {
          title: 'Tầng',
        },
        {
          title: 'Toà nhà',
        },
        {
          title: 'Cơ sở',
        },
        {
          title: 'Loại phòng',
        },
        {
          title: 'Sức chứa',
        },
        {
          title: 'Ghi chú',
        },
      ],
    };

    this.getList(a.Token);
    this.RoomService.GetAll(a.Token).subscribe((z) => {
      for (var i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Loai_phong == false) {
          z.Data[i].Loai_phong_text = 'Nam';
        } else {
          z.Data[i].Loai_phong_text = 'Nữ';
        }
      }
      this.datadetail = z.Data;
    });
  }
  ngAfterViewInit() {}
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let code_data = data[1];
      let name_data = data[2];
      let bool1 = true;
      let bool2 = true;
      if (this.code_filter != undefined) {
        var a = code_data;
        var b = this.code_filter;
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.name_filter != undefined) {
        var a = name_data;
        var b = this.name_filter;
        bool2 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (bool1 && bool2) {
        return true;
      }
      return false;
    });
  }
  getList(token) {
    this.spinner.show();
    this.DeviceService.getList(token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.Title = '';
        this.TitleClass = '';
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa thiết bị';
      this.checkInsert = true;
      this.check = Data.code;
      this.ID = Data.ID;
      this.code = Data.code;
      this.name = Data.name;
      this.unit = Data.unit;
      this.price = Data.price;
      this.note = Data.note;
    } else if (Loai == 'Add') {
      this.titleModal = 'Thêm mới thiết bị';
      this.checkInsert = false;
      this.Insert.reset();
    } else if (Loai == 'Delete') {
      this.ID = Data.ID;
    } else if (Loai == 'View') {
      this.ID = Data.ID;
      $('#soluongphat').val('');

      // this.getdeviceroom()
    }
  }
  getdeviceroom() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
  }
  //check value
  get checkvalue() {
    return this.Insert.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
  }
  // create
  create() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let InputDatas = {
      ID: this.ID,
      code: this.code,
      name: this.name,
      unit: this.unit,
      price: this.price,
      note: this.note,
    };
    let code = $('#code').val();
    if (this.checkInsert == true) {
      if (this.check == $('#code').val()) {
        this.DeviceService.Update(InputDatas, a.Token).subscribe(
          (z) => {
            if (z.Status == 2) {
              this.toastr.warning(z.Message);
            } else if (z.Status == 1) {
              this.toastr.success(z.Message);
            } else if (z.Status == 4) {
              this.toastr.error('Cập nhật thất bại!');
            }
            this.modalService.dismissAll('AddModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList(a.Token);
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            } else if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          }
        );
      } else {
        this.DeviceService.CheckExist(code, a.Token).subscribe((z) => {
          if (z.Status == 9) {
            this.DeviceService.Update(InputDatas, a.Token).subscribe(
              (z) => {
                if (z.Status == 2) {
                  this.toastr.error('Cập nhật thất bại!');
                } else if (z.Status == 1) {
                  this.toastr.success('Cập nhật thành công!');
                  this.modalService.dismissAll('AddModal');
                  this.datatableElement.dtInstance.then(
                    (dtInstance: DataTables.Api) => {
                      dtInstance.destroy();
                    }
                  );
                  this.getList(a.Token);
                }
              },
              (err) => {
                console.log(err);
                this.spinner.hide();
                if (err.status == 401) {
                  this.toastr.warning(
                    'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                  );
                }
              }
            );
          } else if (z.Status == 8) {
            this.toastr.warning('Đã tồn tại mã thiết bị!');
          }
        });
      }
    } else {
      this.DeviceService.CheckExist(code, a.Token).subscribe((z) => {
        if (z.Status == 9) {
          this.DeviceService.Insert(InputDatas, a.Token).subscribe(
            (z) => {
              if (z.Status == 2) {
                this.toastr.error('Thêm mới thất bại!');
              } else if (z.Status == 1) {
                this.toastr.success('Thêm mới thành công!');
                this.modalService.dismissAll('AddModal');
                this.datatableElement.dtInstance.then(
                  (dtInstance: DataTables.Api) => {
                    dtInstance.destroy();
                  }
                );
                this.getList(a.Token);
              }
            },
            (err) => {
              console.log(err);
              this.spinner.hide();
              if (err.status == 0) {
                localStorage.removeItem('UserInfo');
                this.router.navigate(['/login']);
              } else if (err.status == 401) {
                this.toastr.warning(
                  'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                );
              }
            }
          );
        } else if (z.Status == 8) {
          this.toastr.warning('Đã tồn tại mã thiết bị!');
        }
      });
    }
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let ID = this.ID;
    this.DeviceService.CheckDelete(ID, a.Token).subscribe((z) => {
      if (z.Status == 9) {
        this.DeviceService.Delete(ID, a.Token).subscribe(
          (z) => {
            this.modalService.dismissAll('AddModal');
            if (z.Status == 2) {
              this.toastr.warning(z.Message);
            } else if (z.Status == 1) {
              this.toastr.success(z.Message);
            } else {
              this.toastr.error(z.Message);
            }
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList(a.Token);
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            } else if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          }
        );
      } else if (z.Status == 8) {
        this.toastr.warning('Không thể xóa do thiết bị đã tồn tại trong phòng');
      }
    });
  }

  removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  CheckAll() {
    if ($('#SelectALL').prop('checked')) {
      // $('input[name ="SelectData"]').prop("checked",true)
      for (let index = 0; index < this.datadetail.length; index++) {
        this.datadetail[index].Selected = true;
      }
    } else {
      // $('input[name ="SelectData"]').prop("checked",false)
      for (let index = 0; index < this.datadetail.length; index++) {
        this.datadetail[index].Selected = false;
      }
    }
  }
  SelectRow(ID) {
    for (let index = 0; index < this.datadetail.length; index++) {
      if (this.datadetail[index].ID_Phong == ID) {
        if ($('#' + ID).prop('checked')) {
          {
            this.datadetail[index].Selected = true;
          }
        } else {
          this.datadetail[index].Selected = false;
        }
        break;
      }
    }
  }
  OnSubmit2() {
    if ($('#soluongphat').val() == '') {
      this.toastr.warning('Bạn chưa nhập số thiết bị mỗi phòng');
    } else {
      var lst = jQuery.grep(this.datadetail, function (pa: any, i) {
        return pa.Selected;
      });
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      let InputDatas = {
        ID_tb: this.ID,
        soluongtb: $('#soluongphat').val(),
        Data: lst,
      };
      this.DeviceService.InsertDeviceRoom(InputDatas, a.Token).subscribe(
        (z) => {
          this.modalService.dismissAll('AddModal');
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
        }
      );
    }
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.RoomService.Excel(a.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'Thietbitheophong');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      });
    }
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QuanLyThietBi;
    window.open(urlHelp, '_blank');
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RegisterDormService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Register/RegisterListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  cancelRegister(ID, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Register/HuyDangKy?UserID=' + ID, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Register/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Register/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListAll(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Register/ListAll', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListSVQuaHanDongTien(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Register/Register_LoadSVNhapHoc', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  SinhVienCheckValue(ID_sv:any,token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Register/SinhVienCheckValu/?ID_sv='+ID_sv, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getSVbyMa(Ma_sv: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/RegisterLoadSVByMa?Ma_sv=' +
          Ma_sv,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Register/RegisterAdd', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  InsertStudent(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Register/RegisterAddByStudent',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Import_Excel(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Register/ImportListRegister',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Delete(ID: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Register/RegisterDelete?ID_dang_ky=' + ID,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Register/RegisterUpdate', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Update_Student(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Register/RegisterUpdateStudent',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  UpdateStatus(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Register/RegisterUpdateStatus',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  UpdateStatus_ListID(data: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/RegisterUpdateStatus_ListID?lstIdDangKy=' +
          data.lstIdDangKy,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  UnApproveRegister(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Register/UnApproveRegister',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  UnApproveRegister_ListID(data: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/UnApproveRegister_ListID?lstIdDangKy=' +
          data.lstIdDangKy +
          '&Ly_do=' +
          data.Ly_do,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  RejectStatus_ListID(data: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/RegisterReject_ListID?lstIdDangKy=' +
          data.lstIdDangKy +
          '&Ly_do=' +
          data.Ly_do,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  RejectStatus(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Register/RegisterReject', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  List_He(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniSystemAPI + 'He/Danh_sach_he', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  List_Khoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniSystemAPI + 'Khoa/Danh_sach_khoa', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListStudentByCode(code, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Register/StudentListByCode?Ma=' + code,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListRegisterByID(id, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Register/RegisterListByID?ID=' + id,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListMonneyRoom(id, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/RegisterListMoneyRoom?ID_sv=' +
          id,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getFile(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Register/StudentReadFile', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ExcelDangKy(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Register/ExPortDanhSachO', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { Account } from 'src/app/utils/services/Account.service';
import { Console } from 'console';

@Component({
  selector: 'app-thong-tin-sinh-vien',
  templateUrl: './thong-tin-sinh-vien.component.html',
  styleUrls: ['./thong-tin-sinh-vien.component.scss'],
})
export class ThongTinSinhVienComponent implements OnInit {
  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  Token: string;
  dataTable: any = [];
  dataLoaiPhong: any[];
  dataSinhVienDangKy: any;
  submitted = false;
  NameTitle: string;
  titleModal: string;
  closeResult: string;
  Data: any;
  check: any;
  checker: boolean;
  DataLoaiDeXuat: any;
  public com: common;
  dtTrigger = new Subject();

  Insert = new FormGroup({
    Ghi_chu: new FormControl(''),
    Tinh_trang_suc_khoe: new FormControl(''),
    Nguyen_vong: new FormControl(''),
    ID_doi_tuong_TS: new FormControl(''),
    ID_khu_vuc_TS: new FormControl(''),
    Ma_sv: new FormControl(''),
    Ho_ten: new FormControl(''),
    Email: new FormControl(''),
    Ngay_sinh: new FormControl(''),
    Loai_phong: new FormControl(''),
    Ruler: new FormControl(false),
    Dienthoai_canhan: new FormControl(''),
    Ten_gioi_tinh: new FormControl(''),
    Dan_toc: new FormControl(''),
    Ton_giao: new FormControl(''),
    Ten_khoa_nhap_hoc: new FormControl(''),
    Khoa_hoc_nhap_hoc: new FormControl(''),
    Lop: new FormControl(''),
    Xa_phuong_tt: new FormControl(''),
    Huyen_tt: new FormControl(''),
    Tinh_tt: new FormControl(''),
    So_dien_thoai_bo: new FormControl(''),
    Ten_dt_chinh_sach: new FormControl(''),
  });
  checkThongTin: boolean;
  UserName_get: string;
  Ma_sv_get: string;
  Ten_phong: string;
  Ten_loai_phong: string;
  Tinh_trang_suc_khoe: string;
  Nguyen_vong: string;
  Ghi_chu: string;
  Trang_thai_duyet: number;
  Trang_thai_o: number;
  ID_dang_ky: number;
  FullName_get: string;
  dataSinhVien: any;
  Ten_phong_dang_ky: string = '';
  Ten_loai_phong_dang_ky: string = '';
  Trang_thai_duyet_dang_ky: any;
  Ten_trang_thai_o_dang_ky: string = '';
  Trang_thai_o_dang_ky: any;
  dataCheck: any;
  ID_sv: any;
  tokenEncr: any;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RegisterDorm: RegisterDormService,
    private RoomCategoryRegister: RoomCategoryRegisterService,
    private Account: Account
  ) {}

  ngOnInit(): void {
    this.checkThongTin = false;
    let element, name, arr;
    element = document.getElementById('menu1');
    arr = element.className.split(' ');
    name = 'menu-open';
    if (arr.indexOf(name) == -1) {
      element.className += ' ' + name;
    }
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    var a = this.com.getPortalInfo();
    this.Token = a.Token;
    this.ID_sv = a.Info.UserID;
    this.UserName_get = a.Info.UserName;
    this.Ma_sv_get = a.Info.UserName;
    this.FullName_get = a.Info.FullName;
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Nội dung đề xuất',
        },
        {
          title: 'Ngày đề xuất',
        },
        {
          title: 'Loại đề xuất',
          visible: false,
        },
        {
          title: 'Loại đề xuất',
        },
        {
          title: 'Nội dung phản hồi',
        },
      ],
      order: [[0, 'desc']],
    };
    this.GetSinhVienByMaSV();
    this.SinhVienCheck();
    this.AutoLogin();
  }
  GetSinhVienByMaSV() {
    this.spinner.show();
    this.RegisterDorm.getSVbyMa(this.Ma_sv_get, this.Token).subscribe((res) => {
      if (res.Status == 1) {
        this.dataSinhVien = res.Data;
        this.Insert.patchValue({
          Ma_sv: this.Ma_sv_get,
          Ho_ten: this.dataSinhVien[0].Ho_ten,
          Ngay_sinh: this.dataSinhVien[0].Ngay_sinh.substring(0, 10),
          Email: this.dataSinhVien[0].Email,
          ID_doi_tuong_TS: this.dataSinhVien[0].ID_doi_tuong_TS,
          ID_khu_vuc_TS: this.dataSinhVien[0].ID_khu_vuc_TS,
          Dienthoai_canhan: this.dataSinhVien[0].Dienthoai_canhan,
          Ten_gioi_tinh: this.dataSinhVien[0].Ten_gioi_tinh,
          Ten_khoa_nhap_hoc: this.dataSinhVien[0].Ten_khoa_nhap_hoc,
          Lop: this.dataSinhVien[0].Lop,
          Khoa_hoc_nhap_hoc: this.dataSinhVien[0].Khoa_hoc_nhap_hoc,
          So_dien_thoai_bo: this.dataSinhVien[0].So_dien_thoai_bo,
          Ten_dt_chinh_sach: this.dataSinhVien[0].Ten_dt_chinh_sach,
          Xa_phuong_tt: this.dataSinhVien[0].Xa_phuong_tt,
          Huyen_tt: this.dataSinhVien[0].Huyen_tt,
          Tinh_tt: this.dataSinhVien[0].Tinh_tt,
          Ton_giao: this.dataSinhVien[0].Ton_giao,
          Dan_toc: this.dataSinhVien[0].Dan_toc,
        });
        if (this.dataSinhVien.length > 0) {
          this.GetListLoaiPhong(
            (this.dataSinhVien[0].ID_gioi_tinh = null
              ? 0
              : this.dataSinhVien[0].ID_gioi_tinh),
            this.dataSinhVien[0].ID_sv
          );
        }
        if (this.Insert.value == '') {
          this.checker == true;
        }
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }
  SinhVienCheck() {
    this.RegisterDorm.SinhVienCheckValue(this.ID_sv, this.Token).subscribe(
      (z) => {
        z.CheckEd;
        if ((z.CheckEd = 1)) {
          this.checkThongTin == true;
        } else {
          this.checkThongTin = false;
        }
      }
    );
  }
  GetListLoaiPhong(ID_gioi_tinh: number, ID_sv: number) {
    this.spinner.show();
    this.RoomCategoryRegister.getList(
      ID_gioi_tinh,
      ID_sv,
      this.Token
    ).subscribe((res) => {
      if (res.Status == 1) {
        this.dataLoaiPhong = !res.Data ? [] : res.Data;
        // this.dataCheckTime = res.DataQuyDinhDoiTuong;
        this.GetDangKiByID_sv(this.dataSinhVien[0].ID_sv);
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }
  GetDangKiByID_sv(ID_sv: number) {
    this.spinner.show();
    this.RegisterDorm.getListRegisterByID(ID_sv, this.Token).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.dataSinhVienDangKy = res.Data;
          if (this.dataSinhVienDangKy.length > 0) {
            document.getElementById('__btnNotifi').click();
            // this.toastr.success(`Bạn đã đăng kí nội trú thành công lần mới nhất vào ngày ${this.dataSinhVienDangKy[0].Create_date.substring(0,10)} !`);
            this.Ten_phong_dang_ky = this.dataSinhVienDangKy[0].Ten_phong;
            this.Ten_loai_phong_dang_ky = this.dataSinhVienDangKy[0].Ten_loai_phong;
            this.Insert.patchValue({
              Tinh_trang_suc_khoe: this.dataSinhVienDangKy[0]
                .Tinh_trang_suc_khoe,
              Nguyen_vong: this.dataSinhVienDangKy[0].Nguyen_vong,
              Ghi_chu: this.dataSinhVienDangKy[0].Ghi_chu,
              // Loai_phong: this.dataRegisterStudent[0].ID_loai_phong,
              Ruler: true,
            });
            this.Trang_thai_duyet_dang_ky = this.dataSinhVienDangKy[0].Trang_thai_duyet;
            this.Ten_trang_thai_o_dang_ky = this.dataSinhVienDangKy[0].Ten_trang_thai_o;
            this.Trang_thai_o_dang_ky = this.dataSinhVienDangKy[0].Trang_thai_o;
            this.ID_dang_ky = this.dataSinhVienDangKy[0].ID_dang_ky;
            this.spinner.hide();
            //-------------------------Lấy ra danh sách khoản nộp----------------------------//
            // this.RegisterDorm.getListMonneyRoom(ID_sv, this.Token).subscribe(
            //   (res) => {
            //     if (res.Status == 1) {
            //       if (res.Data) {
            //         this.So_tien_phai_nop = this.dataKhoanNop[0].Sotienphainop;
            //         this.So_tien_cuoc = this.dataKhoanNop[0].Sotiencuoc;
            //         this.So_tien_da_nop = this.dataKhoanNop[0].Sotiendanop;
            //         this.So_tien_con_phai_dong = null
            //           ? this.dataKhoanNop[0].Sotienconphaidong
            //           : this.dataKhoanNop[0].Sotienconphaidong + this.dataKhoanNop[0].Sotiencuoc;
            //           this.spinner.hide();
            //       }
            //     }
            //     else {
            //       this.toastr.warning(res.Message);
            //       this.spinner.hide();
            //     }
            //   }
            // );
          } else {
            this.toastr.success('Bạn chưa đăng kí nội trú !');
          }
        } else {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        }
      }
    );
  }
  AutoLogin() {
    this.Account.AutoLogin(this.Ma_sv_get, this.Token).subscribe((z) => {
      z;
      this.tokenEncr = z.Token;
      console.log(z);
    });
  }
  get checkvalue() {
    return this.Insert.controls;
  }
}

<div class="row">
    <div class="col-4">
        <div style="height: 100%;" class="small-box bg-info">
          <div class="inner">
            <h3>{{TongDeXuat}}</h3>
            <p>Tổng số đề xuất</p>
          </div>
          <div class="icon">
            <i class="fas fa-users-class"></i>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div style="height: 100%;" class="small-box bg-info">
          <div class="inner">
            <h3>{{DaXuLy}}</h3>
            <p>Đề xuất đã xử lý</p>
          </div>
          <div class="icon">
            <i class="fas fa-user-check"></i>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div style="height: 100%;" class="small-box bg-info">
          <div class="inner">
            <h3>{{TonDong}}</h3>
            <p>Đề xuất tồn đọng</p>
          </div>
          <div class="icon">
            <i class="fas fa-user-times"></i>
          </div>
        </div>
      </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="table-responsive" style="max-height: 40vh; margin-bottom: 15px;">
            <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
        </div>
    </div>
    <div class="col-md-6">
            <div class="table-responsive" style="max-height: 40vh; margin-bottom: 15px;">
                <canvasjs-chart [options]="chartOptions1" [styles]="{width: '50%', height:'360px'}"></canvasjs-chart>
            </div>
    </div>
</div>
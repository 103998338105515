import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { SearchLogService } from 'src/app/utils/services/search-log.service';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-search-log',
  templateUrl: './search-log.component.html',
  styleUrls: ['./search-log.component.scss'],
})
export class SearchLogComponent implements OnInit {
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  ID_ToaNha: any;
  dataTable: any = [];
  dataCampus: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  Ma_ToaNha: any;
  Ten_ToaNha: any;
  address: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkAction: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  FillChucNang: string = '';
  FillThaoTac: number = 3;
  FillFromDate: any;
  FillToDate: any;
  constructor(
    private SearchLogService: SearchLogService,
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          text: ' <i class="far fa-file-excel"></i>&nbspXuất excel ',
          titleAttr: 'Xuất excel ',
          className: 'btn-sm',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5],
            orthogonal: 'export',
            className: 'btn  btn-success',
            format: {
              body: function (data, row, column, node) {
                return data;
              },
            },
          },
        },
      ],
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'Chức năng',
        },
        {
          title: 'Sự kiện',
        },
        {
          title: 'Nội dung',
          width: '50%',
        },
        {
          title: 'Máy trạm',
        },
        {
          title: 'Thời điểm',
        },
        {
          title: 'Người dùng',
          width: '10%',
        },
      ],
    };
  }

  ngAfterViewInit() {
    this.dataTable = [];
    this.dtTrigger.next();
  }
  getList() {
    this.spinner.show();
    this.SearchLogService.GetAll(
      this.FillChucNang,
      this.FillThaoTac,
      this.FillFromDate,
      this.FillToDate,
      this.Token
    ).subscribe(
      (z) => {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  //get list table all

  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }

  cleanDataTable() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }
}

import { Component, OnInit, Output, EventEmitter,Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import {BaseComponent} from 'src/app/components/base/base.component'
import { AppConfig, AppConfiguration } from 'src/configuration';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;

  constructor(
    private appService: AppService,
    @Inject(AppConfig) 
    public readonly appConfig: AppConfiguration,
    ) {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      search: new FormControl(null),
    });
  }

  logout() {
    this.appService.logout();
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl + "?" + this.appConfig.document_QuanLyViPham;
    window.open(urlHelp, '_blank');
  }
}

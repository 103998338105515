import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { CampusService } from 'src/app/utils/services/campus.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { AbstractControl } from '@angular/forms';
import { AppConfig, AppConfiguration } from 'src/configuration';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
})
export class BuildingComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Token: string;
  Res: any;
  req: any;
  check: any;
  ID_ToaNha: any;
  dataTable: any = [];
  dataCampus: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  Ma_ToaNha: any;
  Ten_ToaNha: any;
  address: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkAction: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Action = new FormGroup({
    Ma_ToaNha: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    Ten_ToaNha: new FormControl(null, [
      Validators.required,
      Validators.maxLength(255),
      removeSpaces,
    ]),
    ID_CoSo: new FormControl(null, [Validators.required]),
    // address: new FormControl(null, [Validators.maxLength(255)]),
  });

  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private BuildingService: BuildingService,
    private CampusService: CampusService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Mã tòa nhà',
        },
        {
          title: 'Tên tòa nhà',
        },
        {
          title: 'Cơ sở đào tạo',
        },
        {
          title: 'Thao tác',
          className: 'dt-center',
          width: '12%',
        },
      ],
      order: [[0, 'desc']],
    };
    this.getList(a.Token);
  }

  getList(token) {
    this.spinner.show();
    this.BuildingService.getList(token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.getListCampus(this.Token);
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }
  //get list table all

  getListCampus(token) {
    // this.spinner.show();
    this.CampusService.getList(token).subscribe((z) => {
      this.dataCampus = z.Data;
    });
  }
  //get list table all

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa tòa nhà';
      this.check = Data.Ma_ToaNha;
      this.checkAction = true;
      this.ID_ToaNha = Data.ID_ToaNha;
      this.Action.setValue({
        Ma_ToaNha: Data.Ma_ToaNha,
        Ten_ToaNha: Data.Ten_ToaNha,
        ID_CoSo: Data.ID_CoSo,
      });
    } else if (Loai == 'Add') {
      this.titleModal = 'Thêm mới tòa nhà';
      this.checkAction = false;
      this.Action.reset();
    } else if (Loai == 'Delete') {
      this.ID_ToaNha = Data.ID_ToaNha;
    }
  }
  //check value
  get checkvalue() {
    return this.Action.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Action.reset();
  }
  // create
  create() {
    let InputDatas = {
      ID_ToaNha: this.ID_ToaNha,
      Ma_ToaNha: this.Action.value.Ma_ToaNha,
      Ten_ToaNha: this.Action.value.Ten_ToaNha,
      ID_CoSo: this.Action.value.ID_CoSo,
    };
    if (this.checkAction == true) {
      if (this.check == this.Action.value.Ma_ToaNha) {
        this.BuildingService.Update(InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else if (z.Status == 5) {
            this.toastr.error(z.Message);
          }
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList(this.Token);
        });
      } else {
        if (
          this.dataTable.some((x) => {
            return x.Ma_ToaNha == this.Action.value.Ma_ToaNha;
          }) == false
        ) {
          this.BuildingService.Update(InputDatas, this.Token).subscribe((z) => {
            if (z.Status == 2) {
              this.toastr.error('Cập nhật thất bại!');
            } else if (z.Status == 9) {
              this.toastr.warning(z.Message);
            } else if (z.Status == 1) {
              this.toastr.success(z.Message);
              this.modalService.dismissAll('AddModal');
              this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                }
              );
              this.getList(this.Token);
            }
          });
        } else {
          this.toastr.warning('Mã đã tồn tại');
        }
      }
    } else {
      if (
        this.dataTable.some((x) => {
          return x.Ma_ToaNha == this.Action.value.Ma_ToaNha;
        }) == false
      ) {
        this.BuildingService.Insert(InputDatas, this.Token).subscribe(
          (z) => {
            if (z.Status == 2) {
              this.toastr.error('Thêm mới thất bại!');
            } else if (z.Status == 1) {
              this.toastr.success('Thêm mới thành công!');
              this.modalService.dismissAll('AddModal');
              this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                }
              );
              this.getList(this.Token);
            } else {
              this.toastr.success(z.Message);
            }
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            } else if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
              this.spinner.hide();
            }
          }
        );
      } else {
        this.toastr.warning('Đã tồn tại mã !');
      }
    }
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    let ID = this.ID_ToaNha;
    this.BuildingService.Delete(ID, this.Token).subscribe((z) => {
      this.modalService.dismissAll('AddModal');
      if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList(this.Token);
    });
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QLNha;
    window.open(urlHelp, '_blank');
  }
}

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Lịch sử sửa chữa</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          style="float: right; margin-right: 10px;"
          (click)="openModal(ThemModal, 'xl', 'Insert', '')"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="Excel()"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="hidesearch()">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <form class="form-horizontal" [formGroup]="FilterForm">
      <div class="card-body" *ngIf="show_search">
        <div class="row">
          <div class="col-md-4">
            <label>Tòa nhà</label>
            <select
              class="form-control cus-fs"
              formControlName="Ten_nha_ft"
              [(ngModel)]="FillToaNha"
              (change)="Filter()"
              (change)="ChangeToaNha()"
            >
              <option value="">Chọn tòa nhà</option>
              <option
                *ngFor="let i of dataBuilding"
                value="{{ i.ID_ToaNha }}"
                >{{ i.Ten_ToaNha }}</option
              >
            </select>
          </div>
          <div class="col-md-4">
            <label>Tên phòng</label>
            <select
              class="form-control cus-fs"
              formControlName="ID_phong_ft"
              [(ngModel)]="FillPhong"
              (change)="Filter()"
            >
              <option value="">Chọn phòng</option>
              <option
                *ngFor="let item of dataRoomView"
                value="{{ item.ID_Phong }}"
                >{{ item.Ten_phong }}</option
              >
            </select>
          </div>
          <div class="col-md-4">
            <label>Tên thiết bị</label>
            <input
              class="form-control cus-fs"
              type="text"
              formControlName="Ten_thiet_bi_ft"
              placeholder="Tìm tên thiết bị ..."
              (change)="Filter()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Từ ngày</label>
            <input
              class="form-control cus-fs"
              type="date"
              formControlName="Tu_ngay_ft"
              (change)="Filter()"
            />
          </div>
          <div class="col-md-4">
            <label>Đến ngày</label>
            <input
              class="form-control cus-fs"
              type="date"
              formControlName="Den_ngay_ft"
              (change)="Filter()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6"></div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>STT</th>
                <th>Nhà - phòng</th>
                <th>Mã thiết bị</th>
                <th>Tên thiết bị</th>
                <th>Số lượng</th>
                <th>Nội dung</th>
                <th>Ngày hoàn thành</th>
                <th>Người thực hiện</th>
                <th>Ngày cập nhật</th>
                <th>Tài khoản cập nhật</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of DataView; let i = index; let odd = odd">
                  <td>{{ p.STT }}</td>
                  <td>{{ p.Ten_toa_nha }}-{{ p.Ten_phong }}</td>
                  <td>{{ p.Ma_thiet_bi }}</td>
                  <td>{{ p.Ten_thiet_bi }}</td>
                  <td>{{ p.So_luong }}</td>
                  <td>{{ p.Noi_dung }}</td>
                  <td>{{ p.Ngay_hoan_thanh | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Nguoi_thuc_hien }}</td>
                  <td>{{ p.Modify_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Modify_user }}</td>
                  <td class="text-center" style="white-space: nowrap;">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="openModal(SuaModal, 'xl', 'Update', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="openModal(DeleteModal, '', 'Delete', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-trash-alt" title="Xoá"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="DataView.length == 0">
                  <td colspan="11" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="InsertForm">
      <div class="container" style="font-size: 14px;">
        <div class="cus-box">
          <div class="row">
            <div class="col-md-6">
              <label>Tòa nhà</label>
              <select
                class="form-control"
                formControlName="Ten_toa_nha"
                (change)="ChangeToaNhaInsert()"
              >
                <option value="">Chọn tòa nhà</option>
                <option
                  *ngFor="let i of dataBuilding"
                  value="{{ i.ID_ToaNha }}"
                  >{{ i.Ten_ToaNha }}</option
                >
              </select>
            </div>
            <div class="col-md-6">
              <label>Tên phòng</label>
              <select
                class="form-control"
                formControlName="ID_phong"
                (change)="getListTBInsert()"
              >
                <option value="">Chọn phòng</option>
                <option
                  *ngFor="let item of dataRoomView"
                  value="{{ item.ID_Phong }}"
                  >{{ item.Ten_phong }}
                </option>
              </select>
            </div>
          </div>
          <div class="row" style="margin-top: 8px;">
            <div class="col-md-6">
              <label class="col-md-10 require">Tên thiết bị</label>
              <select
                class="form-control"
                (change)="changeTB()"
                formControlName="ID_thiet_bi"
                [ngClass]="{
                  'is-invalid': submitted && checkvalue.ID_thiet_bi.errors
                }"
              >
                <option value="">Chọn thiết bị</option>
                <option
                  *ngFor="let p of DataThietBi"
                  value="{{ p.ID_thiet_bi }}"
                  >{{ p.Ten_thiet_bi }}</option
                >
              </select>
            </div>
            <div class="col-md-6">
              <label class="col-md-10">Số lượng</label>
              <input
                class="form-control"
                type="number"
                formControlName="So_luong"
              />
            </div>
          </div>
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-6">
              <label class="col-md-10">Người thực hiện</label>
              <input
                class="form-control cus-fs"
                type="text"
                formControlName="Nguoi_thuc_hien"
              />
            </div>
            <div class="col-md-6">
              <label class="col-md-10">Ngày hoàn thành</label>
              <input
                class="form-control"
                type="date"
                formControlName="Ngay_hoan_thanh"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="col-md-10 require">Ghi chú</label>
              <textarea
                class="form-control"
                type="text"
                formControlName="Noi_dung"
                placeholder="Nhập nội dung.."
                [ngClass]="{
                  'is-invalid': submitted && checkvalue.Noi_dung.errors
                }"
              ></textarea>
              <div
                *ngIf="submitted && checkvalue.Noi_dung.errors"
                class="invalid-feedback"
              >
                <div *ngIf="checkvalue.Noi_dung.errors.required">
                  Nội dung không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Noi_dung.errors.maxlength">
                  Nội dung không quá 500 ký tự
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="Insert()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>
<ng-template #SuaModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="UpdateForm">
      <div class="container" style="font-size: 14px;">
        <div class="cus-box">
          <div class="row">
            <div class="col-md-6">
              <label class="col-md-10">Toà nhà</label>
              <input
                class="form-control"
                type="text"
                disabled
                formControlName="Ten_toa_nha"
              />
            </div>
            <div class="col-md-6">
              <label>Tên phòng</label>
              <select class="form-control" formControlName="ID_phong" disabled>
                <option value="">Chọn phòng</option>
                <option
                  *ngFor="let item of dataRoomView"
                  value="{{ item.ID_Phong }}"
                  >{{ item.Ten_phong }}
                </option>
              </select>
            </div>
          </div>
          <div class="row" style="padding-top: 10px; margin-top: 8px;">
            <div class="col-md-6">
              <!-- <label class="col-md-10 require">Tên thiết bị</label>
              <input
                class="form-control"
                type="text"
                formControlName="Ten_thiet_bi"
              /> -->
              <label class="col-md-10 require">Tên thiết bị</label>
              <select
                class="form-control"
                (change)="changeTB()"
                formControlName="ID_thiet_bi"
                [ngClass]="{
                  'is-invalid': submitted && checkvalue.ID_thiet_bi.errors
                }"
              >
                <option value="">Chọn thiết bị</option>
                <option
                  *ngFor="let p of DataThietBi"
                  value="{{ p.ID_thiet_bi }}"
                  >{{ p.Ten_thiet_bi }}</option
                >
              </select>
            </div>
            <div class="col-md-6">
              <label class="col-md-10">Số lượng</label>
              <input
                class="form-control"
                type="text"
                formControlName="So_luong"
              />
            </div>
          </div>
          <div class="row" style="padding-top: 10px; margin-top: 8px;">
            <div class="col-md-6">
              <label class="col-md-10">Người thực hiện</label>
              <input
                class="form-control"
                type="text"
                formControlName="Nguoi_thuc_hien"
              />
            </div>
            <div class="col-md-6">
              <label class="col-md-10">Ngày hoàn thành</label>
              <input
                class="form-control"
                type="date"
                formControlName="Ngay_hoan_thanh"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Ghi chú</label>
              <textarea
                class="form-control"
                cols="100"
                rows="5"
                formControlName="Noi_dung"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="update()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn muốn xoá lịch sử sửa chữa này!
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Delete()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
<ng-container *ngIf="typeReceipt == 10;else tmlPrintClass">
  <ng-container [ngTemplateOutlet]="tmlPrintEW"></ng-container>
  </ng-container>
<ng-template #tmlPrintEW>
  <p>
    <strong>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;BỘ GI&Aacute;O DỤC V&Agrave; Đ&Agrave;O TẠO&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </strong>Số bi&ecirc;n lai : {{ dt_print.So_bl }}
  </p>
  <p>
    &nbsp; &nbsp;TRƯỜNG ĐH KINH DOANH &amp; C&Ocirc;NG NGHỆ H&Agrave; NỘI&nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    (<em>Li&ecirc;n1)&nbsp;&nbsp;&nbsp;&nbsp;
    </em>&nbsp;&nbsp;
  </p>
  <p>&nbsp;</p>
  <p style="text-align: center; font-size: 19px;">
    <strong>CHỈ SỐ TIÊU THỤ ĐIỆN-NƯỚC SINH HOẠT PHÒNG {{ dt_print.So_phong }}</strong>
  </p>
  <p style="text-align: center; font-size: 13px; font-style: italic;">
   <!-- (Tháng  ) -->
  </p>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Trưởng phòng : {{ dt_print.Ho_ten }}</span>
        <span id="HotenFee" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
      <td>
        <span style="margin-left: 75px;"> Mã SV : {{ dt_print.Ma_sv }}</span>
        <span id="PersonTV" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
    </tr>
  </table>
  <div class="row" style="justify-content: center;margin-top: 20px;">
    <table class="table table-bordered" style="width: 900px;">
      <thead>
        <tr>
          <th>STT</th>
          <th>Nội dung</th>
          <th>Chỉ số cũ</th>
          <th>Chỉ số mới</th>
          <th>Chỉ số sử dụng tháng</th>
          <th>Đơn giá</th>
          <th>Thành tiền</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of dt_print.Pament;index as count">
          <td>{{count + 1 }}</td>
          <td>{{p.Noi_dung }}</td>
          <td class="text-center">{{p.Chi_so_cu }}</td>
          <td class="text-center">{{p.Chi_so_moi }}</td>
          <td class="text-center">{{p.Chi_so_moi - p.Chi_so_cu }}</td>
          <td class="text-center">{{p.Don_gia | number}}</td>
          <td class="text-center">{{p.Thanh_tien | number}}</td>
        </tr>
        <tr>
          <td colspan="6">Tổng tiền </td>
          <td >
            <p style="float: right;font-weight: 700;">{{dt_money | number }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Viết bằng chữ :</span>
        <span style="font-weight: 700; margin-left: 20px;">{{
          money_words
        }}</span>
      </td>
    </tr>
  </table>
  <div class="row">
    <div class="col-md-7"></div>
    <div class="col-md-5">
      <span> Từ sơn , Ngày {{ date | date: 'dd' }} tháng {{date | date: 'MM'}} năm {{date | date: 'yyyy'}}</span>
    </div>
  </div>
  <div class="row" style="text-align: center;">
    <div class="col-md-12">
      <span style="text-align: center;">
        <strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Người nộp
          tiền&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Người
          thu tiền</strong>
      </span>
    </div>
    <div class="col-md-12">
      <span style="text-align: center;">
        <em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (K&yacute;, họ t&ecirc;n)&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(K&yacute;,
          họ t&ecirc;n)&nbsp;&nbsp;&nbsp;&nbsp;
        </em>
      </span>
    </div>
  
    <p style="text-align: center;"><strong>&nbsp;</strong></p>
    <p>&nbsp;</p>
  </div>
   <br>
   <br>
   <br>
   <br>
   <br>
  
  <p style="text-align: center; border-bottom: 1px solid #2f2d2d !important;">
    <strong>&nbsp; &nbsp; &nbsp; &nbsp; </strong>
  </p>
  
  <p>
    <strong>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;BỘ GI&Aacute;O DỤC V&Agrave; Đ&Agrave;O TẠO&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </strong>Số bi&ecirc;n lai : {{ dt_print.So_bl }}
  </p>
  <p>
    &nbsp; &nbsp;TRƯỜNG ĐH KINH DOANH &amp; C&Ocirc;NG NGHỆ H&Agrave; NỘI&nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    (<em>Li&ecirc;n2)&nbsp;&nbsp;&nbsp;&nbsp;
    </em>&nbsp;&nbsp;
  </p>
  <p>&nbsp;</p>
  <p style="text-align: center; font-size: 19px;">
    <strong>CHỈ SỐ TIÊU THỤ ĐIỆN-NƯỚC SINH HOẠT PHÒNG {{ dt_print.So_phong }}</strong>
  </p>
  <p style="text-align: center; font-size: 13px; font-style: italic;">
   <!-- (Tháng  ) -->
  </p>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Trưởng phòng : {{ dt_print.Ho_ten }}</span>
        <span id="HotenFee" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
      <td>
        <span style="margin-left: 75px;"> Mã SV : {{ dt_print.Ma_sv }}</span>
        <span id="PersonTV" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
    </tr>
  </table>
  <div class="row" style="justify-content: center;margin-top: 20px;">
    <table class="table table-bordered" style="width: 900px;">
      <thead>
        <tr>
          <th>STT</th>
          <th>Nội dung</th>
          <th>Chỉ số cũ</th>
          <th>Chỉ số mới</th>
          <th>Chỉ số sử dụng tháng</th>
          <th>Đơn giá</th>
          <th>Thành tiền</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of dt_print.Pament;index as count">
          <td>{{count + 1 }}</td>
          <td>{{p.Noi_dung }}</td>
          <td class="text-center">{{p.Chi_so_cu }}</td>
          <td class="text-center">{{p.Chi_so_moi }}</td>
          <td class="text-center">{{p.Chi_so_moi - p.Chi_so_cu }}</td>
          <td class="text-center">{{p.Don_gia | number}}</td>
          <td class="text-center">{{p.Thanh_tien | number}}</td>
        </tr>
        <tr>
          <td colspan="6">Tổng tiền </td>
          <td >
            <p style="float: right;font-weight: 700;">{{dt_money | number }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Viết bằng chữ :</span>
        <span style="font-weight: 700; margin-left: 20px;">{{
          money_words
        }}</span>
      </td>
    </tr>
  </table>
  <div class="row">
    <div class="col-md-7"></div>
    <div class="col-md-5">
      <span> Từ sơn , Ngày {{ date | date: 'dd' }} tháng {{date | date: 'MM'}} năm {{date | date: 'yyyy'}}</span>
    </div>
  </div>
  <div class="row" style="text-align: center;">
    <div class="col-md-12">
      <span style="text-align: center;">
        <strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Người nộp
          tiền&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Người
          thu tiền</strong>
      </span>
    </div>
    <div class="col-md-12">
      <span style="text-align: center;">
        <em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (K&yacute;, họ t&ecirc;n)&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(K&yacute;,
          họ t&ecirc;n)&nbsp;&nbsp;&nbsp;&nbsp;
        </em>
      </span>
    </div>
  
    <p style="text-align: center;"><strong>&nbsp;</strong></p>
    <p>&nbsp;</p>
  </div>
</ng-template>


<ng-template #tmlPrintClass>
  <p>
    <strong>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;BỘ GI&Aacute;O DỤC V&Agrave; Đ&Agrave;O TẠO&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </strong>Số bi&ecirc;n lai : {{ dt_print.So_bl }}
  </p>
  <p>
    &nbsp; &nbsp;TRƯỜNG ĐH KINH DOANH &amp; C&Ocirc;NG NGHỆ H&Agrave; NỘI&nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    (<em>Li&ecirc;n1)&nbsp;&nbsp;&nbsp;&nbsp;
    </em>&nbsp;&nbsp;
  </p>
  <p>&nbsp;</p>
  <p style="text-align: center; font-size: 19px;">
    <strong>BIÊN LAI THU TIỀN PHÒNG {{ dt_print.So_phong }}</strong>
  </p>
  <p style="text-align: center; font-size: 13px; font-style: italic;">
    ({{ date | date: 'dd/MM/yyyy' }})
  </p>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Họ tên : {{ dt_print.Ho_ten }}</span>
        <span id="HotenFee" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
      <td>
        <span style="margin-left: 75px;"> Mã SV : {{ dt_print.Ma_sv }}</span>
        <span id="PersonTV" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style="margin-left: 75px;"> Lớp : {{ studyClass }}</span>
        <span style="font-weight: 700; margin-left: 20px;"></span>
      </td>
      <td>
        <span style="margin-left: 75px;">
          Số phòng : {{ dt_print.So_phong }}</span>
        <span style="font-weight: 700; margin-left: 20px;"></span>
      </td>
    </tr>
  </table>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Số tiền nộp : </span>
        <span style="font-weight: 700; margin-left: 20px;">{{
          dt_money | number
        }} đ</span>
      </td>
    </tr>
  </table>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Viết bằng chữ :</span>
        <span style="font-weight: 700; margin-left: 20px;">{{
          money_words
        }}</span>
      </td>
    </tr>
  </table>
  <div style="width: 704px; margin-left: 78px;">
    Khoản nộp :
    <span *ngFor="let p of dt_payment">
      <span *ngIf="p.Hinh_thuc_thu == true">
        {{ p.Ten_khoan_thu }} {{ p.Thang }}/{{ p.Nam }} :
        {{ p.Thanh_tien | number }} đ,
      </span>
      <span *ngIf="p.Hinh_thuc_thu == false">
        {{ p.Ten_khoan_thu }} học kì {{ p.Hoc_ky }} năm {{ p.Nam_hoc }} :
        {{ p.Thanh_tien | number }} đ ,
      </span>
    </span>
  </div>
  <p style="text-align: center;">
    <strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Người nộp
      tiền&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Người
      thu tiền</strong>
  </p>
  <p style="text-align: center;">
    <em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (K&yacute;, họ t&ecirc;n)&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(K&yacute;,
      họ t&ecirc;n)&nbsp;&nbsp;&nbsp;&nbsp;
    </em>
  </p>
  <p style="text-align: center;"><strong>&nbsp;</strong></p>
  <p>&nbsp;</p>
  <br>
  <br>
  <br>
  <br>
  <br>
  <p style="text-align: center; border-bottom: 1px solid #2f2d2d !important;">
    <strong>&nbsp; &nbsp; &nbsp; &nbsp; </strong>
  </p>
  <p>
    <strong>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;BỘ GI&Aacute;O DỤC V&Agrave; Đ&Agrave;O TẠO&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </strong>Số bi&ecirc;n lai : {{ dt_print.So_bl }}
  </p>
  <p>
    &nbsp; &nbsp;TRƯỜNG ĐH KINH DOANH &amp; C&Ocirc;NG NGHỆ H&Agrave; NỘI&nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    (<em>Li&ecirc;n2)&nbsp;&nbsp;&nbsp;&nbsp;
    </em>&nbsp;&nbsp;
  </p>
  <p>&nbsp;</p>
  <p style="text-align: center; font-size: 19px;">
    <strong>BIÊN LAI THU TIỀN PHÒNG {{ dt_print.So_phong }}</strong>
  </p>
  <p style="text-align: center; font-size: 13px; font-style: italic;">
    ({{ date | date: 'dd/MM/yyyy' }})
  </p>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Họ tên : {{ dt_print.Ho_ten }}</span>
        <span id="HotenFee" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
      <td>
        <span style="margin-left: 75px;"> Mã SV : {{ dt_print.Ma_sv }}</span>
        <span id="PersonTV" style="font-weight: 700; margin-left: 20px;"></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style="margin-left: 75px;"> Lớp : {{ studyClass }}</span>
        <span style="font-weight: 700; margin-left: 20px;"></span>
      </td>
      <td>
        <span style="margin-left: 75px;">
          Số phòng : {{ dt_print.So_phong }}</span>
        <span style="font-weight: 700; margin-left: 20px;"></span>
      </td>
    </tr>
  </table>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Số tiền nộp : </span>
        <span style="font-weight: 700; margin-left: 20px;">{{
          dt_money | number
        }}đ</span>
      </td>
    </tr>
  </table>
  <table style="width: 704px;">
    <tr>
      <td>
        <span style="margin-left: 75px;"> Viết bằng chữ :</span>
        <span style="font-weight: 700; margin-left: 20px;">{{
          money_words
        }}</span>
      </td>
    </tr>
  </table>
  <div style="width: 704px; margin-left: 78px;">
    Khoản nộp :
    <span *ngFor="let p of dt_payment">
      <span *ngIf="p.Hinh_thuc_thu == true">
        {{ p.Ten_khoan_thu }} {{ p.Thang }}/{{ p.Nam }} :
        {{ p.Thanh_tien | number}} đ,
      </span>
      <span *ngIf="p.Hinh_thuc_thu == false">
        {{ p.Ten_khoan_thu }} học kì {{ p.Hoc_ky }} năm {{ p.Nam_hoc }} :
        {{ p.Thanh_tien | number }} đ,
      </span>
    </span>
  </div>
  <p style="text-align: center;">
    <strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Người nộp
      tiền&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Người
      thu tiền</strong>
  </p>
  <p style="text-align: center;">
    <em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (K&yacute;, họ t&ecirc;n)&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(K&yacute;,
      họ t&ecirc;n)&nbsp;&nbsp;&nbsp;&nbsp;
    </em>
  </p>
  <p style="text-align: center;"><strong>&nbsp;</strong></p>
  <p>&nbsp;</p> 
</ng-template>


<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Sơ đồ phòng</li>
        </ol>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row" style="padding-left: 10px; padding-right: 10px;">
          <div class="col-md-3">
            <label class="col-md-10">Toà nhà:</label>
            <select
              name=""
              class="form-control"
              id="ID_toa_nha_ft"
              formControlName="ID_toa_nha_ft"
              (change)="changeBuilding()"
            >
              <option value="">- Tất cả -</option>
              <option *ngFor="let p of DSToaNha2" [value]="p.ID_ToaNha">{{
                p.Ten_ToaNha
              }}</option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">Tầng:</label>
            <select name="" class="form-control" formControlName="Tang">
              <option value="">- Tất cả -</option>
              <option *ngFor="let p of listFloor" [value]="p">{{ p }}</option>
            </select>
          </div>
          <!-- <div class="col-md-2">
            <label class="col-md-10">Phòng:</label>
            <input type="text" [(ngModel)]="searchString"  placeholder="Tìm kiếm tên phòng" />
          </div> -->
          <div class="col-md-2">
            <label class="col-md-10">&nbsp;</label>
            <button class="form-control btn-success" (click)="Find()">
              <i class="fas fa-search"></i> Xem sơ đồ phòng
            </button>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">&nbsp;</label>
            <button class="form-control btn-danger" (click)="PrintSoPhong()">
              <i class="fas fa-print"></i> In sổ phòng
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row" style="padding-left: 10px; padding-right: 10px;">
        <div class="col-md-4">
          <label class="col-md-10"
            >Tổng số chỗ trống: {{ dataThongke?.Tong_so_phong_trong }}</label
          >
        </div>
        <div class="col-md-4">
          <label class="col-md-10"
            >Tổng số chỗ trống nam:
            {{ dataThongke?.Tong_so_phong_trong_nam }}</label
          >
        </div>
        <div class="col-md-4">
          <label class="col-md-10"
            >Tổng số chỗ trống nữ:
            {{ dataThongke?.Tong_so_phong_trong_nu }}</label
          >
        </div>
        <div class="col-md-4">
          <label class="col-md-10"
            >Chỗ trống còn lại: {{ dataThongke?.Con_lai }}</label
          >
        </div>
        <div class="col-md-4">
          <label class="col-md-10"
            >Chỗ trống nam còn lại: {{ dataThongke?.Con_lai_nam }}</label
          >
        </div>
        <div class="col-md-4">
          <label class="col-md-10"
            >Chỗ trống nữ còn lại: {{ dataThongke?.Con_lai_nu }}</label
          >
        </div>
      </div>
    </div>
  </div>
</section>

<div class="col-md-12" id="viewtable">
  <h4 style="text-align: center; font-weight: bolder;">{{ tentoanha }}</h4>
  <div *ngFor="let toanha of arr_all">
    <div class="card" style="overflow-y: scroll; overflow-x: scroll;">
      <div class="card-header" placement="bottom">
        <h4 style="text-align: center; font-weight: bolder;">
          Toà nhà: {{ toanha[0][0].Ten_toa_nha }}
        </h4>
      </div>
      <div class="card-body">
        <div class="col-md-12">
          <input
            type="text"
            [(ngModel)]="searchString"
            placeholder="Tìm kiếm tên phòng"
            style="float: right;"
          />
        </div>
        <table>
          <tr *ngFor="let tang of toanha">
            <td *ngFor="let phong of tang | filter: searchString">
              <span *ngIf="phong.Exp_Date == 1">
                <i class="fas fa-exclamation-triangle blink_me _ptop"></i
              ></span>
              <div
                (click)="open(ViewModal, phong, 'xl', 'view')"
                class="roomWarning"
                *ngIf="
                  !phong.Khoa_phong && phong.Dang_o > 0 && phong.Con_trong > 0
                "
              >
                <span class="roomName">{{ phong.Ten_phong }} </span><br />
                <span class="roomSlot"
                  >{{ phong.Dang_o }}/{{ phong.Suc_chua }}</span
                >
                <br />
              </div>
              <div
                (click)="open(ViewModal, phong, 'xl', 'view')"
                class="roomFull"
                *ngIf="phong.Khoa_phong || phong.Con_trong == 0"
              >
                <i *ngIf="phong.Khoa_phong" class="fas fa-lock"></i>
                <span class="roomName">{{ phong.Ten_phong }}</span
                >&nbsp;
                <br />
                <span class="roomSlot"
                  >{{ phong.Dang_o }}/{{ phong.Suc_chua }}</span
                >
                <br />
              </div>
              <div
                (click)="open(ViewModal, phong, 'xl', 'view')"
                class="roomReady"
                *ngIf="!phong.Khoa_phong && phong.Dang_o == 0"
              >
                <span class="roomName">{{ phong.Ten_phong }}</span
                ><br />
                <span class="roomSlot"
                  >{{ phong.Dang_o }}/{{ phong.Suc_chua }}</span
                >
                <br />
              </div>
              <!-- <div (click)="open(ViewModal, phong,'xl','view')" class="_roomB" *ngIf=" phong.Bao_phong ">
              <span class="roomName">{{phong.Ten_phong}}</span>&nbsp;
              <i *ngIf="phong.Khoa_phong" class="fas fa-lock"></i>
              <br>
              <span class="roomSlot">{{phong.Dang_o}}/{{phong.Suc_chua}}</span>
              <br>
            </div> -->
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #ViewModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ TenPhong }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <div class="container">
      <ngb-tabset [destroyOnHide]="false" style="font-weight: bold;">
        <ngb-tab>
          <ng-template ngbTabTitle>Danh sách sinh viên trong phòng</ng-template>
          <ng-template ngbTabContent>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-2">
                  <label class="col-md-10">&nbsp;</label>
                  <button
                    class="form-control btn-danger"
                    (click)="PrintSoPhongDetail()"
                  >
                    <i class="fas fa-print"></i> In sổ phòng
                  </button>
                </div>
                <div class="col-md-12">
                  <button
                    class="btn btn-primary btn-sm"
                    style="float: right;"
                    ngbTooltip="Thêm sinh viên vào phòng"
                    (click)="
                      openModalHSSV(ChangeClassModal, '', 'xs', 'changeRoom')
                    "
                  >
                    <i class="fas fa-plus-circle"></i> Đổi phòng
                  </button>
                  <button
                    class="btn btn-sm btn-success"
                    (click)="open(MultiGiaHanModal, 'xs', '', '')"
                    style="float: right; margin-right: 10px;"
                    ngbTooltip="Gia hạn "
                  >
                    <i class="fas fa-angle-double-right"></i> Gia hạn
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    (click)="openApprover(ChoRaktx, 'xs')"
                    style="float: right; margin-right: 10px;"
                    ngbTooltip="Cho ra KTX"
                  >
                    <i class="fas fa-trash-alt"></i> Ra KTX
                  </button>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-md-12">
                  <div
                    class="table-responsive"
                    style="
                      max-height: 80vh;
                      margin-bottom: 15px;
                      font-weight: 100;
                      overflow-y: scroll;
                      overflow-x: scroll;
                    "
                  >
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              class="myCheckbox"
                              [checked]="isAllCheckBoxChecked()"
                              (change)="checkAllCheckBox($event)"
                              style="width: 1rem; height: 1rem;"
                            />
                          </th>

                          <th>Mã sinh viên</th>
                          <th>Họ tên</th>
                          <th>Chức vụ</th>
                          <th>Quốc tịch</th>
                          <th>Hệ</th>
                          <th>Khoa</th>
                          <th>Khóa-Lớp</th>
                          <th>Số tiền phải đóng</th>
                          <th>Số tiền đã đóng</th>
                          <th>Thao tác</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let p of dataroom; let i = index"
                          [ngStyle]="CheckExpDate(p.Ngay_ket_thuc)"
                        >
                          <td class="text-center">
                            <input
                              type="checkbox"
                              class="myCheckbox"
                              value="{{ p.Chon }}"
                              [checked]="p.Chon"
                              [(ngModel)]="dataroom[i].Chon"
                              style="width: 1rem; height: 1rem;"
                            />
                          </td>
                          <td>{{ p.Ma_sv }}</td>
                          <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                          <td>
                            <div *ngIf="p.Truong_Phong">
                              Trưởng phòng
                            </div>
                            <div *ngIf="!p.Truong_Phong">
                              Thành viên
                            </div>
                          </td>
                          <td>{{ p.Quoc_Tich }}</td>
                          <td>{{ p.Ten_he }}</td>
                          <td>{{ p.Ten_khoa }}</td>
                          <td class="_pl-pr">{{ p.Khoa_Lop }}</td>
                          <td class="_pl-pr">
                            {{ p.So_tien_phai_dong | number }}
                          </td>
                          <td class="_pl-pr">
                            {{ p.So_tien_da_nop | number }}
                          </td>
                          <td
                            class="_pl-pr text-center"
                            style="white-space: nowrap;"
                          >
                            <button
                              class="btn btn-sm btn-success"
                              style="margin-right: 4px;"
                              (click)="open(RaHanModal, p, 'xs', 'RaHan')"
                            >
                              <i
                                class="fas fa-angle-double-right"
                                ngbTooltip="Gia hạn"
                              ></i>
                            </button>
                            <button
                              class="btn btn-sm btn-warning"
                              (click)="
                                openModalHSSV(ThemModal, p, 'xl', 'edit')
                              "
                              style="margin-right: 4px;"
                            >
                              <i
                                class="far fa-id-badge"
                                ngbTooltip="Xem thông tin"
                              ></i>
                            </button>
                            <button
                              class="btn btn-sm btn-warning"
                              (click)="
                                openModalHSSV(
                                  AvatarModal,
                                  p,
                                  'avatarModalSize',
                                  'avatar'
                                )
                              "
                            >
                              <!-- <i
                                class="far fa-id-badge"
                                ngbTooltip="Cập nhật ảnh đại diện"
                              ></i> -->
                              <i
                                class="far fa-user-circle"
                                ngbTooltip="Cập nhật ảnh đại diện"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab
          style="font-weight: bold;"
          title="Sinh viên đăng ký"
          *ngIf="!Khoa_phong || Check_con_trong == 0"
        >
          <ng-template ngbTabContent>
            <app-filter-sv
              [dtPhong]="dtPhong"
              (LoadParent)="LoadCoSo()"
            ></app-filter-sv>
          </ng-template>
        </ngb-tab>
        <ngb-tab style="font-weight: bold;" title="Xếp phòng thủ công">
          <ng-template ngbTabContent>
            <app-filter-sv-manual
              [dtPhong]="dtPhong"
              (LoadParent)="LoadCoSo()"
              (EnableManualTab)="showManualTab()"
            ></app-filter-sv-manual>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ChoRaktx let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Cho ra khỏi ký túc xá</h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-6 form-group">
          <label class="form-control">
            <input type="checkbox" class="md-textarea" [(ngModel)]="Check_ra" />
            Ra đột xuất
          </label>
        </div>
        <div class="col-md-6 form-group">
          <label class="form-control">
            <input
              type="checkbox"
              class="md-textarea"
              [(ngModel)]="Check_blacklist"
            />
            Vào sách đen
          </label>
        </div>
      </div>
      <div class="row" *ngIf="Check_ra">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Ngày ra</label>
          <input
            type="date"
            class="md-textarea form-control"
            [(ngModel)]="Ngay_ra_ktx"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Lý do cho ra</label>
          <textarea
            type="text"
            class="md-textarea form-control"
            [(ngModel)]="Ly_do"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="ChoRa()"
        (keyup.enter)="ChoRa()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #RaHanModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Ra hạn thời gian ở</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>

  <div class="modal-body cus-fs">
    <div class="row">
      <div class="col-md-12">
        <label class="col-md-10" style="color: red;"
          >Thời gian ở: {{ dataDangKyQuaTrinh.Tu_ngay | date: 'dd/MM/yyyy' }}
          -
          {{ dataDangKyQuaTrinh.Den_ngay | date: 'dd/MM/yyyy' }}</label
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="col-md-10 require">Ngày gia hạn</label>
        <input
          type="date"
          class="form-control"
          (change)="changeGiaHan()"
          [(ngModel)]="Ngay_gia_han"
        />
      </div>
      <div class="col-md-12" style="margin-top: 5px;">
        <label class="col-md-10">Tổng số tháng ở : {{ So_thang_o }} </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="GiaHan()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #MultiGiaHanModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Ra hạn thời gian ở</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <div class="row">
      <div class="col-md-12">
        <label class="col-md-10 require">Ngày gia hạn</label>
        <input
          type="date"
          class="form-control"
          (change)="changeGiaHan()"
          [(ngModel)]="Ngay_gia_han"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="MultiGiaHan()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Thông tin chi tiết sinh viên</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <form class="form-horizontal" [formGroup]="Action">
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Mã SV</label>
          <input
            class="form-control"
            placeholder="Nhập mã sv"
            formControlName="Ma_sv"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Họ tên</label>
          <input
            class="form-control"
            placeholder="Nhập họ tên"
            formControlName="Ho_ten"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày sinh</label>
          <input
            class="form-control"
            placeholder="Nhập ngày sinh"
            formControlName="Ngay_sinh"
            type="date"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Giới tính</label>
          <select class="form-control" formControlName="Gioi_Tinh" disabled>
            <option value="2">Chọn giới tính</option>
            <option value="0">Nam</option>
            <option value="1">Nữ</option>
          </select>
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Số hộ chiếu</label>
          <input
            class="form-control cus-fs"
            placeholder="Nhập số hộ chiếu"
            type="text"
            formControlName="So_Ho_Chieu"
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày hết hạn Visa</label>
          <input
            class="form-control cus-fs"
            placeholder="Nhập ngày hết hạn Visa"
            type="date"
            formControlName="Ngay_Het_Han_Visa"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày vào KTX</label>
          <input
            class="form-control cus-fs"
            required
            type="date"
            formControlName="Ngay_bat_dau"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày ra KTX</label>
          <input
            class="form-control cus-fs"
            required
            type="date"
            formControlName="Ngay_ket_thuc"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">CMND</label>
          <input
            class="form-control"
            placeholder="Nhập cmnd"
            formControlName="CMND"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày cấp</label>
          <input
            class="form-control"
            placeholder="Nhập ngày cấp"
            formControlName="Ngay_cap_CMND"
            type="date"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Nơi cấp</label>
          <input
            class="form-control"
            placeholder="Nhập nơi cấp"
            formControlName="Noi_cap_CMND"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Tôn giáo</label>
          <input
            class="form-control"
            placeholder="Nhập tôn giáo"
            formControlName="Ton_giao"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Điện thoại</label>
          <input
            class="form-control"
            placeholder="Nhập điện thoại"
            formControlName="Dienthoai_canhan"
            type="text"
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Email</label>
          <input
            class="form-control"
            placeholder="Nhập email"
            formControlName="Email"
            type="text"
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Quốc tịch</label>
          <input
            class="form-control"
            placeholder="Nhập dân tộc"
            formControlName="ID_quoc_tich"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Dân tộc</label>
          <input
            class="form-control"
            placeholder="Nhập dân tộc"
            formControlName="Dan_toc"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Họ tên cha</label>
          <input
            class="form-control"
            placeholder="Nhập họ tên cha"
            formControlName="Ho_ten_cha"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Năm sinh cha</label>
          <input
            class="form-control"
            placeholder="Nhập năm sinh cha"
            formControlName="Namsinh_cha"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Nghề nghiệp cha</label>
          <input
            class="form-control"
            placeholder="Nhập nghề nghiệp cha"
            formControlName="Hoat_dong_XH_CT_cha"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Số đt cha</label>
          <input
            class="form-control"
            placeholder="Nhập số đt cha"
            formControlName="So_dien_thoai_bo"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Họ tên mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập họ tên mẹ"
            formControlName="Ho_ten_me"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Năm sinh mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập năm sinh mẹ"
            formControlName="Namsinh_me"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Nghề nghiệp mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập nghề nghiệp mẹ"
            formControlName="Hoat_dong_XH_CT_me"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Số đt mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập số đt mẹ"
            formControlName="So_dien_thoai_me"
            type="text"
            disabled
          />
        </div>
      </div>

      <div class="row mt10">
        <div class="col-md-6">
          <label class="col-md-10">Địa chỉ</label>
          <input
            class="form-control"
            placeholder="Nhập địa chỉ"
            formControlName="Dia_chi_tt"
            type="text"
          />
        </div>
        <div class="col-md-6 `">
          <label class="col-md-10">Nơi ở hiện nay</label>
          <input
            class="form-control"
            placeholder="Nhập nơi ở hiện nay"
            formControlName="NoiO_hiennay"
            type="text"
            disabled
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onApprove()"
        (keyup.enter)="onApprove()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #AvatarModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">
      Cập nhật ảnh sinh viên - {{ Student.Ho_ten }}
    </h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <img
              [src]="StudentImage"
              (error)="updateUrl($event)"
              style="width: 100%;"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <div class="form-group">
                <input
                  type="file"
                  class="form-control"
                  style="height: 45px !important;"
                  name="currentInput"
                  [(ngModel)]="currentInput"
                  (change)="onFileSelected($event)"
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-8"></div> -->
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="UploadAvatar()"
        (keyup.enter)="UploadAvatar()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ChangeClassModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Đổi phòng</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <form class="form-horizontal" [formGroup]="ActionChangeRoom">
      <div class="row">
        <div class="col-md-6 mt10">
          <label class="col-md-10">Cơ sở</label>
          <select
            class="form-control cus-fs"
            formControlName="ID_Campus"
            (change)="changeCampusRep($event.target.value)"
          >
            <option
              *ngFor="let item of dataCampusChange"
              value="{{ item.ID }}"
              >{{ item.name }}</option
            >
          </select>
        </div>
        <div class="col-md-6 mt10">
          <label class="col-md-10">Tòa nhà</label>
          <select
            class="form-control cus-fs"
            formControlName="ID_Building"
            (change)="changeRoomRep($event.target.value)"
          >
            <option
              *ngFor="let item of dataBuildingChange"
              [value]="item.ID_ToaNha"
              >{{ item.Ten_ToaNha }}</option
            >
          </select>
        </div>
        <div class="col-md-12 mt10">
          <label class="col-md-10">Phòng</label>
          <select class="form-control cus-fs" formControlName="ID_Room">
            <option>Chọn phòng</option>
            <option
              *ngFor="let item of dataRoomChange"
              value="{{ item.ID_Phong }}"
              >{{ item.Ten_phong }}</option
            >
          </select>
        </div>
      </div>
    </form>
    <div class="row" style="margin-top: 1rem;">
      <div class="col-md-12">
        <label class="col-md-12">Ngày đổi</label>
        <input
          class="form-control"
          type="date"
          [(ngModel)]="Ngay_ra_phong_cu"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onChangeRoom()"
        style="margin-left: 1em;"
        (keyup.enter)="ChoRa()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>

<div id="print-so-phong" style="display: none;">
  <app-print-so-phong [data]="dataSoPhong"></app-print-so-phong>
</div>
<ng-template #Print let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">In sổ phòng</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <app-print-so-phong [data]="dataSoPhong"></app-print-so-phong>
  </div>
</ng-template>

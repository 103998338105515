<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Biến động khoản thu</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button class="btn btn-primary btn-sm" (click)="TongHop(true)" style="float: right; margin-right: 10px;">
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
      
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row" style="margin-top: 20px;">
        <div class="col-sm-3">
          <label>Mã sinh viên</label>
          <input class="form-control" (keyup.enter)="TongHop(true)" placeholder="Tìm kiếm mã sinh viên"
            [(ngModel)]="Ma_sv" />
        </div>
        <div class="col-sm-3">
          <label>Họ tên sinh viên</label>
          <input class="form-control" (keyup.enter)="TongHop(true)" placeholder="Tìm kiếm tên sinh viên"
            [(ngModel)]="Ho_ten" />
        </div>
        <div class="col-sm-3">
          <label>Ngày sinh</label>
          <input type="date" class="form-control" (keyup.enter)="TongHop(true)" [(ngModel)]="Ngay_sinh" />
        </div>
        <div class="col-sm-3">
          <label>Phòng</label>
          <input type="text" class="form-control" (keyup.enter)="TongHop(true)" [(ngModel)]="Ten_phong" />
        </div>
      </div>
      <div class="row" style="margin-top: 20px;">
        <div class="col-sm-3">
          <label>Học kỳ</label>
          <select class="form-control" (change)="TongHop(true)" [(ngModel)]="Hoc_ky">
            <option>Tất cả</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Năm học</label>
          <input type="text" (keyup.enter)="TongHop(true)" class="form-control" [(ngModel)]="Nam_hoc" />
        </div>
        <div class="col-sm-3">
          <label>Tòa nhà</label>
          <select class="form-control" (change)="TongHop(true)" [(ngModel)]="ID_toa_nha">
            <option selected>Tất cả</option>
            <option *ngFor="let i of dataBuilding" value="{{ i.ID_ToaNha }}">{{
              i.Ten_ToaNha
              }}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Lần biến động</label>
          <input type="number" (keyup.enter)="TongHop(true)" class="form-control" [(ngModel)]="So_lan_bien_dong" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button title="Ẩn/hiện bộ lọc" style="float: right; margin: 0 0 5px 5px;" (click)="buttonFilter()">
                <i class="fas fa-filter"></i>
              </button>
              <button title="Tải lại" style="float: right; margin-bottom: 5px;" (click)="refesh()">
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div class="table-responsive" style="max-height: 60vh; margin-bottom: 15px;">
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>STT</th>
                <th>Mã sinh viên</th>
                <th>Họ tên</th>
                <th>Ngày sinh</th>
                <th>Học kỳ</th>
                <th>Năm học</th>
                <th>Lần</th>
                <th>Tòa nhà - Phòng</th>
                <th>Tiền phòng</th>
                <th>Tiền cược</th>
                <th>Tổng tiền</th>
                <th>Số lần biến động</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                  <td style="text-align: center;">{{ p.RowNum }}</td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Hoc_ky }}</td>
                  <td>{{ p.Nam_hoc }}</td>
                  <td>{{ p.Lan }}</td>
                  <td>{{ p.Ten_toa_nha }} - {{ p.Ten_phong }}</td>
                  <td class="text-right">{{ p.Tien_phong | number }}</td>
                  <!-- <td class="text-right">{{ p.So_tien_mien_giam |number}}</td>
                    <td class="text-right">{{ p.Phan_tram_mien_giam }}</td> -->
                  <td class="text-right">{{ p.Tien_cuoc | number }}</td>
                  <td class="text-right">{{ p.Tong_tien | number }}</td>
                  <td class="text-right">{{ p.So_lan_bien_dong }}</td>
                  <td class="text-center">
                    <button class="btn btn-sm btn-warning" (click)="open(DetailMoDal, 'xl', 'Detail', p)">
                      <i class="far fa-eye"> </i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataTable.length == 0">
                  <td colspan="13" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                        }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)">Cuối</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #DetailMoDal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100">Quá trình đăng ký</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body" style="margin-top: 10px;">
    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Tòa nhà - Phòng</th>
            <th>Từ ngày</th>
            <th>Đến ngày</th>
            <th>Nội dung</th>
            <th>Tiền cược</th>
            <th>Tiền phòng</th>
            <th>Tổng tiền</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of dataDetail">
            <td>{{ p.Ten_toa_nha }}-{{ p.Ten_phong }}</td>
            <td>{{ p.Tu_ngay | date: 'dd/MM/yyyy'}}</td>
            <td>{{ p.Den_ngay | date: 'dd/MM/yyyy'}}</td>
            <td>{{ p.Noi_dung }}</td>
            <td>{{ p.Tien_cuoc | number}}</td>
            <td>{{ p.Tien_phong | number}}</td>
            <td>{{ p.Tong_tien | number}}</td>
          </tr>
          <tr *ngIf="dataDetail.length == 0">
            <td colspan="5" class="text-center">Không có kết quả</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Cấp phát thu hồi thẻ KTX</li>
        </ol>
      </div>
      <!-- <div class="col-md-6">
                <button class="btn btn-success btn-sm" (click)="excel()" style="float: right;"><i
                        class="far fa-file-excel"></i> Xuất excel</button>
            </div> -->
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.ID_dang_ky }}</td>
                      <td>{{ p.ID_sv }}</td>
                      <td>{{ p.Ma_sv }}</td>
                      <td>{{ p.Ho_ten }}</td>
                      <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ p.Trang_thai_the }}</td>
                      <td>{{ p.So_the }}</td>
                      <td>{{ p.Ngay_cap_the | date: 'dd/MM/yyyy' }}</td>
                      <td>
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'xs', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i
                            class="fas fa-id-card"
                            title="Sửa thông tin thẻ"
                          ></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-trash-alt" title="Thu hồi thẻ"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Số thẻ</label>
          <input
            class="form-control"
            required
            placeholder="Nhập Số thẻ"
            type="text"
            id="So_the"
            formControlName="So_the"
            [(ngModel)]="So_the"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.So_the.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.So_the.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.So_the.errors.required">
              Số thẻ không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.So_the.errors.maxlength">
              Số thẻ tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">Ngày cấp thẻ</label>
          <input
            class="form-control"
            required
            placeholder="Nhập tên cơ sở"
            type="date"
            id="Ngay_cap_the"
            formControlName="Ngay_cap_the"
            [(ngModel)]="Ngay_cap_the"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ngay_cap_the.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ngay_cap_the.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ngay_cap_the.errors.required">
              Ngày cấp thẻ không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onSubmit()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn muốn thu hồi thẻ này
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Delete()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>

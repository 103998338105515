import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { common } from 'src/app/app.common';
import { AppService } from 'src/app/utils/services/app.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  DonGiaDienNuoc: any;
  QuanTriEmail: any;
  LichSuEmail: any;
  constructor(
    public appService: AppService,
    private router: Router,
    private toastr: ToastrService,
    private titleService: Title
  ) {}
  styleLi: string = 'nav-item ';
  public com: common;
  Co_so: boolean = false;
  Toa_nha: boolean = false;
  Loai_phong: boolean = false;
  Phong: boolean = false;
  So_do_phong: boolean = false;
  Thiet_bi: boolean = false;
  Quy_dinh_dang_ky: boolean = false;
  Duyet_dang_ky: boolean = false;
  Xep_phong: boolean = false;
  Bien_lai: boolean = false;
  Thong_ke: boolean = false;
  Danh_sach_o: boolean = false;
  Danh_sach_ra: boolean = false;
  Danh_sach_han_che: boolean = false;
  Tra_cuu_log: boolean = false;
  QuyDinhKhoanThu: boolean = false;
  DeXuatSinhVien: boolean = false;
  DiemSinhVien: boolean = false;
  DiemPhong: boolean = false;
  PhanQuyen: boolean = false;
  LichSuSuaChua: boolean = false;
  ViPham: boolean = false;
  HinhThuc: boolean = false;
  KhenThuongKyLuat: boolean = false;
  BienDong: boolean = false;
  SoDien: boolean = false;
  SoNuoc: boolean = false;
  TienIch: boolean = false;
  ThamSoHeThong: boolean = false;
  Roles: any;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var UserData = this.com.getUserinfo();
    this.Roles = UserData.Roles;
    if (this.Roles.length == 0) {
      this.toastr.warning(
        'Bạn chưa được cấp bất kỳ quyền nào! Vui lòng liên hệ với quản trị viên để được hỗ trợ'
      );
    } else {
      this.SetUpMenu(this.Roles);
    }
  }
  menu(id: string) {
    let element, name, arr;
    element = document.getElementById(id);
    arr = element.className.split(' ');
    name = 'menu-open';
    if (arr.indexOf(name) == -1) {
      element.className += ' ' + name;
    } else {
      element.className = 'nav-item';
    }
  }
  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }
  public setTitle(newTitle) {
    this.titleService.setTitle(newTitle + ' | UniDormitory');
  }
  SetUpMenu(R) {
    // Đăng ký
    this.Duyet_dang_ky = R.some((x) => x.ID_quyen == 1500);
    this.QuyDinhKhoanThu = R.some((x) => x.ID_quyen == 1513);
    // Xếp phòng
    this.Xep_phong = R.some((x) => x.ID_quyen == 1501);
    // Danh sách ở
    this.Danh_sach_o = R.some((x) => x.ID_quyen == 1502);
    // Biên lai hóa đơn
    this.Bien_lai = R.some((x) => x.ID_quyen == 1503);
    // Cơ sở
    this.Co_so = R.some((x) => x.ID_quyen == 1504);
    // Tòa nhà
    this.Toa_nha = R.some((x) => x.ID_quyen == 1505);
    // Phòng
    this.Phong = R.some((x) => x.ID_quyen == 1506);
    // Loại phòng
    this.Loai_phong = R.some((x) => x.ID_quyen == 1507);
    // Thiết bị
    this.Thiet_bi = R.some((x) => x.ID_quyen == 1508);

    // Quy định đăng ký
    this.Quy_dinh_dang_ky = R.some((x) => x.ID_quyen == 1509);
    // Danh sách hạn chế
    this.Danh_sach_han_che = R.some((x) => x.ID_quyen == 1510);
    // Danh sách ra
    this.Danh_sach_ra = R.some((x) => x.ID_quyen == 1511);
    this.Tra_cuu_log = R.some((x) => x.ID_quyen == 1512);
    this.DeXuatSinhVien = R.some((x) => x.ID_quyen == 1514);
    this.DiemSinhVien = R.some((x) => x.ID_quyen == 1515);
    this.DiemPhong = R.some((x) => x.ID_quyen == 1516);
    this.BienDong = R.some((x) => x.ID_quyen == 1517);
    this.PhanQuyen = R.some((x) => x.ID_quyen == 1518);
    this.LichSuSuaChua = R.some((x) => x.ID_quyen == 1519);
    this.ViPham = R.some((x) => x.ID_quyen == 1520);
    this.HinhThuc = R.some((x) => x.ID_quyen == 1521);
    this.KhenThuongKyLuat = R.some((x) => x.ID_quyen == 1522);
    this.SoDien = R.some((x) => x.ID_quyen == 1523);
    this.SoNuoc = R.some((x) => x.ID_quyen == 1524);
    this.DonGiaDienNuoc = R.some((x) => x.ID_quyen == 1525);
    this.QuanTriEmail = R.some((x) => x.ID_quyen == 1526);
    this.LichSuEmail = R.some((x) => x.ID_quyen == 1527);
    this.ThamSoHeThong = R.some((x) => x.ID_quyen == 1529);
    this.TienIch = R.some((x) => x.ID_quyen == 1530);
  }
}

import { Component, OnInit } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { formatDate } from '@angular/common';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent extends BaseComponent implements OnInit {
  public Editor = ClassicEditor;

  Tieu_de: any = '';
  Create_userName: any = '';
  Create_date: any;

  UniDormAPIURL: any;
  file: any;
  listShare: any;
  listFile: any;
  listPeople: any;
  isEditDoc: any;
  currentInput: any;
  titleModal: any = '';
  Token: string;
  public com: common;

  SaveForm = new FormGroup({
    Tieu_de: new FormControl(''),
    Create_userName: new FormControl(''),
    Create_date: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en')),
    Noi_dung: new FormControl(''),
    Files: new FormControl(''),
  });

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    // this.StatisticalService.ThongKe_List_Count(this.Token).subscribe((z) => {
    //   this.totalItem = z.Total;
    // });
    this.getList();
    this.UniDormAPIURL = this.appConfig.UniDormAPI;
  }
  Download(p: any) {
    window.open(
      this.UniDormAPIURL +
        '/Resources/DocumentWeb/' +
        p.ID_file +
        '.' +
        p.Ten_file.split('.').pop(),
      '_blank'
    );
  }

  deleteFile(p) {
    var r = confirm('Bạn có muốn xóa file này không?');
    if (r) {
      this.spinner.show();
      this.DocumentService.DeleteFile(p.ID_file, this.Token).subscribe((z) => {
        this.spinner.hide();
        if (z.Status == 1) {
          this.getListFile();
          this.toastr.success(z.Message);
        }
      });
    }
  }
  // ExportExcel() {
  //   var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
  //   if (r) {
  //     this.spinner.show();
  //     this.page = 1;
  //     let dataFilter = {
  //       So_ban_ghi: 1000000000,
  //       Trang: 1,
  //       Ma_sv: this.Ma_sv.trim(),
  //       Ho_ten: this.Ho_ten.trim(),
  //       ID_khoa: this.ID_khoa,
  //       Khoa_hoc: this.Khoa_hoc,
  //       Nam_hoc: this.Nam_hoc,
  //       Trang_thai_hoc: this.Trang_thai_hoc,
  //       Tu_ngay: this.Tu_ngay,
  //       Den_ngay: this.Den_ngay,
  //       ID_co_so: this.ID_co_so,
  //       ID_toa_nha: this.ID_toa_nha,
  //       Tang: this.Tang,
  //       ID_gioi_tinh: this.ID_gioi_tinh,
  //     };
  //     this.StatisticalService.Export_BaoCaoThongKe(
  //       dataFilter,
  //       this.Token
  //     ).subscribe((z) => {
  //       if (z.Status == 1) {
  //         this.exportService.exportExcelByte(z.FileData, 'BaoCaoThongKe');
  //         this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
  //         this.spinner.hide();
  //         this.modalService.dismissAll('ExportExcel');
  //       } else {
  //         this.toastr.error(z.Message, 'Tác vụ thất bại');
  //         this.spinner.hide();
  //       }
  //     });
  //   }
  // }
  getList() {
    this.spinner.show();
    // this.page = 1;
    // this.arrNumberPage = [];
    // this.arrNumberPage_chil = [];

    let dataFilter = {
      Tieu_de: this.Tieu_de,
      Create_userName: this.Create_userName,
      Create_date: this.Create_date,
    };
    this.DocumentService.GetList(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.dataTable = z.Data;
          this.spinner.hide();
        } else {
          this.toastr.warning(z.Message);
          this.spinner.hide();
        }
      },
      (err) => {
        this.toastr.error('Lỗi kết nối');
        this.spinner.hide();
      }
    );
    // this.StatisticalService.ThongKe_List_CountFilter(
    //   dataFilter,
    //   this.Token
    // ).subscribe((z) => {
    //   this.totalItemFilter = z.Total;
    //   this.numberPage = this.createNumberPage(
    //     z.Total,
    //     this.pageSize
    //   ).numberPage;
    //   this.arrNumberPage_chil = this.createNumberPage(
    //     z.Total,
    //     this.pageSize
    //   ).arrNumberPage_chil;
    //   this.spinner.hide();
    // });
  }

  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }

  refesh() {
    this.Tieu_de = '';
    this.Create_userName = '';
    this.Create_date;
    this.getList();
  }

  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Tieu_de: this.Tieu_de,
      Create_userName: this.Create_userName,
      Create_date: this.Create_date,
    };
    this.DocumentService.GetList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.spinner.hide();
    });
  }

  open(data: any, content: any, type: any, sizm: any) {
    this.ID = data.ID_tai_lieu ? data.ID_tai_lieu : 0;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (type == 'ADD') {
      this.titleModal = 'Thêm tài liệu';
      this.SaveForm.reset();
      this.listFile = [];
      this.isEditDoc = false;
    }
    if (type == 'EDIT') {
      this.isEditDoc = true;
      this.titleModal = 'Sửa tài liệu';
      this.SaveForm.patchValue({
        Tieu_de: data.Tieu_de,
        Create_userName: data.Create_userName,
        Create_date: data.Create_date.substring(0, 10),
        Noi_dung: data.Noi_dung,
      });
      this.getListFile();
    }
    if (type == 'SHARE') {
      this.titleModal = 'Chia sẻ tài liệu';
      this.SaveForm.patchValue({
        Tieu_de: data.Tieu_de,
        Create_userName: data.Create_userName,
        Create_date: data.Create_date.substring(0, 10),
        Noi_dung: data.Noi_dung,
      });
      this.getListShare();
    }
  }

  getListFile() {
    let req = {
      ID_tai_lieu: this.ID,
      Tieu_de: this.SaveForm.value.Tieu_de,
      Create_userName: this.SaveForm.value.Create_userName,
      Create_date: this.SaveForm.value.Create_date,
      Noi_dung: this.SaveForm.value.Noi_dung,
    };
    this.DocumentService.Get_File_ByID(req, this.Token).subscribe((z) => {
      this.listFile = z.Data[0].Files;
      this.spinner.hide();
    });
  }

  getListShare() {
    let req = {
      ID_tai_lieu: this.ID,
      Tieu_de: this.SaveForm.value.Tieu_de,
      Create_userName: this.SaveForm.value.Create_userName,
      Create_date: this.SaveForm.value.Create_date,
      Noi_dung: this.SaveForm.value.Noi_dung,
    };
    this.DocumentService.Get_ChiaSe_ByID(req, this.Token).subscribe((z) => {
      this.listShare = z.Data[0].DocumentShares;
      this.spinner.hide();
    });
  }

  openPeople(data: any, content: any, type: any, sizm: any) {
    this.ID_tai_lieu_chia_se = data.ID_tai_lieu_chia_se
      ? data.ID_tai_lieu_chia_se
      : 0;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (type == 'PEOPLE') {
      this.titleModal = 'Chọn người chia sẻ';
      this.DocumentService.GetPeople(this.Token).subscribe((z) => {
        this.listPeople = z.Data;
        this.listShare.forEach(
          (x: any) =>
            (this.listPeople = this.listPeople.filter(
              (y) => y.UserID != x.ID_user
            ))
        );
        this.spinner.hide();
      });
    }
  }

  share(data: any) {
    let req = {
      ID_tai_lieu: this.ID,
      ID_tai_lieu_chia_se: data.ID_tai_lieu_chia_se,
      ID_user: data.UserID,
    };
    this.DocumentService.SaveShare(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        if (!data.ID_tai_lieu_chia_se) {
          this.listPeople = this.listPeople.filter(
            (x) => x.UserID != req.ID_user
          );
          this.toastr.success(z.Message);
        } else {
          this.toastr.success('Hủy chia sẻ thành công');
        }
        this.getListShare();
      } else {
        this.toastr.warning(z.Message);
      }
    });
  }

  save() {
    this.UploadFile();
    let req = {
      ID_tai_lieu: this.ID,
      Tieu_de: this.SaveForm.value.Tieu_de,
      Noi_dung: this.SaveForm.value.Noi_dung,
    };
    this.DocumentService.SaveDocument(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.getList();
        this.toastr.success(z.Message);
        this.modalService.dismissAll('AddModal');
      }
    });
  }

  delete() {
    this.DocumentService.DeleteDocument(this.ID, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.getList();
        this.toastr.success(z.Message);
        this.modalService.dismissAll('DeleteModal');
      }
    });
  }

  UploadFile() {
    if (!this.currentInput) {
      this.toastr.warning('Vui lòng chọn file cần tải lên');
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.currentInput);
    formData.append('ID_tai_lieu', this.ID);
    this.DocumentService.SaveFile(formData, this.Token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.toastr.success('Cập nhật file thành công.');
        this.getListFile();
      } else {
        this.toastr.error(z.Message, 'Thao tác thất bại');
      }
    });
  }

  onFileSelected(event) {
    this.currentInput = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => (this.file = reader.result);
      reader.readAsDataURL(file);
    }

    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.currentInput);
    formData.append('ID_tai_lieu', this.ID);
    this.DocumentService.CreateFile(formData, this.Token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.getListFile();
        this.toastr.success('Tải lên file thành công.');
      } else {
        this.toastr.error(z.Message, 'Thao tác thất bại');
      }
    });
  }
}

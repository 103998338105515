<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Tham số hệ thống</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th class="text-center">Tên tham số</th>
                <th class="text-center">Giá trị</th>
                <th class="text-center">Ghi chú</th>
                <th class="text-center">Thao tác</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of dataTable
                      | paginate: { itemsPerPage: 10, currentPage: page }
                  "
                >
                  <td>{{ data.Ten_tham_so }}</td>
                  <td>{{ data.Gia_tri }}</td>
                  <td>{{ data.Ghi_chu }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="open(UpdateModal, 'xs', 'Edit', data)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="4" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
            <pagination-controls
              class="float-right"
              (pageChange)="page = $event"
              previousLabel="Trước"
              nextLabel="Sau"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #UpdateModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Sửa tham số hệ thống</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="update">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Tên tham số</label>
          <input
            class="form-control"
            formControlName="Ten_tham_so"
            placeholder="Nhập tên tham số"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ten_tham_so.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Tu_so.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ten_tham_so.errors.required">
              Tên tham số không được bỏ trống!
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <label class="col-md-10 require">Giá trị</label>
          <input
            class="form-control"
            placeholder="Nhập giá trị"
            formControlName="Gia_tri"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Gia_tri.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Gia_tri.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Gia_tri.errors.required">
              Giá trị không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">Ghi chú</label>
          <input
            class="form-control"
            placeholder="Ghi chú"
            formControlName="Ghi_chu"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Ghi_chu.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Ghi_chu.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ghi_chu.errors.required">
              Ghi chú không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

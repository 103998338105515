import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-diem-phong',
  templateUrl: './diem-phong.component.html',
  styleUrls: ['./diem-phong.component.scss'],
})
export class DiemPhongComponent extends BaseComponent implements OnInit {
  Nam_hoc_arr = [];
  dataTable = [];
  dataToaNha = [];
  dataPhong = [];
  dataPhongOld = [];
  dataChiTiet = [];
  Hoc_ky: any = 1;
  Nam_hoc: any;
  Tu_diem = '';
  Den_diem = '';
  Ten_phong = '';
  Ten_toa_nha = '';
  Data: any;
  DataDetail: any;
  Insert = new FormGroup({
    Toa_nha: new FormControl(null),
    ID_phong: new FormControl(null, [Validators.required]),
    Hoc_ky: new FormControl(1, [Validators.required]),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1),
      [Validators.required]
    ),
    Diem_tru: new FormControl(0, [Validators.required]),
    Noi_dung: new FormControl(null),
  });
  ngOnInit(): void {
   
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.Nam_hoc =
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
    this.getToken();
    this.getCount();
    this.getList();
  }
  getCount() {
    this.spinner.show();
    this.DiemPhongService.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  getList() {
    if (
      (this.Tu_diem != '' && this.Den_diem === '') ||
      (this.Tu_diem === '' && this.Den_diem != '')
    ) {
      this.toastr.warning('Bạn phải nhập từ điểm, đến điểm');
      return false;
    }
    if (this.Tu_diem > this.Den_diem) {
      this.toastr.warning('Từ điểm phải nhỏ hơn đến điểm');
      return false;
    }
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ten_phong: this.Ten_phong,
      Ten_toa_nha: this.Ten_toa_nha,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Tu_diem: this.Tu_diem,
      Den_diem: this.Den_diem,
    };
    this.DiemPhongService.getList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.DiemPhongService.getCountFilter(dataFilter, this.Token).subscribe(
        (z) => {
          this.totalItemFilter = z.Tong_so_ban_ghi_filter;
          this.numberPage = this.createNumberPage(
            z.Tong_so_ban_ghi_filter,
            this.pageSize
          ).numberPage;
          this.arrNumberPage_chil = this.createNumberPage(
            z.Tong_so_ban_ghi_filter,
            this.pageSize
          ).arrNumberPage_chil;
          this.spinner.hide();
        }
      );
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.Tu_diem = '';
    this.Den_diem = '';
    this.getList();
  }
  changPage(event) {
    if (
      (this.Tu_diem != '' && this.Den_diem == '') ||
      (this.Tu_diem == '' && this.Den_diem != '')
    ) {
      this.toastr.warning('Bạn phải nhập từ điểm, đến điểm');
      return false;
    }
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ten_phong: this.Ten_phong,
      Ten_toa_nha: this.Ten_toa_nha,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Tu_diem: this.Tu_diem,
      Den_diem: this.Den_diem,
    };
    this.DiemPhongService.getList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
  }
  modalDetail(content, sizm, type, data) {
    this.Data = data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (type == 'Detail') {
      this.getDetail();
    }
  }
  getDetail() {
    this.spinner.show();
    this.DiemPhongService.Detail(
      this.Data.ID_phong,
      this.Data.Hoc_ky,
      this.Data.Nam_hoc,
      this.Token
    ).subscribe((z) => {
      this.dataChiTiet = z.Data;
      this.spinner.hide();
    });
  }
  open(content, sizm, type, data) {
    this.DataDetail = data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataToaNha = z.Data;
      this.RoomService.GetAll(this.Token).subscribe((z) => {
        this.dataPhong = z.Data;
        this.dataPhongOld = z.Data;
        this.Insert.controls.ID_phong.setValue(this.dataPhong[0].ID_Phong);
      });
    });
    // this.Insert.setValue({
    //   Noi_dung: ''
    // });
    this.Insert.controls.Noi_dung.setValue('');
  }
  lock() {
    let ID_phong = this.Data.ID_phong;
    this.spinner.show();
    this.DiemPhongService.updateStatus(ID_phong, 1, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.getCount();
          this.getList();
          this.toastr.success(z.Message);
          this.modalService.dismissAll('AddModal');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message);
          this.modalService.dismissAll('AddModal');
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  unlock() {
    let ID_phong = this.Data.ID_phong;
    this.spinner.show();
    this.DiemPhongService.updateStatus(ID_phong, 0, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.getCount();
          this.getList();
          this.toastr.success(z.Message);
          this.modalService.dismissAll('AddModal');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message);
          this.modalService.dismissAll('AddModal');
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  delete() {
    this.spinner.show();
    this.DiemPhongService.Delete(
      this.DataDetail.ID_diem_phong,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 1) {
        this.getDetail();
        this.getCount();
        this.getList();
        this.toastr.success(z.Message);
        this.modalService.dismissAll('DeleteModal');
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message);
        this.modalService.dismissAll('DeleteModal');
        this.spinner.hide();
      }
    },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    let req = {
      ID_phong: this.Insert.value.ID_phong,
      Hoc_ky: this.Insert.value.Hoc_ky,
      Nam_hoc: this.Insert.value.Nam_hoc,
      Diem_tru: this.Insert.value.Diem_tru,
      Noi_dung: this.Insert.value.Noi_dung,
      Trang_thai: 0,
    };
    this.spinner.show();
    this.DiemPhongService.Insert(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.getCount();
        this.getList();
        this.toastr.success(z.Message);
        this.modalService.dismissAll('DeleteModal');
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message);

        this.spinner.hide();
      }
    },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
  }
  ToaNhaChange() {
    this.dataPhong = this.dataPhongOld.filter(
      (x) => x.ID_Toa_Nha == this.Insert.value.Toa_nha
    );
    this.Insert.controls.ID_phong.setValue(this.dataPhong[0].ID_Phong);
  }
  ConfirmUnlock() {
    this.spinner.show();
    this.DiemPhongService.updateStatusByHocKy(
      this.Hoc_ky,
      this.Nam_hoc,
      0,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 1) {
        this.getCount();
        this.getList();
        this.toastr.success(z.Message);
        this.modalService.dismissAll('DeleteModal');
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message);

        this.spinner.hide();
      }
    },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
  }
  Confirm() {
    this.spinner.show();
    this.DiemPhongService.updateStatusByHocKy(
      this.Hoc_ky,
      this.Nam_hoc,
      1,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 1) {
        this.getCount();
        this.getList();
        this.toastr.success(z.Message);
        this.modalService.dismissAll('DeleteModal');
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message);

        this.spinner.hide();
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  XuatExcel() {
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: 1000000,
      Trang: this.page,
      Ten_phong: this.Ten_phong,
      Ten_toa_nha: this.Ten_toa_nha,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Tu_diem: this.Tu_diem,
      Den_diem: this.Den_diem,
    };
    this.DiemPhongService.Excel(dataFilter, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, 'Diem_phong');
        this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message);
        this.spinner.hide();
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_DiemPhong;
    window.open(urlHelp,'_blank');
  }

}

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-3">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Điểm sinh viên</li>
        </ol>
      </div>
      <div class="col-md-9">
        <button class="btn btn-primary btn-sm" style="float: right;" (click)="getList()">
          <i class="fas fa-search"></i> Tìm kiếm
        </button>

        <button class="btn btn-danger btn-sm" style="float: right; margin-right: 7px;"
          (click)="modalDetail(ConfirmModal,'xs','Confirm','')">
          <i class="fas fa-lock"></i> Khoá điểm
        </button>
        <button class="btn btn-success btn-sm" style="float: right; margin-right: 7px;"
          (click)="modalDetail(ConfirmModalUnlock,'xs','Confirm','')">
          <i class="fas fa-unlock"></i> Mở khoá điểm
        </button>
        <button class="btn btn-primary btn-sm" style="float: right; margin-right: 7px;" (click)="XuatExcel()">
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label>Mã/Họ tên sinh viên</label>
          <input type="text" class="form-control" [(ngModel)]="Ma_ho_ten" placeholder="Nhập mã/tên sinh viên ..." />
        </div>
        <div class="col-md-2">
          <label>Ngày sinh</label>
          <input type="date" class="form-control" [(ngModel)]="Ngay_sinh" />
        </div>
        <div class="col-md-2">
          <label>Toà nhà - Phòng</label>
          <input type="text" class="form-control" [(ngModel)]="Toa_nha_phong" placeholder="Nhập toà nhà - phòng" />
        </div>
        <div class="col-md-2">
          <label>Học kỳ</label>
          <select class="form-control" [(ngModel)]="Hoc_ky">
            <option value="1">Học kỳ 1</option>
            <option value="2">Học kỳ 2</option>
            <option value="3">Học kỳ 3</option>
          </select>
        </div>
        <div class="col-md-2">
          <label>Năm học</label>
          <select class="form-control" [(ngModel)]="Nam_hoc">
            <option *ngFor="let element of Nam_hoc_arr " value="{{element.value}}">{{element.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>Từ điểm</label>
          <input type="number" class="form-control" [(ngModel)]="Tu_diem" />
        </div>
        <div class="col-md-2">
          <label>Đến điểm</label>
          <input type="number" class="form-control" [(ngModel)]="Den_diem" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button title="Ẩn/hiện bộ lọc" style="float: right; margin: 0 0 5px 5px;" (click)="buttonFilter()">
                <i class="fas fa-filter"></i>
              </button>
              <button title="Tải lại" style="float: right; margin-bottom: 5px;" (click)="refesh()">
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>

          <div class="table-responsive" style="max-height: 60vh; margin-bottom: 15px;">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Mã SV</th>
                  <th>Họ tên</th>
                  <th>Ngày sinh</th>
                  <th>Nhà-Phòng</th>
                  <th>Học kỳ</th>
                  <th>Năm học</th>
                  <th>Điểm quy định</th>
                  <th>Điểm trừ</th>
                  <th>Điểm</th>
                  <th>Trạng thái</th>
                  <th>Chi tiết</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i=index; let odd = odd">
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Ngay_sinh_date | date:'dd/MM/yyyy' }}</td>
                  <td>{{ p.Ten_toa_nha }}-{{ p.Ten_phong }}</td>
                  <td>{{ p.Hoc_ky }}</td>
                  <td>{{ p.Nam_hoc }}</td>
                  <td>{{ p.Diem_quy_dinh }}</td>
                  <td>{{ p.Diem_tru }}</td>
                  <td>{{ p.Diem }}</td>
                  <td *ngIf="p.Trang_thai == 0" class="text-center text-success">Chưa Khoá điểm</td>
                  <td *ngIf="p.Trang_thai == 1" class="text-center text-danger">Đã khoá điểm</td>
                  <td class="text-center">
                    <button class="btn btn-sm btn-success" style="margin-right: 5px;"
                      (click)="modalDetail(UnlockModal,'xs','unlock',p)">
                      <i class="fas fa-unlock"></i>
                    </button>
                    <button class="btn btn-sm btn-danger" style="margin-right: 5px;"
                      (click)="modalDetail(lockModal,'xs','lock',p)">
                      <i class="fas fa-lock">
                      </i>
                    </button>
                    <button class="btn btn-sm btn-warning" (click)="modalDetail(DetailMoDal,'xl','Detail',p)">
                      <i class="far fa-eye">
                      </i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataTable.length==0">
                  <td colspan="11" class="text-center"> Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem|number }} kết quả, đã lọc được
                {{ totalItemFilter|number}} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{ element }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)">Cuối</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #DetailMoDal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100">Chi tiết vi phạm</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body" style="margin-top: 10px;">
    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Mã sinh viên</th>
            <th> Tên sinh viên</th>
            <th>Nội dung</th>
            <th>Điểm trừ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of dataViPham">
            <td>{{ p.Ma_sv}}</td>
            <td>{{ p.Ten_sv }}</td>
            <td>{{ p.Noi_dung }}</td>
            <td>{{ p.Diem_tru }}</td>
          </tr>
          <tr *ngIf="dataViPham.length==0">
            <td colspan="4" class="text-center"> Không có kết quả</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</ng-template>
<ng-template #ConfirmModal let-modal>
  <div class="modal-content">
    <div class="modal-body">

      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          white-space: nowrap;
        ">
        Bạn có muốn chốt điểm học kỳ {{Hoc_ky}} năm học {{Nam_hoc}}
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Confirm()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ConfirmModalUnlock let-modal>
  <div class="modal-content">
    <div class="modal-body">

      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          white-space: nowrap;
        ">
        Bạn có muốn mở điểm học kỳ {{Hoc_ky}} năm học {{Nam_hoc}}
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="ConfirmUnlock()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #UnlockModal let-modal>
  <div class="modal-content">
    <div class="modal-body">

      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          white-space: nowrap;
        ">
        Bạn có muốn mở điểm của sinh viên
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="unlock()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #lockModal let-modal>
  <div class="modal-content">
    <div class="modal-body">

      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          white-space: nowrap;
        ">
        Bạn có muốn khoá điểm của sinh viên
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="lock()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, from, Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

@Component({
  selector: 'app-portal-dang-ky',
  templateUrl: './portal-dang-ky.component.html',
  styleUrls: ['./portal-dang-ky.component.scss'],
})
export class PortalDangKyComponent implements OnInit {
  dtOptions: DataTables.Settings;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  dataDoiTuongChinhSach: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  ID_doi_tuong_TS: any;
  ID_khu_vuc_TS: any;
  Ma_loai_phong: any;
  Gia_phong: any;
  Mo_ta: any;
  Con_trong: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  getNull = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách loại phòng...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Datas_Input: any;
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  //-----------------------------------------------------//
  dataSinhVien: any;
  dataLoaiPhong: any[];
  dataSinhVienDangKy: any;
  dataKhoanNop: any;
  dataKhuVucTuyenSinh: any;
  dataPhongByID: any;
  dataDanToc: any;
  dataQuocTich: any;
  dataFile: any;
  FullName_get: string;

  Insert = new FormGroup({
    Ghi_chu: new FormControl(''),
    Tinh_trang_suc_khoe: new FormControl(''),
    Nguyen_vong: new FormControl(''),
    ID_doi_tuong_TS: new FormControl(''),
    ID_khu_vuc_TS: new FormControl(''),
    Ma_sv: new FormControl(''),
    Ho_ten: new FormControl(''),
    Email: new FormControl('', [
      Validators.required,
      Validators.email,
      removeSpaces,
    ]),
    Ngay_sinh: new FormControl(''),
    Loai_phong: new FormControl(''),
    Ruler: new FormControl(false),
    Dienthoai_canhan: new FormControl('', [Validators.required]),
  });

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RoomCategoryRegister: RoomCategoryRegisterService,
    private System: SystemService,
    private RegisterDorm: RegisterDormService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    var a = this.com.getPortalInfo();
    this.Token = a.Token;
    this.UserName_get = a.Info.UserName;
    this.Ma_sv_get = a.Info.UserName;
    this.FullName_get = a.Info.FullName;

    this.GetSinhVienByMaSV();
    this.GetDataSystem();
    this.Insert.get('ID_doi_tuong_TS').disable();
    this.Insert.get('ID_khu_vuc_TS').disable();
  }

  UserName_get: string;
  Ma_sv_get: string;
  Ten_phong: string;
  Ten_loai_phong: string;
  Tinh_trang_suc_khoe: string;
  Nguyen_vong: string;
  Ghi_chu: string;
  Trang_thai_duyet: number;
  Trang_thai_o: number;
  ID_dang_ky: number;

  So_tien_phai_nop: any;
  So_tien_cuoc: any;
  So_tien_da_nop: any;
  So_tien_con_phai_dong: any;
  Dang_o: any;
  Suc_chua: any;
  dataLichSuEmail: any;
  ID_doi_tuong_TS_Insert: number;
  ID_khu_vuc_TS_Insert: number;

  //-------------------------Lấy thông tin sv theo mã----------------------------//
  GetSinhVienByMaSV() {
    this.spinner.show();
    this.RegisterDorm.getSVbyMa(this.Ma_sv_get, this.Token).subscribe((res) => {
      if (res.Status == 1) {
        this.dataSinhVien = res.Data;
        this.ID_doi_tuong_TS_Insert = this.dataSinhVien[0].ID_doi_tuong_TS;
        this.ID_khu_vuc_TS_Insert = this.dataSinhVien[0].ID_khu_vuc_TS;
        this.Insert.patchValue({
          Ma_sv: this.Ma_sv_get,
          Ho_ten: this.dataSinhVien[0].Ho_ten,
          Ngay_sinh: this.dataSinhVien[0].Ngay_sinh.substring(0, 10),
          Email: this.dataSinhVien[0].Email,
          ID_doi_tuong_TS: this.dataSinhVien[0].ID_doi_tuong_TS,
          ID_khu_vuc_TS: this.dataSinhVien[0].ID_khu_vuc_TS,
          Dienthoai_canhan: this.dataSinhVien[0].Dienthoai_canhan,
        });
        if (this.dataSinhVien.length > 0) {
          this.GetListLoaiPhong(
            (this.dataSinhVien[0].ID_gioi_tinh = null
              ? 0
              : this.dataSinhVien[0].ID_gioi_tinh),
            this.dataSinhVien[0].ID_sv
          );
        }
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }

  ID_toa_nha_check: any;
  dataCheckTime: any;
  Tu_ngay: any;
  Den_ngay: any;
  Tu_ngay_before: any;
  Den_ngay_before: any;
  //-------------------------Lấy danh sách loại phòng theo giới tính + ID_sv----------------------------//
  GetListLoaiPhong(ID_gioi_tinh: number, ID_sv: number) {
    this.spinner.show();
    this.RoomCategoryRegister.getList(
      ID_gioi_tinh,
      ID_sv,
      this.Token
    ).subscribe((res) => {
      if (res.Status == 1) {
        this.dataLoaiPhong = !res.Data ? [] : res.Data;
        // this.dataCheckTime = res.DataQuyDinhDoiTuong;
        this.GetDangKiByID_sv(this.dataSinhVien[0].ID_sv);
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }

  Ten_phong_dang_ky: string = '';
  Ten_loai_phong_dang_ky: string = '';
  Trang_thai_duyet_dang_ky: any;
  Ten_trang_thai_o_dang_ky: string = '';
  Trang_thai_o_dang_ky: any;
  //-------------------------Lấy danh sách đăng ký sv theo id----------------------------//
  GetDangKiByID_sv(ID_sv: number) {
    this.spinner.show();
    this.RegisterDorm.getListRegisterByID(ID_sv, this.Token).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.dataSinhVienDangKy = res.Data;
          if (this.dataSinhVienDangKy.length > 0) {
            document.getElementById('__btnNotifi').click();
            // this.toastr.success(`Bạn đã đăng kí nội trú thành công lần mới nhất vào ngày ${this.dataSinhVienDangKy[0].Create_date.substring(0,10)} !`);
            this.Ten_phong_dang_ky = this.dataSinhVienDangKy[0].Ten_phong;
            this.Ten_loai_phong_dang_ky = this.dataSinhVienDangKy[0].Ten_loai_phong;
            this.Insert.patchValue({
              Tinh_trang_suc_khoe: this.dataSinhVienDangKy[0]
                .Tinh_trang_suc_khoe,
              Nguyen_vong: this.dataSinhVienDangKy[0].Nguyen_vong,
              Ghi_chu: this.dataSinhVienDangKy[0].Ghi_chu,
              // Loai_phong: this.dataRegisterStudent[0].ID_loai_phong,
              Ruler: true,
            });
            this.Trang_thai_duyet_dang_ky = this.dataSinhVienDangKy[0].Trang_thai_duyet;
            this.Ten_trang_thai_o_dang_ky = this.dataSinhVienDangKy[0].Ten_trang_thai_o;
            this.Trang_thai_o_dang_ky = this.dataSinhVienDangKy[0].Trang_thai_o;
            this.ID_dang_ky = this.dataSinhVienDangKy[0].ID_dang_ky;
            this.spinner.hide();
            //-------------------------Lấy ra danh sách khoản nộp----------------------------//
            // this.RegisterDorm.getListMonneyRoom(ID_sv, this.Token).subscribe(
            //   (res) => {
            //     if (res.Status == 1) {
            //       if (res.Data) {
            //         this.So_tien_phai_nop = this.dataKhoanNop[0].Sotienphainop;
            //         this.So_tien_cuoc = this.dataKhoanNop[0].Sotiencuoc;
            //         this.So_tien_da_nop = this.dataKhoanNop[0].Sotiendanop;
            //         this.So_tien_con_phai_dong = null
            //           ? this.dataKhoanNop[0].Sotienconphaidong
            //           : this.dataKhoanNop[0].Sotienconphaidong + this.dataKhoanNop[0].Sotiencuoc;
            //           this.spinner.hide();
            //       }
            //     }
            //     else {
            //       this.toastr.warning(res.Message);
            //       this.spinner.hide();
            //     }
            //   }
            // );
          } else {
            this.toastr.success('Bạn chưa đăng kí nội trú !');
          }
        } else {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        }
      }
    );
  }

  //-------------------------Lấy danh sách dataSystemm----------------------------//
  GetDataSystem() {
    this.spinner.show();
    this.System.Get_khuvuc_tuyensinh(this.Token).subscribe((res) => {
      this.dataKhuVucTuyenSinh = res;
      this.System.Get_danh_sach_dan_toc(this.Token).subscribe((res) => {
        this.dataDanToc = res;
        this.System.Get_doituong_chinhsach(this.Token).subscribe((res) => {
          this.dataDoiTuongChinhSach = res;
          this.System.Get_danh_sach_quoc_tich(this.Token).subscribe((res) => {
            this.dataQuocTich = res;
          });
        });
      });
    });
    this.spinner.hide();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'ViewInfoRoom') {
      if (!(this.Insert.value.Loai_phong > 0)) {
        this.toastr.warning('Bạn chưa chọn loại phòng !');
        return false;
      }
      this.submitted = false;
      this.dataPhongByID = this.dataLoaiPhong.filter(
        (x) => x.ID == this.Insert.value.Loai_phong
      );
      this.Ma_loai_phong = this.dataPhongByID[0].Ma_loai_phong;
      this.Con_trong = this.dataPhongByID[0].Con_trong;
      this.Dang_o = this.dataPhongByID[0].Dang_o;
      this.Gia_phong = this.dataPhongByID[0].Gia_phong;
      this.Mo_ta = this.dataPhongByID[0].Mo_ta;
      this.Suc_chua = this.dataPhongByID[0].Suc_chua;
      this.Ten_loai_phong = this.dataPhongByID[0].Ten_loai_phong;
    } else if (Loai == 'Add') {
      this.submitted = false;
      this.Insert.reset();
      this.titleModal = 'Thêm mới phòng';
      this.checkInsert = true;
    } else if (Loai == 'Destroy') {
      this.submitted = false;
      this.Mo_ta = Data.Mo_ta;
    } else if (Loai == 'Aprove') {
      this.titleModal = 'Chính sách quy định ban quản lý KTX';
      this.submitted = false;
      this.RegisterDorm.getFile(this.Token).subscribe((res) => {
        if (res.Status == 1) {
          this.dataFile = res.Data;
        } else {
          this.toastr.warning(res.Data);
        }
      });
    } else if (Loai == 'Notification') {
      this.titleModal = 'Thông báo ';
      this.submitted = false;
    }
  }

  get checkvalue() {
    return this.Insert.controls;
  } //check values
  cancelRegister() {
    this.RegisterDorm.cancelRegister(
      this.com.getPortalInfo().Info.UserID,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.modalService.dismissAll('');
      } else {
        this.toastr.warning(z.Message);
        this.modalService.dismissAll('ConfirmRegisterModal');
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      this.modalService.dismissAll('ConfirmRegisterModal');
      this.toastr.warning('Bạn chưa nhập đầy đủ thông tin !');
      return false;
    }
    if (!(this.Insert.value.Loai_phong > 0)) {
      this.toastr.warning(
        'Chưa lấy được thông tin phòng mà bạn đăng ký. Lưu thất bại !'
      );
      this.modalService.dismissAll('ConfirmRegisterModal');
      return false;
    }
    if (this.Insert.value.Ruler) {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //As January is 0.
      var yyyy = today.getFullYear();
      this.Datas_Input = {
        ID_doi_tuong: this.ID_doi_tuong_TS_Insert,
        Ma_sv:
          this.Insert.value.Ma_sv == ''
            ? this.UserName_get
            : this.dataSinhVien[0].Ma_sv,
        So_the: this.Insert.value.Ma_sv,
        Ghi_chu: this.Insert.value.Ghi_chu,
        Ngay_cap_the: yyyy + '-' + mm + '-' + dd,
        Nguyen_vong: this.Insert.value.Nguyen_vong,
        Tinh_trang_suc_khoe: this.Insert.value.Tinh_trang_suc_khoe,
        La_truong_phong: false,
        Ten_trang_thai_o: 0,
        Ten_trang_thai_the: 0,
        Ten_trang_thai_duyet: 1,
        Trang_thai_o: 0,
        ID_sv: this.dataSinhVien[0].ID_sv,
        ID_phong: null,
        Email: this.Insert.value.Email,
        ID_loai_phong: this.Insert.value.Loai_phong,
        Gia_loai_phong: this.dataLoaiPhong.filter(
          (x) => x.ID == this.Insert.value.Loai_phong
        )[0].Gia_phong,
        ID_lop: this.dataSinhVien[0].ID_lop,
        Ho_ten: this.Insert.value.Ho_ten,
        Ten_loai_phong: this.dataLoaiPhong.filter(
          (x) => x.ID == this.Insert.value.Loai_phong
        )[0].Ten_loai_phong,
        Ma_loai_phong: this.dataLoaiPhong.filter(
          (x) => x.ID == this.Insert.value.Loai_phong
        )[0].Ma_loai_phong,
        ID_gioi_tinh: this.dataSinhVien[0].ID_gioi_tinh,
        ID_toa_nha: this.dataLoaiPhong[0].Id_toa_nha,
      };
      let Data_student = {
        ID_sv: this.dataSinhVien[0].ID_sv,
        Email: this.Insert.value.Email,
        ID_doi_tuong_TS: this.ID_doi_tuong_TS_Insert,
        ID_khu_vuc_TS: this.ID_khu_vuc_TS_Insert,
        Dienthoai_canhan: this.Insert.value.Dienthoai_canhan,
      };
      if (this.dataSinhVienDangKy.length == 0 || this.Trang_thai_duyet == 2) {
        this.spinner.show();
        this.RegisterDorm.Update_Student(Data_student, this.Token).subscribe(
          (z) => {
            if (z.Status == 5) {
              this.toastr.error('Xảy ra lỗi sửa lại thông tin sinh viên.');
            } else if (z.Status == 1) {
              this.RegisterDorm.InsertStudent(
                this.Datas_Input,
                this.Token
              ).subscribe((z) => {
                if (z.Status == 5) {
                  this.toastr.warning(z.Message);
                } else if (z.Status == 8) {
                  this.toastr.warning(z.Message);
                } else if (z.Status == 2) {
                  this.toastr.warning(z.Message);
                } else if (z.Status == 1) {
                  this.toastr.success(z.Message);
                }
                this.modalService.dismissAll('AddModal');
                this.spinner.hide();
                this.GetSinhVienByMaSV();
                // setTimeout(()=> {
                // window.location.reload();
                // },4000)
              });
            }
          }
        );
      } else {
        this.toastr.warning(
          'Bạn đã đăng ký, thông tin phòng được hiển thị phía dưới !'
        );
        this.spinner.hide();
      }
    } else {
      this.toastr.warning('Bạn cần chấp nhận nội quy chính sách ở ký túc xá');
      this.modalService.dismissAll('ConfirmRegisterModal');
    }
  }
}

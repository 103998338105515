<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quy định đối tượng</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'xl', 'Add', '')"
          style="float: right; margin-left: 10px;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row">
          <div class="col-md-3">
            <label>Tên hệ</label>
            <input
              type="text"
              class="form-control"
              formControlName="He_filter"
              (keyup)="filterById()"
              placeholder="Tìm kiếm theo tên hệ..."
            />
          </div>
          <div class="col-md-3">
            <label>Tên khoa</label>
            <input
              type="text"
              class="form-control"
              formControlName="Khoa_filter"
              (keyup)="filterById()"
              placeholder="Tìm kiếm theo tên khoa..."
            />
          </div>
          <div class="col-md-3">
            <label>Khóa học</label>
            <input
              type="text"
              class="form-control"
              formControlName="Khoa_hoc_filter"
              (keyup)="filterById()"
              placeholder="Tìm kiếm theo khóa học..."
            />
          </div>
          <div class="col-md-3">
            <label>Năm học</label>
            <select
              class="form-control"
              formControlName="Nam_hoc_filter"
              (change)="filterById()"
            >
              <option value="">Tất cả</option>
              <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{
                i.name
              }}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.ID_he }}</td>
                      <td>{{ p.ID_he > 0 ? p.Ten_he : 'Tất cả' }}</td>
                      <td>{{ p.Ten_khoa ? p.Ten_khoa : 'Tất cả' }}</td>
                      <td>{{ p.Khoa_hoc == 0 ? 'Tất cả' : p.Khoa_hoc }}</td>
                      <td>{{ p.Nam_hoc == 0 ? 'Tất cả' : p.Nam_hoc }}</td>
                      <td>
                        {{
                          p.Tu_ngay == '0001-01-01T00:00:00'
                            ? ''
                            : (p.Tu_ngay | date: 'dd/MM/yyyy HH:mm')
                        }}
                      </td>
                      <td>
                        {{
                          p.Den_ngay == '0001-01-01T00:00:00'
                            ? ''
                            : (p.Den_ngay | date: 'dd/MM/yyyy HH:mm')
                        }}
                      </td>
                      <td *ngIf="p.Gioi_tinh == null">Tất cả</td>
                      <td *ngIf="p.Gioi_tinh == true">Nữ</td>
                      <td *ngIf="p.Gioi_tinh == false">Nam</td>
                      <td *ngIf="p.Hoc_ky_3 == true">
                        <input type="checkbox" checked disabled />
                      </td>
                      <td *ngIf="p.Hoc_ky_3 == false || p.Hoc_ky_3 == null">
                        <input type="checkbox" disabled />
                      </td>
                      <td>
                        {{ p.He_so_tien }}
                      </td>
                      <td *ngIf="p.Doi_tuong_cs == true">
                        <input type="checkbox" checked disabled />
                      </td>
                      <td
                        *ngIf="
                          p.Doi_tuong_cs == false || p.Doi_tuong_cs == null
                        "
                      >
                        <input type="checkbox" disabled />
                      </td>
                      <td>{{ p.SoLuongToaNha }}</td>
                      <td>
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'xl', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-trash-alt" title="Xoá thiết bị"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Quy định đối tượng</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row" *ngIf="checkInsert">
        <div class="col-md-3">
          <label>Tên hệ</label>
          <select
            class="form-control"
            formControlName="ID_he"
            (change)="changeHe()"
          >
            <option *ngFor="let p of dataHe" value="{{ p.ID_he }}">{{
              p.Ten_he
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Tên khoa</label>
          <select class="form-control" formControlName="ID_khoa">
            <option value="0">Tất cả</option>
            <option *ngFor="let p of dataKhoa" value="{{ p.ID_khoa }}">{{
              p.Ten_khoa
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Khóa học</label>
          <select class="form-control" formControlName="Khoa_hoc">
            <option value="0">Tất cả</option>
            <option *ngFor="let p of dataKhoaHoc" value="{{ p }}">{{
              p
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Năm học</label>

          <select class="form-control" formControlName="Nam_hoc">
            <option value="0">Tất cả</option>
            <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{
              i.name
            }}</option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="!checkInsert">
        <div class="col-md-3">
          <label>Tên hệ</label>
          <select
            class="form-control"
            formControlName="ID_he"
            (change)="changeHe()"
            disabled
          >
            <option *ngFor="let p of dataHe" value="{{ p.ID_he }}">{{
              p.Ten_he
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Tên khoa</label>
          <select class="form-control" formControlName="ID_khoa" disabled>
            <option value="0">Tất cả</option>
            <option *ngFor="let p of dataKhoa" value="{{ p.ID_khoa }}">{{
              p.Ten_khoa
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Khóa học</label>
          <select class="form-control" formControlName="Khoa_hoc" disabled>
            <option value="0">Tất cả</option>
            <option *ngFor="let p of dataKhoaHoc" value="{{ p }}">{{
              p
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Năm học</label>
          <select class="form-control" formControlName="Nam_hoc" disabled>
            <option value="0">Tất cả</option>
            <option *ngFor="let i of Nam_hoc_arr" value="{{ i.value }}">{{
              i.name
            }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>Giới tính</label>
          <select class="form-control" formControlName="Gioi_tinh">
            <option value="null">Tất cả</option>
            <option value="true">Nữ</option>
            <option value="false">Nam</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Học kỳ 3</label>
          <select class="form-control" formControlName="Hoc_ky_3">
            <option value="false">Không áp dụng</option>
            <option value="true">Áp dụng</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Đối tượng chính sách</label>
          <select class="form-control" formControlName="Doi_tuong_cs">
            <option value="false">Không áp dụng</option>
            <option value="true">Áp dụng</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="require">Hệ số tiền</label>
          <input
            type="number"
            class="form-control"
            formControlName="He_so_tien"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.He_so_tien.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.He_so_tien.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.He_so_tien.errors.required">
              Không được bỏ trống
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label class="require">Từ ngày</label>
          <input
            type="datetime-local"
            class="form-control"
            formControlName="Tu_ngay"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Tu_ngay.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Tu_ngay.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Tu_ngay.errors.required">
              Từ ngày không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="require">Đến ngày</label>
          <input
            type="datetime-local"
            class="form-control"
            formControlName="Den_ngay"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Den_ngay.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Den_ngay.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Den_ngay.errors.required">
              Đến ngày không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="modal-body">
      <div class="row" style="margin-top: 10px;">
        <div class="col-md-12">
          <div
            class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
          >
            <table
              datatable
              [dtOptions]="dtOptions2"
              id="TableToaNha"
              class="table-bordered dataTables_wrapper no-footer"
            >
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="SelectALL"
                      (change)="CheckAll()"
                      value="SelectAll"
                    />
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataToaNha">
                  <td>
                    <input
                      type="checkbox"
                      (change)="SelectRow(p.ID_ToaNha)"
                      name="SelectData"
                      id="checkbox{{ p.ID_ToaNha }}"
                      [checked]="p.Selected"
                      value="{{ p.ID_ToaNha }}"
                    />
                  </td>
                  <td>{{ p.Ma_ToaNha }}</td>
                  <td>{{ p.Ten_ToaNha }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.col -->
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="OnSubmit()"
        >
          <i class="fas fa-save"></i> Lưu
        </button>
        <button
          style="margin-left: 1em;"
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </div>
</ng-template>

<section class="content-header" style="padding: 5px !important;">
  <div class="container-fluid">
    <div class="row mb2">
      <div class="col-md-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"><i class="fas fa-home"></i>Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Quy định khoản nộp</li>
        </ol>
      </div>
      <div class="col-md-6">
        <a [routerLink]="['/admin/add-payment-regulation']"><button class="btn btn-success btn-sm"  style="float: right;">Thêm mới</button></a>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <label>Loại khoản thu</label>
          <select name="ID_khoan_thu_filter" class="form-control" (change)="filterById()"
            [(ngModel)]="ID_khoan_thu_filter" >
            <option value="0">Tìm kiếm loại khoản thu...</option>
            <option *ngFor="let p of dataKhoanNop;" value="{{p.Gia_tri}}">{{p.Ten_tham_so}}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Mã sinh viên</label>
          <input type="text" class="form-control" (keyup)="filterById()" [(ngModel)]="Ma_sv_filter" placeholder="Tìm kiếm mã sinh viên...">
        </div>
        <div class="col-sm-3">
          <label>Họ và tên</label>
          <input type="text" name="" class="form-control" (keyup)="filterById()" [(ngModel)]="Ho_ten_filter" placeholder="Tìm kiếm tên sinh viên...">
        </div>
        <div class="col-sm-3">
          <label>Tháng</label>
          <select class="form-control" (change)="filterById()" [(ngModel)]="Thang_filter">
            <option value="0">Tìm kiếm tháng...</option>
            <option *ngFor="let i of MonthOption" value="{{i.value}}">{{i.name}}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Năm</label>
          <select name="Nam" class="form-control" (change)="filterById()" [(ngModel)]="Nam_filter">
            <option value="0">Tìm kiếm năm...</option>
            <option *ngFor="let i of yearArr" value="{{i.value}}">{{i.name}}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Học kỳ</label>
          <select name="Hoc_ky_filter" id="Hoc_ky_filter" class="form-control" (change)="filterById()"
            [(ngModel)]="Hoc_ky_filter">
            <option value="0">Tìm kiếm học kỳ...</option>
            <option value="1">Học kỳ I</option>
            <option value="2">Học kỳ II</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Năm học</label>
          <select name="Nam_hoc_filter" class="form-control" (change)="filterById()" [(ngModel)]="Nam_hoc_filter">
            <option value="0">Tìm kiếm năm học...</option>
            <option *ngFor="let i of Nam_hoc_Arr" value="{{i.value}}">{{i.name}}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Trạng thái</label>
          <select class="form-control" (change)="filterById()" [(ngModel)]="Trang_thai_filter">
            <option value="0">Tìm kiếm trạng thái...</option>
            <option value="false">Chưa lập biên lai</option>
            <option value="true">Đã lập biên lai</option>
          </select>
        </div>

      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <label>Cở sở</label>
          <select class="form-control" >
            <option value="0">Tìm kiếm theo cơ sở</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Tòa nhà</label>
          <select class="form-control">
            <option value="0">Tìm kiếm theo tòa nhà</option>
          </select>
        </div>
        <div class="col-sm-3">
          <label>Phòng</label>
          <select class="form-control">
            <option value="0">Tìm kiếm theo phòng</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer" id="dataTable">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable;">
                    <td>{{ p.ID_quy_dinh_khoan_nop }}</td>
                    <td>{{ p.Ten_thu_chi }}</td>
                    <td>{{ p.Ma_sv }}</td>
                    <td>{{ p.Ho_ten }}</td>
                    <td>{{ p.Ngay_sinh | date:'dd/MM/yyyy' }}</td>
                    <td>{{ p.Hoc_ky}}</td>
                    <td>{{ p.Nam_hoc }}</td>
                    <td>{{ p.Thang }}</td>
                    <td>{{ p.Nam }}</td>
                    <td>{{ p.So_tien|currency:'VND'}}</td>
                    <td>{{ p.Phan_tram_mien_giam}}</td>
                    <td>{{ p.So_tien_mien_giam | currency:'VND'}}</td>
                    <td class="text-danger">{{ p.Thanh_tien | currency:'VND'}}</td>
                    <td *ngIf="!p.Trang_thai"> <button class="btn btn-sm btn-danger"
                        (click)="open(DeleteModal, '', 'Delete', p)" style="margin-right: 7px;">
                        <i class="fas fa-trash-alt"></i></button>
                    </td>
                    <td *ngIf="p.Trang_thai">Đã lập biên lai</td>
                    <td>{{p.ID_khoan_thu}}</td>
                    <td>{{p.Trang_thai}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i class="fas fa-trash-alt" style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
      </div>
      <p
        style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')">
          Hủy bỏ
        </button>
        <button type="button" class="btn btn-md btn-success" (click)="Delete()" style="margin-left: 1em;">
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class StatisticalService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  GetAll(token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'Statistical/ThongKeKhoanNop_List',
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Excel(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Statistical/Excel', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LoadKhoaHoc(ID_he: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'RegulationObject/LoadByHe?ID_he=' + ID_he,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_List(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Statistical/ThongKe_List', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_List_CountFilter(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Statistical/ThongKe_List_CountFilter',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_List_Count(token: any): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Statistical/ThongKe_List_Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Export_BaoCaoThongKe(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'BaoCao/BaoCaoThongKe', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-3">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Đăng ký</li>
        </ol>
      </div>
      <div class="col-md-9">
        <button
          class="btn btn-primary btn-sm"
          (click)="getList()"
          style="float: right; margin-left: 10px;"
        >
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button
          class="btn btn-primary btn-sm"
          (click)="open(ThemModal, 'xl', 'Add', '')"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Đăng ký
        </button>
        <button
          class="btn btn-sm btn-info"
          (click)="open(DuyetDKList, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fa fa-lock"></i> Khóa
        </button>
        <button
          class="btn btn-sm btn-secondary"
          (click)="open(HuyDuyetList, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-trash-alt"></i> Hủy ĐK
        </button>
        <button
          class="btn btn-sm btn-danger"
          (click)="open(TuChoiList, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-window-close"></i> Từ chối
        </button>

        <button
          class="btn btn-success btn-sm"
          (click)="open(Import_Excel, 'xs', 'Import_Excel', '')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-file-import"></i> Import Excel
        </button>

        <button
          class="btn btn-success btn-sm"
          (click)="Excel()"
          style="float: right; margin-right: 5px;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label>Mã sinh viên</label>
          <input
            type="text"
            class="form-control"
            (change)="getList()"
            [(ngModel)]="FillMaSV"
            placeholder="Nhập mã sinh viên ..."
          />
        </div>
        <div class="col-md-2">
          <label>Tên sinh viên</label>
          <input
            type="text"
            class="form-control"
            (change)="getList()"
            [(ngModel)]="FillHoTen"
            placeholder="Nhập tên sinh viên..."
          />
        </div>
        <div class="col-md-2">
          <label>Ngày sinh</label>
          <input
            type="date"
            [(ngModel)]="FillNgaySinh"
            class="form-control cus-fs"
          />
        </div>
        <div class="col-md-2">
          <label>Giới tính</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillGioiTinh"
          >
            <ng-option value="">Chọn giới tính</ng-option>
            <ng-option [value]="0">Nam</ng-option>
            <ng-option [value]="1">Nữ</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Đối tượng chính sách</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillDoiTuong"
          >
            <ng-option value="">Chọn đối tượng</ng-option>
            <ng-option [value]="-1" selected>
              Là đối tượng chính sách bất kỳ</ng-option
            >
            <ng-option
              *ngFor="let item of dataDoiTuongChinhSach"
              [value]="item.ID_dt"
            >
              {{ item.Ten_dt }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Loại phòng</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillLoaiPhong"
          >
            <ng-option value="">Chọn loại phòng</ng-option>
            <ng-option *ngFor="let item of dataTypeRoom" [value]="item.ID">
              {{ item.Ten_loai_phong }}</ng-option
            >
          </ng-select>
        </div>
      </div>
      <div class="row" style="padding-top: 10px;">
        <div class="col-md-2">
          <label>Trạng thái đăng ký</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillTrangThaiDangKy"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option [value]="0"> Chưa duyệt</ng-option>
            <ng-option [value]="1"> Khóa duyệt</ng-option>
            <ng-option [value]="2"> Hủy duyệt</ng-option>
            <ng-option [value]="3"> Từ chối duyệt</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Trạng thái ở</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillTrangThaiO"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option [value]="0"> Chưa ở</ng-option>
            <ng-option [value]="1"> Đang ở</ng-option>
            <ng-option [value]="2"> Đã ra</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Hệ</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillHe"
          >
            <ng-option value="">Chọn hệ</ng-option>
            <ng-option *ngFor="let item of dataHe" [value]="item.Ten_he">
              {{ item.Ten_he }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Khoa</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="FillKhoa"
          >
            <ng-option value="">Chọn khoa</ng-option>
            <ng-option *ngFor="let item of dataKhoa" [value]="item.Ten_khoa">
              {{ item.Ten_khoa }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Khóa học</label>
          <input
            type="text"
            class="form-control"
            (change)="getList()"
            [(ngModel)]="FillKhoaHoc"
            placeholder="Nhập khóa học..."
          />
        </div>
        <div class="col-md-2">
          <label>Năm học</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="Nam_hoc"
          >
            <ng-option *ngFor="let i of Nam_hoc_arr" [value]="i.value">{{
              i.name
            }}</ng-option>
          </ng-select>
        </div>
      </div>
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-2">
            <label>Dân tộc</label>
            <ng-select (change)="getList()" formControlName="ID_dan_toc_filter">
              <ng-option value="0" selected>Chọn dân tộc</ng-option>
              <ng-option *ngFor="let p of dataDanToc" [value]="p.ID_dan_toc">{{
                p.Dan_toc
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <label>Tôn giáo</label>
            <input
              type="text"
              (change)="getList()"
              formControlName="Ton_giao_filter"
              class="form-control"
              placeholder="Tôn giáo"
            />
          </div>

          <div class="col-md-2">
            <label>Tỉnh thường trú</label>
            <ng-select (change)="getList()" formControlName="ID_tinh_tt_filter">
              <ng-option value="0" selected>Tỉnh thường trú</ng-option>
              <ng-option *ngFor="let p of lstTinh" [value]="p.ID_tinh">{{
                p.Ten_tinh
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <label>Điện thoại</label>
            <input
              type="text"
              (change)="getList()"
              formControlName="Dienthoai_canhan_filter"
              class="form-control"
              placeholder="Điện thoại"
            />
          </div>
          <div class="col-md-2">
            <label>Địa chỉ mail</label>
            <input
              type="text"
              (change)="getList()"
              formControlName="Mail_filter"
              class="form-control"
              placeholder="Địa chỉ email"
            />
          </div>
          <div class="col-md-2">
            <label>Học kỳ</label>
            <ng-select (change)="getList()" formControlName="Hoc_ky_filter">
              <ng-option value="0" selected>Chọn học kỳ</ng-option>
              <ng-option value="1">Học kỳ 1</ng-option>
              <ng-option value="2">Học kỳ 2</ng-option>
              <ng-option value="3">Học kỳ 3</ng-option>
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>

          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      [checked]="isAllCheckBoxChecked()"
                      (change)="checkAllCheckBox($event)"
                    />
                  </th>
                  <th>Mã SV</th>
                  <th>Tên sinh viên</th>
                  <th>Ngày sinh</th>
                  <th>Hệ</th>
                  <th>Khoa</th>
                  <th>Khóa</th>
                  <th>Loại phòng</th>
                  <th>Ngày ĐK</th>
                  <th>Trạng thái duyệt</th>
                  <th>Trạng thái ở</th>
                  <th>Đợt đăng ký</th>
                  <th>
                    <div style="width: 200px;">Ghi chú</div>
                  </th>
                  <th>Ngày duyệt</th>
                  <th>Ngày xếp phòng</th>
                  <th>
                    <div style="width: 200px;">Lý do</div>
                  </th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                  <td>
                    <input
                      type="checkbox"
                      value="{{ p.ID_dang_ky }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataTable[i].checked"
                    />
                  </td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                  <td style="white-space: nowrap;">{{ p.He_nhap_hoc }}</td>
                  <td style="white-space: nowrap;">
                    {{ p.Ten_khoa_nhap_hoc }}
                  </td>
                  <td>{{ p.Khoa_hoc_nhap_hoc }}</td>
                  <td style="white-space: nowrap;">{{ p.Ten_loai_phong }}</td>
                  <td>{{ p.Create_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Ten_trang_thai_duyet }}</td>
                  <td>{{ p.Ten_trang_thai_o }}</td>
                  <td>{{ p.Dot }}</td>
                  <td>{{ p.Ghi_chu }}</td>
                  <td>{{ p.Approve_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Sort_room_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Ly_do }}</td>
                  <td style="white-space: nowrap; text-align: center;">
                    <button
                      class="btn btn-sm btn-info"
                      (click)="open(DuyetDK, 'xs', 'DuyetDK', p)"
                      style="margin-right: 2px;"
                    >
                      <i class="fa fa-lock" ngbTooltip="Khóa đăng ký"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary"
                      (click)="open(HuyDuyet, 'xs', 'huyDuyet', p)"
                      style="margin-right: 2px;"
                    >
                      <i
                        class="fas fa-trash-alt"
                        aria-hidden="true"
                        ngbTooltip="Hủy đăng ký"
                      ></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="open(TuChoi, 'xs', 'tuChoi', p)"
                      style="margin-right: 2px;"
                    >
                      <i
                        class="fas fa-window-close"
                        ngbTooltip="Từ chối đăng ký"
                      ></i>
                    </button>

                    <button
                      class="btn btn-sm btn-warning"
                      (click)="open(ThemModal, 'xl', 'Edit', p)"
                      style="margin-right: 2px;"
                    >
                      <i class="fas fa-edit" ngbTooltip="Sửa đăng ký"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataTable.length == 0">
                  <td colspan="16" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Mã sinh viên hoặc CMND</label>
          <input
            class="form-control"
            placeholder="Nhập mã sinh viên hoặc CMND"
            type="text"
            id="code"
            formControlName="code"
            [(ngModel)]="code"
          />
        </div>

        <div class="float-sm-left" style="padding: 30px;">
          <button
            type="button"
            class="btn btn-md btn-success"
            (click)="search()"
            style="margin-left: 1em;"
          >
            <i class="fas fa-search"></i> Tìm sinh viên
          </button>
        </div>
      </div>
      <div class="container">
        <ngb-tabset [destroyOnHide]="false" style="font-weight: bold;">
          <ngb-tab>
            <ng-template ngbTabTitle
              ><strong>Thông tin đăng ký ký túc xá</strong></ng-template
            >
            <ng-template ngbTabContent>
              <div class="cus-box">
                <div class="row">
                  <div class="col-md-4">
                    <label class="col-md-10">Mã SV</label>
                    <input
                      class="form-control"
                      placeholder="Nhập mã sv"
                      type="text"
                      id="Ma_sv"
                      formControlName="Ma_sv"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Họ tên</label>
                    <input
                      class="form-control"
                      placeholder="Nhập họ tên"
                      type="text"
                      id="Ho_ten"
                      formControlName="Ho_ten"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Ngày sinh</label>
                    <input
                      class="form-control"
                      placeholder="Nhập ngày sinh"
                      type="date"
                      id="Ngay_sinh"
                      formControlName="Ngay_sinh"
                      disabled
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="col-md-10 require">Số thẻ</label>
                    <input
                      class="form-control"
                      required
                      placeholder="Nhập số thẻ"
                      type="text"
                      formControlName="So_the"
                      [ngClass]="{
                        'is-invalid': submitted && checkvalue.So_the.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && checkvalue.So_the.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="checkvalue.So_the.errors.required">
                        Số thẻ không được bỏ trống!
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Ngày cấp thẻ</label>
                    <input
                      class="form-control"
                      placeholder="Nhập ngày cấp thẻ"
                      type="date"
                      id="Ngay_cap_the"
                      formControlName="Ngay_cap_the"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Loại phòng</label>
                    <div class="input-group">
                      <select class="form-control" formControlName="Loai_phong">
                        <option value="0"> Chọn loại phòng</option>
                        <option
                          *ngFor="let p of dataTypeRoomGioiTinh"
                          value="{{ p.ID }}"
                          >{{ p.Ten_loai_phong }} - {{ p.Gia_phong | number }}đ
                        </option>
                      </select>
                      <div class="input-group-append">
                        <span
                          class="input-group-text"
                          (click)="open(MotaModal, 'lg', 'View', '')"
                          ><i class="fas fa-search"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-4">
                    <label class="col-md-10">Đối tượng chính sách</label>
                    <select
                      class="form-control"
                      formControlName="ID_dt"
                      disabled
                    >
                      <option value="0">Chọn đối tượng chính sách</option>
                      <option
                        *ngFor="let item of dataDoiTuongChinhSach"
                        value="{{ item.ID_dt }}"
                      >
                        {{ item.Ten_dt }}</option
                      >
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Khu vực</label>
                    <select
                      class="form-control"
                      formControlName="ID_kv"
                      disabled
                    >
                      <option value="0">Chọn khu vực</option>
                      <option
                        *ngFor="let item of dataKhuVucTuyenSinh"
                        value="{{ item.ID_kv }}"
                      >
                        {{ item.Ten_kv }}</option
                      >
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Tình trạng sức khỏe</label>
                    <input
                      class="form-control"
                      placeholder="Nhập tình trạng sức khỏe"
                      type="text"
                      id="Tinh_trang_suc_khoe"
                      formControlName="Tinh_trang_suc_khoe"
                    />
                  </div>
                </div>
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-6">
                    <label class="col-md-10">Nguyện vọng đăng ký</label>
                    <textarea
                      type="text"
                      placeholder="Nhập nguyện vọng đăng ký"
                      class="form-control"
                      type="text"
                      id="Nguyen_vong"
                      formControlName="Nguyen_vong"
                    ></textarea>
                  </div>
                  <div class="col-md-6">
                    <label class="col-md-10">Ghi chú</label>
                    <textarea
                      type="text"
                      placeholder="Nhập ghi chú"
                      class="form-control"
                      id="Ghi_chu"
                      formControlName="Ghi_chu"
                    ></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab style="font-weight: bold;" title="Thông tin sinh viên">
            <ng-template ngbTabContent>
              <div class="cus-box">
                <div class="row">
                  <div class="col-md-4">
                    <label class="col-md-10">Mã SV</label>
                    <input
                      class="form-control"
                      placeholder="Nhập mã sv"
                      type="text"
                      id="Ma_sv"
                      formControlName="Ma_sv"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Họ tên</label>
                    <input
                      class="form-control"
                      placeholder="Nhập họ tên"
                      type="text"
                      id="Ho_ten"
                      formControlName="Ho_ten"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Ngày sinh</label>
                    <input
                      class="form-control"
                      placeholder="Nhập ngày sinh"
                      type="date"
                      id="Ngay_sinh"
                      formControlName="Ngay_sinh"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Giới tính</label>
                    <select
                      class="form-control"
                      formControlName="ID_gioi_tinh"
                      disabled
                    >
                      <option value="2">Chọn giới tính</option>
                      <option value="0">Nam</option>
                      <option value="1">Nữ</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">CMND</label>
                    <input
                      class="form-control"
                      placeholder="Nhập cmnd"
                      type="text"
                      id="CMND"
                      formControlName="CMND"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Ngày cấp</label>
                    <input
                      class="form-control"
                      placeholder="Nhập ngày cấp"
                      type="date"
                      id="Ngay_cap_CMND"
                      formControlName="Ngay_cap_CMND"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Nơi cấp</label>
                    <input
                      class="form-control"
                      placeholder="Nhập nơi cấp"
                      type="text"
                      id="ID_noi_cap_CMND"
                      formControlName="ID_noi_cap_CMND"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Tôn giáo</label>
                    <input
                      class="form-control"
                      placeholder="Nhập tôn giáo"
                      type="text"
                      id="Ton_giao"
                      formControlName="Ton_giao"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Số hộ chiếu</label>
                    <input
                      class="form-control"
                      placeholder="Nhập số hộ chiếu"
                      type="text"
                      id="So_ho_chieu"
                      formControlName="So_ho_chieu"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Ngày hết hạn visa</label>
                    <input
                      class="form-control"
                      placeholder="Nhập ngày hết hạn visa"
                      type="date"
                      id="Ngay_het_han_visa"
                      formControlName="Ngay_het_han_visa"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Điện thoại</label>
                    <input
                      class="form-control"
                      placeholder="Nhập điện thoại"
                      type="text"
                      id="Dienthoai_canhan"
                      formControlName="Dienthoai_canhan"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Email</label>
                    <input
                      class="form-control"
                      placeholder="Nhập email"
                      type="text"
                      id="Email"
                      formControlName="Email"
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Địa chỉ</label>
                    <input
                      class="form-control"
                      placeholder="Nhập địa chỉ"
                      type="text"
                      id="Dia_chi_tt"
                      formControlName="Dia_chi_tt"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Dân tộc</label>
                    <select
                      class="form-control"
                      formControlName="ID_dan_toc"
                      disabled
                    >
                      <option value="0">Chọn dân tộc</option>
                      <option
                        *ngFor="let item of dataDanToc"
                        value="{{ item.ID_dan_toc }}"
                      >
                        {{ item.Dan_toc }}</option
                      >
                    </select>
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Quốc tịch</label>
                    <select
                      class="form-control"
                      formControlName="ID_quoc_tich"
                      disabled
                    >
                      <option value="0">Chọn quốc tịch</option>
                      <option
                        *ngFor="let item of dataQuocTich"
                        value="{{ item.ID_quoc_tich }}"
                      >
                        {{ item.Quoc_tich }}</option
                      >
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-10">Hệ nhập học</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      id="He_nhap_hoc"
                      formControlName="He_nhap_hoc"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Khoa nhập học</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      id="Ten_khoa_nhap_hoc"
                      formControlName="Ten_khoa_nhap_hoc"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Khóa nhập học</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      id="Khoa_hoc_nhap_hoc"
                      formControlName="Khoa_hoc_nhap_hoc"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Ngành nhập học</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      id="Ten_nganh_nhap_hoc"
                      formControlName="Ten_nganh_nhap_hoc"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Chuyên ngành nhập học</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      id="Ten_chuyen_nganh_nhap_hoc"
                      formControlName="Ten_chuyen_nganh_nhap_hoc"
                      disabled
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="col-md-10">Lớp</label>
                    <input
                      class="form-control"
                      placeholder=""
                      type="text"
                      id="Lop"
                      formControlName="Lop"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #Delete let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn xóa đăng ký cho sinh viên?
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Delete_Register()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #DuyetDKList let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fa fa-lock"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn khóa đăng ký cho sinh viên?
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="DuyetDangKy_ListID()"
        >
          <i class="fa fa-lock"></i> Khóa đăng ký
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          <i class="far fa-times-circle"></i> Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #DuyetDK let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fa fa-lock"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn khóa đăng ký cho sinh viên?
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Approve_Register()"
        >
          <i class="fa fa-lock"></i> Khóa đăng ký
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          <i class="far fa-times-circle"></i> Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Import_Excel let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="container">
        <h5 style="text-align: center;">
          Import đăng ký cho sinh viên bằng file excel
        </h5>
        <a
          style="text-align: center; text-decoration-line: underline;"
          download="filename"
          target="_blank"
          href="/assets/MauDangKy.xlsx"
        >
          File mẫu Excel
        </a>

        <div class="row">
          <div class="col-md-12 form-group">
            <input
              type="file"
              class="form-control"
              (change)="uploadedFile($event)"
              placeholder="Upload file"
              accept=".xlsx"
              style="padding-bottom: 36px;"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <button
              type="button"
              style="float: right;"
              class="btn btn-md btn-success"
              (click)="ImportExcel()"
            >
              <i class="fas fa-save"></i> Lưu
            </button>
          </div>
          <div class="col-md-6 form-group">
            <button
              type="button"
              class="btn btn-md btn-danger"
              (click)="modal.dismiss('Cross click')"
            >
              <i class="far fa-times-circle"></i> Hủy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #MotaModal let-modal>
  <div class="modal-header">
    <h4 style="text-align: center;">Thông tin mô tả loại phòng</h4>
    <div [innerHTML]="Mo_ta"></div>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10">Mã loại phòng : {{ Ma_loai_phong }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Tên loại phòng : {{ Ten_loai_phong }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Giá phòng : {{ Gia_loai_phong }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Đang ở : {{ Dang_o_lp }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Còn trống : {{ Con_trong_lp }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Sức chứa : {{ Suc_chua_lp }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Mô tả : </label>
          <div [innerHTML]="Mo_ta_lp" style="margin-left: 50px;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #HuyDuyetList let-modal>
  <div class="modal-header">
    <h4 style="text-align: center; margin-left: 80px;">
      Hủy đăng ký của sinh viên
    </h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Lý do hủy đăng ký</label>
          <textarea
            type="text"
            id="Ly_do"
            class="md-textarea form-control"
            [(ngModel)]="Ly_do"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="HuyDangKy_ListID()"
      >
        <i class="fas fa-trash-alt"></i> Hủy đăng ký
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #HuyDuyet let-modal>
  <div class="modal-header">
    <h4 style="text-align: center; margin-left: 80px;">
      Hủy đăng ký của sinh viên
    </h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Lý do hủy đăng ký</label>
          <textarea
            type="text"
            id="Ly_do"
            class="md-textarea form-control"
            [(ngModel)]="Ly_do"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="UnApprove_Register()"
      >
        <i class="fas fa-trash-alt"></i> Hủy đăng ký
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #TuChoiList let-modal>
  <div class="modal-header">
    <h4 style="text-align: center; margin-left: 80px;">
      Từ chối đăng ký của sinh viên
    </h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Lý do từ chối đăng ký</label>
          <textarea
            type="text"
            id="Ly_do"
            class="md-textarea form-control"
            [(ngModel)]="Ly_do"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        style="height: 38px; font-size: 1rem;"
        class="btn btn-md btn-success"
        (click)="TuChoiDangKy_ListID()"
      >
        <i class="fas fa-window-close"></i> Từ chối
      </button>
      <button
        type="button"
        style="margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #TuChoi let-modal>
  <div class="modal-header">
    <h4 style="text-align: center; margin-left: 80px;">
      Từ chối đăng ký của sinh viên
    </h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Lý do từ chối đăng ký</label>
          <textarea
            type="text"
            id="Ly_do"
            class="md-textarea form-control"
            [(ngModel)]="Ly_do"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        style="height: 38px; font-size: 1rem;"
        class="btn btn-md btn-success"
        (click)="Reject_Register()"
      >
        <i class="fas fa-window-close"></i> Từ chối
      </button>
      <button
        type="button"
        style="margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-4">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Đăng ký ở đề xuất</li>
        </ol>
      </div>
      <div class="col-md-8">
        <button
          class="btn btn-success btn-sm"
          style="float: right;"
          (click)="open(ThemModal, 'xs', 'Add', '')"
          (click)="clear()"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới đề xuất
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div
              class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
            >
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer"
              >
                <thead style="text-align: center;">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable" style="text-align: center;">
                    <td>{{ p.ID_de_xuat }}</td>
                    <td>{{ p.Noi_dung }}</td>
                    <td>{{ p.Ngay_de_xuat | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ p.ID_loai_de_xuat }}</td>
                    <td>{{ p.Ten_loai_de_xuat }}</td>
                    <td>{{ p.Noi_dung_phan_hoi }}</td>
                    <td>
                      {{
                        p.Trang_thai_hoan_thanh == 0
                          ? 'Chưa hoàn thành'
                          : 'Đã hoàn thành'
                      }}
                    </td>
                    <td>
                      <div *ngIf="p.Trang_thai == 0">
                        <button
                          *ngIf="p.Trang_thai_hoan_thanh == 0"
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'xs', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-edit" title="Sửa Nội dung"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-trash-alt" title="Xoá"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4
      style="font-weight: bold; font-size: 20px; margin: 15px auto;"
      class="modal-title h-title w-100"
    >
      {{ titleModal }}
    </h4>
  </div>
  <div class="modal-body cus-fs" style="padding: 2%;">
    <div class="cus-box">
      <form class="form-horizontal" [formGroup]="Insert">
        <div class="row">
          <div class="col-md-12">
            <label class="col-md-10 require">Loại đề xuất</label>
            <ng-select
              formControlName="ID_loai_de_xuat"
              placeholder="Chọn loại đề xuất"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.ID_loai_de_xuat.errors
              }"
            >
              <ng-option value="">Chọn loại đề xuất</ng-option>
              <ng-option
                *ngFor="let value of DataLoaiDeXuat"
                [value]="value.ID_loai_de_xuat"
              >
                {{ value.Ten_loai_de_xuat }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="submitted && checkvalue.ID_loai_de_xuat.errors"
              class="invalid-feedback"
            >
              <div *ngIf="checkvalue.ID_loai_de_xuat.errors.required">
                Loại đề xuất không được bỏ trống!
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label class="col-md-10 require">Nội dung đề xuất</label>
            <textarea
              class="form-control"
              rows="4"
              required
              placeholder="Nhập nội dung đề xuất"
              type="text"
              formControlName="Noi_dung"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.Noi_dung.errors
              }"
            >
            </textarea>
            <div
              *ngIf="submitted && checkvalue.Ten_loai_de_xuat.errors"
              class="invalid-feedback"
            >
              <div *ngIf="checkvalue.Noi_dung.errors.required">
                Nội dung không được bỏ trống!
              </div>
              <div *ngIf="checkvalue.Noi_dung.errors.maxlength">
                Nội dung tối đa 500 ký tự!
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        style="margin-right: 10px;"
        (click)="save()"
      >
        <i class="fa-plus-circle fas"></i> Gửi đề xuất
      </button>

      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

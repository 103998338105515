<div *ngFor="let element of data">
  <h2>{{ element?.Ten_phong }}</h2>
  <div
    style="
      margin-top: 10px;
      height: 100%;
      width: 100%;
      content: '';
      display: table;
      clear: both;
      page-break-after: always;
    "
  >
    <div
      *ngFor="let child of element.Data"
      style="float: left; width: 25%; padding: 0 5px; margin: 0px 5px 10px 5px;"
    >
      <div
        style="
          border: 2px solid black;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          padding: 16px;
          text-align: center;
          background-color: #f1f1f1;
          /* width: 100%; */
          max-height: 270px;
          min-width: 200px;
        "
      >
        <img
          [src]="baseURL + child?.Ma_sv + '.png'"
          width="146px"
          height="152px"
        />
        <h5>{{ child?.Ho_ten }}</h5>
        <p style="margin-top: -15px;">{{ child?.Ngay_sinh }}</p>
        <p style="margin-top: -10px;">{{ child?.Thong_tin }}</p>
        <p style="margin-top: -10px;">{{ child?.Que_quan }}</p>
      </div>
    </div>
  </div>
</div>

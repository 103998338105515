<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Phòng ký túc xá</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'lg', 'Add', '')"
          style="float: right; margin-left: 10px;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="KhoaPhong()"
          style="float: right;"
        >
          <i class="fas fa-lock"></i> Khoá/Mở phòng
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row" style="padding-left: 10px; padding-right: 10px;">
        <div class="col-md-3">
          <label class="col-md-10">Cơ sở:</label>
          <ng-select
            [(ngModel)]="ID_co_so_ft"
            (change)="filterById()"
            (change)="cosochange()"
          >
            <ng-option value="" selected>Chọn cơ sở</ng-option>
            <ng-option *ngFor="let p of DSCoSo" [value]="p.ID">{{
              p.name
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Toà nhà:</label>
          <ng-select
            id="ID_toa_nha_ft"
            [(ngModel)]="ID_toa_nha_ft"
            (change)="filterById()"
          >
            <ng-option value="" selected>Chọn toà nhà</ng-option>
            <ng-option *ngFor="let p of DSToaNha3" [value]="p.ID_ToaNha">{{
              p.Ten_ToaNha
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Loại phòng:</label>
          <ng-select [(ngModel)]="loai_phong_ft" (change)="filterById()">
            <ng-option value="" selected>Chọn loại phòng</ng-option>
            <ng-option *ngFor="let p of DSLoaiPhong" [value]="p.ID">{{
              p.Ten_loai_phong
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Phòng Nam/Nữ:</label>
          <ng-select [(ngModel)]="gioi_tinh_ft" (change)="filterById()">
            <ng-option value="" selected>Chọn phòng Nam/Nữ</ng-option>
            <ng-option [value]="false">Phòng Nam</ng-option>
            <ng-option [value]="true">Phòng Nữ</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row" style="padding-left: 10px; padding-right: 10px;">
        <div class="col-md-3">
          <label class="col-md-10" style="margin-top: 10px;"
            >Tổng sức chứa:</label
          >
          <input class="form-control" readonly type="text" id="tongsucchua" />
        </div>
        <div class="col-md-3">
          <label class="col-md-10" style="margin-top: 10px;">Đã sử dụng:</label>
          <input class="form-control" readonly type="text" id="svdango" />
        </div>

        <div class="col-md-3">
          <label class="col-md-10" style="margin-top: 10px;">Tầng:</label>
          <input
            class="form-control"
            type="text"
            (keyup)="filterById()"
            [(ngModel)]="Tang_ft"
            placeholder="Nhập tầng"
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10" style="margin-top: 10px;">Tên phòng:</label>
          <input
            class="form-control"
            type="text"
            (keyup)="filterById()"
            [(ngModel)]="Ten_phong_ft"
            placeholder="Nhập tên phòng cần tìm"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  id="maindata"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          (click)="chonall()"
                          name="SelectALL"
                          id="SelectALL"
                          value="SelectAll"
                        />
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>
                        <input
                          type="checkbox"
                          (change)="SelectRow(p.ID_Phong)"
                          name="checkbox{{ p.ID_Phong }}"
                          id="checkbox{{ p.ID_Phong }}"
                          [checked]="p.Selected"
                          value="{{ p.ID_Phong }}"
                        />
                      </td>
                      <td>{{ p.ID_Phong }}</td>
                      <td>{{ p.ID_Co_So }}</td>
                      <td>{{ p.ID_Toa_Nha }}</td>
                      <td>{{ p.Ma_phong }}</td>
                      <td>{{ p.Ten_phong }}</td>
                      <td>{{ p.Tang }}</td>
                      <td>{{ p.Loai_phong }}</td>
                      <td>{{ p.Loai_phong_text }}</td>
                      <td>{{ p.Ten_loai_phong }}</td>
                      <td>{{ p.Suc_chua }}</td>
                      <td>{{ p.Dang_o }}</td>
                      <td>{{ p.Gia_tien | number }}</td>
                      <td>
                        <div *ngIf="p.Khoa_phong">
                          <div class="fs_10 row_st bg-danger">
                            Đã khoá
                          </div>
                        </div>
                        <div *ngIf="!p.Khoa_phong">
                          <div class="fs_10 row_st green_color">
                            Chưa khoá
                          </div>
                        </div>
                      </td>
                      <td>
                        <div *ngIf="p.Bao_phong">
                          <input type="checkbox" checked disabled />
                        </div>
                        <div *ngIf="!p.Bao_phong">
                          <input type="checkbox" disabled />
                        </div>
                      </td>
                      <td>{{ p.ID_loai_phong }}</td>
                      <td>{{ p.Thu_tu }}</td>
                      <td style="white-space: nowrap;">
                        <a
                          [routerLink]="['/admin/roomdetail']"
                          style="margin-right: 7px;"
                          class="btn btn-success btn-sm"
                          [queryParams]="{
                            ID_Phong: p.ID_Phong,
                            Ma_phong: p.Ten_phong
                          }"
                          queryParamsHandling="merge"
                        >
                          <i class="fas fa-eye" ngbTooltip="Xem"></i>
                        </a>
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'lg', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-edit" title="Sửa phòng"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-trash-alt" title="Xoá phòng"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require">Cơ sở</label>
          <select
            name=""
            class="form-control"
            formControlName="ID_co_so"
            (change)="cosochange()"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.ID_co_so.errors
            }"
          >
            <option [disabled]="CheckEdit" value="">Chọn cơ sở</option>
            <option
              *ngFor="let p of DSCoSo"
              [disabled]="CheckEdit"
              [value]="p.ID"
              >{{ p.name }}</option
            >
          </select>
          <div
            *ngIf="submitted && checkvalue.ID_co_so.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.ID_co_so.errors.required">
              Vui lòng chọn cơ sở
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Toà nhà</label>
          <select
            name=""
            class="form-control"
            id="ID_toa_nha"
            formControlName="ID_toa_nha"
            [disabled]="CheckEdit"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.ID_toa_nha.errors
            }"
          >
            <option value="" [disabled]="CheckEdit">Chọn toà nhà</option>
            <option
              *ngFor="let p of DSToaNha2"
              [disabled]="CheckEdit"
              [value]="p.ID_ToaNha"
              >{{ p.Ten_ToaNha }}</option
            >
          </select>
          <div
            *ngIf="submitted && checkvalue.ID_toa_nha.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.ID_toa_nha.errors.required">
              Vui lòng chọn toà nhà
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require" style="margin-top: 10px;"
            >Mã phòng</label
          >
          <input
            class="form-control"
            required
            placeholder="Nhập mã phòng"
            type="text"
            [readonly]="CheckEdit"
            formControlName="Ma_phong"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ma_phong.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ma_phong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ma_phong.errors.required">
              Mã phòng không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Ma_phong.errors.maxlength">
              Mã phòng tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require" style="margin-top: 10px;"
            >Tên phòng</label
          >
          <input
            class="form-control"
            required
            placeholder="Nhập tên phòng"
            type="text"
            formControlName="Ten_phong"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ten_phong.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ten_phong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ten_phong.errors.required">
              Tên phòng không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Ten_phong.errors.maxlength">
              Tên phòng tối đa 10 ký tự!
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10" style="margin-top: 10px;"
            >Phòng Nam/Nữ</label
          >
          <select
            name=""
            class="form-control"
            id="Loai_phong"
            formControlName="Loai_phong"
            [disabled]="CheckEdit"
          >
            <option [disabled]="CheckEdit" value="false">Phòng Nam</option>
            <option [disabled]="CheckEdit" value="true">Phòng Nữ</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10" style="margin-top: 10px; margin-top: 10px;"
            >Tầng</label
          >
          <input
            class="form-control"
            placeholder="Nhập tầng"
            type="text"
            id="Tang"
            formControlName="Tang"
            [readonly]="CheckEdit"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Tang.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Tang.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Tang.errors.maxlength">
              Tầng tối đa 20 ký tự!
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require" style="margin-top: 10px;"
            >Loại phòng</label
          >
          <select
            name=""
            class="form-control"
            id="ID_loai_phong"
            formControlName="ID_loai_phong"
            [disabled]="CheckEdit"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.ID_loai_phong.errors
            }"
          >
            <option
              [disabled]="CheckEdit"
              *ngFor="let p of DSLoaiPhong"
              [value]="p.ID"
              >{{ p.Ten_loai_phong }}</option
            >
          </select>
          <div
            *ngIf="submitted && checkvalue.ID_loai_phong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.ID_loai_phong.errors.required">
              Loại phòng không được để trống
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-check" style="margin-top: 30px; text-align: center;">
            <input
              type="checkbox"
              style="width: 20px; height: 20px;"
              class="form-check-input"
              formControlName="Khoa_phong"
              name="Khoa_phong"
            />
            <label class="form-check-label" style="margin-left: 5px;"
              >Khoá phòng</label
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-check" style="margin-top: 30px; text-align: center;">
            <input
              type="checkbox"
              style="width: 20px; height: 20px;"
              class="form-check-input"
              formControlName="Bao_phong"
              name="Bao_phong"
            />
            <label class="form-check-label" style="margin-left: 5px;"
              >Bao phòng</label
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require" style="margin-top: 10px;"
            >Sức chứa</label
          >
          <input
            class="form-control"
            require
            placeholder="Nhập sức chứa của phòng"
            type="number"
            id="Suc_chua"
            [readonly]="CheckEdit"
            formControlName="Suc_chua"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Suc_chua.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Suc_chua.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Suc_chua.errors.required">
              Sức chứa không được để trống
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10" style="margin-top: 10px;">Thứ tự</label>
          <input
            class="form-control"
            require
            placeholder="Nhập thứ tự phòng"
            type="number"
            id="Thu_tu"
            formControlName="Thu_tu"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10">Ghi chú</label>
          <input
            class="form-control"
            placeholder="Nhập ghi chú"
            type="text"
            id="Ghi_chu"
            formControlName="Ghi_chu"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Ghi_chu.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Ghi_chu.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ghi_chu.errors.maxlength">
              Ghi chú tối đa 500 ký tự!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

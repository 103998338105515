import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, zip } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { RegulationPaymentService } from 'src/app/utils/services/regulation-payment.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-room-charge',
  templateUrl: './room-charge.component.html',
  styleUrls: ['./room-charge.component.scss']
})
export class RoomChargeComponent implements OnInit {
  Student: any = [];
  onItemDeSelect(a:any){
  }
  ListStudent(item: any) {
    this.Student.push(item)
  }
  ListAllStudent(items: any) {
  }
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  titleConfirm: any
  yearArr: any = []
  Nam_hoc_Arr: any = []
  dataTable: any = [];
  dataStudent: any = [];
  DetailData: any = [];
  MonthOption: any = [];
  dataKhoanNop:any = []
  closeResult: string;
  ClassData: any;
  titleModal: string;
  default: any;
  Ma_sv_filter: any
  Ho_ten_filter: any
  Thang_filter: any = 0
  Nam_filter: any = 0
  Hoc_ky_filter: any = 0
  Nam_hoc_filter: any = 0
  ID_khoan_thu_filter:any = 0
  ID_sv: any
  Hinh_thuc_thu: boolean = true
  Thang: any
  Nam: any
  Hoc_ky: any
  Nam_hoc: any
  Nam_hoc2: any
  So_tien: number = 0
  Phan_tram_mien_giam: number = 0
  So_tien_mien_giam: number = 0
  Thanh_tien: number = 0
  Trang_thai_filter: any = 0
  ID_qdkt: any
  dropdownSettings:any
  selectedItems:any
  myForm:FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: any;
  checkConfirm: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải mức học phí tín chỉ...';
  Request: any;
  constructor(
    private modalService: NgbModal,
    private RegulationPaymentService: RegulationPaymentService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,

  ) { }
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;
  Insert = new FormGroup({
    selectedItems:new FormControl(null,[Validators.required]),
    Hinh_thuc_thu: new FormControl(true,[Validators.required]),
    Thang: new FormControl((new Date().getMonth() + 1),[Validators.required]),
    Nam: new FormControl((new Date().getFullYear()),[Validators.required]),
    Hoc_ky: new FormControl(1,[Validators.required]),
    Nam_hoc: new FormControl(new Date().getFullYear() + '-' + (new Date().getFullYear() + 1),[Validators.required]),
    So_tien: new FormControl(null,[Validators.required,Validators.min(0)]),
    Phan_tram_mien_giam: new FormControl(0,[Validators.min(0)]),
    So_tien_mien_giam: new FormControl(0,[Validators.min(0)]),
    Thanh_tien: new FormControl(null),
    Noi_dung: new FormControl(null,[removeSpaces]),
    ID_tham_so: new FormControl('ID_khoan_thu_tien_phong',[Validators.required]),
  });

  ngOnInit() {
    var d = new Date();
    var n = d.getFullYear();

    var Nam_hoc = d.getFullYear();
    var out = '';
    for (var i = 0; i < 10; i++) {
      var value = Nam_hoc - 1 + '-' + Nam_hoc;
      out += '<option value="' + value + '">' + value + '</option>';
      Nam_hoc--;
    }
    $('#Nam_hoc').html(out);
    for (var i = 0; i < 10; i++) {
      let object = {
        'value': n,
        'name': n
      }
      this.yearArr.push(object)
      n--
    }
    var yearNow = d.getFullYear();
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1)
      let object = {
        'value': Nam_hoc_string,
        'name': Nam_hoc_string
      }
      this.Nam_hoc_Arr.push(object)
      yearNow--
    }
    this.MonthOption = [
      { 'value': 1, 'name': 'Tháng 1' }, { 'value': 2, 'name': 'Tháng 2' },
      { 'value': 3, 'name': 'Tháng 3' }, { 'value': 4, 'name': 'Tháng 4' },
      { 'value': 5, 'name': 'Tháng 5' }, { 'value': 6, 'name': 'Tháng 6' },
      { 'value': 7, 'name': 'Tháng 7' }, { 'value': 8, 'name': 'Tháng 8' },
      { 'value': 9, 'name': 'Tháng 9' }, { 'value': 10, 'name': 'Tháng 10' },
      { 'value': 11, 'name': 'Tháng 11' }, { 'value': 12, 'name': 'Tháng 12' },
    ]
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5', text: ' <i class="far fa-file-excel"></i>&nbspXuất excel ',
          titleAttr: 'Xuất excel ',
          className: "btn-sm",
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            orthogonal: 'export',
            className: "btn  btn-success",
            format: {
              body: function (data, row, column, node) {
                return data;
              }
            }
          },
        },
      ],

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible:false
        },
        {
          title: 'Loại khoản thu',
        },
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ và tên',
        },
        {
          title: 'Ngày sinh',
        },
        {
          title: 'Học kỳ',
        },
        {
          title: 'Năm học',
        },
        {
          title: 'Tháng',
        },
        {
          title: 'Năm',
        },
        {
          title: 'Số tiền',
        },
        {
          title: 'Phần trăm miễn giảm',
        },
        {
          title: 'Số tiền miễn giảm',
        },
        {
          title: 'Thành tiền',
        },
        {
          title: 'Thao tác',
          width: '75px',
          className: 'dt-center'
        },
        {
          title: 'ID_khoan_thu',
          visible:false
        },
        {
          title: 'Trang thai',
          visible:false
        }
      ],
      "order": [[ 0, "desc" ]]
    };
    this.getAll(a.Token);
  }
  fill() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_sv_data = data[2]
      let Ho_ten_data = data[3]
      let Hoc_ky_data = data[5]
      let Nam_hoc_data = data[6]
      let Thang_data = data[7]
      let Nam_data = data[8]
      let ID_khoan_thu_data = data[14]
      let Trang_thai_data = data[15]
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;
      let bool7 = true;
      let bool8 = true;
      let fillter = true;
      if (this.Ma_sv_filter != null) {
        var a = Ma_sv_data;
        var b = this.Ma_sv_filter;
        bool1 = (a.toLowerCase()).includes(b.toLowerCase());
      }
      if (this.Ho_ten_filter != null) {
        var a = Ho_ten_data;
        var b = this.Ho_ten_filter;
        bool2 = (a.toLowerCase()).includes(b.toLowerCase());
      }
      if (this.Hoc_ky_filter != 0) {
        var a = Hoc_ky_data;
        var b = this.Hoc_ky_filter
        bool3 = a == b;
      }
      if (this.Nam_hoc_filter != 0) {
        var a = Nam_hoc_data
        var b = this.Nam_hoc_filter
        bool4 =  a==b;
      }

      if (this.Thang_filter != 0) {
        var a = Thang_data
        var b = this.Thang_filter
        bool5 =  a==b;
      }
      if (this.Nam_filter != 0) {
        var a = Nam_data
        var b = this.Nam_filter
        bool6 = a==b;
      }
      if (this.ID_khoan_thu_filter != 0) {
        var a = ID_khoan_thu_data
        var b = this.ID_khoan_thu_filter
        bool7 = a==b;
      }
      if (this.Trang_thai_filter != 0) {
        var a = Trang_thai_data
        var b = this.Trang_thai_filter
        bool8 = a==b;
      }
      if (bool1 && bool2 && bool3 && bool4 && bool5 && bool6 && bool7 && bool8) {
        return true;
      }
      return false;
    });
  }
  getAll(token) {
    
    this.spinner.show();
    this.RegulationPaymentService.getAll(this.Token).subscribe(z => {
      this.dataTable = z.Data;
        this.RegulationPaymentService.getStudent(token).subscribe((student) => {
          for (let i = 0; i < student.Data.length; i++) {
            student.Data[i]['Ma_ho_ten'] = student.Data[i].Ma_sv + " - " + student.Data[i].Ho_ten
          }
          this.dataStudent = student.Data;
          this.RegulationPaymentService.SystemParameterRP_List(this.Token).subscribe(z => {
          this.dataKhoanNop = z.Data;
          this.dtTrigger.next();
          this.spinner.hide();
          });
        });
    });
  }
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Add') {
      this.titleModal = 'Thêm mới thiết bị'
      this.checkInsert = false
    } else if (Loai == 'Delete') {
      this.ID_qdkt = Data.ID_quy_dinh_khoan_nop;
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  Delete() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.RegulationPaymentService.Delete(this.ID_qdkt, a.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.modalService.dismissAll('DeleteModal');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getAll(a.Token);
    });
  }

  //check value
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
  }
  changeHinh_thuc() {
    let a = $('#Hinh_thuc_thu').val();
    if (a == "true") {
      this.Hinh_thuc_thu = true
    } else {
      this.Hinh_thuc_thu = false
    }
  }
  changeSoTien() {
    let st = $('#So_tien').val()
    let a = parseFloat(st ? st.toString() : '0')
    let ptmg = $('#Phan_tram_mien_giam').val()
    let b = parseFloat(ptmg ? ptmg.toString() : '0')
    let stmg = $('#So_tien_mien_giam').val()
    let c = parseFloat(stmg ? stmg.toString() : '0')
    let d = a - a * b / 100 - c
    this.Insert.patchValue({ Thanh_tien: d })
  }
  create() {
    this.com = new common(this.router)
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let obj = {}
    let arr = []
    if(this.Insert.controls.selectedItems.value.length>0){
      this.Insert.controls.selectedItems.value.forEach(element => {
        if(this.Insert.controls.Hinh_thuc_thu.value == true){
        obj = {
          ID_sv : element.ID_sv,
          Trang_thai : 0,
          ID_tham_so : this.Insert.controls.ID_tham_so.value,
          Hinh_thuc_thu : true,
          Hoc_ky : 0,
          Nam_hoc : 0,
          Thang : this.Insert.controls.Thang.value,
          Nam : this.Insert.controls.Nam.value,
          So_tien : this.Insert.controls.So_tien.value,
          Phan_tram_mien_giam : this.Insert.controls.Phan_tram_mien_giam.value,
          So_tien_mien_giam : this.Insert.controls.So_tien_mien_giam.value,
          Thanh_tien : this.Insert.controls.Thanh_tien.value,
          Noi_dung : this.Insert.controls.Noi_dung.value,
        }
      }else{
        obj = {
          ID_sv : element.ID_sv,
          Trang_thai : 0,
          ID_tham_so : this.Insert.controls.ID_tham_so.value,
          Hinh_thuc_thu : true,
          Hoc_ky : this.Insert.controls.Hoc_ky.value,
          Nam_hoc : this.Insert.controls.Nam_hoc.value,
          Thang : 0,
          Nam : 0,
          So_tien : this.Insert.controls.So_tien.value,
          Phan_tram_mien_giam : this.Insert.controls.Phan_tram_mien_giam.value,
          So_tien_mien_giam : this.Insert.controls.So_tien_mien_giam.value,
          Thanh_tien : this.Insert.controls.Thanh_tien.value,
          Noi_dung : this.Insert.controls.Noi_dung.value,
        }
      }
          arr.push(obj)
      });
      this.RegulationPaymentService.MultipleInsert(arr, a.Token).subscribe((z) => {
        this.toastr.success(z.Message);
        this.modalService.dismissAll('DeleteModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getAll(a.Token);
      });
    }
  }

  getData() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fill();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }


}


import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, from, Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';
import { WebSocketService } from 'src/app/utils/services/web-socket.service';

@Component({
  selector: 'app-portal-ket-qua',
  templateUrl: './portal-ket-qua.component.html',
  styleUrls: ['./portal-ket-qua.component.scss'],
})
export class PortalKetQuaComponent implements OnInit {
  current_year = new Date().getFullYear();
  registerTime = new Date().getHours() + ':' + new Date().getMinutes();

  submitted: boolean = false;
  closeResult: string;
  titleModal: string;
  public com: common;
  Token: string;
  UserName_get: string;
  Ma_sv_get: string;
  dataLichSuEmail: any;
  searchString: string;

  dataNoiDungEmail: string;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private RegisterDorm: RegisterDormService
  ) {}

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    var a = this.com.getPortalInfo();
    this.Token = a.Token;
    this.UserName_get = a.Info.UserName;
    this.Ma_sv_get = a.Info.UserName;

    this.GetAll();
  }

  //-------------------------Lấy thông tin sv theo mã----------------------------//
  GetAll() {
    this.spinner.show();
    this.RegisterDorm.getSVbyMa(this.Ma_sv_get, this.Token).subscribe((res) => {
      if (res.Status == 1) {
        this.dataLichSuEmail = !res.DataEmail ? [] : res.DataEmail;
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Detail') {
      this.submitted = false;
      this.titleModal = 'Nội dung kết quả đăng kí';
      this.dataNoiDungEmail = Data.Noi_dung_email;
    }
  }

  hideContent(data) {
    this.dataLichSuEmail = this.dataLichSuEmail.filter(
      (x) => x.ID_email_da_gui != data.ID_email_da_gui
    );
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RoomService } from 'src/app/utils/services/room.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { StatisticalService } from 'src/app/utils/services/statistical.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { CampusService } from 'src/app/utils/services/campus.service';
import { ExportService } from 'src/app/utils/services/export.service';
@Component({
  selector: 'app-statistical',
  templateUrl: './statistical.component.html',
  styleUrls: ['./statistical.component.scss'],
})
export class StatisticalComponent implements OnInit {
  dtOptions: DataTables.Settings;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  titleModal: string;
  default: any;
  Ma_sv_ft: any;
  Loai_thu_ft: any = 0;
  Thang_nam_ft: any;
  Hoc_ky_ft: any = 0;
  Trang_thai_ft: any = 0;
  show: any = 0;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;

  Fillter = new FormGroup({
    Ma_sv_ft: new FormControl(null),
    Loai_thu_ft: new FormControl(0),
    Thang_nam_ft: new FormControl(null),
    Hoc_ky_ft: new FormControl(null),
    Trang_thai_ft: new FormControl(null),
  });

  constructor(
    private modalService: NgbModal,
    private StatisticalService: StatisticalService,
    private BuildingService: BuildingService,
    private CampusService: CampusService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public exportService: ExportService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_sv_dt = data[1];
      let Trang_thai_dt = data[7];
      let Loai_thu_dt = data[10];
      let Thang_dt = data[14];
      let Nam_dt = data[15];
      let Hoc_ky_dt = data[12];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;
      let fillter = true;
      if (this.Ma_sv_ft != undefined) {
        var a = this.removeAccents(Ma_sv_dt);
        var b = this.removeAccents(this.Ma_sv_ft);
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Loai_thu_ft != 0) {
        var a = Loai_thu_dt;
        var b = this.Loai_thu_ft;
        bool2 = a == b;
      }
      if (this.Hoc_ky_ft != 0) {
        var a = Hoc_ky_dt;
        var b = this.Hoc_ky_ft;
        bool3 = a == b;
      }
      if (this.Trang_thai_ft != 0) {
        var a = this.removeAccents(Trang_thai_dt);
        var b = this.removeAccents(this.Trang_thai_ft);
        bool6 = a.toLowerCase().includes(b.toLowerCase());
      }

      if (this.Thang_nam_ft != '' && this.Thang_nam_ft != undefined) {
        var nam = this.Thang_nam_ft.substring(0, 4);
        var thang = this.Thang_nam_ft.substring(5, 7);
        bool4 = nam == Nam_dt;
        bool5 = parseInt(thang) == parseInt(Thang_dt);
      }

      fillter = bool1 && bool2 && bool3 && bool4 && bool5 && bool6;
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID sv',
          visible: false,
        },
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ tên',
        },
        {
          title: 'ID cơ sở',
          visible: false,
        },
        {
          title: 'ID toà nhà',
          visible: false,
        },
        {
          title: 'ID phòng',
          visible: false,
        },
        {
          title: 'Tên phòng',
        },
        {
          title: 'Trạng thái nộp',
          visible: false,
        },
        {
          title: 'Trạng thái nộp',
        },
        {
          title: 'Hình thức thu',
          visible: false,
        },
        {
          title: 'Loại khoản thu',
          visible: false,
        },
        {
          title: 'Loại khoản thu',
        },
        {
          title: 'Học kỳ',
        },
        {
          title: 'Năm học',
        },
        {
          title: 'Tháng',
        },
        {
          title: 'Năm',
        },
        {
          title: 'Thành tiền',
        },
        {
          title: 'Ngày quy định',
          visible: false,
        },
        {
          title: 'Số biên lai',
        },
      ],
    };
    this.CampusService.getList(a.Token).subscribe((z) => {
      this.DSCoSo = z.Data;
      this.BuildingService.getList(a.Token).subscribe((z) => {
        this.DSToaNha = z.Data;
        this.DSToaNha2 = z.Data;
        this.getList(a.Token);
      });
    });
  }
  cosochange() {
    let ID_coso = $('#ID_co_so').val();
    this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
      return pa.ID_CoSo == ID_coso;
    });
  }
  removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  getList(token) {
    this.spinner.show();
    this.StatisticalService.GetAll(token).subscribe((z) => {
      z.Data.forEach((a) => {
        if (a.ID_khoan_thu == 10) {
          a.ID_khoan_thu_text = 'Tiền điện';
        }
        if (a.ID_khoan_thu == 11) {
          a.ID_khoan_thu_text = 'Tiền nước';
        }
        if (a.ID_khoan_thu == 16) {
          a.ID_khoan_thu_text = 'Tiền phòng';
        }
        if (a.Trang_thai == true) {
          a.Trang_thai_text = 'Đã nộp';
        } else {
          a.Trang_thai_text = 'Chưa nộp';
        }
      });
      this.dataTable = z.Data;
      this.dtTrigger.next();
      this.Title = '';
      this.TitleClass = '';
      this.spinner.hide();
    });
  }
  //get
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  //check value
  // get checkvalue() {
  //   return this.Insert.controls;
  // }
  filterById() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
    if (this.Loai_thu_ft == '16') {
      this.show = 1;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.StatisticalService.Excel(a.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'Thongkekhoannop');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      });
    }
  }
}

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Xếp phòng</li>
        </ol>
      </div>
      <div class="col-md-6">
        <!-- <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right; margin-left: 10px;">
                    <i class="fas fa-users-cog"></i> Xếp phòng tự động
                </button> -->

        <button
          class="btn btn-success btn-sm"
          (click)="navigate()"
          style="float: right;"
        >
          <i class="fas fa-users-cog"></i> Xếp phòng
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="getList()"
          style="float: right; margin-right: 10px; background-color: #4c6ef5;"
        >
          <i class="fas fa-search"></i>Tìm kiếm
        </button>
        <button
          class="btn btn-primary btn-sm"
          style="float: right; margin-right: 7px;"
          (click)="ExportExcel()"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row">
          <div class="col-md-2">
            <input
              type="text"
              (change)="getList()"
              formControlName="Ma_sv_filter"
              class="form-control"
              placeholder="Nhập mã sv"
            />
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (change)="getList()"
              formControlName="Ho_ten_filter"
              class="form-control"
              placeholder="Nhập tên sv"
            />
          </div>
          <div class="col-md-2">
            <input
              type="date"
              (change)="getList()"
              formControlName="NgaySinh_filter"
              class="form-control"
              placeholder="Chọn ngày sinh"
            />
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Gioi_tinh_filter">
              <ng-option [value]="1">Nam</ng-option>
              <ng-option [value]="2">Nữ</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Thanh_toan_filter">
              <ng-option value="">Chọn loại thanh toán</ng-option>
              <ng-option [value]="1">Đã đóng tiền</ng-option>
              <ng-option [value]="2">Chưa đóng tiền</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Nhap_hoc_filter">
              <ng-option value="" selected>Chọn đối tượng</ng-option>
              <ng-option [value]="1">Sinh viên nhập học</ng-option>
              <ng-option [value]="0">Sinh viên đang học</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <!-- <div class="col-md-3">
            <label>Mã sinh viên</label>
            <input type="text" class="form-control" [(ngModel)]="code_filter" formControlName="code_filter"
              (keyup)="filterById()" placeholder="Nhập mã sinh viên..." />
          </div>
          <div class="col-md-3">
            <label>Họ tên</label>
            <input type="text" class="form-control" [(ngModel)]="name_filter" formControlName="name_filter"
              (keyup)="filterById()" placeholder="Nhập họ tên..." />
          </div> -->

          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="He_filter">
              <ng-option value="" selected> Chọn hệ</ng-option>
              <ng-option *ngFor="let p of DSHe" [value]="p.Ten_he">{{
                p.Ten_he
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" (keyup)="filterById()"   formControlName="He_filter" class="form-control" placeholder="Nhập hệ..." /> -->
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Khoa_filter">
              <ng-option value="" selected> Chọn khoa</ng-option>
              <ng-option *ngFor="let p of DSKhoa" [value]="p.Ten_khoa">{{
                p.Ten_khoa
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" (keyup)="filterById()"   formControlName="Khoa_filter" class="form-control" placeholder="Nhập khoa..." /> -->
          </div>
          <div class="col-md-2">
            <input
              (change)="getList()"
              type="text"
              class="form-control"
              formControlName="Khoa_hoc_filter"
              placeholder="Nhập khóa học..."
            />
          </div>
          <div class="col-md-2">
            <ng-select
              (change)="getList()"
              formControlName="Chuyen_nganh_filter"
            >
              <ng-option value="" selected> Chọn chuyên ngành</ng-option>
              <ng-option
                *ngFor="let p of DSChuyenNganh"
                [value]="p.Chuyen_nganh"
                >{{ p.Chuyen_nganh }}</ng-option
              >
            </ng-select>
            <!-- <input type="text" class="form-control" formControlName="Chuyen_nganh_filter" (keyup)="filterById()" placeholder="Nhập chuyên ngành..." /> -->
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Khu_vuc_filter">
              <ng-option value="" selected> Chọn khu vực</ng-option>
              <ng-option *ngFor="let p of DSKhuVuc" [value]="p.Ten_kv">{{
                p.Ten_kv
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" class="form-control" formControlName="Khu_vuc_filter" (keyup)="filterById()" placeholder="Nhập khu vực..." /> -->
          </div>
          <!-- <div class="col-md-2">
            <label>Lớp học</label>
            <input type="text" class="form-control" [(ngModel)]="lop_filter" formControlName="lop_filter"
              (keyup)="filterById()" placeholder="Nhập lớp học..." />
          </div> -->
          <!-- <div class="col-md-2">
            <label>Đối tượng</label>
            <input type="text" class="form-control" [(ngModel)]="doituong_filter" formControlName="doituong_filter"
              (keyup)="filterById()" placeholder="Nhập đối tượng..." />
          </div> -->
          <div class="col-md-2">
            <ng-select
              (change)="getList()"
              formControlName="Doi_tuong_chinh_sach_filter"
            >
              <ng-option value="" selected>
                Chọn loại đối tượng chính sách</ng-option
              >
              <!-- <option value="-1" selected> Là đối tượng chính sách bất kỳ</option> -->
              <ng-option *ngFor="let p of DSDoiTuong" [value]="p.Ten_dt">{{
                p.Ten_dt
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" (keyup)="filterById()" formControlName="Doi_tuong_chinh_sach_filter" class="form-control" placeholder="Nhập tên đối tượng" /> -->
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Loai_phong_filter">
              <ng-option value="" selected> Chọn loại phòng đăng ký</ng-option>
              <ng-option
                *ngFor="let p of DSLoaiPhong"
                [value]="p.Ten_loai_phong"
                >{{ p.Ten_loai_phong }}</ng-option
              >
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Hoc_ky_filter">
              <ng-option value="0" selected>Chọn học kỳ</ng-option>
              <ng-option value="1">Học kỳ 1</ng-option>
              <ng-option value="2">Học kỳ 2</ng-option>
              <ng-option value="3">Học kỳ 3</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Nam_hoc_filter">
              <ng-option *ngFor="let i of Nam_hoc_arr" [value]="i.value">{{
                i.name
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="ID_tinh_tt_filter">
              <ng-option value="0" selected>Tỉnh thường trú</ng-option>
              <ng-option *ngFor="let p of lstTinh" [value]="p.ID_tinh">{{
                p.Ten_tinh
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (change)="getList()"
              formControlName="Dienthoai_canhan_filter"
              class="form-control"
              placeholder="Điện thoại"
            />
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (change)="getList()"
              formControlName="Mail_filter"
              class="form-control"
              placeholder="Địa chỉ email"
            />
          </div>
          <!-- <div class="col-md-2">
            <input type="text" formControlName="NguyenVong_filter" class="form-control"
              placeholder="Nhập nguyện vọng" />
          </div> -->
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="ID_dan_toc_filter">
              <ng-option value="0" selected>Chọn dân tộc</ng-option>
              <ng-option *ngFor="let p of lstDanToc" [value]="p.ID_dan_toc">{{
                p.Dan_toc
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (change)="getList()"
              formControlName="Ton_giao_filter"
              class="form-control"
              placeholder="Tôn giáo"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <!-- <div class="active tab-pane" id="TeachInfo">
          
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                <table datatable id="datatable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer">
                  <thead>
                    <tr>
                      <th></th>
                      <th><input type="checkbox" (change)="CheckAll()" id="SelectALL" value="SelectAll" /></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">

                      <td>{{ p.ID_dang_ky }}</td>
                      <td>
                        <input type="checkbox" (change)="SelectRow(p.ID_dang_ky)" name="SelectData"
                          id="{{ p.ID_dang_ky }}" [checked]="p.Selected" value="{{ p.ID_dang_ky }}" />
                      </td>

                      <td>{{ p.Ma_sv }}</td>
                      <td>{{ p.Ho_ten }}</td>
                      <td>{{ p.Gioi_tinh }}</td>
                      <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ p.Nguyen_vong }}</td>
                      <td>{{ p.Ten_dt_chinh_sach }}</td>
                      <td>{{ p.Ten_kv_ts }}</td>
                      <td>{{ p.So_tien_phai_nop | number  }}</td>
                      <td>{{ p.So_tien_da_nop | number }}</td>
                      <td>{{ p.So_tiep_hoan_tra | number }}</td>
                      <td>{{ p.So_tien_con_no | number }}</td>
                      <td>{{ p.Ten_lop }}</td>
                      <td>{{ p.Quoc_tich }}</td>
                      <td>{{ p.Nhap_hoc }}</td>
                      <td>{{ p.Ten_he }}</td>
                      <td>{{ p.Ten_khoa }}</td>
                      <td>{{ p.Khoa_hoc }}</td>
                      <td>{{ p.Chuyen_nganh }}</td>
                      <td>{{ p.Ten_loai_phong }}</td>
                      <td>{{ p.Ten_dt_chinh_sach }}</td>
                      <td>{{ p.Create_date }}</td>
                      <td>{{ p.Email }}</td>
                      <td>Sắp xếp</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row mb-2">
          <div class="col-md-6">
            Hiển thị
            <select (change)="handlePageSizeChange($event)">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
            kết quả
          </div>
          <div class="col-md-6"></div>
        </div>
        <div
          class="table-responsive"
          style="max-height: 60vh; margin-bottom: 15px;"
        >
          <input
            type="text"
            [(ngModel)]="searchString"
            placeholder="Tìm kiếm nhanh"
            style="float: right; margin-bottom: 10px;"
          />
          <table class="table table-bordered">
            <thead>
              <th>
                <input
                  type="checkbox"
                  [checked]="isAllCheckBoxChecked()"
                  (change)="checkAllCheckBox($event)"
                />
              </th>
              <th>Mã sinh viên</th>
              <th style="white-space: nowrap;">Họ và tên</th>
              <th>Giới tính</th>
              <th>Ngày sinh</th>
              <th>Nguyện vọng</th>
              <th>Khu vực</th>
              <th>Phải nộp</th>
              <th>Đã nộp</th>
              <th>Hoàn trả</th>
              <th>Thiếu thừa</th>
              <th>Lớp</th>
              <th>Loại phòng đăng ký</th>
              <th>Đối tượng chính sách</th>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let p of dataTable | filter: searchString;
                  let i = index;
                  let odd = odd
                "
              >
                <td>
                  <input
                    type="checkbox"
                    value="{{ p.ID_dang_ky }}"
                    [checked]="p.checked"
                    [(ngModel)]="dataTable[i].checked"
                  />
                </td>

                <td>{{ p.Ma_sv }}</td>
                <td>{{ p.Ho_ten }}</td>
                <td>{{ p.Gioi_tinh }}</td>
                <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                <td>{{ p.Nguyen_vong }}</td>
                <td>{{ p.Ten_kv_ts }}</td>
                <td>{{ p.So_tien_phai_nop | number }}</td>
                <td>{{ p.So_tien_da_nop | number }}</td>
                <td>{{ p.So_tiep_hoan_tra | number }}</td>
                <td>{{ p.So_tien_con_no | number }}</td>
                <td>{{ p.Ten_lop }}</td>
                <td>{{ p.Ten_loai_phong }}</td>
                <td>{{ p.Ten_dt_chinh_sach }}</td>
              </tr>
              <tr *ngIf="dataTable.length == 0">
                <td colspan="14" class="text-center">Không có kết quả</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p style="float: left; font-weight: bold;">
              Tổng số {{ totalItem | number }} kết quả, đã lọc được
              {{ totalItemFilter | number }} kết quả
            </p>
          </div>
          <div class="col-md-6">
            <nav aria-label="Page navigation example" style="float: right;">
              <ul class="pagination">
                <li class="page-item" [class.disabled]="page == 1">
                  <a class="page-link" (click)="changPage(1)">Đầu</a>
                </li>
                <li class="page-item" [class.disabled]="page == 1">
                  <a class="page-link" (click)="changPage('pre')">Trước</a>
                </li>
                <ng-container *ngIf="page > 4">
                  <li class="page-item"><a class="page-link">...</a></li>
                </ng-container>
                <ng-container *ngFor="let element of arrNumberPage_chil">
                  <li class="page-item" [class.active]="page == element">
                    <a class="page-link" (click)="changPage(element)">{{
                      element
                    }}</a>
                  </li>
                </ng-container>
                <ng-container *ngIf="page < numberPage - 3">
                  <li class="page-item"><a class="page-link">...</a></li>
                </ng-container>
                <li class="page-item" [class.disabled]="page == numberPage">
                  <a class="page-link" (click)="changPage('next')">Sau</a>
                </li>
                <li class="page-item" [class.disabled]="page == numberPage">
                  <a class="page-link" (click)="changPage(numberPage)">Cuối</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-students-sort-detail [StudentData]="StudentData"></app-students-sort-detail> -->

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HinhThucService {
  constructor(@Inject(AppConfig) private readonly appConfig: AppConfiguration, private router: Router, private http: HttpClient) { }

  GetAll(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'HinhThuc/ListAll', {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Insert(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'HinhThuc/Add',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  } 
  Update(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'HinhThuc/Update',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Count(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'HinhThuc/Count',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Delete(req:any,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'HinhThuc/Delete?ID_hinh_thuc='+req.ID_hinh_thuc, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
}
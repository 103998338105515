<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Danh sách hạn chế</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'xl', 'Add', '')"
          (click)="onReset()"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row">
          <div class="col-md-2">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Ma_sv_filter"
              class="form-control"
              placeholder="Nhập mã sv"
            />
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Ho_ten_filter"
              class="form-control"
              placeholder="Nhập tên sv"
            />
          </div>
          <div class="col-md-2">
            <input
              type="date"
              (keyup.enter)="getList()"
              formControlName="NgaySinh_filter"
              class="form-control"
              placeholder="Chọn ngày sinh"
            />
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Gioi_tinh_filter">
              <ng-option value="0">Chọn giới tính</ng-option>
              <ng-option [value]="1">Nam</ng-option>
              <ng-option [value]="2">Nữ</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="ID_dan_toc_filter">
              <ng-option value="0" selected>Chọn dân tộc</ng-option>
              <ng-option *ngFor="let p of lstDanToc" [value]="p.ID_dan_toc">{{
                p.Dan_toc
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Ton_giao_filter"
              class="form-control"
              placeholder="Tôn giáo"
            />
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <!-- <div class="col-md-3">
            <label>Mã sinh viên</label>
            <input type="text" class="form-control" [(ngModel)]="code_filter" formControlName="code_filter"
              (keyup)="filterById()" placeholder="Nhập mã sinh viên..." />
          </div>
          <div class="col-md-3">
            <label>Họ tên</label>
            <input type="text" class="form-control" [(ngModel)]="name_filter" formControlName="name_filter"
              (keyup)="filterById()" placeholder="Nhập họ tên..." />
          </div> -->

          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="He_filter">
              <ng-option value="0" selected> Chọn hệ</ng-option>
              <ng-option *ngFor="let p of DSHe" [value]="p.Ten_he">{{
                p.Ten_he
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" (keyup)="filterById()"   formControlName="He_filter" class="form-control" placeholder="Nhập hệ..." /> -->
          </div>
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="Khoa_filter">
              <ng-option value="0"> Chọn khoa</ng-option>
              <ng-option *ngFor="let p of DSKhoa" [value]="p.Ten_khoa">{{
                p.Ten_khoa
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" (keyup)="filterById()"   formControlName="Khoa_filter" class="form-control" placeholder="Nhập khoa..." /> -->
          </div>
          <div class="col-md-2">
            <input
              (keyup.enter)="getList()"
              type="text"
              class="form-control"
              formControlName="Khoa_hoc_filter"
              placeholder="Nhập khóa học..."
            />
          </div>

          <!-- <div class="col-md-2">
            <label>Lớp học</label>
            <input type="text" class="form-control" [(ngModel)]="lop_filter" formControlName="lop_filter"
              (keyup)="filterById()" placeholder="Nhập lớp học..." />
          </div> -->
          <!-- <div class="col-md-2">
            <label>Đối tượng</label>
            <input type="text" class="form-control" [(ngModel)]="doituong_filter" formControlName="doituong_filter"
              (keyup)="filterById()" placeholder="Nhập đối tượng..." />
          </div> -->
          <div class="col-md-2">
            <ng-select
              (change)="getList()"
              formControlName="Doi_tuong_chinh_sach_filter"
            >
              <ng-option value="0"> Chọn loại đối tượng chính sách</ng-option>
              <!-- <option value="-1" selected> Là đối tượng chính sách bất kỳ</option> -->
              <ng-option *ngFor="let p of DSDoiTuong" [value]="p.Ten_dt">{{
                p.Ten_dt
              }}</ng-option>
            </ng-select>
            <!-- <input type="text" (keyup)="filterById()" formControlName="Doi_tuong_chinh_sach_filter" class="form-control" placeholder="Nhập tên đối tượng" /> -->
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-2">
            <ng-select (change)="getList()" formControlName="ID_tinh_tt_filter">
              <ng-option value="0" selected>Tỉnh thường trú</ng-option>
              <ng-option *ngFor="let p of lstTinh" [value]="p.ID_tinh">{{
                p.Ten_tinh
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Dienthoai_canhan_filter"
              class="form-control"
              placeholder="Điện thoại"
            />
          </div>
          <div class="col-md-2">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Mail_filter"
              class="form-control"
              placeholder="Địa chỉ email"
            />
          </div>
          <!-- <div class="col-md-2">
            <input type="text" formControlName="NguyenVong_filter" class="form-control"
              placeholder="Nhập nguyện vọng" />
          </div> -->
        </div>
      </form>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div
              class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
            >
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable">
                    <td>{{ p.Ma_sv }}</td>
                    <td>{{ p.Ho_ten }}</td>
                    <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ p.Que_quan }}</td>
                    <td>{{ p.Ghi_chu }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-danger"
                        (click)="open(DeleteModal, '', 'Delete', p)"
                        style="margin-right: 7px;"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Thêm danh sách</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Action">
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-10">Mã sinh viên hoặc CMND</label>
          <input
            class="form-control"
            placeholder="Nhập mã sinh viên hoặc CMND"
            type="text"
            formControlName="code"
          />
        </div>
        <div class="float-sm-left" style="padding: 30px;">
          <button
            type="button"
            class="btn btn-md btn-success"
            (click)="search()"
            style="margin-left: 1em;"
          >
            <i class="fas fa-search"></i> Tìm sinh viên
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-10">Họ tên</label>
          <input
            class="form-control"
            placeholder="Họ tên"
            type="text"
            formControlName="Ho_ten"
            readonly
          />
        </div>
        <div class="col-md-4">
          <label class="col-md-10">Ngày sinh</label>
          <input
            class="form-control"
            placeholder="Ngày sinh"
            type="text"
            formControlName="Ngay_sinh"
            readonly
          />
        </div>
        <div class="col-md-4">
          <label class="col-md-10">Quê quán</label>
          <input
            class="form-control"
            placeholder="Quê quán"
            type="text"
            formControlName="Que_quan"
            readonly
          />
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Ghi chú</label>
          <textarea
            class="form-control"
            cols="100"
            rows="3"
            placeholder="Ghi chú"
            formControlName="Ghi_chu"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

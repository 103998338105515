import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  FormControlName,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InvoinceService } from 'src/app/utils/services/invoince.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { ExportService } from 'src/app/utils/services/export.service';
import { AbstractControl } from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-invoince',
  templateUrl: './invoince.component.html',
  styleUrls: ['./invoince.component.scss'],
})
export class InvoinceComponent extends BaseComponent implements OnInit {
  ID_bien_lai: any;
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  dataImport: any = [];
  Nam_hoc_arr: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  So_the: any;
  dt_print: any = '123';
  dt_print2: any = '123';
  Ngay_cap_the: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  dt_detail: any;
  NameTitle: string;
  Title: string = 'Đang tải danh sách...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  Hoc_ky: any = '';
  Nam_hoc: any = '';
  Thu_chi: any = '';
  Huy_phieu: any = '';
  Tu_ngay: any = '';
  Den_ngay: any = '';
  Ma_ten_sv: any = '';
  public com: common;
  FormFilter = new FormGroup({
    Tu_ngay: new FormControl(
      formatDate(
        new Date().setMonth(new Date().getMonth() - 1),
        'yyyy-MM-dd',
        'en'
      )
    ),
    Den_ngay: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en')),
  });
  Filter = new FormGroup({
    Ma_sv_ft: new FormControl(null),
    Hoc_ky_ft: new FormControl(''),
    Nam_hoc_ft: new FormControl(''),
    Loai_phieu_ft: new FormControl(''),
    Huy_phieu_ft: new FormControl(false),
  });
  ngOnInit() {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);

      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
      this.Nam_hoc =
        new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.getToken();
    this.getCount();
    this.getList();
  }
  getCount() {
    this.InvoinceService.Count(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Thu_chi: this.Thu_chi,
      Huy_phieu: this.Huy_phieu,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
      Ma_ten_sv: this.Ma_ten_sv,
    };
    this.InvoinceService.getList(dataFilter, this.Token).subscribe((z) => {
      if (z.Status == 10) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
        this.router.navigate(['/admin']);
        this.spinner.hide();
      } else {
        this.dataTable = z.Data;
        this.InvoinceService.CountFilter(dataFilter, this.Token).subscribe(
          (z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          }
        );
      }
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 10000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.Hoc_ky = '';
    this.Nam_hoc = '';
    this.Thu_chi = '';
    this.Huy_phieu = '';
    this.Tu_ngay = '';
    this.Den_ngay = '';
    this.Ma_ten_sv = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Thu_chi: this.Thu_chi,
      Huy_phieu: this.Huy_phieu,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
      Ma_ten_sv: this.Ma_ten_sv,
    };
    this.InvoinceService.getList(dataFilter, this.Token).subscribe((z) => {
      if (z.Status == 10) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      } else {
        this.dataTable = z.Data;
        this.spinner.hide();
      }
    });
  }
  Find() {
    let Tungay = this.FormFilter.value.Tu_ngay;
    let Denngay = this.FormFilter.value.Den_ngay;
    let array = [{ Tu_ngay: Tungay, Den_ngay: Denngay }];
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.getList();
  }
  open(content, sizm, Loai, Data) {
    this.ID_bien_lai = Data.ID_bien_lai;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Print(Data) {
    this.dt_print = Data;
    this.InvoinceService.getdetail(
      this.dt_print.ID_bien_lai,
      this.Token
    ).subscribe((z) => {
      this.dt_detail = z.Data;
    });
    setTimeout(() => {
      let printContents = document.getElementById('print-section').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 200);
    this.modalService.dismissAll('DeleteModal');
  }
  Print2(Data) {
    this.dt_print = Data;
    this.InvoinceService.getdetail(
      this.dt_print.ID_bien_lai,
      this.Token
    ).subscribe((z) => {
      this.dt_detail = z.Data;
    });

    setTimeout(() => {
      let printContents = document.getElementById('print-section2').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 200);
    this.modalService.dismissAll('DeleteModal');
  }
  filterById() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_sv_dt = data[2];
      let Hoc_ky_dt = data[6];
      let Nam_hoc_dt = data[7];
      let Loai_phieu_dt = data[12];
      let Huy_phieu_data = data[13];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let fillter = true;
      if (this.Filter.value.Ma_sv_ft != undefined) {
        var a = this.removeAccents(Ma_sv_dt);
        var b = this.removeAccents(this.Filter.value.Ma_sv_ft);
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (
        this.Filter.value.Hoc_ky_ft != '' &&
        this.Filter.value.Hoc_ky_ft != undefined
      ) {
        var a = Hoc_ky_dt;
        var b = this.Filter.value.Hoc_ky_ft;
        bool2 = a == b;
      }
      if (
        this.Filter.value.Nam_hoc_ft != undefined ||
        this.Filter.value.Nam_hoc_ft != ''
      ) {
        var a = this.removeAccents(Nam_hoc_dt);
        var b = this.removeAccents(this.Filter.value.Nam_hoc_ft);
        bool3 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (
        this.Filter.value.Loai_phieu_ft != '' &&
        this.Filter.value.Loai_phieu_ft != undefined
      ) {
        var a = this.removeAccents(Loai_phieu_dt);
        var b = this.removeAccents(this.Filter.value.Loai_phieu_ft);
        bool4 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Filter.value.Huy_phieu_ft != false) {
        var b = this.Filter.value.Huy_phieu_ft.toString();

        var a = Huy_phieu_data;

        bool5 = a == b;
      }

      fillter = bool1 && bool2 && bool3 && bool4 && bool5;

      return fillter;
    });
  }
  print() {
    this.spinner.show();
    let req = {
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Thu_chi: this.Thu_chi,
      Huy_phieu: this.Huy_phieu,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
      Ma_ten_sv: this.Ma_ten_sv,
    };
    this.InvoinceService.Excel(req, this.Token).subscribe((z) => {
      this.exportService.exportExcelByte(z.FileData, 'Bien_lai_hoa_don');
      this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
      this.spinner.hide();
    });
  }
  removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  updateStatus() {
    let req = {
      ID_bien_lai: this.ID_bien_lai,
      Huy_phieu: 1,
    };
    this.InvoinceService.updateStatus(req, this.Token).subscribe((z) => {
      this.modalService.dismissAll('AddModal');
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList();
    });
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_BienLai;
    window.open(urlHelp, '_blank');
  }
  fileUpload(event: any) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event) => {
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' });
      workbook.SheetNames.forEach((sheet) => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        this.dataImport = data;
      });
    };
  }
  ImportKhoanThuChiExcel() {
    this.spinner.show();
    this.InvoinceService.ImportExcel(this.dataImport, this.Token).subscribe(
      (x) => {
        this.spinner.hide();
        if (x.Status == 2) {
          this.toastr.success(x.Message);
        } else {
          this.toastr.warning(x.Message);
        }
      }
    );
  }
}

<aside
  #mainSidebar
  class="main-sidebar sidebar-light-primary elevation-4 cus-text"
  style="overflow: visible !important; position: fixed;"
>
  <!-- Brand Logo -->
  <a [routerLink]="['/admin/']" class="brand-link">
    <img src="assets/img/unilogo.png" alt="UniDormitory" class="brand-image" />
    <span class="brand-text font-weight-light">UniDormitory</span>
  </a>
  <div class="sidebar">
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li id="menu1" class="nav-item menu-open">
          <a
            class="nav-link cus-bg"
            (click)="menu('menu1')"
            style="background: #418994; color: white;"
          >
            <i class="fas fa-hotel"></i>&nbsp;
            <p>
              Cơ sở
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Co_so">
              <a
                (click)="setTitle('Cơ sở')"
                [routerLink]="['/admin/campus']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-suitcase"></i>
                <p>&nbsp;Cơ sở</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Toa_nha">
              <a
                (click)="setTitle('Tòa nhà')"
                [routerLink]="['/admin/building']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-building"></i>
                <p>&nbsp; Tòa nhà</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Loai_phong">
              <a
                (click)="setTitle('Loại phòng')"
                [routerLink]="['/admin/room-category']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-warehouse"></i>
                <p>&nbsp; Loại phòng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Phong">
              <a
                (click)="setTitle('Phòng')"
                [routerLink]="['/admin/room']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-chess-rook"></i>
                <p>&nbsp; Phòng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Phong">
              <a
                (click)="setTitle('Sơ đồ phòng')"
                [routerLink]="['/admin/plan-room']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-columns"></i>
                <p>&nbsp; Sơ đồ phòng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Thiet_bi">
              <a
                (click)="setTitle('Thiết bị')"
                [routerLink]="['/admin/device']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-tablet-alt"></i>
                <p>&nbsp;Thiết bị</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu2" class="nav-item menu-open">
          <a
            class="nav-link cus-bg"
            (click)="menu('menu2')"
            style="background: #418994; color: white;"
          >
            <i class="fas fa-users-cog"></i>&nbsp;
            <p>
              Đăng ký, xếp phòng
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Quy_dinh_dang_ky">
              <a
                (click)="setTitle('Quy định đăng ký')"
                [routerLink]="['/admin/regulation-object']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-pencil-ruler"></i>
                <p>&nbsp; Quy định đăng ký</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Duyet_dang_ky">
              <a
                (click)="setTitle('Duyệt đăng ký')"
                [routerLink]="['/admin/register-dorm']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-highlighter"></i>
                <p>&nbsp; Duyệt đăng ký</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Xep_phong">
              <a
                (click)="setTitle('Xếp phòng')"
                [routerLink]="['/admin/students-sort']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-calendar-alt"></i>
                <p>&nbsp;Xếp phòng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Danh_sach_o">
              <a
                (click)="setTitle('Danh sách ở')"
                [routerLink]="['/admin/listpeopledorm']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-address-book"></i>
                <p>&nbsp; Danh sách ở</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Danh_sach_ra">
              <a
                (click)="setTitle('Danh sách ra')"
                [routerLink]="['/admin/listpeopledes']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-people-arrows"></i>
                <p>&nbsp; Danh sách ra</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Danh_sach_han_che">
              <a
                (click)="setTitle('Danh sách hạn chế')"
                [routerLink]="['/admin/black-list']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-address-card"></i>
                <p>&nbsp; Danh sách hạn chế</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu3" class="nav-item menu-open">
          <a
            class="nav-link cus-bg"
            (click)="menu('menu3')"
            style="background: #418994; color: white;"
          >
            <i class="fas fa-chart-bar"></i>&nbsp;
            <p>
              Tổng hợp khoản thu
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="SoNuoc">
              <a
                (click)="setTitle('Số nước')"
                [routerLink]="['/admin/waternumber']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-tint"></i>
                <p>&nbsp; Số nước</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="SoDien">
              <a
                (click)="setTitle('Số điện')"
                [routerLink]="['/admin/electricnumber']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-bolt"></i>
                <p>&nbsp; Số điện</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Bien_lai">
              <a
                (click)="setTitle('Báo cáo thống kê')"
                [routerLink]="['/admin/integrated-statistics']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-poll"></i>
                <p>&nbsp;Báo cáo thống kê</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Bien_lai">
              <a
                (click)="setTitle('Biên lai hóa đơn')"
                [routerLink]="['/admin/invoince']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-file-invoice-dollar"></i>
                <p>&nbsp;Biên lai hóa đơn</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Bien_lai">
              <a
                (click)="setTitle('Thống kê khoản nộp')"
                [routerLink]="['/admin/statistical-money']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-chart-bar"></i>
                <p>&nbsp;Thống kê khoản nộp</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QuyDinhKhoanThu">
              <a
                (click)="setTitle('Quy định khoản khu')"
                [routerLink]="['/admin/QuyDinhKhoanThu']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-money-bill"></i>
                <p>&nbsp;Quy định khoản thu</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="BienDong">
              <a
                (click)="setTitle('Biến động khoản thu')"
                [routerLink]="['/admin/bien-dong-quy-dinh-thu']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-chart-line"></i>
                <p>&nbsp;Biến động khoản thu</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu4" class="nav-item">
          <a
            class="nav-link cus-bg"
            (click)="menu('menu4')"
            style="background: #418994; color: white;"
          >
            <i class="fas fa-user-graduate"></i>&nbsp;
            <p>
              Sinh viên
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="DeXuatSinhVien">
              <a
                (click)="setTitle('Đề xuất sinh viên')"
                [routerLink]="['/admin/de-xuat-sinh-vien']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-book-reader"></i>
                <p>&nbsp; Đề xuất sinh viên</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                (click)="setTitle('Loại đề xuất')"
                [routerLink]="['/admin/loai-de-xuat']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-book-reader"></i>
                <p>&nbsp; Loại đề xuất</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="LichSuSuaChua">
              <a
                (click)="setTitle('Lịch sử sửa chữa')"
                [routerLink]="['/admin/lich-su-sua-chua']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-tools"></i>
                <p>&nbsp; Lịch sử sửa chữa</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="DiemSinhVien">
              <a
                (click)="setTitle('Điểm sinh viên')"
                [routerLink]="['/admin/diem-sinh-vien']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-file-signature"></i>
                <p>&nbsp; Điểm sinh viên</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="DiemPhong">
              <a
                (click)="setTitle('Điểm phòng')"
                [routerLink]="['/admin/diem-phong']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-calculator"></i>
                <p>&nbsp; Điểm phòng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="KhenThuongKyLuat">
              <a
                (click)="setTitle('Khen thưởng kỷ luật')"
                [routerLink]="['/admin/khenthuongkyluat']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-sign-language"></i>
                <p>&nbsp; Khen thưởng kỷ luật</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="ViPham">
              <a
                (click)="setTitle('Vi phạm')"
                [routerLink]="['/admin/vi-pham']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-user-times"></i>
                <p>&nbsp; Vi phạm</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu5" class="nav-item">
          <a
            class="nav-link cus-bg"
            (click)="menu('menu5')"
            style="background: #418994; color: white;"
          >
            <i class="fas fa-sticky-note"></i>&nbsp;
            <p>
              Danh mục
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                (click)="setTitle('Tiện ích')"
                [routerLink]="['/admin/tien-ich']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fa fa-bath"></i>

                <p>&nbsp; Tiện ích</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                (click)="setTitle('Loại đề xuất')"
                [routerLink]="['/admin/loai-de-xuat']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-book-reader"></i>
                <p>&nbsp; Loại đề xuất</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                (click)="setTitle('Đối tượng chính sách')"
                [routerLink]="['/admin/doi-tuong-chinh-sach']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-user-alt"></i>
                <p>&nbsp; Đối tượng chính sách</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="DonGiaDienNuoc">
              <a
                (click)="setTitle('Đơn giá điện nước')"
                [routerLink]="['/admin/don-gia-dien-nuoc']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-money-bill"></i>
                <p>&nbsp; Đơn giá điện nước</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="HinhThuc">
              <a
                (click)="setTitle('Khen thưởng kỷ luật')"
                [routerLink]="['/admin/hinhthuc']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-award"></i>
                <p>&nbsp;Khen thưởng kỷ luật</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                (click)="setTitle('Tài liệu')"
                [routerLink]="['/admin/document']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-file-signature"></i>

                <p>&nbsp; Tài liệu</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu6" class="nav-item">
          <a
            class="nav-link cus-bg"
            (click)="menu('menu6')"
            style="background: #418994; color: white;"
          >
            <i class="fas fa-cog"></i>&nbsp;
            <p>
              Quản trị hệ thống
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a
                (click)="setTitle('Template Email')"
                [routerLink]="['/admin/template-email']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-envelope"></i>
                <p>&nbsp; Nội dung email</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QuanTriEmail">
              <a
                (click)="setTitle('Quản trị Email')"
                [routerLink]="['/admin/quan-tri-email']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-envelope"></i>
                <p>&nbsp; Quản trị Email</p>
              </a>
            </li>

            <li class="nav-item" *ngIf="LichSuEmail">
              <a
                (click)="setTitle('Lịch sử Email')"
                [routerLink]="['/admin/lich-su-email']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-envelope"></i>
                <p>&nbsp; Lịch sử Email</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="ThamSoHeThong">
              <a
                (click)="setTitle('Tham số hệ thống')"
                [routerLink]="['/admin/tham-so-he-thong']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-cog"></i>
                <p>&nbsp; Tham số hệ thống</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="PhanQuyen">
              <a
                (click)="setTitle('Phân quyền người dùng')"
                [routerLink]="['/admin/Users-Toa-Nha']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-users-cog"></i>
                <p>&nbsp;Phân quyền người dùng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Tra_cuu_log">
              <a
                (click)="setTitle('Log hệ thống')"
                [routerLink]="['/admin/search-log']"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fas fa-history"></i>
                <p>&nbsp; Tra cứu log</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
</aside>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoaiDeXuatService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DeXuatLoai/Load_List')
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID,token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DeXuatLoai/Delete?ID='+ID, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DeXuatLoai/Insert',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DeXuatLoai/Update',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-md-6">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item">
              <a [routerLink]="['/']"
                  ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
                >
              </li>
              <li class="breadcrumb-item active">Cập nhật thông tin cá nhân</li>
            </ol>
          </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" style="float: right;margin-right: 5px; " (click)="open(UpdateModal, 'xs', 'Update', '')">
                  <i class="fas fa-save"></i>&nbsp;Lưu
                </button>
            </div>
        </div>
    </div>
</section>
<!----------------------------------------------- THÔNG TIN SINH VIÊN ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="modal-body" style="padding-top: 0px;">
          <div class="__title-register col-md-12">
            {{Ho_ten_sv}} - {{Ten_nganh_nhap_hoc_sv}} - K{{Khoa_hoc_dang_hoc_sv}}&nbsp; <i class="fas fa-user"></i>
          </div>
          <form class="form-horizontal" [formGroup]="Insert">
            <div class="cus-box">
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Mã SV</label>
                  <input class="form-control" placeholder="Nhập mã sv" type="text" formControlName="Ma_sv" readonly />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Họ tên</label>
                  <input class="form-control" placeholder="Nhập tên sv" type="text" formControlName="Ho_ten" readonly />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Ngày sinh</label>
                  <input class="form-control" type="date" formControlName="Ngay_sinh" readonly />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10 require" style="margin-top: 10px;color: red;">Email (bắt buộc)</label>
                  <input class="form-control" placeholder="Nhập email" type="text" formControlName="Email"
                    [ngClass]="{ 'is-invalid': submitted && checkvalue.Email.errors }"
                  />
                  <div *ngIf="submitted && checkvalue.Email.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.Email.errors.required">
                      Email không được bỏ trống !
                    </div>
                    <div *ngIf="checkvalue.Email.errors.email">
                      Email không đúng định dạng !
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt10">
                <div class="col-md-3">
                  <label class="col-md-10 require" style="margin-top: 10px;color: red;">Số điện thoại (bắt buộc)</label>
                  <input type="tel" class="form-control" placeholder="Nhập SĐT" formControlName="Dienthoai_canhan" 
                      onkeyup="
                              if(this.value<0)
                              {
                                  this.value = this.value * -1
                              }
                              else if(this.value == null || this.value == NaN || this.value == undefined || this.value == '' || this.value.toString().includes(',') || this.value.toString().includes('.') || this.value.toString().includes('e')) {
                                  this.value = ''
                              }
                              " 
                 >    
                  <div *ngIf="submitted && checkvalue.Dienthoai_canhan.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.Dienthoai_canhan.errors.required">
                      Số điện thoại không được bỏ trống !
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Đối tượng chính sách</label>
                  <ng-select *ngIf="ID_doi_tuong_TS_Insert>0" formControlName="ID_doi_tuong_TS" notFoundText="Không có kết quả" readonly>
                    <ng-option value = "0">Chọn đối tượng chính sách</ng-option>
                    <ng-option *ngFor="let item of dataDoiTuongChinhSach" [value] = "item.ID_dt">
                      {{ item.Ten_dt }}
                    </ng-option>
                  </ng-select>
                  <input class="form-control" type="text" *ngIf="!(ID_doi_tuong_TS_Insert>0)" value="Không" readonly>
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Khu vực</label>
                  <ng-select *ngIf="ID_khu_vuc_TS_Insert>0" formControlName="ID_khu_vuc_TS" notFoundText="Không có kết quả">
                    <ng-option value = "">Chọn khu vực</ng-option>
                    <ng-option *ngFor="let item of dataKhuVucTuyenSinh" [value]="item.ID_kv">{{ item.Ten_kv }}</ng-option>
                  </ng-select>
                  <input class="form-control" type="text" *ngIf="!(ID_khu_vuc_TS_Insert>0)" value="Không" readonly>
                </div>
                
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Tình trạng sức khỏe</label>
                  <input
                    class="form-control"
                    placeholder="Nhập tình trạng sức khỏe"
                    type="text"
                    formControlName="Tinh_trang_suc_khoe"
                  />
                </div>
              </div>
              <div class="row mt10">
                <div class="col-md-12">
                  <label class="col-md-10">Ghi chú</label>
                  <textarea
                    type="text"
                    placeholder="Nhập ghi chú"
                    class="form-control"
                    formControlName="Ghi_chu"
                  ></textarea>
                </div>
              </div>            
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!-----------------------------------------------XÁC NHẬN------------------------------------------------->
<ng-template #UpdateModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box-cf">
        <i
          class="far fa-check-circle"
          style="
            color: green;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 18px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn chắc chắn đã khai báo đúng thông tin cá nhân ?
      </p>
      <div class="container" style="text-align: center;">
        <label class="col-md-12">
          Email:
          <label class="__inforRegistered">{{Email_get}}</label>
        </label>
        <label class="col-md-12">
          Số điện thoại:
          <label class="__inforRegistered">{{So_dien_thoai_get}}</label>
        </label>
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="onSubmit()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>
  
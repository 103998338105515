import { Injectable, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class QuyDinhKhoanThuService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  getAll(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/ListAll', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Count(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CountFilter(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/Delete', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiDelete(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/MultiDelete', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiInsert(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/MultiInsert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListSV(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/HoSoSV', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getLoaiKhoanThu(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DanhSachKhoanNopNienChe/LoaiKhoanThu', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getHe(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'He/Danh_sach_he', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getKhoa(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Khoa/Danh_sach_khoa', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getKhoaHoc(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Core/KhoaHoc_Load_List', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getChuyenNganh(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'ChuyenNganh/Danh_sach_chuyen_nganh', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  getLop(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniSystemAPI + 'Lop/Danh_sach_lop_dang_hoc', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import {
  registerLocaleData,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { HttpClientModule } from '@angular/common/http';

import { DataTablesModule } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { CampusComponent } from './components/campus/campus.component';
import { BuildingComponent } from './components/building/building.component';
import { RoomtypesComponent } from './components/roomtypes/roomtypes.component';
import { RoomComponent } from './components/room/room.component';
import { DeviceComponent } from './components/device/device.component';
import { RoomdetailComponent } from './components/room/roomdetail/roomdetail.component';
import { GrantcardComponent } from './components/grantcard/grantcard.component';
import { ListpeopledormComponent } from './components/listpeopledorm/listpeopledorm.component';
import { StudentsSortComponent } from './components/students-sort/students-sort.component';
import { NumberElectricComponent } from './components/number-electric/number-electric.component';
import { AccessHistoryComponent } from './components/access-history/access-history.component';
import { AccessHistoryDetailComponent } from './components/access-history/access-history-detail/access-history-detail.component';
import { RegisterDormComponent } from './components/register-dorm/register-dorm.component';
import { RoomChargeComponent } from './components/room-charge/room-charge.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NumberWaterComponent } from './components/number-water/number-water.component';
import { CollectionReceiptComponent } from './components/collection-receipt/collection-receipt.component';
import { ReceiptDetailComponent } from './components/collection-receipt/receipt-detail/receipt-detail.component';
import { PrintReceiptComponent } from './components/collection-receipt/print-receipt/print-receipt.component';
import { StatisticalComponent } from './components/statistical/statistical.component';
import { ReceiptDestroyComponent } from './components/collection-receipt/receipt-destroy/receipt-destroy.component';
import { ReceiptDestroyDetailComponent } from './components/collection-receipt/receipt-destroy/receipt-destroy-detail/receipt-destroy-detail.component';
import { ListPeopleDesComponent } from './components/list-people-des/list-people-des.component';
import { RoomCategoryComponent } from './components/room-category/room-category.component';
import { DangNhapComponent } from './pages/dang-nhap/dang-nhap.component';
import { XacNhanComponent } from './pages/xac-nhan/xac-nhan.component';
import { PortalComponent } from './pages/portal/portal.component';
import { PortalFooterComponent } from './pages/portal/portal-footer/portal-footer.component';
import { PortalHeaderComponent } from './pages/portal/portal-header/portal-header.component';
import { PortalMenuSidebarComponent } from './pages/portal/portal-menu-sidebar/portal-menu-sidebar.component';
import { PortalUserDropdownComponent } from './pages/portal/portal-header/portal-user-dropdown/portal-user-dropdown.component';
import { PortalDangKyComponent } from './pages/portal/portal-dang-ky/portal-dang-ky.component';
import { AddPaymentRegulationsComponent } from './components/room-charge/add-payment-regulations/add-payment-regulations.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { StudentsSortDetailComponent } from './components/students-sort/students-sort-detail/students-sort-detail.component';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
import { ReceiptRoomComponent } from './components/listpeopledorm/receipt-room/receipt-room.component';
import { RegulationObjectComponent } from './components/regulation-object/regulation-object.component';
import { PlanRoomComponent } from './components/plan-room/plan-room.component';
import { BlackListComponent } from './components/black-list/black-list.component';
import { InvoinceComponent } from './components/invoince/invoince.component';
import { StatisticalMoneyComponent } from './components/statistical-money/statistical-money.component';
import { ContractComponent } from './components/listpeopledorm/contract/contract.component';
import { InvoiceAddComponent } from './components/invoice-add/invoice-add.component';
import { ReceiptsComponent } from './components/invoince/receipts/receipts.component';
import { ReceiptPaymentComponent } from './components/invoince/receipt-payment/receipt-payment.component';
import { PortalChangePassComponent } from './pages/portal/portal-change-pass/portal-change-pass.component';
import { SearchLogComponent } from './components/search-log/search-log.component';
import { VersionDetailComponent } from './components/version-detail/version-detail.component';
import { BaseComponent } from './components/base/base.component';
import { QuyDinhKhoanThuComponent } from './components/quy-dinh-khoan-thu/quy-dinh-khoan-thu.component';
import { DeXuatSinhVienComponent } from './components/de-xuat-sinh-vien/de-xuat-sinh-vien.component';
import { DiemSinhVienComponent } from './components/diem-sinh-vien/diem-sinh-vien.component';
import { DiemPhongComponent } from './components/diem-phong/diem-phong.component';
import { DeXuatComponent } from './pages/portal/de-xuat/de-xuat.component';
import { LichSuSuaChuaComponent } from './components/lich-su-sua-chua/lich-su-sua-chua.component';
import { DeveloperSQLComponent } from './components/developer-sql/developer-sql.component';
import { FilterSVComponent } from './components/plan-room/filter-sv/filter-sv.component';
import { ViPhamComponent } from './components/vi-pham/vi-pham.component';
import { HinhThucComponent } from './components/hinh-thuc/hinh-thuc.component';
import { KhenThuongKyLuatComponent } from './components/khen-thuong-ky-luat/khen-thuong-ky-luat.component';
import { UsersToaNhaComponent } from './components/users-toa-nha/users-toa-nha.component';
import { DangKyQuaTrinhComponent } from './components/dang-ky-qua-trinh/dang-ky-qua-trinh.component';
import { XacNhanSauDaiHocComponent } from './pages/xac-nhan-sau-dai-hoc/xac-nhan-sau-dai-hoc.component';
import { DonGiaDienNuocComponent } from './components/don-gia-dien-nuoc/don-gia-dien-nuoc.component';
import { QuanTriEmailComponent } from './components/quan-tri-email/quan-tri-email.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { KhoanThuAddComponent } from './components/invoince/khoan-thu-add/khoan-thu-add.component';
import { PortalDangKyTotalPageComponent } from './pages/portal/portal-dang-ky-total-page/portal-dang-ky-total-page.component';
import { PortalKetQuaComponent } from './pages/portal/portal-ket-qua/portal-ket-qua.component';
import { ChatBotComponent } from './pages/chat-bot/chat-bot.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PortalCapNhatThongTinComponent } from './pages/portal/portal-cap-nhat-thong-tin/portal-cap-nhat-thong-tin.component';
import { LichSuEmailComponent } from './components/lich-su-email/lich-su-email.component';
import { ThamSoHeThongComponent } from './components/tham-so-he-thong/tham-so-he-thong.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PrintSoPhongComponent } from './components/plan-room/print-so-phong/print-so-phong.component';
import { LoaiDeXuatComponent } from './components/loai-de-xuat/loai-de-xuat.component';
import { ThongTinSinhVienComponent } from './pages/portal/thong-tin-sinh-vien/thong-tin-sinh-vien.component';
import { TemplateEmailComponent } from './components/template-email/template-email.component';
import { ChartDeXuatComponent } from './components/de-xuat-sinh-vien/chart-de-xuat/chart-de-xuat.component';
registerLocaleData(localeEn, 'en-EN');
import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
import { DoiTuongChinhSachComponent } from './components/doi-tuong-chinh-sach/doi-tuong-chinh-sach.component';
import { TienIchComponent } from './components/tien-ich/tien-ich.component';
import { FilterSvManualComponent } from './components/plan-room/filter-sv-manual/filter-sv-manual.component';
import { IntegratedStatisticsComponent } from './components/integrated-statistics/integrated-statistics.component';
import { DocumentComponent } from './components/document/document.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
@NgModule({
  declarations: [
    RegulationObjectComponent,
    AddPaymentRegulationsComponent,
    StatisticalComponent,
    PrintReceiptComponent,
    ReceiptDetailComponent,
    CollectionReceiptComponent,
    RoomChargeComponent,
    NumberElectricComponent,
    NumberWaterComponent,
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    UserDropdownMenuComponent,
    CampusComponent,
    BuildingComponent,
    RoomtypesComponent,
    RoomComponent,
    DeviceComponent,
    RoomdetailComponent,
    GrantcardComponent,
    ListpeopledormComponent,
    StudentsSortComponent,
    AccessHistoryComponent,
    AccessHistoryDetailComponent,
    RegisterDormComponent,
    ReceiptDestroyComponent,
    ReceiptDestroyDetailComponent,
    ListPeopleDesComponent,
    RoomCategoryComponent,
    DangNhapComponent,
    XacNhanComponent,
    PortalComponent,
    PortalFooterComponent,
    PortalHeaderComponent,
    PortalMenuSidebarComponent,
    PortalUserDropdownComponent,
    PortalDangKyComponent,
    StudentsSortDetailComponent,
    ChangePassComponent,
    ReceiptRoomComponent,
    PlanRoomComponent,
    BlackListComponent,
    InvoinceComponent,
    StatisticalMoneyComponent,
    ContractComponent,
    InvoiceAddComponent,
    ReceiptsComponent,
    ReceiptPaymentComponent,
    PortalChangePassComponent,
    SearchLogComponent,
    VersionDetailComponent,
    BaseComponent,
    QuyDinhKhoanThuComponent,
    DeXuatSinhVienComponent,
    DiemSinhVienComponent,
    DiemPhongComponent,
    DeXuatComponent,
    LichSuSuaChuaComponent,
    DeveloperSQLComponent,
    FilterSVComponent,
    ViPhamComponent,
    HinhThucComponent,
    KhenThuongKyLuatComponent,
    UsersToaNhaComponent,
    DangKyQuaTrinhComponent,
    XacNhanSauDaiHocComponent,
    DonGiaDienNuocComponent,
    QuanTriEmailComponent,
    KhoanThuAddComponent,
    PortalDangKyTotalPageComponent,
    PortalKetQuaComponent,
    ChatBotComponent,
    PortalCapNhatThongTinComponent,
    LichSuEmailComponent,
    ThamSoHeThongComponent,
    PrintSoPhongComponent,
    LoaiDeXuatComponent,
    ThongTinSinhVienComponent,
    TemplateEmailComponent,
    ChartDeXuatComponent,
    CanvasJSChart,
    DoiTuongChinhSachComponent,
    TienIchComponent,
    FilterSvManualComponent,
    IntegratedStatisticsComponent,
    DocumentComponent,
  ],
  imports: [
    NgxPaginationModule,
    CKEditorModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CKEditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgbModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    Ng2SearchPipeModule,
  ],
  providers: [
    CookieService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

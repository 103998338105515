<section class="content cus-body">
  <div class="card">
    <div class="card-body" style="padding: 0.5rem !important;">
      <div class="row">
        <div class="modal-body" style="padding-top: 0px;">
          <div class="__title-register col-md-12" style="color: green;">
            <h4>THÔNG TIN SINH VIÊN</h4>
          </div>
          <!-- <div class="__title-register col-md-12" style="color: red;">
                       Vui lòng nhập đầy đủ thông tin sinh viên &nbsp;<a style="color: green;" href="http://hssv.hnue.edu.vn/#/auto-login?userName=Ma_sv_get&token=tokenEncr">tại đây</a>
                    </div> -->
          <form class="form-horizontal" [formGroup]="Insert">
            <div class="cus-box">
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Mã sinh viên</label
                  >
                  <input
                    class="form-control"
                    placeholder="Nhập mã sv"
                    type="text"
                    formControlName="Ma_sv"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Họ tên</label
                  >
                  <input
                    class="form-control"
                    placeholder="Nhập tên sv"
                    type="text"
                    formControlName="Ho_ten"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Ngày sinh</label
                  >
                  <input
                    class="form-control"
                    type="date"
                    formControlName="Ngay_sinh"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Giới tính</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    formControlName="Ten_gioi_tinh"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Dân tộc</label
                  >
                  <input
                    class="form-control"
                    formControlName="Dan_toc"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Tôn giáo</label
                  >
                  <input
                    class="form-control"
                    formControlName="Ton_giao"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Đối tượng CS</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    formControlName="Ten_dt_chinh_sach"
                    readonly
                  />
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label" for="exampleCheck1"
                    >Đảng viên</label
                  >
                  <input
                    type="checkbox"
                    class="form-check-input"
                    formControlName="Ngay_sinh"
                    id="exampleCheck1"
                    readonly
                  />
                </div>
                <!-- <div class="form-group form-check">
                                    <label class="form-check-label" for="exampleCheck1">Đoàn viên</label>
                                    <input type="checkbox" class="form-check-input" formControlName="Ngay_sinh"  id="exampleCheck1">
                                </div> -->
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Khoa</label
                  >
                  <input
                    class="form-control"
                    formControlName="Ten_khoa_nhap_hoc"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Khoá</label
                  >
                  <input
                    class="form-control"
                    formControlName="Khoa_hoc_nhap_hoc"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Lớp</label>
                  <input
                    class="form-control"
                    formControlName="Lop"
                    type="text"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <h6
                  style="
                    margin-bottom: 11px;
                    margin-top: 10px;
                    margin-left: 21px;
                  "
                >
                  <b><u>Hộ khẩu thường trú</u></b>
                </h6>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Xã/Phường</label
                  >
                  <input
                    class="form-control"
                    formControlName="Xa_phuong_tt"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Quận/huyện:</label
                  >
                  <input
                    class="form-control"
                    formControlName="Huyen_tt"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Tỉnh/TP</label
                  >
                  <input
                    class="form-control"
                    formControlName="Tinh_tt"
                    type="text"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >ĐT cá nhân</label
                  >
                  <input
                    class="form-control"
                    formControlName="Dienthoai_canhan"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >Email:</label
                  >
                  <input
                    class="form-control"
                    formControlName="Email"
                    type="text"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;"
                    >ĐT người thân</label
                  >
                  <input
                    class="form-control"
                    formControlName="So_dien_thoai_bo"
                    type="text"
                    readonly
                  />
                </div>
              </div>
              <!-- <div class="row">
                                <div class="col-md-3">
                                    <label class="col-md-10" style="margin-top: 10px;">STK sinh viên/người thân</label>
                                    <input class="form-control" formControlName="Ngay_sinh"  type="text">
                                </div>
                                <div class="col-md-3">
                                    <label class="col-md-10" style="margin-top: 10px;">Ngân hàng:</label>
                                    <input class="form-control" formControlName=""  type="text">
                                </div>
                                <div class="col-md-3">
                                    <label class="col-md-10" style="margin-top: 10px;">Chi nhánh</label>
                                    <input class="form-control" formControlName=""  type="text">
                                </div>
                            </div> -->
            </div>
          </form>
          <div class="row" style="float: right; margin-top: 16px;">
            <div class="col-md-8" style="margin-right: 65px;">
              <button
                class="btn btn-success btn-sm"
                [routerLink]="['/dang-ky']"
                style="float: right;"
                style="float: right;"
              >
                <i class="fas fa-fast-forward" [(ngModel)]="checkThongTin"></i>
                Đăng ký ở ký túc xá
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Hình thức khen thưởng kỷ luật</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          style="float: right; margin-right: 10px;"
          (click)="openModal(ThemModal, 'xl', 'Insert', '')"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
        <!-- <button class="btn btn-success btn-sm " style="float: right; margin-right: 10px;">
            <i class="fas fa-file-excel"></i> Xuất excel
          </button> -->
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="FilterForm">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <label>Hình thức</label>
              <input
                class="form-control"
                type="text"
                formControlName="Hinh_thuc_ft"
                (change)="Filter()"
              />
            </div>
            <div class="col-md-6">
              <label>Loại hình thức</label>
              <select
                type="checkbox"
                class="form-control"
                formControlName="Loai_hinh_thuc_ft"
                (change)="Filter()"
              >
                <option value="">Chọn loại hình thức</option>
                <option value="1">Khen thưởng</option>
                <option value="2">Kỷ luật</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6"></div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>STT</th>
                <th>Hình thức</th>
                <th>Loại hình thức</th>
                <th>Ghi chú</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of DataView; let i = index; let odd = odd">
                  <td>{{ p.STT }}</td>
                  <td>{{ p.Hinh_thuc }}</td>
                  <td *ngIf="p.Loai_hinh_thuc == 1">Khen thưởng</td>
                  <td *ngIf="p.Loai_hinh_thuc == 2">Kỷ luật</td>
                  <td>{{ p.Ghi_chu }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="openModal(SuaModal, 'xl', 'Update', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="openModal(DeleteModal, '', 'Delete', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-trash-alt" title="Xoá"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="DataView.length == 0">
                  <td colspan="5" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="InsertForm">
      <div class="container" style="font-size: 14px;">
        <div class="cus-box">
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-6">
              <label class="col-md-10 require">Hình thức</label>
              <input
                class="form-control"
                type="text"
                formControlName="Hinh_thuc"
                placeholder="Nhập hình thức.."
                [ngClass]="{
                  'is-invalid': submitted && checkvalue.Hinh_thuc.errors
                }"
              />
              <div
                *ngIf="submitted && checkvalue.Hinh_thuc.errors"
                class="invalid-feedback"
              >
                <div *ngIf="checkvalue.Hinh_thuc.errors.required">
                  Hình thức không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Hinh_thuc.errors.maxlength">
                  Hình thức không quá 500 ký tự
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label class="col-md-10 require">Loại hình thức</label>
              <select
                class="form-control"
                type="checkbox"
                formControlName="Loai_hinh_thuc"
                placeholder="Nhập loại hình thức.."
                [ngClass]="{
                  'is-invalid': submitted && checkvalue.Loai_hinh_thuc.errors
                }"
              >
                <option value="">Chọn loại khen thưởng</option>
                <option value="1">Khen thưởng</option>
                <option value="2">Kỷ luật</option>
              </select>
              <div
                *ngIf="submitted && checkvalue.Loai_hinh_thuc.errors"
                class="invalid-feedback"
              >
                <div *ngIf="checkvalue.Loai_hinh_thuc.errors.required">
                  Hình thức không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Loai_hinh_thuc.errors.maxlength">
                  Loại hinh thức không quá 500 ký tự
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Ghi chú</label>
              <textarea
                class="form-control"
                cols="100"
                rows="5"
                formControlName="Ghi_chu"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="Insert()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #SuaModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="UpdateForm">
      <div class="container" style="font-size: 14px;">
        <ngb-tabset [destroyOnHide]="false">
          <ngb-tab>
            <ng-template ngbTabContent>
              <div class="cus-box">
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-6">
                    <label class="col-md-10 require">Hình thức</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="Hinh_thuc"
                      placeholder="Nhập hình thức.."
                      [ngClass]="{
                        'is-invalid': submitted && checkvalue.Hinh_thuc.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && checkvalue.Hinh_thuc.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="checkvalue.Hinh_thuc.errors.required">
                        Hình thức không được bỏ trống!
                      </div>
                      <div *ngIf="checkvalue.Hinh_thuc.errors.maxlength">
                        Hình thức không quá 500 ký tự
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="col-md-10 require">Loại hình thức</label>
                    <select
                      class="form-control"
                      type="checkbox"
                      formControlName="Loai_hinh_thuc"
                      placeholder="Nhập loại hình thức.."
                      [ngClass]="{
                        'is-invalid':
                          submitted && checkvalue.Loai_hinh_thuc.errors
                      }"
                    >
                      <option value="">Chọn loại khen thưởng</option>
                      <option value="1">Khen thưởng</option>
                      <option value="2">Kỷ luật</option>
                    </select>
                    <div
                      *ngIf="submitted && checkvalue.Loai_hinh_thuc.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="checkvalue.Loai_hinh_thuc.errors.required">
                        Hình thức không được bỏ trống!
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Ghi chú</label>
                    <textarea
                      class="form-control"
                      cols="100"
                      rows="5"
                      formControlName="Ghi_chu"
                    ></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="update()"
        style="margin-left: 1em;"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn muốn xoá hình thức này
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Delete()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  NgModel,
} from '@angular/forms';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
import { BaseComponent } from 'src/app/components/base/base.component';
import { isBuffer } from 'util';
@Component({
  selector: 'app-vi-pham',
  templateUrl: './vi-pham.component.html',
  styleUrls: ['./vi-pham.component.scss'],
})
export class ViPhamComponent extends BaseComponent implements OnInit {
  titleModal: any = '';
  data_Delete: any;
  isInsert: any;
  FillPhong: any = '';
  DataToaNha: any;
  show_search: any = true;
  List_phong_ban: any;
  FillToaNha: any;
  Ho_ten: any;
  numberChecked: any;
  Ma_sv: any;
  req: any;
  public arrNumberPage2_child: any;
  numberPage2: any;
  pageSize2: any = 10;
  page_sv: any = 1;
  ID_vi_pham: any;
  dataPhongByID: any;
  DuyetDKRow: any;
  HuyDuyetRow: any;
  Ngay_sinh: any;
  Duyet_diem: any;
  Display: any = false;
  DataRoom: any = [];
  totalRecord2: any = 0;
  DataThietBi: any = [];
  dataSV2: any = [];
  dataSV: any = [];
  DataView: any = [];
  dataTypeVipham: any = [];
  beforeFilter: any = [];
  Nam_hoc_arr: any = [];
  DataV: any = [];
  DataViPham: any = [];
  DataFilter: any = [];
  dataRoomView: any;
  dataSVO:any;
  FilterForm = new FormGroup({
    Ma_sv_ft: new FormControl(null,removeSpaces),
    Ten_sv_ft: new FormControl(null, removeSpaces),
    Ngay_sinh_ft: new FormControl(null),
    Tu_ngay_ft: new FormControl(null),
    Den_ngay_ft: new FormControl(null),
    Hoc_ky_ft: new FormControl(""),
    Nam_hoc_ft: new FormControl(
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
    ),
  });
  InsertForm = new FormGroup({
    ID_sv: new FormControl(null),
    Hoc_ky: new FormControl(1, [Validators.required]),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1),
      [Validators.required]
    ),
    Noi_dung: new FormControl(null),
    Ngay_sinh: new FormControl(null),
    Diem_tru: new FormControl(null),
    Ngay_vi_pham: new FormControl(null, [Validators.required]),
    Duyet_diem: new FormControl(null),
  });
  UpdateForm = new FormGroup({
    ID_vi_pham: new FormControl(null),
    ID_sv: new FormControl(null),
    Ma_sv: new FormControl(null),
    Ngay_sinh: new FormControl(null),
    Ten_sv: new FormControl(null),
    Hoc_ky: new FormControl(null),
    Nam_hoc: new FormControl(null),
    Diem_tru: new FormControl(null),
    Noi_dung: new FormControl(null),
    Ngay_vi_pham: new FormControl(null),
    Duyet_diem: new FormControl(null),
  });
  dtTrigger = new Subject();
  public com: common;
  ngOnInit(): void {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.getToken();
    this.GetAll();
    this.getHoSoSV();
  }
  hidesearch() {
    if (this.show_search == true) {
      return (this.show_search = false);
    }
    if (this.show_search == false) {
      return (this.show_search = true);
    }
  }
  GetAll() {
    this.page = 1;
    this.spinner.show();
    this.ViPhamService.GetAll(this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.DataFilter = z.Data;
      this.DataView = this.dataTable.filter(
        (x) =>
          this.pageSize * (this.page - 1) < x.STT &&
          x.STT <= this.pageSize * this.page
      );
      this.numberPage = this.createNumberPage(
        z.Data.length,
        this.pageSize
      ).numberPage;
      this.arrNumberPage_chil = this.createNumberPage(
        z.Data.length,
        this.pageSize
      ).arrNumberPage_chil;
      this.totalItem = z.Data.length;
      this.totalItemFilter = z.Data.length;
      this.spinner.hide();
    });
  }
  getListBuilding() {
    this.FillToaNha = '';
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data;
    });
  }
  ChangeToaNha() {
    let id_toa_nha = this.FilterForm.value.Ten_nha_ft;
    if (id_toa_nha != '') {
      this.dataRoomView = this.dataRoom.filter(
        (x) => x.ID_Toa_Nha == id_toa_nha
      );
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  ChangeToaNhaInsert() {
    let id_toa_nha = this.FilterForm.value.Ten_toa_nha;
    this.dataRoomView = this.dataRoom.filter((x) => x.ID_Toa_Nha == id_toa_nha);
   
  }
  getListRoom() {
    this.FillPhong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data;
      this.dataRoomView = z.Data;
      //this.Filter();
    });
  } // list phòng
  GetCount(req) {
    this.LichSuSuaChuaService.Count(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.totalItem = z.Message;
      }
    });
  }
  getHoSoSV() {
    this.spinner.show();
    this.ListpeopledormService.getList(this.Token).subscribe((z) => {
      this.dataSV = z.Data;
      this.totalRecord2 = z.Data.length;
      this.beforeFilter = z.Data;
      this.numberPage2 = this.createNumberPage(
        this.dataSV.length,
        this.pageSize2
      ).numberPage;
      this.arrNumberPage2_child = this.createNumberPage(
        this.dataSV.length,
        this.pageSize2
      ).arrNumberPage_chil;
      this.dataSV2 = z.Data.filter((x) => 0 < x.STT && x.STT <= this.pageSize2);
      this.spinner.hide();
    });
  }
  checkAllCheckBox(ev) {
    this.DataView.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked() {
    if (this.DataView != undefined) return this.DataView.every((p) => p.checked);
  }
  isAllCheckBoxChecked1() {
    if (this.dataSV != undefined) return this.dataSV.every((p) => p.checked);
  }
  checkAllCheckBox1(ev) {
    this.dataSV.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked2() {
    if (this.dataSV != undefined) return this.dataSV.every((p) => {
      return p.checked
    });
  }
  isCheckBox(p) {

  }
  checkAllCheckBoxAu2(ev) {
    if (this.DataViPham.length > 0) {
      this.DataViPham.forEach((x) => {
        x.checked = ev.target.checked
      })
    }
  }

  changepage2(event: any) {
    this.spinner.show();
    if (event == 'pre') {
      this.page_sv = this.page_sv - 1;
    } else if (event == 'next') {
      this.page_sv = this.page_sv + 1;
    } else {
      this.page_sv = event;
      this.arrNumberPage2_child = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage2) {
          this.arrNumberPage2_child.push(i);
        }
      }
    }
    this.dataSV2 = this.dataSV.filter(
      (x) =>
        this.pageSize2 * (this.page_sv - 1) < x.STT &&
        x.STT <= this.pageSize2 * this.page_sv
    );

    this.spinner.hide();
  }
  SpliceCheckRow() {
    for (let i = 0; i < this.DataViPham.length; i++) {
      if (this.DataViPham[i].checked == true) {
        const check = this.dataSV.some(
          (el) => el.ID_sv === this.DataViPham[i].ID_sv
        );
        if (!check) {
          this.dataSV.push(this.DataViPham[i]);
        }
        this.DataViPham.splice(i, 1);
        i--;
      }
    }
  } // Xóa row check
  SpliceCheckRow1(DataViPham) {
      if (this.DataViPham.checked == true) {
        const check = this.dataSV.some(
          (el) => el.ID_sv === this.DataViPham.ID_sv
        );
        if (!check) {
          this.dataSV.push(this.DataViPham);
        }
        this.DataViPham.splice();
        DataViPham --;
      }
  } // Xóa row check

  PushCheckRow() {
    for (let i = 0; i < this.dataSV.length; i++) {
      if (this.dataSV[i].checked == true) {
        const check = this.DataViPham.some((el) => {
          return el.ID_sv == this.dataSV[i].ID_sv;
        });
        if (!check) {
          this.DataViPham.push(this.dataSV[i]);
        }
        this.dataSV.splice(i, 1);
        i--;
      }
    }
  } // thêm row check
  next() {
    if (this.DataViPham.length == 0) {
      this.toastr.warning('Chọn sinh viên !');
      return false;
    }
    this.Display = true;
  }

  prev() {
    this.Display = false;
  }

  resetform() {
    this.DataViPham = [];
    this.Display = false;
  }
  checkbox(event) {
    this.DataViPham.push(event);
  }
  open(content, sizm, category, Data) {
    if (category == 'DeleteM') {
      this.numberChecked = this.dataTable.filter((x) => x.checked == true);
      if (this.numberChecked.length == 0) {
        this.toastr.warning('Bạn chưa chọn sinh viên!');
        return false;
      }
    }
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.dataPopup = Data;
  }
  openModal(content, sizm, category, Data) {
    this.dataSV2 = [];
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    if (category == 'Insert') {
      this.dataPopup = Data;
      this.page_sv = 1;
      this.getHoSoSV();
      this.titleModal = 'Thêm vi phạm';
      this.isInsert = true;
    } else if (category == 'DuyetDK') {
      this.dataPhongByID = this.dataTypeRoom.filter(
        (x) => x.ID == Data.ID_vi_pham
      );
      // this.DuyetDKRow = {
      //   ID_vi_pham: Data.ID_vi_pham,
      //   Ma_sv: Data.ID_sv,
      //   Ten_sv: Data.ID_sv,
      //   Ngay_sinh: Data.Ngay_sinh,
      //   Hoc_ky: Data.Hoc_ky,
      //   Nam_hoc: Data.Nam_hoc,
      //   Diem_tru: Data.Diem_tru,
      //   Noi_dung: Data.Noi_dung,
      //   Ngay_vi_pham: Data.Ngay_vi_pham,
      //   Duyet_diem: Data.Duyet_diem,
      // };
    }
    if (category == 'Update') {
      this.titleModal = 'Sửa vi phạm';
      this.UpdateForm.setValue({
        ID_vi_pham: Data.ID_vi_pham,
        ID_sv: Data.ID_sv,
        Ma_sv: Data.Ma_sv,
        Ten_sv: Data.Ten_sv,
        Ngay_sinh: Data.Ngay_sinh,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc.trim(),
        Diem_tru: Data.Diem_tru,
        Noi_dung: Data.Noi_dung,
        Ngay_vi_pham: Data.Ngay_vi_pham.substr(0, 10),
        Duyet_diem: Data.Duyet_diem,
      });
    }
    if (category == 'Delete') {
      this.ID_vi_pham = Data.ID_vi_pham;
      this.data_Delete=Data;
    }
  }
  changPage(event) {
    this.handlePageChange(event);
    //this.spinner.show();
    this.DataView = this.DataFilter.filter(
      (x) =>
        this.pageSize * (this.page - 1) < x.STT &&
        x.STT <= this.pageSize * this.page
    );
    // this.arrNumberPage_chil = this.createNumberPage(
    //   this.DataFilter.length,
    //   this.pageSize
    // ).arrNumberPage_chil;
  }
  handlePageSizeChange2(event) {
    this.page_sv = 1;
    if (event.target.value == 'Tất cả') {
      this.pageSize2 = 100000;
    } else {
      this.pageSize2 = event.target.value;
    }
    this.numberPage2 = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).numberPage;
    this.arrNumberPage2_child = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).arrNumberPage_chil;
    this.changepage2(1);
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    //this.GetCount();
    this.GetAll();
  }
  get checkvalue() {
    return this.InsertForm.controls;
  }
  Delete() {
    let req = {
      ID_vi_pham: this.ID_vi_pham,
      ID_sv: this.data_Delete.ID_sv,
      Hoc_ky:this.data_Delete.Hoc_ky,
      Nam_hoc: this.data_Delete.Nam_hoc,
    };
    this.modalService.dismissAll();
    this.ViPhamService.Delete(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
     
    }, 
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    }
    );
  }
  Insert() {
    this.submitted = true;
    if (this.InsertForm.invalid) {
      this.toastr.warning('Vui lòng nhập đủ các thông tin cần thiết');
      return false;
    }
    if (this.DataViPham.length == 0) {
      this.toastr.warning('Bạn chưa chọn sinh viên!');
      return false;
    }
    let listsv = [];
    let listsv1 = this.DataViPham.filter((x) => x.checked == true);

    for (var i = 0; i < listsv1.length; i++) {
      let req = {
        ID_sv: listsv1[i].ID_sv,
        Ngay_sinh: this.InsertForm.value.Ngay_sinh,
        Hoc_ky: this.InsertForm.value.Hoc_ky,
        Nam_hoc: this.InsertForm.value.Nam_hoc,
        Diem_tru: this.InsertForm.value.Diem_tru,
        Noi_dung: this.InsertForm.value.Noi_dung,
        Ngay_vi_pham: this.InsertForm.value.Ngay_vi_pham,
        Duyet_diem: 0,
      };
      listsv.push(req);
    }
    if (this.isInsert == true) {
      this.spinner.show()
      this.ViPhamService.Insert(listsv, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.GetAll();
          this.GetCount(this.req);
        } else {
          this.toastr.error(z.Message);
        }
        this.spinner.hide()        
      }, (err) => {
        this.spinner.hide(); 
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
      });
      this.modalService.dismissAll();
    }
  }
  update() {
    let req = {
      ID_vi_pham: this.UpdateForm.value.ID_vi_pham,
      ID_sv: this.UpdateForm.value.ID_sv,
      Ma_sv: this.UpdateForm.value.Ma_sv,
      Ten_sv: this.UpdateForm.value.Ten_sv,
      Hoc_ky: this.UpdateForm.value.Hoc_ky,
      Nam_hoc: this.UpdateForm.value.Nam_hoc.trim(),
      Diem_tru: this.UpdateForm.value.Diem_tru,
      Noi_dung: this.UpdateForm.value.Noi_dung,
      Ngay_vi_pham: this.UpdateForm.value.Ngay_vi_pham,
      Duyet_diem: this.UpdateForm.value.Duyet_diem,
    };
    this.ViPhamService.Update(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    }
    );

    this.modalService.dismissAll();
  }
  filterModal() {
    this.dataSV = this.beforeFilter;
    if (this.Ma_sv != '') {
      let a = this.dataSV;
      let b = this.dataSV;
      if (a != []) {
        a = this.dataSV.filter((i) => i.Ma_sv.includes(this.Ma_sv.trim()));
      }
      if (b != []) {
        b = this.dataSV.filter((i) =>
          this.xoa_dau(i.Ho_ten.toLowerCase()).includes(
            this.xoa_dau(this.Ma_sv.trim().toLowerCase())
          )
        );
      }
      this.dataSV = [];
      if (a.length != 0) {
        a.forEach((i) => {
          this.dataSV.push(i);
        });
      }
      if (b.length != 0) {
        b.forEach((i) => {
          this.dataSV.push(i);
        });
      }
    }
    if (
      this.Ngay_sinh != undefined &&
      this.Ngay_sinh != null &&
      this.Ngay_sinh != ''
    ) {
      this.dataSV = this.dataSV.filter(
        (x) => x.Ngay_sinh.toString().substring(0, 10) == this.Ngay_sinh
      );
    }
    for (var j = 0; j < this.dataSV.length; j++) {
      this.dataSV[j].STT = j + 1;
    }
    this.totalRecord2 = this.dataSV.length;
    this.numberPage2 = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).numberPage;
    this.arrNumberPage2_child = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).arrNumberPage_chil;
    this.changepage2(1);
  }
  Filter() {
    this.DataFilter = this.dataTable;
    if (this.FilterForm.value.Ma_sv_ft != '' && this.FilterForm.value.Ma_sv_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => (x.Ma_sv.trim()).toLowerCase()  == (this.FilterForm.value.Ma_sv_ft.trim()).toLowerCase());
    }
    if (this.FilterForm.value.Ten_sv_ft != '' && this.FilterForm.value.Ten_sv_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => (x.Ten_sv.trim()).toLowerCase()== (this.FilterForm.value.Ten_sv_ft.trim()).toLowerCase());
    }
    if ( this.FilterForm.value.Hoc_ky_ft != '' &&this.FilterForm.value.Hoc_ky_ft != undefined ) {
      this.DataFilter = this.DataFilter.filter(
        (x) => x.Hoc_ky.toString() == this.FilterForm.value.Hoc_ky_ft
      );
    }
    if (
      this.FilterForm.value.Nam_hoc_ft != '' &&
      this.FilterForm.value.Nam_hoc_ft != undefined
    ) {
      this.DataFilter = this.DataFilter.filter(
        (x) => x.Nam_hoc.toString().trim() == this.FilterForm.value.Nam_hoc_ft
      );
    }
    if (this.FilterForm.value.Tu_ngay_ft != '' && this.FilterForm.value.Tu_ngay_ft != undefined) {
      if (this.FilterForm.value.Den_ngay_ft != '' && this.FilterForm.value.Den_ngay_ft != undefined) {
        if (this.FilterForm.value.Den_ngay_ft < this.FilterForm.value.Tu_ngay_ft) {
          this.toastr.warning('Từ ngày không lớn hơn đến ngày!');
        } else {         
          this.DataFilter = this.DataFilter.filter((x) => x.Ngay_vi_pham == null ? "" : x.Ngay_vi_pham.substr(0, 10) >= this.FilterForm.value.Tu_ngay_ft && x.Ngay_vi_pham == null ? "" : x.Ngay_vi_pham.substr(0, 10) <= this.FilterForm.value.Den_ngay_ft);
        }
      }
    }   
    this.page = 1;
    for (var i = 0; i < this.DataFilter.length; i++) {
      this.DataFilter[i].STT = i + 1;
    }
    this.totalItemFilter = this.DataFilter.length;
    this.DataView = this.DataFilter.filter(
      (x) =>
        x.STT > (this.page - 1) * this.pageSize &&
        x.STT <= this.page * this.pageSize
    );
    this.numberPage = this.createNumberPage(
      this.totalItemFilter,
      this.pageSize
    ).numberPage;
    this.arrNumberPage_chil = this.createNumberPage(
      this.totalItemFilter,
      this.pageSize
    ).arrNumberPage_chil;
  }
  updateStatus_ViPham() {
   let dataRequest = this.DataView.filter((x) => x.checked == true);
    this.spinner.show();
    this.ViPhamService.UpdateStatus(dataRequest, this.Token).subscribe(
      (z) => {
        if (z.Status == 5) {
          this.toastr.error('Xảy ra lỗi trong quá trình duyệt điểm!');
        } else if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 7) {
          this.toastr.warning(z.Message);
        } else {
          this.toastr.success(z.Message);
        }
        this.modalService.dismissAll('DuyetDK');
        this.GetAll();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  unUpdate_Status_ViPham() {

    this.spinner.show();
    if (this.Duyet_diem == undefined) this.HuyDuyetRow.Duyet_diem = '';
    else this.HuyDuyetRow.Duyet_diem = this.Duyet_diem;
    this.ViPhamService.UnUpdateStatus(this.HuyDuyetRow, this.Token).subscribe(
      (z) => {
        if (z.Status == 5) {
          this.toastr.error(
            'Xảy ra lỗi trong quá trình hủy duyệt điểm sinh viên.!'
          );
        } else if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 7) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        }
        this.modalService.dismissAll('HuyDuyet');
        this.GetAll();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  GetStringID(m) {
    var rs = '';
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_vi_pham;
      } else {
        rs += ',' + m[k].ID_vi_pham;
      }
    }
    return rs;
  }

  DuyetDiem_ListID() {
    this.spinner.show();
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('DuyetDKList');
      this.toastr.error('Bạn vui lòng tích chọn sinh viên để duyệt điểm.!');
      this.spinner.hide();
    } else {
   //   var length = dt.length;
     // var dtstring = this.GetStringID(dt);
      let req = [];
      for (var i = 0; i < dt.length; i++) {
        req.push({ 
          ID_vi_pham: dt[i].ID_vi_pham,
          ID_sv: dt[i].ID_sv,
          Hoc_ky:dt[i].Hoc_ky,
          Nam_hoc:dt[i].Nam_hoc
        });
      }
      this.ViPhamService.UpdateStatus(req, this.Token).subscribe(
        (z) => {
          if (z.Status == 5) {
            this.toastr.error('Xảy ra lỗi trong duyệt điểm.!');
          } else if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 7) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
          }else if(z.Status==0)
          {
            this.toastr.warning(z.Message);
          }
          this.modalService.dismissAll('DuyetDKList');
          this.GetAll();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  HuyDuyetDiem_ListID() {
    this.spinner.show();
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('DuyetDKList');
      this.toastr.error('Bạn vui lòng tích chọn sinh viên để duyệt điểm.!');
      this.spinner.hide();
    } else {
      var length = dt.length;
      let req = [];
      for (var i = 0; i < length; i++) {
        req.push({ 
          ID_vi_pham: dt[i].ID_vi_pham,
          ID_sv: dt[i].ID_sv,
          Hoc_ky:dt[i].Hoc_ky,
          Nam_hoc:dt[i].Nam_hoc
         });
      }
      this.ViPhamService.UnUpdateStatus(req, this.Token).subscribe(
        (z) => {
          if (z.Status == 5) {
            this.toastr.error('Xảy ra lỗi trong huỷ duyệt điểm.!');
          } else if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 7) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
          }
          this.modalService.dismissAll('DuyetDKList');
          this.GetAll();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  getListViPham() {
    this.ViPhamService.GetAll(this.Token).subscribe((z) => {
      this.dataTypeVipham = z.Data;
    });
  }
  save() {
    this.submitted = true;
    if (this.InsertForm.invalid) {
      return false;
    }
    this.spinner.show();
    let arr = [];
    let dataChecked = this.DataViPham.filter((x) => x.checked == true);
    for (var i = 0; i < dataChecked.length; i++) {
      let req = {
        ID_sv: dataChecked[i].ID_sv,
        Ngay_sinh: this.InsertForm.value.Ngay_sinh,
        Hoc_ky: this.InsertForm.value.Hoc_ky,
        Nam_hoc: this.InsertForm.value.Nam_hoc,
        Diem_tru: this.InsertForm.value.Diem_tru,
        Noi_dung: this.InsertForm.value.Noi_dung,
        Ngay_vi_pham: this.InsertForm.value.Ngay_vi_pham,
        Duyet_diem: 0,
      };
      arr.push(req);
    }
    this.ViPhamService.Insert(arr, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.GetCount(this.req);
          this.GetAll();
        } else {
          this.toastr.error(z.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
    this.modalService.dismissAll();
    this.spinner.hide();
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.ViPhamService.Excel(a.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'Sinh viên vi phạm');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      });
    }
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl + "?" + this.appConfig.document_QuanLyViPham;
    window.open(urlHelp, '_blank');
  }
}

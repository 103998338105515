<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Phân quyền tài khoản</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-primary btn-sm"
          (click)="getList()"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button
          class="btn btn-success btn-sm"
          style="float: right; margin-right: 10px;"
          (click)="open(ThemModal, 'xl', 'Add', '')"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label>Họ và tên</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="FullName"
            placeholder="Nhập họ và tên"
          />
        </div>
        <div class="col-md-2">
          <label>Tên tài khoản</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="UserName"
            placeholder="Nhập tên đăng nhập"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>

          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Tên tài khoản</th>
                  <th>Họ tên</th>
                  <th>Email</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                  <td>{{ p.UserName }}</td>
                  <td>{{ p.FullName }}</td>
                  <td>{{ p.Email }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="openEdit(EditModal, 'xl', 'Add', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa quyền"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="openEdit(DeleteModal, '', 'Delete', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-trash-alt" title="Xoá quyền"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataTable.length == 0">
                  <td colspan="4" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Thêm quyền người dùng</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>Tài khoản</label>
        <ng-select [(ngModel)]="UserID">
          <ng-option *ngFor="let p of dataUser" [value]="p.UserID"
            >{{ p.UserName }} ({{ p.FullName }})</ng-option
          >
        </ng-select>
      </div>
    </div>
    <div class="modal-body">
      <div
        class="table-responsive"
        style="max-height: 50vh; margin-bottom: 15px;"
      >
        <div class="row">
          <div class="col-md-6">
            <table class="table table-bordered" id="tableSV">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      [checked]="isAllCheckBoxChecked2()"
                      (change)="checkAllCheckBox2($event)"
                    />
                  </th>
                  <th>Tên cơ sở</th>
                  <th>Mã tòa nhà</th>
                  <th>Tên tòa nhà</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let p of dataBuilding; let i = index; let odd = odd"
                >
                  <td *ngIf="p.checked != true" class="text-center">
                    <input
                      type="checkbox"
                      value="{{ p.ID_ToaNha }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataBuilding[i].checked"
                    />
                  </td>
                  <td *ngIf="p.checked != true">{{ p.Ten_CoSo }}</td>
                  <td *ngIf="p.checked != true">{{ p.Ma_ToaNha }}</td>
                  <td *ngIf="p.checked != true" style="white-space: nowrap;">
                    {{ p.Ten_ToaNha }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-bordered" id="tableSV">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      *ngIf="isAllCheckBoxChecked2()"
                      [checked]="isAllCheckBoxChecked2()"
                      (change)="checkAllCheckBox2($event)"
                    />
                  </th>
                  <th>Tên cơ sở</th>
                  <th>Mã tòa nhà</th>
                  <th>Tên tòa nhà</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let p of dataBuilding; let i = index; let odd = odd"
                >
                  <td *ngIf="p.checked == true" class="text-center">
                    <input
                      type="checkbox"
                      value="{{ p.ID_ToaNha }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataBuilding[i].checked"
                    />
                  </td>
                  <td *ngIf="p.checked == true">{{ p.Ten_CoSo }}</td>
                  <td *ngIf="p.checked == true">{{ p.Ma_ToaNha }}</td>
                  <td *ngIf="p.checked == true" style="white-space: nowrap;">
                    {{ p.Ten_ToaNha }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="OnSubmit()"
        >
          <i class="fas fa-save"></i> Lưu
        </button>
        <button
          style="margin-left: 1em;"
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #EditModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Sửa quyền người dùng</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>Tài khoản</label>
        <select class="form-control" [(ngModel)]="UserID" disabled>
          <option *ngFor="let p of dataUser" value="{{ p.UserID }}"
            >{{ p.UserName }} ({{ p.FullName }})</option
          >
        </select>
      </div>
    </div>
    <div class="modal-body">
      <div
        class="table-responsive"
        style="max-height: 50vh; margin-bottom: 15px;"
      >
        <div class="row">
          <div class="col-md-6">
            <table class="table table-bordered" id="tableSV">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      [checked]="isAllCheckBoxChecked2()"
                      (change)="checkAllCheckBox2($event)"
                    />
                  </th>
                  <th>Tên cơ sở</th>
                  <th>Mã tòa nhà</th>
                  <th>Tên tòa nhà</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let p of dataBuilding; let i = index; let odd = odd"
                >
                  <td *ngIf="p.checked != true" class="text-center">
                    <input
                      type="checkbox"
                      value="{{ p.ID_ToaNha }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataBuilding[i].checked"
                    />
                  </td>
                  <td *ngIf="p.checked != true">{{ p.Ten_CoSo }}</td>
                  <td *ngIf="p.checked != true">{{ p.Ma_ToaNha }}</td>
                  <td *ngIf="p.checked != true" style="white-space: nowrap;">
                    {{ p.Ten_ToaNha }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-bordered" id="tableSV">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      *ngIf="isAllCheckBoxChecked2()"
                      [checked]="isAllCheckBoxChecked2()"
                      (change)="checkAllCheckBox2($event)"
                    />
                  </th>
                  <th>Tên cơ sở</th>
                  <th>Mã tòa nhà</th>
                  <th>Tên tòa nhà</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let p of dataBuilding; let i = index; let odd = odd"
                >
                  <td *ngIf="p.checked == true" class="text-center">
                    <input
                      type="checkbox"
                      value="{{ p.ID_ToaNha }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataBuilding[i].checked"
                    />
                  </td>
                  <td *ngIf="p.checked == true">{{ p.Ten_CoSo }}</td>
                  <td *ngIf="p.checked == true">{{ p.Ma_ToaNha }}</td>
                  <td *ngIf="p.checked == true" style="white-space: nowrap;">
                    {{ p.Ten_ToaNha }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button type="button" class="btn btn-md btn-success" (click)="update()">
          <i class="fas fa-save"></i> Lưu
        </button>
        <button
          style="margin-left: 1em;"
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ListpeopledormService } from 'src/app/utils/services/listpeopledorm.service';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-receipt-room',
  templateUrl: './receipt-room.component.html',
  styleUrls: ['./receipt-room.component.scss'],
})
export class ReceiptRoomComponent implements OnInit {
  @Input() public dt_print: any;
  @Input() typeReceipt: any;
  // tmlPrintEW:TemplateRef<any>;
  studyClass: any;
  date: any = new Date();
  arr_number: any = [
    'không',
    'một',
    'hai',
    'ba',
    'bốn',
    'năm',
    'sáu',
    'bảy',
    'tám',
    'chín',
  ];
  money_words: any;
  dt_payment: any = [];
  dt_money: any = 0;
  Token: any;
  public com: common;
  dataTable: any;
  constructor(
    private ListAllService: ListpeopledormService,
    public router: Router
  ) {}

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dt_print.ID_dang_ky != undefined) {
      this.getList(this.dt_print.ID_dang_ky);
    }
  }

  getList(id_dang_ky) {
    //   this.dataTable =[ {
    //     Noi_dung:'Nop tien phong',
    //     So_tien_thuc_nop:100000
    //   },
    //   {
    //     Noi_dung:'Nop tien Cuoc',
    //     So_tien_thuc_nop:1000523
    //   },
    // ];
    this.ListAllService.getListReceipt(id_dang_ky, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        if (this.dataTable) {
          this.dataTable.forEach((element) => {
            this.dt_money += element.So_tien_thuc_nop;
          });
          this.money_words = this.DocTienBangChu(this.dt_money);
        }
      }
    );
  }

  DocTienBangChu(so) {
    if (so == 0) return this.arr_number[0];
    let chuoi = '',
      hauto = '';
    do {
      let ty = so % 1000000000;
      so = Math.floor(so / 1000000000);
      if (so > 0) {
        chuoi = this.dochangtrieu(ty, true) + hauto + chuoi;
      } else {
        chuoi = this.dochangtrieu(ty, false) + hauto + chuoi;
      }
      hauto = ' tỷ';
    } while (so > 0);
    return chuoi.charAt(1).toUpperCase() + chuoi.slice(2);
  } //đọc tiền

  dochangchuc(so, daydu) {
    let chuoi = '';
    let chuc = Math.floor(so / 10);
    let donvi = so % 10;
    if (chuc > 1) {
      chuoi = ' ' + this.arr_number[chuc] + ' mươi';
      if (donvi == 1) {
        chuoi += ' mốt';
      }
    } else if (chuc == 1) {
      chuoi = ' mười';
      if (donvi == 1) {
        chuoi += ' một';
      }
    } else if (daydu && donvi > 0) {
      chuoi = ' lẻ';
    }

    if (donvi == 5 && chuc >= 1) {
      chuoi += ' lăm';
    } else if (donvi > 1 || (donvi == 1 && chuc == 0)) {
      chuoi += ' ' + this.arr_number[donvi];
    }
    return chuoi;
  }

  docblock(so, daydu) {
    let chuoi = '';
    let tram = Math.floor(so / 100);
    so = so % 100;
    if (daydu || tram > 0) {
      chuoi = ' ' + this.arr_number[tram] + ' trăm';
      chuoi += this.dochangchuc(so, true);
    } else {
      chuoi = this.dochangchuc(so, false);
    }
    return chuoi;
  }

  dochangtrieu(so, daydu) {
    let chuoi = '';
    let trieu = Math.floor(so / 1000000);
    so = so % 1000000;
    if (trieu > 0) {
      chuoi = this.docblock(trieu, daydu) + ' triệu';
      daydu = true;
    }
    let nghin = Math.floor(so / 1000);
    so = so % 1000;
    if (nghin > 0) {
      chuoi += this.docblock(nghin, daydu) + ' nghìn';
      daydu = true;
    }
    if (so > 0) {
      chuoi += this.docblock(so, daydu);
    }
    return chuoi;
  }
}

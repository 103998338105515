<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quản trị Email</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <label>Email gửi</label>
          <input
            type="text"
            placeholder="Tìm kiếm theo tên Email"
            class="form-control"
            [(ngModel)]="Email_gui"
            (change)="getList()"
          />
        </div>
        <div class="col-md-3">
          <label>Email nhận</label>
          <input
            type="text"
            placeholder="Tìm kiếm theo tên Email"
            class="form-control"
            [(ngModel)]="Email_nhan"
            (change)="getList()"
          />
        </div>
        <div class="col-md-3">
          <label>Năm</label>
          <input
            type="number"
            class="form-control"
            [(ngModel)]="Nam"
            (change)="getList()"
          />
        </div>
        <div class="col-md-3">
          <label>Mã sinh viên</label>
          <input
            type="text"
            class="form-control"
            placeholder="Tìm kiếm theo tên Mã sv"
            [(ngModel)]="Ma_sv"
            (change)="getList()"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th>Email gửi</th>
                <th>Email nhận</th>
                <th>Tiêu đề</th>
                <th>Mã sinh viên</th>
                <th>Họ tên</th>
                <th>Ngày gửi</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable">
                  <td>{{ p.Email_gui }}</td>
                  <td>{{ p.Email_sv }}</td>
                  <td>{{ p.Tieu_de_email }}</td>
                  <td>{{ p.Ma_sv }}</td>
                  <td>{{ p.Ho_ten }}</td>
                  <td>{{ p.CreateDate | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="open(DetailModal, 'xl', 'xl', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="open(ResendModal, '', '', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-envelope"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="5" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #DetailModal let-modal>
  <div [innerHtml]="detail"></div>
</ng-template>
<ng-template #ResendModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-envelope"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn gửi lại email
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="reSend()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

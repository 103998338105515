
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body"  style="padding:0.5%  !important">
    <div class="card">
        <div class="card-body" style="padding: 0.5rem ;">
            <div class="__title-register col-md-12">
                LỊCH SỬ ĐĂNG KÝ KTX {{current_year}} 
            </div>
                <div class="container col-md-12" style="margin: 15px auto" class="__serachAll">
                    <input type="text" class="form-control" placeholder="Tìm kiếm nhanh" [(ngModel)]="searchString">
                </div>
                <div class="tab-content">
                  <div class="active tab-pane" >
                      <div class="row">
                        <div class="col-md-12">
                <div class=" table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs ">
                  <table  class="table-bordered table-hover dataTables_wrapper no-footer" style="width: 100%;">
                    <thead >
                      <tr>
                        <th>STT</th>
                        <th>Nội dung</th>
                        <th>Thời gian</th>
                        <th>Người nhận</th>
                        <th>Người gửi</th>
                        <th>Thao tác</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let email of dataLichSuEmail | filter: searchString;let i=index">
                        <th (click)="open(EmailDetailModal, 'lg', 'Detail', email)" style="text-align: center;" scope="row">{{i+1}}</th>
                        <td style="word-wrap: break-word;max-width: 480px;text-align: left;" (click)="open(EmailDetailModal, 'lg', 'Detail', email)">{{ email.Tieu_de_email }}</td>
                        <td (click)="open(EmailDetailModal, 'lg', 'Detail', email)" style="text-align: center;white-space: nowrap;">{{ email.CreateDate | date:'dd-MM-yyyy hh:mm a' }}</td>
                        <td (click)="open(EmailDetailModal, 'lg', 'Detail', email)" style="text-align: center;">{{ email.Email_sv }}</td>
                        <td (click)="open(EmailDetailModal, 'lg', 'Detail', email)" style="text-align: center;">{{ email.Ten_nguoi_gui | uppercase}}</td>
                        <td style="text-align: center;">
                          <a [routerLink]="['/dang-ky']" ngbTooltip="Xem" class=" btn btn-sm btn-info" (click)="open(EmailDetailModal, 'lg', 'Detail', email)">
                            <i class="fas fa-search"></i>
                          </a>
                          <!-- &nbsp;
                          &nbsp;
                          <i class="far fa-clock" ngbTooltip="{{email.Time_email}}"></i> -->
                        </td>
                      </tr>     
                      <tr *ngIf="!dataLichSuEmail">
                        <td colspan="6" class="text-center">Rất tiếc bạn chưa nhận được bất kỳ email nào từ ban quản lý KTX</td>
                      </tr>                            
                    </tbody>
                  </table>
                </div>
              </div>
                </div>
              </div>
            </div>
            <!----------------------------------------------- @MEDIA ------------------------------------------------->
            <div class="col-sm-12" class="__contentMedia" style="display: none;">
              <div class="row">
                <hr class="__hrMedia">
                <div class="col-sm-12" *ngFor="let email of dataLichSuEmail" (click)="open(EmailDetailModal, 'xs', 'Detail', email)">
                      <i class="fas fa-eye"></i>
                    &nbsp;
                    <label style="margin-top: 10px;">{{ email.Ten_nguoi_gui | uppercase}}</label>                                  
                    <label style="font-weight: bold;font-size: 11px; float: right;">{{ email.CreateDate | date:'dd-MM-yyyy h:mm a' }}</label>
                    <div class="col-md-12">
                      <div [innerHTML]="email.Tieu_de_email"></div>                                      
                    </div>
                  <hr class="__hrMedia">
                </div>                            
              </div>
            </div>
          </div>
      </div>
</section>

<!-----------------------------------------------EMAIL DETAIL MODAL ------------------------------------------------->
<ng-template #EmailDetailModal let-modal>
  <div class="modal-content">
    <div class="modal-header m-header text-center"style="padding-top: 0px; padding-bottom: 0px;">
      <h4 style="font-weight: bold;font-size: 20px;margin: 15px auto;" class="modal-title h-title w-100">{{ titleModal }}</h4>
      <span
        (click)="modal.dismiss('Cross click')"
        aria-hidden="true"
        style="font-size: 25px;"
        >&times;</span
      >
    </div>
    <div class="modal-body" style="overflow-x: auto;">
      <div class="__bodyEmailWebApp" [innerHTML]="dataNoiDungEmail"></div>
      <div class="__bodyEmailMeida" style="display: none;" [innerHTML]="dataNoiDungEmail"></div>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </div>
</ng-template>
import { Injectable, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NumberWaterService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }
  Count( token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
        'NumberWater/Count',
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'NumberWater/CountFilter',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  GetPagination(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'NumberWater/RoomSoNuoc',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getKHoanThu(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/KhoanThu', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getThongKeKHoanThu(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/ThongKeKhoanThu', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/NumberWater_Insert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/NumberWater_Update', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MoChot(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/MoChot', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  PhanBo(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/PhanBo', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListFill(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberWater/NumberWater_MonthYear_Load?month=' + req.month + '&year=' + req.year ,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  RP_Manager(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/NumberWater_RP_Manager', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  RP_Personal(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/NumberWater_RP_Personal', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiUpdate(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/NumberWater_MultiUpdate', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiChot(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/MultiChot', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiMoChot(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/MultiMoChot', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiPhanBo(req,type, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/MultiPhanBo?type='+type, req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListStatus0(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberWater/NumberWater_MonthYear_Load_Status_0?month=' + req.month + '&year=' + req.year ,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListStatus1(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberWater/NumberWater_MonthYear_Load_Status_1?month=' + req.month + '&year=' + req.year+'&ID_building='+req.ID_building ,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListStatus01(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberWater/NumberWater_MonthYear_Load_Status_0_1?month=' + req.month + '&year=' + req.year+'&ID_building='+req.ID_building ,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ExcelSoNuoc(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberWater/ExcelSoNuoc',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DangKyQuaTrinhServiceService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  TongHop(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + '/DangKyQuaTrinh/TongHop', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  TongHop_Count(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + '/DangKyQuaTrinh/TongHop_Count',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Detail(ID_dang_ky, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          '/DangKyQuaTrinh/DangKyQuaTrinh_Detail?ID_dang_ky=' +
          ID_dang_ky,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

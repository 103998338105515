<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Loại đề xuất</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm float-right"
          (click)="open(InsertModal, 'xs', 'Add', '')"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.Ten_loai_de_xuat }}</td>
                      <td *ngIf="p.Trang_thai == true">Mở</td>
                      <td *ngIf="p.Trang_thai == false">Đóng</td>
                      <td>{{ p.Thu_tu }}</td>
                      <td>
                        <button
                          class="btn btn-sm btn-warning"
                          style="margin-right: 7px;"
                          (click)="open(InsertModal, 'xs', 'Edit', p)"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          style="margin-right: 7px;"
                          (click)="open(DeleteModal, 'xs', 'Delete', p)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #InsertModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Tên loại đề xuất</label>
          <input
            class="form-control"
            required
            placeholder="Nhập tên loại đề xuất"
            type="text"
            formControlName="Ten_loai_de_xuat"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ten_loai_de_xuat.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ten_loai_de_xuat.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ten_loai_de_xuat.errors.required">
              Tên loại đề xuất không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Ten_loai_de_xuat.errors.maxlength">
              Tên loại đề xuất tối đa 200 ký tự!
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Thứ tự</label>
          <input
            class="form-control"
            required
            placeholder="Nhập thứ tự"
            type="number"
            formControlName="Thu_tu"
          />
          <div
            *ngIf="submitted && checkvalue.Thu_tu.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Thu_tu.errors.required">
              Thứ tự không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <label class="col-md-10 require">Trạng thái</label>
          <label class="switch">
            <input
              style="margin-left: 17px;"
              class="form-control"
              type="checkbox"
              formControlName="Trang_thai"
              checked
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

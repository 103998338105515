<div *ngIf="isShowContent">
  <section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-6">
          <!-- <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                      <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Xếp phòng</li>
                  </ol> -->
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-success btn-sm"
            (click)="navigate()"
            style="float: right;"
          >
            <i class="fas fa-users-cog"></i> Xếp phòng
          </button>
          <button
            class="btn btn-success btn-sm"
            (click)="getList()"
            style="float: right; margin-right: 10px; background-color: #4c6ef5;"
          >
            <i class="fas fa-search"></i>Tìm kiếm
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="content cus-body cus-fs">
    <div class="card">
      <div class="card-body" style="padding: 10px !important;">
        <form class="form-horizontal" [formGroup]="Fillter">
          <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">
              <select
                class="form-control cus-fs"
                formControlName="He_filter"
                (change)="filterLop()"
              >
                <option value="0" selected>Hệ</option>
                <option *ngFor="let p of DSHe" [value]="p.ID_he">{{
                  p.Ten_he
                }}</option>
              </select>
            </div>
            <div class="col-md-3">
              <select
                class="form-control cus-fs"
                formControlName="Khoa_filter"
                (change)="filterLop()"
              >
                <option value="0" selected>Khoa</option>
                <option *ngFor="let p of DSKhoa" [value]="p.ID_khoa">{{
                  p.Ten_khoa
                }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <input
                type="text"
                class="form-control cus-fs"
                formControlName="Khoa_hoc_filter"
                placeholder="Nhập khóa học..."
                (keyup.enter)="filterLop()"
              />
            </div>
            <div class="col-md-3">
              <select
                class="form-control cus-fs"
                formControlName="Chuyen_nganh_filter"
                (change)="filterLop()"
              >
                <option value="0" selected>Chuyên ngành</option>
                <option
                  *ngFor="let p of DSChuyenNganh"
                  [value]="p.ID_chuyen_nganh"
                  >{{ p.Chuyen_nganh }}</option
                >
              </select>
            </div>
            <div class="col-md-2">
              <select
                class="form-control cus-fs"
                formControlName="lop_filter"
                (change)="getList()"
              >
                <option value="0" selected>Lớp</option>
                <option *ngFor="let p of DSLopFilter" [value]="p.ID_lop">{{
                  p.Ten_lop
                }}</option>
              </select>
            </div>
            <!-- <div class="col-md-3">
                    <select class="form-control cus-fs" formControlName="Khu_vuc_filter">
                      <option value="" selected> Chọn khu vực</option>
                      <option *ngFor="let p of DSKhuVuc" [value]="p.Ten_kv">{{
                        p.Ten_kv
                      }}</option>
                    </select>
                  </div> -->
          </div>
          <div class="row" style="margin-top: 10px;">
            <div class="col-md-4">
              <input
                type="text"
                (keyup.enter)="getList()"
                formControlName="Ma_sv_filter"
                class="form-control cus-fs"
                placeholder="Nhập mã sinh viên"
              />
            </div>
            <div class="col-md-3">
              <select
                class="form-control cus-fs"
                formControlName="Gioi_tinh_filter"
                (change)="getList()"
              >
                <option value="0" selected>Nam</option>
                <option value="1">Nữ</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section class="content cus-body cus-fs">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <label
              >Tìm kiếm sinh viên ( Đã chọn:
              {{ dataTable2.length + '/' + dtPhong.Con_trong }})</label
            >
            <div class="tab-content">
              <div
                class="table-responsive"
                style="max-height: 60vh; margin-bottom: 15px; font-weight: 100;"
              >
                <table class="table table-sm table-striped">
                  <thead>
                    <th>
                      <input
                        type="checkbox"
                        [checked]="isAllCheckBoxChecked()"
                        (change)="checkAllCheckBox($event)"
                        style="width: 1rem; height: 1rem;"
                        (change)="PushCheckRow()"
                      />
                    </th>
                    <th>Mã sinh viên</th>
                    <th>Họ và tên</th>
                    <th>Giới tính</th>
                    <th>Hệ</th>
                    <th>Khoa</th>
                    <th>Khoá-Lớp</th>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let p of dataTable; let i = index; let odd = odd"
                    >
                      <td>
                        <input
                          type="checkbox"
                          value="{{ p.ID_dang_ky }}"
                          [checked]="p.checked"
                          (change)="PushCheckRow()"
                          [(ngModel)]="dataTable[i].checked"
                          style="width: 1rem; height: 1rem;"
                        />
                      </td>

                      <td>{{ p.Ma_sv }}</td>
                      <td class="_w-s-nowrap">{{ p.Ho_ten }}</td>
                      <td>{{ p.Gioi_tinh }}</td>
                      <td style="white-space: nowrap;">{{ p.Ten_he }}</td>
                      <td>{{ p.Ten_khoa }}</td>
                      <td>K{{ p.Khoa_hoc + '-' + p.Ten_lop }}</td>
                    </tr>
                    <tr *ngIf="dataTable.length == 0">
                      <td colspan="14" class="text-center">Không có kết quả</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p style="float: left; font-weight: bold;">
                    <!-- Tổng số {{ totalItem | number }} kết quả, đã lọc được
                          {{ totalItemFilter | number }} kết quả -->
                  </p>
                </div>
                <div class="col-md-12">
                  <nav
                    aria-label="Page navigation example"
                    style="float: right;"
                  >
                    <ul class="pagination">
                      <li class="page-item" [class.disabled]="page == 1">
                        <a class="page-link" (click)="changPage(1)">Đầu</a>
                      </li>
                      <li class="page-item" [class.disabled]="page == 1">
                        <a class="page-link" (click)="changPage('pre')"
                          >Trước</a
                        >
                      </li>
                      <ng-container *ngIf="page > 4">
                        <li class="page-item"><a class="page-link">...</a></li>
                      </ng-container>
                      <ng-container *ngFor="let element of arrNumberPage_chil">
                        <li class="page-item" [class.active]="page == element">
                          <a class="page-link" (click)="changPage(element)">{{
                            element
                          }}</a>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="page < numberPage - 3">
                        <li class="page-item"><a class="page-link">...</a></li>
                      </ng-container>
                      <li
                        class="page-item"
                        [class.disabled]="page == numberPage"
                      >
                        <a class="page-link" (click)="changPage('next')">Sau</a>
                      </li>
                      <li
                        class="page-item"
                        [class.disabled]="page == numberPage"
                      >
                        <a class="page-link" (click)="changPage(numberPage)"
                          >Cuối</a
                        >
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label>Danh sách đã chọn</label>
            <div class="tab-content">
              <div
                class="table-responsive"
                style="max-height: 60vh; margin-bottom: 15px; font-weight: 100;"
              >
                <table class="table table-bordered table-sm table-striped">
                  <thead>
                    <th>
                      <input
                        type="checkbox"
                        [checked]="isAllCheckBoxChecked2()"
                        (change)="checkAllCheckBox2($event)"
                        style="width: 1rem; height: 1rem;"
                        (change)="SpliceCheckRow()"
                      />
                    </th>
                    <th>Mã sinh viên</th>
                    <th>Họ và tên</th>
                    <th>Giới tính</th>
                    <th>Hệ</th>
                    <th>Khoa</th>
                    <th>Khoá-Lớp</th>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let p of dataTable2; let i = index; let odd = odd"
                    >
                      <td>
                        <input
                          type="checkbox"
                          value="{{ p.ID_dang_ky }}"
                          [checked]="p.checked"
                          [(ngModel)]="dataTable2[i].checked"
                          style="width: 1rem; height: 1rem;"
                          (change)="SpliceCheckRow()"
                        />
                      </td>
                      <td>{{ p.Ma_sv }}</td>
                      <td class="_w-s-nowrap">{{ p.Ho_ten }}</td>
                      <td>{{ p.Gioi_tinh }}</td>
                      <td style="white-space: nowrap;">{{ p.Ten_he }}</td>
                      <td>{{ p.Ten_khoa }}</td>
                      <td>K{{ p.Khoa_hoc + '-' + p.Ten_lop }}</td>
                    </tr>
                    <tr *ngIf="dataTable2.length == 0">
                      <td colspan="14" class="text-center">Không có kết quả</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div *ngIf="!isShowContent">
  <div class="row">
    <div
      class="col-md-12"
      style="display: flex; justify-content: center; margin-top: 50px;"
    >
      <label style="color: brown;"
        >Chức năng này đang không được phép sử dụng, vui lòng liên hệ với quản
        trị viên để được hỗ trợ</label
      >
    </div>
  </div>
</div>

<ng-template #SortRoomModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Xếp phòng</h4>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="ActionSave">
      <div class="cus-box">
        <div class="row">
          <div class="col-md-12">
            <label>&nbsp; Số tháng ở thực tế :</label>
            <input type="text" value="true" readonly formControlName="ThangO" />
          </div>
        </div>
      </div>
      <div class="cus-box" style="margin-top: 10px;">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="col-md-10">Ngày vào</label>
            <input
              type="date"
              class="form-control"
              formControlName="Ngay_vao"
              (change)="GetMonth()"
            />
          </div>
          <div class="col-md-12">
            <label class="col-md-10">Ngày kết thúc</label>
            <input
              type="date"
              class="form-control"
              formControlName="Ngay_ra"
              (change)="GetMonth()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onSubmit()"
        (keyup.enter)="onSubmit()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class KhenThuongKyLuatService {
  constructor(@Inject(AppConfig) private readonly appConfig: AppConfiguration, private router: Router, private http: HttpClient) { }

  GetAll(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/ListAll', {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Insert(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/Add',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  } 
  Update(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/Update',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Count(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/Count',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Count_Filter(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/CountFilter',req, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Delete(req:any,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/Delete?ID='+req.ID, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }).pipe(map(z => { return z }))
  }
  Excel(token): Observable<any> {
    return this.http
        .get<any>(this.appConfig.UniDormAPI + 'KhenThuongKyLuat/Excel', {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }).pipe(map((z) => {return z;}));
  }
}

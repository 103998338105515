import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { StudentSortService } from 'src/app/utils/services/student-sort.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CampusService } from 'src/app/utils/services/campus.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { RoomService } from 'src/app/utils/services/room.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { RoomcategoryService } from 'src/app/utils/services/room-category.service';
import { BaseComponent } from '../base/base.component';
@Component({
  selector: 'app-students-sort',
  templateUrl: './students-sort.component.html',
  styleUrls: ['./students-sort.component.scss'],
})
export class StudentsSortComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  dtOptions: any;
  datadetail: any;
  closeResult: string;
  Token: string;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  DSPhong: any;
  DSPhong2: any;
  DSHe: any;
  DSKhoa: any;
  DSChuyenNganh: any;
  DSKhuVuc: any;
  DSDoiTuong: any;
  DSLoaiPhong: any;
  submitted = false;
  dataTable: any = [];
  dataMain: any = [];
  SelectData: any = [];
  code_filter: any;
  name_filter: any;
  NguyenVong_filter: any;
  NgaySinh_filter: any;
  Nhap_hoc_filter: any;
  Loai_phong_filter: any;
  Thanh_toan_filter: any;
  Doi_tuong_chinh_sach_filter: any;
  He_filter: any;
  Khoa_filter: any;
  Khoa_hoc_filter: any;
  Chuyen_nganh_filter: any;
  Khu_vuc_filter: any;
  khoa_filter: any;
  lop_filter: any;
  StudentData: any = [];
  lstDanToc: any = [];
  lstTinh: any = [];
  doituong_filter: any;
  SelectNum: any;
  Fillter: FormGroup;
  totalItem: any;
  totalItemFilter: any;
  Insert = new FormGroup({
    ID_Phong: new FormControl(null, [Validators.required]),
    ID_co_so: new FormControl(null, [Validators.required]),
    ID_toa_nha: new FormControl(null, [Validators.required]),
  });
  Nam_hoc_arr: any = [];
  public com: common;
  ngOnInit(): void {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    $('#datatable').DataTable().clear();
    $('#datatable').DataTable().destroy();
    $.fn['dataTable'].ext.search = [];
    this.Fillter = new FormGroup({
      code_filter: new FormControl(null),
      name_filter: new FormControl(null),
      khoa_filter: new FormControl(null),
      lop_filter: new FormControl(null),
      doituong_filter: new FormControl(null),
      NguyenVong_filter: new FormControl(null),
      NgaySinh_filter: new FormControl(null),
      Loai_phong_filter: new FormControl(''),
      Thanh_toan_filter: new FormControl(''),
      Gioi_tinh_filter: new FormControl(1),
      Doi_tuong_chinh_sach_filter: new FormControl(''),
      He_filter: new FormControl(''),
      Khoa_filter: new FormControl(''),
      Khoa_hoc_filter: new FormControl(null),
      Chuyen_nganh_filter: new FormControl(''),
      Khu_vuc_filter: new FormControl(''),
      Nhap_hoc_filter: new FormControl(''),
      Ma_sv_filter: new FormControl(''),
      Ho_ten_filter: new FormControl(''),
      Hoc_ky_filter: new FormControl('1'),
      Nam_hoc_filter: new FormControl('2023-2024'),
      ID_tinh_tt_filter: new FormControl('0'),
      ID_dan_toc_filter: new FormControl('0'),
      Dienthoai_canhan_filter: new FormControl(null),
      Mail_filter: new FormControl(null),
      Ton_giao_filter: new FormControl(null),
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;

    this.getList();
    this.getCount();
    this.getDanToc();
    this.getTinh();
    this.CampusService.getList(a.Token).subscribe((z) => {
      this.DSCoSo = z.Data;
      this.BuildingService.getList(a.Token).subscribe((z) => {
        this.DSToaNha = z.Data;
        this.DSToaNha2 = z.Data;
        this.RoomService.GetAll(this.Token).subscribe((z) => {
          for (var i = 0; i < z.Data.length; i++) {
            if (z.Data[i].Loai_phong == false) {
              z.Data[i].Loai_phong_text = 'Nam';
            } else {
              z.Data[i].Loai_phong_text = 'Nữ';
            }
          }
          this.Service.List_He(a.Token).subscribe((z) => {
            this.DSHe = z;
          });
          this.Service.List_Khoa(a.Token).subscribe((z) => {
            this.DSKhoa = z;
          });
          this.Service.List_ChuyenNganh(a.Token).subscribe((z) => {
            this.DSChuyenNganh = z;
          });
          this.SystemService.Get_khuvuc_tuyensinh(a.Token).subscribe((z) => {
            this.DSKhuVuc = z;
          });
          this.SystemService.Get_doituong_chinhsach(a.Token).subscribe((z) => {
            this.DSDoiTuong = z;
          });
          this.RoomCateGoryService.getList(true, a.Token).subscribe((z) => {
            this.DSLoaiPhong = z.Data;
          });
          this.DSPhong = z.Data;
          this.DSPhong2 = z.Data;
        });
      });
    });
  }
  ngAfterViewInit() {}
  getCount() {
    this.Service.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }

  getDanToc() {
    this.SystemService.Get_danh_sach_dan_toc(this.Token).subscribe((z) => {
      this.lstDanToc = z;
    });
  }

  getTinh() {
    this.SystemService.Get_tinh(this.Token).subscribe((z) => {
      this.lstTinh = z;
    });
  }

  cosochange() {
    let ID_coso = $('#ID_co_so').val();
    this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
      return pa.ID_CoSo == ID_coso;
    });
    let idtn = this.DSToaNha2.length == 0 ? 0 : this.DSToaNha2[0].ID_ToaNha;
    // this.Insert.patchValue({
    //   ID_toa_nha: idtn
    // });
  }
  ToaNhaChange() {
    let ID_toanha = $('#ID_toa_nha').val();
    this.DSPhong2 = jQuery.grep(this.DSPhong, function (pa: any, i) {
      return pa.ID_Toa_Nha == ID_toanha;
    });
    // let idphong = this.DSPhong2.length == 0 ? 0 : this.DSPhong2[0].ID_Phong;
    // this.Insert.patchValue({
    //   ID_Phong: idphong
    // });
  }
  getRequest() {
    let req = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Nhap_hoc: this.Fillter.value.Nhap_hoc_filter,
      Thanh_toan: this.Fillter.value.Thanh_toan_filter,
      Gioi_tinh: this.Fillter.value.Gioi_tinh_filter == 2 ? 'Nữ' : 'Nam',
      Ten_loai_phong: this.Fillter.value.Loai_phong_filter,
      Nguyen_vong:
        this.Fillter.value.NguyenVong_filter == null
          ? ''
          : this.Fillter.value.NguyenVong_filter,
      Doi_tuong_chinh_sach: this.Fillter.value.Doi_tuong_chinh_sach_filter,
      Ngay_sinh:
        this.Fillter.value.NgaySinh_filter == null
          ? ''
          : this.Fillter.value.NgaySinh_filter,
      Ten_he: this.Fillter.value.He_filter,
      Khoa: this.Fillter.value.Khoa_filter,
      Khoa_hoc:
        this.Fillter.value.Khoa_hoc_filter == null
          ? ''
          : this.Fillter.value.Khoa_hoc_filter,
      Chuyen_nganh: this.Fillter.value.Chuyen_nganh_filter,
      Doi_tuong_khu_vuc: this.Fillter.value.Khu_vuc_filter,
      Ma_sv: this.Fillter.value.Ma_sv_filter,
      Ho_ten: this.Fillter.value.Ho_ten_filter,
      Hoc_ky: this.Fillter.value.Hoc_ky_filter,
      Nam_hoc: this.Fillter.value.Nam_hoc_filter,
      ID_tinh_tt: this.Fillter.value.ID_tinh_tt_filter,
      Dienthoai_canhan: this.Fillter.value.Dienthoai_canhan_filter,
      Mail: this.Fillter.value.Mail_filter,
      ID_dan_toc: this.Fillter.value.ID_dan_toc_filter,
      Ton_giao: this.Fillter.value.Ton_giao_filter,
    };
    return req;
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    let req = this.getRequest();
    this.Service.ListAll(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.dataTable = z.Data;
          this.Service.getCountFilter(req, this.Token).subscribe((z) => {
            this.spinner.hide();
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
          });
        } else {
          this.toastr.warning(z.Message, 'Tác vụ thất bại');
        }
      },
      (err) => {
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  open(content, size, Loai, Data) {
    let dtft = this.dataTable.filter((x) => x.checked == true);
    this.SelectNum = dtft.length;
    if (this.SelectNum > 0) {
      this.StudentData = dtft;
      this.modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title', size: size })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else {
      this.toastr.warning('Bạn chưa chọn đối tượng xếp phòng');
    }
    // this.ID_co_so=this.DSCoSo[0].ID;
    // this.ID_toa_nha=this.DSToaNha[0].ID_ToaNha;
    let ID_coso = this.DSCoSo[0].ID;
    this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
      return pa.ID_CoSo == ID_coso;
    });
    let idtn = this.DSToaNha2.length == 0 ? 0 : this.DSToaNha2[0].ID_ToaNha;
    this.Insert.patchValue({
      ID_co_so: this.DSCoSo[0].ID,
      ID_toa_nha: idtn,
    });
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    let dtft = this.dataTable.filter((x) => x.checked == true);
    if (this.SelectNum > 0) {
      let InputDatas = {
        IDPhong: this.Insert.value.ID_Phong,
        IDToaNha: this.Insert.value.ID_toa_nha,
        IDCoSo: this.Insert.value.ID_co_so,
        Students: dtft,
      };
      // this.Service.Sort(this.Token, InputDatas).subscribe((z) => {
      //   this.modalService.dismissAll('ThemModal');
      //   if (z.Status == 1) {
      //     this.toastr.success(z.Message, 'Xếp phòng thành công');
      //   } else {
      //     this.toastr.warning(z.Message, 'Tác vụ thất bại');
      //   }
      //   this.getList();
      // });
    } else {
      this.toastr.warning('Bạn chưa chọn danh sách đối tượng xếp phòng');
    }
  }
  navigate(): void {
    let dtft = this.dataTable.filter((x) => x.checked == true);
    this.StudentData = dtft;
    if (this.StudentData.length > 0) {
      this.router.navigate(['/admin/sort-detail'], { state: this.StudentData });
    } else {
      this.toastr.warning('Vui lòng chọn sinh viên cần xếp phòng');
    }

    // this.router.navigate(['/sort-detail', this.StudentData]);
  }
  ngOnDestroy() {
    // $('#datatable').DataTable().clear();
    // $('#datatable').DataTable().destroy();
    // $.fn['dataTable'].ext.search = []
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    // });
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let req = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Nhap_hoc: this.Fillter.value.Nhap_hoc_filter,
      Thanh_toan: this.Fillter.value.Thanh_toan_filter,
      Gioi_tinh: this.Fillter.value.Gioi_tinh_filter == 2 ? 'Nữ' : 'Nam',
      Ten_loai_phong: this.Fillter.value.Loai_phong_filter,
      Nguyen_vong:
        this.Fillter.value.NguyenVong_filter == null
          ? ''
          : this.Fillter.value.NguyenVong_filter,
      Doi_tuong_chinh_sach: this.Fillter.value.Doi_tuong_chinh_sach_filter,
      Ngay_sinh:
        this.Fillter.value.NgaySinh_filter == null
          ? ''
          : this.Fillter.value.NgaySinh_filter,
      Ten_he: this.Fillter.value.He_filter,
      Khoa: this.Fillter.value.Khoa_filter,
      Khoa_hoc:
        this.Fillter.value.Khoa_hoc_filter == null
          ? ''
          : this.Fillter.value.Khoa_hoc_filter,
      Chuyen_nganh: this.Fillter.value.Chuyen_nganh_filter,
      Doi_tuong_khu_vuc: this.Fillter.value.Khu_vuc_filter,
      Ma_sv: this.Fillter.value.Ma_sv_filter,
      Ho_ten: this.Fillter.value.Ho_ten_filter,
    };
    this.spinner.show();
    this.Service.ListAll(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.dataTable = z.Data;
          this.Service.getCountFilter(req, this.Token).subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.spinner.hide();
          });
        } else {
          this.toastr.warning(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_XepPhong;
    window.open(urlHelp, '_blank');
  }

  ExportExcel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      let req = this.getRequest();
      this.spinner.show();
      this.Service.ExportDanhSachXepPhongTiet(this.Token, req).subscribe(
        (z) => {
          if (z.Status == 1) {
            this.exportService.exportExcelByte(
              z.FileData,
              'DanhSachXepPhongChiTiet'
            );
            this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, 'Tác vụ thất bại');
            this.spinner.hide();
          }
        }
      );
    }
  }
}

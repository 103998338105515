<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quản lý loại phòng</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'xl', 'Add', '')"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section
  class="content cus-body"
  style="font-family: sans-serif; font-size: 14px;"
>
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Filter">
        <div class="row">
          <div class="col-md-3">
            <label style="margin-left: 10px;">Mã loại phòng</label>
            <input
              type="text"
              class="form-control"
              formControlName="Ma_loai_phong_ft"
              (keyup)="filterById()"
              placeholder="Tìm kiếm mã loại phòng..."
            />
          </div>
          <div class="col-md-3">
            <label style="margin-left: 10px;">Tên loại phòng</label>
            <input
              type="text"
              class="form-control"
              formControlName="Ten_loai_phong_ft"
              (keyup)="filterById()"
              placeholder="Tìm kiếm tên phòng..."
            />
          </div>
          <div class="col-md-3">
            <label style="margin-left: 10px;">Giá phòng</label>
            <input
              type="text"
              class="form-control"
              formControlName="Gia_phong_ft"
              (keyup)="filterById()"
              placeholder="Tìm kiếm giá phòng..."
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.ID }}</td>
                      <td>{{ p.Ma_loai_phong }}</td>
                      <td>{{ p.Ten_loai_phong }}</td>
                      <td>{{ p.Gia_phong | number }}</td>
                      <td>{{ p.Tien_cuoc | number }}</td>
                      <td>{{ p.Suc_chua }}</td>
                      <td>{{ p.Dang_o }}</td>
                      <td>{{ p.Da_dang_ky }}</td>
                      <td>{{ p.Con_trong }}</td>
                      <td style="white-space: nowrap;">
                        <button
                          class="btn btn-success btn-sm"
                          (click)="open(ViewModal, 'xl', 'ViewMota', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-eye"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'xl', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-edit" mo="Sửa loại phòng"></i>
                        </button>
                        <button
                          class="btn btn-warning btn-sm"
                          title="Thông tin tiện ích"
                          (click)="open(ViewExtensions, 'xl', 'Extensions', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fa fa-bath"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i
                            class="fas fa-trash-alt"
                            title="Xoá loại phòng"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal style="font-family: sans-serif;">
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body" style="margin-top: 5px; padding-top: 5px;">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-10 require">Mã loại phòng </label>
          <input
            class="form-control"
            required
            placeholder="Nhập mã loại phòng"
            type="text"
            formControlName="Ma_loai_phong"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ma_loai_phong.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ma_loai_phong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ma_loai_phong.errors.required">
              Mã loại phòng không được bỏ trống
            </div>
            <div *ngIf="checkvalue.Ma_loai_phong.errors.maxlength">
              Mã loại phòng bị tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="col-md-10 require">Tên loại phòng </label>
          <input
            class="form-control"
            required
            placeholder="Tên loại phòng"
            type="text"
            formControlName="Ten_loai_phong"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ten_loai_phong.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ten_loai_phong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ten_loai_phong.errors.required">
              Tên loại phòng không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Ten_loai_phong.errors.maxlength">
              Tên loại phòng bị tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="col-md-10 require">Giá phòng </label>
          <input
            class="form-control"
            required
            placeholder="Giá phòng"
            type="number"
            formControlName="Gia_phong"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Gia_phong.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Gia_phong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Gia_phong.errors.required">
              Giá phòng không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Gia_phong.errors.maxlength">
              Giá phòng bị tối đa 200 ký tự!
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <label class="col-md-10 require">Tiền cược </label>
          <input
            class="form-control"
            required
            placeholder="Tiền cược"
            type="number"
            formControlName="Tien_cuoc"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Tien_cuoc.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Tien_cuoc.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Tien_cuoc.errors.required">
              Tiền cược không được bỏ trống!
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <label class="col-md-10 require">Mô tả </label>
          <ckeditor
            [editor]="Editor"
            data="{{ dataMoTa }}"
            (change)="onChange($event)"
          ></ckeditor>
          <!-- <input
            class="form-control"
            placeholder="Mô tả"
            type="text"
            formControlName="Mo_ta"
          /> -->
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #ViewModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Mô tả loại phòng</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div [innerHTML]="Mo_ta"></div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ViewExtensions let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Thông tin tiện ích</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-success btn-sm"
          (click)="open(AddExtensions, 'xl', 'AddExtensions', '')"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm tiện ích
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th>
                Mã tiện ích
              </th>
              <th>
                Tên tiện ích
              </th>
              <th>
                Đơn giá
              </th>
              <th>
                Mô tả
              </th>
              <th>
                Thao tác
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of ExtensionsRoom">
              <td>{{ p.Ma_tien_ich }}</td>
              <td>{{ p.Ten_tien_ich }}</td>
              <td>{{ p.Don_gia | number }}</td>
              <td>{{ p.Mo_ta }}</td>
              <td style="white-space: nowrap;">
                <button
                  class="btn btn-sm btn-danger"
                  (click)="DeleteExtension(p.ID_tien_ich)"
                  style="margin-right: 7px;"
                >
                  <i class="fas fa-trash-alt" title="Xoá tiện tích"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddExtensions let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Thêm tiện ích vào Loại phòng</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
        <tr>
          <th>
            Mã tiện ích
          </th>
          <th>
            Tên tiện ích
          </th>
          <th>
            Đơn giá
          </th>
          <th>
            Mô tả
          </th>
          <th>
            Thao tác
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of ExtensionsNotInRoom">
          <td>{{ p.Ma_tien_ich }}</td>
          <td>{{ p.Ten_tien_ich }}</td>
          <td><input type="number" [(ngModel)]="p.Don_gia" /></td>
          <td>{{ p.Mo_ta }}</td>
          <td style="white-space: nowrap;">
            <button
              class="btn btn-sm btn-success"
              style="margin-right: 7px;"
              (click)="InsertExtension(p.ID_tien_ich, p.Don_gia)"
            >
              <i class="fas fa-plus-circle" title="Thêm tiện ích"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

import { Component, OnInit, ViewChild } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { BaseComponent } from '../base/base.component';
import { AnyMxRecord } from 'dns';
import { debug } from 'console';

@Component({
  selector: 'app-register-dorm',
  templateUrl: './register-dorm.component.html',
  styleUrls: ['./register-dorm.component.scss'],
})
export class RegisterDormComponent extends BaseComponent implements OnInit {
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  dataPhongKTX: any = [];
  dataDanToc: any = [];
  dataLoaiPhong: any = [];
  dataQuocTich: any = [];
  SelectData: any = [];
  dataDoiTuongChinhSach: any = [];
  dataKhuVucTuyenSinh: any = [];
  ClassData: any;
  ID_dang_ky: any;
  ID_doi_tuong: any;
  code: any;
  Ghi_chu: any;
  Ngay_cap_the: any;
  Ngay_bat_dau: any;
  Ngay_ket_thuc: any;
  La_truong_phong: any;
  Ten_trang_thai_o: any;
  Ten_trang_thai_the: any;
  Ten_trang_thai_duyet: any;
  Trang_thai_duyet: any;
  Ma_sv: any;
  Mo_ta: any;
  Ho_ten: any;
  Ngay_sinh: any;
  ID_gioi_tinh: any;
  Ten_gioi_tinh: any;
  id_he_nhap_hoc: any;
  lstDanToc: any = [];
  lstTinh: any = [];
  He_nhap_hoc: any;
  ID_quoc_tich: any;
  Ten_quoc_tich: any;
  ID_dan_toc: any;
  Ten_dan_toc: any;
  CMND: any;
  Ngay_cap_CMND: any;
  ID_noi_cap_CMND: any;
  Ton_giao: any;
  So_ho_chieu: any;
  Ngay_het_han_visa: any;
  Dia_chi_tt: any;
  ID_khoa_nhap_hoc: any;
  Ten_khoa_nhap_hoc: any;
  Khoa_hoc_nhap_hoc: any;
  ID_chuyen_nganh_nhap_hoc: any;
  Ten_chuyen_nganh_nhap_hoc: any;
  ID_nganh_nhap_hoc: any;
  ID_loai_phong: any;
  Ten_nganh_nhap_hoc: any;
  Lop: any;
  Dienthoai_canhan: any;
  Email: any;
  Ho_ten_cha: any;
  Namsinh_cha: any;
  Hoat_dong_XH_CT_cha: any;
  Ho_ten_me: any;
  Namsinh_me: any;
  Hoat_dong_XH_CT_me: any;
  NoiO_hiennay: any;
  So_dien_thoai_bo: any;
  So_dien_thoai_me: any;
  titleModal: string;
  dataStudent: any;
  dataStudent_Search: any;
  ID_sv: any;
  ID_kv: any;
  ID_dt: any;
  ID_doi_tuong_TS: any;
  ID_khu_vuc_TS: any;
  Hoc_ky: any;
  Ly_do: any;
  Nam_hoc: any;
  default: any;
  ckicked: any;
  title = 'read-excel-in-angular8';
  storeData: any;
  csvData: any;
  jsonData: any;
  textData: any;
  htmlData: any;
  fileUploaded: File;
  worksheet: any;
  dataPhongByID: any;
  Ma_loai_phong: any;
  Con_trong_lp: any;
  Dang_o_lp: any;
  Gia_loai_phong: any;
  Mo_ta_lp: any;
  Suc_chua_lp: any;
  Ten_loai_phong: any;
  Gia_phong: any;
  Nam_hoc_arr: any = [];
  uploadedFile(event) {
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  getNull = false;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải cơ sở...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  InputDatas: any;
  HuyDuyetRow: any;
  TuChoiRow: any;
  DuyetDKRow: any;
  InputDatas_Update: any;
  InputDatas_Student: any;
  Insert = new FormGroup({
    ID_doi_tuong: new FormControl(1, [Validators.required]),
    ID_dang_ky: new FormControl(0, []),
    code: new FormControl(null, []),
    ID_phong: new FormControl(null, []),
    Trang_thai_the: new FormControl(0, []),
    Trang_thai_o: new FormControl(0, []),
    Trang_thai_duyet: new FormControl(0, []),
    So_the: new FormControl(null, [Validators.required]),
    Ngay_cap_the: new FormControl(null, []),
    Ghi_chu: new FormControl(null, []),
    La_truong_phong: new FormControl(null, []),
    ID_sv: new FormControl(0, []),
    Ma_sv: new FormControl(null, []),
    Ho_ten: new FormControl(null, []),
    Ngay_sinh: new FormControl(null, []),
    ID_gioi_tinh: new FormControl(2, []),
    Ten_gioi_tinh: new FormControl(null, []),
    id_he_nhap_hoc: new FormControl(0, []),
    He_nhap_hoc: new FormControl(null, []),
    ID_quoc_tich: new FormControl(0, []),
    Ten_quoc_tich: new FormControl(null, []),
    ID_dan_toc: new FormControl(0, []),
    Ten_dan_toc: new FormControl(null, []),
    CMND: new FormControl(null, []),
    Ngay_cap_CMND: new FormControl(null, []),
    ID_noi_cap_CMND: new FormControl(0, []),
    Ton_giao: new FormControl(null, []),
    So_ho_chieu: new FormControl(null, []),
    Ngay_het_han_visa: new FormControl(null, []),
    Dia_chi_tt: new FormControl(null, []),
    ID_khoa_nhap_hoc: new FormControl(0, []),
    Ten_khoa_nhap_hoc: new FormControl(null, []),
    Khoa_hoc_nhap_hoc: new FormControl(0, []),
    ID_chuyen_nganh_nhap_hoc: new FormControl(0, []),
    Ten_chuyen_nganh_nhap_hoc: new FormControl(null, []),
    ID_nganh_nhap_hoc: new FormControl(0, []),
    Ten_nganh_nhap_hoc: new FormControl(null, []),
    Lop: new FormControl(null, []),
    Dienthoai_canhan: new FormControl(null, []),
    Email: new FormControl(null, []),
    Ho_ten_cha: new FormControl(null, []),
    Namsinh_cha: new FormControl(null, []),
    Hoat_dong_XH_CT_cha: new FormControl(null, []),
    Ho_ten_me: new FormControl(null, []),
    Namsinh_me: new FormControl(null, []),
    Hoat_dong_XH_CT_me: new FormControl(null, []),
    NoiO_hiennay: new FormControl(null, []),
    So_dien_thoai_bo: new FormControl(null, []),
    So_dien_thoai_me: new FormControl(null, []),
    Tinh_trang_suc_khoe: new FormControl(null, []),
    Nguyen_vong: new FormControl(null, []),
    ID_kv: new FormControl(0, []),
    ID_dt: new FormControl(0, []),
    ID_doi_tuong_TS: new FormControl(0, []),
    ID_khu_vuc_TS: new FormControl(0, []),
    Hoc_ky: new FormControl(null, []),
    Nam_hoc: new FormControl(null, []),
    Loai_phong: new FormControl(0, []),
    ID_lop: new FormControl(0, []),
  });
  FillMaSV: any;
  FillHoTen: any;
  FillLoaiPhong: any = '';
  FillTrangThaiDangKy: any = 0;
  FillTrangThaiO: any = '';
  FillNgaySinh: any;
  dataTypeRoom: any;
  dataTypeRoomGioiTinh: any;
  dataHe: any;
  dataKhoa: any;
  FillDoiTuong: any = '';
  FillTuNgay: any;
  FillDenNgay: any;
  FillGioiTinh: any = '';
  FillHe: any = '';
  FillKhoa: any = '';
  FillKhoaHoc: any;
  public com: common;
  Fillter = new FormGroup({
    Hoc_ky_filter: new FormControl('1'),
    ID_tinh_tt_filter: new FormControl('0'),
    ID_dan_toc_filter: new FormControl('0'),
    Dienthoai_canhan_filter: new FormControl(null),
    Mail_filter: new FormControl(null),
    Ton_giao_filter: new FormControl(null),
  });
  ngOnInit(): void {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    var getMonth = new Date().getMonth() + 1;
    if (getMonth >= 8) {
      this.Nam_hoc =
        new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
    } else {
      this.Nam_hoc =
        new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.getListSVTuDongHuyDK();
    this.GetDanToc();
    this.getTinh();
    this.GetQuocTich();
    this.GetDoiTuongChinhSach();
    this.GetKhuVucTuyenSinh();
    this.getHeNhapHoc();
    this.getKhoaNhapHoc();
    this.getListTypeRoom();

    this.FillTuNgay = new Date().toISOString().substring(0, 10);
    this.FillDenNgay = new Date().toISOString().substring(0, 10);
  }

  getCount() {
    this.RegisterDormService.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }

  getListFirst() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let param = this.getRequest();
    this.RegisterDormService.getListAll(param, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.getCount();
        this.RegisterDormService.getCountFilter(param, this.Token).subscribe(
          (z) => {
            this.spinner.hide();
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
          }
        );
      },
      (err) => {
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }

  getRequest() {
    let req = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ma_sv: this.FillMaSV,
      Ho_ten: this.FillHoTen,
      Ngay_sinh: this.FillNgaySinh,
      ID_loai_phong: this.FillLoaiPhong,
      Trang_thai_duyet: this.FillTrangThaiDangKy,
      Ten_he: this.FillHe,
      Ten_khoa: this.FillKhoa,
      Khoa_hoc: this.FillKhoaHoc,
      ID_doi_tuong_TS: this.FillDoiTuong,
      ID_gioi_tinh: this.FillGioiTinh,
      Nam_hoc: this.Nam_hoc,
      Trang_thai_o: this.FillTrangThaiO,
      Hoc_ky: this.Fillter.value.Hoc_ky_filter,
      ID_tinh_tt: this.Fillter.value.ID_tinh_tt_filter,
      Dienthoai_canhan: this.Fillter.value.Dienthoai_canhan_filter,
      Mail: this.Fillter.value.Mail_filter,
      ID_dan_toc: this.Fillter.value.ID_dan_toc_filter,
      Ton_giao: this.Fillter.value.Ton_giao_filter,
    };
    return req;
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];

    let param = this.getRequest();
    this.RegisterDormService.getListAll(param, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.RegisterDormService.getCountFilter(param, this.Token).subscribe(
          (z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          }
        );
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  refesh() {
    this.FillMaSV = '';
    this.FillHoTen = '';
    this.FillNgaySinh = '';
    this.FillLoaiPhong = '';
    this.FillTrangThaiDangKy = '';
    this.FillHe = '';
    this.FillKhoa = '';
    this.FillKhoaHoc = '';
    this.FillDoiTuong = '';
    this.FillGioiTinh = '';
    this.FillTrangThaiO = '';
    this.Fillter.patchValue({
      Hoc_ky_filter: '1',
      ID_tinh_tt_filter: '0',
      ID_dan_toc_filter: '0',
      Dienthoai_canhan_filter: null,
      Mail_filter: null,
      Ton_giao_filter: null,
    });

    this.getList();
  }

  getTinh() {
    this.SystemService.Get_tinh(this.Token).subscribe((z) => {
      this.lstTinh = z;
    });
  }

  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let param = this.getRequest();
    this.RegisterDormService.getListAll(param, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.spinner.hide();
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  getListSVTuDongHuyDK() {
    this.spinner.show();
    this.RegisterDormService.getListSVQuaHanDongTien(this.Token).subscribe(
      (z) => {
        this.getListFirst();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.getListTypeRoomGioiTinh(Data.ID_gioi_tinh, Data.ID_sv);
      this.titleModal = 'Sửa đăng ký';
      this.checkInsert = true;
      this.check = Data.code;
      this.Trang_thai_duyet = Data.Trang_thai_duyet;
      this.Insert.patchValue({
        ID_dang_ky: Data.ID_dang_ky,
        ID_sv: Data.ID_sv,
        Ma_sv: Data.Ma_sv,
        Ho_ten: Data.Ho_ten,
        Ngay_sinh:
          Data.Ngay_sinh == null ? '' : Data.Ngay_sinh.substring(0, 10),
        ID_gioi_tinh: Data.ID_gioi_tinh,
        CMND: Data.CMND,
        Ngay_cap_CMND:
          Data.Ngay_cap_CMND == null ? '' : Data.Ngay_cap_CMND.substring(0, 10),
        ID_noi_cap_CMND: Data.ID_noi_cap_CMND,
        Ton_giao: Data.Ton_giao,
        So_ho_chieu: Data.So_ho_chieu,
        Ngay_het_han_visa:
          Data.Ngay_het_han_visa == null
            ? ''
            : Data.Ngay_het_han_visa.substring(0, 10),
        Dienthoai_canhan: Data.Dienthoai_canhan,
        Email: Data.Email,
        Dia_chi_tt: Data.Dia_chi_tt,
        ID_dan_toc: Data.ID_dan_toc,
        ID_quoc_tich: Data.ID_quoc_tich,
        Ho_ten_cha: Data.Ho_ten_cha,
        Namsinh_cha: Data.Namsinh_cha,
        Hoat_dong_XH_CT_cha: Data.Hoat_dong_XH_CT_cha,
        So_dien_thoai_bo: Data.So_dien_thoai_bo,
        Ho_ten_me: Data.Ho_ten_me,
        Namsinh_me: Data.Namsinh_me,
        Hoat_dong_XH_CT_me: Data.Hoat_dong_XH_CT_me,
        So_dien_thoai_me: Data.So_dien_thoai_me,
        NoiO_hiennay: Data.NoiO_hiennay,
        He_nhap_hoc: Data.He_nhap_hoc,
        Ten_khoa_nhap_hoc: Data.Ten_khoa_nhap_hoc,
        Khoa_hoc_nhap_hoc: Data.Khoa_hoc_nhap_hoc,
        Ten_nganh_nhap_hoc: Data.Ten_nganh_nhap_hoc,
        Ten_chuyen_nganh_nhap_hoc: Data.Ten_chuyen_nganh_nhap_hoc,
        Lop: Data.Lop,
        So_the: Data.So_the,
        Ngay_cap_the:
          Data.Ngay_cap_the == null ? '' : Data.Ngay_cap_the.substring(0, 10),
        Ghi_chu: Data.Ghi_chu,
        ID_dt: Data.ID_doi_tuong_TS,
        ID_kv: Data.ID_khu_vuc_TS,
        Loai_phong: Data.ID_loai_phong,
        Tinh_trang_suc_khoe: Data.Tinh_trang_suc_khoe,
        Nguyen_vong: Data.Nguyen_vong,
        ID_doi_tuong: Data.ID_doi_tuong,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc,
        ID_lop: Data.ID_lop,
      });
    } else if (Loai == 'Add') {
      this.titleModal = 'Đăng ký ký túc xá cho sinh viên';
      this.checkInsert = false;
      this.dataTypeRoomGioiTinh = this.dataTypeRoom;
      this.GetAll();
    } else if (Loai == 'Delete') {
      this.ID_dang_ky = Data.ID_dang_ky;
      this.Trang_thai_duyet = Data.Trang_thai_duyet;
    } else if (Loai == 'View') {
      this.dataPhongByID = this.dataTypeRoom.filter(
        (x) => x.ID == this.Insert.value.Loai_phong
      );
      this.Ma_loai_phong = this.dataPhongByID[0].Ma_loai_phong;
      this.Con_trong_lp = this.dataPhongByID[0].Con_trong;
      this.Dang_o_lp = this.dataPhongByID[0].Dang_o;
      this.Gia_loai_phong = this.dataPhongByID[0].Gia_phong;
      this.Mo_ta_lp = this.dataPhongByID[0].Mo_ta;
      this.Suc_chua_lp = this.dataPhongByID[0].Suc_chua;
      this.Ten_loai_phong = this.dataPhongByID[0].Ten_loai_phong;
    } else if (Loai == 'huyDuyet') {
      this.dataPhongByID = this.dataTypeRoom.filter(
        (x) => x.ID == Data.ID_loai_phong
      );
      this.HuyDuyetRow = {
        ID_dang_ky: Data.ID_dang_ky,
        ID_sv: Data.ID_sv,
        Ho_ten: Data.Ho_ten,
        Trang_thai_duyet: Data.Trang_thai_duyet,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc,
        Trang_thai_o: Data.Trang_thai_o,
        Ma_loai_phong: this.dataPhongByID[0].Ma_loai_phong,
        Ten_loai_phong: this.dataPhongByID[0].Ten_loai_phong,
        Email: Data.Email,
      };
    } else if (Loai == 'tuChoi') {
      this.dataPhongByID = this.dataTypeRoom.filter(
        (x) => x.ID == Data.ID_loai_phong
      );
      this.TuChoiRow = {
        ID_dang_ky: Data.ID_dang_ky,
        ID_sv: Data.ID_sv,
        Ho_ten: Data.Ho_ten,
        Trang_thai_duyet: Data.Trang_thai_duyet,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc,
        Trang_thai_o: Data.Trang_thai_o,
        Email: Data.Email,
        Ma_loai_phong: this.dataPhongByID[0].Ma_loai_phong,
        Ten_loai_phong: this.dataPhongByID[0].Ten_loai_phong,
      };
    } else if (Loai == 'DuyetDK') {
      this.dataPhongByID = this.dataTypeRoom.filter(
        (x) => x.ID == Data.ID_loai_phong
      );
      this.DuyetDKRow = {
        ID_dang_ky: Data.ID_dang_ky,
        ID_sv: Data.ID_sv,
        Ho_ten: Data.Ho_ten,
        Trang_thai_duyet: Data.Trang_thai_duyet,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc,
        Trang_thai_o: Data.Trang_thai_o,
        Ma_loai_phong: this.dataPhongByID[0].Ma_loai_phong,
        Ten_loai_phong: this.dataPhongByID[0].Ten_loai_phong,
        Email: Data.Email,
      };
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  getdatasv(code) {}
  onReset() {
    this.submitted = false;
    this.Insert.reset();
  }

  GetAll() {
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataPhongKTX = z.Data;
    });
  }
  GetDoiTuongChinhSach() {
    this.SystemService.Get_doituong_chinhsach(this.Token).subscribe((z) => {
      this.dataDoiTuongChinhSach = z;
    });
  }
  getHeNhapHoc() {
    this.RegisterDormService.List_He(this.Token).subscribe((z) => {
      this.dataHe = z;
    });
  }
  getKhoaNhapHoc() {
    this.RegisterDormService.List_Khoa(this.Token).subscribe((z) => {
      this.dataKhoa = z;
    });
  }
  getListRegister() {
    this.RegisterDormService.getList(this.Token).subscribe((z) => {
      this.dataTypeRoom = z.Data;
    });
  } // lấy danh sách loại phòng
  getListTypeRoom() {
    this.RoomcategoryService.getList(this.getNull, this.Token).subscribe(
      (z) => {
        this.dataTypeRoom = z.Data;
      }
    );
  } // lấy danh sách loại phòng

  getListTypeRoomGioiTinh(IDGioiTinh, IDsv) {
    this.RoomCategoryRegisterService.getListAdmin(
      IDGioiTinh,
      IDsv,
      this.Token
    ).subscribe((z) => {
      this.dataTypeRoomGioiTinh = z.Data;
    });
  } // lấy danh sách loại phòng
  GetKhuVucTuyenSinh() {
    this.SystemService.Get_khuvuc_tuyensinh(this.Token).subscribe((z) => {
      this.dataKhuVucTuyenSinh = z;
    });
  }

  GetDanToc() {
    this.SystemService.Get_danh_sach_dan_toc(this.Token).subscribe((z) => {
      this.dataDanToc = z;
    });
  }

  GetQuocTich() {
    this.SystemService.Get_danh_sach_quoc_tich(this.Token).subscribe((z) => {
      this.dataQuocTich = z;
    });
  }
  create() {
    this.spinner.show();
    if (this.Insert.value.Loai_phong == 0) {
      this.toastr.warning('Vui lòng chọn loại phòng!');
      this.spinner.hide();
      return;
    }
    var dt = this.dataTypeRoom.filter(
      (x) => x.ID == this.Insert.value.Loai_phong
    );
    this.InputDatas_Update = {
      ID_dang_ky: this.Insert.value.ID_dang_ky,
      ID_doi_tuong: this.Insert.value.ID_doi_tuong,
      code: this.code,
      So_the: this.Insert.value.So_the,
      Ghi_chu: this.Insert.value.Ghi_chu,
      Ngay_cap_the: this.Insert.value.Ngay_cap_the,
      Ngay_sinh: this.Insert.value.Ngay_sinh,
      ID_khu_vuc_TS: this.Insert.value.ID_kv,
      ID_doi_tuong_TS: this.Insert.value.ID_dt,
      Nguyen_vong: this.Insert.value.Nguyen_vong,
      Tinh_trang_suc_khoe: this.Insert.value.Tinh_trang_suc_khoe,
      ID_loai_phong: this.Insert.value.Loai_phong,
      Gia_loai_phong: this.dataTypeRoom.filter(
        (x) => x.ID == this.Insert.value.Loai_phong
      )[0].Gia_phong,
      Ten_loai_phong: this.dataTypeRoom.filter(
        (x) => x.ID == this.Insert.value.Loai_phong
      )[0].Ten_loai_phong,
      Ma_loai_phong: this.dataTypeRoom.filter(
        (x) => x.ID == this.Insert.value.Loai_phong
      )[0].Ma_loai_phong,
      La_truong_phong: false,
      Ten_trang_thai_o: 0,
      Ten_trang_thai_the: 0,
      Ten_trang_thai_duyet: 0,
      Trang_thai_o: 0,
      ID_sv: this.Insert.value.ID_sv,
      ID_phong: null,
      Ma_sv: this.code,
      ID_lop: this.Insert.value.ID_lop,
      Ho_ten: this.Insert.value.Ho_ten,
      Hoc_ky: this.Insert.value.Hoc_ky,
      Nam_hoc: this.Insert.value.Nam_hoc,
      Email: this.Insert.value.Email,
      ID_gioi_tinh: this.Insert.value.ID_gioi_tinh,
    };
    if (this.checkInsert == true) {
      this.RegisterDormService.Update(
        this.InputDatas_Update,
        this.Token
      ).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 9) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.RegisterDormService.Update_Student(
            this.Insert.value,
            this.Token
          ).subscribe(
            (z) => {
              if (z.Status == 5) {
                this.toastr.error('Xảy ra lỗi sửa lại thông tin sinh viên.');
              } else if (z.Status == 1) {
                this.toastr.success(z.Message);
              }
              this.getList();
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              if (err.status == 0) {
                localStorage.removeItem('UserInfo');
                this.router.navigate(['/login']);
              } else if (err.status == 401) {
                this.toastr.warning(
                  'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                );
              }
            }
          );
        } else if (z.Status == 5) {
          this.toastr.error('Cập nhật thất bại!');
        } else if (z.Status == 6) {
          this.toastr.warning(z.Message);
        }
        this.spinner.hide();
        this.modalService.dismissAll('AddModal');
      });
    } else {
      this.RegisterDormService.getSVbyMa(this.code, this.Token).subscribe(
        (z) => {
          if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 5) {
            this.toastr.error(
              'Mã sinh viên không đúng. Xin vui lòng nhập lại mã sinh viên.!'
            );
          } else if (z.Status == 1) {
            this.dataStudent = z.Data;
            this.InputDatas = {
              ID_dang_ky: this.ID_dang_ky,
              ID_doi_tuong: this.Insert.value.ID_doi_tuong,
              ID_gioi_tinh: this.Insert.value.ID_gioi_tinh,
              code: this.code,
              So_the: this.Insert.value.So_the,
              Ghi_chu: this.Insert.value.Ghi_chu,
              Ngay_cap_the: this.Insert.value.Ngay_cap_the,
              ID_khu_vuc_TS: this.Insert.value.ID_kv,
              ID_doi_tuong_TS: this.Insert.value.ID_dt,
              Nguyen_vong: this.Insert.value.Nguyen_vong,
              Tinh_trang_suc_khoe: this.Insert.value.Tinh_trang_suc_khoe,
              ID_loai_phong: this.Insert.value.Loai_phong,
              Gia_loai_phong: this.dataTypeRoom.filter(
                (x) => x.ID == this.Insert.value.Loai_phong
              )[0].Gia_phong,
              Ten_loai_phong: this.dataTypeRoom.filter(
                (x) => x.ID == this.Insert.value.Loai_phong
              )[0].Ten_loai_phong,
              La_truong_phong: false,
              Ten_trang_thai_o: 0,
              Ten_trang_thai_the: 0,
              Ten_trang_thai_duyet: 0,
              Trang_thai_o: 0,
              ID_sv: this.dataStudent[0].ID_sv,
              ID_phong: null,
              Ma_sv: this.code,
              ID_lop: this.dataStudent_Search[0].ID_lop,
              Ho_ten: this.Insert.value.Ho_ten,
              Email: this.Insert.value.Email,
              Ma_loai_phong: this.dataTypeRoom.filter(
                (x) => x.ID == this.Insert.value.Loai_phong
              )[0].Ma_loai_phong,
            };
            this.RegisterDormService.Update_Student(
              this.Insert.value,
              this.Token
            ).subscribe(
              (z) => {
                if (z.Status == 5) {
                  this.toastr.error('Xảy ra lỗi sửa lại thông tin sinh viên.');
                } else if (z.Status == 1) {
                  this.RegisterDormService.Insert(
                    this.InputDatas,
                    this.Token
                  ).subscribe((z) => {
                    if (z.Status == 5) {
                      this.toastr.warning(z.Message);
                    } else if (z.Status == 8) {
                      this.toastr.warning(z.Message);
                    } else if (z.Status == 2) {
                      this.toastr.warning(z.Message);
                    } else if (z.Status == 1) {
                      this.toastr.success(z.Message);
                    } else if (z.Status == 6) {
                      this.toastr.warning(z.Message);
                    }
                    this.getList();
                    this.spinner.hide();
                  });
                }
              },
              (err) => {
                this.spinner.hide();
                if (err.status == 0) {
                  localStorage.removeItem('UserInfo');
                  this.router.navigate(['/login']);
                } else if (err.status == 401) {
                  this.toastr.warning(
                    'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                  );
                }
              }
            );
          }
          this.spinner.hide();
          this.modalService.dismissAll('AddModal');
        }
      );
    }
  }

  search() {
    this.spinner.show();
    this.RegisterDormService.getSVbyMa(this.code, this.Token).subscribe(
      (z) => {
        this.spinner.hide();
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 5) {
          this.toastr.error(
            'Mã sinh viên không đúng. Xin vui lòng nhập lại  mã sinh viên dể tìm kiếm.!'
          );
        } else if (z.Status == 1) {
          this.dataStudent_Search = z.Data;
          this.getListTypeRoomGioiTinh(
            this.dataStudent_Search[0].ID_gioi_tinh,
            this.dataStudent_Search[0].ID_sv
          );
          this.Insert.patchValue({
            ID_sv: this.dataStudent_Search[0].ID_sv,
            Ma_sv: this.dataStudent_Search[0].Ma_sv,
            ID_dan_toc: this.dataStudent_Search[0].ID_dan_toc,
            Ho_ten: this.dataStudent_Search[0].Ho_ten,
            Ngay_sinh:
              this.dataStudent_Search[0].Ngay_sinh == null
                ? ''
                : this.dataStudent_Search[0].Ngay_sinh.substring(0, 10),
            ID_gioi_tinh: this.dataStudent_Search[0].ID_gioi_tinh,
            CMND: this.dataStudent_Search[0].CMND,
            Ngay_cap_CMND:
              this.dataStudent_Search[0].Ngay_cap_CMND == null
                ? ''
                : this.dataStudent_Search[0].Ngay_cap_CMND.substring(0, 10),
            ID_noi_cap_CMND: this.dataStudent_Search[0].ID_noi_cap_CMND,
            Ton_giao: this.dataStudent_Search[0].Ton_giao,
            So_ho_chieu: this.dataStudent_Search[0].So_ho_chieu,
            Ngay_het_han_visa:
              this.dataStudent_Search[0].Ngay_het_han_visa == null
                ? ''
                : this.dataStudent_Search[0].Ngay_het_han_visa.substring(0, 10),
            Dienthoai_canhan: this.dataStudent_Search[0].Dienthoai_canhan,
            Email: this.dataStudent_Search[0].Email,
            Dia_chi_tt: this.dataStudent_Search[0].Dia_chi_tt,
            ID_quoc_tich: this.dataStudent_Search[0].ID_quoc_tich,
            Ho_ten_cha: this.dataStudent_Search[0].Ho_ten_cha,
            Namsinh_cha: this.dataStudent_Search[0].Namsinh_cha,
            Hoat_dong_XH_CT_cha: this.dataStudent_Search[0].Hoat_dong_XH_CT_cha,
            So_dien_thoai_bo: this.dataStudent_Search[0].So_dien_thoai_bo,
            Ho_ten_me: this.dataStudent_Search[0].Ho_ten_me,
            Namsinh_me: this.dataStudent_Search[0].Namsinh_me,
            Hoat_dong_XH_CT_me: this.dataStudent_Search[0].Hoat_dong_XH_CT_me,
            So_dien_thoai_me: this.dataStudent_Search[0].So_dien_thoai_me,
            NoiO_hiennay: this.dataStudent_Search[0].NoiO_hiennay,
            He_nhap_hoc: this.dataStudent_Search[0].He_nhap_hoc,
            Ten_khoa_nhap_hoc: this.dataStudent_Search[0].Ten_khoa_nhap_hoc,
            Khoa_hoc_nhap_hoc: this.dataStudent_Search[0].Khoa_hoc_nhap_hoc,
            Ten_nganh_nhap_hoc: this.dataStudent_Search[0].Ten_nganh_nhap_hoc,
            Ten_chuyen_nganh_nhap_hoc: this.dataStudent_Search[0]
              .Ten_chuyen_nganh_nhap_hoc,
            Lop: this.dataStudent_Search[0].Lop,
            So_the: this.dataStudent_Search[0].Ma_sv,
            ID_dang_ky: this.dataStudent_Search[0].ID_dang_ky,
            ID_dt: this.dataStudent_Search[0].ID_doi_tuong_TS,
            ID_doi_tuong_TS: this.dataStudent_Search[0].ID_doi_tuong_TS,
            ID_kv: this.dataStudent_Search[0].ID_khu_vuc_TS,
            ID_khu_vuc_TS: this.dataStudent_Search[0].ID_khu_vuc_TS,
            ID_lop: this.dataStudent_Search[0].ID_lop,
          });
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  GetStringID(m) {
    var rs = '';
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_dang_ky;
      } else {
        rs += ',' + m[k].ID_dang_ky;
      }
    }
    return rs;
  }
  Delete_Register() {
    let TrangThaiDuyet = this.Trang_thai_duyet;
    if (TrangThaiDuyet == 1) {
      this.toastr.warning('Bạn không thể xóa đăng ký ở trạng thái: Đã duyệt.!');
    } else {
      this.RegisterDormService.Delete(this.ID_dang_ky, this.Token).subscribe(
        (z) => {
          if (z.Status == 2) {
            this.toastr.error('Có lỗi trong quá trình xóa đăng ký.!');
          } else {
            this.modalService.dismissAll('Delete');

            this.toastr.success('Xóa đăng ký thành công!');
          }
          this.getList();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  Approve_Register() {
    this.spinner.show();
    this.RegisterDormService.UpdateStatus(
      this.DuyetDKRow,
      this.Token
    ).subscribe(
      (z) => {
        if (z.Status == 5) {
          this.toastr.error('Xảy ra lỗi trong quá trình Khóa đăng ký.!');
        } else if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 7) {
          this.toastr.warning(z.Message);
        } else {
          this.toastr.success(z.Message);
        }
        this.modalService.dismissAll('DuyetDK');
        this.getList();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  UnApprove_Register() {
    this.spinner.show();
    if (this.Ly_do == undefined) this.HuyDuyetRow.Ly_do = '';
    else this.HuyDuyetRow.Ly_do = this.Ly_do;
    this.RegisterDormService.UnApproveRegister(
      this.HuyDuyetRow,
      this.Token
    ).subscribe(
      (z) => {
        if (z.Status == 5) {
          this.toastr.error('Xảy ra lỗi trong quá trình hủy duyệt đăng ký.!');
        } else if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 7) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        }
        this.modalService.dismissAll('HuyDuyet');
        this.getList();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  DuyetDangKy_ListID() {
    this.spinner.show();
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('DuyetDKList');
      this.toastr.error('Bạn vui lòng tích chọn sinh viên để Khóa đăng ký.!');
    } else {
      var length = dt.length;
      var dtstring = this.GetStringID(dt);
      let data = {
        lstIdDangKy: dtstring,
      };
      this.RegisterDormService.UpdateStatus_ListID(data, this.Token).subscribe(
        (z) => {
          if (z.Status == 5) {
            this.toastr.error('Xảy ra lỗi trong duyệt đăng ký.!');
          } else if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 7) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
          }
          this.modalService.dismissAll('DuyetDKList');
          this.getList();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  HuyDangKy_ListID() {
    this.spinner.show();
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('HuyDuyetList');
      this.toastr.error('Bạn vui lòng tích chọn sinh viên để Hủy đăng ký.!');
    } else {
      var length = dt.length;
      var dtstring = this.GetStringID(dt);
      let data = {
        lstIdDangKy: dtstring,
        Ly_do: this.Ly_do == undefined ? '' : this.Ly_do,
      };
      this.RegisterDormService.UnApproveRegister_ListID(
        data,
        this.Token
      ).subscribe(
        (z) => {
          if (z.Status == 5) {
            this.toastr.error('Xảy ra lỗi trong quá trình hủy duyệt đăng ký.!');
          } else if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 7) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
          }
          this.modalService.dismissAll('HuyDuyetList');
          this.getList();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  TuChoiDangKy_ListID() {
    this.spinner.show();
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('TuChoiList');
      this.toastr.error(
        'Bạn vui lòng tích chọn sinh viên để Từ chối đăng ký.!'
      );
    } else {
      var length = dt.length;
      var dtstring = this.GetStringID(dt);
      let data = {
        lstIdDangKy: dtstring,
        Ly_do: this.Ly_do == undefined ? '' : this.Ly_do,
      };
      this.RegisterDormService.RejectStatus_ListID(data, this.Token).subscribe(
        (z) => {
          if (z.Status == 5) {
            this.toastr.error('Xảy ra lỗi trong quá trình hủy duyệt đăng ký.!');
          } else if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 7) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
          }

          this.modalService.dismissAll('TuChoiList');
          this.getList();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }

  Reject_Register() {
    this.spinner.show();
    if (this.Ly_do == undefined) this.TuChoiRow.Ly_do = '';
    else this.TuChoiRow.Ly_do = this.Ly_do;
    this.RegisterDormService.RejectStatus(this.TuChoiRow, this.Token).subscribe(
      (z) => {
        if (z.Status == 5) {
          this.toastr.error(
            'Xảy ra lỗi trong quá trình từ chối duyệt đăng ký.!'
          );
        } else if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 8) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        }
        this.modalService.dismissAll('TuChoi');
        this.getList();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  ImportExcel() {
    this.spinner.show();
    var lstCols = ['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I', 'J'];
    if (this.worksheet == undefined) {
      this.toastr.warning('Bạn phải chọn file để ấn lưu.!');
      this.spinner.hide();
    } else {
      var ref = this.worksheet['!ref'];
      var row = ref.substring(4, ref.lenght);
      let lstData: any = [];
      for (let index = 2; index <= row; index++) {
        var obj = {
          Ma_sv: null,
          CMND: null,
          Ma_loai_phong: '',
          Doi_tuong_chinh_sach: '',
          So_the: '',
          Ngay_cap_the: '',
          Khu_vuc: '',
          Tinh_trang_suc_khoe: '',
          Nguyen_vong: '',
          Ghi_chu: '',
          ID_sv: 0,
          Trang_thai_o: 0,
        };
        if (
          this.worksheet['A' + index] != undefined ||
          this.worksheet['A' + index] != null
        ) {
          obj.Ma_sv = this.worksheet['A' + index].v;
        }
        if (
          this.worksheet['B' + index] != undefined ||
          this.worksheet['B' + index] != null
        ) {
          obj.CMND = this.worksheet['B' + index].v;
        }
        if (
          this.worksheet['C' + index] != undefined ||
          this.worksheet['C' + index] != null
        ) {
          obj.Ma_loai_phong = this.worksheet['C' + index].v;
        }
        if (
          this.worksheet['D' + index] != undefined ||
          this.worksheet['D' + index] != null
        ) {
          obj.So_the = this.worksheet['D' + index].v;
        }

        if (
          this.worksheet['G' + index] != undefined ||
          this.worksheet['G' + index] != null
        ) {
          obj.Khu_vuc = this.worksheet['G' + index].v;
        }
        if (
          this.worksheet['H' + index] != undefined ||
          this.worksheet['H' + index] != null
        ) {
          obj.Tinh_trang_suc_khoe = this.worksheet['H' + index].v;
        }
        if (
          this.worksheet['I' + index] != undefined ||
          this.worksheet['I' + index] != null
        ) {
          obj.Nguyen_vong = this.worksheet['I' + index].v;
        }
        if (
          this.worksheet['J' + index] != undefined ||
          this.worksheet['J' + index] != null
        ) {
          obj.Ghi_chu = this.worksheet['J' + index].v;
        }

        lstData.push(obj);
        // this.modalService.dismissAll('Import_Excel');
      }
      console.log(lstData);
      this.RegisterDormService.Import_Excel(lstData, this.Token).subscribe(
        (z) => {
          if (z.Status == 5) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 2) {
            this.toastr.error(
              'Import đăng ký cho sinh viên không thành công.!'
            );
          } else if (z.Status == 1) {
            this.toastr.success('Import đăng ký cho sinh viên thành công.!');
          } else if (z.Status == 8) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 6) {
            this.toastr.warning(z.Message);
          }
          this.modalService.dismissAll('Import_Excel');
          this.getList();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            this.toastr.warning('');
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();

      let param = this.getRequest();
      this.RegisterDormService.ExcelDangKy(param, a.Token).subscribe((z) => {
        this.exportService.exportExcelByte(z.FileData, 'DANHSACHDANGKY');
        this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
        this.spinner.hide();

        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        };
      });
    }
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_DuyetDK;
    window.open(urlHelp, '_blank');
  }
}

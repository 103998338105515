import { Injectable, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ListallService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }
  getListBuilding(dt: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Building/BuildingCampusLoad?ID_co_so=' +dt,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Register_getManager(ID_phong: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/Register_getManager?ID_phong=' + ID_phong,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Register_getRoom(ID_phong: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Register/Register_getRoom?ID_phong=' + ID_phong,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListRoom(dt: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Room/RoomBuildingLoad?ID_toa_nha=' +dt,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListRoomNuoc(dt: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Room/RoomSoNuoc?ID_toa_nha=' +dt.ID_toa_nha+'&Thang='+dt.Thang+'&Nam='+dt.Nam,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListRoomDien(dt: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
        'Room/RoomSoDien?ID_toa_nha=' +dt.ID_toa_nha+'&Thang='+dt.Thang+'&Nam='+dt.Nam,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListCampus(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Campus/Campus_List', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-dang-ky-qua-trinh',
  templateUrl: './dang-ky-qua-trinh.component.html',
  styleUrls: ['./dang-ky-qua-trinh.component.scss'],
})
export class DangKyQuaTrinhComponent extends BaseComponent implements OnInit {
  Ma_sv: any = '';
  Ho_ten: any = '';
  Ngay_sinh: any;
  Ten_phong: any;
  Hoc_ky: any;
  Nam_hoc: any;
  ID_toa_nha: any;
  So_lan_bien_dong: any;
  Token: string;
  dataKhoanNopKTX: any = [];
  tableData: any = [];
  dataDetail: any = [];
  public com: common;
  ngOnInit() {
 
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.TongHop(true);
    this.getListBuilding();
  }
  TongHop(Recount) {
    let req = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Ma_sv: this.Ma_sv,
      Ho_ten: this.Ho_ten,
      Ngay_sinh: this.Ngay_sinh,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      ID_toa_nha: this.ID_toa_nha,
      Ten_phong: this.Ten_phong,
      So_lan_bien_dong: this.So_lan_bien_dong,
    };
    this.spinner.show();
    this.DangKyQuaTrinhServiceService.TongHop(req, this.Token).subscribe(
      (z) => {
        this.spinner.hide();
        this.dataTable = z.Data;
        if (Recount) {
          this.DangKyQuaTrinhServiceService.TongHop_Count(
            req,
            this.Token
          ).subscribe((z) => {
            this.spinner.hide();
            this.totalItem = z.TotalNumber;
            this.totalItemFilter = z.FilterNumber;
            this.numberPage = this.createNumberPage(
              z.FilterNumber,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.FilterNumber,
              this.pageSize
            ).arrNumberPage_chil;
          });
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 401) {
          this.dataTable = [];
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }

  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 1000000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.TongHop(false);
  }
  refesh() {}
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    this.TongHop(false);
  }
  getListBuilding() {
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data;
    });
  }
  open(content, sizm, type, data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.DangKyQuaTrinhServiceService.Detail(
      data.ID_dang_ky,
      this.Token
    ).subscribe((z) => {
      this.dataDetail = z.Data;
    });
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_BienDongKT;
    window.open(urlHelp,'_blank');
  }
}

<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
/>
<p style="text-align: center;">
  <strong>CỘNG H&Ograve;A X&Atilde; HỘI CHỦ NGHĨA VIỆT NAM</strong
  ><strong
    ><u><br />Độc lập - Tự do - Hạnh ph&uacute;c</u></strong
  ><strong>&nbsp;</strong>
</p>
<p style="text-align: right;">
  Số:&nbsp;...................&nbsp;&nbsp;/2020/HĐNT-KTX
</p>
<h3 style="text-align: center;">
  <strong>HỢP ĐỒNG Ở NỘI TR&Uacute;&nbsp;</strong>
</h3>
<ul>
  <li>
    <em
      >Căn cứ Quy chế C&ocirc;ng t&aacute;c HSSV Nội tr&uacute; - Ban
      h&agrave;nh k&egrave;m theo Th&ocirc;ng tư số 27/2011/TT-BGDĐT ng&agrave;y
      27 th&aacute;ng 06 năm 2011 của Bộ Gi&aacute;o dục v&agrave; Đ&agrave;o
      tạo;</em
    >
  </li>
  <li>
    <em
      >Căn cứ Chức năng, nhiệm vụ của Ban quản l&yacute; K&yacute; t&uacute;c
      x&aacute;;</em
    >
  </li>
</ul>
<p>
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Hôm nay ngày {{ date | date: 'dd' }} tháng
  {{ date | date: 'MM' }} năm {{ date | date: 'yyyy' }} tại K&yacute; t&uacute;c
  x&aacute; &ndash; Trường Đại học Sư phạm H&agrave; Nội.
</p>
<p>
  <strong>Ch&uacute;ng t&ocirc;i gồm c&oacute;:</strong><strong>&nbsp;</strong>
</p>
<p><strong>B&ecirc;n A: B&Ecirc;N CHO THU&Ecirc;&nbsp;</strong></p>
<p>
  <strong
    >Ban quản l&yacute; KTX - Trường Đại học Sư phạm H&agrave; Nội (Sau
    đ&acirc;y gọi tắt l&agrave; K&yacute; t&uacute;c x&aacute;)</strong
  >
</p>
<p>
  - Người đại diện: &Ocirc;ng&nbsp;{{ Nguoi_dai_dien }}&nbsp; &nbsp; &nbsp;
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Chức vụ:&nbsp;Trưởng Ban&nbsp;quản
  l&yacute;&nbsp;K&yacute; t&uacute;c x&aacute;.
</p>
<p>
  - Địa chỉ:&nbsp;Tầng 1, nh&agrave; A12, K&yacute; t&uacute;c x&aacute; -
  Trường ĐHSP H&agrave; Nội, số 136 Xu&acirc;n Thủy, &nbsp;P.Dịch Vọng Hậu,
  Q.Cầu Giấy, TP. H&agrave; Nội.
</p>
<p>- Điện thoại: {{ So_dien_thoai }}&nbsp;</p>
<p><strong>B&ecirc;n B: B&Ecirc;N THU&Ecirc;&nbsp;</strong></p>
<p>
  - Họ v&agrave; t&ecirc;n HSSV: {{ dt_print.Ho_ten }} &nbsp; &nbsp; &nbsp;
  &nbsp; &nbsp;Nam (Nữ): {{ dt_print.Gioi_Tinh == 1 ? 'Nữ' : 'Nam' }}&nbsp;
  &nbsp; &nbsp;Ng&agrave;y sinh: {{ dt_print.Ngay_sinh | date: 'dd' }} /{{
    dt_print.Ngay_sinh | date: 'MM'
  }}/{{ dt_print.Ngay_sinh | date: 'yyyy' }}
</p>
<p>
  -&nbsp;Trường: Đại học Sư phạm Hà Nội &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
  Khoa:{{ dl_sv.Ten_khoa_nhap_hoc }} &nbsp; &nbsp;
  &nbsp;&nbsp;Kh&oacute;a:&nbsp;{{ dt_print.Khoa_hoc_nhap_hoc }} &nbsp; &nbsp;
  &nbsp;&nbsp;Lớp:&nbsp;{{ dl_sv.Lop }}
</p>
<p>
  - Số CMND/CCCD:{{
    dt_print.CMND == null
      ? '&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;'
      : dt_print.CMND
  }}
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Cấp ng&agrave;y:{{
    dt_print.Ngay_cap_CMND == null
      ? '&nbsp; &nbsp; &nbsp;&nbsp;'
      : (dt_print.Ngay_cap_CMND | date: 'dd')
  }}
  /{{
    dt_print.Ngay_cap_CMND == null
      ? '&nbsp; &nbsp; &nbsp;&nbsp;'
      : (dt_print.Ngay_cap_CMND | date: 'MM')
  }}/{{
    dt_print.Ngay_cap_CMND == null
      ? '&nbsp; &nbsp; &nbsp;&nbsp;'
      : (dt_print.Ngay_cap_CMND | date: 'yyyy')
  }}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;tại:{{
    dt_print2.ID_noi_cap_CMND == ''
      ? '&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;'
      : dt_print2.ID_noi_cap_CMND
  }}&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Số thẻ HSSV :
  &hellip;{{ dt_print.So_the }}................&hellip;&hellip;.
</p>
<p>
  - Điện thoại:{{
    dl_sv.Dienthoai_canhan == null
      ? '&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;'
      : dl_sv.Dienthoai_canhan
  }}
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Email:{{ dl_sv.Email }}
</p>
<p>
  - Khi cần b&aacute;o tin cho cha, mẹ&nbsp;<em>(hoặc người th&acirc;n):</em
  >&nbsp; &nbsp;{{
    dt_print.Ho_ten_cha == '' ? dt_print.Ho_ten_me : dt_print.Ho_ten_cha
  }}
</p>
<p>
  - Địa chỉ:&nbsp; &nbsp;{{
    dt_print.Dia_chi_tt == ''
      ? '&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;'
      : dt_print.Dia_chi_tt
  }}
  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Số điện thoại li&ecirc;n hệ:
  {{
    dt_print.Ho_ten_cha == '' ? dl_sv.So_dien_thoai_me : dl_sv.So_dien_thoai_me
  }}
</p>
<p>
  Hai b&ecirc;n thống nhất k&yacute; kết Hợp đồng&nbsp;ở nội
  tr&uacute;&nbsp;d&ugrave;ng cho mục đ&iacute;ch để ở v&agrave; sinh hoạt của
  HSSV với&nbsp;những&nbsp;nội dung sau:<strong>&nbsp;</strong>
</p>
<p>
  <strong>Điều 1.</strong><strong>&nbsp;</strong
  ><strong>Đặc điểm ch&iacute;nh của nh&agrave; ở HSSV</strong>
</p>
<p>
  <strong>Địa điểm thu&ecirc;:</strong>&nbsp;Khu nh&agrave; ở HSSV K&yacute;
  t&uacute;c x&aacute; - Trường ĐHSP H&agrave; Nội, số 136 Xu&acirc;n Thủy,
  &nbsp; P.Dịch Vọng Hậu, Q.Cầu Giấy, TP. H&agrave; Nội.
</p>
<div align="center">
  <table style="height: 41px; width: 790px;">
    <tbody>
      <tr style="height: 35.7188px;">
        <td
          style="
            height: 35.7188px;
            width: 95px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p><b>Nh&agrave;</b></p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 95px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p>&nbsp;{{ dt_print.ToaNha_Phong }}</p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 95px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p><b>Tầng</b></p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 95px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p>&nbsp;{{ dt_print.ID_Tang }}</p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 95px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p><b>Ph&ograve;ng</b></p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 95px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p>&nbsp;{{ dt_print.Ten_phong }}</p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 125px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p><b>M&atilde;&nbsp;thẻ ra v&agrave;o</b></p>
        </td>
        <td
          style="
            height: 35.7188px;
            width: 125px;
            border: solid 1px black;
            text-align: center;
          "
        >
          <p>&nbsp;{{ dl_room[0].Ma_the }}</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<p><strong>&nbsp;</strong></p>
<p>
  <strong>Điều 2.</strong><strong>&nbsp;</strong
  ><strong>Gi&aacute; trị hợp đồng,</strong
  ><strong>&nbsp;phương thức thanh to&aacute;n, thời hạn hợp đồng</strong
  ><em>&nbsp;</em>
</p>
<p><strong>2.1.</strong><strong>&nbsp;Gi&aacute; trị hợp đồng</strong></p>
<p>
  Gi&aacute; trị hợp đồng =&nbsp;Đơn gi&aacute;&nbsp;cho thu&ecirc;&nbsp;x Thời
  gian thu&ecirc;.
</p>
<p>
  (Gi&aacute; cho thu&ecirc; theo quy định của Nh&agrave; trường; Thời gian cho
  thu&ecirc; theo Kế hoạch đ&agrave;o tạo của Nh&agrave; trường).
</p>
<p><strong>2.2. Phương thức thanh to&aacute;n</strong></p>
<p>
  a) B&ecirc;n B thanh to&aacute;n tiền thu&ecirc;&nbsp;chỗ ở&nbsp;01 lần bằng
  tiền mặt ngay khi k&yacute;&nbsp;Hợp đồng.
</p>
<p>
  b) C&aacute;c chi ph&iacute; sử dụng điện, nước, ...&nbsp;B&ecirc;n B
  c&oacute; tr&aacute;ch nhiệm thanh to&aacute;n bằng tiền mặt cho B&ecirc;n A
  theo thực tế sử dụng trong thời gian 05 ng&agrave;y kể từ ng&agrave;y
  B&ecirc;n A th&ocirc;ng b&aacute;o cho B&ecirc;n B.&nbsp;
</p>
<p>
  c) Gi&aacute; thu&ecirc;&nbsp;chỗ&nbsp;ở HSSV sẽ được điều chỉnh khi
  Nh&agrave; trường c&oacute; thay đổi đơn gi&aacute; cho thu&ecirc;. B&ecirc;n
  A c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o gi&aacute; mới cho
  B&ecirc;n B biết trước khi &aacute;p dụng 01 th&aacute;ng.<strong
    >&nbsp;</strong
  >
</p>
<p>
  <strong>2.3.</strong><strong>&nbsp;</strong><strong>Thời hạn&nbsp;</strong
  ><strong>H</strong><strong>ợp đồng</strong>
</p>
<p>
  Thời hạn hợp đồng kể từ&nbsp;ng&agrave;y&nbsp;{{
    dt_print.Ngay_bat_dau | date: 'dd'
  }}
  tháng {{ dt_print.Ngay_bat_dau | date: 'MM' }} năm
  {{ dt_print.Ngay_bat_dau | date: 'yyyy' }}&nbsp;đến&nbsp;ng&agrave;y&nbsp;{{
    dt_print.Ngay_ket_thuc | date: 'dd'
  }}
  tháng {{ dt_print.Ngay_ket_thuc | date: 'MM' }} năm
  {{ dt_print.Ngay_ket_thuc | date: 'yyyy' }}
</p>
<p>
  <strong>Điều 3. Biện ph&aacute;p đảm bảo thực hiện&nbsp;</strong
  ><strong>H</strong><strong>ợp đồng</strong>
</p>
<p>
  B&ecirc;n B đặt một khoản tiền cược t&agrave;i sản được quy định l&agrave;:{{
    dl_room[0].Tien_cuoc
  }}&nbsp;VNĐ để đảm bảo việc thực hiện&nbsp;Hợp đồng đối
  với&nbsp;HSSV&nbsp;được x&eacute;t thu&ecirc; chỗ ở. B&ecirc;n A sẽ
  ho&agrave;n trả số tiền n&agrave;y sau khi B&ecirc;n B chấm dứt hợp đồng
  m&agrave; kh&ocirc;ng l&agrave;m hư hỏng t&agrave;i sản v&agrave; đ&atilde;
  thanh to&aacute;n c&aacute;c khoản nợ&nbsp;<em>(nếu c&oacute;)</em>&nbsp;cho
  B&ecirc;n A. Đồng thời,&nbsp;B&ecirc;n B đ&atilde; mang to&agrave;n bộ
  h&agrave;nh l&yacute;,&nbsp;tư trang c&aacute; nh&acirc;n ra
  khỏi&nbsp;K&yacute; t&uacute;c x&aacute;.
</p>
<p>
  <strong>Điều 4.</strong><strong>&nbsp;</strong
  ><strong>Quyền v&agrave; nghĩa vụ của B&ecirc;n A</strong>
</p>
<p>
  <strong>1</strong><strong>.</strong
  ><strong>&nbsp;Quyền của B&ecirc;n A</strong>
</p>
<p>
  a) Y&ecirc;u cầu B&ecirc;n B sử dụng chỗ ở đ&uacute;ng mục đ&iacute;ch
  v&agrave; đ&uacute;ng&nbsp;Nội quy,&nbsp;Quy định&nbsp;của&nbsp;K&yacute;
  t&uacute;c x&aacute;&nbsp;v&agrave; Trường ĐHSP H&agrave; Nội;
</p>
<p>
  b) Xử l&yacute;&nbsp;hoặc phối hợp với c&aacute;c đơn vị li&ecirc;n quan trong
  việc xử l&yacute; vi phạm quy định về quản l&yacute; sử dụng ph&ograve;ng ở;
</p>
<p>
  c) Y&ecirc;u cầu B&ecirc;n B thực hiện đ&uacute;ng nội dung ghi
  trong&nbsp;Điều 2 của&nbsp;Hợp đồng n&agrave;y;
</p>
<p>
  d) Y&ecirc;u cầu B&ecirc;n B c&oacute; tr&aacute;ch nhiệm thanh to&aacute;n
  tiền để sửa chữa phần hư hỏng, bồi thường thiệt hại do lỗi của B&ecirc;n B
  g&acirc;y ra;
</p>
<p>
  g) Di chuyển, bố tr&iacute;, sắp xếp chỗ ở của&nbsp;B&ecirc;n B để phục vụ
  c&ocirc;ng t&aacute;c quản l&yacute; vận h&agrave;nh KTX;
</p>
<p>
  h) Được quyền chấm dứt hợp đồng v&agrave; thu hồi chỗ ở khi c&oacute; một
  trong c&aacute;c trường hợp quy định tại Điều 7 của hợp đồng n&agrave;y;
</p>
<p>
  i) C&aacute;c quyền kh&aacute;c&nbsp;<em>(theo thỏa thuận)</em
  ><em>.</em>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
</p>
<p>
  <strong>2</strong><strong>.</strong><strong>&nbsp;Nghĩa vụ của&nbsp;</strong
  ><strong>B</strong><strong>&ecirc;n A</strong>
</p>
<p>
  a) Giao chỗ ở cho B&ecirc;n B đ&uacute;ng thời gian quy định tại Điều 2 của
  hợp đồng n&agrave;y;
</p>
<p>
  b) Phổ biến&nbsp;Nội quy,&nbsp;Quy định&nbsp;của&nbsp;K&yacute; t&uacute;c
  x&aacute;&nbsp;v&agrave; Trường ĐHSP H&agrave; Nội cho B&ecirc;n B v&agrave;
  c&aacute;c tổ chức, c&aacute; nh&acirc;n li&ecirc;n quan biết;
</p>
<p>
  c) Thực hiện quản l&yacute; vận h&agrave;nh, bảo tr&igrave; nh&agrave; ở cho
  thu&ecirc; theo quy định;
</p>
<p>
  d) Th&ocirc;ng b&aacute;o cho B&ecirc;n B những thay đổi về gi&aacute;
  thu&ecirc; &iacute;t nhất l&agrave; 01 th&aacute;ng trước khi điều
  chỉnh&nbsp;đơn gi&aacute; cho thu&ecirc;&nbsp;mới;
</p>
<p>
  g) Phối hợp với c&aacute;c tổ chức, c&aacute;c nh&acirc;n c&oacute; li&ecirc;n
  quan đ&ocirc;n đốc&nbsp;HSSV&nbsp;chấp h&agrave;nh&nbsp;Nội quy,&nbsp;Quy
  định&nbsp;của&nbsp;K&yacute; t&uacute;c x&aacute;&nbsp;v&agrave; Trường ĐHSP
  H&agrave; Nội;
</p>
<p>
  h) C&aacute;c nghĩa vụ kh&aacute;c&nbsp;<em>(theo thỏa thuận)</em><em>.</em>
</p>
<p>
  <strong>Điều 6.</strong><strong>&nbsp;</strong
  ><strong>Quyền v&agrave; nghĩa vụ của B&ecirc;n B</strong>
</p>
<p><strong>1. Quyền của B&ecirc;n B</strong></p>
<p>
  a) Nhận chỗ ở theo đ&uacute;ng thỏa thuận n&ecirc;u tại Khoản 1 Điều 1 của hợp
  đồng n&agrave;y;
</p>
<p>
  b) Y&ecirc;u cầu B&ecirc;n A sửa chữa kịp thời những hư hỏng của ph&ograve;ng
  ở v&agrave; trang thiết bị trong ph&ograve;ng để đảm bảo việc sử dụng
  b&igrave;nh thường v&agrave; an to&agrave;n;
</p>
<p>c) Được gia hạn ở nội tr&uacute; nếu vẫn thuộc đối tượng, đủ điều kiện;</p>
<p>
  d) Chấm dứt&nbsp;Hợp đồng khi kh&ocirc;ng c&ograve;n nhu cầu
  thu&ecirc;&nbsp;chỗ&nbsp;ở;
</p>
<p>g) C&aacute;c quyền kh&aacute;c&nbsp;<em>(theo thỏa thuận).</em></p>
<p>
  <strong>2</strong><strong>.</strong
  ><strong>&nbsp;Nghĩa vụ của B&ecirc;n B</strong>
</p>
<p>
  a) Sử dụng chỗ ở đ&uacute;ng mục đ&iacute;ch,&nbsp;giữ
  g&igrave;n&nbsp;ph&ograve;ng&nbsp;ở v&agrave; c&oacute; tr&aacute;ch nhiệm sửa
  chữa những hư hỏng v&agrave; bồi thường thiệt hại do lỗi của m&igrave;nh
  g&acirc;y ra.&nbsp;Kh&ocirc;ng được tự &yacute; dịch chuyển vị tr&iacute; hoặc
  thay đổi trang thiết bị đ&atilde; lắp đặt sẵn trong ph&ograve;ng;
</p>
<p>
  b) Thực hiện đ&uacute;ng nội dung trong&nbsp;Điều 2 của&nbsp;Hợp đồng
  n&agrave;y;
</p>
<p>
  c) Chấp h&agrave;nh đầy đủ những&nbsp;Nội quy,&nbsp;Quy
  định&nbsp;của&nbsp;K&yacute; t&uacute;c x&aacute;&nbsp;v&agrave; Trường ĐHSP
  H&agrave; Nội;
</p>
<p>
  d) Kh&ocirc;ng được chuyển nhượng&nbsp;Hợp đồng ở nội tr&uacute; hoặc cho
  người kh&aacute;c thu&ecirc; lại chỗ ở&nbsp;HSSV&nbsp;dưới bất kỳ h&igrave;nh
  thức n&agrave;o;
</p>
<p>
  g) Kh&ocirc;ng được tự &yacute; sửa chữa, cải tạo nh&agrave; ở. Chấp
  h&agrave;nh đầy đủ những quy định về quản l&yacute; sử dụng nh&agrave; ở
  v&agrave; c&aacute;c quyết định của cơ quan c&oacute; thẩm quyền về quản
  l&yacute; nh&agrave; ở;
</p>
<p>
  h) Giao lại chỗ ở cho B&ecirc;n A trong c&aacute;c trường hợp chấm
  dứt&nbsp;Hợp đồng hoặc trong trường hợp chỗ ở nội tr&uacute; thuộc diện bị thu
  hồi;
</p>
<p>i) C&aacute;c nghĩa vụ kh&aacute;c&nbsp;<em>(theo thỏa thuận).</em></p>
<p>
  <strong>Điều 7.</strong><strong>&nbsp;</strong
  ><strong>Chấm dứt hợp đồng</strong>
</p>
<p>Hợp đồng n&agrave;y chấm dứt trong c&aacute;c trường hợp sau:</p>
<p>1. Hết thời hạn hiệu lực của&nbsp;Hợp đồng&nbsp;ở nội tr&uacute;;</p>
<p>
  2. Khi hai b&ecirc;n c&ugrave;ng nhất tr&iacute; chấp dứt&nbsp;Hợp đồng&nbsp;ở
  nội tr&uacute;;
</p>
<p>
  3. Khi&nbsp;B&ecirc;n B kh&ocirc;ng c&ograve;n thuộc đối tượng được thu&ecirc;
  chỗ ở hoặc khi B&ecirc;n B mất năng lực h&agrave;nh vi d&acirc;n sự;&nbsp;
</p>
<p>
  4. Khi&nbsp;B&ecirc;n B vi phạm&nbsp;Nội quy,&nbsp;Quy
  định&nbsp;của&nbsp;K&yacute; t&uacute;c x&aacute;&nbsp;v&agrave; Trường ĐHSP
  H&agrave; Nội;
</p>
<p>
  5. Khi&nbsp;B&ecirc;n B kh&ocirc;ng thanh to&aacute;n&nbsp;ph&iacute; nội
  tr&uacute;, c&aacute;c khoản tiền điện, nước sinh
  hoạt,&nbsp;...&nbsp;qu&aacute; 15 ng&agrave;y m&agrave; kh&ocirc;ng c&oacute;
  l&yacute; do ch&iacute;nh đ&aacute;ng;
</p>
<p>
  6. Khi&nbsp;B&ecirc;n B tự &yacute; sửa chữa, đục ph&aacute; kết cấu, cải tạo
  hoặc cơi nới ph&ograve;ng ở;
</p>
<p>
  7. Khi&nbsp;B&ecirc;n B tự &yacute; chuyển quyền thu&ecirc; cho người
  kh&aacute;c hoặc cho người kh&aacute;c c&ugrave;ng sử dụng ph&ograve;ng ở;
</p>
<p>
  8. Khi&nbsp;B&ecirc;n B vi phạm c&aacute;c Điều cấm theo quy định&nbsp;của
  Ph&aacute;p luật;
</p>
<p>
  9. Khi nh&agrave; ở cho thu&ecirc; bị hư hỏng nặng c&oacute; nguy cơ sập đổ
  hoặc nằm trong khu vực đ&atilde; c&oacute; quyết định thu hồi đất, giải
  ph&oacute;ng mặt bằng hoặc c&oacute; quyết định ph&aacute; dỡ của cơ quan
  nh&agrave; nước c&oacute; thẩm quyền;
</p>
<p>
  10. Khi một trong c&aacute;c b&ecirc;n đơn phương chấm dứt hợp đồng theo thỏa
  thuận&nbsp;<em>(nếu c&oacute;)</em>&nbsp;hoặc theo quy định&nbsp;của
  Ph&aacute;p luật;
</p>
<p>
  11. Trường hợp&nbsp;B&ecirc;n B chấm dứt&nbsp;Hợp đồng thu&ecirc; trước thời
  hạn th&igrave; phải c&oacute; đơn đề nghị gửi Ban&nbsp;Quản
  l&yacute;&nbsp;K&yacute; t&uacute;c x&aacute;&nbsp;trước&nbsp;02 th&aacute;ng;
</p>
<p>
  12. Khi c&oacute; nhiệm vụ ph&aacute;t sinh,&nbsp;B&ecirc;n A được quyền
  y&ecirc;u cầu chấm dứt&nbsp;Hợp đồng với&nbsp;B&ecirc;n B&nbsp;<em
    >(b&aacute;o trước tối thiểu 01 th&aacute;ng)</em
  >.
</p>
<p>
  <strong>Điều 8.</strong><strong>&nbsp;</strong
  ><strong>Cam kết thực hiện v&agrave; giải quyết tranh chấp</strong>
</p>
<p>
  a). C&aacute;c b&ecirc;n cam kết thực hiện đầy đủ c&aacute;c nội dung
  đ&atilde; ghi trong&nbsp;Hợp đồng n&agrave;y.
</p>
<p>
  b). Mọi tranh chấp li&ecirc;n quan hoặc ph&aacute;t sinh từ&nbsp;Hợp đồng
  n&agrave;y sẽ được b&agrave;n bạc giải quyết tr&ecirc;n tinh thần thương
  lượng, ho&agrave; giải giữa hai b&ecirc;n. Trường hợp kh&ocirc;ng ho&agrave;
  giải được th&igrave; đưa ra To&agrave; &aacute;n để giải quyết.
</p>
<p>
  <strong>Điều 9.</strong><strong>&nbsp;</strong
  ><strong>Điều khoản thi h&agrave;nh</strong>
</p>
<p>
  Hợp đồng n&agrave;y c&oacute; hiệu lực kể từ ng&agrave;y k&yacute;. Hợp đồng
  n&agrave;y&nbsp;gồm&nbsp;02 trang,&nbsp;được lập th&agrave;nh 02&nbsp;bản,
  c&oacute; gi&aacute; trị ph&aacute;p l&yacute;&nbsp;như&nbsp;nhau, mỗi
  b&ecirc;n giữ 01 bản&nbsp;để thực hiện. &nbsp;
</p>
<p>
  Hợp đồng n&agrave;y được coi l&agrave; tự thanh l&yacute;&nbsp;khi hết thời
  hạn&nbsp;Hợp đồng v&agrave; kh&ocirc;ng được gia hạn. &nbsp;
</p>
<p>&nbsp;</p>
<table style="height: 117px;" width="1026">
  <tbody>
    <tr>
      <td style="width: 504px;">
        <p style="text-align: center;"><strong>B&Ecirc;N A</strong></p>
        <p style="text-align: center;"><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p>&nbsp;</p>
      </td>
      <td style="width: 506px;">
        <p style="text-align: center;"><strong>B&Ecirc;N B</strong></p>
        <p style="text-align: left;">&nbsp;</p>
        <p style="text-align: left;">&nbsp;</p>
        <p style="text-align: left;">&nbsp;</p>
      </td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>

<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-6">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
            </li>
            <li class="breadcrumb-item active">Thống kê khoản nộp</li>
          </ol>
        </div> 
         <div class="col-md-6">          
          
                <button class="btn btn-success btn-sm" (click)="Excel()"
                style="float: right; ">
                <i class="fas fa-file-excel"></i> Xuất excel
            </button>
            </div>     
      </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="Fillter">
                <div class="row" style="padding-left:10px; padding-right:10px">
                    <div class="col-md-3">
                        <label class="col-md-10">Mã sinh viên:</label>
                        <input class="form-control" type="text" id="Ma_sv_ft" formControlName="Ma_sv_ft"
                            (keyup)="filterById()" [(ngModel)]="Ma_sv_ft" placeholder="Nhập mã sinh viên cần tìm">
                    </div>                  
                    <div class="col-md-3">
                        <label class="col-md-10">Loại khoản thu:</label>
                        <select name="" class="form-control" id="Loai_thu_ft" formControlName="Loai_thu_ft" [(ngModel)]="Loai_thu_ft"  (change)="filterById()">
                          <option value="0" selected>Chọn loại thu</option>
                          <option value="10">Tiền điện </option>
                          <option value="11">Tiền nước</option>
                          <option value="16">Tiền phòng</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Tháng năm:</label>
                        <input class="form-control" type="month" id="Thang_nam_ft" formControlName="Thang_nam_ft"
                        (change)="filterById()" [(ngModel)]="Thang_nam_ft">
                    </div>
                    <div class="col-md-3" id="hockyft" *ngIf="show">
                      <label class="col-md-10">Học kỳ:</label>
                      <select name="" class="form-control" id="Hoc_ky_ft" formControlName="Hoc_ky_ft" [(ngModel)]="Hoc_ky_ft"  (change)="filterById()">
                        <option value="0" selected>Chọn học kỳ</option>
                        <option value="1">Học kỳ I </option>
                        <option value="2">Học kỳ II</option>
                      </select>
                  </div>
                  <div class="col-md-3">
                    <label class="col-md-10">Trạng thái thu:</label>
                    <select name="" class="form-control" id="Trang_thai_ft" formControlName="Trang_thai_ft" [(ngModel)]="Trang_thai_ft"  (change)="filterById()">
                      <option value="0" selected>Chọn trạng thái</option>
                      <option value="true">Đã nộp </option>
                      <option value="false">Chưa nộp</option>
                    </select>
                </div>
                </div>
            </form>
        </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                    class="table-bordered dataTables_wrapper no-footer">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let p of dataTable">
                        <td>{{ p.ID_sv }}</td>
                        <td>{{ p.Ma_sv }}</td>
                        <td>{{ p.Ho_ten }}</td>
                        <td>{{ p.ID_co_so }}</td>
                        <td>{{ p.ID_toa_nha }}</td>
                        <td>{{ p.ID_phong }}</td>
                        <td>{{ p.Ten_phong}}</td>
                        <td>{{ p.Trang_thai}}</td>
                        <td>{{ p.Trang_thai_text}}</td>
                        <td>{{ p.Hinh_thuc_thu }}</td>
                        <td>{{ p.ID_khoan_thu }}</td>
                        <td>{{ p.ID_khoan_thu_text }}</td>
                        <td>{{ p.Hoc_ky }}</td>
                        <td>{{ p.Nam_hoc }}</td>
                        <td>{{ p.Thang }}</td>
                        <td>{{ p.Nam }}</td>
                        <td>{{ p.Thanh_tien|number }}</td>
                        <td>{{ p.Ngay_quy_dinh |date:'dd/MM/yyyy' }}</td>    
                        <td>{{ p.So_bien_lai }}</td>                   
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
  
<section class="content-header" style="padding: 5px !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item active">
            <i class="fas fa-home"></i> &nbsp;Trang chủ
          </li>
          <div class=""></div>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <div style="height: 90%;" class="small-box bg-info">
          <div class="inner">
            <h3>{{ SinhVienDangO }}</h3>
            <p>Sinh viên đang ở</p>
          </div>
          <div class="icon">
            <i class="fas fa-user-graduate"></i>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div style="height: 90%;" class="small-box bg-success">
          <div class="inner">
            <h3>{{ QuyDinhKhoanNopChuaNop }}</h3>
            <p>Sinh viên chưa nộp đủ</p>
          </div>
          <div class="icon">
            <i class="fas fa-file-invoice-dollar"></i>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div style="height: 90%;" class="small-box bg-warning">
          <div class="inner">
            <h3>{{ DangKyChuaDuyet }}</h3>
            <p>Hồ sơ đăng ký chưa duyệt</p>
          </div>
          <div class="icon">
            <i class="fas fa-file-signature"></i>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div style="height: 90%;" class="small-box bg-info">
          <div class="inner">
            <h3>{{ SinhVienChuaXepPhong }}</h3>
            <p>Sinh viên chưa xếp phòng</p>
          </div>
          <div class="icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div style="height: 90%;" class="small-box bg-secondary">
          <div class="inner">
            <h4>{{ SoChoTrong }}</h4>
            <p>Số chỗ trống</p>
          </div>
          <div class="icon">
            <i class="fas fa-chair"></i>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div style="height: 90%;" class="small-box bg-primary">
          <div class="inner">
            <h4>{{ TongSucChua }}</h4>
            <p>Tổng số chỗ</p>
          </div>
          <div class="icon">
            <i class="fas fa-building"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div style="float: right; margin-right: 3%;">
          <select
            style="
              width: 20px;
              height: 20px;
              z-index: 1000;
              position: absolute;
            "
            class="fas fa-bars"
            (change)="Change_BD1()"
            [(ngModel)]="khoaSlected"
          >
            <option [value]="0">Tất cả</option>
            <option *ngFor="let kh of data_khoaHoc" [value]="kh"
              >Khóa: {{ kh }}</option
            >
          </select>
        </div>
        <div
          id="chartContainer"
          style="
            height: 370px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
        ></div>
      </div>
      <div class="col-md-6">
        <div
          id="chartContainer3"
          style="
            height: 370px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
        ></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div style="float: right; margin-right: 3%;">
          <select
            style="
              width: 20px;
              height: 20px;
              z-index: 1000;
              position: absolute;
            "
            class="fas fa-bars"
            (change)="Change_BD2()"
            [(ngModel)]="BD2"
          >
            <option value="1">Biểu đồ giới tính theo toà nhà</option>
            <option value="2">Biểu đồ giới tính theo hệ đào tạo</option>
            <option value="3">Biểu đồ giới tính theo khoa đào tạo</option>
          </select>
        </div>
        <div
          id="chartContainer2"
          style="
            height: 370px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
        ></div>
      </div>
    </div>
    <div class="row">
      <div class="card" style="width: 100%; margin: 12px;">
        <div class="card-header" style="text-align: center;">
          <label style="font-size: 20px;"
            >Danh sách sinh viên nội trú có quyết định thôi học ngừng học</label
          >
        </div>
        <div
          class="table-responsive"
          style="max-height: 40vh; margin-bottom: 15px;"
        >
          <table class="table table-bordered" id="tableData">
            <thead>
              <th class="text-center">STT</th>
              <th class="text-center">Mã sinh viên</th>
              <th class="text-center">Họ tên</th>
              <th class="text-center">Ngày sinh</th>
              <th class="text-center">Nhà-phòng</th>
              <th class="text-center">Loại quyết định</th>
              <th class="text-center">Ngày quyết định</th>
            </thead>
            <tbody>
              <tr *ngFor="let p of data_thoihoc; let i = index; let odd = odd">
                <td>{{ i + 1 }}</td>
                <td>{{ p.Ma_sv }}</td>
                <td>{{ p.Ten_sv }}</td>
                <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                <td>{{ p.Ten_toa_nha }}-{{ p.Ten_phong }}</td>
                <td>{{ p.Ten_loai_QD }}</td>
                <td>{{ p.Ngay_QD | date: 'dd/MM/yyyy' }}</td>
              </tr>
              <tr *ngIf="data_thoihoc.length == 0">
                <td colspan="7" class="text-center">Không có kết quả</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

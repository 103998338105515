<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Đơn giá điện nước</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'lg', 'Add', '')"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <label>Loại khoản thu</label>
          <select
            class="form-control"
            [(ngModel)]="Loai_khoan_thu_filter"
            (change)="getList()"
          >
            <option value="">Tất cả</option>
            <option value="1">Tiền điện</option>
            <option value="2">Tiền nước</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Năm</label>
          <select
            class="form-control"
            [(ngModel)]="Nam_filter"
            (change)="getList()"
          >
            <option value="">Tất cả</option>
            <option *ngFor="let value of yearArr" value="{{ value }}">{{
              value
            }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <span
            ><input
              type="checkbox"
              [(ngModel)]="Tinh_theo_sinh_vien_filter"
              (change)="getList()"
            />&nbsp; <b>Tính theo số sinh viên</b></span
          >
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th>Loại khoản thu</th>
                <th>Từ số</th>
                <th>Đến số</th>
                <th>Đơn giá</th>
                <th>Tính theo số sinh viên</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable">
                  <td>
                    {{ p.Loai_khoan_thu == 1 ? 'Tiền điện' : 'Tiền nước' }}
                  </td>
                  <td>{{ p.Tu_so | number }}</td>
                  <td>{{ p.Den_so | number }}</td>
                  <td>{{ p.Don_gia | number }}</td>
                  <td class="text-center" *ngIf="p.Tinh_theo_sinh_vien">
                    <input type="checkbox" disabled checked />
                  </td>
                  <td class="text-center" *ngIf="!p.Tinh_theo_sinh_vien">
                    <input type="checkbox" disabled />
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="open(ThemModal, 'xs', 'Edit', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="open(DeleteModal, '', 'Delete', p)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-trash-alt" title="Xoá"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="5" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require">Loại khoản thu</label>
          <select
            class="form-control"
            formControlName="Loai_khoan_thu"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Loai_khoan_thu.errors
            }"
          >
            <option value="1">Tiền điện</option>
            <option value="2">Tiền nước</option>
          </select>
        </div>
        <div class="col-md-6">
          <span
            ><input
              type="checkbox"
              formControlName="Tinh_theo_sinh_vien"
            />&nbsp; <b>Tính theo số sinh viên</b></span
          >
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Từ số</label>
          <input
            class="form-control"
            placeholder="Nhập từ số"
            type="number"
            formControlName="Tu_so"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Tu_so.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Tu_so.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Tu_so.errors.min">
              Từ số không được nhỏ hơn 1!
            </div>
            <div *ngIf="checkvalue.Tu_so.errors.required">
              Từ số không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Đến số</label>
          <input
            class="form-control"
            placeholder="Nhập đến số"
            type="number"
            formControlName="Den_so"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Den_so.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Den_so.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Den_so.errors.min">
              Đến số không được nhỏ hơn 0!
            </div>
            <div *ngIf="checkvalue.Den_so.errors.required">
              Đến số không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Đơn giá</label>
          <input
            class="form-control"
            placeholder="Nhập đơn giá"
            type="number"
            formControlName="Don_gia"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Don_gia.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Don_gia.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Don_gia.errors.min">
              Đơn giá không được nhỏ hơn 0!
            </div>
            <div *ngIf="checkvalue.Don_gia.errors.required">
              Đơn giá không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Năm</label>
          <select
            formControlName="Nam"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Nam.errors }"
          >
            <option *ngFor="let value of yearArr" value="{{ value }}">{{
              value
            }}</option>
          </select>
          <div
            *ngIf="submitted && checkvalue.Nam.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Nam.errors.required">
              Năm không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

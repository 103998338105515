import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class GrantCardService {
    constructor(
        @Inject(AppConfig) private readonly appConfig: AppConfiguration,
        private router: Router,
        private http: HttpClient
    ) { }

    getList(token): Observable<any> {
        return this.http
            .get<any>(this.appConfig.UniDormAPI + 'GrantCard/GrantCardGetList', {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            }).pipe(map((z) => {return z;}));
    }
    Update(req: any, token): Observable<any> {
        return this.http
            .post<any>(this.appConfig.UniDormAPI + 'GrantCard/GrantCard_Update', req, {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            }).pipe(map((z) => {return z;}));
    } 
    Excel(token): Observable<any> {
        return this.http
            .get<any>(this.appConfig.UniDormAPI + 'GrantCard/GrantCardExcel', {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            }).pipe(map((z) => {return z;}));
    }
}

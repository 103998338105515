import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-don-gia-dien-nuoc',
  templateUrl: './don-gia-dien-nuoc.component.html',
  styleUrls: ['./don-gia-dien-nuoc.component.scss'],
})
export class DonGiaDienNuocComponent extends BaseComponent implements OnInit {
  Loai_khoan_thu_filter: any = 1;
  Tinh_theo_sinh_vien_filter: any = false;
  Nam_filter: any = new Date().getFullYear();
  titleModal: any = '';
  yearArr: any = [];
  checkInsert = true;
  ID: number;
  Insert = new FormGroup({
    Loai_khoan_thu: new FormControl(1),
    Tinh_theo_sinh_vien: new FormControl(false),
    Tu_so: new FormControl(0, [Validators.required, Validators.min(1)]),
    Den_so: new FormControl(0, [Validators.required, Validators.min(0)]),
    Don_gia: new FormControl(0, [Validators.required, Validators.min(0)]),
    Nam: new FormControl(new Date().getFullYear()),
  });
  ngOnInit(): void {
    let year = new Date().getFullYear() + 2;
    for (let i = 0; i < 5; i++) {
      this.yearArr.push(year);
      year -= 1;
    }
    this.getToken();
    this.getList();
    this.getCount();
  }
  getCount() {
    this.DonGiaDienNuocService.Count(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Loai_khoan_thu: this.Loai_khoan_thu_filter,
      Tinh_theo_sinh_vien: this.Tinh_theo_sinh_vien_filter,
      Nam: this.Nam_filter,
    };
    this.DonGiaDienNuocService.getList(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.router.navigate(['/admin']);
          this.spinner.hide();
        } else {
          this.dataTable = z.Data;
          this.DonGiaDienNuocService.CountFilter(
            dataFilter,
            this.Token
          ).subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 10000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.Loai_khoan_thu_filter = '';
    this.Tinh_theo_sinh_vien_filter = false;
    this.Nam_filter = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Loai_khoan_thu: this.Loai_khoan_thu_filter,
      Tinh_theo_sinh_vien: this.Tinh_theo_sinh_vien_filter,
      Nam: this.Nam_filter,
    };
    this.DonGiaDienNuocService.getList(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        } else {
          this.dataTable = z.Data;
          this.spinner.hide();
        }
      }
    );
  }
  open(content, sizm, Loai, Data) {
    this.ID = Data.ID_don_gia;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Add') {
      this.titleModal = 'Thêm mới đơn giá';
      this.checkInsert = true;
      this.Insert.get('Loai_khoan_thu').setValue(this.Loai_khoan_thu_filter);
      this.Insert.get('Tinh_theo_sinh_vien').setValue(
        this.Tinh_theo_sinh_vien_filter
      );
      this.Insert.get('Tu_so').setValue(0);
      this.Insert.get('Den_so').setValue(0);
      this.Insert.get('Don_gia').setValue(0);
      this.Insert.get('Nam').setValue(this.Nam_filter);
    } else {
      this.titleModal = 'Sửa đơn giá';
      this.checkInsert = false;
      this.Insert.get('Loai_khoan_thu').setValue(Data.Loai_khoan_thu);
      this.Insert.get('Tinh_theo_sinh_vien').setValue(Data.Tinh_theo_sinh_vien);
      this.Insert.get('Tu_so').setValue(Data.Tu_so);
      this.Insert.get('Den_so').setValue(Data.Den_so);
      this.Insert.get('Don_gia').setValue(Data.Don_gia);
      this.Insert.get('Nam').setValue(Data.Nam);
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    if (this.Insert.value.Tu_so >= this.Insert.value.Den_so) {
      this.toastr.warning('Từ số phải nhỏ hơn đến số!');
      return false;
    }
    let req = {
      ID_don_gia: this.ID,
      Loai_khoan_thu: this.Insert.value.Loai_khoan_thu,
      Tinh_theo_sinh_vien: this.Insert.value.Tinh_theo_sinh_vien,
      Tu_so: this.Insert.value.Tu_so,
      Den_so: this.Insert.value.Den_so,
      Don_gia: this.Insert.value.Don_gia,
      Nam: this.Insert.value.Nam,
    };
    if (this.checkInsert) {
      this.DonGiaDienNuocService.Insert(req, this.Token).subscribe(
        (res) => {
          if (res.Status == 2) {
            this.toastr.warning(res.Message);
          } else if (res.Status == 1) {
            this.toastr.success(res.Message);
            this.modalService.dismissAll('AddModal');
            this.getList();
            this.getCount();
          } else if (res.Status == 4) {
            this.toastr.error('Thêm mới thất bại!');
          } else {
            this.toastr.warning(res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    } else {
      this.DonGiaDienNuocService.Update(req, this.Token).subscribe(
        (res) => {
          if (res.Status == 2) {
            this.toastr.warning(res.Message);
          } else if (res.Status == 1) {
            this.toastr.success(res.Message);
            this.modalService.dismissAll('AddModal');
            this.getList();
          } else if (res.Status == 4) {
            this.toastr.error('Cập nhật thất bại!');
          } else {
            this.toastr.warning(res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  Delete() {
    this.DonGiaDienNuocService.Delete(this.ID, this.Token).subscribe(
      (res) => {
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Xoá thất bại!');
        } else {
          this.toastr.warning(res.Message);
        }
        this.modalService.dismissAll('');
        this.getCount();
        this.getList();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
}

<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-12">
        <div class="container" style="text-align: center; color: red;" *ngIf="!dataLoaiPhong">
          Đã hết phòng đăng kí ktx. Bạn vui lòng chờ đợt đăng ký tiếp theo !
        </div>
      </div>
      <div class="__blockButon col-md-12">
        <button class="__btnConfirm btn btn-success btn-sm" style="float: right; margin-right: 5px;"
          (click)="open(ConfirmRegisterModal, 'xs', 'ConfirmRegister', '')">
          <i class="fas fa-save"></i>&nbsp;Xác nhận
        </button>
        <button class="__btnConfirm btn btn-danger btn-sm" style="float: right; margin-right: 5px;"
          (click)="open(CancelRegisterModal, 'xs', 'ConfirmRegister', '')">
          <i class="fas fa-times"></i>&nbsp;Huỷ đăng ký
        </button>
        <!-- <button class="__btnSearchRoom btn btn-info btn-sm" style="float: right;margin-right: 5px; " (click)="open(InfoRoomModal, 'lg', 'ViewInfoRoom', '')">
              <i class="fas fa-search"></i>&nbsp;Xem thông tin phòng
            </button> -->
        <button id="__btnNotifi" class="btn btn-danger btn-sm" style="float: right; margin-right: 5px; display: none;"
          (click)="open(NotificationModal, 'xl', 'Notification', '')">
          <i class="fas fa-search"></i>&nbsp;Xem lịch sử
        </button>
      </div>
    </div>
  </div>
</section>

<!----------------------------------------------- THÔNG TIN SINH VIÊN ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
    <div class="card-body" style="padding: 0.5rem !important;">
      <div class="row">
        <div class="modal-body" style="padding-top: 0px;">
          <div class="__title-register col-md-12">
            THÔNG TIN ĐĂNG KÍ KTX &nbsp; <i class="fas fa-edit"></i>
          </div>
          <form class="form-horizontal" [formGroup]="Insert">
            <div class="cus-box">
              <div class="row">
                <div class="col-md-2">
                  <label class="col-md-10" style="margin-top: 10px;">Mã SV</label>
                  <input class="form-control" placeholder="Nhập mã sv" type="text" formControlName="Ma_sv" readonly />
                </div>
                <div class="col-md-2">
                  <label class="col-md-10" style="margin-top: 10px;">Họ tên</label>
                  <input class="form-control" placeholder="Nhập tên sv" type="text" formControlName="Ho_ten" readonly />
                </div>
                <div class="col-md-2">
                  <label class="col-md-10" style="margin-top: 10px;">Ngày sinh</label>
                  <input class="form-control" type="date" formControlName="Ngay_sinh" readonly />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10 require" style="margin-top: 10px; color: red;">Email</label>
                  <input class="form-control" placeholder="Nhập email" type="text" formControlName="Email" [ngClass]="{
                      'is-invalid': submitted && checkvalue.Email.errors
                    }" />
                  <div *ngIf="submitted && checkvalue.Email.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.Email.errors.required">
                      Email không được bỏ trống !
                    </div>
                    <div *ngIf="checkvalue.Email.errors.email">
                      Email không đúng định dạng !
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="col-md-10 require" style="margin-top: 10px; color: red;">Số điện thoại</label>
                  <input type="tel" class="form-control" placeholder="Nhập SĐT" formControlName="Dienthoai_canhan"
                    onkeyup="
                          if(this.value<0)
                          {
                              this.value = this.value * -1
                          }
                          else if(this.value == null || this.value == NaN || this.value == undefined || this.value == '' || this.value.toString().includes(',') || this.value.toString().includes('.') || this.value.toString().includes('e')) {
                              this.value = ''
                          }
                          " />
                  <div *ngIf="submitted && checkvalue.Dienthoai_canhan.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.Dienthoai_canhan.errors.required">
                      Số điện thoại không được bỏ trống !
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt10">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Đối tượng chính sách</label>
                  <ng-select *ngIf="ID_doi_tuong_TS_Insert > 0" formControlName="ID_doi_tuong_TS"
                    notFoundText="Không có kết quả" readonly>
                    <ng-option value="0">Chọn đối tượng chính sách</ng-option>
                    <ng-option *ngFor="let item of dataDoiTuongChinhSach" [value]="item.ID_dt">
                      {{ item.Ten_dt }}
                    </ng-option>
                  </ng-select>
                  <input class="form-control" type="text" *ngIf="!(ID_doi_tuong_TS_Insert > 0)" value="Không"
                    readonly />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Khu vực</label>
                  <ng-select *ngIf="ID_khu_vuc_TS_Insert > 0" formControlName="ID_khu_vuc_TS"
                    notFoundText="Không có kết quả">
                    <ng-option value="">Chọn khu vực</ng-option>
                    <ng-option *ngFor="let item of dataKhuVucTuyenSinh" [value]="item.ID_kv">{{ item.Ten_kv }}
                    </ng-option>
                  </ng-select>
                  <input class="form-control" type="text" *ngIf="!(ID_khu_vuc_TS_Insert > 0)" value="Không" readonly />
                </div>
                <div class="col-md-3">
                  <label class="col-md-10 require" style="margin-top: 10px; color: red;">Loại phòng</label>
                  <input type="text" class="form-control" *ngIf="!dataLoaiPhong" value="Đã hết phòng để đăng kí !!!"
                    readonly />
                  <div class="input-group" *ngIf="dataLoaiPhong">
                    <select class="form-control" formControlName="Loai_phong" [ngClass]="{
                        'is-invalid': submitted && checkvalue.Loai_phong.errors
                      }">
                      <option value="">Chọn loại phòng</option>
                      <option *ngFor="let p of dataLoaiPhong" value="{{ p.ID }}">{{ p.Ten_loai_phong }} - {{ p.Gia_phong
                        | number }}đ
                      </option>
                    </select>
                    <div class="input-group-append" ngbTooltip>
                      <span class="input-group-text btn-info" (click)="open(InfoRoomModal, 'lg', 'ViewInfoRoom', '')"><i
                          class="fas fa-search"></i> &nbsp; Xem</span>
                    </div>
                  </div>
                  <!-- <ng-select *ngIf="dataLoaiPhong" formControlName="Loai_phong" notFoundText="Không có kết quả"
                    [ngClass]="{ 'is-invalid': submitted && checkvalue.Loai_phong.errors }"
                  >
                    <ng-option value="">Chọn loại phòng</ng-option>
                    <ng-option *ngFor="let p of dataLoaiPhong" [value]=" p.ID ">{{ p.Ten_loai_phong }} - {{ p.Gia_phong | number }}đ
                    </ng-option>
                  </ng-select> -->
                  <div *ngIf="submitted && checkvalue.Loai_phong.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.Loai_phong.errors.required">
                      Loại phòng không được bỏ trống!
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Tình trạng sức khỏe</label>
                  <input class="form-control" placeholder="Nhập tình trạng sức khỏe" type="text"
                    formControlName="Tinh_trang_suc_khoe" />
                </div>
              </div>
              <div class="row mt10">
                <div class="col-md-12">
                  <label class="col-md-10">Ghi chú</label>
                  <textarea type="text" placeholder="Nhập ghi chú" class="form-control"
                    formControlName="Ghi_chu"></textarea>
                </div>
              </div>
              <div class="row mt10">
                <div class="col-md-9">
                  <label>
                    <input type="checkbox" formControlName="Ruler" class="mr-10 w-h-checkbox" />Tôi đồng ý chính sách
                    của Ban quản lý Ký túc xá quy
                    định</label>
                  <a class="cus-fs cus_a col-md-10" [routerLink]="['/dang-ky']"
                    (click)="open(RegisterRulesModal, 'lg', 'Aprove', '')">Xem nội dung chính sách</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-----------------------------------------------THÔNG TIN PHÒNG Ở ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
    <div class="card-body" style="padding: 0.5rem !important;">
      <div class="modal-body m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 style="font-weight: bold; font-size: 20px; margin: 0 auto;" class="modal-title h-title w-100">
          THÔNG TIN PHÒNG ĐĂNG KÍ
        </h4>
      </div>
      <div class="row">
        <div class="modal-body" style="padding-top: 0px; overflow-x: auto;">
          <form class="form-horizontal">
            <div class="cus-box">
              <div class="row">
                <div class="col-md-12">
                  <label class="col-md-10">
                    Loại phòng đăng ký :
                    <label class="__inforRegistered">{{
                      Ten_loai_phong_dang_ky
                      }}</label>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="col-md-10">
                    Trạng thái ở:
                    <label class="__inforRegistered">{{
                      Ten_trang_thai_o_dang_ky
                      }}</label>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="col-md-10" *ngIf="
                      Trang_thai_duyet_dang_ky == 1 && Trang_thai_o_dang_ky == 1
                    ">
                    Phòng ở:
                    <label class="__inforRegistered">{{
                      Ten_phong_dang_ky
                      }}</label>
                  </label>
                  <label class="col-md-10" *ngIf="
                      Trang_thai_duyet_dang_ky == 1 && Trang_thai_o_dang_ky == 0
                    ">
                    <label class="__inforRegistered">Đang chờ ban quản lý KTX duyệt...</label>
                  </label>
                  <label class="col-md-10" *ngIf="
                      Trang_thai_duyet_dang_ky == 2 && Trang_thai_o_dang_ky == 0
                    ">
                    <label class="__inforRegistered">Bạn đã bị từ chối duyệt</label>
                  </label>
                  <label class="col-md-10" *ngIf="Trang_thai_o_dang_ky == 2">
                    <label class="__inforRegistered">Bạn đã ra khỏi KTX</label>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!-----------------------------------------------CHÍNH SÁCH KTX ------------------------------------------------->
<ng-template #RegisterRulesModal let-modal>
  <div class="modal-content">
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
      <h4 style="font-weight: bold; font-size: 20px; margin: 15px auto;" class="modal-title h-title w-100">
        {{ titleModal }}
      </h4>
      <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
      <div [innerHTML]="dataFile"></div>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------XEM THÔNG TIN LOẠI PHÒNG Ở------------------------------------------------->
<ng-template #InfoRoomModal let-modal>
  <div class="modal-header">
    <h4 style="font-weight: bold; font-size: 20px;" class="modal-title h-title w-100">
      Thông tin mô tả loại phòng
    </h4>
  </div>
  <div class="modal-body" style="overflow-x: auto;">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10">Mã loại phòng : {{ Ma_loai_phong }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Tên loại phòng : {{ Ten_loai_phong }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Đơn giá phòng : {{ Gia_phong | number }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Đang ở : {{ Dang_o }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Còn trống : {{ Con_trong }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Sức chứa : {{ Suc_chua }}</label>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Mô tả : </label>
          <div [innerHTML]="Mo_ta" style="margin-left: 50px;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------XÁC NHẬN ĐĂNG KÍ------------------------------------------------->
<ng-template #ConfirmRegisterModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box-cf">
        <i class="far fa-check-circle" style="
            color: green;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "></i>
      </div>
      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
        ">
        Bạn có chắc chắn muốn đăng ký thông tin này ?
      </p>

      <div class="text-center">
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')">
          Hủy bỏ
        </button>
        <button type="button" class="btn btn-md btn-success" (click)="onSubmit()" style="margin-left: 1em;">
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #CancelRegisterModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box-cf">
        <i class="fas fa-times-circle" style="
            color: red;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "></i>
      </div>
      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
        ">
        Bạn có chắc chắn muốn huỷ đăng ký?
      </p>

      <div class="text-center">
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')">
          Hủy bỏ
        </button>
        <button type="button" class="btn btn-md btn-success" (click)="cancelRegister()" style="margin-left: 1em;">
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-----------------------------------------------THÔNG BÁO KHI ĐĂNG NHẬP------------------------------------------------->
<ng-template #NotificationModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 style="font-weight: bold; font-size: 25px;" class="modal-title h-title w-100">
      {{ titleModal }}
    </h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="icon-box" style="margin-top: 20px; border: 3px solid #17a2b8 !important;">
    <i class="fas fa-bullhorn" style="
        font-size: 46px;
        display: inline-block;
        margin-top: 13px;
        color: #17a2b8;
      "></i>
  </div>
  <div class="modal-body" style="margin: auto;">
    <div class="container" style="text-align: center;" *ngIf="!dataSinhVienDangKy">
      <h4>Không có sự kiện nào mới !</h4>
    </div>
    <div class="container" style="text-align: center;" *ngIf="dataSinhVienDangKy">
      <h4>Thông tin đăng kí !</h4>
    </div>
    <div class="tab-content">
      <div class="active tab-pane">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs __table-WebApp">
              <table class="table-bordered dataTables_wrapper no-footer" style="width: 100%;"
                *ngIf="dataSinhVienDangKy">
                <thead>
                  <tr>
                    <th style="width: 15%; text-align: center;">Họ và tên</th>
                    <th style="width: 10%; text-align: center;">Mã SV</th>
                    <th style="width: 15%; text-align: center;">
                      Ngày đăng ký
                    </th>
                    <th style="width: 10%; text-align: center;">Năm học</th>
                    <th style="width: 20%; text-align: center;">
                      Loại phòng đăng ký
                    </th>
                    <th style="width: 30%; text-align: center;">Phòng ở</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let svdk of dataSinhVienDangKy">
                    <th>{{ FullName_get }}</th>
                    <th>{{ UserName_get }}</th>
                    <th>{{ svdk.Create_date | date: 'dd-MM-yyyy hh:mm a' }}</th>
                    <th>{{ svdk.Nam_hoc }}</th>
                    <th>{{ svdk.Ten_loai_phong }}</th>
                    <th *ngIf="Trang_thai_duyet_dang_ky == 0">
                      Đang chờ ban quản lý KTX duyệt...
                    </th>
                    <th *ngIf="
                        Trang_thai_duyet_dang_ky == 1 &&
                        Trang_thai_o_dang_ky != 1
                      ">
                      Ban quản lý đã duyệt.Vui lòng chờ xếp phòng...
                    </th>
                    <th *ngIf="
                        Trang_thai_duyet_dang_ky == 1 &&
                        Trang_thai_o_dang_ky == 1
                      ">
                      {{ Ten_phong_dang_ky }}
                    </th>
                    <th *ngIf="Trang_thai_duyet_dang_ky == 2">
                      Ban quản lý đã từ chối duyệt đăng ký nội trú
                    </th>
                    <th *ngIf="Trang_thai_duyet_dang_ky == 3">
                      Ban quản lý hủy duyệt đăng ký nội trú
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----------------------------------------------- @MEDIA ------------------------------------------------->
    <div class="col-sm-12" class="__contentMedia">
      <div class="row __contentMedia" style="display: none;" *ngIf="dataSinhVienDangKy">
        <div class="col-sm-12" *ngFor="let svdk of dataSinhVienDangKy">
          <hr class="__hrMedia" />
          <div class="cus-box">
            <div class="row">
              <div class="col-md-12">
                <label class="col-md-10">
                  Tên sinh viên : {{ FullName_get }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="col-md-10"> Số thẻ: {{ svdk.So_the }} </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="col-md-10">
                  Ngày đăng kí:
                  {{ svdk.Create_date | date: 'dd-MM-yyyy hh:mm a' }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="col-md-10"> Năm học: {{ svdk.Nam_hoc }} </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="col-md-10">
                  Tên loại phòng: {{ svdk.Ten_loai_phong }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="col-md-10" *ngIf="Trang_thai_duyet_dang_ky == 0">
                  Đang chờ ban quản lý KTX duyệt...
                </label>
                <label class="col-md-10" *ngIf="
                    Trang_thai_duyet_dang_ky == 1 && Trang_thai_o_dang_ky == 1
                  ">
                  Phòng ở: {{ Ten_phong_dang_ky }}
                </label>
                <label class="col-md-10" *ngIf="
                    Trang_thai_duyet_dang_ky == 1 && Trang_thai_o_dang_ky != 1
                  ">
                  Ban quản lý đã duyệt.Vui lòng chờ xếp phòng...
                </label>
                <label class="col-md-10" *ngIf="Trang_thai_duyet_dang_ky == 2">
                  Ban quản lý đã từ chối duyệt đăng ký nội trú
                </label>
                <label class="col-md-10" *ngIf="Trang_thai_duyet_dang_ky == 3">
                  Ban quản lý hủy duyệt đăng ký nội trú
                </label>
              </div>
            </div>
          </div>
          <hr class="__hrMedia" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;">
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class RegulationObjectService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  GetAll(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'RegulationObject/ListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'RegulationObject/Insert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'RegulationObject/Update', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Delete(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'RegulationObject/Delete', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LoadKhoaHoc(ID_he: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'RegulationObject/LoadByHe?ID_he=' + ID_he,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

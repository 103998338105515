import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-statistical-money',
  templateUrl: './statistical-money.component.html',
  styleUrls: ['./statistical-money.component.scss'],
})
export class StatisticalMoneyComponent extends BaseComponent implements OnInit {
  ID_thu_chi_list: any = '';
  Ma_sv: any = '';
  Ho_ten: any = '';
  Nam: any = '';
  Thang: any = '';
  Token: string;
  dataKhoanNopKTX: any = [];
  dataRoom: any = [];
  Nam_arr: any = [];
  So_tien_con_phai_thu: any = 0;
  So_tien_con_phai_tra: any = 0;
  So_tien_da_nop: any = 0;
  So_tien_da_tra: any = 0;
  So_tien_phai_thu: any = 0;
  TatCa: string = '';
  Thieu_thua: any = 0;
  ID_co_so: any;
  ID_toa_nha: any;
  Ten_phong: any = '';
  Hoc_ky: any = '';
  Nam_hoc: any = '';
  Tu_ngay: any = '';
  Den_ngay: any = '';
  yeardate: any = [];
  Nam_hoc2: any = '2023-2024';
  public com: common;
  ngOnInit() {
    var yearNow = new Date().getFullYear();
    for (var i = 0; i < 9; i++) {
      let object = {
        value: yearNow,
        name: yearNow,
      };
      this.Nam_arr.push(object);
      yearNow--;
    }

    var Nam_hoc = new Date().getFullYear();
    for (var i = 0; i < 9; i++) {
      let object = {
        value: Nam_hoc + '-' + (Nam_hoc + 1),
        name: Nam_hoc,
      };
      this.yeardate.push(object);
      Nam_hoc--;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.KhoanNopKTX();
    this.getListCampus();
  }
  KhoanNopKTX() {
    this.spinner.show();
    this.StatisticalMoneyService.KhoanNopKTX(this.Token).subscribe(
      (z) => {
        z.Data.forEach((element, index) => {
          this.TatCa += element.ID_thu_chi + ',';
        });
        this.TatCa += '0';
        let input = '';
        if (this.ID_thu_chi_list == '') {
          input = this.TatCa;
        } else {
          input = this.ID_thu_chi_list;
        }
        let Nam_hoc;
        if (this.Thang > 8) {
          Nam_hoc =
            new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
        } else {
          Nam_hoc =
            new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
        }
        let dataFilter = {
          So_ban_ghi: this.pageSize,
          Trang: this.page,
          ID_thu_chi_list: input,
          Ma_sv: this.Ma_sv.trim(),
          Ho_ten: this.Ho_ten.trim(),
          Thieu_thua: this.Thieu_thua,
          ID_co_so_filter: this.ID_co_so,
          ID_toa_nha_filter: this.ID_toa_nha,
          Ten_phong: this.Ten_phong.trim(),
          Hoc_ky: 1,
          Nam_hoc: Nam_hoc,
          Thang: this.Thang,
        };
        this.StatisticalMoneyService.Count(dataFilter, this.Token).subscribe(
          (z) => {
            this.totalItem = z.Tong_so_ban_ghi;
          }
        );
        this.dataKhoanNopKTX = z.Data;
        this.getList();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 401) {
          this.dataTable = [];
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  ExportExcel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.spinner.show();
      this.page = 1;
      let input = '';
      if (this.ID_thu_chi_list == '') {
        input = this.TatCa;
      } else {
        input = this.ID_thu_chi_list;
      }
      let Nam_hoc;
      if (this.Thang > 8) {
        Nam_hoc =
          new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
      } else {
        Nam_hoc = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
      }
      let dataFilter = {
        So_ban_ghi: 1000000000,
        Trang: 1,
        ID_thu_chi_list: input,
        Ma_sv: this.Ma_sv.trim(),
        Ho_ten: this.Ho_ten.trim(),
        Thieu_thua: this.Thieu_thua,
        ID_co_so_filter: this.ID_co_so,
        ID_toa_nha_filter: this.ID_toa_nha,
        Ten_phong: this.Ten_phong.trim(),
        Hoc_ky: this.Hoc_ky,
        Thang: this.Thang,
        Nam_hoc: this.Nam_hoc,
        Tu_ngay: this.Tu_ngay,
        Den_ngay: this.Den_ngay,
      };
      this.StatisticalMoneyService.Export(dataFilter, this.Token).subscribe(
        (z) => {
          if (z.Status == 1) {
            this.exportService.exportExcelByte(z.FileData, 'ThongKeKhoanNop');
            this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
            this.spinner.hide();
            this.modalService.dismissAll('ExportExcel');
          } else {
            this.toastr.error(z.Message, 'Tác vụ thất bại');
            this.spinner.hide();
          }
        }
      );
    }
  }
  ExportExcelTienIch() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.spinner.show();
      let dataFilter = {
        Nam_hoc: this.Nam_hoc2,
      };
      this.StatisticalMoneyService.ExportTienIch(
        dataFilter,
        this.Token
      ).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(
            z.FileData,
            'ThongKeKhoanNopTienIch-' + this.Nam_hoc2
          );
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
          this.modalService.dismissAll('ExportTienIch');
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      });
    }
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    // this.So_tien_phai_thu = 0;
    // this.So_tien_da_thu = 0;
    // this.So_tien_con_phai_thu = 0;
    // this.So_tien_thu_thua = 0;
    let input = '';
    if (this.ID_thu_chi_list == '') {
      input = this.TatCa;
    } else {
      input = this.ID_thu_chi_list;
    }
    let Nam_hoc;
    let Hoc_ky = 1;
    if (this.Thang == '') {
      Nam_hoc = '';
      Hoc_ky = null;
    } else {
      if (this.Thang > 8) {
        Nam_hoc =
          new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
      } else {
        Nam_hoc = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
      }
    }

    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_thu_chi_list: input,
      Ma_sv: this.Ma_sv.trim(),
      Ho_ten: this.Ho_ten.trim(),
      Thieu_thua: this.Thieu_thua,
      ID_co_so_filter: this.ID_co_so,
      ID_toa_nha_filter: this.ID_toa_nha,
      Ten_phong: this.Ten_phong.trim(),
      Hoc_ky: this.Hoc_ky,
      Thang: this.Thang,
      Nam_hoc: this.Nam_hoc,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
    };
    this.StatisticalMoneyService.GetAll(dataFilter, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        // this.dataTable.forEach((element, index) => {
        //   this.So_tien_phai_thu += element.So_tien_nop;
        //   this.So_tien_da_thu += element.So_tien_da_nop;
        //   if (element.So_tien_nop >= element.So_tien_da_nop) {
        //     this.So_tien_con_phai_thu += element.Thua_thieu;
        //   } else {
        //     this.So_tien_thu_thua += Math.abs(element.Thua_thieu);
        //   }
        // });
        this.spinner.hide();
      }
    );
    this.StatisticalMoneyService.TotalFilter(dataFilter, this.Token).subscribe(
      (z) => {
        this.So_tien_con_phai_thu = z.So_tien_con_phai_thu;
        this.So_tien_con_phai_tra = z.So_tien_con_phai_tra;
        this.So_tien_da_nop = z.So_tien_da_nop;
        this.So_tien_da_tra = z.So_tien_da_tra;
        this.So_tien_phai_thu = z.So_tien_phai_thu;
      }
    );
    this.StatisticalMoneyService.CountFilter(dataFilter, this.Token).subscribe(
      (z) => {
        this.totalItemFilter = z.Tong_so_ban_ghi_filter;
        this.numberPage = this.createNumberPage(
          z.Tong_so_ban_ghi_filter,
          this.pageSize
        ).numberPage;
        this.arrNumberPage_chil = this.createNumberPage(
          z.Tong_so_ban_ghi_filter,
          this.pageSize
        ).arrNumberPage_chil;
        this.spinner.hide();
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.ID_thu_chi_list = '';
    this.Ma_sv = '';
    this.Ho_ten = '';
    this.Thieu_thua = '0';
    this.ID_co_so = '';
    this.ID_toa_nha = '';
    this.Ten_phong = '';
    this.Nam = '';
    this.Thang = '';
    this.Nam_hoc = '';
    this.Tu_ngay = '';
    this.Den_ngay = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let input = '';
    if (this.ID_thu_chi_list == '') {
      input = this.TatCa;
    } else {
      input = this.ID_thu_chi_list;
    }
    let Nam_hoc;
    if (this.Thang > 8) {
      Nam_hoc = new Date().getFullYear() + '-' + (new Date().getFullYear() + 1);
    } else {
      Nam_hoc = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_thu_chi_list: input,
      Ma_sv: this.Ma_sv.trim(),
      Ho_ten: this.Ho_ten.trim(),
      Thieu_thua: this.Thieu_thua,
      ID_co_so_filter: this.ID_co_so,
      ID_toa_nha_filter: this.ID_toa_nha,
      Ten_phong: this.Ten_phong.trim(),
      Hoc_ky: 1,
      Nam_hoc: Nam_hoc,
      Thang: this.Thang,
      Tu_ngay: this.Tu_ngay,
      Den_ngay: this.Den_ngay,
    };
    this.StatisticalMoneyService.GetAll(dataFilter, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.spinner.hide();
      }
    );
  }
  open(content, sizm) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  changeCampus() {
    this.getListBuilding(this.ID_co_so);
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QDKhoanNop;
    window.open(urlHelp, '_blank');
  }
}

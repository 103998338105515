<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <!-- <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
            </li>
            <li class="breadcrumb-item active">Xếp phòng</li>
          </ol> -->
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="navigate()"
          style="float: right;"
        >
          <i class="fas fa-users-cog"></i> Xếp phòng
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="getList()"
          style="float: right; margin-right: 10px; background-color: #4c6ef5;"
        >
          <i class="fas fa-search"></i>Tìm kiếm
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body cus-fs">
  <div class="card">
    <div class="card-body" style="padding: 10px !important;">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Ma_sv_filter"
              class="form-control cus-fs"
              placeholder="Nhập mã sv/CMND"
            />
          </div>
          <div class="col-md-4">
            <input
              type="text"
              (keyup.enter)="getList()"
              formControlName="Ho_ten_filter"
              class="form-control"
              placeholder="Nhập tên sv"
            />
          </div>
          <div class="col-md-4">
            <input
              (keyup.enter)="getList()"
              type="date"
              formControlName="NgaySinh_filter"
              class="form-control cus-fs"
              placeholder="Chọn ngày sinh"
            />
          </div>
          <!-- <div class="col-md-4">
            <select class="form-control cus-fs" formControlName="Thanh_toan_filter" (change)="getList()">
              <option value="">Chọn loại phòng đăng ký</option>
              <option value="1">Đã đóng tiền</option>
              <option value="2">Chưa đóng tiền</option>
            </select>
          </div> -->
          <!-- <div class="col-md-3">
            <select
              class="form-control cus-fs"
              formControlName="Doi_tuong_chinh_sach_filter"
            >
              <option value="" selected> Chọn loại đối tượng chính sách</option>
              <option *ngFor="let p of DSDoiTuong" [value]="p.Ten_dt">{{
                p.Ten_dt
              }}</option>
            </select>
          </div> -->
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-4">
            <select
              class="form-control cus-fs"
              formControlName="He_filter"
              (change)="getList()"
            >
              <option value="" selected> Chọn hệ</option>
              <option *ngFor="let p of DSHe" [value]="p.Ten_he">{{
                p.Ten_he
              }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <select
              class="form-control cus-fs"
              formControlName="Khoa_filter"
              (change)="getList()"
            >
              <option value="" selected> Chọn khoa</option>
              <option *ngFor="let p of DSKhoa" [value]="p.Ten_khoa">{{
                p.Ten_khoa
              }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control cus-fs"
              formControlName="Khoa_hoc_filter"
              placeholder="Nhập khóa học..."
              (keyup.enter)="getList()"
            />
          </div>

          <!-- <div class="col-md-3">
            <select class="form-control cus-fs" formControlName="Khu_vuc_filter">
              <option value="" selected> Chọn khu vực</option>
              <option *ngFor="let p of DSKhuVuc" [value]="p.Ten_kv">{{
                p.Ten_kv
              }}</option>
            </select>
          </div> -->
        </div>
        <div class="row" style="margin-top: 10px;">
          <!-- <div class="col-md-3">
                        <select class="form-control" formControlName="Nhap_hoc_filter">
                            <option value="" selected>Chọn đối tượng</option>
                            <option value="1">Sinh viên nhập học</option>
                            <option value="0">Sinh viên đang học</option>
                        </select>
                    </div> -->
          <!-- <div class="col-md-3">
                        <select class="form-control" formControlName="Chuyen_nganh_filter">
                            <option value="" selected> Chọn chuyên ngành</option>
                            <option *ngFor="let p of DSChuyenNganh" [value]="p.Chuyen_nganh">{{p.Chuyen_nganh}}</option>
                        </select>
                    </div> -->
          <!-- <div class="col-md-3">
                        <input type="text" formControlName="Ho_ten_filter" class="form-control"
                            placeholder="Nhập tên sv" />
                    </div> -->
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body cus-fs">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="tab-content">
            <div
              class="table-responsive"
              style="max-height: 60vh; margin-bottom: 15px; font-weight: 100;"
            >
              <table class="table table-sm table-striped">
                <thead>
                  <th>
                    <input
                      type="checkbox"
                      [checked]="isAllCheckBoxChecked()"
                      (change)="checkAllCheckBox($event)"
                      style="width: 1rem; height: 1rem;"
                      (change)="PushCheckRow()"
                    />
                  </th>
                  <th>Mã sinh viên</th>
                  <th>Họ và tên</th>
                  <th>Ngày sinh</th>
                  <th>Giới tính</th>
                  <th>Khu vực</th>
                  <th>Đối tượng chính sách</th>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                    <td>
                      <input
                        type="checkbox"
                        value="{{ p.ID_dang_ky }}"
                        [checked]="p.checked"
                        (change)="PushCheckRow()"
                        [(ngModel)]="dataTable[i].checked"
                        style="width: 1rem; height: 1rem;"
                      />
                    </td>

                    <td>{{ p.Ma_sv }}</td>
                    <td class="_w-s-nowrap">{{ p.Ho_ten }}</td>
                    <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ p.Gioi_tinh }}</td>
                    <td style="white-space: nowrap;">{{ p.Ten_kv_ts }}</td>
                    <td>{{ p.Ten_dt_chinh_sach }}</td>
                  </tr>
                  <tr *ngIf="dataTable.length == 0">
                    <td colspan="14" class="text-center">Không có kết quả</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p style="float: left; font-weight: bold;">
                  <!-- Tổng số {{ totalItem | number }} kết quả, đã lọc được
                  {{ totalItemFilter | number }} kết quả -->
                </p>
              </div>
              <div class="col-md-6">
                <nav aria-label="Page navigation example" style="float: right;">
                  <ul class="pagination">
                    <li class="page-item" [class.disabled]="page == 1">
                      <a class="page-link" (click)="changPage(1)">Đầu</a>
                    </li>
                    <li class="page-item" [class.disabled]="page == 1">
                      <a class="page-link" (click)="changPage('pre')">Trước</a>
                    </li>
                    <ng-container *ngIf="page > 4">
                      <li class="page-item"><a class="page-link">...</a></li>
                    </ng-container>
                    <ng-container *ngFor="let element of arrNumberPage_chil">
                      <li class="page-item" [class.active]="page == element">
                        <a class="page-link" (click)="changPage(element)">{{
                          element
                        }}</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="page < numberPage - 3">
                      <li class="page-item"><a class="page-link">...</a></li>
                    </ng-container>
                    <li class="page-item" [class.disabled]="page == numberPage">
                      <a class="page-link" (click)="changPage('next')">Sau</a>
                    </li>
                    <li class="page-item" [class.disabled]="page == numberPage">
                      <a class="page-link" (click)="changPage(numberPage)"
                        >Cuối</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="tab-content">
            <div
              class="table-responsive"
              style="max-height: 60vh; margin-bottom: 15px; font-weight: 100;"
            >
              <table class="table table-bordered table-sm table-striped">
                <thead>
                  <th>
                    <input
                      type="checkbox"
                      [checked]="isAllCheckBoxChecked2()"
                      (change)="checkAllCheckBox2($event)"
                      style="width: 1rem; height: 1rem;"
                      (change)="SpliceCheckRow()"
                    />
                  </th>
                  <th>Mã sinh viên</th>
                  <th>Họ và tên</th>
                  <th>Ngày sinh</th>
                  <th>Giới tính</th>
                  <th>Khu vực</th>
                  <th>Đối tượng chính sách</th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let p of dataTable2; let i = index; let odd = odd"
                  >
                    <td>
                      <input
                        type="checkbox"
                        value="{{ p.ID_dang_ky }}"
                        [checked]="p.checked"
                        [(ngModel)]="dataTable2[i].checked"
                        style="width: 1rem; height: 1rem;"
                        (change)="SpliceCheckRow()"
                      />
                    </td>
                    <td>{{ p.Ma_sv }}</td>
                    <td class="_w-s-nowrap">{{ p.Ho_ten }}</td>
                    <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ p.Gioi_tinh }}</td>
                    <td style="white-space: nowrap;">{{ p.Ten_kv_ts }}</td>
                    <td>{{ p.Ten_dt_chinh_sach }}</td>
                  </tr>
                  <tr *ngIf="dataTable2.length == 0">
                    <td colspan="14" class="text-center">Không có kết quả</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #SortRoomModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Xếp phòng</h4>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="ActionSave">
      <div class="cus-box">
        <div class="row">
          <div class="col-md-12">
            <label>&nbsp; Số tháng ở thực tế :</label>
            <input type="text" value="true" readonly formControlName="ThangO" />
          </div>
        </div>
      </div>
      <div class="cus-box" style="margin-top: 10px;">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="col-md-10">Ngày vào</label>
            <input
              type="date"
              class="form-control"
              formControlName="Ngay_vao"
              (change)="GetMonth()"
            />
          </div>
          <div class="col-md-12">
            <label class="col-md-10">Ngày kết thúc</label>
            <input
              type="date"
              class="form-control"
              formControlName="Ngay_ra"
              (change)="GetMonth()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onSubmit()"
        (keyup.enter)="onSubmit()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

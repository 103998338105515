<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Tài liệu</li>
        </ol>
      </div>
      <div class="col-md-6">
        <!-- <button
            class="btn btn-primary btn-sm"
            (click)="getList()"
            style="float: right; margin-right: 10px;"
          >
            <i class="fas fa-search"></i> Tìm kiếm
          </button> -->
        <!-- <button
            class="btn btn-success btn-sm"
            (click)="open(ExportExcelModal, 'xs')"
            style="float: right; margin-right: 10px;"
          >
            <i class="fas fa-file-excel"></i> Xuất excel
          </button> -->
        <button
          class="btn btn-primary btn-sm"
          (click)="open('', SaveModal, 'ADD', 'xl')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-plus"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4">
          <label>Tiêu đề tài liệu</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm tiêu đề tài liệu"
            [(ngModel)]="Tieu_de"
          />
        </div>
        <div class="col-sm-4">
          <label>Người tạo</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm tên người tạo"
            [(ngModel)]="Create_userName"
          />
        </div>
        <div class="col-sm-4">
          <label>Ngày tạo</label>
          <input
            (change)="getList()"
            class="form-control"
            type="date"
            [(ngModel)]="Create_date"
          />
        </div>
      </div>
      <hr />
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              <!-- Hiển thị
                <select (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                kết quả -->
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>STT</th>
                <th>Người tạo</th>
                <th>Ngày tạo</th>
                <th>Tiêu đề</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ p.Create_userName }}</td>
                  <td>{{ p.Create_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Tieu_de }}</td>
                  <td style="text-align: center; white-space: nowrap;">
                    <button
                      class="btn btn-sm btn-success"
                      style="margin-right: 3px;"
                      title="Sửa tài liệu"
                      (click)="open(p, SaveModal, 'EDIT', 'xl')"
                    >
                      <i class="fas fa-pen"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-success"
                      style="margin-right: 3px;"
                      title="Chia sẻ"
                      (click)="open(p, ShareModal, 'SHARE', 'xl')"
                    >
                      <i class="fas fa-share"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      title="Xóa tài liệu"
                      (click)="open(p, DeleteModal, 'DELETE', 'lg')"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataTable.length == 0">
                  <td colspan="13" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- <p style="float: left; font-weight: bold;">
                  Tổng số {{ totalItem | number }} kết quả, đã lọc được
                  {{ totalItemFilter | number }} kết quả
                </p> -->
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <ng-template #ExportExcelModal let-modal>
    <div class="modal-header">
      <h4 style="margin: auto;">Bạn có muốn xuất excel dữ liệu này không?</h4>
    </div>
  
    <div class="modal-footer">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="ExportExcel()"
        >
          <i class="fa fa-download" aria-hidden="true"></i> Tải xuống
        </button>
        <button
          type="button"
          style="float: right; margin-left: 1em;"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </ng-template> -->

<ng-template #SaveModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">{{ titleModal }}</h4>
  </div>

  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="SaveForm">
      <div class="container" style="font-size: 14px;">
        <div class="cus-box">
          <div class="row">
            <div class="col-md-6">
              <label class="col-md-10">Tiêu đề tài liệu</label>
              <input
                class="form-control"
                type="text"
                formControlName="Tieu_de"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Nội dung</label>
              <ckeditor [editor]="Editor" formControlName="Noi_dung"></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="container" *ngIf="isEditDoc" style="font-size: 14px;">
      <div class="cus-box">
        <div class="row">
          <div class="col-md-12">
            <button
              (click)="file.click()"
              class="btn btn-primary btn-sm"
              style="float: right; margin-top: 20px;"
            >
              <i class="fas fa-upload"></i> Thêm file
              <input
                [(ngModel)]="currentInput"
                (change)="onFileSelected($event)"
                type="file"
                #file
                hidden
              />
            </button>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-12"
            style="justify-content: center; margin-top: 20px;"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Mã file</th>
                  <th>Tên file</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of listFile; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ p.Ma_file }}</td>
                  <td>{{ p.Ten_file }}</td>
                  <td style="text-align: center;">
                    <button
                      class="btn btn-sm btn-primary"
                      title="Tải file"
                      (click)="Download(p)"
                    >
                      <i class="fas fa-download"></i>
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-sm btn-danger"
                      title="Xóa file"
                      (click)="deleteFile(p)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="save()">
        <i class="fa-plus-circle fas"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ShareModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">{{ titleModal }}</h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary btn-sm"
          (click)="openPeople('', PeopleModal, 'PEOPLE', 'xl')"
          style="float: right; margin-top: 20px;"
        >
          <i class="fas fa-plus"></i> Thêm chia sẻ
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="justify-content: center; margin-top: 20px;">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>STT</th>
              <th>Người được chia sẻ</th>
              <th>Ngày chia sẻ</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of listShare; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ p.UserName }}</td>
              <td>{{ p.Ngay_chia_se | date: 'dd/MM/yyyy' }}</td>
              <td style="text-align: center;">
                <button
                  class="btn btn-sm btn-danger"
                  title="Xóa file"
                  (click)="share(p)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success">
        <i class="fa-plus-circle fas"></i> Chia sẻ
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #DeleteModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Bạn có muốn xóa tài liệu này không?</h4>
  </div>

  <div style="text-align: center;" class="modal-body">
    <i class="trash-icon fa fa-trash" aria-hidden="true"></i>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="delete()">
        <i class="fas fa-trash"></i> Xóa
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #PeopleModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Chọn người chia sẻ</h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12" style="justify-content: center; margin-top: 20px;">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên người dùng</th>
              <th>Họ và tên</th>
              <th>Email</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of listPeople; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ p.UserName }}</td>
              <td>{{ p.FullName }}</td>
              <td>{{ p.Email }}</td>
              <td style="text-align: center;">
                <button
                  class="btn btn-sm btn-success"
                  title="Chia sẻ"
                  (click)="share(p)"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <!-- <button
          type="button"
          class="btn btn-md btn-success"
        >
          <i class="fa-plus-circle fas"></i> Chia sẻ
        </button> -->
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

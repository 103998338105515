import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DiemSinhVienService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/ListAll',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Excel(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/Excel',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCountFilter(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/CountFilter',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getViPham(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/ListViPham',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ChotDiem(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/ChotDiem',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MoDiem(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/MoDiem',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MoDiemSV(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/MoDiemSV',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  KhoaDiemSV(req,token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemSinhVien/KhoaDiemSV',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { Subject, from } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  AbstractControl,
} from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { AnyMxRecord } from 'dns';

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-listpeopledorm',
  templateUrl: './listpeopledorm.component.html',
  styleUrls: ['./listpeopledorm.component.scss'],
})
export class ListpeopledormComponent extends BaseComponent implements OnInit {
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  ID_ToaNha: any;
  ID_sv: any;
  ID_dangky: any;
  dataTable: any = [];
  dataCampus: any = [];
  dataBuildingChangeAfter: any = [];
  dataBuilding: any = [];
  dataDangKyQuaTrinh: any = [];
  dataRoom: any = [];
  closeResult: string;
  ClassData: any;
  ID_room: any;
  ID: any;
  dt: any;
  Sap_het_han: any;
  So_Ho_Chieu: any;
  Ngay_Het_Han_Visa: any;
  address: any;
  titleModal: string;
  default: any;
  Ngay_gia_han: any;
  So_thang_o: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkAction: boolean;
  NameTitle: string;
  Title: string = 'Đang tải ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Phong_Model: any;
  Ly_do: any = ' ';
  Check_ra: boolean;
  Ngay_ra_ktx: any;
  Title_mail: any;
  Content_mail: any;
  Mail_send: any;
  Mail_send_pass: any;
  Action = new FormGroup({
    So_Ho_Chieu: new FormControl(null),
    Ngay_Het_Han_Visa: new FormControl(null),
    Ngay_bat_dau: new FormControl(null, [Validators.required]),
    Ngay_ket_thuc: new FormControl(null, [Validators.required]),
    Ma_sv: new FormControl(null, []),
    Ho_ten: new FormControl(null, []),
    Ngay_sinh: new FormControl(null, []),
    Gioi_Tinh: new FormControl(2, []),
    ID_quoc_tich: new FormControl(0, []),
    Dan_toc: new FormControl(0, []),
    CMND: new FormControl(null, []),
    Ngay_cap_CMND: new FormControl(null, []),
    Noi_cap_CMND: new FormControl(0, []),
    Ton_giao: new FormControl(null, []),
    Dia_chi_tt: new FormControl(null, []),
    Dienthoai_canhan: new FormControl(null, []),
    Email: new FormControl(null, []),
    Ho_ten_cha: new FormControl(null, []),
    Namsinh_cha: new FormControl(null, []),
    Hoat_dong_XH_CT_cha: new FormControl(null, []),
    Ho_ten_me: new FormControl(null, []),
    Namsinh_me: new FormControl(null, []),
    Hoat_dong_XH_CT_me: new FormControl(null, []),
    NoiO_hiennay: new FormControl(null, []),
    So_dien_thoai_bo: new FormControl(null, []),
    So_dien_thoai_me: new FormControl(null, []),
  });
  Ngay_ra_phong_cu: any;
  ActionChangeRoom = new FormGroup({
    ID_Campus: new FormControl(null),
    ID_Building: new FormControl(null),
    ID_Floor: new FormControl(null),
    ID_Room: new FormControl(null, []),
    Ma_sv_dp: new FormControl(null),
    Ho_ten_dp: new FormControl(null),
  });
  id_phong_ktx: any;
  FillMaSV: any;
  FillHoTen: any;
  FillLoaiDongTien: any = '';
  FillTrangThaiO: any = '';
  FillQuocTich: any;
  FillPhong: any = '';
  FillThoiGianVao: any;
  FillThoiGianVisa: any;
  Ngay_ra_KTX: any;
  Ngay_vao_KTX: any;
  dataQuocTich: any;
  dataDanToc: any;
  FillLoaiPhong: any = '';
  FillPhongNamNu: any = '';
  dataTypeRoom: any;
  getNull = false;
  FillCoSo: any = '';
  FillToaNha: any = '';
  FillTang: any;
  dtReceipt: any;
  dataPrintf: any;
  dt_print: any = '123';
  dt_print2: any = '1234';
  dl_sv: any = '321';
  dl_room: any = [{ Ma_the: 0, Tien_cuoc: 0 }];
  dataBuildingChange: any;
  dataCampusChange: any;
  dataRoomChange: any;
  NameStudent: string;
  FillThoiGianRa: any;
  Loai_phong: any;
  Ghi_chu_black: string;
  Check_blacklist: boolean = false;
  dtRoomChange: any;
  totalItemFilter: any;
  lstTinh: any = [];
  public com: common;
  options = {};
  ID_loai_phong_cu: any;
  Fillter = new FormGroup({
    Hoc_ky_filter: new FormControl('1'),
    ID_tinh_tt_filter: new FormControl('0'),
    ID_dan_toc_filter: new FormControl('0'),
    Dienthoai_canhan_filter: new FormControl(null),
    Mail_filter: new FormControl(null),
    Ton_giao_filter: new FormControl(null),
    Nam_hoc_filter: new FormControl('2023-2024'),
  });
  Nam_hoc_arr: any = [];
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.getList();
    this.getCount();
    this.getListTypeRoom();
    this.getListCampusChange();
    this.getListCampus();
    this.GetDanToc();
    this.getTinh();
  }
  GetDanToc() {
    this.SystemService.Get_danh_sach_dan_toc(this.Token).subscribe((z) => {
      this.dataDanToc = z;
    });
  }
  getTinh() {
    this.SystemService.Get_tinh(this.Token).subscribe((z) => {
      this.lstTinh = z;
    });
  }
  getCount() {
    this.ListpeopledormService.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }
  getRequest() {
    return {
      ID_toa_nha: this.FillToaNha ? this.FillToaNha : '',
      ID_co_so: this.FillCoSo ? this.FillCoSo : '',
      Ten_phong: this.FillPhong ? this.FillPhong : '',
      ID_loai_phong: this.FillLoaiPhong ? this.FillLoaiPhong : '',
      Tang: this.FillTang ? this.FillTang : '',
      Ngay_bat_dau: this.FillThoiGianVao ? this.FillThoiGianVao : '',
      Ngay_ket_thuc: this.FillThoiGianRa ? this.FillThoiGianRa : '',
      Ma_sv: this.FillMaSV ? this.FillMaSV.trim() : '',
      Ho_ten: this.FillHoTen ? this.FillHoTen.trim() : '',
      ID_gioi_tinh: this.FillPhongNamNu,
      Sap_het_han: this.Sap_het_han,
      Nam_hoc: this.Fillter.value.Nam_hoc_filter,
      Hoc_ky: 0,
      ID_tinh_tt: this.Fillter.value.ID_tinh_tt_filter,
      Dienthoai_canhan: this.Fillter.value.Dienthoai_canhan_filter,
      Mail: this.Fillter.value.Mail_filter,
      ID_dan_toc: this.Fillter.value.ID_dan_toc_filter,
      Ton_giao: this.Fillter.value.Ton_giao_filter,
    };
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = this.getRequest();
    var a = this.com.getUserinfo();
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
      UserID: a.Info.UserID,
    };
    this.ListpeopledormService.getDormList(param, this.Token).subscribe((z) => {
      if (z.Status == 10) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
        this.router.navigate(['/admin']);
        this.spinner.hide();
      } else {
        this.dataTable = z.Data;
        this.ListpeopledormService.getCountFilter(param, this.Token).subscribe(
          (z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          }
        );
      }
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.FillToaNha = '';
    this.FillCoSo = '';
    this.FillPhong = '';
    this.FillLoaiPhong = '';
    this.FillTang = '';
    this.FillThoiGianVao = '';
    this.FillThoiGianRa = '';
    this.FillMaSV = '';
    this.FillHoTen = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = this.getRequest();
    var a = this.com.getUserinfo();
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
      UserID: a.Info.UserID,
    };
    this.ListpeopledormService.getDormList(param, this.Token).subscribe((z) => {
      if (z.Status == 10) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      } else {
        this.dataTable = z.Data;
        this.spinner.hide();
      }
    });
  }
  getListTypeRoom() {
    this.RoomcategoryService.getList(this.getNull, this.Token).subscribe(
      (z) => {
        this.dataTypeRoom = z.Data;
      }
    );
  } // lấy danh sách loại phòng
  Aprove() {
    this.dt.Ly_do = this.Ly_do;
    this.ListpeopledormService.UpdateRaKhoiKTX(this.dt, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('Approve');
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.getList();
      }
    );
  }
  getListCampus() {
    this.CampusService.getList(this.Token).subscribe((z) => {
      this.dataCampus = z.Data;
    });
  }
  GiaHan() {
    if (
      this.Ngay_gia_han == null ||
      this.Ngay_gia_han == undefined ||
      this.Ngay_gia_han == ''
    ) {
      this.toastr.warning('Bạn chưa chọn ngày gia hạn');
      return false;
    }
    if (this.So_thang_o <= 0) {
      this.toastr.warning('Số tháng ở phải lớn hơn 0!');
      return false;
    }
    if (this.Ngay_gia_han <= this.dataDangKyQuaTrinh.Den_ngay) {
      this.toastr.warning('Ngày gia hạn phải lớn hơn ngày kết thúc!');
      return false;
    }
    this.spinner.show();
    let req = {
      ID: this.dt.ID_dang_ky,
      Ngay_ket_thuc: this.Ngay_gia_han,
      So_thang_o: this.So_thang_o,
    };
    this.ListpeopledormService.GiaHanNgayRa(req, this.Token).subscribe((z) => {
      this.modalService.dismissAll('Approve');
      this.spinner.hide();
      if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.getList();
    });
  }
  MultiGiaHan() {
    let dtsv = this.dataTable.filter((x) => x.checked == true);
    this.spinner.show();
    if (dtsv.length < 1) {
      this.toastr.warning('Bạn chưa chọn sinh viên');
      return false;
    }
    if (
      this.Ngay_gia_han == null ||
      this.Ngay_gia_han == undefined ||
      this.Ngay_gia_han == ''
    ) {
      this.toastr.warning('Bạn chưa chọn ngày gia hạn');
      return false;
    }
    let req = {
      listSinhVien: dtsv,
      Ngay_ket_thuc: this.Ngay_gia_han,
    };
    this.ListpeopledormService.MultiGiaHanNgayRa(req, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('Approve');
        this.spinner.hide();
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.getList();
        } else {
          this.toastr.error(z.Message);
        }
      }
    );
  }
  changeGiaHan() {
    if (this.Ngay_gia_han <= this.dataDangKyQuaTrinh.Den_ngay) {
      this.toastr.warning('Ngày gia hạn phải lớn hơn ngày kết thúc!');
      return false;
    }
    let Ngay_ket_thuc = new Date(this.Ngay_gia_han);
    let Ngay_bat_dau = new Date(this.dataDangKyQuaTrinh.Tu_ngay);
    let SoThangO;

    if (Ngay_ket_thuc.getFullYear() > Ngay_bat_dau.getFullYear()) {
      SoThangO =
        (Ngay_ket_thuc.getFullYear() - Ngay_bat_dau.getFullYear()) * 12 -
        Ngay_bat_dau.getMonth() +
        Ngay_ket_thuc.getMonth();
    } else {
      SoThangO = Ngay_ket_thuc.getMonth() - Ngay_bat_dau.getMonth();
    }
    if (Ngay_bat_dau != Ngay_ket_thuc) {
      SoThangO -= 1;
      if (30 - Ngay_bat_dau.getDate() > 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
      if (30 - Ngay_ket_thuc.getDate() < 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
    }
    this.So_thang_o = SoThangO;
  }
  getListBuilding() {
    this.FillToaNha = '';
    this.BuildingService.getList(this.Token).subscribe((z) => {
      for (var i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Bao_phong) {
          z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
        }
      }
      this.dataBuilding = z.Data.filter((x) => {
        return x.ID_CoSo == this.FillCoSo;
      });
      if (this.dataBuilding.length > 0) {
        this.getListRoom();
      } else {
        this.FillPhong = '';
      }
    });
  }
  getListRoom() {
    this.FillPhong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data.filter((x) => {
        return x.ID_Toa_Nha == this.FillToaNha;
      });
    });
  }
  changeCampus() {
    this.getListBuilding();
  }
  changeRoom() {
    this.getListRoom();
  }
  getListCampusChange() {
    this.CampusService.getList(this.Token).subscribe((z) => {
      this.dataCampusChange = z.Data;
      this.getListBuildingChange();
    });
  } // list all Cơ sở đổi phòng
  getListBuildingChange() {
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuildingChange = z.Data;
      this.dataBuildingChangeAfter = z.Data;

      this.getListRoomChange();
    });
  } // list tòa nhà đổi phòng
  getListRoomChange() {
    this.RoomService.GetAllNonLock(this.Token).subscribe((z) => {
      for (var i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Bao_phong) {
          z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
        }
      }

      this.dtRoomChange = z.Data;
      this.dataRoomChange = z.Data;
    });
  } // list phòng đổi phòng
  changeCampusRep() {
    this.dataBuildingChange = this.dataBuildingChangeAfter.filter(
      (x) => x.ID_CoSo == this.ActionChangeRoom.value.ID_Campus
    );
    this.ActionChangeRoom.get('ID_Room').setValue('');
  }
  changeRoomRep() {
    this.dataRoomChange = this.dtRoomChange.filter((x) => {
      return (
        x.ID_Toa_Nha == this.ActionChangeRoom.value.ID_Building &&
        x.Loai_phong == this.dt.Phong_nam_nu &&
        x.Con_trong > 0
      );
    });
  }
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.onReset();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.onReset();
        }
      );
    this.Ngay_gia_han = '';
    this.dt = Data;
    this.So_thang_o = 0;
    let today = new Date();
    let day, month;
    var yyyy = today.getFullYear();
    if (today.getDate() < 10) {
      day = '0' + today.getDate();
    } else {
      day = today.getDate();
    }
    if (today.getMonth() + 1 < 10) {
      month = '0' + (today.getMonth() + 1);
    } else {
      month = today.getMonth() + 1;
    }
    var getday = yyyy + '-' + month + '-' + day;
    this.Ngay_ra_ktx = getday;
    this.Check_ra = false;
    this.Check_blacklist = false;
    this.Ly_do = ' ';
    this.Title_mail = ' ';
    this.Content_mail = ' ';
    this.Mail_send = ' ';
    this.Mail_send_pass = ' ';
    if (Loai == 'Edit') {
      const date = new Date().toLocaleString();
      this.titleModal = 'Thông tin sinh viên';
      this.check = Data.So_Ho_Chieu;
      this.checkAction = true;
      if (!Data.Ngay_Het_Han_Visa) {
        Data.Ngay_Het_Han_Visa = date.substring(0, 10);
      }
      if (!Data.Ngay_bat_dau) {
        Data.Ngay_bat_dau = date.substring(0, 10);
      }
      if (!Data.Ngay_ket_thuc) {
        Data.Ngay_ket_thuc = date.substring(0, 10);
      }
      this.Action.setValue({
        So_Ho_Chieu: Data.So_Ho_Chieu,
        Ngay_Het_Han_Visa:
          Data.Ngay_Het_Han_Visa != null
            ? Data.Ngay_Het_Han_Visa.substring(0, 10)
            : null,
        Ngay_bat_dau:
          Data.Ngay_bat_dau != null ? Data.Ngay_bat_dau.substring(0, 10) : null,
        Ngay_ket_thuc:
          Data.Ngay_ket_thuc != null
            ? Data.Ngay_ket_thuc.substring(0, 10)
            : null,
        Ma_sv: Data.Ma_sv,
        Ho_ten: Data.Ho_ten,
        Ngay_sinh:
          Data.Ngay_sinh != null ? Data.Ngay_sinh.substring(0, 10) : null,
        Gioi_Tinh: Data.Gioi_Tinh,
        ID_quoc_tich: Data.Quoc_tich,
        Dan_toc: Data.Dan_toc,
        CMND: Data.CMND,
        Ngay_cap_CMND:
          Data.Ngay_cap_CMND != null
            ? Data.Ngay_cap_CMND.substring(0, 10)
            : null,
        Noi_cap_CMND: Data.ID_noi_cap_CMND,
        Ton_giao: Data.Ton_giao,
        Dia_chi_tt: Data.Dia_chi_tt,
        Dienthoai_canhan: Data.Dienthoai_canhan,
        Email: Data.Email,
        Ho_ten_cha: Data.Ho_ten_cha,
        Namsinh_cha:
          Data.Namsinh_cha != null ? Data.Namsinh_cha.substring(0, 10) : null,
        Hoat_dong_XH_CT_cha: Data.Hoat_dong_XH_CT_cha,
        Ho_ten_me: Data.Ho_ten_me,
        Namsinh_me:
          Data.Namsinh_me != null ? Data.Namsinh_me.substring(0, 10) : null,
        Hoat_dong_XH_CT_me: Data.Hoat_dong_XH_CT_me,
        NoiO_hiennay: Data.NoiO_hiennay,
        So_dien_thoai_bo: Data.So_dien_thoai_bo,
        So_dien_thoai_me: Data.So_dien_thoai_me,
      });
    } else if (Loai == 'Add') {
      this.titleModal = 'Thêm mới thông tin sinh viên';
      this.checkAction = false;
      this.Action.reset();
    } else if (Loai == 'changeRoom') {
      this.dataRoomChange = [];
      this.ListpeopledormService.getDangKyQuaTrinhLast(
        Data.ID_dang_ky,
        this.Token
      ).subscribe((z) => {
        this.dataDangKyQuaTrinh = z;
      });
      (this.ID_dangky = Data.ID_dang_ky), (this.ID_sv = Data.ID_sv);
      this.Loai_phong = Data.ID_loai_phong;
      this.ID_loai_phong_cu = Data.ID_loai_phong;
      this.NameStudent = Data.Ho_ten;
      this.ID_phong = Data.ID_phong;

      this.ActionChangeRoom.setValue({
        ID_Campus: '',
        ID_Building: '',
        ID_Room: '',
        ID_Floor: '',
        Ma_sv_dp: Data.Ma_sv,
        Ho_ten_dp: Data.Ho_ten,
      });
      this.Ngay_ra_KTX = Data.Ngay_ket_thuc;
      this.Ngay_vao_KTX = Data.Ngay_bat_dau;
      this.id_phong_ktx = Data.ID_phong;
    } else if (Loai == 'RoomLead') {
      this.ID_room = Data.ID_phong;
      this.ID_dangky = Data.ID_dang_ky;
      this.Ngay_ra_KTX = Data.Ngay_ket_thuc;
    } else if (Loai == 'RaHan') {
      this.ListpeopledormService.getDangKyQuaTrinhLast(
        Data.ID_dang_ky,
        this.Token
      ).subscribe((z) => {
        this.dataDangKyQuaTrinh = z;
      });
    }
  }

  openprintf(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.onReset();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.onReset();
        }
      );
    this.dt = Data;
  }
  //check value
  get checkvalue() {
    return this.Action.controls;
  }

  onChangeRoom() {
    this.dt.ID_phong = this.ActionChangeRoom.value.ID_Room;
    this.dt.ID_Co_So = this.ActionChangeRoom.value.ID_Campus;
    this.dt.ID_Toa_Nha = this.ActionChangeRoom.value.ID_Building;
    this.dt.ID_Tang = this.ActionChangeRoom.value.ID_Floor;
    let dtRoo = this.dataRoomChange.filter((x) => {
      return x.ID_Phong == this.ActionChangeRoom.value.ID_Room;
    });
    if (dtRoo.length > 0) {
      if (this.Ngay_ra_phong_cu == null || this.Ngay_ra_phong_cu == '') {
        this.toastr.warning('Bạn chưa chọn ngày đổi !');
      } else {
        //let dtsv = this.dataTable.filter((x) => x.checked == true);
        let req = {
          Students: [this.dt],
          IDPhong: this.ActionChangeRoom.value.ID_Room,
          IDPhongDi: this.ID_phong,
          Ngay_bat_dau: this.Ngay_ra_phong_cu,
        };
        this.ListpeopledormService.UpdateRoom(req, this.Token).subscribe(
          (z) => {
            if (z.Status == 1) {
              this.toastr.success(z.Message);
              this.modalService.dismissAll('ChangeClassModal');
            } else {
              this.toastr.error(z.Message);
            }
            this.getList();
          },
          (err) => {
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            } else if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
            this.modalService.dismissAll('ChangeClassModal');
          }
        );
      }
    } else {
      this.toastr.warning('Chọn phòng !');
    }
  } // đổi phòng ktx

  saveRoomHistory() {
    this.dt.ID_phong = this.ActionChangeRoom.value.ID_Room;
    if (this.id_phong_ktx != this.dt.ID_phong) {
      this.ListAccessHistory.Insert(this.dt, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success('Cập nhật thành công');
        } else {
          this.toastr.error(z.Message);
        }
        this.ActionChangeRoom.reset();
      });
    }
    this.modalService.dismissAll('ChangeClassModal');

    this.getList();
  } // cập nhật thông tin vào ra phòng ktx

  onSubmit() {
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.actionUpdate();
  }
  onReset() {
    this.submitted = false;
    this.Action.reset();
    this.dataBuilding = [];
    this.dataRoom = [];
    this.dataCampus = [];
    this.ActionChangeRoom.reset();
  }
  // actionUpdate
  actionUpdate() {
    // this.dt.So_Ho_Chieu = this.Action.value.So_Ho_Chieu;
    // this.dt.Ngay_Het_Han_Visa = this.Action.value.Ngay_Het_Han_Visa;
    // this.dt.Ngay_bat_dau = this.Action.value.Ngay_bat_dau;
    // this.dt.Ngay_ket_thuc = this.Action.value.Ngay_ket_thuc;
    this.dt.So_Ho_Chieu = this.Action.value.So_Ho_Chieu;
    this.dt.Ngay_Het_Han_Visa = this.Action.value.Ngay_Het_Han_Visa;
    this.dt.Dienthoai_canhan = this.Action.value.Dienthoai_canhan;
    this.dt.Email = this.Action.value.Email;
    this.dt.Dia_chi_tt = this.Action.value.Dia_chi_tt;
    if (Date.parse(this.dt.Ngay_bat_dau) > Date.parse(this.dt.Ngay_ket_thuc)) {
      this.toastr.warning('Ngày ra KTX không thể nhỏ hơn ngày vào KTX');
    } else {
      this.ListpeopledormService.UpdateSV(this.dt, this.Token).subscribe(
        (z) => {
          if (z.Status == 2) {
            this.toastr.error('Cập nhật thất bại!');
          } else if (z.Status == 9) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
            this.modalService.dismissAll('ThemModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList();
          }
          this.Action.reset();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          } else if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  } // cập nhật số hộ chiếu quốc tịch đối với sinh viên nước ngoài
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }

  checkHanraKTX(datetime) {
    let dateParts = datetime.split('-');
    let day = parseInt(dateParts[2]);
    let month = parseInt(dateParts[1]) - 1;
    let year = parseInt(dateParts[0]);
    let someday = new Date(year, month, day);
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    if (today > someday) {
      return true;
    }
    return false;
  }

  AproveChange() {
    if (this.Ngay_ra_KTX != null) {
      if (this.checkHanraKTX(this.Ngay_ra_KTX) == false) {
        this.ListpeopledormService.UpdateLeadRoom(
          this.ID_dangky,
          this.ID_room,
          this.Token
        ).subscribe((z) => {
          this.modalService.dismissAll('AddModal');
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }

          this.getList();
        });
      } else {
        this.toastr.error('Sinh viên này đã hết hạn ở KTX kiểm tra lại !');
      }
    } else {
      this.toastr.warning('Ngày ra ký túc xá đang trống xin kiểm tra lại !');
    }
  } // kiểm tra hạn ra ktx

  Print() {
    this.dt_print = this.dt;
    setTimeout(() => {
      let printContents = document.getElementById('print-section').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 200);
    this.modalService.dismissAll('DeleteModal');
  }
  ChoRa() {
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('ChoRaktx');
      this.toastr.warning('Bạn vui lòng tích chọn sinh viên để cho ra !');
    } else {
      var length = dt.length;
      var dtstring = dt.map((x) => x.ID_dang_ky);
      let data = {
        lstID_dang_ky: dtstring,
        Ly_do: this.Ly_do,
        Ngay_cho_ra: this.Ngay_ra_ktx,
        Ra_truoc_han: this.Check_ra,
        Danh_sach_den: this.Check_blacklist,
      };
      this.spinner.show();
      this.ListpeopledormService.ChoRaKTX(data, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.spinner.hide();
          this.toastr.success(z.Message);
        } else {
          this.spinner.hide();

          this.toastr.error(z.Message);
        }
        this.modalService.dismissAll('ChoRaktx');
        this.getList();
      });
    }
  } // Cho ra đúng thời hạn
  SendMail() {
    this.spinner.show();
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('Guimail');
      this.toastr.warning(
        'Bạn vui lòng tích chọn sinh viên để gửi thông báo !'
      );
      this.spinner.hide();
    } else {
      var length = dt.length;
      var dtstring = dt.map((x) => x.Email);
      let data = {
        lstEmail: dtstring,
        Title_mail: this.Title_mail,
        Content_mail: this.Content_mail,
        Mail_send: this.Mail_send,
        Mail_send_pass: this.Mail_send_pass,
      };
      this.ListpeopledormService.Sendmail(data, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.spinner.hide();
      });
    }
  }
  ExportExcelList() {
    var dt = this.dataTable.filter((x) => x.checked == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('ExportExcel');
      this.toastr.warning('Bạn vui lòng tích chọn sinh viên để xuất Excel !');
    } else {
      var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
      if (r) {
        this.spinner.show();
        var dtstring = this.GetStringID(dt);
        let data = {
          listString: dtstring,
        };
        this.ListpeopledormService.ExportDanhSachO(data, this.Token).subscribe(
          (z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(z.FileData, 'DanhSachO');
              this.toastr.success(
                'File đã được tải xuống',
                'Tác vụ thành công'
              );
              this.spinner.hide();
              this.modalService.dismissAll('ExportExcel');
            } else {
              this.toastr.error(z.Message, 'Tác vụ thất bại');
              this.spinner.hide();
            }
          }
        );
      }
    }
  } // Xuất excel
  GetStringID(m) {
    var rs = '';
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_dang_ky;
      } else {
        rs += ',' + m[k].ID_dang_ky;
      }
    }
    return rs;
  } // get string id dang ky
  GetData_Print2(Data) {
    this.SystemService.Get_tinh(this.Token).subscribe((z) => {
      let a = Data.ID_noi_cap_CMND;
      Data.ID_noi_cap_CMND = '';
      for (let j = 0; j < z.length; j++) {
        if (z[j].ID_tinh == parseInt(a)) {
          Data.ID_noi_cap_CMND = z[j].Ten_tinh;
        }
      }
      Data.ToaNha_Phong = Data.ToaNha_Phong.slice(
        0,
        Data.ToaNha_Phong.indexOf('-')
      );
    });
    let a = [];

    this.RegisterDormService.getSVbyMa(Data.Ma_sv, this.Token).subscribe(
      (z) => {
        this.dl_sv = z.Data[0];
        this.GrantCardService.getList(this.Token).subscribe((z) => {
          a = z.Data;
          let Ma_the_ktx = a.filter((a) => a.ID_sv == Data.ID_sv)[0].So_the;
          let tiencuoc = this.dataTypeRoom.filter(
            (a) => a.ID == Data.ID_loai_phong
          )[0].Tien_cuoc;
          this.dl_room = [];
          this.dl_room.push({ Ma_the: Ma_the_ktx, Tien_cuoc: tiencuoc });
        });
      }
    );
    this.Print2(Data);
  }
  Print2(Data) {
    this.dt_print2 = Data;
    setTimeout(() => {
      let printContents = document.getElementById('print-section2').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 200);
    this.modalService.dismissAll('DeleteModal');
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_DanhSachO;
    window.open(urlHelp, '_blank');
  }
}


export class RequestPhong {
    Con_trong: number;
    Dang_o: number;
    Ghi_chu: string;
    Gia_tien: string;
    ID_Co_So: number;
    ID_Phong: number;
    ID_Toa_Nha: number;
    ID_loai_phong: number;
    Khoa_phong: boolean;
    Loai_phong: boolean;
    Ma_phong: string;
    Suc_chua: number;
    Tang: string;
    Ten_co_so: string;
    Ten_loai_phong: string;
    Ten_phong: string;
    Ten_toa_nha: string;
    Thu_tu: number;
    dsID?: string;
}



import { Component, OnInit, ViewChild } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { forkJoin, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, timeout } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { of } from 'rxjs';
import { RequestStudent } from 'src/app/Models/input.model/RequestStudent';
@Component({
  selector: 'app-plan-room',
  templateUrl: './plan-room.component.html',
  styleUrls: ['./plan-room.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // styles: ['.styleboxroomSuccess{background-color: #28a745;color:#fffff;width: 75px;height:75px;border:1px solid black;text-align:center;color:#fff}.styleboxroomWarning{background-color: #ffc107;width: 75px;height:75px;border:1px solid black;text-align:center}.styleboxroomErorr{background-color: #dc3545;width: 75px;height:75px;border:1px solid black;text-align:center;color:#fff}.title{text-align:center}']
})
export class PlanRoomComponent extends BaseComponent implements OnInit {
  listFloor: any;
  dataSoPhong: any;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  DSLoaiPhong: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  dataCampusChangeAfter: any = [];
  dataBuildingChangeAfter: any = [];
  closeResult: string;
  ClassData: any;
  ID_phong: any;
  ID_co_so: any;
  ID_toa_nha: any;
  Ma_phong: any;
  Ten_phong: any;
  TenPhong: any;
  Loai_phong: any;
  ID_loai_phong: any;
  Khoa_phong: any;
  Tang: any;
  Suc_chua: any;
  Ngay_gia_han: any;
  dataDangKyQuaTrinh: any = [];
  Ghi_chu: any;
  ID_toa_nha_ft: any = '';
  Ten_phong_ft: any;
  gioi_tinh_ft: any = '';
  loai_phong_ft: any = '';
  ID_co_so_ft: any = '';
  Tang_ft: any = '';
  titleModal: string;
  default: any;
  viewtable: any;
  DataSelect: any;
  filter: any = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách phòng...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  ds_toanha: any;
  tentoanha: string = '';
  arr_all: any = [];
  dtOptions: any;
  dataallsv: any;
  dataroom: any;
  So_thang_o: any;
  Check_ra: boolean;
  Check_blacklist: boolean = false;
  Ly_do: string;
  Ngay_ra_ktx: any;
  Ngay_ra_phong_cu: any;
  modalRef: any;
  searchString: any;
  Student: RequestStudent;
  Fillter = new FormGroup({
    Tang_ft: new FormControl(null),
    ID_toa_nha_ft: new FormControl(''),
    Tang: new FormControl(''),
    Ten_phong_ft: new FormControl(null),
    loai_phong_ft: new FormControl(null),
    gioi_tinh_ft: new FormControl(null),
    ID_co_so_ft: new FormControl(''),
  });
  isShowManualTab = false;
  FillterSV = new FormGroup({
    code_filter: new FormControl(null),
    name_filter: new FormControl(null),
    khoa_filter: new FormControl(null),
    lop_filter: new FormControl(null),
    doituong_filter: new FormControl(null),
    NguyenVong_filter: new FormControl(null),
    NgaySinh_filter: new FormControl(null),
    Loai_phong_filter: new FormControl(''),
    Thanh_toan_filter: new FormControl(''),
    Gioi_tinh_filter: new FormControl(1),
    Doi_tuong_chinh_sach_filter: new FormControl(''),
    He_filter: new FormControl(''),
    Khoa_filter: new FormControl(''),
    Khoa_hoc_filter: new FormControl(null),
    Chuyen_nganh_filter: new FormControl(''),
    Khu_vuc_filter: new FormControl(''),
    Nhap_hoc_filter: new FormControl(''),
    Ma_sv_filter: new FormControl(''),
    Ho_ten_filter: new FormControl(''),
  });
  ActionChangeRoom = new FormGroup({
    ID_Campus: new FormControl(null),
    ID_Building: new FormControl(null),
    ID_Floor: new FormControl(null),
    ID_Room: new FormControl(null, []),
    Ma_sv_dp: new FormControl(null),
    Ho_ten_dp: new FormControl(null),
  });
  Action = new FormGroup({
    So_Ho_Chieu: new FormControl(null),
    Ngay_Het_Han_Visa: new FormControl(null),
    Ngay_bat_dau: new FormControl(null, [Validators.required]),
    Ngay_ket_thuc: new FormControl(null, [Validators.required]),
    Ma_sv: new FormControl(null, []),
    Ho_ten: new FormControl(null, []),
    Ngay_sinh: new FormControl(null, []),
    Gioi_Tinh: new FormControl(2, []),
    ID_quoc_tich: new FormControl(0, []),
    Dan_toc: new FormControl(0, []),
    CMND: new FormControl(null, []),
    Ngay_cap_CMND: new FormControl(null, []),
    Noi_cap_CMND: new FormControl(0, []),
    Ton_giao: new FormControl(null, []),
    Dia_chi_tt: new FormControl(null, []),
    Dienthoai_canhan: new FormControl(null, []),
    Email: new FormControl(null, []),
    Ho_ten_cha: new FormControl(null, []),
    Namsinh_cha: new FormControl(null, []),
    Hoat_dong_XH_CT_cha: new FormControl(null, []),
    Ho_ten_me: new FormControl(null, []),
    Namsinh_me: new FormControl(null, []),
    Hoat_dong_XH_CT_me: new FormControl(null, []),
    NoiO_hiennay: new FormControl(null, []),
    So_dien_thoai_bo: new FormControl(null, []),
    So_dien_thoai_me: new FormControl(null, []),
  });
  DSKhuVuc: any;
  public com: common;
  DSChuyenNganh: any;
  DSHe: any;
  DSKhoa: any;
  DSDoiTuong: any;
  dtPhong: any;
  dataCampusChange: any;
  dtRoomChange: any;
  dataRoomChange: any;
  ID_loai_phong_cu: number;
  CheckDay: any;
  Check_con_trong: number;
  dataThongke;
  tenPhongPrint: any;
  currentInput: any;
  StudentImage: any;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.LoadCoSo();
    this.CheckDay = new Date();
    this.loadFloor(0);
  }

  loadFloor(id_building: any) {
    this.RoomService.getListFloor(id_building, this.Token).subscribe(
      (res: any) => {
        this.listFloor = res.Data;
      }
    );
  }

  changeBuilding() {
    this.loadFloor(
      (this.Fillter.value.ID_toa_nha_ft =
        this.Fillter.value.ID_toa_nha_ft > 0
          ? this.Fillter.value.ID_toa_nha_ft
          : 0)
    );
  }
  showManualTab() {
    this.isShowManualTab = true;
  }
  cosochange() {
    let ID_coso2 = this.Fillter.controls.ID_co_so_ft.value;
    if (ID_coso2 != '') {
      this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
        return pa.ID_CoSo == ID_coso2;
      });
    } else {
      this.DSToaNha2 = this.DSToaNha;
    }
  }
  getList(token) {
    this.spinner.show();
    this.RoomService.GetAll(token).subscribe(
      (z) => {
        // var tongsucchua = 0;
        // var dasudung = 0;
        // var controng = 0;
        for (var i = 0; i < z.Data.length; i++) {
          if (z.Data[i].Bao_phong) {
            z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
          }
        }
        this.dataTable = z.Data;
        this.spinner.hide();
        this.Find();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  ThongKePhongTrong() {
    let req = this.Fillter.controls.ID_toa_nha_ft.value;
    if (this.Fillter.controls.ID_toa_nha_ft.value == '') {
      req = '0';
    }
    this.RoomService.ThongKePhongTrong(req, this.Token).subscribe(
      (z) => {
        this.dataThongke = z;
      },
      (err) => {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
    );
  }
  Find() {
    if (
      this.Fillter.value.Tang > 0 &&
      !(this.Fillter.controls.ID_toa_nha_ft.value > 0)
    ) {
      this.toastr.warning('Bạn chưa chọn Tòa nhà');
      return false;
    }
    let dstang = [];
    this.ThongKePhongTrong();
    this.tentoanha = '';
    this.arr_all = [];
    let idtn = this.Fillter.controls.ID_toa_nha_ft.value;
    if (idtn != '') {
      var arr_toanha = [];
      let dl_toanha = this.dataTable.filter((a) => a.ID_Toa_Nha == idtn);
      this.tentoanha = '';
      if (this.Fillter.value.Tang > 0) {
        dstang.push(this.Fillter.value.Tang);
      } else {
        dstang = dl_toanha
          .map((item) => item.Tang)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((a, b) => parseFloat(b) - parseFloat(a));
      }
      for (var i = 0; i < dstang.length; i++) {
        var arr_tang = [];
        let dl_tang = dl_toanha.filter((a) => a.Tang == dstang[i]);
        dl_tang.sort((n1, n2) => {
          if (n1.Thu_tu > n2.Thu_tu) {
            return 1;
          }
          if (n1.Thu_tu < n2.Thu_tu) {
            return -1;
          }
          return 0;
        });
        arr_tang = dl_tang;
        arr_toanha.push(arr_tang);
        arr_tang = [];
      }
      this.arr_all.push(arr_toanha);
    } else {
      var fil = [];
      let A = this.dataTable
        .map((item) => item.ID_Toa_Nha)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => parseFloat(b) - parseFloat(a));
      let B = this.DSToaNha2.map((item) => item.ID_ToaNha)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => parseFloat(b) - parseFloat(a));
      for (let i = 0; i < B.length; i++) {
        for (let j = 0; j < A.length; j++) {
          if (B[i] == A[j]) {
            fil.push(B[i]);
          }
        }
      }
      this.ds_toanha = fil;
      for (var k = 0; k < this.ds_toanha.length; k++) {
        var arr_toanha = [];
        let dl_toanha = this.dataTable.filter(
          (a) => a.ID_Toa_Nha == this.ds_toanha[k]
        );
        if (this.Fillter.value.Tang > 0) {
          dstang.push(this.Fillter.value.Tang);
        } else {
          dstang = dl_toanha
            .map((item) => item.Tang)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => parseFloat(b) - parseFloat(a));
        }
        for (var i = 0; i < dstang.length; i++) {
          var arr_tang = [];
          let dl_tang = dl_toanha.filter((a) => a.Tang == dstang[i]);

          dl_tang.sort((n1, n2) => {
            if (n1.Thu_tu > n2.Thu_tu) {
              return 1;
            }
            if (n1.Thu_tu < n2.Thu_tu) {
              return -1;
            }
            return 0;
          });
          arr_toanha.push(dl_tang);
          dl_tang = [];
        }
        this.arr_all.push(arr_toanha);
      }
    }
  }

  ViewRoom(Data) {
    alert('ID_phong:' + Data.ID_Phong);
  }

  GiaHan() {
    if (
      this.Ngay_gia_han == null ||
      this.Ngay_gia_han == undefined ||
      this.Ngay_gia_han == ''
    ) {
      this.toastr.warning('Bạn chưa chọn ngày gia hạn');
      return false;
    }
    if (this.Ngay_gia_han <= this.dataDangKyQuaTrinh.Den_ngay) {
      this.toastr.warning('Ngày gia hạn phải lớn hơn ngày kết thúc!');
      return false;
    }
    if (this.So_thang_o <= 0) {
      this.toastr.warning('Số tháng ở phải lớn hơn 0!');
      return false;
    }
    let req = {
      ID: this.dtPhong.ID_dang_ky,
      Ngay_ket_thuc: this.Ngay_gia_han,
      So_thang_o: this.So_thang_o,
    };
    this.ListpeopledormService.GiaHanNgayRa(req, this.Token).subscribe((z) => {
      this.modalRef.close();
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.RoomService.GetAllSVByIdPhong(
          this.dtPhong.ID_Phong,
          this.Token
        ).subscribe((z) => {
          if (z.Status == 1) {
            this.dataroom = z.Data;
          }
        });
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  MultiGiaHan() {
    let dtsv = this.dataroom.filter((x) => x.Chon == true);
    if (dtsv.length < 1) {
      this.toastr.warning('Bạn chưa chọn sinh viên');
      return false;
    }
    if (
      this.Ngay_gia_han == null ||
      this.Ngay_gia_han == undefined ||
      this.Ngay_gia_han == ''
    ) {
      this.toastr.warning('Bạn chưa chọn ngày gia hạn');
      return false;
    }
    let req = {
      listSinhVien: dtsv,
      Ngay_ket_thuc: this.Ngay_gia_han,
    };
    this.ListpeopledormService.MultiGiaHanNgayRa(req, this.Token).subscribe(
      (z) => {
        this.modalRef.close();

        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.RoomService.GetAllSVByIdPhong(
          this.dtPhong.ID_Phong,
          this.Token
        ).subscribe((z) => {
          if (z.Status == 1) {
            this.dataroom = z.Data;
          }
        });
      }
    );
  }
  changeGiaHan() {
    if (this.Ngay_gia_han <= this.dataDangKyQuaTrinh.Den_ngay) {
      this.toastr.warning('Ngày gia hạn phải lớn hơn ngày kết thúc!');
      return false;
    }
    let Ngay_ket_thuc = new Date(this.Ngay_gia_han);
    let Ngay_bat_dau = new Date(this.dtPhong.Ngay_bat_dau);
    let SoThangO;

    if (Ngay_ket_thuc.getFullYear() > Ngay_bat_dau.getFullYear()) {
      SoThangO =
        (Ngay_ket_thuc.getFullYear() - Ngay_bat_dau.getFullYear()) * 12 -
        Ngay_bat_dau.getMonth() +
        Ngay_ket_thuc.getMonth();
    } else {
      SoThangO = Ngay_ket_thuc.getMonth() - Ngay_bat_dau.getMonth();
    }
    if (Ngay_bat_dau != Ngay_ket_thuc) {
      SoThangO -= 1;
      if (30 - Ngay_bat_dau.getDate() > 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
      if (30 - Ngay_ket_thuc.getDate() < 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
    }
    this.So_thang_o = SoThangO;
  }
  open(content, Data, type, action) {
    this.So_thang_o = 0;
    this.Ngay_gia_han = '';
    this.dtPhong = Data;
    this.ID_loai_phong_cu = Data.ID_loai_phong;
    this.submitted = false;
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: type,
      windowClass: 'modal-window',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.Khoa_phong = Data.Khoa_phong;
    this.Check_con_trong = Data.Con_trong;
    if (action == 'view') {
      this.tenPhongPrint = Data.Ten_toa_nha + ' - ' + Data.Ten_phong;
      this.TenPhong = Data.Ten_toa_nha + '-' + Data.Ten_phong;
      this.ID_phong = Data.ID_Phong;
      this.RoomService.GetAllSVByIdPhong(Data.ID_Phong, this.Token).subscribe(
        (z) => {
          if (z.Status == 1) {
            this.dataroom = z.Data;
            // for(var i =0 ; i<z.Data.length;i++){
            //   let dateTemp = new Date(z.Data[i].Ngay_ket_thuc);
            //   dateTemp.setDate(dateTemp.getDate()-15);
            //   if(this.CheckDay < dateTemp){
            //   }
            // }
          } else {
            this.toastr.error(z.Message);
          }
        }
      );
      let today = new Date();
      let day, month;
      var yyyy = today.getFullYear();
      if (today.getDate() < 10) {
        day = '0' + today.getDate();
      } else {
        day = today.getDate();
      }
      if (today.getMonth() + 1 < 10) {
        month = '0' + (today.getMonth() + 1);
      } else {
        month = today.getMonth() + 1;
      }
      var getday = yyyy + '-' + month + '-' + day;
      this.Ngay_ra_ktx = getday;
      this.Check_ra = false;
      this.Check_blacklist = false;
      this.Ly_do = ' ';
    } else if (action == 'RaHan') {
      this.ListpeopledormService.getDangKyQuaTrinhLast(
        Data.ID_dang_ky,
        this.Token
      ).subscribe((z) => {
        this.dataDangKyQuaTrinh = z;
      });
    }
  }
  openPrintSoPhong(content, type) {
    this.SoPhongService.GetAll(this.Token).subscribe((z) => {
      this.dataSoPhong = z;
    });

    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: type,
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openModalHSSV(content, Data, type, action) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: type })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    if (action == 'edit') {
      this.Action.setValue({
        So_Ho_Chieu: Data.So_Ho_Chieu,
        Ngay_Het_Han_Visa:
          Data.Ngay_Het_Han_Visa != null
            ? Data.Ngay_Het_Han_Visa.substring(0, 10)
            : null,
        Ngay_bat_dau:
          Data.Ngay_bat_dau != null ? Data.Ngay_bat_dau.substring(0, 10) : null,
        Ngay_ket_thuc:
          Data.Ngay_ket_thuc != null
            ? Data.Ngay_ket_thuc.substring(0, 10)
            : null,
        Ma_sv: Data.Ma_sv,
        Ho_ten: Data.Ho_ten,
        Ngay_sinh:
          Data.Ngay_sinh != null ? Data.Ngay_sinh.substring(0, 10) : null,
        Gioi_Tinh: Data.Gioi_Tinh,
        ID_quoc_tich: Data.Quoc_tich,
        Dan_toc: Data.Dan_toc,
        CMND: Data.CMND,
        Ngay_cap_CMND:
          Data.Ngay_cap_CMND != null
            ? Data.Ngay_cap_CMND.substring(0, 10)
            : null,
        Noi_cap_CMND: Data.ID_noi_cap_CMND,
        Ton_giao: Data.Ton_giao,
        Dia_chi_tt: Data.Dia_chi_tt,
        Dienthoai_canhan: Data.Dienthoai_canhan,
        Email: Data.Email,
        Ho_ten_cha: Data.Ho_ten_cha,
        Namsinh_cha:
          Data.Namsinh_cha != null ? Data.Namsinh_cha.substring(0, 10) : null,
        Hoat_dong_XH_CT_cha: Data.Hoat_dong_XH_CT_cha,
        Ho_ten_me: Data.Ho_ten_me,
        Namsinh_me:
          Data.Namsinh_me != null ? Data.Namsinh_me.substring(0, 10) : null,
        Hoat_dong_XH_CT_me: Data.Hoat_dong_XH_CT_me,
        NoiO_hiennay: Data.NoiO_hiennay,
        So_dien_thoai_bo: Data.So_dien_thoai_bo,
        So_dien_thoai_me: Data.So_dien_thoai_me,
      });
      this.Student = Data;
    }
    if (action == 'changeRoom') {
      this.CampusService.getList(this.Token).subscribe((z) => {
        this.dataCampusChange = z.Data;
        this.BuildingService.getList(this.Token).subscribe((z) => {
          for (var i = 0; i < z.Data.length; i++) {
            if (z.Data[i].Bao_phong) {
              z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
            }
          }
          this.dataBuildingChangeAfter = z.Data;
          this.RoomService.GetAllNonLock(this.Token).subscribe((z) => {
            for (var i = 0; i < z.Data.length; i++) {
              if (z.Data[i].Bao_phong) {
                z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
              }
            }
            this.dtRoomChange = z.Data;
          });
        });
      });
    }

    if (action == 'avatar') {
      this.StudentImage = this.appConfig.URL_Anh_SV + Data.Ma_sv + '.PNG';
      this.Student = Data;

      // this.StudentImage =
      //   'https://www.kindpng.com/picc/m/22-223863_no-avatar-png-circle-transparent-png.png';
      // this.Student = Data;
      // this.StudentImage = this.imageExists(
      //   this.appConfig.URL_Anh_SV + Data.Ma_sv + '.PNG'
      // )
      //   ? this.appConfig.URL_Anh_SV + Data.Ma_sv + '.PNG'
      //   : 'https://www.kindpng.com/picc/m/22-223863_no-avatar-png-circle-transparent-png.png';
    }
  }

  UploadAvatar() {
    if (!this.currentInput) {
      this.toastr.warning('Vui lòng chọn ảnh cần tải lên');
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.currentInput);
    formData.append('Ma_sv', this.Student.Ma_sv);
    this.SoPhongService.UploadAvatar(formData, this.Token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.toastr.success('Cập nhật ảnh sinh viên thành công.');
      } else {
        this.toastr.error(z.Message, 'Thao tác thất bại');
      }
    });
  }

  imageExists(image_url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status != 404;
  }

  updateUrl(event) {
    this.StudentImage =
      'https://www.kindpng.com/picc/m/22-223863_no-avatar-png-circle-transparent-png.png';
  }

  onFileSelected(event) {
    this.currentInput = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => (this.StudentImage = reader.result);

      reader.readAsDataURL(file);
    }
  }
  openApprover(content, type) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: type })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  LoadCoSo() {
    this.CampusService.getList(this.Token).subscribe(
      (z) => {
        this.DSCoSo = z.Data;
        this.BuildingService.getList(this.Token).subscribe((z) => {
          this.DSToaNha = z.Data;
          this.DSToaNha2 = z.Data;
          this.getList(this.Token);
        });
        this.RoomcategoryService.getList(1, this.Token).subscribe((z) => {
          this.DSLoaiPhong = z.Data;
        });
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }

  checkAllCheckBox(ev) {
    if (this.dataroom.length > 0) {
      this.dataroom.forEach((x) => {
        x.Chon = ev.target.checked;
      });
    }
  }

  isAllCheckBoxChecked() {
    if (this.dataroom != undefined)
      return this.dataroom.every((p) => {
        return p.checked;
      });
  }

  onApprove() {
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.actionUpdate();
  }

  actionUpdate() {
    this.Student.So_Ho_Chieu = this.Action.value.So_Ho_Chieu;
    this.Student.Ngay_Het_Han_Visa = this.Action.value.Ngay_Het_Han_Visa;
    this.Student.Dienthoai_canhan = this.Action.value.Dienthoai_canhan;
    this.Student.Email = this.Action.value.Email;
    this.Student.Dia_chi_tt = this.Action.value.Dia_chi_tt;
    this.ListpeopledormService.UpdateSV(this.Student, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.modalService.dismissAll('ThemModal');
        } else {
          this.toastr.warning(z.Message);
        }
        this.Action.reset();
      },
      (err) => {
        this.toastr.warning(err.Message);
      }
    );
  } // cập nhật thông tin sinh viên

  onChangeRoom() {
    let dt = this.dataroom.filter((x) => x.Chon == true);
    if (dt.length == 0) {
      this.toastr.warning('Bạn vui lòng tích chọn sinh viên để đổi phòng !');
    } else {
      let dtRoo = this.dataRoomChange.filter((x) => {
        return x.ID_Phong == this.ActionChangeRoom.value.ID_Room;
      });
      if (dtRoo.length > 0) {
        if (this.Ngay_ra_phong_cu == null || this.Ngay_ra_phong_cu == '') {
          this.toastr.warning('Bạn chưa chọn ngày đổi !');
        } else {
          if (
            this.dataDangKyQuaTrinh.Tu_ngay > this.Ngay_ra_phong_cu ||
            this.dataDangKyQuaTrinh.Den_ngay < this.Ngay_ra_phong_cu
          ) {
            this.toastr.warning('Ngày đổi phòng phải trong thời gian ở!');
            return false;
          }
          this.spinner.show();
          let req = {
            Students: dt,
            IDPhong: this.ActionChangeRoom.value.ID_Room,
            IDPhongDi: this.ID_phong,
            Ngay_bat_dau: this.Ngay_ra_phong_cu,
          };
          this.ListpeopledormService.UpdateRoom(req, this.Token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.toastr.success(z.Message);
                this.modalService.dismissAll('ChangeClassModal');
                this.LoadCoSo();
              } else {
                this.toastr.error(z.Message);
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              if (err.status == 0) {
                localStorage.removeItem('UserInfo');
                this.router.navigate(['/login']);
              } else if (err.status == 401) {
                this.toastr.warning(
                  'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
                );
              }
            }
          );
        }
      } else {
        this.toastr.warning('Chọn phòng !');
      }
    }
  } // đổi phòng ktx

  ChoRa() {
    var dt = this.dataroom.filter((x) => x.Chon == true);
    if (dt.length == 0) {
      this.modalService.dismissAll('ChoRaktx');
      this.toastr.warning('Bạn vui lòng tích chọn sinh viên để cho ra !');
    } else {
      var dtstring = dt.map((x) => x.ID_dang_ky);
      if (this.Check_blacklist) {
        this.Check_ra = true;
      }

      let data = {
        lstID_dang_ky: dtstring,
        Ly_do: this.Ly_do,
        Ngay_cho_ra: this.Ngay_ra_ktx,
        Ra_truoc_han: this.Check_ra,
        Danh_sach_den: this.Check_blacklist,
        ID_phong: this.ID_phong,
      };
      this.ListpeopledormService.ChoRaKTX(data, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.modalService.dismissAll('ChoRaktx');
          this.LoadCoSo();
        } else {
          this.toastr.error(z.Message);
        }
      });
    }
  } // Cho ra đúng thời hạn

  GetStringID(m) {
    var rs = '';
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_dang_ky;
      } else {
        rs += ',' + m[k].ID_dang_ky;
      }
    }
    return rs;
  } // get string id dang ky

  getListCampusChange() {
    this.CampusService.getList(this.Token).subscribe((z) => {
      this.dataCampusChange = z.Data;
      this.getListBuildingChange();
    });
  } // list all Cơ sở đổi phòng
  getListBuildingChange() {
    this.BuildingService.getList(this.Token).subscribe((z) => {
      // for (var i = 0; i < z.Data.length; i++) {
      //   if (z.Data[i].Bao_phong) {
      //     z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
      //   }
      // }
      this.dataBuildingChange = z.Data;
      this.getListRoomChange();
    });
  } // list tòa nhà đổi phòng
  getListRoomChange() {
    this.RoomService.GetAllNonLock(this.Token).subscribe((z) => {
      for (var i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Bao_phong) {
          z.Data[i].Ten_phong = z.Data[i].Ten_phong + '_PB';
        }
      }
      this.dtRoomChange = z.Data;
      this.dataRoomChange = z.Data;
    });
  } // list phòng đổi phòng
  changeCampusRep(id) {
    this.dataBuildingChange = this.dataBuildingChangeAfter.filter((x) => {
      return x.ID_CoSo == id;
    });
  }
  changeRoomRep(id_toanha) {
    this.dataRoomChange = this.dtRoomChange.filter((x) => {
      return (
        x.ID_Toa_Nha.toString() == id_toanha &&
        x.Loai_phong == this.dtPhong.Loai_phong &&
        x.Con_trong > 0
      );
    });
    console.table(this.dataRoomChange);
  }

  substrDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  CheckExpDate(date): object {
    let d = this.substrDays(date, 15);
    let datenow = new Date();
    if (d <= datenow) {
      return { 'background-color': 'lightgoldenrodyellow' };
    }
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_SoDoPhong;
    window.open(urlHelp, '_blank');
  }
  PrintSoPhong() {
    this.SoPhongService.GetAll(this.Token).subscribe((z) => {
      this.dataSoPhong = z;
    });
    setTimeout(() => {
      let printContents = document.getElementById('print-so-phong').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 1000);
    this.modalService.dismissAll('DeleteModal');
  }

  PrintSoPhongDetail() {
    this.spinner.show();
    this.SoPhongService.GetAll(this.Token, this.ID_phong).subscribe((z) => {
      this.dataSoPhong = z.filter((x) => x.Ten_phong == this.tenPhongPrint);
    });
    setTimeout(() => {
      let printContents = document.getElementById('print-so-phong').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 500);
    this.spinner.hide();
    this.modalService.dismissAll('DeleteModal');
  }
}

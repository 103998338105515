import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AccessHistoryService {
    constructor(
        @Inject(AppConfig) private readonly appConfig: AppConfiguration,
        private router: Router,
        private http: HttpClient
    ) { }
    Access_History(token): Observable<any> {
        return this.http
            .get<any>(this.appConfig.UniDormAPI + 'AccessHistory/Access_History', {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            }).pipe(map((z) => { return z; }));
    }
    Access_History_Detail(ID: any, token): Observable<any> {
        return this.http
            .get<any>(this.appConfig.UniDormAPI + 'AccessHistory/Access_History_Detail?ID_sv=' + ID, {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            }).pipe(map((z) => { return z; }));
    }
    Access_History_Room(ID: any, token): Observable<any> {
        return this.http
            .get<any>(this.appConfig.UniDormAPI + 'AccessHistory/Access_History_Room?ID_room=' + ID, {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            }).pipe(map((z) => { return z; }));
    }
    Insert(req: any, token): Observable<any> {
        return this.http.post<any>(this.appConfig.UniDormAPI + 'AccessHistory/Access_History_Insert', req, {
            headers: new HttpHeaders()
                .set('Authorization', `Bearer ${token}`)
        }).pipe(map(z => { return z }))
    }
    Update(req: any, token): Observable<any> {
        return this.http.post<any>(this.appConfig.UniDormAPI + 'AccessHistory/Access_History_Update', req, {
            headers: new HttpHeaders()
                .set('Authorization', `Bearer ${token}`)
        }).pipe(map(z => { return z }))
    }
}

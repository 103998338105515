<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quản lý vi phạm</li>
        </ol>
      </div>
      <div class="col-md-6" style="float: left;">
        <button
          class="btn btn-success btn-sm"
          style="float: right; margin-right: 10px;"
          (click)="openModal(ThemModal, 'xl', 'Insert', '')"
        >
          <i class="fas fa-plus-circle"></i>&nbsp; Thêm mới
        </button>
        <button
          class="btn btn-sm btn-danger"
          (click)="open(DuyetDiem, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fa fa-lock" ngbTooltip="Duyệt"></i>&nbsp; Duyệt điểm
        </button>
        <button
          class="btn btn-sm btn-info"
          (click)="open(BoDuyetDiem, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-unlock" ngbTooltip="Duyệt"></i>&nbsp; Bỏ duyệt điểm
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="Excel()"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="hidesearch()">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <form class="form-horizontal" [formGroup]="FilterForm">
      <div class="card-body" *ngIf="show_search">
        <div class="row">
          <div class="col-md-2">
            <label>Mã sinh viên</label>
            <input
              class="form-control cus-fs"
              type="text"
              formControlName="Ma_sv_ft"
              placeholder="Tìm mã sinh viên ..."
              (change)="Filter()"
            />
          </div>
          <div class="col-md-2">
            <label>Tên sinh viên</label>
            <input
              class="form-control cus-fs"
              type="text"
              formControlName="Ten_sv_ft"
              placeholder="Tìm kiếm sinh viên ..."
              (change)="Filter()"
            />
          </div>
          <div class="col-md-2">
            <label>Học kỳ</label>
            <select
              class="form-control cus-fs"
              formControlName="Hoc_ky_ft"
              (change)="Filter()"
            >
              <option value="">Tất cả</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>

          <div class="col-md-2">
            <label>Năm học</label>
            <select
              class="form-control cus-fs"
              formControlName="Nam_hoc_ft"
              (change)="Filter()"
            >
              <option value="">Tất cả</option>
              <option *ngFor="let p of Nam_hoc_arr" value="{{ p.value }}">{{
                p.name
              }}</option>
            </select>
          </div>
          <div class="col-md-2">
            <label>Từ ngày</label>
            <input
              class="form-control cus-fs"
              type="date"
              formControlName="Tu_ngay_ft"
              (change)="Filter()"
            />
          </div>
          <div class="col-md-2">
            <label>Đến ngày</label>
            <input
              class="form-control cus-fs"
              type="date"
              formControlName="Den_ngay_ft"
              (change)="Filter()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6"></div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>
                  <input
                    type="checkbox"
                    [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)"
                  />
                </th>

                <th>STT</th>
                <th>Mã sinh viên</th>
                <th>Họ tên</th>
                <th>Ngày sinh</th>
                <th>Điểm trừ</th>
                <th>Học kỳ</th>
                <th>Năm học</th>
                <th>Nhà - phòng</th>
                <th>Nội dung</th>
                <th>Ngày vi phạm</th>
                <th>Trạng thái duyệt</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of DataView; let i = index; let odd = odd">
                  <td>
                    <input
                      type="checkbox"
                      value="{{ p.ID_sv }}"
                      [checked]="p.checked"
                      [(ngModel)]="DataView[i].checked"
                    />
                  </td>
                  <td>{{ p.STT }}</td>
                  <td>{{ p.Ma_sv }}</td>
                  <td>{{ p.Ten_sv }}</td>
                  <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Diem_tru }}</td>
                  <td>{{ p.Hoc_ky }}</td>
                  <td>{{ p.Nam_hoc }}</td>
                  <td>{{ p.Ten_nha }}-{{ p.Ten_phong }}</td>
                  <td>{{ p.Noi_dung }}</td>
                  <td>{{ p.Ngay_vi_pham | date: 'dd/MM/yyyy' }}</td>
                  <td *ngIf="p.Duyet_diem == 0">Chưa duyệt</td>
                  <td *ngIf="p.Duyet_diem == 1">Duyệt</td>
                  <td *ngIf="p.Duyet_diem == 2">Bỏ duyệt</td>
                  <td class="text-center" style="white-space: nowrap;">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="openModal(SuaModal, 'xl', 'Update', p)"
                      *ngIf="p.Duyet_diem == 0 || p.Duyet_diem == 2"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="openModal(DeleteModal, '', 'Delete', p)"
                      *ngIf="p.Duyet_diem == 0 || p.Duyet_diem == 2"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-trash-alt" title="Xoá"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="DataView.length == 0">
                  <td colspan="13" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="container" style="font-size: 14px;">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab>
          <ng-template ngbTabTitle>Thông tin vi phạm</ng-template>
          <ng-template ngbTabContent>
            <div class="cus-box">
              <form class="form-horizontal" [formGroup]="InsertForm">
                <div class="row">
                  <div class="col-md-6">
                    <label class="require">Học kỳ</label>
                    <select
                      class="form-control"
                      required
                      formControlName="Hoc_ky"
                      [ngClass]="{
                        'is-invalid': submitted && checkvalue.Hoc_ky.errors
                      }"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    <div
                      *ngIf="submitted && checkvalue.Hoc_ky.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="checkvalue.Hoc_ky.errors.required">
                        Học kỳ không được bỏ trống!
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="require">Năm học</label>
                    <select
                      class="form-control"
                      required
                      formControlName="Nam_hoc"
                      [ngClass]="{
                        'is-invalid': submitted && checkvalue.Nam_hoc.errors
                      }"
                    >
                      <option
                        *ngFor="let p of Nam_hoc_arr"
                        value="{{ p.value }}"
                        >{{ p.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && checkvalue.Nam_hoc.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="checkvalue.Nam_hoc.errors.required">
                        Năm học không được bỏ trống!
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="col-md-10 require">Ngày vi phạm</label>
                    <input
                      class="form-control"
                      type="date"
                      formControlName="Ngay_vi_pham"
                      [ngClass]="{
                        'is-invalid': submitted && checkvalue.Nam_hoc.errors
                      }"
                    />
                  </div>
                  <div
                    *ngIf="submitted && checkvalue.Nam_hoc.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="checkvalue.Nam_hoc.errors.required">
                      Năm học không được bỏ trống!
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="col-md-10">Điểm trừ</label>
                    <input
                      class="form-control"
                      type="number"
                      formControlName="Diem_tru"
                    />
                  </div>
                </div>
                <div class="row" style="padding-top: 10px;"></div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Ghi chú</label>
                    <textarea
                      class="form-control"
                      cols="100"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab style="font-weight: bold;" title="Thông tin sinh viên">
          <ng-template ngbTabContent>
            <div class="modal-body" *ngIf="!Display">
              <div class="row" style="font-family: sans-serif;">
                <div class="modal-body">
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-md-6">
                      <label>Tìm kiếm theo mã hoặc tên sinh viên</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Tìm kiếm theo mã hoặc tên sinh viên"
                          [(ngModel)]="Ma_sv"
                          (change)="filterModal()"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text btn-primary"
                            (click)="filterModal()"
                            ><i class="fas fa-search"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label>Ngày sinh</label>
                      <input
                        type="date"
                        class="form-control"
                        [(ngModel)]="Ngay_sinh"
                        (change)="filterModal()"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-body cus-fs" style="padding: 2%;">
                  <div class="row" style="padding-top: 20px;">
                    <div class="col-md-6">
                      <div class="col-md-12 mb-1">
                        <div class="col-md-6">
                          Hiển thị
                          <select (change)="handlePageSizeChange($event)">
                            <option
                              *ngFor="let size of pageSizes"
                              [ngValue]="size"
                            >
                              {{ size }}
                            </option>
                          </select>
                          kết quả
                        </div>
                      </div>
                      <div class="col-md-12 mb-1">
                        <div class="input-group">
                          <div class="input-group-append">
                            <!-- <span class="input-group-text btn-success" (click)="PushCheckRow()"><i
                                class="fas fa-plus-circle"></i></span> -->
                          </div>
                        </div>
                      </div>
                      <!-- Bnarg 1 -->
                      <div class="col-md-12 mb-1">
                        <div
                          class="table-responsive"
                          style="max-height: 60vh; margin-bottom: 15px;"
                        >
                          <table class="table table-bordered" id="tableData">
                            <thead>
                              <th>
                                <input
                                  type="checkbox"
                                  [checked]="isAllCheckBoxChecked1()"
                                  (change)="checkAllCheckBox1($event)"
                                  (change)="PushCheckRow()"
                                />
                              </th>
                              <th>Mã sinh viên</th>
                              <th>Họ tên</th>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let p of dataSV;
                                  let i = index;
                                  let odd = odd
                                "
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    value="{{ p.ID_sv }}"
                                    [checked]="p.checked"
                                    [(ngModel)]="dataSV[i].checked"
                                    (change)="PushCheckRow()"
                                  />
                                </td>
                                <td>{{ p.Ma_sv }}</td>
                                <td>{{ p.Ho_ten }}</td>
                              </tr>
                              <tr *ngIf="dataSV.length == 0">
                                <td colspan="3" class="text-center">
                                  Không có kết quả
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-12 mb-1">
                        <div class="col-md-6">
                          <p>
                            <b>Tổng số bản ghi: {{ totalRecord2 }}</b>
                          </p>
                        </div>
                        <!-- <div class="col-md-6">
                          <nav aria-label="Page navigation example" style="float: left;">
                            <ul class="pagination">
                              <li class="page-item" [class.disabled]="page_sv == 1">
                                <a style="font-size: 14px !important;" class="page-link"
                                  (click)="changepage2(1)">Đầu</a>
                              </li>
                              <li class="page-item" [class.disabled]="page_sv == 1">
                                <a style="font-size: 14px !important;" class="page-link"
                                  (click)="changepage2('pre')">Trước</a>
                              </li>
                              <ng-container *ngIf="page_sv > 4">
                                <li class="page-item">
                                  <a style="font-size: 14px !important;" class="page-link">...</a>
                                </li>
                              </ng-container>
                              <ng-container *ngFor="let element of arrNumberPage2_child">
                                <li class="page-item" [class.active]="page_sv == element">
                                  <a style="font-size: 14px !important;" class="page-link"
                                    (click)="changepage2(element)">{{ element }}</a>
                                </li>
                              </ng-container>
                              <ng-container *ngIf="page_sv < numberPage2 - 4">
                                <li class="page-item">
                                  <a style="font-size: 14px !important;" class="page-link">...</a>
                                </li>
                              </ng-container>
                              <li class="page-item" [class.disabled]="page_sv == numberPage2">
                                <a style="font-size: 14px !important;" class="page-link"
                                  (click)="changepage2('next')">Sau</a>
                              </li>
                              <li class="page-item" [class.disabled]="page_sv == numberPage2">
                                <a style="font-size: 14px !important;" class="page-link"
                                  (click)="changepage2(numberPage2)">Cuối</a>
                              </li>
                            </ul>
                          </nav>
                        </div> -->
                      </div>
                    </div>
                    <!-- Bnarg 2 -->
                    <div class="col-md-6">
                      <div class="col-md-12 mb-1">
                        <div class="input-group">
                          <div class="input-group-append">
                            <!-- <span class="input-group-text btn-danger" (click)="SpliceCheckRow()"><i
                                class="fas fa-times-circle"></i></span> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-1">
                        <div
                          class="table-responsive"
                          style="max-height: 60vh; margin-bottom: 15px;"
                        >
                          <table class="table table-bordered" id="tableData">
                            <thead>
                              <th>
                                <input
                                  type="checkbox"
                                  (change)="checkAllCheckBox1($event)"
                                  [checked]="isAllCheckBoxChecked1()"
                                  (change)="SpliceCheckRow()"
                                />
                              </th>
                              <th>Mã sinh viên</th>
                              <th>Họ tên</th>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let p of DataViPham;
                                  let i = index;
                                  let odd = odd
                                "
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    value="{{ p.ID_sv }}"
                                    [checked]="p.checked"
                                    [(ngModel)]="DataViPham[i].checked"
                                    (change)="SpliceCheckRow1(DataViPham)"
                                  />
                                </td>
                                <td>{{ p.Ma_sv }}</td>
                                <td>{{ p.Ho_ten }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="Insert()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #SuaModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="UpdateForm">
      <div class="container" style="font-size: 14px;">
        <ngb-tabset [destroyOnHide]="false">
          <ngb-tab>
            <ng-template ngbTabContent>
              <div class="cus-box">
                <div class="row">
                  <div class="col-md-6">
                    <label>Mã sinh viên</label>
                    <input
                      class="form-control"
                      formControlName="Ma_sv"
                      disabled
                      type="text"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Tên sinh viên</label>
                    <input
                      class="form-control"
                      formControlName="Ten_sv"
                      disabled
                      type="text"
                    />
                  </div>
                </div>
                <div class="row" style="margin-top: 8px;">
                  <div class="col-md-6">
                    <label class="col-md-10">Ngày vi phạm</label>
                    <input
                      class="form-control"
                      formControlName="Ngay_vi_pham"
                      type="date"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="col-md-10">Điểm trừ</label>
                    <input
                      class="form-control"
                      formControlName="Diem_tru"
                      type="number"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="col-md-6">Học kỳ</label>
                    <select class="form-control" formControlName="Hoc_ky">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="col-md-6">Năm học</label>
                    <select class="form-control" formControlName="Nam_hoc">
                      <option
                        *ngFor="let p of Nam_hoc_arr"
                        value="{{ p.value }}"
                        >{{ p.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-12">
                    <label>Nội dung</label>
                    <textarea
                      class="form-control"
                      cols="100"
                      formControlName="Noi_dung"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="update()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn muốn xóa thông tin vi phạm của sinh viên
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Delete()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- <ng-template #DuyetDiem let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="icon-box">
                <i class="fa fa-lock"
                    style=" color: #f15e5e; font-size: 46px;display: inline-block; margin-top: 13px;"></i>
            </div>
            <p
                style=" color: inherit;text-decoration: none; font-size: 20px;text-align: center; font-weight: 600; margin-top: 3em;">
                Bạn có chắc chắn muốn duyệt điểm cho sinh viên?
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="updateStatus_ViPham()">
                    <i class="fa fa-lock"></i>Duyệt điểm
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    <i class="far fa-times-circle"></i> Hủy bỏ
                </button>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template #BoDuyetDiem let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fa fa-lock"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn bỏ duyệt điểm cho sinh viên?
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="HuyDuyetDiem_ListID()"
        >
          <i class="fa fa-lock"></i>Bỏ duyệt
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          <i class="far fa-times-circle"></i> Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #DuyetDiem let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fa fa-lock"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn duyệt điểm cho sinh viên?
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="DuyetDiem_ListID()"
        >
          <i class="fa fa-lock"></i> Duyệt điểm
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          <i class="far fa-times-circle"></i> Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

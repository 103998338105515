<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Nội dung email</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th class="text-center">Mã Template</th>
                <th class="text-center">Tên Template</th>
                <th class="text-center">Thao tác</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of dataTable
                      | paginate: { itemsPerPage: 10, currentPage: page }
                  "
                >
                  <td>{{ data.Ma_template }}</td>
                  <td>{{ data.Ten_template }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="open(UpdateModal, 'xl', 'Edit', data)"
                      style="margin-right: 7px;"
                    >
                      <i class="fas fa-edit" title="Sửa"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="4" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
            <pagination-controls
              class="float-right"
              (pageChange)="page = $event"
              previousLabel="Trước"
              nextLabel="Sau"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #UpdateModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Sửa nội dung Email</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <ckeditor
        [editor]="Editor"
        data="{{ content }}"
        (change)="onChange($event)"
      ></ckeditor>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { RoomcategoryService } from 'src/app/utils/services/room-category.service';
import { NgIf } from '@angular/common';
import { AppConfig, AppConfiguration } from 'src/configuration';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-room-category',
  templateUrl: './room-category.component.html',
  styleUrls: ['./room-category.component.scss'],
})
export class RoomCategoryComponent implements OnInit {
  public Editor = ClassicEditor;
  dtOptions: DataTables.Settings;
  dataTable: any = [];
  ExtensionsRoom: any = [];
  ExtensionsNotInRoom: any = [];
  dataMoTa: any = 'Mô tả phòng';
  titleModal: string;
  default: any;
  Token: string;
  closeResult: string;
  fileName: string = '';
  fileNameUpLoad: string = '';
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  fileToUpload: File = null;
  FileBase64: string;
  ID: any;
  ID_loai_phong: any;
  Ma_loai_phong_ft: any;
  Ma_loai_phong: any;
  Ten_loai_phong: any;
  Gia_phong: any;
  Mo_ta: any;
  Dang_o: any;
  MaLP: any;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public RoomcategoryService: RoomcategoryService
  ) {}
  Insert = new FormGroup({
    Ten_loai_phong: new FormControl(null, [
      Validators.required,
      Validators.maxLength(200),
      removeSpaces,
    ]),
    Ma_loai_phong: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    Gia_phong: new FormControl(null, [Validators.required]),
    Tien_cuoc: new FormControl(null, [Validators.required]),
  });
  Filter = new FormGroup({
    Ma_loai_phong_ft: new FormControl(null),
    Ten_loai_phong_ft: new FormControl(null),
    Gia_phong_ft: new FormControl(null),
  });
  dtTrigger = new Subject();
  public com: common;

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Mã loại phòng',
        },
        {
          title: 'Tên loại phòng',
        },
        {
          title: 'Giá phòng',
        },
        {
          title: 'Tiền cược',
        },
        {
          title: 'Sức chứa',
        },
        {
          title: 'Đang ở',
        },
        {
          title: 'Đã đăng ký',
        },
        {
          title: 'Còn trống',
        },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
      order: [[0, 'desc']],
    };
    this.getlist();
  }
  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.dataMoTa = data;
  }

  fill() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_loai_phong_dt = data[1];
      let Ten_loai_phong_dt = data[2];
      let Gia_phong_dt = data[3];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let fillter = true;
      if (this.Filter.controls.Ma_loai_phong_ft.value != undefined) {
        var b = this.Filter.controls.Ma_loai_phong_ft.value;
        var a = Ma_loai_phong_dt;
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Filter.controls.Ten_loai_phong_ft.value != undefined) {
        var b = this.Filter.controls.Ten_loai_phong_ft.value;
        var a = Ten_loai_phong_dt;
        bool2 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Filter.controls.Gia_phong_ft.value != undefined) {
        var b = this.Filter.controls.Gia_phong_ft.value;
        var a = Gia_phong_dt.replaceAll(',', '');
        bool3 = a.toLowerCase().includes(b.toLowerCase());
      }
      fillter = bool1 && bool2 && bool3;

      return fillter;
    });
  }
  getlist() {
    this.spinner.show();
    let GetNull = true;
    this.RoomcategoryService.getList(GetNull, this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  open(content, size, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    this.ID = Data.ID;
    if (Loai == 'Edit') {
      this.checkInsert = false;
      this.titleModal = 'Sửa loại phòng';
      //   this.Insert.patchValue({ID: Data.ID})
      this.MaLP = Data.Ma_loai_phong;
      this.Insert.patchValue({ Ma_loai_phong: Data.Ma_loai_phong });
      this.Insert.patchValue({ Ten_loai_phong: Data.Ten_loai_phong });
      this.Insert.patchValue({ Gia_phong: Data.Gia_phong });
      this.Insert.patchValue({ Tien_cuoc: Data.Tien_cuoc });
      this.Dang_o = Data.Dang_o;
      this.dataMoTa = Data.Mo_ta;
    } else if (Loai == 'Add') {
      this.dataMoTa = '';
      this.titleModal = 'Thêm loại phòng';
      this.checkInsert = true;
      this.Insert.patchValue({ Ma_loai_phong: '' });
      this.Insert.patchValue({ Ten_loai_phong: '' });
      this.Insert.patchValue({ Gia_phong: '' });
      this.Insert.patchValue({ Mo_ta: '' });
      this.Insert.reset();
    } else if (Loai == 'ViewMota') {
      this.Mo_ta = Data.Mo_ta;
    }
    if (Loai == 'Extensions') {
      this.ID_loai_phong = Data.ID;
      this.RoomcategoryService.LoadExtensionsByIDLoaiPhong(
        Data.ID,
        this.Token
      ).subscribe((data) => {
        if (data.Status == 1) {
          this.ExtensionsRoom = data.ListData;
        } else {
          this.toastr.error(data.Message);
        }
      });
    }
    if (Loai == 'AddExtensions') {
      this.RoomcategoryService.LoadExtensionsNotExistByIDLoaiPhong(
        this.ID_loai_phong,
        this.Token
      ).subscribe((data) => {
        if (data.Status == 1) {
          this.ExtensionsNotInRoom = data.ListData;
        } else {
          this.toastr.error(data.Message);
        }
      });
    }
  }
  LoadExtensionsNotExistByIDLoaiPhong(ID_loai_phong) {
    this.spinner.show();
    this.RoomcategoryService.LoadExtensionsNotExistByIDLoaiPhong(
      ID_loai_phong,
      this.Token
    ).subscribe((data) => {
      this.spinner.hide();
      if (data.Status == 1) {
        this.ExtensionsNotInRoom = data.ListData;
      } else {
        this.toastr.error(data.Message);
      }
    });
  }

  LoadExtensionsByIDLoaiPhong(ID_loai_phong) {
    this.spinner.show();
    this.RoomcategoryService.LoadExtensionsByIDLoaiPhong(
      ID_loai_phong,
      this.Token
    ).subscribe((data) => {
      this.spinner.hide();
      if (data.Status == 1) {
        this.ExtensionsRoom = data.ListData;
      } else {
        this.toastr.error(data.Message);
      }
    });
  }
  DeleteExtension(ID_tien_ich) {
    this.spinner.show();
    this.RoomcategoryService.DeleteExtensionsLoaiPhong(
      this.ID_loai_phong,
      ID_tien_ich,
      this.Token
    ).subscribe((data) => {
      this.spinner.hide();
      if (data.Status == 1) {
        this.LoadExtensionsNotExistByIDLoaiPhong(this.ID_loai_phong);
        this.LoadExtensionsByIDLoaiPhong(this.ID_loai_phong);
      } else {
        this.toastr.error(data.Message);
      }
    });
  }
  InsertExtension(ID_tien_ich, Don_gia) {
    if (!Don_gia) {
      this.toastr.warning('Vui lòng nhập đơn giá.');
      return;
    }
    this.spinner.show();
    this.RoomcategoryService.InsertExtensionsLoaiPhong(
      this.ID_loai_phong,
      ID_tien_ich,
      Don_gia,
      this.Token
    ).subscribe((data) => {
      this.spinner.hide();
      if (data.Status == 1) {
        this.LoadExtensionsNotExistByIDLoaiPhong(this.ID_loai_phong);
        this.LoadExtensionsByIDLoaiPhong(this.ID_loai_phong);
      } else {
        this.toastr.error(data.Message);
      }
    });
  }
  Delete() {
    this.RoomcategoryService.Delete(this.ID, this.Token).subscribe((data) => {
      if (data.Status == 1) {
        this.toastr.success('Đã xoá thành công');
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getlist();
      } else {
        this.toastr.error(data.Message);
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    if (this.checkInsert == true) {
      this.create();
    } else {
      this.update();
    }
  }
  create() {
    let input = {
      Ma_loai_phong: this.Insert.controls.Ma_loai_phong.value,
      Ten_loai_phong: this.Insert.controls.Ten_loai_phong.value,
      Gia_phong: this.Insert.controls.Gia_phong.value,
      Tien_cuoc: this.Insert.controls.Tien_cuoc.value,
      Mo_ta: this.dataMoTa,
    };
    this.RoomcategoryService.CheckExist(
      input.Ma_loai_phong,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 9) {
        this.RoomcategoryService.Insert(input, this.Token).subscribe(
          (data) => {
            if (data.Status == 1) {
              this.toastr.success(data.Message);
            } else {
              this.toastr.error(data.Message);
            }
            this.modalService.dismissAll('AddModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getlist();
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          }
        );
      } else {
        this.toastr.warning('Mã loại phòng đã tồn tại');
      }
    });
  }
  update() {
    if (this.Dang_o > 0) {
      this.toastr.warning('Loại phòng đã được sử dụng không thể sửa');
      this.modalService.dismissAll('AddModal');
    } else {
      let input = {
        ID: this.ID,
        Ma_loai_phong: this.Insert.controls.Ma_loai_phong.value,
        Ten_loai_phong: this.Insert.controls.Ten_loai_phong.value,
        Gia_phong: this.Insert.controls.Gia_phong.value,
        Tien_cuoc: this.Insert.controls.Tien_cuoc.value,
        Mo_ta: this.dataMoTa,
      };
      if (input.Ma_loai_phong == this.MaLP) {
        this.RoomcategoryService.Update(input, this.Token).subscribe((data) => {
          if (data.Status == 1) {
            this.toastr.success(data.Message);
          } else {
            this.toastr.error(data.Message);
          }
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getlist();
        });
      } else {
        this.RoomcategoryService.CheckExist(
          input.Ma_loai_phong,
          this.Token
        ).subscribe((z) => {
          if (z.Status == 9) {
            this.RoomcategoryService.Update(input, this.Token).subscribe(
              (data) => {
                if (data.Status == 1) {
                  this.toastr.success(data.Message);
                } else {
                  this.toastr.error(data.Message);
                }
                this.modalService.dismissAll('AddModal');
                this.datatableElement.dtInstance.then(
                  (dtInstance: DataTables.Api) => {
                    dtInstance.destroy();
                  }
                );
                this.getlist();
              }
            );
          } else {
            this.toastr.warning('Mã loại phòng đã tồn tại');
          }
        });
      }
    }
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fill();
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QLLoaiPhong;
    window.open(urlHelp, '_blank');
  }
}

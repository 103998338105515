import { Injectable, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NumberElectricService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }
  Delete(dt: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
        'NumberElectric/NumberElectric_Delete?ID=' + dt,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Count( token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
        'NumberElectric/Count',
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'NumberElectric/CountFilter',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  GetPagination(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'NumberElectric/RoomSoDien',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_List', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getThongKeKHoanThu(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/ThongKeKhoanThu', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_Insert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_Update', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiUpdate(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_MultiUpdate', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  RP_Manager(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_RP_Manager', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  RP_Personal(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_RP_Personal', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CheckExist(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_CheckExist?month=' + req.month + '&year=' + req.year + '&ID_room=' + req.ID_room,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListFill(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_MonthYear_Load?month=' + req.month + '&year=' + req.year,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListStatus0(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_MonthYear_Load_Status_0?month=' + req.month + '&year=' + req.year,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListStatus1(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_MonthYear_Load_Status_1?month=' + req.month + '&year=' + req.year + '&ID_building=' + req.ID_building,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getListStatus01(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'NumberElectric/NumberElectric_MonthYear_Load_Status_0_1?month=' + req.month + '&year=' + req.year + '&ID_building=' + req.ID_building,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MoChot(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/MoChot', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  PhanBo(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/PhanBo', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getKHoanThu(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/KhoanThu', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiChot(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/MultiChot', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiMoChot(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/MultiMoChot', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  MultiPhanBo(req, type, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/MultiPhanBo?type=' + type, req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ExcelSoDien(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'NumberElectric/ExcelSoDien',req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

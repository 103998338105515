import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { AccService } from 'src/app/utils/services/acc.service'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', './main.css'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  public registerForm = new FormGroup({
    UserName: new FormControl(null, Validators.required),
    Email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
    retypePassword: new FormControl(null, Validators.required),
  });
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private Service:AccService
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'register-page');
    //  / this.renderer.addClass(document.querySelector('app-root'), 'register-page');
    this.registerForm
  }

  register() {
    if (this.registerForm.valid) {
      this.appService.register();
    } else {
      this.toastr.error('Hello world!', 'Toastr fun!');
    }
  }
  getPassWord() {
    let req={
      UserName:this.registerForm.value.UserName,
      Email:this.registerForm.value.Email,
      UserCategory:1
    }
    this.Service.GetPassword(req).subscribe(z=>{
      if(z.Status==1){
        this.toastr.success(z.Message)
      }else{
        this.toastr.error(z.Message)
      }
    })
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'register-page');
    // this.renderer.removeClass(document.querySelector('app-root'),'register-page');
  }
}

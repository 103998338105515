<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/students-sort']"> Chọn sinh viên</a>
                    </li>
                    <li class="breadcrumb-item active">Tiến hành xếp phòng</li>
                </ol>
            </div>
            <div class="col-md-6">
                <!-- <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right; margin-left: 10px;">
                      <i class="fas fa-users-cog"></i> Xếp phòng tự động
                  </button> -->

                <button type="button" style="float: right;" (click)="onSubmit()" class="btn-sm btn-success">
                    <i class="fas fa-users-cog"></i> Xếp phòng
                </button>
            </div>
        </div>
    </div>
</section>
<div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Thông tin xếp phòng</h6>
            <form class="form-horizontal" [formGroup]="Insert">
                <div class="row">
                    <div class="col-md-9">
                        <div class="row" style="padding-left: 10px;">
                            <div class="col-md-4">
                                <label>Tổng số đối tượng đã chọn</label>
                                <input type="text" readonly class="form-control" value="{{ StudentData.length }}" />
                            </div>
                            <div class="col-md-4">
                                <label>Thứ tự xếp đối tượng</label>
                                <select formControlName="Thu_tu_doi_tuong" class="form-control">
                                    <option value="0">Theo thời gian đăng ký -> Đối tượng chính sách</option>
                                    <option value="1">Theo đối tượng chính sách -> Thời gian đăng ký</option>
                                    <option value="2">Theo thứ tự mặc định</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label>Ngày nhận phòng</label>
                                <input (change)="GetMonth()" type="date" formControlName="Ngay_bat_dau" class="form-control" />
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;">
                            <div class="col-md-4">
                                <label>Tổng sức chứa đã chọn</label>
                                <input type="text" readonly class="form-control" value="{{ TongSucChua }}" />
                            </div>
                            <div class="col-md-4">
                                <label>Thứ tự xếp phòng</label>
                                <select class="form-control" formControlName="Thu_tu_phong">
                                    <option value="0">Theo tầng từ dưới lên trên->Theo chỗ trống</option>
                                    <option value="1">Theo chỗ trống -> Theo tầng từ dưới lên trên</option>
                                    <option value="2">Theo thứ tự mặc định</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label>Ngày kết thúc</label>
                                <input type="date" formControlName="Ngay_ket_thuc" class="form-control"
                                    (change)="GetMonth()" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="row">
                            <div class="col-md-12">
                                <label>&nbsp; Số tháng ở thực tế :</label>
                                <input type="text" value="true" readonly formControlName="ThangO" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">Danh sách phòng</h6>
            <form class="form-horizontal" [formGroup]="Fillter">
                <div class="row" style="padding-left: 10px;">

                    <div class="col-md-2">
                        <label>Loại phòng</label>
                        <select name="" class="form-control" formControlName="Loai_phong_filter"
                            (change)="filterById()">
                            <option value="" selected>Chọn loại phòng</option>
                            <option *ngFor="let p of DSLoaiPhong" [value]="p.Ten_loai_phong">{{p.Ten_loai_phong}}
                            </option>
                        </select>
                        <!-- <input type="text" class="form-control" (keyup)="filterById()"  formControlName="Loai_phong_filter" /> -->
                    </div>
                    <div class="col-md-2">
                        <label>Cơ sở</label>
                        <select name="" class="form-control" formControlName="Co_so_filter" (change)="filterById()">
                            <option value="" selected>Chọn cơ sở</option>
                            <option *ngFor="let p of DSCoSo" [value]="p.name">{{p.name}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" (keyup)="filterById()" formControlName="Co_so_filter" /> -->
                    </div>
                    <div class="col-md-2">
                        <label>Tòa nhà</label>
                        <select name="" class="form-control" formControlName="Toa_nha_filter" (change)="filterById()">
                            <option value="" selected>Chọn toà nhà</option>
                            <option *ngFor="let p of DSToaNha" [value]="p.Ten_ToaNha">{{p.Ten_ToaNha}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" (keyup)="filterById()" formControlName="Toa_nha_filter" /> -->
                    </div>
                    <div class="col-md-2">
                        <label>Tầng</label>
                        <input type="text" class="form-control" (keyup)="filterById()" formControlName="Tang_filter" />
                    </div>
                    <div class="col-md-2">
                        <label>Tên/mã phòng</label>
                        <input type="text" class="form-control" (keyup)="filterById()"
                            formControlName="Ten_phong_filter" />
                    </div>
                    <div class="col-md-2">
                        <label>Phòng nam/nữ</label>
                        <select class="form-control" disabled (change)="filterById()" formControlName="Phong_filter">

                            <option value="1">Nam</option>
                            <option value="2">Nữ</option>
                        </select>
                    </div>

                </div>
            </form>
            <hr>
            <div class="row" style="margin-top: 10px ;">
                <div class="col-md-12">
                    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                        <table datatable id="datatable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                            class="table-bordered dataTables_wrapper no-footer">
                            <thead>
                                <tr>
                                    <th><input type="checkbox" id="SelectALL2" (change)="CheckAll2()"
                                            value="SelectALL2"></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of DataDetail">
                                    <td>
                                        <input type="checkbox" (change)="SelectRow(p.ID_Phong)" id="{{ p.ID_Phong }}"
                                            [checked]="p.Selected" value="{{ p.ID_Phong }}" />
                                    </td>
                                    <td>{{ p.ID_Phong }}</td>
                                    <td>{{ p.ID_Co_So }}</td>
                                    <td>{{ p.ID_Toa_Nha }}</td>
                                    <td>{{ p.Ten_co_so }}</td>
                                    <td>{{ p.Ten_toa_nha }}</td>
                                    <td>{{ p.Tang }}</td>
                                    <td>{{ p.Ten_loai_phong }}</td>
                                    <td>{{ p.Ma_phong }}</td>
                                    <td>{{ p.Ten_phong }}</td>
                                    <td>{{ p.Suc_chua }}</td>
                                    <td>{{ p.Con_trong }}</td>
                                    <td>{{ p.Ten_co_so }}</td>
                                    <td>{{ p.Loai_phong}}</td>
                                    <td>{{ p.Loai_phong_text}}</td>
                                    <td>{{ p.Suc_chua }}</td>
                                    <td>{{ p.Ghi_chu }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>


</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="cog" [fullScreen]="true" name="sp2">
    <p style="color: white;">Vui lòng chờ, hệ thống đang xếp phòng và gửi email thông báo...</p>
</ngx-spinner>
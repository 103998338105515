import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {}

  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Building/BuildingListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Building/BuildingAdd', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Building/BuildingDelete?ID_ToaNha=' + ID,
        0,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'Building/BuildingEdit', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
 
}

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Báo cáo thống kê</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-primary btn-sm"
          (click)="getList()"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="open(ExportExcelModal, 'xs')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-2">
          <label>Mã sinh viên</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm mã sinh viên"
            [(ngModel)]="Ma_sv"
          />
        </div>
        <div class="col-sm-2">
          <label>Tên sinh viên</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm tên sinh viên"
            [(ngModel)]="Ho_ten"
          />
        </div>
        <div class="col-sm-2">
          <label>Khoa</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_khoa"
          >
            <ng-option [value]="0">Tất cả</ng-option>
            <ng-option *ngFor="let value of DSKhoa" [value]="value.ID_khoa">{{
              value.Ten_khoa
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Khóa</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="Khoa_hoc"
          >
            <ng-option [value]="0">Tất cả</ng-option>
            <ng-option
              *ngFor="let value of dataKhoaHoc"
              [value]="value.Khoa_hoc"
              >{{ value.value }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Năm học</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="Nam_hoc"
          >
            <ng-option [value]="''">Tất cả</ng-option>
            <ng-option *ngFor="let value of yeardate" [value]="value.value">{{
              value.value
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Trạng thái học</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="Trang_thai_hoc"
          >
            <ng-option [value]="-1">Tất cả</ng-option>
            <ng-option [value]="0">Tốt nghiệp</ng-option>
            <ng-option [value]="1">Đang học</ng-option>
            <ng-option [value]="2">Thôi học, bảo lưu</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2">
          <label>Từ ngày</label>
          <input
            (change)="getList()"
            class="form-control"
            type="date"
            [(ngModel)]="Tu_ngay"
          />
        </div>
        <div class="col-sm-2">
          <label>Đến ngày</label>
          <input
            (change)="getList()"
            class="form-control"
            type="date"
            [(ngModel)]="Den_ngay"
          />
        </div>
        <div class="col-sm-2">
          <label>Cơ sở</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_co_so"
            (change)="changeCampus()"
          >
            <ng-option [value]="0">Tất cả</ng-option>
            <ng-option *ngFor="let i of dataCampus" [value]="i.ID">{{
              i.code
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Toà nhà</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_toa_nha"
          >
            <ng-option [value]="0">Tất cả</ng-option>
            <ng-option *ngFor="let i of dataBuilding" [value]="i.ID_ToaNha">{{
              i.Ten_ToaNha
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Tầng</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm tầng"
            [(ngModel)]="Tang"
          />
        </div>
        <div class="col-sm-2">
          <label>Giới tính</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_gioi_tinh"
          >
            <ng-option [value]="-1">Tất cả</ng-option>
            <ng-option [value]="0">Nam</ng-option>
            <ng-option [value]="1">Nữ</ng-option>
          </ng-select>
        </div>
      </div>
      <hr />
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>STT</th>
                <th>Mã sinh viên</th>
                <th>Họ tên</th>
                <th>Giới tính</th>
                <th>Khoa</th>
                <th>Khóa - Lớp</th>
                <th>Tòa nhà</th>
                <th>Số tháng ở</th>
                <th>Ngày vào KTX</th>
                <th>Ngày ra KTX</th>
                <th>Số tiền phải nộp</th>
                <th>Số tiền đã đóng</th>
                <th>Số tiền thừa, thiếu</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Gioi_tinh }}</td>
                  <td>{{ p.Ten_khoa }}</td>
                  <td>{{ p.Khoa_Lop }}</td>
                  <td>{{ p.Ma_toa_nha }}</td>
                  <td class="text-right">{{ p.So_thang_o | number }}</td>
                  <td>{{ p.Ngay_bat_dau | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Ngay_ket_thuc | date: 'dd/MM/yyyy' }}</td>
                  <td class="text-right">{{ p.So_tien_phai_nop | number }}</td>
                  <td class="text-right">{{ p.So_tien_da_nop | number }}</td>
                  <td class="text-right">{{ p.So_tien_con_no | number }}</td>
                </tr>
                <tr *ngIf="dataTable.length == 0">
                  <td colspan="13" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ExportExcelModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Bạn có muốn xuất excel dữ liệu này không?</h4>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="ExportExcel()"
      >
        <i class="fa fa-download" aria-hidden="true"></i> Tải xuống
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SearchLogService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  GetAll(Chuc_nang,Thao_tac,FromDate,ToDate,token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'LogEvent/ListAll?Chuc_Nang='+Chuc_nang +"&Thao_tac="+
      Thao_tac+"&FromDate="+FromDate
      +"&ToDate="+ToDate
      , {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

}

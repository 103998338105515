import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-quan-tri-email',
  templateUrl: './quan-tri-email.component.html',
  styleUrls: ['./quan-tri-email.component.scss'],
})
export class QuanTriEmailComponent extends BaseComponent implements OnInit {
  titleModal: any = '';
  ID: number;
  Email: any = '';
  Trang_thai: any = '';
  Mac_dinh: any = '';
  checkInsert: any;
  record: any;
  Insert = new FormGroup({
    Email: new FormControl(null, [Validators.required, Validators.email]),
    Password: new FormControl(null, [Validators.required]),
    PasswordConfirm: new FormControl(null, [Validators.required]),
    Mac_dinh: new FormControl(false),
    Trang_thai: new FormControl(false),
  });
  ngOnInit(): void {
    this.getToken();
    this.getList();
    this.getCount();
  }
  getCount() {
    this.QuanTriEmailService.Count(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Email: this.Email,
      Trang_thai: this.Trang_thai == '' ? null : this.Trang_thai,
      Mac_dinh: this.Mac_dinh == '' ? null : this.Mac_dinh,
    };
    this.QuanTriEmailService.getList(dataFilter, this.Token).subscribe((z) => {
      if (z.Status == 10) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
        this.router.navigate(['/admin']);
        this.spinner.hide();
      } else {
        this.dataTable = z.Data;
        this.QuanTriEmailService.CountFilter(dataFilter, this.Token).subscribe(
          (z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          }
        );
      }
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 10000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.Email = '';
    this.Trang_thai = false;
    this.Mac_dinh = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Email: this.Email,
      Trang_thai: this.Trang_thai,
      Mac_dinh: this.Mac_dinh,
    };
    this.QuanTriEmailService.getList(dataFilter, this.Token).subscribe((z) => {
      if (z.Status == 10) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      } else {
        this.dataTable = z.Data;
        this.spinner.hide();
      }
    });
  }
  open(content, sizm, Loai, Data) {
    this.ID = Data.ID_quan_tri_email;
    this.record = Data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Add') {
      this.titleModal = 'Thêm mới địa chỉ Email';
      this.checkInsert = true;
      this.Insert.get('Email').setValue(null);
      this.Insert.get('Password').setValue(null);
      this.Insert.get('PasswordConfirm').setValue(null);
      this.Insert.get('Mac_dinh').setValue(false);
    } else {
      this.titleModal = 'Sửa địa chỉ Email';
      this.checkInsert = false;
      this.Insert.get('Email').setValue(Data.Email);
      this.Insert.get('Password').setValue(Data.Password);
      this.Insert.get('PasswordConfirm').setValue(Data.Password);
      this.Insert.get('Mac_dinh').setValue(Data.Mac_dinh);
      this.Insert.get('Trang_thai').setValue(Data.Trang_thai);
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    if (this.Insert.value.Password != this.Insert.value.PasswordConfirm) {
      this.toastr.warning('Mật khẩu xác nhận không trùng khớp!');
      return false;
    }
    let req = {
      ID_quan_tri_email: this.ID,
      Email: this.Insert.value.Email,
      Password: this.Insert.value.Password,
      Mac_dinh: this.Insert.value.Mac_dinh,
      Trang_thai: this.Insert.value.Trang_thai,
    };
    if (this.checkInsert) {
      req.Trang_thai = false
      this.QuanTriEmailService.Insert(req, this.Token).subscribe(
        (res) => {
          if (res.Status == 2) {
            this.toastr.warning(res.Message);
          } else if (res.Status == 1) {
            this.toastr.success(res.Message);
            this.modalService.dismissAll('AddModal');
            this.getList();
            this.getCount();
          } else if (res.Status == 4) {
            this.toastr.error('Thêm mới thất bại!');
          } else {
            this.toastr.warning(res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    } else {
      if (
        this.record.Da_gui > 0 &&
        this.record.Email != this.Insert.value.Email
      ) {
        this.toastr.warning('Không thể sửa tên Email!');
        return false;
      }
      this.QuanTriEmailService.Update(req, this.Token).subscribe(
        (res) => {
          if (res.Status == 2) {
            this.toastr.warning(res.Message);
          } else if (res.Status == 1) {
            this.toastr.success(res.Message);
            this.modalService.dismissAll('AddModal');
            this.getList();
          } else if (res.Status == 4) {
            this.toastr.error('Cập nhật thất bại!');
          } else {
            this.toastr.warning(res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }
  }
  Delete() {
    this.QuanTriEmailService.Delete(this.ID, this.Token).subscribe(
      (res) => {
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Xoá thất bại!');
        } else {
          this.toastr.warning(res.Message);
        }
        this.modalService.dismissAll('');
        this.getCount();
        this.getList();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  MacDinh() {
    this.QuanTriEmailService.MacDinh(this.ID, true, this.Token).subscribe(
      (res) => {
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
          this.modalService.dismissAll('AddModal');
          this.getList();
        } else if (res.Status == 4) {
          this.toastr.error('Cập nhật thất bại!');
        } else {
          this.toastr.warning(res.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
}

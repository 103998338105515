import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-version-detail',
  templateUrl: './version-detail.component.html',
  styleUrls: ['./version-detail.component.scss']
})
export class VersionDetailComponent implements OnInit {
version:any;
versionDetail:any;
  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.http.get<any>('assets/version.json').subscribe(data => {
      this.version=data.version 
      
  })
  this.http.get<any>('assets/UpdateDescription.json').subscribe(data => {
    this.versionDetail=data
})
  }

}

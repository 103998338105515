import {
  Component,
  OnInit,
  ViewChild,
  Input,
  DebugElement,
} from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { common } from 'src/app/app.common';
import { ExportService } from 'src/app/utils/services/export.service';
import { SystemService } from 'src/app/utils/services/system.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
import { AbstractControl } from '@angular/forms';
import { LoaiDeXuatService } from 'src/app/utils/services/loaidexuat.service';
import { DeXuatSinhVienService } from 'src/app/utils/services/de-xuat-sinh-vien.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { KhenThuongKyLuatService } from 'src/app/utils/services/khenthuongkyluat.service';
import { ViPhamService } from 'src/app/utils/services/vi-pham.service';
import { RoomService } from 'src/app/utils/services/room.service';
@Component({
  selector: 'app-de-xuat-sinh-vien',
  templateUrl: './de-xuat-sinh-vien.component.html',
  styleUrls: ['./de-xuat-sinh-vien.component.scss'],
})
export class DeXuatSinhVienComponent implements OnInit {
  submitted = false;
  NameTitle: string;
  titleModal: string;
  closeResult: string;
  Message: any;
  Token: any;
  Code: any;
  IsValid: any = false;
  public com: common;
  check: any;
  ID: any;
  Data: any;
  Ngay_xu_ly: any;
  DataCanBo: any = [];
  DataThietBi: any = [];
  Date = new Date();
  dtTrigger = new Subject();
  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  IDRecord: any;
  checkInsert: boolean;
  MonthOption: any = [];
  dataLoaiDeXuat: any;
  dataSuaChua: any;
  dataTable: any;
  dataPhong: any;
  dataToaNha: any;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    public Service: SystemService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public exportService: ExportService,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private LoaiDeXuatService: LoaiDeXuatService,
    private DeXuatSinhVienService: DeXuatSinhVienService,
    private ViPhamService: ViPhamService,
    public RoomService: RoomService,

  ) {}
  Insert = new FormGroup({
    Noi_dung_phan_hoi: new FormControl(null, [
      Validators.required,
      Validators.maxLength(500),
      removeSpaces,
    ]),
  });
  UpdateForm = new FormGroup({
    ID_thiet_bi: new FormControl(null),
    Ten_thiet_bi: new FormControl(0),
    ID_phong: new FormControl(null),
    Ten_phong: new FormControl(null),
    Ten_toa_nha: new FormControl(null),
    Ghi_chu: new FormControl(null),
    So_luong: new FormControl(null),
    Noi_dung: new FormControl(null),
    Nguoi_thuc_hien: new FormControl(null),
    Ngay_hoan_thanh: new FormControl(null),
    Modify_user: new FormControl(null),
  });
  searchForm = new FormGroup({
    ID_phong: new FormControl(''),
    ID_loai_de_xuat: new FormControl(0),
    Trang_thai_hoan_thanh: new FormControl(0),
    Ma_sv: new FormControl(''),
    Ho_ten: new FormControl(''),
    Thang: new FormControl(0),
  });

  ngOnInit(): void {
    let element, name, arr;
    element = document.getElementById('menu1');
    arr = element.className.split(' ');
    name = 'menu-open';
    if (arr.indexOf(name) == -1) {
      element.className += ' ' + name;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.MonthOption = [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' },
      { value: 4, name: '4' },
      { value: 5, name: '5' },
      { value: 6, name: '6' },
      { value: 7, name: '7' },
      { value: 8, name: '8' },
      { value: 9, name: '9' },
      { value: 10, name: '10' },
      { value: 11, name: '11' },
      { value: 12, name: '12' },
    ];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'Mã SV',
        },
        {
          title: 'Họ tên',
        },
        {
          title: 'Số điện thoại',
        },
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Nhà-Phòng',
        },
        {
          title: 'Nội dung đề xuất',
        },
        {
          title: 'Ngày đề xuất',
        },
        {
          title: 'ID_loai_de_xuat',
          visible: false,
        },
        {
          title: 'Tên loại đề xuất',
        },
        {
          title: 'Nội dung phản hồi',
        },
        {
          title: 'Trạng thái hoàn thành',
        },
        {
          title: 'Thao tác',
          width: '75px',
          className: 'dt-center',
        },
      ],
    };
    this.getList();
    this.filterById();
  }
  getList() {
    this.spinner.show();
    this.DeXuatSinhVienService.getList(this.Token).subscribe(
      (z) => {
        this.dataTable = z.Data;
        this.getLoaiDeXuaT();
        this.getToaNha();
        this.dtTrigger.next();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  open(content, sizm, Loai, Data) {
    this.IDRecord = Data.ID_de_xuat;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.checkInsert = false;
      this.titleModal = 'Phản hồi ý kiến';
      this.Insert.get('Noi_dung_phan_hoi').setValue(Data.Noi_dung_phan_hoi);
    } else if (Loai == 'Add') {
      this.Insert.reset();
      this.checkInsert = true;
      this.titleModal = 'Thêm mới loại đề xuất';
    } else if (Loai == 'XacNhan') {
      this.dataSuaChua = Data;
      this.getListTB(Data.ID_phong)
    }
  }

  getListTB(ID_phong : any) {
    this.RoomService.PhongThietBiGetAll(ID_phong, this.Token).subscribe((z) => {
      this.DataThietBi = z.Data;
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  PhanHoi() {
    let req = {
      ID_de_xuat: this.IDRecord,
      Noi_dung_phan_hoi: this.Insert.value.Noi_dung_phan_hoi,
    };
    this.DeXuatSinhVienService.Update(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.getList();
          this.toastr.success(z.Message);
        } else {
          this.toastr.error('Phản hồi thất bại!');
        }
        this.modalService.dismissAll('PhanHoi');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      console.log(data);
      let Ma_sv = data[0];
      let Ho_ten = data[1];
      let ID_phong = data[3];
      let Thang = data[6].split('/');
      let ID_loai_de_xuat = data[7];
      let Trang_thai_hoan_thanh = data[10];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;
      if (this.searchForm.value.Ma_sv != 0) {
        bool1 = Ma_sv == this.searchForm.value.Ma_sv;
      }

      if (this.searchForm.value.Ho_ten != 0) {
        bool2 = this.xoa_dau(Ho_ten.toLowerCase()).includes(
          this.xoa_dau(this.searchForm.value.Ho_ten.toLowerCase())
        );
      }
      if (this.searchForm.value.ID_phong != null) {
        bool3 = ID_phong == this.searchForm.value.ID_phong;
      }

      if (this.searchForm.value.Thang != 0) {
        bool4 = Thang[1] == this.searchForm.value.Thang;
      }
      if (this.searchForm.value.ID_loai_de_xuat != 0) {
        bool5 = ID_loai_de_xuat == this.searchForm.value.ID_loai_de_xuat;
      }
      if (this.searchForm.value.ID_loai_de_xuat != 0) {
        bool5 = ID_loai_de_xuat == this.searchForm.value.ID_loai_de_xuat;
      }
      if (this.searchForm.value.Trang_thai_hoan_thanh != 0) {
        bool6 =
          Trang_thai_hoan_thanh == this.searchForm.value.Trang_thai_hoan_thanh;
      }
      if (bool1 && bool2 && bool3 && bool4 && bool5 && bool6) {
        return true;
      }
      return false;
    });
    $('#SelectALL').prop('checked', false);
  }
  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }
  // XuatExcel(){

  //   this.spinner.show();
  //   let dataFilter = {
  //     So_ban_ghi: this.pageSize,
  //     Trang: this.page,
  //     Ma_sv: this.Ma_sv.trim(),
  //     Ho_ten: this.Ho_ten.trim(),
  //     ID_gioi_tinh: this.ID_gioi_tinh,
  //     Ngay_sinh: this.Ngay_sinh,
  //     Trang_thai_filter: this.Trang_thai,
  //     ToaNha_Phong: this.ToaNha_Phong,
  //     Ten_cb_xu_ly: this.Ten_cb_xu_ly,
  //     Ngay_de_xuat_ft:this.Ngay_de_xuat
  //   };
  //   this.DeXuatSinhVienService.Excel(dataFilter, this.Token).subscribe((z) => {
  //     if(z.Status==1){
  //       this.exportService.exportExcelByte(z.FileData, 'De_xuat');
  //       this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
  //       this.spinner.hide();
  //     }else{
  //       this.toastr.error(z.Message)
  //       this.spinner.hide();
  //     }
  //   },
  //   (err) => {
  //     this.spinner.hide();
  //     if (err.status == 0) {
  //       localStorage.removeItem('UserInfo');
  //       this.router.navigate(['/login']);
  //     }
  //     if (err.status == 401) {
  //       this.toastr.warning(
  //         'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
  //       );
  //     }
  //   }
  //   );
  // }
  getLoaiDeXuaT() {
    this.LoaiDeXuatService.getList(this.Token).subscribe((z) => {
      this.dataLoaiDeXuat = z.Data;
    });
  }
  getToaNha() {
    this.ViPhamService.getList(this.Token).subscribe((z) => {
      this.dataToaNha = z.Data;
    });
  }
  updateXacNhan() {
    let req = {
      ID_de_xuat: this.IDRecord,
      ID_thiet_bi: this.UpdateForm.value.ID_thiet_bi,
      So_luong: this.UpdateForm.value.So_luong,
      Noi_dung: this.UpdateForm.value.Noi_dung,
      ID_phong: this.dataSuaChua.ID_phong,
      Ten_phong: this.UpdateForm.value.Ten_phong,
      Ten_toa_nha: this.UpdateForm.value.Ten_toa_nha,
      Nguoi_thuc_hien: this.UpdateForm.value.Nguoi_thuc_hien,
      Ngay_hoan_thanh: this.UpdateForm.value.Ngay_hoan_thanh,
      Modify_user: this.UpdateForm.value.Modify_user,
    };
    this.DeXuatSinhVienService.XacNhanHoanThanh(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
    this.modalService.dismissAll();
  }
}

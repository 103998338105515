<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Thống kê khoản nộp</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-primary btn-sm"
          (click)="getList()"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="open(ExportExcelModal, 'xs')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="open(ExportTienIchModal, 'xs')"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-file-excel"></i> Thống kê tiện ích
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-2">
          <label>Loại khoản nộp</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_thu_chi_list"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option
              *ngFor="let i of dataKhoanNopKTX"
              [value]="i.ID_thu_chi"
              >{{ i.Ten_thu_chi }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Mã sinh viên</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm mã sinh viên"
            [(ngModel)]="Ma_sv"
          />
        </div>
        <div class="col-sm-2">
          <label>Tên sinh viên</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm tên sinh viên"
            [(ngModel)]="Ho_ten"
          />
        </div>
        <div class="col-sm-2">
          <label>Trạng thái khoản nộp</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="Thieu_thua"
          >
            <ng-option [value]="0">Tất cả</ng-option>
            <ng-option [value]="1">Có khoản chưa đóng</ng-option>
            <ng-option [value]="2">Có khoản thu thừa</ng-option>
            <ng-option [value]="3">Đã hoàn thành khoản nộp</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Học kỳ</label>
          <select
            class="form-control"
            (change)="getList()"
            [(ngModel)]="Hoc_ky"
          >
            <option value="">Tất cả</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <div class="col-sm-2">
          <label>Năm học</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="Nam_hoc"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let value of yeardate" [value]="value.value">{{
              value.value
            }}</ng-option>
          </ng-select>
        </div>
        <!-- <div class="col-sm-2">
          <label>Tháng</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()"  [(ngModel)]="Thang">
            <ng-option value="">Tất cả</ng-option>
            <ng-option [value]="1">1</ng-option>
            <ng-option [value]="2">2</ng-option>
            <ng-option [value]="3">3</ng-option>
            <ng-option [value]="4">4</ng-option>
            <ng-option [value]="5">5</ng-option>
            <ng-option [value]="6">6</ng-option>
            <ng-option [value]="7">7</ng-option>
            <ng-option [value]="8">8</ng-option>
            <ng-option [value]="9">9</ng-option>
            <ng-option [value]="10">10</ng-option>
            <ng-option [value]="11">11</ng-option>
            <ng-option [value]="12">12</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Năm </label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()"  [(ngModel)]="Nam">
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let value of Nam_arr" [value]="value.value">{{value.value}}</ng-option>
          </ng-select>
        </div> -->
      </div>
      <div class="row">
        <div class="col-sm-2">
          <label>Cơ sở</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_co_so"
            (change)="changeCampus()"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let i of dataCampus" [value]="i.ID">{{
              i.code
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Toà nhà</label>
          <ng-select
            notFoundText="Không có kết quả"
            (change)="getList()"
            [(ngModel)]="ID_toa_nha"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let i of dataBuilding" [value]="i.ID_ToaNha">{{
              i.Ten_ToaNha
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label>Tên phòng</label>
          <input
            (change)="getList()"
            class="form-control"
            placeholder="Tìm kiếm tên phòng"
            [(ngModel)]="Ten_phong"
          />
        </div>
        <div class="col-sm-2">
          <label>Từ ngày</label>
          <input
            (change)="getList()"
            class="form-control"
            type="date"
            [(ngModel)]="Tu_ngay"
          />
        </div>
        <div class="col-sm-2">
          <label>Đến ngày</label>
          <input
            (change)="getList()"
            class="form-control"
            type="date"
            [(ngModel)]="Den_ngay"
          />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-sm-2">
          <label>Tổng phải thu: {{ So_tien_phai_thu | number }}</label>
        </div>
        <div class="col-sm-2">
          <label>Tổng đã nộp: {{ So_tien_da_nop | number }}</label>
        </div>
        <div class="col-sm-2">
          <label>Tổng đã trả: {{ So_tien_da_tra | number }}</label>
        </div>
        <div class="col-sm-2">
          <label>Tổng còn phải thu: {{ So_tien_con_phai_thu | number }}</label>
        </div>
        <div class="col-sm-2">
          <label>Tổng còn phải trả: {{ So_tien_con_phai_tra | number }}</label>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>Mã sinh viên</th>
                <th>Họ tên</th>
                <th>Ngày sinh</th>
                <th>Tòa nhà - Phòng</th>
                <th>Tiền phải nộp</th>
                <th>Tiền đã nộp</th>
                <th>Tiền trả lại</th>
                <th>Tiền thừa/thiếu</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Ten_toa_nha }} - {{ p.Ten_phong }}</td>
                  <td class="text-right">{{ p.So_tien_phai_nop | number }}</td>
                  <!-- <td class="text-right">{{ p.So_tien_mien_giam |number}}</td>
                  <td class="text-right">{{ p.Phan_tram_mien_giam }}</td> -->
                  <td class="text-right">{{ p.So_tien_da_nop | number }}</td>
                  <td class="text-right">{{ p.So_tien_tra_lai | number }}</td>
                  <td class="text-right">{{ p.Thua_thieu | number }}</td>
                </tr>
                <tr *ngIf="!dataTable || dataTable.length == 0">
                  <td colspan="8" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 3">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ExportExcelModal let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Bạn có muốn xuất excel dữ liệu này không?</h4>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="ExportExcel()"
      >
        <i class="fa fa-download" aria-hidden="true"></i> Tải xuống
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ExportTienIchModal let-modal>
  <div class="modal-header">
    <h6 style="margin: auto;">Báo cáo thống kê khoản nộp tiện ích chi tiết</h6>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <label>Năm học</label>
        <ng-select [(ngModel)]="Nam_hoc2">
          <ng-option value="2022-2023">2022-2023</ng-option>
          <ng-option value="2023-2024">2023-2024</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="ExportExcelTienIch()"
      >
        <i class="fa fa-download" aria-hidden="true"></i> Tải xuống
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

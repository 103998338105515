import { Component, OnInit ,VERSION} from '@angular/core';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { DeXuatSinhVienService } from 'src/app/utils/services/de-xuat-sinh-vien.service';
@Component({
  selector: 'app-chart-de-xuat',
  templateUrl: './chart-de-xuat.component.html',
  styleUrls: ['./chart-de-xuat.component.scss']
})

export class ChartDeXuatComponent   {
	data: any;
  Token: any;
  dataChart: any = [];
  chart: any;
  TongDeXuat: any = 0;
  DaXuLy: any = 0;
  TonDong: any = 0;
  dataDeXuat:any
  public com: common;
  constructor(
    private DeXuat:DeXuatSinhVienService ,
    public router: Router,
  ) {}
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.getList();
  }
  getList()
  {
	this.DeXuat.DeXuat_ThongKe(this.Token).subscribe((z)=>{
		var tondong=0;
		var xuly=0;
		this.dataDeXuat=z.Data
		for(let i=0;i<this.dataDeXuat.length;i++)
		{
			if(this.dataDeXuat[0].Noi_dung_phan_hoi=='')
			{
				tondong=tondong+1;
			}
			else
			{
				xuly=xuly+1;
			}
		}
		
		this.TongDeXuat=this.dataDeXuat.length
		this.DaXuLy=xuly;
		this.TonDong=tondong;
		console.log(this.dataDeXuat)
	})
  }
  chartOptions = {
	  title: {
		  text: "Thống kê sinh viên phản hồi"
	  },
	  animationEnabled: true,
	  axisY: {
		includeZero: true
	  },
	  data: [{
		type: "column", //change type to bar, line, area, pie, etc
		//indexLabel: "{y}", //Shows y value on all Data Points
		indexLabelFontColor: "#5A5757",
		dataPoints: [
			{ x: 10, y: 71 },
			{ x: 20, y: 55 },
		]
	  }]
	}
	chartOptions1 = {
		animationEnabled: true,
		title: {
		  text: "Sales by Department"
		},
		data: [{
		  type: "pie",
		  startAngle: -90,
		  indexLabel: "{name}: {y}",
		  yValueFormatString: "#,###.##'%'",
		  dataPoints: [
			{ y: 14.1, name: "Toys" },
			{ y: 28.2, name: "Electronics" },
			{ y: 14.4, name: "Groceries" },
			{ y: 43.3, name: "Furniture" }
		  ]
		}]
	  }	

}

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">

<section>
    <table style="height: 72px;" width="1038">
        <tbody>
        <tr>
        <td style="width: 441px;">
        <p>Đơn vị: Trường Đại học Sư phạm H&agrave; Nội</p>
        <p>M&atilde; QHNS: 1055502</p>
        </td>
        <td style="width: 581px;">
        <p>&nbsp;</p>
        <p style="text-align: center;"><strong>Mẫu số: C45-BB</strong></p>
        <p style="text-align: center;"><em>(Ban h&agrave;nh k&egrave;m theo Th&ocirc;ng tư số 107/2017/TT-BTC </em></p>
        <p style="text-align: center;"><em>ng&agrave;y 10/10/2017 của Bộ T&agrave;i ch&iacute;nh)</em></p>
        </td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <table style="height: 107px;" width="1038">
        <tbody>
        <tr>
        <td style="width: 287px;">
        <p>&nbsp;</p>
        </td>
        <td style="width: 509px;">
        <h3 style="text-align: center;"><strong>BI&Ecirc;N LAI THU TIỀN</strong></h3>
        <p style="text-align: center;">Ngày {{ date | date: 'dd' }} tháng {{date | date: 'MM'}} năm {{date | date: 'yyyy'}}</p>
        </td>
        <td style="width: 220px;">
        <p>&nbsp;</p>
        </td>
        </tr>
        <tr>
        <td style="width: 287px;">
        <p>&nbsp;</p>
        </td>
        <td style="width: 509px;">
        <p><strong>&nbsp;</strong></p>
        </td>
        <td style="width: 220px;">&nbsp;</td>
        </tr>
        </tbody>
        </table>
        <table style="width: 704px;">
            <tr>
              <td>
                <span style="margin-left: 75px;">Họ tên : {{ dt_print.Ho_ten }}  </span>
                <span id="HotenFee" style="font-weight: 700; margin-left: 20px;"></span>
              </td>
              <td>
                <span style="margin-left: 75px;"> Mã sinh viên :  {{ dt_print.Ma_sv }} </span>
                <span id="PersonTV" style="font-weight: 700; margin-left: 20px;"></span>
              </td>
            </tr>
            <tr>
              <td>
                <span style="margin-left: 75px;">
                  Số phòng :  {{ dt_print.Ten_phong}}  </span>
                <span style="font-weight: 700; margin-left: 20px;"></span>
              </td>
            </tr>
          </table>
    
          <div class="row" style="justify-content: center;margin-top: 20px;">
            <table class="table table-bordered table-sm" style="width: 900px;">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên khoản nộp</th>
                  <th>Số tiền</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable;index as count">
                  <td>{{count + 1 }}</td>   
                  <td>{{ p.Ten_thu_chi}}</td>
                  <td class="text-center"><p style="float: right;font-weight: 700;">{{p.So_tien_thuc_nop | number}} đ</p> </td>
                </tr>
                <tr>
                    <td colspan="2">Tổng tiền </td>
                    <td >
                      <p style="float: right;font-weight: 700;">{{dt_money | number}} đ</p>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
    
          <table style="width: 704px;">
            <tr>
              <td>
                <span style="margin-left: 75px;"> Số tiền nộp : </span>
                <span style="font-weight: 700; margin-left: 20px;">{{
                  dt_money | number
                }} đ</span>
              </td>
            </tr>
          </table>
          <table style="width: 704px;">
            <tr>
              <td>
                <span style="margin-left: 75px;"> Viết bằng chữ :</span>
                <span style="font-weight: 700; margin-left: 20px;">{{
                  money_words
                }}</span>
              </td>
            </tr>
          </table>
          <br>
        <table width="100%">
        <tbody>
        <tr>
        <td width="50%">
        <p style="text-align: center;"><strong>NGƯỜI NỘP TIỀN <br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
        </td>
        <td width="50%">
        <p style="text-align: center;"><strong>NGƯỜI THU TIỀN<br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
        </td>
        </tr>
        </tbody>
        </table>
   <br>
   <br>
   <br>
   <br>

   <p style="text-align: center; border-bottom: 1px solid #2f2d2d !important;">
    <strong>&nbsp; &nbsp; &nbsp; &nbsp; </strong>
  </p>
  
  <table style="height: 72px;" width="1038">
    <tbody>
    <tr>
    <td style="width: 441px;">
    <p>Đơn vị: Trường Đại học Sư phạm H&agrave; Nội</p>
    <p>M&atilde; QHNS: 1055502</p>
    </td>
    <td style="width: 581px;">
    <p>&nbsp;</p>
    <p style="text-align: center;"><strong>Mẫu số: C45-BB</strong></p>
    <p style="text-align: center;"><em>(Ban h&agrave;nh k&egrave;m theo Th&ocirc;ng tư số 107/2017/TT-BTC </em></p>
    <p style="text-align: center;"><em>ng&agrave;y 10/10/2017 của Bộ T&agrave;i ch&iacute;nh)</em></p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="height: 107px;" width="1038">
    <tbody>
    <tr>
    <td style="width: 287px;">
    <p>&nbsp;</p>
    </td>
    <td style="width: 509px;">
    <h3 style="text-align: center;"><strong>BI&Ecirc;N LAI THU TIỀN</strong></h3>
    <p style="text-align: center;">Ngày {{ date | date: 'dd' }} tháng {{date | date: 'MM'}} năm {{date | date: 'yyyy'}}</p>
    </td>
    <td style="width: 220px;">
    <p>&nbsp;</p>
    </td>
    </tr>
    <tr>
    <td style="width: 287px;">
    <p>&nbsp;</p>
    </td>
    <td style="width: 509px;">
    <p><strong>&nbsp;</strong></p>
    </td>
    <td style="width: 220px;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <table style="width: 704px;">
        <tr>
          <td>
            <span style="margin-left: 75px;">Họ tên : {{ dt_print.Ho_ten }}  </span>
            <span id="HotenFee" style="font-weight: 700; margin-left: 20px;"></span>
          </td>
          <td>
            <span style="margin-left: 75px;"> Mã sinh viên :  {{ dt_print.Ma_sv }} </span>
            <span id="PersonTV" style="font-weight: 700; margin-left: 20px;"></span>
          </td>
        </tr>
        <tr>
          <td>
            <span style="margin-left: 75px;">
              Số phòng :  {{ dt_print.Ten_phong}}  </span>
            <span style="font-weight: 700; margin-left: 20px;"></span>
          </td>
        </tr>
      </table>

      <div class="row" style="justify-content: center;margin-top: 20px;">
        <table class="table table-bordered table-sm" style="width: 900px;">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên khoản nộp</th>
              <th>Số tiền</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of dataTable;index as count">
              <td>{{count + 1 }}</td>   
              <td>{{ p.Ten_thu_chi}}</td>
              <td class="text-center"><p style="float: right;font-weight: 700;">{{p.So_tien_thuc_nop | number}} đ</p> </td>
            </tr>
            <tr>
                <td colspan="2">Tổng tiền </td>
                <td >
                  <p style="float: right;font-weight: 700;">{{dt_money | number}} đ</p>
                </td>
              </tr>
          </tbody>
        </table>
      </div>

      <table style="width: 704px;">
        <tr>
          <td>
            <span style="margin-left: 75px;"> Số tiền nộp : </span>
            <span style="font-weight: 700; margin-left: 20px;">{{
              dt_money | number
            }} đ</span>
          </td>
        </tr>
      </table>
      <table style="width: 704px;">
        <tr>
          <td>
            <span style="margin-left: 75px;"> Viết bằng chữ :</span>
            <span style="font-weight: 700; margin-left: 20px;">{{
              money_words
            }}</span>
          </td>
        </tr>
      </table>
      <br>
    <table width="100%">
    <tbody>
    <tr>
    <td width="50%">
    <p style="text-align: center;"><strong>NGƯỜI NỘP TIỀN <br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
    </td>
    <td width="50%">
    <p style="text-align: center;"><strong>NGƯỜI THU TIỀN<br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
    </td>
    </tr>
    </tbody>
    </table>

</section>

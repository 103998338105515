import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { BlackListService } from 'src/app/utils/services/black-list.service';
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';
import { DatePipe } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { BaseComponent } from '../base/base.component';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-black-list',
  templateUrl: './black-list.component.html',
  styleUrls: ['./black-list.component.scss'],
})
export class BlackListComponent extends BaseComponent implements OnInit {
  DSHe: any;
  DSKhoa: any;
  DSChuyenNganh: any;
  DSKhuVuc: any;
  DSDoiTuong: any;
  DSLoaiPhong: any;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  DSPhong: any;
  DSPhong2: any;
  ID: number;
  closeResult: string;
  dtOptions: DataTables.Settings;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  submitted = false;
  Request: any;
  Action = new FormGroup({
    code: new FormControl(null, [removeSpaces]),
    Ho_ten: new FormControl(null),
    Ngay_sinh: new FormControl(null),
    Que_quan: new FormControl(null),
    Ghi_chu: new FormControl(null, [Validators.maxLength(500)]),
  });
  Filter = new FormGroup({
    Ma_sv_filter: new FormControl(null),
    Ho_ten_filter: new FormControl(null),
  });

  dtTrigger = new Subject();
  public com: common;
  Fillter: FormGroup;
  Nam_hoc_arr: any = [];
  lstDanToc: any = [];
  lstTinh: any = [];

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ tên',
        },
        {
          title: 'Ngày sinh',
        },
        {
          title: 'Quê quán',
        },
        {
          title: 'Ghi chú',
        },
        {
          title: 'Thao tác',
          className: 'dt-center',
          width: '12%',
        },
      ],
      order: [[0, 'desc']],
    };
    this.Fillter = new FormGroup({
      Ma_sv_filter: new FormControl(null),
      Ho_ten_filter: new FormControl(null),
      NgaySinh_filter: new FormControl(null),
      Gioi_tinh_filter: new FormControl('0'),
      ID_dan_toc_filter: new FormControl('0'),
      Ton_giao_filter: new FormControl(null),
      He_filter: new FormControl('0'),
      NguyenVong_filter: new FormControl(null),
      Khoa_filter: new FormControl('0'),
      Khoa_hoc_filter: new FormControl(null),
      Chuyen_nganh_filter: new FormControl(null),
      Khu_vuc_filter: new FormControl(null),
      Doi_tuong_chinh_sach_filter: new FormControl('0'),
      ID_tinh_tt_filter: new FormControl('0'),
      Dienthoai_canhan_filter: new FormControl(null),
      Mail_filter: new FormControl(null),
    });
    this.getListFirst();
    this.CampusService.getList(a.Token).subscribe((z) => {
      this.DSCoSo = z.Data;
      this.BuildingService.getList(a.Token).subscribe((z) => {
        this.DSToaNha = z.Data;
        this.DSToaNha2 = z.Data;
        this.RoomService.GetAll(this.Token).subscribe((z) => {
          for (var i = 0; i < z.Data.length; i++) {
            if (z.Data[i].Loai_phong == false) {
              z.Data[i].Loai_phong_text = 'Nam';
            } else {
              z.Data[i].Loai_phong_text = 'Nữ';
            }
          }
          this.Service.List_He(a.Token).subscribe((z) => {
            this.DSHe = z;
          });
          this.Service.List_Khoa(a.Token).subscribe((z) => {
            this.DSKhoa = z;
          });
          this.Service.List_ChuyenNganh(a.Token).subscribe((z) => {
            this.DSChuyenNganh = z;
          });
          this.SystemService.Get_khuvuc_tuyensinh(a.Token).subscribe((z) => {
            this.DSKhuVuc = z;
          });
          this.SystemService.Get_doituong_chinhsach(a.Token).subscribe((z) => {
            this.DSDoiTuong = z;
          });
          this.RoomCateGoryService.getList(true, a.Token).subscribe((z) => {
            this.DSLoaiPhong = z.Data;
          });
          this.DSPhong = z.Data;
          this.DSPhong2 = z.Data;
        });
      });
    });
    this.getDanToc();
    this.getTinh();
  }

  getDanToc() {
    this.SystemService.Get_danh_sach_dan_toc(this.Token).subscribe((z) => {
      this.lstDanToc = z;
    });
  }

  getTinh() {
    this.SystemService.Get_tinh(this.Token).subscribe((z) => {
      this.lstTinh = z;
    });
  }

  ngAfterViewInit() {}

  getRequest() {
    return {
      Ma_sv: this.Fillter.value.Ma_sv_filter,
      Ho_ten: this.Fillter.value.Ho_ten_filter,
      Ngay_sinh: this.Fillter.value.NgaySinh_filter,
      ID_gioi_tinh:
        this.Fillter.value.Gioi_tinh_filter == 0
          ? null
          : this.Fillter.value.Gioi_tinh_filter,
      ID_dan_toc:
        this.Fillter.value.ID_dan_toc_filter == 0
          ? null
          : this.Fillter.value.ID_dan_toc_filter,
      Ton_giao: this.Fillter.value.Ton_giao_filter,
      Ten_he:
        this.Fillter.value.He_filter == 0 ? null : this.Fillter.value.He_filter,
      Ten_khoa:
        this.Fillter.value.Khoa_filter == 0
          ? null
          : this.Fillter.value.Khoa_filter,
      Khoa_hoc: this.Fillter.value.Khoa_hoc_filter,
      ID_doi_tuong_ts:
        this.Fillter.value.Doi_tuong_chinh_sach_filter == 0
          ? null
          : this.Fillter.value.Doi_tuong_chinh_sach_filter,
      ID_tinh_tt:
        this.Fillter.value.ID_tinh_tt_filter == 0
          ? null
          : this.Fillter.value.ID_tinh_tt_filter,
      Dienthoai_canhan: this.Fillter.value.Dienthoai_canhan_filter,
      Mail: this.Fillter.value.Mail_filter,
    };
  }

  getListFirst() {
    this.spinner.show();
    // this.spinner.show();
    var req = this.getRequest();
    this.BlackListService.getList(this.Token, req).subscribe(
      (z) => {
        this.spinner.hide();
        this.dataTable = z.Data;
        this.dtTrigger.next();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }

  getList() {
    // this.spinner.show();
    var req = this.getRequest();
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    // });
    this.spinner.show();
    this.BlackListService.getList(this.Token, req).subscribe(
      (z) => {
        this.spinner.hide();
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.dataTable = z.Data;
        this.dtTrigger.next();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  //get list table all
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_sv_data = data[0];
      let Ho_ten_data = data[1];
      let bool1 = true;
      let bool2 = true;
      if (
        this.Filter.value.Ma_sv_filter != undefined ||
        this.Filter.value.Ma_sv_filter != null
      ) {
        var a = Ma_sv_data;
        var b = this.Filter.value.Ma_sv_filter;
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (
        this.Filter.value.Ho_ten_filter != undefined ||
        this.Filter.value.Ho_ten_filter != null
      ) {
        var a = Ho_ten_data;
        var b = this.Filter.value.Ho_ten_filter;
        bool2 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (bool1 && bool2) {
        return true;
      }
      return false;
    });
  }
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.ID = Data.ID_danh_sach_den;
  }
  //check value
  get checkvalue() {
    return this.Action.controls;
  }
  search() {
    if (this.Action.controls.code.value == null) {
      return false;
    }
    this.RegisterDormService.getSVbyMa(
      this.Action.controls.code.value,
      this.Token
    ).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 5) {
        this.toastr.error('Mã sinh viên không đúng. Xin vui lòng nhập lại!');
      } else if (z.Status == 1) {
        this.ID = z.Data[0].ID_sv;
        if (z.Data[0].Ngay_sinh != null) {
          var date = new Date(z.Data[0].Ngay_sinh);
          var latest_date = this.datePipe.transform(date, 'dd/MM/yyyy');
        }
        this.Action.patchValue({
          Ma_sv: z.Data[0].Ma_sv ? z.Data[0].Ma_sv : '',
          Ho_ten: z.Data[0].Ho_ten ? z.Data[0].Ho_ten : '',
          Ngay_sinh: z.Data[0].Ngay_sinh ? latest_date : '',
          Que_quan: z.Data[0].Que_quan ? z.Data[0].Que_quan : '',
        });
      }
    });
  }
  onSubmit() {
    if (this.Action.controls.Ho_ten.value == null) {
      this.toastr.warning('Bạn chưa nhập sinh viên!');
      return false;
    }
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.Create();
  }
  onReset() {
    this.submitted = false;
    this.Action.reset();
  }
  // create

  //modal
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    this.BlackListService.Delete(this.ID, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('AddModal');
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  Create() {
    let input = {
      ID_sv: this.ID,
      Ghi_chu: this.Action.controls.Ghi_chu.value,
    };
    this.BlackListService.Insert(input, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('AddModal');
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_DSHanChe;
    window.open(urlHelp, '_blank');
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RoomService } from 'src/app/utils/services/room.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { DeviceService } from 'src/app/utils/services/device.service';
@Component({
  selector: 'app-roomdetail',
  templateUrl: './roomdetail.component.html',
  styleUrls: ['./roomdetail.component.scss'],
})
export class RoomdetailComponent implements OnInit {
  dtOptions: DataTables.Settings;
  DSThietBi: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  IDphong: any;
  Maphong: any;
  ID_phong_thiet_bi: any;
  ID_phong: any;
  ID_thiet_bi: any;
  Ma_thiet_bi: any;
  Ten_thiet_bi: any;
  Trang_thai: any;
  So_luong: any;
  Ghi_chu: any;
  Ma_thiet_bi_ft: any;
  Ten_thiet_bi_dt: any;
  Trang_thai_ft: any = '';
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách thiết bị trong phòng ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Insert = new FormGroup({
    ID_phong_thiet_bi: new FormControl(null),
    ID_phong: new FormControl(null),
    ID_thiet_bi: new FormControl(null, [Validators.required]),
    Trang_thai: new FormControl(null),
    So_luong: new FormControl(null, [Validators.required]),
    Ghi_chu: new FormControl(null, [Validators.maxLength(500)]),
  });
  Fillter = new FormGroup({
    Ma_thiet_bi_ft: new FormControl(null),
    Ten_thiet_bi_dt: new FormControl(null),
    Trang_thai_ft: new FormControl(null),
  });
  constructor(
    private modalService: NgbModal,
    private RoomService: RoomService,
    private DeviceService: DeviceService,
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.IDphong = params.ID_Phong;
      this.Maphong = params.Ma_phong;
    });

    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_thiet_bi_dt = data[3];
      let Ten_thiet_bi_dt = data[4];
      let Trang_thai_dt = data[5];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let fillter = true;
      if (this.Ma_thiet_bi_ft != undefined) {
        var a = this.removeAccents(Ma_thiet_bi_dt);
        var b = this.removeAccents(this.Ma_thiet_bi_ft);
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Ten_thiet_bi_dt != undefined) {
        var a = this.removeAccents(Ten_thiet_bi_dt);
        var b = this.removeAccents(this.Ten_thiet_bi_dt);
        bool2 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (this.Trang_thai_ft != '') {
        bool3 = Trang_thai_dt == this.Trang_thai_ft;
      } else {
        bool3 = true;
      }
      fillter = bool1 && bool2 && bool3;
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID phòng thiết bị',
          visible: false,
        },
        {
          title: 'ID phòng',
          visible: false,
        },
        {
          title: 'ID thiết bị',
          visible: false,
        },
        {
          title: 'Mã thiết bị',
        },
        {
          title: 'Tên thiết bị',
        },
        {
          title: 'Trạng thái',
          visible: false,
        },
        {
          title: 'Trạng thái',
        },
        {
          title: 'Số lượng',
        },
        {
          title: 'Ghi chú',
        },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
    };
    this.DeviceService.getList(a.Token).subscribe((z) => {
      this.DSThietBi = z.Data;
      this.getList(a.Token);
    });
  }
  removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  getList(token) {
    this.spinner.show();
    this.RoomService.PhongThietBiGetAll(this.IDphong, token).subscribe((z) => {
      for (var i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Trang_thai == false) {
          z.Data[i].Trang_thai_text = 'Không sử dụng';
        } else {
          z.Data[i].Trang_thai_text = 'Đang sử dụng';
        }
      }
      this.dataTable = z.Data;
      this.dtTrigger.next();
      this.Title = '';
      this.TitleClass = '';
      this.spinner.hide();
    });
  }
  //get
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa';
      this.checkInsert = false;
      this.Insert.get('ID_thiet_bi').setValue(Data.ID_thiet_bi);
      this.Insert.get('Trang_thai').setValue(Data.Trang_thai);
      this.Insert.get('So_luong').setValue(Data.So_luong);
      this.Insert.get('Ghi_chu').setValue(Data.Ghi_chu);
      this.ID_phong_thiet_bi = Data.ID_phong_thiet_bi;
      this.ID_thiet_bi = Data.ID_thiet_bi;
      this.Trang_thai = Data.Trang_thai;
      this.So_luong = Data.So_luong;
      this.Ghi_chu = Data.Ghi_chu;
    } else if (Loai == 'Add') {
      this.onReset();
      this.titleModal = 'Thêm mới';
      this.checkInsert = true;
      this.Insert.reset();
      this.ID_thiet_bi = this.DSThietBi[0].ID;
      this.Trang_thai = true;
    } else if (Loai == 'Delete') {
      this.ID_phong_thiet_bi = Data.ID_phong_thiet_bi;
    }
  }
  //check value
  get checkvalue() {
    return this.Insert.controls;
  }
  filterById() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
    this.ID_phong_thiet_bi = '';
    this.ID_thiet_bi = '';
    this.Trang_thai = '';
    this.So_luong = '';
    this.Ghi_chu = '';
  }
  // create
  create() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let InputDatas = {
      ID_phong_thiet_bi: this.ID_phong_thiet_bi,
      ID_Phong: this.IDphong,
      ID_thiet_bi: this.ID_thiet_bi,
      So_luong: this.So_luong,
      Trang_thai: this.Trang_thai,
      Ghi_chu: this.Ghi_chu,
    };
    if (this.checkInsert == false) {
      this.RoomService.PhongThietBiUpdate(InputDatas, a.Token).subscribe(
        (z) => {
          if (z.Status == 2) {
            this.toastr.warning(z.Message);
          } else if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else if (z.Status == 4) {
            this.toastr.error('Cập nhật không thành công!');
          }
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList(a.Token);
        }
      );
    } else {
      this.RoomService.PhongThietBiInsert(InputDatas, a.Token).subscribe(
        (z) => {
          if (z.Status == 2) {
            this.toastr.success('Thêm mới thất bại!');
          } else if (z.Status == 1) {
            this.toastr.success('Thêm mới thành công!');
            this.modalService.dismissAll('AddModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList(a.Token);
          } else if (z.Status == 8) {
            this.toastr.warning(z.Message);
          }
        }
      );
    }
  }
  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let Data = {
      ID_phong_thiet_bi: this.ID_phong_thiet_bi,
    };
    this.RoomService.PhongThietBiDelete(Data, a.Token).subscribe((z) => {
      this.modalService.dismissAll('AddModal');
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList(a.Token);
    });
  }
}

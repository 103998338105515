import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DashBoardService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(UserID, khoa_hoc, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          `DashBoard/DashBoard_List?UserID=${UserID}&khoa_hoc=${khoa_hoc}`,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getListKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DashBoard/Load_Khoa_Hoc', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-6">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
            </li>
            <li class="breadcrumb-item active">
              <a [routerLink]="['/admin/receipt-destroy']">Biên lai hủy</a>
            </li>
            <li class="breadcrumb-item">Biên lai chi tiết</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <form class="form-horizontal" [formGroup]="Action">
          <div class="row">
            <div class="col-md-4 form-group">
              <label class="control-label require">
                Mã sinh viên
              </label>
              <div class="input-group">
                <input formControlName="Ma_sv" class="form-control" placeholder="Nhập mã sinh viên" type="text" [ngClass]="{
                    'is-invalid': submitted && checkvalue.Ma_sv.errors
                  }" />
                <div class="input-group-append" *ngIf="!ID_bien_lai">
                  <span class="input-group-text" (click)="FillSutudent()"><i class="fas fa-search"></i></span>
                </div>
                <div *ngIf="submitted && checkvalue.Ma_sv.errors" class="invalid-feedback">
                  <div *ngIf="checkvalue.Ma_sv.errors.required">
                    Mã sinh viên không được bỏ trống!
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label require">
                Tên sinh viên
              </label>
              <input disabled formControlName="Ten_sv" class="form-control" placeholder="Tên sinh viên" type="text"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.Ten_sv.errors
              }"
              />
              <div *ngIf="submitted && checkvalue.Ten_sv.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ten_sv.errors.required">
                  Tên sinh viên không được bỏ trống!
                </div>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label require">Số biên lai</label>
              <input class="form-control" required placeholder="Nhập Số biên lai" type="number" formControlName="So_phieu"
                id="So_phieu" [ngClass]="{
                  'is-invalid': submitted && checkvalue.So_phieu.errors
                }" />
              <div *ngIf="submitted && checkvalue.So_phieu.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.So_phieu.errors.required">
                  Số biên lai không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.So_phieu.errors.maxlength">
                  Số biên lai tối đa 20 ký tự!
                </div>
              </div>
            </div>
  
            <div class="col-md-4 form-group">
              <label class="control-label require">Phòng</label>
              <select class="form-control" formControlName="ID_phong" (change)="onChangePhong($event)"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.ID_phong.errors
              }"
              >
                <option *ngFor="let item of dataRooms" value="{{ item.ID_Phong }}">{{ item.Ten_phong }}</option>
              </select>
              <div *ngIf="submitted && checkvalue.ID_phong.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.ID_phong.errors.required">
                  Phòng không được bỏ trống!
                </div>
              </div>
            </div>
  
            <div class="col-md-4 form-group">
              <label class="control-label require">
                Ngày thu
              </label>
              <input class="form-control" formControlName="Ngay_thu" type="date"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.Ngay_thu.errors
              }"
              />
              <div *ngIf="submitted && checkvalue.Ngay_thu.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ngay_thu.errors.required">
                  Ngày thu không được bỏ trống!
                </div>
              </div>
            </div>
            <div class="col-md-4 form-group">
              <label class="control-label require">
                Người thu
              </label>
              <input class="form-control" formControlName="Nguoi_thu" type="text" placeholder="Nhập người thu ..." 
              [ngClass]="{
                'is-invalid': submitted && checkvalue.Nguoi_thu.errors
              }"
              />
              <div *ngIf="submitted && checkvalue.Nguoi_thu.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Nguoi_thu.errors.required">
                  Người thu không được bỏ trống!
                </div>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <label class="control-label require">Nội dung biên lai</label>
              <textarea class="form-control" required placeholder="Nhập Nội dung biên lai" type="text"
                formControlName="Noi_dung" [ngClass]="{
                  'is-invalid': submitted && checkvalue.Noi_dung.errors
                }"></textarea>
              <div *ngIf="submitted && checkvalue.Noi_dung.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Noi_dung.errors.required">
                  Nội dung biên lai không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Noi_dung.errors.maxlength">
                  Nội dung biên lai tối đa 255 ký tự!
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table-bordered dataTables_wrapper no-footer">
              <thead>
                <tr>
                  <th class="text-center">
                    <input type="checkbox" name="all" [checked]="isAllChecked()" (change)="checkAll($event)" />
                  </th>
                  <th style="width: 30%;">Nội dung</th>
                  <th>Tên khoản thu</th>
                  <th>Học kì</th>
                  <th>Năm học</th>
                  <th>Tháng</th>
                  <th>Năm</th>
                  <th>Ngày quy định</th>
                  <th>Số tiền</th>
                  <th>Phần trăm miễn giảm</th>
                  <th>Số tiền miễn giảm</th>
                  <th>Thành tiền</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataDetailReceipt">
                  <td class="text-center">
                    <input type="checkbox" name="pcb[]" value="{{ p.id }}" [(ngModel)]="p.state" />
                  </td>
                  <td>{{ p.Noi_dung }}</td>
                  <td>{{ p.Ten_Khoan_thu }}</td>
                  <td>{{ p.Hoc_ky }}</td>
                  <td>{{ p.Nam_hoc }}</td>
                  <td>{{ p.Thang }}</td>
                  <td>{{ p.Nam }}</td>
                  <td>{{ p.Ngay_quy_dinh | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.So_tien | currency: 'VND' }}</td>
                  <td>{{ p.Phan_tram_mien_giam }}</td>
                  <td>{{ p.So_tien_mien_giam }}</td>
                  <td style="color: red;">
                    {{ p.Thanh_tien | currency: 'VND' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <ng-template #Confirm let-modal>
    <div class="modal-content">
      <div class="modal-body">
        <div class="icon-box">
          <i class="fas fa-trash-alt" style="
              color: #f15e5e;
              font-size: 46px;
              display: inline-block;
              margin-top: 13px;
            "></i>
        </div>
        <p style="
            color: inherit;
            text-decoration: none;
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            margin-top: 3em;
          ">
          Bạn có chắc chắn tạo biên lai
        </p>
        <div class="text-center">
          <div *ngIf="ID_bien_lai">
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')">
              Hủy bỏ
            </button>
            <button type="button" class="btn btn-md btn-success" (click)="Print()" style="margin-left: 1em;">
              Đồng ý
            </button>
          </div>
          <div *ngIf="!ID_bien_lai">
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')">
              Hủy bỏ
            </button>
            <button type="button" class="btn btn-md btn-success" (click)="Add()" style="margin-left: 1em;">
              Đồng ý
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <div id="print-section" style="display: none;">
    <app-print-receipt [dt_print]="dt_print"></app-print-receipt>
  </div>
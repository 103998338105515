import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CollectionReceiptService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Receipt/ReceiptListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  GetAllDetailreceipt(id ,id_phong , token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'Receipt/DetailReceiptListAll?id_bien_lai=' + id +'&ID_phong=' + id_phong,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  GetAllCodeDetailreceipt(code, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
          'Receipt/DetailReceiptListAllMSV?Ma_sv=' +
          code,
        0,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  Insert(req, dsid, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Receipt/ReceiptAdd?dsid=' + dsid,
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(dt, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'Receipt/ReceiptEdit',dt,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  } //hủy biên lai

  getListDestroy(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'Receipt/ReceiptListAllDestroy', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  } // lish all biên lai hủy

}

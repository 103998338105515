<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Đăng kí nội trú</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<ngb-tabset [destroyOnHide]="false">
  <ngb-tab>
    <ng-template ngbTabTitle>
      Đăng kí nội trú
    </ng-template>
    <ng-template ngbTabContent>
      <app-portal-dang-ky></app-portal-dang-ky>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>
      Hộp thư đến &nbsp;
      <span class="badge">{{So_thong_bao}}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <app-portal-ket-qua></app-portal-ket-qua>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['//admin']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Tra cứu log</li>
        </ol>
      </div>
      <!-- <div class="col-md-6">
          <button
            class="btn btn-success btn-sm"
            (click)="open(ThemModal, 'xs', 'Add', '')"
            (click)="onReset()"
            style="float: right;"
          >
            <i class="fas fa-plus-circle"></i> Thêm mới
          </button>
        </div> -->
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <label>Chức năng</label>
          <select class="form-control cus-fs" [(ngModel)]="FillChucNang">
            <option value="">Chọn chức năng</option>
            <option value="1500">1. Đăng ký</option>
            <option value="1501">2. Xếp phòng</option>
            <option value="1502">3. Danh sách ở</option>
            <option value="1503">4. Biên lai hóa đơn</option>
            <option value="1504">5. Cơ sở</option>
            <option value="1505">6. Tòa nhà</option>
            <option value="1506">7. Phòng</option>
            <option value="1507">8. Loại phòng</option>
            <option value="1508">9. Thiết bị</option>
            <option value="1509">10. Quy định đăng ký</option>
            <option value="1510">11. Danh sách hạn chế</option>
            <option value="1511">12. Danh sách ra</option>
            <option value="1513">13. Quy định khoản thu</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Thao tác</label>
          <select class="form-control cus-fs" [(ngModel)]="FillThaoTac">
            <option value="3">Chọn chức năng</option>
            <option value="0">Thêm</option>
            <option value="1">Sửa</option>
            <option value="2">Xóa</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Từ ngày</label>
          <input type="date" class="form-control" [(ngModel)]="FillFromDate">
        </div>
        <div class="col-md-3">
          <label>Đến ngày</label>
          <input type="date" class="form-control" [(ngModel)]="FillToDate">
        </div>
        <div class="col-md-3 float-left">
          <label> </label>
          <button class="btn btn-info btn-sm" (click)="getList()" ngbTooltip="Tìm kiếm"
            style="float: left; margin-top: 30px;">
            <i class="fas fa-search"></i> Tìm kiếm
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable">
                    <td *ngIf="p.Chuc_nang=='1500'">Đăng ký</td>
                    <td *ngIf="p.Chuc_nang=='1501'">Xếp phòng</td>
                    <td *ngIf="p.Chuc_nang=='1502'">Danh sách ở</td>
                    <td *ngIf="p.Chuc_nang=='1503'">Biên lai hóa đơn</td>
                    <td *ngIf="p.Chuc_nang=='1504'">Cơ sở</td>
                    <td *ngIf="p.Chuc_nang=='1505'">Tòa nhà</td>
                    <td *ngIf="p.Chuc_nang=='1506'">Phòng</td>
                    <td *ngIf="p.Chuc_nang=='1507'">Loại phòng</td>
                    <td *ngIf="p.Chuc_nang=='1508'">Thiết bị</td>
                    <td *ngIf="p.Chuc_nang=='1509'">Quy định đăng ký</td>
                    <td *ngIf="p.Chuc_nang=='1510'">Danh sách hạn chế</td>
                    <td *ngIf="p.Chuc_nang=='1511'">Danh sách ra</td>
                    <td *ngIf="p.Chuc_nang=='1513'">Quy định khoản thu</td>
                    <td>{{ p.Su_kien }}</td>
                    <td style="word-break: break-all;">{{ p.Noi_dung }}</td>
                    <td>{{ p.May_tram }}</td>
                    <td>{{ p.Thoi_diem | date: 'dd/MM/yyy , h:mm:ss a' }}</td>
                    <td>{{ p.User_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class StudentSortService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {}
  ListAll(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSort/ListAll', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Sort(token, req): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSort/SortCustom', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  SortManual(token, req): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSort/SortManual', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  List_He(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniSystemAPI + 'He/Danh_sach_he', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  List_Khoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniSystemAPI + 'Khoa/Danh_sach_khoa', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  List_ChuyenNganh(token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniSystemAPI + 'ChuyenNganh/Danh_sach_chuyen_nganh',
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'StudentSort/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSort/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getStudentManual(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'StudentSortManual/ListAll', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ExportDanhSachXepPhongTiet(token, req): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'StudentSort/ExportDanhSachXepPhongTiet',
        req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class StatisticalMoneyService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  GetAll(req,token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'StatisticalMoney/ListAll',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Count(req,token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'StatisticalMoney/Count',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CountFilter(req,token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'StatisticalMoney/CountFilter',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  TotalFilter(req,token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI +
        'StatisticalMoney/TotalFilter',req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Export(data: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'StatisticalMoney/ExportExcel',
        data,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  
  ExportTienIch(data: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.UniDormAPI + 'StatisticalMoney/ExportExcelTienIch',
        data,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  KhoanNopKTX(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'StatisticalMoney/KhoanNopKTX', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

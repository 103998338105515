import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DiemPhongService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}
  Excel(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemPhong/ExportExcel', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getList(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemPhong/ListAll', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemPhong/Insert', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'DiemPhong/Delete?ID_diem_phong=' + ID,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemPhong/Update', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'DiemPhong/Count', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  getCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'DiemPhong/CountFilter', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  updateStatus(ID_phong, Trang_thai, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'DiemPhong/UpdateStatus?ID_phong=' +
          ID_phong +
          '&Trang_thai=' +
          Trang_thai,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  updateStatusByHocKy(Hoc_ky, Nam_hoc, Trang_thai, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'DiemPhong/UpdateStatusByHocKy?Hoc_ky=' +
          Hoc_ky +
          '&Nam_hoc=' +
          Nam_hoc +
          '&Trang_thai=' +
          Trang_thai,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Detail(ID_phong, Hoc_ky, Nam_hoc, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'DiemPhong/Detail?ID_phong=' +
          ID_phong +
          '&Hoc_ky=' +
          Hoc_ky +
          '&Nam_hoc=' +
          Nam_hoc,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

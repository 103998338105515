import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { common } from 'src/app/app.common';
import { forkJoin, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../base/base.component';
import { RequestPhong } from 'src/app/Models/input.model/RequestPhong';
import { PlanRoomComponent } from '../../plan-room/plan-room.component';
@Component({
  selector: 'app-filter-sv',
  templateUrl: './filter-sv.component.html',
  styleUrls: ['./filter-sv.component.scss'],
})
export class FilterSVComponent extends BaseComponent implements OnInit {
  @Output() LoadParent: EventEmitter<any> = new EventEmitter();
  @Input() dtPhong = new RequestPhong();
  @Input() isManual: boolean;
  @ViewChild('SortRoomModal') SortRoomModal: TemplateRef<any>;
  datadetail: any;
  closeResult: string;
  Token: string;
  DSCoSo: any;
  DSToaNha: any;
  DSToaNha2: any;
  DSPhong: any;
  DSPhong2: any;
  DSHe: any;
  DSKhoa: any;
  DSChuyenNganh: any;
  DSKhuVuc: any;
  DSDoiTuong: any;
  DSLoaiPhong: any;
  submitted = false;
  dataTable: any = [];
  dataTable2: any = [];
  dataMain: any = [];
  SelectData: any = [];
  code_filter: any;
  name_filter: any;
  NguyenVong_filter: any;
  NgaySinh_filter: any;
  Nhap_hoc_filter: any;
  Loai_phong_filter: any;
  Thanh_toan_filter: any;
  Doi_tuong_chinh_sach_filter: any;
  He_filter: any;
  Khoa_filter: any;
  Khoa_hoc_filter: any;
  Chuyen_nganh_filter: any;
  Khu_vuc_filter: any;
  khoa_filter: any;
  lop_filter: any;
  StudentData: any = [];
  doituong_filter: any;
  SelectNum: any;
  Fillter: FormGroup;
  totalItem: any;
  totalItemFilter: any;
  ActionSave = new FormGroup({
    Ngay_vao: new FormControl(null, [Validators.required]),
    Ngay_ra: new FormControl(null, [Validators.required]),
    ThangO: new FormControl(null),
  });
  Insert = new FormGroup({
    ID_Phong: new FormControl(null, [Validators.required]),
    ID_co_so: new FormControl(null, [Validators.required]),
    ID_toa_nha: new FormControl(null, [Validators.required]),
  });
  ThangQuyDinh: any;
  ThangThucTe: any;
  public com: common;
  visible: any;
  ngOnInit(): void {
    this.spinner.show();
    // $('#datatable').DataTable().clear();
    // $('#datatable').DataTable().destroy();
    // $.fn['dataTable'].ext.search = [];
    this.Fillter = new FormGroup({
      code_filter: new FormControl(null),
      name_filter: new FormControl(null),
      khoa_filter: new FormControl(null),
      lop_filter: new FormControl(null),
      doituong_filter: new FormControl(null),
      NguyenVong_filter: new FormControl(null),
      NgaySinh_filter: new FormControl(null),
      Loai_phong_filter: new FormControl(''),
      Thanh_toan_filter: new FormControl(''),
      Doi_tuong_chinh_sach_filter: new FormControl(''),
      He_filter: new FormControl(''),
      Khoa_filter: new FormControl(''),
      Khoa_hoc_filter: new FormControl(null),
      Chuyen_nganh_filter: new FormControl(''),
      Khu_vuc_filter: new FormControl(''),
      Nhap_hoc_filter: new FormControl(''),
      Ma_sv_filter: new FormControl(''),
      Ho_ten_filter: new FormControl(''),
    });

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.getList();
    this.Service.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
    this.CampusService.getList(a.Token).subscribe((z) => {
      this.DSCoSo = z.Data;
      this.BuildingService.getList(a.Token).subscribe((z) => {
        this.DSToaNha = z.Data;
        this.DSToaNha2 = z.Data;
        this.RoomService.GetAll(this.Token).subscribe((z) => {
          for (var i = 0; i < z.Data.length; i++) {
            if (z.Data[i].Loai_phong == false) {
              z.Data[i].Loai_phong_text = 'Nam';
            } else {
              z.Data[i].Loai_phong_text = 'Nữ';
            }
          }
          this.DSPhong = z.Data;
          this.DSPhong2 = z.Data;
        });
      });
    });

    forkJoin({
      KhuVuc: this.SystemService.Get_khuvuc_tuyensinh(this.Token),
      ChuyenNganh: this.Service.List_ChuyenNganh(this.Token),
      He: this.Service.List_He(this.Token),
      Khoa: this.Service.List_Khoa(this.Token),
      DoiTuong: this.SystemService.Get_doituong_chinhsach(this.Token),
      Phong: this.RoomCateGoryService.getList(true, a.Token),
    }).subscribe(
      ({ KhuVuc, ChuyenNganh, He, Khoa, DoiTuong, Phong }) => {
        this.DSKhuVuc = KhuVuc;
        this.DSChuyenNganh = ChuyenNganh;
        this.DSHe = He;
        this.DSKhoa = Khoa;
        this.DSLoaiPhong = Phong;
        this.DSDoiTuong = DoiTuong;
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }
  cosochange() {
    let ID_coso = $('#ID_co_so').val();
    this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
      return pa.ID_CoSo == ID_coso;
    });
    let idtn = this.DSToaNha2.length == 0 ? 0 : this.DSToaNha2[0].ID_ToaNha;
  }
  ToaNhaChange() {
    let ID_toanha = $('#ID_toa_nha').val();
    this.DSPhong2 = jQuery.grep(this.DSPhong, function (pa: any, i) {
      return pa.ID_Toa_Nha == ID_toanha;
    });
  }
  getList() {
    this.page = 1;
    let req = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Nhap_hoc: '',
      Thanh_toan: this.Fillter.value.Thanh_toan_filter,
      Gioi_tinh: this.dtPhong.Loai_phong == true ? 'Nữ' : 'Nam',
      Ten_loai_phong: this.dtPhong.Ten_loai_phong,
      Nguyen_vong:
        this.Fillter.value.NguyenVong_filter == null
          ? ''
          : this.Fillter.value.NguyenVong_filter,
      Doi_tuong_chinh_sach: this.Fillter.value.Doi_tuong_chinh_sach_filter,
      Ngay_sinh:
        this.Fillter.value.NgaySinh_filter == null
          ? ''
          : this.Fillter.value.NgaySinh_filter,
      Ten_he: this.Fillter.value.He_filter,
      Khoa: this.Fillter.value.Khoa_filter,
      Khoa_hoc:
        this.Fillter.value.Khoa_hoc_filter == null
          ? ''
          : this.Fillter.value.Khoa_hoc_filter,
      Chuyen_nganh: this.Fillter.value.Chuyen_nganh_filter,
      Doi_tuong_khu_vuc: this.Fillter.value.Khu_vuc_filter,
      Ma_sv: this.Fillter.value.Ma_sv_filter,
      Ho_ten: this.Fillter.value.Ho_ten_filter,
    };
    this.spinner.show();
    this.Service.ListAll(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.dataTable = z.Data;
          this.Service.getCountFilter(req, this.Token).subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
        } else {
          this.toastr.warning(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  open(content, size, Loai, Data) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    let Ngay_ket_thuc = '05-31-' + (new Date().getFullYear() + 1);
    if (
      this.formatDate(new Date()) <
      this.formatDate(new Date('05-31-' + new Date().getFullYear()))
    ) {
      Ngay_ket_thuc = '05-31-' + new Date().getFullYear();
    }
    this.ActionSave.patchValue({
      Ngay_vao: this.formatDate(new Date()),
      Ngay_ra: this.formatDate(new Date(Ngay_ket_thuc)),
    });
    this.GetMonth();
    // this.ID_co_so=this.DSCoSo[0].ID;
    // this.ID_toa_nha=this.DSToaNha[0].ID_ToaNha;
    let ID_coso = this.DSCoSo[0].ID;
    this.DSToaNha2 = jQuery.grep(this.DSToaNha, function (pa: any, i) {
      return pa.ID_CoSo == ID_coso;
    });
    let idtn = this.DSToaNha2.length == 0 ? 0 : this.DSToaNha2[0].ID_ToaNha;
    this.Insert.patchValue({
      ID_co_so: this.DSCoSo[0].ID,
      ID_toa_nha: idtn,
    });
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  onSubmit() {
    if (this.ActionSave.invalid) {
      this.toastr.warning('Vui lòng chọn ngày nhận phòng và ngày kết thúc !');
      return false;
    }
    if (this.dataTable2.length <= this.dtPhong.Suc_chua) {
      let InputDatas = {
        Thu_tu_doi_tuong: 2,
        Thu_tu_phong: 2,
        Ngay_bat_dau: this.ActionSave.value.Ngay_vao,
        Ngay_ket_thuc: this.ActionSave.value.Ngay_ra,
        Students: this.dataTable2,
        Rooms: [this.dtPhong],
      };
      this.spinner.show();
      this.Service.Sort(this.Token, InputDatas).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message, 'Xếp phòng thành công');
        } else {
          this.toastr.warning(z.Message, 'Thao tác thất bại');
        }
        this.LoadParent.emit(); //emit event
        this.modalService.dismissAll('Cross click');
      });
    } else {
      this.toastr.warning('Sức chứa không đủ, vui lòng kiểm tra lại');
    }
  }
  navigate() {
    if (this.dataTable2.length > 0) {
      this.open(this.SortRoomModal, 'xs', '', '');
    } else {
      this.toastr.warning('Vui lòng chọn sinh viên cần xếp phòng');
    }
  }

  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let req = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      Nhap_hoc: '',
      Thanh_toan: this.Fillter.value.Thanh_toan_filter,
      Gioi_tinh: this.dtPhong.Loai_phong == true ? 'Nữ' : 'Nam',
      Ten_loai_phong: this.dtPhong.Ten_loai_phong,
      Nguyen_vong:
        this.Fillter.value.NguyenVong_filter == null
          ? ''
          : this.Fillter.value.NguyenVong_filter,
      Doi_tuong_chinh_sach: this.Fillter.value.Doi_tuong_chinh_sach_filter,
      Ngay_sinh:
        this.Fillter.value.NgaySinh_filter == null
          ? ''
          : this.Fillter.value.NgaySinh_filter,
      Ten_he: this.Fillter.value.He_filter,
      Khoa: this.Fillter.value.Khoa_filter,
      Khoa_hoc:
        this.Fillter.value.Khoa_hoc_filter == null
          ? ''
          : this.Fillter.value.Khoa_hoc_filter,
      Chuyen_nganh: this.Fillter.value.Chuyen_nganh_filter,
      Doi_tuong_khu_vuc: this.Fillter.value.Khu_vuc_filter,
      Ma_sv: this.Fillter.value.Ma_sv_filter,
      Ho_ten: this.Fillter.value.Ho_ten_filter,
    };
    this.spinner.show();
    this.Service.ListAll(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.dataTable = z.Data;
          this.Service.getCountFilter(req, this.Token).subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.spinner.hide();
          });
        } else {
          this.toastr.warning(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        } else if (err.status == 401) {
          this.dataTable = [];
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }

  checkAllCheckBox2(ev) {
    if (this.dataTable2.length) {
      this.dataTable2.forEach((x) => (x.checked = ev.target.checked));
    }
  }
  isAllCheckBoxChecked2() {
    if (this.dataTable2.length) {
      if (this.dataTable2 != undefined)
        return this.dataTable2.every((p) => p.checked);
    }
  }

  PushCheckRow() {
    for (let i = 0; i < this.dataTable.length; i++) {
      if (this.dataTable[i].checked == true) {
        const check = this.dataTable2.some(
          (el) => el.ID_sv === this.dataTable[i].ID_sv
        );
        if (!check) {
          this.dataTable2.push(this.dataTable[i]);
        }
        this.dataTable.splice(i, 1);
        i--;
      }
    }
  } // thêm row check

  SpliceCheckRow() {
    for (let i = 0; i < this.dataTable2.length; i++) {
      if (this.dataTable2[i].checked == false) {
        const check = this.dataTable.some(
          (el) => el.ID_sv === this.dataTable2[i].ID_sv
        );
        if (!check) {
          this.dataTable.push(this.dataTable2[i]);
        }
        this.dataTable2.splice(i, 1);
        i--;
      }
    }
  } // Xóa row check

  GetMonth() {
    let SoThangO = 0;
    let Ngay_ket_thuc = new Date(this.ActionSave.value.Ngay_ra);
    let Ngay_bat_dau = new Date(this.ActionSave.value.Ngay_vao);

    if (Ngay_ket_thuc.getFullYear() > Ngay_bat_dau.getFullYear()) {
      SoThangO = 12 - Ngay_bat_dau.getMonth() + Ngay_ket_thuc.getMonth();
    } else {
      SoThangO = Ngay_ket_thuc.getMonth() - Ngay_bat_dau.getMonth();
    }
    if (Ngay_bat_dau != Ngay_ket_thuc) {
      SoThangO -= 1;
      if (30 - Ngay_bat_dau.getDate() > 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
      if (30 - Ngay_ket_thuc.getDate() < 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
    }
    this.ActionSave.patchValue({
      ThangO: SoThangO,
    });
  } //Lấy ra số tháng ở từ ngày đến ngày
}

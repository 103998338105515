import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-doi-tuong-chinh-sach',
  templateUrl: './doi-tuong-chinh-sach.component.html',
  styleUrls: ['./doi-tuong-chinh-sach.component.scss']
})
export class DoiTuongChinhSachComponent extends BaseComponent implements OnInit {
  ID:any
  ID_doi_tuong
  DSDoiTuong:any
  ngOnInit(): void {
  
    this.getToken();
    this.getAll();
  }
  getDoiTuongChinhSach(){
    this.SystemService.Get_doituong_chinhsach(this.Token).subscribe((z) => {
      this.DSDoiTuong = z;
    });
  }
  getAll() {
    this.spinner.show();
    this.DoiTuongChinhSachService.getAll(this.Token).subscribe(
      (z) => {
        this.getDoiTuongChinhSach()
        this.spinner.hide();
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.router.navigate(['/admin']);
          this.spinner.hide();
        } else {
          this.dataTable = z;
        }
      }
    );
  }

 
  open(content, sizm, Loai, Data) {
    this.ID = Data.id;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  Delete(){
    this.DoiTuongChinhSachService.delete(this.ID,this.Token).subscribe((res)=>{
     if (res.Status == 1) {
        this.toastr.success(res.Message);
        this.modalService.dismissAll('AddModal');
        this.getAll();
      }else {
        this.toastr.warning(res.Message);
      }
    })
  }
 
  onSubmit() {
    if(this.ID_doi_tuong != null){
      this.DoiTuongChinhSachService.add(this.ID_doi_tuong, this.Token).subscribe(
        (res) => {
          if (res.Status == 2) {
            this.toastr.warning(res.Message);
          } else if (res.Status == 1) {
            this.toastr.success(res.Message);
            this.modalService.dismissAll('AddModal');
            this.getAll();
          } else if (res.Status == 4) {
            this.toastr.error('Thêm mới thất bại!');
          } else {
            this.toastr.warning(res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
    }else{
      this.toastr.warning("Bạn chưa chọn đối tượng chính sách")
    }
    
  }

}

import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout, zipAll } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { ExportService } from 'src/app/utils/services/export.service';
import { RegulationObjectService } from 'src/app/utils/services/regulation-object.service';
import { CampusService } from 'src/app/utils/services/campus.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { ListallService } from 'src/app/utils/services/listall.service';
import { StudentSortService } from 'src/app/utils/services/student-sort.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

@Component({
  selector: 'app-regulation-object',
  templateUrl: './regulation-object.component.html',
  styleUrls: ['./regulation-object.component.scss'],
})
export class RegulationObjectComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dtOptions2: any;
  Token: string;
  datadetail: any;
  Res: any;
  req: any;
  check: any;
  ID: any;
  Nam_hoc: any;
  Khoa_hoc: any;
  ID_toa_nha: any;
  ID_khoa: any;
  dataTable: any = [];
  dataKhoaHoc: any = [];
  dataCoSo: any = [];
  dataToaNha: any = [];
  dataHe: any = [];
  dataKhoa: any = [];
  closeResult: string;
  ClassData: any;
  default: any;
  ListTN: any;
  Nam_hoc_arr: any = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  titleName: string;
  Request: any;
  id_selected: any;

  Insert = new FormGroup({
    ID_he: new FormControl(null),
    ID_quy_dinh_dt: new FormControl(0),
    ID_khoa: new FormControl('0'),
    Khoa_hoc: new FormControl('0'),
    Nam_hoc: new FormControl('0'),
    ID_coso: new FormControl(''),
    So_thang: new FormControl(0, [Validators.required]),
    Tu_ngay: new FormControl(null, [Validators.required]),
    Den_ngay: new FormControl(null, [Validators.required]),
    He_so_tien: new FormControl(1.0, [Validators.required]),
    Gioi_tinh: new FormControl(null),
    Hoc_ky_3: new FormControl(false),
    Doi_tuong_cs: new FormControl(false),
  });
  Fillter = new FormGroup({
    He_filter: new FormControl(null),
    Khoa_filter: new FormControl(null),
    Khoa_hoc_filter: new FormControl(null),
    Nam_hoc_filter: new FormControl(''),
    Tu_ngay: new FormControl(null),
    Den_ngay: new FormControl(null),
  });
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private RegulationObjectService: RegulationObjectService,
    private StudentSortService: StudentSortService,
    private CampusService: CampusService,
    private BuildingService: BuildingService,
    private ListallService: ListallService,
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public exportService: ExportService
  ) {}
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  public com: common;

  ngOnInit() {
    var yearNow = new Date().getFullYear() + 1;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Tên hệ',
        },
        {
          title: 'Tên khoa',
        },
        {
          title: 'Khóa học',
        },
        {
          title: 'Năm học',
        },
        {
          title: 'Từ ngày',
        },
        {
          title: 'Đến ngày',
        },
        {
          title: 'Giới tính',
        },
        {
          title: 'Học kỳ 3',
        },
        {
          title: 'Hệ số tiền',
        },
        {
          title: 'Đối tượng CS',
        },
        {
          title: 'SL tòa nhà',
        },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
      order: [[1, 'desc']],
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {},
        {
          title: 'Mã tòa nhà',
        },
        {
          title: 'Tên tòa nhà',
        },
      ],
    };

    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.ListTN = z.Data;
      this.getList(a.Token);
    });
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ten_he_data = data[1];
      let Khoa_data = data[2];
      let Khoa_hoc_data = data[3];
      let Nam_hoc_data = data[4];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      if (
        this.Fillter.controls.He_filter.value != '' &&
        this.Fillter.controls.He_filter.value != undefined
      ) {
        var a = Ten_he_data;
        var b = this.Fillter.controls.He_filter.value;
        bool1 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (
        this.Fillter.controls.Khoa_hoc_filter.value != '' &&
        this.Fillter.controls.Khoa_hoc_filter.value != undefined
      ) {
        let b = Khoa_hoc_data;
        let a = this.Fillter.controls.Khoa_hoc_filter.value;
        bool2 = a == b;
      }
      if (
        this.Fillter.controls.Nam_hoc_filter.value != '' &&
        this.Fillter.controls.Nam_hoc_filter.value != undefined
      ) {
        var a = Nam_hoc_data;
        var b = this.Fillter.controls.Nam_hoc_filter.value;
        bool3 = a.toLowerCase().includes(b.toLowerCase());
      }
      if (
        this.Fillter.controls.Khoa_filter.value != '' &&
        this.Fillter.controls.Khoa_filter.value != undefined
      ) {
        bool4 = Khoa_data.toLowerCase().includes(
          this.Fillter.controls.Khoa_filter.value.toLowerCase()
        );
      }

      if (bool1 && bool2 && bool3 && bool4) {
        return true;
      }
      return false;
    });
  }
  getList(token) {
    this.spinner.show();
    this.RegulationObjectService.GetAll(token).subscribe(
      (z) => {
        console.log('zxc', z.Data);

        z.Data.forEach((element, index) => {
          if (element.ID_toa_nha == '') {
            z.Data[index].SoLuongToaNha = 0;
          } else {
            z.Data[index].SoLuongToaNha = element.ID_toa_nha.split(',').length;
          }
        });
        this.dataTable = z.Data;
        this.dtTrigger.next();
        this.spinner.hide();
        this.StudentSortService.List_He(this.Token).subscribe((z) => {
          this.dataHe = z;
          this.StudentSortService.List_Khoa(this.Token).subscribe((z) => {
            this.dataKhoa = z;
          });
          this.Insert.patchValue({ ID_he: z[0].ID_he });
          this.getKhoaHoc(this.Insert.controls.ID_he.value);
          this.CampusService.getList(this.Token).subscribe((z) => {
            this.dataCoSo = z.Data;
            this.Insert.patchValue({ ID_coso: z.Data[0].ID });
            this.getToaNha(z.Data[0].ID);
          });
        });
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 401) {
          this.dataTable = [];
          this.dtTrigger.next();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
  changeHe() {
    this.getKhoaHoc(this.Insert.controls.ID_he.value);
  }
  changeCoSo() {
    this.getToaNha(this.Insert.controls.ID_coso.value);
  }
  ChangeKhoaHoc() {
    var id_khoa = this.Insert.controls.Khoa_hoc.value;
    let filter = this.dataKhoaHoc.filter((a) => a.Khoa_hoc == id_khoa);
    this.Insert.patchValue({ Nam_hoc: filter[0].Nam_hoc });
  }
  ChangeNamHoc() {
    var nam = this.Insert.controls.Nam_hoc.value;
    let filter = this.dataKhoaHoc.filter((a) => a.Nam_hoc == nam);
    this.Insert.patchValue({ Khoa_hoc: filter[0].Khoa_hoc });
  }
  getKhoaHoc(ID_he) {
    this.dataKhoaHoc = [];
    this.RegulationObjectService.LoadKhoaHoc(ID_he, this.Token).subscribe(
      (z) => {
        let arr = [];
        z.Data.forEach((x) => {
          if (arr.indexOf(x.Khoa_hoc) < 0) {
            arr.push(x.Khoa_hoc);
          }
        });
        this.dataKhoaHoc = arr;
      }
    );
  }
  getToaNha(ID) {
    this.dataToaNha = this.ListTN.filter((d) => d.ID_CoSo == ID);
    this.dtTrigger2.next();
  }
  open(content, sizm, Loai, Data) {
    this.ID = Data.ID_he;
    this.Khoa_hoc = Data.Khoa_hoc;
    this.Nam_hoc = Data.Nam_hoc;
    this.ID_toa_nha = Data.ID_toa_nha;
    this.ID_khoa = Data.ID_khoa;
    this.submitted = false;
    this.id_selected = Data.ID_quy_dinh_dt > 0 ? Data.ID_quy_dinh_dt : 0;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Add') {
      this.spinner.show();
      this.Insert.patchValue({
        Khoa_hoc: '0',
        Tu_ngay: null,
        Den_ngay: null,
        So_thang: 0,
        Nam_hoc: 0,
        Gioi_tinh: null,
        Hoc_ky_3: false,
        ID_khoa: '0',
        Doi_tuong_cs: false,
        He_so_tien: 1.0,
        ID_quy_dinh_dt: 0,
      });
      this.checkInsert = true;
      for (let index = 0; index < this.dataToaNha.length; index++) {
        this.dataToaNha[index].Selected = false;
      }
      this.spinner.hide();
    } else if (Loai == 'Edit') {
      this.spinner.show();
      this.checkInsert = false;
      this.Insert.patchValue({ ID_he: Data.ID_he });
      this.Insert.patchValue({ ID_khoa: Data.ID_khoa });
      this.Insert.patchValue({ Gioi_tinh: Data.Gioi_tinh });
      this.Insert.patchValue({ Hoc_ky_3: Data.Hoc_ky_3 });
      this.Insert.patchValue({ Doi_tuong_cs: Data.Doi_tuong_cs });
      this.changeHe();
      this.Insert.patchValue({ Khoa_hoc: Data.Khoa_hoc });
      this.Insert.patchValue({ Nam_hoc: Data.Nam_hoc });
      this.Insert.patchValue({ So_thang: Data.So_thang });
      this.Insert.patchValue({ Tu_ngay: Data.Tu_ngay });
      this.Insert.patchValue({ Den_ngay: Data.Den_ngay });
      this.Insert.patchValue({ He_so_tien: Data.He_so_tien });
      this.Insert.patchValue({ ID_quy_dinh_dt: Data.ID_quy_dinh_dt });
      let a = Data.ID_toa_nha.split(',');
      let idcs = this.ListTN.filter((d) => d.ID_ToaNha == a[0]);
      this.Insert.patchValue({ ID_coso: idcs[0].ID_CoSo });
      this.dataToaNha = this.ListTN.filter((d) => d.ID_CoSo == idcs[0].ID_CoSo);
      this.BuildingService.getList(this.Token).subscribe((z) => {
        for (let i = 0; i < this.dataToaNha.length; i++) {
          this.dataToaNha[i].Selected = false;
          for (let j = 0; j < a.length; j++) {
            if (a[j] == this.dataToaNha[i].ID_ToaNha.toString()) {
              if ($('#checkbox' + a[j]).prop('checked', true)) {
                {
                  this.dataToaNha[i].Selected = true;
                }
              } else {
                this.dataToaNha[i].Selected = false;
              }
            }
          }
        }
        this.spinner.hide();
      });
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }

  // onSubmit() {
  //   this.submitted = true
  //   if (this.Insert.invalid) {
  //     return false
  //   }
  //   this.create()
  // }
  // onReset() {
  //   this.submitted = false
  //   this.Insert.reset()
  // }
  // create

  Delete() {
    let req = {
      ID_quy_dinh_dt: this.id_selected,
      Danh_sach_toa_nha: this.ID_toa_nha.split(','),
    };
    this.RegulationObjectService.Delete(req, this.Token).subscribe((z) => {
      this.modalService.dismissAll('AddModal');
      if (z.Status == 8) {
        this.toastr.warning('Rằng buộc không thể xóa');
      } else if (z.Status == 1) {
        this.toastr.success('Xóa thành công');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList(this.Token);
      } else {
        this.toastr.warning(z.Message);
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  CheckAll() {
    if ($('#SelectALL').prop('checked')) {
      for (let index = 0; index < this.dataToaNha.length; index++) {
        this.dataToaNha[index].Selected = true;
      }
    } else {
      for (let index = 0; index < this.dataToaNha.length; index++) {
        this.dataToaNha[index].Selected = false;
      }
    }
  }
  SelectRow(ID_ToaNha) {
    for (let index = 0; index < this.dataToaNha.length; index++) {
      if (this.dataToaNha[index].ID_ToaNha == ID_ToaNha) {
        if ($('#checkbox' + ID_ToaNha).prop('checked')) {
          {
            this.dataToaNha[index].Selected = true;
          }
        } else {
          this.dataToaNha[index].Selected = false;
        }
        break;
      }
    }
  }
  OnSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    if (
      this.Insert.controls.Tu_ngay.value >= this.Insert.controls.Den_ngay.value
    ) {
      this.toastr.warning('Từ ngày phải nhỏ hơn đến ngày');
      return false;
    }
    var lst = jQuery.grep(this.dataToaNha, function (pa: any, i) {
      return pa.Selected;
    });
    let stringID_toa_nha = '';
    if (lst.length < 1) {
      this.toastr.warning('Bạn chưa chọn tòa nhà!');
    } else {
      lst.forEach((element, index) => {
        if (index == 0) {
          stringID_toa_nha += element.ID_ToaNha;
        } else {
          stringID_toa_nha += ',' + element.ID_ToaNha;
        }
      });
      let InputDatas = {
        ID_he: this.Insert.controls.ID_he.value,
        ID_khoa: this.Insert.controls.ID_khoa.value,
        Khoa_hoc: this.Insert.controls.Khoa_hoc.value,
        Nam_hoc: this.Insert.controls.Nam_hoc.value,
        ID_toa_nha: stringID_toa_nha,
        So_thang: this.Insert.controls.So_thang.value,
        Tu_ngay: this.Insert.controls.Tu_ngay.value,
        Den_ngay: this.Insert.controls.Den_ngay.value,
        Gioi_tinh: this.Insert.controls.Gioi_tinh.value,
        Hoc_ky_3: this.Insert.controls.Hoc_ky_3.value,
        Doi_tuong_cs: this.Insert.controls.Doi_tuong_cs.value,
        He_so_tien: this.Insert.controls.He_so_tien.value,
        ID_quy_dinh_dt: this.Insert.controls.ID_quy_dinh_dt.value,
      };
      Object.assign(InputDatas, { ID_quy_dinh_dt: this.id_selected });
      if (this.checkInsert == true) {
        this.RegulationObjectService.Insert(InputDatas, this.Token).subscribe(
          (z) => {
            if (z.Status == 2) {
              this.toastr.warning(z.Message);
            } else if (z.Status == 1) {
              this.toastr.success(z.Message);
              this.modalService.dismissAll('AddModal');
              this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                }
              );
              this.getList(this.Token);
            } else if (z.Status == 8) {
              this.toastr.warning(z.Message);
            } else {
              this.toastr.warning(z.Message);
            }
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            if (err.status == 0) {
              localStorage.removeItem('UserInfo');
              this.router.navigate(['/login']);
            } else if (err.status == 401) {
              this.toastr.warning(
                'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
              );
            }
          }
        );
      } else {
        this.RegulationObjectService.Update(InputDatas, this.Token).subscribe(
          (z) => {
            if (z.Status == 2) {
              this.toastr.warning(z.Message);
            } else if (z.Status == 1) {
              this.toastr.success(z.Message);
            } else if (z.Status == 8) {
              this.toastr.warning(z.Message);
            } else {
              this.toastr.warning(z.Message);
            }
            this.modalService.dismissAll('AddModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList(this.Token);
          }
        );
      }
    }
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_QLDangKy;
    window.open(urlHelp, '_blank');
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  NgModel,
} from '@angular/forms';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
import { BaseComponent } from 'src/app/components/base/base.component';
import { LOADIPHLPAPI } from 'dns';
@Component({
  selector: 'app-khen-thuong-ky-luat',
  templateUrl: './khen-thuong-ky-luat.component.html',
  styleUrls: ['./khen-thuong-ky-luat.component.scss'],
})
export class KhenThuongKyLuatComponent extends BaseComponent implements OnInit {
  titleModal: any = '';
  isInsert: any;
  req: any;
  DataToaNha: any;
  show_search: any = true;
  Nam_hoc_arr: any = [];
  Hoc_ky_arr: any = [];
  List_phong_ban: any;
  Ma_kho_checkexist: any;
  Ma_sv: any;
  ID: any;
  public arrNumberPage2_child: any;
  numberPage2: any;
  FillToaNha: any;
  FillPhong: any = '';
  FillQuyetDinh: any = '';
  totalRecord2: any = 0;
  pageSize2: any = 10;
  dataSV2: any = [];
  dataHinhThuc: any = [];
  dataHinhThuc1: any = [];
  page_sv: any = 1;
  beforeFilter: any = [];
  Ngay_sinh: any;
  dataQuyetDinhView: any;
  dataSV: any = [];
  Display: any = false;
  DataKhenThuong: any = [];
  DataRoom: any = [];
  DataThietBi: any = [];
  DataHinhThuc: any = [];
  DataView: any = [];
  DataFilter: any = [];
  dataRoomView: any;
  dataHinhThucView: any;
  dataQuyetDinh: any;
  dataSVO: any;
  Date = new Date();
  FilterForm = new FormGroup({
    ID_phong_ft: new FormControl(''),
    Ten_nha_ft: new FormControl(null),
    So_quyet_dinh_ft: new FormControl(null),
    Hoc_ky_ft: new FormControl(""),
    Nam_hoc_ft: new FormControl(
      this.Date.getFullYear() + '-' + (this.Date.getFullYear() + 1)
    ),
    Ma_sv_ft: new FormControl(null,removeSpaces),
    Ten_sv_ft: new FormControl(null),
    Loai_quyet_dinh_ft: new FormControl(null),
    Ten_thiet_bi_ft: new FormControl(null, [removeSpaces]),
    Tu_ngay_ft: new FormControl(null),
    Den_ngay_ft: new FormControl(null),
  });

  InsertForm = new FormGroup({
    ID_sv: new FormControl(null),
    So_quyet_dinh: new FormControl(null, [Validators.required, removeSpaces]),
    Ngay_quyet_dinh: new FormControl(null),
    ID_hinh_thuc: new FormControl(null, [Validators.required]),
    Loai_quyet_dinh: new FormControl(null, [Validators.required]),
    Cap_ky_luat_khen_thuong: new FormControl(null, [Validators.required]),
    Noi_dung: new FormControl(null),
    ID_phong: new FormControl(null),
    Ten_phong: new FormControl(null),
    Ten_toa_nha: new FormControl(null),
    Hoc_ky: new FormControl(1, [Validators.required]),
    Nam_hoc: new FormControl(
      this.Date.getFullYear() + '-' + (this.Date.getFullYear() + 1),
      [Validators.required]
    ),
  });
  UpdateForm = new FormGroup({
    ID: new FormControl(null),
    ID_sv: new FormControl(null),
    Ho_ten: new FormControl(null,removeSpaces),
    Ma_sv: new FormControl(null,removeSpaces),
    So_quyet_dinh: new FormControl(null, [Validators.required]),
    Ngay_quyet_dinh: new FormControl(),
    ID_hinh_thuc: new FormControl(null, [Validators.required]),
    Loai_quyet_dinh: new FormControl(null, [Validators.required]),
    Cap_ky_luat_khen_thuong: new FormControl(null, [Validators.required]),
    Noi_dung: new FormControl(null),
    ID_phong: new FormControl(null),
    Ten_phong: new FormControl(null),
    Ten_toa_nha: new FormControl(null),
    Hoc_ky: new FormControl(null),
    Nam_hoc: new FormControl(null),
  });
  dtTrigger = new Subject();
  public com: common;
  ngOnInit(): void {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let Hoc_ky_string= this.FilterForm.value.Hoc_ky;
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      this.Hoc_ky_arr.push(object)
      yearNow--;
    }
    this.getToken();
    this.GetAll();
    this.getListRoom();
    this.getListBuilding();
    this.getHoSoSV();
    this.getListHinhThuc();
    this.getListQuyetDinh();
    this.Filter();
  }
  hidesearch() {
    if (this.show_search == true) {
      return (this.show_search = false);
    }
    if (this.show_search == false) {
      return (this.show_search = true);
    }
  }
  GetAll() {
    this.page = 1;
    this.spinner.show();
    this.KhenThuongKyLuatService.GetAll(this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.DataFilter = z.Data;
      this.DataView = this.dataTable.filter(
        (x) =>
          this.pageSize * (this.page - 1) < x.STT &&
          x.STT <= this.pageSize * this.page
      );
      this.numberPage = this.createNumberPage(
        this.DataFilter.length,
        this.pageSize
      ).numberPage;
      this.arrNumberPage_chil = this.createNumberPage(
        this.DataFilter.length,
        this.pageSize
      ).arrNumberPage_chil;
      this.totalItem = z.Data.length;
      this.totalItemFilter = z.Data.length;
      this.spinner.hide();
    });
  }
  getListBuilding() {
    this.FillToaNha = '';
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data;
    });
  }
  ChangeToaNha() {
    let id_toa_nha = this.FilterForm.value.Ten_nha_ft;
    if (id_toa_nha != '') {
      this.dataRoomView = this.dataRoom.filter(
        (x) => x.ID_Toa_Nha == id_toa_nha
      );
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  ChangeSV(){
    let id_sv=this.FilterForm.value.Ten_sv;
    if(id_sv!=''){
      this.dataSVO=this.dataSV.filter((x) =>x.ID_sv==id_sv);
    }else{
      this.dataSVO=this.dataSV;
    }
  }
  changeQuyetDinh(){
    let loai_quyet_dinh=this.InsertForm.value.Loai_quyet_dinh;
    if(loai_quyet_dinh!=''){
      this.dataHinhThucView=this.dataHinhThuc.filter((x) =>x.Loai_hinh_thuc==loai_quyet_dinh);
    }else{
      this.dataHinhThucView=this.dataHinhThuc;
    }
    
  }
  ChangeToaNhaInsert() {
    let id_toa_nha = this.InsertForm.value.Ten_toa_nha;
    if (id_toa_nha != '') {
      this.dataRoomView = this.dataRoom.filter(
        (x) => x.ID_Toa_Nha == id_toa_nha
      );
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  ChangeToaNhaUpdate() {
    let id_toa_nha = this.UpdateForm.value.Ten_toa_nha;
    if (id_toa_nha != '') { this.dataRoomView = this.dataRoom.filter((x) => x.ID_Toa_Nha == id_toa_nha);
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  getListRoom() {
    this.FillPhong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data;
      this.dataRoomView = z.Data;
      //this.Filter();
    });
  } // list phòng
  getListQuyetDinh() {
    this.FillQuyetDinh = '';
    this.HinhThucService.GetAll(this.Token).subscribe((z) => {
      this.dataHinhThuc = z.Data;
      this.dataHinhThucView = z.Data;
      //this.Filter();
    });
  } 
  GetCount(req) {
    this.KhenThuongKyLuatService.Count(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.totalItem = z.Message;
      }
    });
  }
  getListThietBi() {
    this.DeviceService.getList(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.DataThietBi = z.Data;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  getListHinhThuc() {
    this.HinhThucService.GetAll(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.dataHinhThucView = z.Data;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  openModal(content, sizm, category, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (category == 'Insert') {
      this.titleModal = 'Thêm quyết định khen thưởng kỷ luật';
      this.isInsert = true;
      this.getHoSoSV()
      this.DataKhenThuong = []
      this.InsertForm.setValue({
        ID_sv: '',
        So_quyet_dinh: '',
        Ngay_quyet_dinh: '',
        ID_hinh_thuc: '',
        Loai_quyet_dinh: '',
        Cap_ky_luat_khen_thuong: '',
        ID_phong: '',
        Ten_phong: '',
        Noi_dung: '',
        Ten_toa_nha: '',
        Hoc_ky: '',
        Nam_hoc: this.Date.getFullYear() + '-' + (this.Date.getFullYear() + 1),
      });
    }
    if (category == 'Update') {

      this.titleModal = 'Sửa quyết định khen thưởng kỷ luật';
      this.UpdateForm.setValue({
        ID: Data.ID,
        ID_sv: Data.ID_sv,
        Ma_sv: Data.Ma_sv,
        Ho_ten: Data.Ho_ten,
        So_quyet_dinh: Data.So_quyet_dinh,
        Ngay_quyet_dinh: Data.Ngay_quyet_dinh.substr(0, 10),
        ID_hinh_thuc: Data.ID_hinh_thuc,
        Loai_quyet_dinh: Data.Loai_quyet_dinh,
        Cap_ky_luat_khen_thuong: Data.Cap_ky_luat_khen_thuong,
        ID_phong: Data.ID_phong,
        Ten_phong: Data.Ten_phong,
        Noi_dung: Data.Noi_dung,
        Ten_toa_nha: Data.Ten_toa_nha,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc.trim(),
      });
    }
    if (category == 'Delete') {
      this.ID = Data.ID;
    }

  }
  changPage(event) {
    this.handlePageChange(event);
    //this.spinner.show();
    this.DataView = this.DataFilter.filter(
      (x) =>
        this.pageSize * (this.page - 1) < x.STT &&
        x.STT <= this.pageSize * this.page
    );
    // this.arrNumberPage_chil = this.createNumberPage(
    //   this.DataFilter.length,
    //   this.pageSize
    // ).arrNumberPage_chil;
  }

  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    //this.GetCount();
    this.GetAll();
  }
  get checkvalue() {
    return this.InsertForm.controls;
  }
  Delete() {
    let req = {
      ID: this.ID,
    };
    this.modalService.dismissAll();
    this.KhenThuongKyLuatService.Delete(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
     
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    }
    );
  }
  Insert() {
    this.submitted = true;
    if (this.InsertForm.invalid) {
      this.toastr.warning('Vui lòng nhập đủ các thông tin cần thiết');
      return false;
    }
    if (this.isInsert == true) {
      let listsv = [];
      let listsv1 = this.DataKhenThuong.filter((x) => x.checked == true);
      if (listsv1.length == 0) {
        this.toastr.warning("Vui lòng chọn sinh viên")
        return false
      }
      for (var i = 0; i < listsv1.length; i++) {
        let req = {
          ID_sv: listsv1[i].ID_sv,
          So_quyet_dinh: this.InsertForm.value.So_quyet_dinh,
          Ngay_quyet_dinh: this.InsertForm.value.Ngay_quyet_dinh,
          ID_hinh_thuc: this.InsertForm.value.ID_hinh_thuc,
          Loai_quyet_dinh: this.InsertForm.value.Loai_quyet_dinh,
          Cap_ky_luat_khen_thuong: this.InsertForm.value
            .Cap_ky_luat_khen_thuong,
          ID_phong: this.InsertForm.value.ID_phong,
          Noi_dung: this.InsertForm.value.Noi_dung,
          Ten_toa_nha: this.InsertForm.value.Ten_toa_nha,
          Hoc_ky: this.InsertForm.value.Hoc_ky,
          Nam_hoc: this.InsertForm.value.Nam_hoc,
        };

        listsv.push(req);
      }
      this.KhenThuongKyLuatService.Insert(listsv, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.GetAll();
          this.GetCount(this.req);
        } else {
          this.toastr.error(z.Message);
        }
      }, (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
      this.modalService.dismissAll();
    }
  }
  update() {
    if (this.UpdateForm.invalid) {
      this.toastr.warning('Vui lòng nhập đủ các thông tin cần thiết');
      return false;
    }
    let req = {
      ID: this.UpdateForm.value.ID,
      ID_sv: this.UpdateForm.value.ID_sv,
      Ma_sv: this.UpdateForm.value.Ma_sv,
      Ten_sv: this.UpdateForm.value.Ten_sv,
      So_quyet_dinh: this.UpdateForm.value.So_quyet_dinh,
      Ngay_quyet_dinh: this.UpdateForm.value.Ngay_quyet_dinh,
      ID_hinh_thuc: this.UpdateForm.value.ID_hinh_thuc,
      Loai_quyet_dinh: this.UpdateForm.value.Loai_quyet_dinh,
      Cap_ky_luat_khen_thuong: this.UpdateForm.value.Cap_ky_luat_khen_thuong,
      ID_phong: this.UpdateForm.value.ID_phong,
      Ten_phong: this.UpdateForm.value.Ten_phong,
      Noi_dung: this.UpdateForm.value.Noi_dung,
      Ten_toa_nha: this.UpdateForm.value.Ten_toa_nha,
      Hoc_ky: this.UpdateForm.value.Hoc_ky,
      Nam_hoc: this.UpdateForm.value.Nam_hoc.trim(),
    };
    this.KhenThuongKyLuatService.Update(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
     
    }, (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });

    this.modalService.dismissAll();
  }

  Filter() {
    this.DataFilter = this.dataTable;
    if (this.FilterForm.value.ID_phong_ft != '' && this.FilterForm.value.ID_phong_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.ID_phong == this.FilterForm.value.ID_phong_ft);
    }
    if (this.FilterForm.value.Ten_nha_ft != '' && this.FilterForm.value.Ten_nha_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.ID_toa_nha == this.FilterForm.value.Ten_nha_ft);
    }
    if (this.FilterForm.value.Ma_sv_ft != '' && this.FilterForm.value.Ma_sv_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => (x.Ma_sv.trim()).toLowerCase()  == (this.FilterForm.value.Ma_sv_ft.trim()).toLowerCase());
    }
    if (this.FilterForm.value.Ten_sv_ft != '' && this.FilterForm.value.Ten_sv_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => (x.Ho_ten.trim()).toLowerCase()== (this.FilterForm.value.Ten_sv_ft.trim()).toLowerCase());
    }
    if (this.FilterForm.value.Hoc_ky_ft != '' && this.FilterForm.value.Hoc_ky_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.Hoc_ky == this.FilterForm.value.Hoc_ky_ft);
    }
    if (this.FilterForm.value.Nam_hoc_ft != '' && this.FilterForm.value.Nam_hoc_ft != undefined) {
      this.DataFilter = this.DataFilter.filter((x) => x.Nam_hoc.trim() == this.FilterForm.value.Nam_hoc_ft);
    }
    if (this.FilterForm.value.Tu_ngay_ft != '' && this.FilterForm.value.Tu_ngay_ft != undefined) {
      if (this.FilterForm.value.Den_ngay_ft != '' && this.FilterForm.value.Den_ngay_ft != undefined) {
        if (this.FilterForm.value.Den_ngay_ft < this.FilterForm.value.Tu_ngay_ft) {
          this.toastr.warning('Từ ngày không lớn hơn đến ngày!');
        } else {  
          let a= new Date(this.FilterForm.value.Tu_ngay_ft) 
          let b= new Date(this.FilterForm.value.Den_ngay_ft)          
          this.DataFilter = this.DataFilter.filter((x) =>          
           new Date(x.Ngay_quyet_dinh == null ? "" :x.Ngay_quyet_dinh.substr(0, 10)) >= a && 
           new Date(x.Ngay_quyet_dinh == null ? "" :x.Ngay_quyet_dinh.substr(0, 10)) <= b          
          );
        
        }
      }
    }
    this.page = 1;
    for (var i = 0; i < this.DataFilter.length; i++) {
      this.DataFilter[i].STT = i + 1;
    }
    this.totalItemFilter = this.DataFilter.length;
    this.DataView = this.DataFilter.filter(
      (x) =>
        x.STT > (this.page - 1) * this.pageSize &&
        x.STT <= this.page * this.pageSize
    );
    this.numberPage = this.createNumberPage(
      this.DataFilter.length,
      this.pageSize
    ).numberPage;
    this.arrNumberPage_chil = this.createNumberPage(
      this.DataFilter.length,
      this.pageSize
    ).arrNumberPage_chil;
  }
  filterModal() {
    this.dataSV = this.beforeFilter;
    if (this.Ma_sv != '') {
      let a = this.dataSV;
      let b = this.dataSV;
      if (a != []) {
        a = this.dataSV.filter((i) => i.Ma_sv.includes(this.Ma_sv.trim()));
      }
      if (b != []) {
        b = this.dataSV.filter((i) =>
          this.xoa_dau(i.Ho_ten.toLowerCase()).includes(
            this.xoa_dau(this.Ma_sv.trim().toLowerCase())
          )
        );
      }
      this.dataSV = [];
      if (a.length != 0) {
        a.forEach((i) => {
          this.dataSV.push(i);
        });
      }
      if (b.length != 0) {
        b.forEach((i) => {
          this.dataSV.push(i);
        });
      }
    }
    if (
      this.Ngay_sinh != undefined &&
      this.Ngay_sinh != null &&
      this.Ngay_sinh != ''
    ) { this.dataSV = this.dataSV.filter( (x) => x.Ngay_sinh.toString().substring(0, 10) == this.Ngay_sinh);
    }
    for (var j = 0; j < this.dataSV.length; j++) {
      this.dataSV[j].STT = j + 1;
    }
    this.totalRecord2 = this.dataSV.length;
    this.numberPage2 = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).numberPage;
    this.arrNumberPage2_child = this.createNumberPage(
      this.dataSV.length,
      this.pageSize2
    ).arrNumberPage_chil;
    this.changepage2(1);
  }
  getHoSoSV() {
    this.spinner.show();
    this.ListpeopledormService.getList(this.Token).subscribe((z) => {
      this.dataSV = z.Data;
      this.totalRecord2 = z.Data.length;
      this.beforeFilter = z.Data;
      this.numberPage2 = this.createNumberPage(
        this.dataSV.length,
        this.pageSize2
      ).numberPage;
      this.arrNumberPage2_child = this.createNumberPage(
        this.dataSV.length,
        this.pageSize2
      ).arrNumberPage_chil;
      this.dataSV2 = z.Data.filter((x) => 0 < x.STT && x.STT <= this.pageSize2);
      this.spinner.hide();
    });
  }
  isAllCheckBoxChecked1() {
    if (this.dataSV != undefined) return this.dataSV.every((p) => p.checked);
  }
  checkAllCheckBox1(ev) {
    this.dataSV.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked2() {
    if (this.DataKhenThuong != undefined)
      return this.DataKhenThuong.every((p) => p.checked);
  }
  checkAllCheckBox2(ev) {
    this.DataKhenThuong.forEach((x) => (x.checked = ev.target.checked));
  }
  changepage2(event: any) {
    this.spinner.show();
    if (event == 'pre') {
      this.page_sv = this.page_sv - 1;
    } else if (event == 'next') {
      this.page_sv = this.page_sv + 1;
    } else {
      this.page_sv = event;
      this.arrNumberPage2_child = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage2) {
          this.arrNumberPage2_child.push(i);
        }
      }
    }
    this.dataSV = this.dataSV.filter(
      (x) =>
        this.pageSize2 * (this.page_sv - 1) < x.STT &&
        x.STT <= this.pageSize2 * this.page_sv
    );

    this.spinner.hide();
  }
  SpliceCheckRow() {
    for (let i = 0; i < this.DataKhenThuong.length; i++) {
      if (this.DataKhenThuong[i].checked == true) {

        const check = this.dataSV.some(
          (el) => el.ID_sv === this.DataKhenThuong[i].ID_sv
        );
        if (!check) {
          this.dataSV.push(this.DataKhenThuong[i]);
        }
        this.DataKhenThuong.splice(i, 1);
        i--;
      }
    }
  } // Xóa row check
  SpliceCheckRow1(DataKhenThuong) {

    if (this.DataKhenThuong.checked == true) {

      const check = this.dataSV.some(
        (el) => el.ID_sv === this.DataKhenThuong.ID_sv
      );
      if (!check) {
        this.dataSV.push(this.DataKhenThuong);
      }
      this.DataKhenThuong.splice();
      DataKhenThuong--;
    }
  } // Xóa row check
  PushCheckRow() {
    for (let i = 0; i < this.dataSV.length; i++) {
      if (this.dataSV[i].checked == true) {
        const check = this.DataKhenThuong.some((el) => {
          return el.ID_sv == this.dataSV[i].ID_sv;
        });
        if (!check) {
          this.DataKhenThuong.push(this.dataSV[i]);
        }
        this.dataSV.splice(i, 1);
        i--;
      }
    }
  } // thêm row check
  PushCheckRow1(dataSV) {
    if (this.dataSV.checked == true) {
      const check = this.DataKhenThuong.some((el) => {
        return el.ID_sv == this.dataSV.ID_sv;
      });
      if (!check) {
        this.DataKhenThuong.push(this.dataSV);
      }
      this.dataSV.splice();
      dataSV--;

    }
  } // thêm row check
  next() {
    if (this.DataKhenThuong.length == 0) {
      this.toastr.warning('Chọn sinh viên !');
      return false;
    }
    this.Display = true;
  }

  prev() {
    this.Display = false;
  }
  resetform() {
    this.DataKhenThuong = [];
    this.Display = false;
  }
  checkbox(event) {
    this.DataKhenThuong.push(event);
  }
  Excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.KhenThuongKyLuatService.Excel(a.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'Khen thưởng kỷ luật');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      });
    }
  }
  listHinhThuc() {
    this.HinhThucService.GetAll(this.Token).subscribe((z) => {
      this.dataHinhThuc = z.Data;
    });
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_KTKL;
    window.open(urlHelp,'_blank');
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
@Component({
  selector: 'app-template-email',
  templateUrl: './template-email.component.html',
  styleUrls: ['./template-email.component.scss'],
})
export class TemplateEmailComponent extends BaseComponent implements OnInit {
  id: any;
  content: any;
  public Editor = ClassicEditor;
  ngOnInit(): void {
    this.getToken();
    this.getAll();
  }
  getAll() {
    this.spinner.show();
    this.TemplateEmailService.getAll(this.Token).subscribe((z) => {
      this.spinner.hide();
      this.dataTable = z;
    });
  }
  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.content = data;
  }
  open(content, sizm, Loai, Data) {
    this.id = Data.id;
    this.content = Data.Noi_dung;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  onSubmit() {
    let req = {
      id: this.id,
      Noi_dung: this.content,
    };
    this.TemplateEmailService.update(req, this.Token).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.toastr.success(res.Message);
          this.modalService.dismissAll('AddModal');
          this.getAll();
        } else {
          this.toastr.warning(res.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      }
    );
  }
}

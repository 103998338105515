<section class="content-header" style="padding: 5px !important;">
  <div class="container-fluid">
    <div class="row mb2">
      <div class="col-md-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i>Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quản lý số nước</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-danger btn-sm"
          (click)="openModal(ViewModal2, 'lg', 'MultiPhanBo', '')"
          style="float: right; margin-right: 5px;"
        >
          Phân bổ số
        </button>
        <button
          class="btn btn-primary btn-sm"
          (click)="openModal(ViewModal3, 'xs', 'MultiMoChot', '')"
          style="float: right; margin: 0 5px 0 5px;"
        >
          Mở chốt số
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="openModal(ViewModal, 'xs', 'View', '')"
          style="float: right;"
        >
          Chốt số
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="Excel()"
          style="float: right; margin-right: 5px;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="filter">
        <div class="row">
          <div class="col-xl-2">
            <label>Tháng</label>
            <ng-select (change)="getList()" formControlName="month">
              <ng-option *ngFor="let i of MonthOption" [value]="i.value">{{
                i.name
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-xl-2">
            <label>Năm</label>
            <ng-select name="Nam" (change)="getList()" formControlName="year">
              <ng-option *ngFor="let i of yearArr" [value]="i.value">{{
                i.name
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-xl-2">
            <label>Cơ sở</label>
            <ng-select formControlName="selectCampus" (change)="getList()">
              <ng-option *ngFor="let i of dataCampus" [value]="i.ID">{{
                i.name
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-xl-2">
            <label>Tòa nhà</label>
            <ng-select
              formControlName="selectBuilding"
              (change)="getList()"
              (change)="changeBuilding()"
            >
              <ng-option *ngFor="let i of dataBuilding" [value]="i.ID_ToaNha">{{
                i.Ten_ToaNha
              }}</ng-option>
            </ng-select>
          </div>

          <div class="col-xl-2">
            <label>Tên phòng</label>
            <ng-select formControlName="selectRoom" (change)="getList()">
              <ng-option *ngFor="let i of dataRoom" [value]="i.ID_Phong">{{
                i.Ten_phong
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-xl-2">
            <label>Trạng thái</label>
            <ng-select formControlName="status" (change)="getList()">
              <ng-option value="">Tất cả</ng-option>
              <ng-option value="Chưa nhập số liệu">Chưa nhập số liệu</ng-option>
              <ng-option value="Chưa chốt">Chưa chốt</ng-option>
              <ng-option value="Đã chốt">Đã chốt</ng-option>
              <ng-option value="Đã phân bổ">Đã phân bổ</ng-option>
            </ng-select>
          </div>
          <!-- <div class="col-xl-2">
            <label>Trạng thái thanh toán</label>
            <ng-select  formControlName="Thanh_toan" (change)="getList()">
              <ng-option value="">Tất cả</ng-option>
              <ng-option value="0">Đã hoàn thành</ng-option>
              <ng-option value="1">Chưa hoàn thành</ng-option>
              <ng-option value="2">Thu thừa</ng-option>
            </ng-select>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th>
                  <input
                    type="checkbox"
                    [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)"
                  />
                </th>

                <th>Tên phòng</th>
                <th>Trạng thái</th>
                <th>Ngày chốt</th>
                <th>Tiêu thụ đầu kỳ</th>
                <th>Chỉ số cũ</th>
                <th>Chỉ số mới</th>
                <th>Số lượng tiêu thụ</th>
                <th>Ghi chú</th>
                <th>Số sinh viên</th>
                <th>Thành tiền</th>
                <th>Số tiền đã nộp</th>
                <th>Thiếu/thừa</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                  <td>
                    <input
                      type="checkbox"
                      value="{{ p.ID_phong }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataTable[i].checked"
                    />
                  </td>

                  <td>{{ p.Ten_phong }}</td>
                  <td *ngIf="p.status == 0" class="text-primary">Chưa chốt</td>
                  <td *ngIf="p.status == 1" class="text-success">Đã chốt</td>
                  <td *ngIf="p.status == 2" class="text-danger">Đã phân bổ</td>
                  <td *ngIf="p.status == null" class="text-default">
                    Chưa nhập số liệu
                  </td>
                  <td>
                    {{
                      p.dateConfirm == '0001-01-01T00:00:00'
                        ? ''
                        : (p.dateConfirm | date: 'dd/MM/yyyy')
                    }}
                  </td>
                  <td>{{ p.So_du_dau_ky }}</td>
                  <td>{{ p.numberOld }}</td>
                  <td>{{ p.numberNew }}</td>
                  <td>{{ p.differenceNumber }}</td>
                  <td>{{ p.note }}</td>
                  <td>{{ p.So_sinh_vien ? p.So_sinh_vien : 0 }}</td>
                  <td>{{ !p.So_tien ? 0 : (p.So_tien | number) }}</td>
                  <td>
                    {{ !p.So_tien_da_nop ? 0 : (p.So_tien_da_nop | number) }}
                  </td>
                  <td>{{ !p.Thieu_thua ? 0 : (p.Thieu_thua | number) }}</td>
                  <td style="white-space: nowrap;">
                    <button
                      class="btn btn-sm btn-dark"
                      (click)="openModal(KhoanThuModal, 'xl', 'KhoanThu', p)"
                      style="margin-right: 2px;"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-success"
                      (click)="openModal(ChotModal, 'xs', 'Chot', p)"
                      style="margin-right: 2px;"
                    >
                      <i class="fas fa-lock"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="openModal(MoModal, 'xs', 'Mo', p)"
                      style="margin-right: 2px;"
                    >
                      <i class="fas fa-unlock"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      (click)="openModal(PhanBoModal, 'lg', 'PhanBo', p)"
                      style="margin-right: 2px;"
                    >
                      <i class="fas fa-share-square"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="openModal(AddModal, 'xl', 'Sua', p)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="20" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #KhoanThuModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Chi tiết khoản thu</h4>
    <p
      type="button"
      class="close t"
      aria-label="Close"
      style="font-size: 30px;"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
      (click)="onReset()"
    >
      <span aria-hidden="true" style="color: #ff0000;">×</span>
    </p>
  </div>
  <div class="modal-body cus-fs" style="padding: 2%;">
    <div
      class="table-responsive"
      style="max-height: 60vh; margin-bottom: 15px;"
    >
      <table class="table table-bordered">
        <thead>
          <th>Mã sinh viên</th>
          <th>Họ và tên</th>
          <th>Ngày sinh</th>
          <th>Số tiền phải nộp</th>
          <th>Số tiền đã nộp</th>
          <th>Số tiền hoàn trả</th>
          <th>Số tiền thiếu/thừa</th>
        </thead>
        <tbody>
          <tr *ngFor="let p of dataKhoanThu; let i = index; let odd = odd">
            <td>{{ p.Ma_sv }}</td>
            <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
            <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
            <td>{{ p.So_tien_phai_nop | number }}</td>
            <td>{{ p.So_tien_da_nop | number }}</td>
            <td>{{ p.So_tien_tra_lai | number }}</td>
            <td>{{ p.Thua_thieu | number }}</td>
          </tr>
          <tr *ngIf="dataTable.length == 0">
            <td colspan="10" class="text-center">Không có kết quả</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #AddModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <p
      type="button"
      class="close t"
      aria-label="Close"
      style="font-size: 30px;"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
      (click)="onReset()"
    >
      <span aria-hidden="true" style="color: #ff0000;">×</span>
    </p>
  </div>
  <form [formGroup]="Insert">
    <div class="modal-body cus-fs" style="padding: 2%;">
      <div class="cus-box">
        <div class="row">
          <div class="col-md-3">
            <label class="col-md-10">Tiêu thụ đầu kỳ</label>
            <input
              class="form-control"
              type="number"
              formControlName="So_du_dau_ky"
              placeholder="Nhập tiêu thụ đầu kỳ"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.So_du_dau_ky.errors
              }"
            />
            <div
              *ngIf="submitted && checkvalue.So_du_dau_ky.errors"
              class="invalid-feedback"
            >
              <div *ngIf="checkvalue.So_du_dau_ky.errors.min">
                Tiêu thụ đầu kỳ không được nhỏ hơn 0!
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label class="col-md-10 require">Chỉ số cũ</label>
            <input
              class="form-control"
              type="number"
              formControlName="numberOld"
              placeholder="Nhập chỉ số cũ"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.numberOld.errors
              }"
            />
            <div
              *ngIf="submitted && checkvalue.numberOld.errors"
              class="invalid-feedback"
            >
              <div *ngIf="checkvalue.numberOld.errors.required">
                Chỉ số cũ không được bỏ trống
              </div>
              <div *ngIf="checkvalue.numberOld.errors.min">
                Chỉ số cũ không được nhỏ hơn 0!
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label class="col-md-10 require">Chỉ số mới</label>
            <input
              class="form-control"
              type="number"
              formControlName="numberNew"
              placeholder="Nhập chỉ số mới"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.numberNew.errors
              }"
            />

            <div
              *ngIf="submitted && checkvalue.numberNew.errors"
              class="invalid-feedback"
            >
              <div *ngIf="checkvalue.numberNew.errors.required">
                Chỉ số mới không được bỏ trống!
              </div>
              <div *ngIf="checkvalue.numberNew.errors.min">
                Chỉ số mới không được nhỏ hơn 0!
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <label class="col-md-10 require">Ngày chốt</label>
            <input
              class="form-control"
              type="date"
              formControlName="dateConfirm"
              placeholder="Chọn ngày chốt"
              [ngClass]="{
                'is-invalid': submitted && checkvalue.dateConfirm.errors
              }"
            />
            <div
              *ngIf="submitted && checkvalue.dateConfirm.errors"
              class="invalid-feedback"
            >
              <div *ngIf="checkvalue.dateConfirm.errors.required">
                Bạn chưa chọn ngày chốt
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label class="col-md-10">Ghi chú</label>
            <textarea class="form-control" formControlName="note" rows="3">
            </textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="onSubmit()"
        >
          <i class="fas fa-save"></i> Lưu
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          style="margin-left: 1em;"
          (click)="onReset()"
          (click)="modal.dismiss('Cross click')"
        >
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #ViewModal let-modal>
  <div class="modal-body">
    <p
      style="
        color: inherit;
        text-decoration: none;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      "
    >
      Bạn có chắc chắn muốn chốt số nước
    </p>
  </div>
  <div class="text-center">
    <button type="button" class="btn btn-md btn-success" (click)="OnSubmit2()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<ng-template #ViewModal2 let-modal>
  <div class="modal-body">
    <div class="row" style="margin-top: 10px;">
      <div class="col-md-12">
        <form [formGroup]="Check">
          <div class="row">
            <div class="col-md-6 text-center">
              <div class="radio">
                <label
                  ><input
                    type="radio"
                    name="radio"
                    value="1"
                    formControlName="radio"
                    checked
                  />
                  Phân bổ cho trưởng phòng</label
                >
              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="radio">
                <label
                  ><input
                    type="radio"
                    name="radio"
                    value="2"
                    formControlName="radio"
                  />
                  Phân bổ cho từng người</label
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.col -->
    </div>
  </div>
  <div class="text-center">
    <button type="button" class="btn btn-md btn-success" (click)="OnSubmit3()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<ng-template #ViewModal3 let-modal>
  <div class="modal-body">
    <p
      style="
        color: inherit;
        text-decoration: none;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      "
    >
      Bạn có chắc chắn muốn mở khoá chốt
    </p>
  </div>
  <div class="text-center">
    <button type="button" class="btn btn-md btn-success" (click)="OnSubmit4()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<ng-template #ChotModal let-modal>
  <div class="modal-body">
    <p
      style="
        color: inherit;
        text-decoration: none;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      "
    >
      Bạn có chắc chắn muốn chốt số nước
    </p>
  </div>
  <div class="text-center">
    <button type="button" class="btn btn-md btn-success" (click)="ChotSubmit()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<ng-template #PhanBoModal let-modal>
  <div class="modal-body">
    <div class="row" style="margin-top: 10px;">
      <div class="col-md-12">
        <form [formGroup]="Check">
          <div class="row">
            <div class="col-md-6 text-center">
              <div class="radio">
                <label
                  ><input
                    type="radio"
                    name="radio"
                    value="1"
                    formControlName="radio"
                    checked
                  />
                  Phân bổ cho trưởng phòng</label
                >
              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="radio">
                <label
                  ><input
                    type="radio"
                    name="radio"
                    value="2"
                    formControlName="radio"
                  />
                  Phân bổ cho từng người</label
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.col -->
    </div>
  </div>
  <div class="text-center">
    <button
      type="button"
      class="btn btn-md btn-success"
      (click)="PhanBoSubmit()"
    >
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<ng-template #PhanBoModal let-modal>
  <div class="modal-body">
    <div class="row" style="margin-top: 10px;">
      <div class="col-md-12">
        <form [formGroup]="Check">
          <div class="row">
            <div class="col-md-6 text-center">
              <div class="radio">
                <label
                  ><input
                    type="radio"
                    name="radio"
                    value="1"
                    formControlName="radio"
                    checked
                  />
                  Phân bổ cho trưởng phòng</label
                >
              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="radio">
                <label
                  ><input
                    type="radio"
                    name="radio"
                    value="2"
                    formControlName="radio"
                  />
                  Phân bổ cho từng người</label
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.col -->
    </div>
  </div>
  <div class="text-center">
    <button
      type="button"
      class="btn btn-md btn-success"
      (click)="PhanBoSubmit()"
    >
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>

<ng-template #MoModal let-modal>
  <div class="modal-body">
    <p
      style="
        color: inherit;
        text-decoration: none;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      "
    >
      Bạn có chắc chắn muốn mở khoá chốt
    </p>
  </div>
  <div class="text-center">
    <button type="button" class="btn btn-md btn-success" (click)="MoSubmit()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-md btn-danger"
      (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

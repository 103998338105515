import { InjectionToken } from '@angular/core';

export interface AppConfiguration {
  production: boolean;
  UniDormAPI: string;
  UniSystemAPI: string;
  NguoiDaiDien_KTX: string;
  SoDienThoai_KTX: string;
  HelpUrl: string;
  UniPracticalResearch: string;
  document_DeXuat: string;
  document_DiemSV: string;
  document_DiemPhong: string;
  document_DanhSachO: string;
  document_DanhSachRa: string;
  document_DSHanChe: string;
  document_QuanLyThietBi: string;
  document_QuanLyViPham: string;
  document_QDKhoanNop: string;
  document_QLPhong: string;
  document_QLNha: string;
  document_QLCoSo: string;
  document_QLLoaiPhong: string;
  document_SoDoPhong: string;
  document_QLDangKy: string;
  document_DuyetDK: string;
  document_XepPhong: string;
  document_BienLai: string;
  document_BienDongKT: string;
  document_LichSuSuaChua: string;
  document_KTKL: string;
  URL_Anh_SV: string;
}

export const AppConfig = new InjectionToken<AppConfiguration>(
  '@@appConfiguration'
);

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  NgModel,
} from '@angular/forms';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
import { BaseComponent } from 'src/app/components/base/base.component';
@Component({
  selector: 'app-hinh-thuc',
  templateUrl: './hinh-thuc.component.html',
  styleUrls: ['./hinh-thuc.component.scss'],
})
export class HinhThucComponent extends BaseComponent implements OnInit {
  titleModal: any = '';
  isInsert: any;
  FillPhong: any = '';
  DataToaNha: any;
  show_search: any = true;
  List_phong_ban: any;
  Ma_kho_checkexist: any;
  ID_hinh_thuc: any;
  FillToaNha: any;
  ID_sua_chua: any;
  DataRoom: any = [];
  DataThietBi: any = [];
  DataView: any = [];
  DataFilter: any = [];
  dataRoomView: any;
  FilterForm = new FormGroup({
    Hinh_thuc_ft: new FormControl(null),
    Loai_hinh_thuc_ft: new FormControl(null),
  });
  InsertForm = new FormGroup({
    Hinh_thuc: new FormControl(null, [Validators.required, removeSpaces]),
    Loai_hinh_thuc: new FormControl(null, [Validators.required, removeSpaces]),
    Ghi_chu: new FormControl(null),
  });
  UpdateForm = new FormGroup({
    ID_hinh_thuc: new FormControl(null),
    Hinh_thuc: new FormControl(null, [Validators.required]),
    Loai_hinh_thuc: new FormControl(null, [Validators.required]),
    Ghi_chu: new FormControl(null),
  });
  dtTrigger = new Subject();
  public com: common;
  ngOnInit(): void {
    this.getToken();
    this.GetAll();
   
  }
  hidesearch() {
    if (this.show_search == true) {
      return (this.show_search = false);
    }
    if (this.show_search == false) {
      return (this.show_search = true);
    }
  }
  GetAll() {
    this.page = 1;
    this.spinner.show();
    this.HinhThucService.GetAll(this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.DataFilter = z.Data;
      this.DataView = this.dataTable.filter(
        (x) =>
          this.pageSize * (this.page - 1) < x.STT &&
          x.STT <= this.pageSize * this.page
      );
      this.numberPage = this.createNumberPage(
        this.DataFilter.length,
        this.pageSize
      ).numberPage;
      this.arrNumberPage_chil = this.createNumberPage(
        this.DataFilter.length,
        this.pageSize
      ).arrNumberPage_chil;
      this.totalItem = z.Data.length;
      this.totalItemFilter = z.Data.length;
      this.spinner.hide();
    },(err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  getListBuilding() {
    this.FillToaNha = '';
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data;
    });
  }

  ChangeToaNha() {
    let id_toa_nha = this.FilterForm.value.Ten_nha_ft;
    if (id_toa_nha != '') {
      this.dataRoomView = this.dataRoom.filter(
        (x) => x.ID_Toa_Nha == id_toa_nha
      );
    } else {
      this.dataRoomView = this.dataRoom;
    }
  }
  ChangeToaNhaInsert() {
    let id_toa_nha = this.FilterForm.value.Ten_toa_nha;
    this.dataRoomView = this.dataRoom.filter((x) => x.ID_Toa_Nha == id_toa_nha);
  }

  getListRoom() {
    this.FillPhong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data;
      this.dataRoomView = z.Data;
      //this.Filter();
    });
  } // list phòng
  GetCount(req) {
    this.HinhThucService.Count(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.totalItem = z.Message;
      }
    });
  }
  getListThietBi() {
    this.DeviceService.getList(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.DataThietBi = z.Data;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  openModal(content, sizm, category, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (category == 'Insert') {
      this.titleModal = 'Thêm hình thức kỷ luật khen thưởng';
      this.isInsert = true;
      this.InsertForm.setValue({
        Hinh_thuc:'',
        Loai_hinh_thuc: '',
        Ghi_chu:'',
      });
    }
    if (category == 'Update') {
      this.titleModal = 'Sửa hình thức kỷ luật khen thưởng';
      this.UpdateForm.setValue({
        ID_hinh_thuc: Data.ID_hinh_thuc,
        Hinh_thuc: Data.Hinh_thuc,
        Loai_hinh_thuc: Data.Loai_hinh_thuc,
        Ghi_chu: Data.Ghi_chu,
      });
    }
    if (category == 'Delete') {
      this.ID_hinh_thuc = Data.ID_hinh_thuc;
    }
  }
  changPage(event) {
    this.handlePageChange(event);
    //this.spinner.show();
    this.DataView = this.DataFilter.filter(
      (x) =>
        this.pageSize * (this.page - 1) < x.STT &&
        x.STT <= this.pageSize * this.page
    );
    // this.arrNumberPage_chil = this.createNumberPage(
    //   this.DataFilter.length,
    //   this.pageSize
    // ).arrNumberPage_chil;
  }

  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    //this.GetCount();
    this.GetAll();
  }
  get checkvalue() {
    return this.InsertForm.controls;
  }
  Delete() {
    let req = {
      ID_hinh_thuc: this.ID_hinh_thuc,
    };
    this.modalService.dismissAll();
    this.HinhThucService.Delete(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
    },(err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  Insert() {
    this.submitted = true;
    if (this.InsertForm.invalid) {
      this.toastr.warning('Vui lòng nhập đủ các thông tin cần thiết');
      return false;
    }
    let req = {
      ID_hinh_thuc: this.InsertForm.value.ID_hinh_thuc,
      Hinh_thuc: this.InsertForm.value.Hinh_thuc,
      Loai_hinh_thuc: this.InsertForm.value.Loai_hinh_thuc,
      Ghi_chu: this.InsertForm.value.Ghi_chu,
    };
    if (this.isInsert == true) {
      this.HinhThucService.Insert(req, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.GetAll();
          this.GetCount(req);
        } else {
          this.toastr.error(z.Message);
        }
      },(err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      });
      this.modalService.dismissAll();
    }
  }
  update() {
    this.submitted = true;
    if (this.UpdateForm.invalid) {
      this.toastr.warning('Vui lòng nhập đủ các thông tin cần thiết');
      return false;
    }
    let req = {
      ID_hinh_thuc: this.UpdateForm.value.ID_hinh_thuc,
      Hinh_thuc: this.UpdateForm.value.Hinh_thuc,
      Loai_hinh_thuc: this.UpdateForm.value.Loai_hinh_thuc,
      Ghi_chu: this.UpdateForm.value.Ghi_chu,
    };
    this.HinhThucService.Update(req, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.GetAll();
        this.GetCount(req);
      } else {
        this.toastr.error(z.Message);
      }
    },(err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });

    this.modalService.dismissAll();
  }
  Filter() {
    this.DataFilter = this.dataTable;

    if (
      this.FilterForm.value.Hinh_thuc_ft != '' &&
      this.FilterForm.value.Hinh_thuc_ft != undefined
    ) {
      this.DataFilter = this.DataFilter.filter(
        (x) => x.Hinh_thuc == this.FilterForm.value.Hinh_thuc_ft
      );
    }
    if (
      this.FilterForm.value.Loai_hinh_thuc_ft != '' &&
      this.FilterForm.value.Loai_hinh_thuc_ft != undefined
    ) {
      this.DataFilter = this.DataFilter.filter(
        (x) => x.Loai_hinh_thuc == this.FilterForm.value.Loai_hinh_thuc_ft
      );
    }
    this.page = 1;
    for (var i = 0; i < this.DataFilter.length; i++) {
      this.DataFilter[i].STT = i + 1;
    }
    this.totalItemFilter = this.DataFilter.length;
    this.DataView = this.DataFilter.filter(
      (x) =>
        x.STT > (this.page - 1) * this.pageSize &&
        x.STT <= this.page * this.pageSize
    );
    this.numberPage = this.createNumberPage(
      this.DataFilter.length,
      this.pageSize
    ).numberPage;
    this.arrNumberPage_chil = this.createNumberPage(
      this.DataFilter.length,
      this.pageSize
    ).arrNumberPage_chil;
  }
}

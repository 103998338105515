import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, from, Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  AbstractControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

@Component({
  selector: 'app-portal-cap-nhat-thong-tin',
  templateUrl: './portal-cap-nhat-thong-tin.component.html',
  styleUrls: ['./portal-cap-nhat-thong-tin.component.scss'],
})
export class PortalCapNhatThongTinComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Token: string;
  dataTable: any = [];
  closeResult: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  submitted = false;
  dtTrigger = new Subject();
  public com: common;
  public data: any;
  checkInsert: boolean = false;
  titleModal: any;

  dataSinhVien: any;
  UserName_get: string;
  Ma_sv_get: string;
  Email_get: string;
  So_dien_thoai_get: string;

  Insert = new FormGroup({
    Ghi_chu: new FormControl(''),
    Tinh_trang_suc_khoe: new FormControl(''),
    // Nguyen_vong: new FormControl(''),
    ID_doi_tuong_TS: new FormControl(0),
    ID_khu_vuc_TS: new FormControl(''),
    Ma_sv: new FormControl(''),
    Ho_ten: new FormControl(''),
    Email: new FormControl('', [
      Validators.required,
      Validators.email,
      removeSpaces,
    ]),
    Ngay_sinh: new FormControl(''),
    Loai_phong: new FormControl(''),
    Dienthoai_canhan: new FormControl('', [Validators.required]),
  });

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RoomCategoryRegister: RoomCategoryRegisterService,
    private System: SystemService,
    private RegisterDorm: RegisterDormService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    var a = this.com.getPortalInfo();
    this.Token = a.Token;
    this.UserName_get = a.Info.UserName;
    this.Ma_sv_get = a.Info.UserName;

    this.GetSinhVienByMaSV();
    this.GetDataSystem();
    this.Insert.get('ID_doi_tuong_TS').disable();
    this.Insert.get('ID_khu_vuc_TS').disable();
  }

  ID_doi_tuong_TS_Insert: number;
  ID_khu_vuc_TS_Insert: number;

  Ho_ten_sv: string;
  Ten_nganh_nhap_hoc_sv: string;
  Khoa_hoc_dang_hoc_sv: number;

  //-------------------------Lấy thông tin sv theo mã----------------------------//
  GetSinhVienByMaSV() {
    this.spinner.show();
    this.RegisterDorm.getSVbyMa(this.Ma_sv_get, this.Token).subscribe((res) => {
      if (res.Status == 1) {
        this.dataSinhVien = res.Data;
        this.ID_doi_tuong_TS_Insert = this.dataSinhVien[0].ID_doi_tuong_TS;
        this.ID_khu_vuc_TS_Insert = this.dataSinhVien[0].ID_khu_vuc_TS;
        this.Ho_ten_sv = this.dataSinhVien[0].Ho_ten;
        this.Ten_nganh_nhap_hoc_sv = this.dataSinhVien[0].Ten_nganh_nhap_hoc;
        this.Khoa_hoc_dang_hoc_sv = this.dataSinhVien[0].Khoa_hoc_nhap_hoc;
        this.Insert.patchValue({
          Ma_sv: this.Ma_sv_get,
          Ho_ten: this.dataSinhVien[0].Ho_ten,
          Ngay_sinh: this.dataSinhVien[0].Ngay_sinh.substring(0, 10),
          Email: this.dataSinhVien[0].Email,
          ID_doi_tuong_TS: this.dataSinhVien[0].ID_doi_tuong_TS,
          ID_khu_vuc_TS: this.dataSinhVien[0].ID_khu_vuc_TS,
          Dienthoai_canhan: this.dataSinhVien[0].Dienthoai_canhan,
        });
        this.spinner.hide();
      } else {
        this.toastr.warning(res.Message);
        this.spinner.hide();
      }
    });
  }

  open(content, sizm, Loai, Data) {
    this.submitted = true;
    if (this.Insert.invalid) {
      this.toastr.warning('Bạn chưa nhập đầy đủ thông tin !');
      return false;
    } else {
      this.modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      if (Loai == 'Update') {
        this.titleModal = 'Xác nhận cập nhật thông tin';
        this.Email_get = this.Insert.value.Email;
        this.So_dien_thoai_get = this.Insert.value.Dienthoai_canhan.toString();
        this.submitted = false;
      }
    }
  }

  dataKhuVucTuyenSinh: any;
  dataDanToc: any;
  dataQuocTich: any;
  dataDoiTuongChinhSach: any;
  //-------------------------Lấy danh sách dataSystemm----------------------------//
  GetDataSystem() {
    this.spinner.show();
    this.System.Get_khuvuc_tuyensinh(this.Token).subscribe((res) => {
      this.dataKhuVucTuyenSinh = res;
      this.System.Get_danh_sach_dan_toc(this.Token).subscribe((res) => {
        this.dataDanToc = res;
        this.System.Get_doituong_chinhsach(this.Token).subscribe((res) => {
          this.dataDoiTuongChinhSach = res;
          this.System.Get_danh_sach_quoc_tich(this.Token).subscribe((res) => {
            this.dataQuocTich = res;
          });
        });
      });
    });
    this.spinner.hide();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get checkvalue() {
    return this.Insert.controls;
  } //check value

  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      this.toastr.warning('Bạn chưa nhập đầy đủ thông tin !');
      return false;
    }
    let req = {
      ID_sv: this.dataSinhVien[0].ID_sv,
      Email: this.Insert.value.Email,
      ID_doi_tuong_TS: this.ID_doi_tuong_TS_Insert,
      ID_khu_vuc_TS: this.ID_khu_vuc_TS_Insert,
      Dienthoai_canhan: this.Insert.value.Dienthoai_canhan,
    };
    this.spinner.show();
    this.RegisterDorm.Update_Student(req, this.Token).subscribe((z) => {
      if (z.Status == 5) {
        this.toastr.warning(z.Message);
        this.spinner.hide();
      } else if (z.Status == 8) {
        this.toastr.warning(z.Message);
        this.spinner.hide();
      } else if (z.Status == 2) {
        this.toastr.warning(z.Message);
        this.spinner.hide();
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.spinner.hide();
      }
      this.GetSinhVienByMaSV();
      this.modalService.dismissAll('UppdateModal');
      this.spinner.hide();
    });
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { StudentSortService } from 'src/app/utils/services/student-sort.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CampusService } from 'src/app/utils/services/campus.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { RoomService } from 'src/app/utils/services/room.service';
import { RoomcategoryService } from 'src/app/utils/services/room-category.service';
import { now } from 'jquery';
import { WebSocketService } from 'src/app/utils/services/web-socket.service';
@Component({
  selector: 'app-students-sort-detail',
  templateUrl: './students-sort-detail.component.html',
  styleUrls: ['./students-sort-detail.component.scss'],
})
export class StudentsSortDetailComponent implements OnInit {
  @Input() StudentData: any = [];
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  spinner1 = 'sp1';
  Token: any;
  TongSucChua: number = 0;
  DataDetail: any = [];
  DSLoaiPhong: any = [];
  DSIDLoaiPhong: any = [];
  DSCoSo: any;
  DSToaNha: any;
  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  Fillter: FormGroup;
  Insert = new FormGroup({
    Thu_tu_doi_tuong: new FormControl(2),
    Thu_tu_phong: new FormControl(2),
    Ngay_bat_dau: new FormControl(null, [Validators.required]),
    Ngay_ket_thuc: new FormControl(null),
    ThangO: new FormControl(null),
  });
  constructor(
    private RoomService: RoomService,
    public router: Router,
    private toastr: ToastrService,
    private activatedroute: ActivatedRoute,
    private Service: StudentSortService,
    private spinner: NgxSpinnerService,
    private RoomCateGoryService: RoomcategoryService,
    private CampusService: CampusService,
    private BuildingService: BuildingService,
    private WebSocketService: WebSocketService
  ) {
    this.StudentData = router.getCurrentNavigation().extras.state;
    // this.activatedroute.params.subscribe(data => {
    // })
    if (this.StudentData == undefined) {
      this.router.navigate(['/students-sort']);
    }
  }
  public com: common;
  ngOnInit(): void {
    let Ngay_ket_thuc = '05-31-' + (new Date().getFullYear() + 1);
    if (
      this.formatDate(new Date()) <
      this.formatDate(new Date('05-31-' + new Date().getFullYear()))
    ) {
      Ngay_ket_thuc = '05-31-' + new Date().getFullYear();
    }
    this.Insert.patchValue({
      Ngay_bat_dau: this.formatDate(new Date()),
      Ngay_ket_thuc: this.formatDate(new Date(Ngay_ket_thuc)),
    });
    this.GetMonth();
    $('#datatable').DataTable().clear();
    $('#datatable').DataTable().destroy();
    $.fn['dataTable'].ext.search = [];
    this.Fillter = new FormGroup({
      Loai_phong_filter: new FormControl(''),
      Co_so_filter: new FormControl(''),
      Toa_nha_filter: new FormControl(''),
      Tang_filter: new FormControl(null),
      Phong_filter: new FormControl(1),
      Ten_phong_filter: new FormControl(''),
    });
    if (this.StudentData[0].Gioi_tinh == 'Nam') {
      this.Fillter.patchValue({ Phong_filter: 1 });
    } else {
      this.Fillter.patchValue({ Phong_filter: 2 });
    }

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          width: '5%',
          className: 'dt-center',
        },
        {
          title: 'ID phòng',
          visible: false,
        },
        {
          title: 'ID cơ sở',
          visible: false,
        },
        {
          title: 'ID toà nhà',
          visible: false,
        },
        {
          title: 'Cơ sở',
        },
        {
          title: 'Tòa nhà',
        },
        {
          title: 'Tầng',
        },
        {
          title: 'Loại phòng',
        },
        {
          title: 'Mã phòng',
        },
        {
          title: 'Tên phòng',
        },
        {
          title: 'Sức chứa',
        },
        {
          title: 'Số chỗ trống',
        },
        {
          title: 'Cơ sở',
          visible: false,
        },
        {
          title: 'Loại phòng',
          visible: false,
        },
        {
          title: 'Phòng',
        },
        {
          title: 'Sức chứa',
          visible: false,
        },
        {
          title: 'Ghi chú',
          visible: false,
        },
      ],
    };
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    // });

    this.RoomCateGoryService.getList(true, a.Token).subscribe((z) => {
      this.DSLoaiPhong = [];
      this.DSIDLoaiPhong = [];
      if (this.StudentData.length > 0) {
        this.StudentData.forEach((element) => {
          let a = z.Data.filter((x) => x.ID == element.ID_loai_phong);
          let req = {
            ID_loai_phong: element.ID_loai_phong,
            Ten_loai_phong: element.Ten_loai_phong,
          };
          let index = this.DSLoaiPhong.indexOf(req, 0);
          let index2 = this.DSIDLoaiPhong.indexOf(req.ID_loai_phong, 0);
          if (index2 == -1) {
            this.DSLoaiPhong.push(req);
            this.DSIDLoaiPhong.push(req.ID_loai_phong);
          }
          this.Fillter.controls['Loai_phong_filter'].setValue(
            element.Ten_loai_phong
          );
          this.filterById();
        });
      }
    });
    this.CampusService.getList(a.Token).subscribe((z) => {
      this.DSCoSo = z.Data;
    });
    this.BuildingService.getList(a.Token).subscribe((z) => {
      this.DSToaNha = z.Data;
    });

    this.getdeviceroom();
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  getdeviceroom() {
    this.spinner.show();
    this.RoomService.GetRoom(this.Token).subscribe((z) => {
      for (var i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Loai_phong == false) {
          z.Data[i].Loai_phong_text = 'Nam';
        } else {
          z.Data[i].Loai_phong_text = 'Nữ';
        }
      }
      if (this.DSLoaiPhong) {
        if (this.DSLoaiPhong.length > 0) {
          this.DSLoaiPhong.forEach((element) => {
            let a = z.Data.filter((x) => x.ID_loai_phong == element.ID);
            a.forEach((element) => {
              this.DataDetail.push(element);
            });
          });
        }
      }

      this.TongSucChua = 0;
      this.DataDetail = z.Data;
      this.dtTrigger.next();
      this.filterById();
      this.spinner.hide();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  SelectRow(ID_Phong) {
    for (let index = 0; index < this.DataDetail.length; index++) {
      if (this.DataDetail[index].ID_Phong == ID_Phong) {
        if ($('#' + ID_Phong).prop('checked')) {
          {
            this.DataDetail[index].Selected = true;
          }
        } else {
          this.DataDetail[index].Selected = false;
        }
        break;
      }
    }
    this.TongSucChua = 0;
    for (let index = 0; index < this.DataDetail.length; index++) {
      if (this.DataDetail[index].Selected == true) {
        this.TongSucChua += this.DataDetail[index].Con_trong;
      }
    }
  }
  onSubmit() {
    if (!(this.StudentData.length > 0)) {
      this.toastr.warning('Tổng số đối tượng đã chọn bằng 0 !');
      return false;
    }
    if (this.Insert.invalid) {
      this.toastr.warning('Vui lòng chọn ngày nhận phòng');
      return false;
    }
    if (
      this.Insert.value.ThangO <= 0 ||
      this.Insert.value.ThangO == null ||
      this.Insert.value.ThangO == undefined
    ) {
      this.toastr.warning('Số tháng ở không được nhỏ hơn 0');
      return false;
    }

    if (this.StudentData.length <= this.TongSucChua) {
      var lstPhong = jQuery.grep(this.DataDetail, function (pa: any, i) {
        return pa.Selected;
      });
      if (this.Fillter.value.Phong_filter == 1) {
        var lstPhongCheck = jQuery.grep(this.DataDetail, function (pa: any, i) {
          return pa.Loai_phong && pa.Selected;
        });
        if (lstPhongCheck.length > 0) {
          this.toastr.warning('Bạn không thể chọn phòng nữ cho sinh viên nam');
          return false;
        }
      } else {
        var lstPhongCheck = jQuery.grep(this.DataDetail, function (pa: any, i) {
          return !pa.Loai_phong && pa.Selected;
        });
        if (lstPhongCheck.length > 0) {
          this.toastr.warning('Bạn không thể chọn phòng nam cho sinh viên nữ');
          return false;
        }
      }
      let InputDatas = {
        Thu_tu_doi_tuong: this.Insert.value.Thu_tu_doi_tuong,
        Thu_tu_phong: this.Insert.value.Thu_tu_phong,
        Ngay_bat_dau: this.Insert.value.Ngay_bat_dau,
        Ngay_ket_thuc: this.Insert.value.Ngay_ket_thuc,
        So_thang_o: this.Insert.value.ThangO,
        Students: this.StudentData,
        Rooms: lstPhong,
      };
      this.showSpinner('sp2');
      this.Service.Sort(this.Token, InputDatas).subscribe((z) => {
        this.StudentData = [];
        this.hideSpinner('sp2');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });

        this.getdeviceroom();
        if (z.Status == 1) {
          this.toastr.success(z.Message, 'Xếp phòng thành công');
        } else {
          this.toastr.warning(z.Message, 'Tác vụ thất bại');
        }
      });
    } else {
      this.toastr.warning('Sức chứa không đủ, vui lòng kiểm tra lại');
    }
  }
  CheckAll2() {
    this.TongSucChua = 0;
    var datatable = $('#datatable')
      .DataTable()
      .rows({ filter: 'applied' })
      .data();
    var length = datatable.rows({ filter: 'applied' }).nodes().length;
    var lstID = [];
    for (var i = 0; i < length; i++) {
      var ID_Phong = parseInt(datatable[i][1]);
      lstID.push(ID_Phong);
    }
    for (let index = 0; index < this.DataDetail.length; index++) {
      if (lstID.includes(this.DataDetail[index].ID_Phong)) {
        this.DataDetail[index].Selected = $('#SelectALL2').prop('checked');
      }
    }
    for (let index = 0; index < this.DataDetail.length; index++) {
      if (this.DataDetail[index].Selected == true) {
        this.TongSucChua += this.DataDetail[index].Con_trong;
      }
    }
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fill();
  }
  fill() {
    $('#SelectALL2').prop('checked', false);
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Loai_phongD = data[7];
      let Co_soD = data[4];
      let Toa_nhaD = data[5];
      let TangD = data[6];
      let PhongD = data[13];
      let TenPhongD = data[8];
      let MaPhongD = data[9];
      ////////////////////////////////
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;

      if (this.Fillter.value.Loai_phong_filter != undefined) {
        bool1 = Loai_phongD.toLowerCase().includes(
          this.Fillter.value.Loai_phong_filter.toLowerCase()
        );
      }
      if (this.Fillter.value.Co_so_filter != undefined) {
        bool2 = Co_soD.toLowerCase().includes(
          this.Fillter.value.Co_so_filter.toLowerCase()
        );
      }
      if (this.Fillter.value.Toa_nha_filter != undefined) {
        bool3 = Toa_nhaD.toLowerCase().includes(
          this.Fillter.value.Toa_nha_filter.toLowerCase()
        );
      }
      if (
        this.Fillter.value.Tang_filter != undefined &&
        this.Fillter.value.Tang_filter != ''
      ) {
        bool4 = TangD == this.Fillter.value.Tang_filter;
      }
      if (this.Fillter.value.Phong_filter > 0) {
        if (this.Fillter.value.Phong_filter == 1) {
          bool5 = PhongD == 'false';
        } else {
          bool5 = PhongD == 'true';
        }
      }
      if (
        this.Fillter.value.Ten_phong_filter != undefined &&
        this.Fillter.value.Ten_phong_filter != ''
      ) {
        bool6 =
          TenPhongD.toLowerCase().includes(
            this.Fillter.value.Ten_phong_filter.toLowerCase()
          ) ||
          MaPhongD.toLowerCase().includes(
            this.Fillter.value.Ten_phong_filter.toLowerCase()
          );
      }
      // if (this.Fillter.value.Khoa_filter != undefined) {
      //   bool3 = KhoaD.toLowerCase().includes(this.Fillter.value.Khoa_filter.toLowerCase());
      // }
      // if (this.Fillter.value.Khoa_hoc_filter != undefined && this.Fillter.value.Khoa_hoc_filter != '') {
      //   bool4 = KhoaHocD == this.Fillter.value.Khoa_hoc_filter;
      // }
      // if (this.Fillter.value.Chuyen_nganh_filter != undefined && this.Fillter.value.Chuyen_nganh_filter != '') {
      //   bool1 = ChuyenNganhD.toLowerCase().includes(this.Fillter.value.Chuyen_nganh_filter.toLowerCase());
      // }
      // if (this.Fillter.value.Chuyen_nganh_filter != undefined && this.Fillter.value.Chuyen_nganh_filter != '') {
      //   bool1 = ChuyenNganhD.toLowerCase().includes(this.Fillter.value.Chuyen_nganh_filter.toLowerCase());
      // }
      // if (this.Fillter.value.Khu_vuc_filter != undefined && this.Fillter.value.Khu_vuc_filter != '') {
      //   bool7 = KhuVucD.toLowerCase().includes(this.Fillter.value.Khu_vuc_filter.toLowerCase());
      // }
      // if (this.Fillter.value.Loai_phong_filter != undefined && this.Fillter.value.Loai_phong_filter != '') {
      //   bool8 = Loai_phongD == this.Fillter.value.Loai_phong_filter;
      // }
      // if (this.Fillter.value.Doi_tuong_chinh_sach_filter != undefined && this.Fillter.value.Doi_tuong_chinh_sach_filter != '') {
      //   bool9 = Doi_tuong_chinh_sach_D.toLowerCase().includes(this.Fillter.value.Doi_tuong_chinh_sach_filter.toLowerCase());
      // }
      if (bool1 && bool2 && bool3 && bool4 && bool5 && bool6) {
        return true;
      }
      return false;
    });
  }
  showSpinner(name: string) {
    this.spinner.show(name);
  }

  hideSpinner(name: string) {
    this.spinner.hide(name);
  }

  GetMonth() {
    let Ngay_ket_thuc = new Date(this.Insert.value.Ngay_ket_thuc);
    let Ngay_bat_dau = new Date(this.Insert.value.Ngay_bat_dau);
    let SoThangO;

    if (Ngay_ket_thuc.getFullYear() > Ngay_bat_dau.getFullYear()) {
      SoThangO = 12 - Ngay_bat_dau.getMonth() + Ngay_ket_thuc.getMonth();
    } else {
      SoThangO = Ngay_ket_thuc.getMonth() - Ngay_bat_dau.getMonth();
    }
    if (Ngay_bat_dau != Ngay_ket_thuc) {
      SoThangO -= 1;
      if (30 - Ngay_bat_dau.getDate() > 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
      if (30 - Ngay_ket_thuc.getDate() < 15) {
        SoThangO += 1;
      } else {
        SoThangO += 0.5;
      }
    }
    this.Insert.controls.ThangO.setValue(SoThangO);
  } //Lấy ra số tháng ở từ ngày đến ngày
}


import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-tham-so-he-thong',
  templateUrl: './tham-so-he-thong.component.html',
  styleUrls: ['./tham-so-he-thong.component.scss']
})
export class ThamSoHeThongComponent extends BaseComponent implements OnInit {
  update = new FormGroup({
    Ten_tham_so: new FormControl('',[Validators.required]),
    Gia_tri: new FormControl('',[Validators.required]),
    Ghi_chu: new FormControl('',[Validators.required]),
  });
  ID:any
  ngOnInit(): void {
  
    this.getToken();
    this.getAll();
  }
  
  getAll() {
    this.spinner.show();

    this.ThamSoHeThongService.getAll(this.Token).subscribe(
      (z) => {
        this.spinner.hide();
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.router.navigate(['/admin']);
          this.spinner.hide();
        } else {
          this.dataTable = z.Data;
        }
      }
    );
  }
  get checkvalue() {
    return this.update.controls;
  }
 
  open(content, sizm, Loai, Data) {
    this.ID = Data.ID_tham_so;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
      this.update.get('Ten_tham_so').setValue(Data.Ten_tham_so);
      this.update.get('Gia_tri').setValue(Data.Gia_tri);
      this.update.get('Ghi_chu').setValue(Data.Ghi_chu);
  }
 
  onSubmit() {
    this.submitted = true;
    if (this.update.invalid) {
      return false;
    }
    let req = {
      ID_tham_so: this.ID,
      Ten_tham_so: this.update.value.Ten_tham_so,
      Gia_tri: this.update.value.Gia_tri,
      Ghi_chu: this.update.value.Ghi_chu,
    };
      this.ThamSoHeThongService.update(req, this.Token).subscribe(
        (res) => {
          if (res.Status == 2) {
            this.toastr.warning(res.Message);
          } else if (res.Status == 1) {
            this.toastr.success(res.Message);
            this.modalService.dismissAll('AddModal');
            this.getAll();
          } else if (res.Status == 4) {
            this.toastr.error('Thêm mới thất bại!');
          } else {
            this.toastr.warning(res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        }
      );
  }

}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  AbstractControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { timeout } from 'rxjs/operators';
import { CampusService } from 'src/app/utils/services/campus.service';
import { ListpeopledormService } from 'src/app/utils//services/listpeopledorm.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { RoomService } from 'src/app/utils/services/room.service';
import { data } from 'jquery';
import { AccessHistoryService } from 'src/app/utils/services/accesshistory.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { RoomcategoryService } from 'src/app/utils/services/room-category.service';
import { BaseComponent } from '../base/base.component';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-list-people-des',
  templateUrl: './list-people-des.component.html',
  styleUrls: ['./list-people-des.component.scss'],
})
export class ListPeopleDesComponent extends BaseComponent implements OnInit {
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  ID_ToaNha: any;
  ID_sv: any;
  ID_dangky: any;
  dataTable: any = [];
  dataCampus: any = [];
  dataBuilding: any = [];
  dataRoom: any = [];
  lstTinh: any = [];
  closeResult: string;
  ClassData: any;
  ID_room: any;
  ID: any;
  dt: any;
  So_Ho_Chieu: any;
  Ngay_Het_Han_Visa: any;
  address: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkAction: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải ...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Phong_Model: any;
  Action = new FormGroup({
    So_Ho_Chieu: new FormControl(null),
    Ngay_Het_Han_Visa: new FormControl(null),
    Ngay_bat_dau: new FormControl(null, [Validators.required]),
    Ngay_ket_thuc: new FormControl(null, [Validators.required]),
    Ma_sv: new FormControl(null, []),
    Ho_ten: new FormControl(null, []),
    Ngay_sinh: new FormControl(null, []),
    Gioi_Tinh: new FormControl(2, []),
    ID_quoc_tich: new FormControl(0, []),
    Dan_toc: new FormControl(0, []),
    CMND: new FormControl(null, []),
    Ngay_cap_CMND: new FormControl(null, []),
    Noi_cap_CMND: new FormControl(0, []),
    Ton_giao: new FormControl(null, []),
    Dia_chi_tt: new FormControl(null, []),
    Dienthoai_canhan: new FormControl(null, []),
    Email: new FormControl(null, []),
    Ho_ten_cha: new FormControl(null, []),
    Namsinh_cha: new FormControl(null, []),
    Hoat_dong_XH_CT_cha: new FormControl(null, []),
    Ho_ten_me: new FormControl(null, []),
    Namsinh_me: new FormControl(null, []),
    Hoat_dong_XH_CT_me: new FormControl(null, []),
    NoiO_hiennay: new FormControl(null, []),
    So_dien_thoai_bo: new FormControl(null, []),
    So_dien_thoai_me: new FormControl(null, []),
  });

  ActionChangeRoom = new FormGroup({
    ID_Campus: new FormControl(null),
    ID_Building: new FormControl(null),
    ID_Floor: new FormControl(null),
    ID_Room: new FormControl(null, []),
  });
  id_phong_ktx: any;
  FillMaSV: any;
  FillHoTen: any;
  FillLoaiDongTien: any = '';
  FillTrangThaiO: any = '';
  FillQuocTich: any;
  FillPhong: any = '';
  FillThoiGianRa: any;
  FillThoiGianVao: any;
  FillThoiGianVisa: any;
  Ngay_ra_KTX: any;
  Ngay_vao_KTX: any;
  dataQuocTich: any;
  dataDanToc: any;
  FillLoaiPhong: any = '';
  FillLy_do: any;
  FillPhongNamNu: any = '';
  dataTypeRoom: any;
  getNull = false;
  FillCoSo: any = '';
  FillToaNha: any = '';
  FillTang: any;
  dtReceipt: any;
  dataPrintf: any;
  dt_print: any = '123';
  dataRoomLock: any;
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  options = {};
  Fillter = new FormGroup({
    Hoc_ky_filter: new FormControl('1'),
    ID_tinh_tt_filter: new FormControl('0'),
    ID_dan_toc_filter: new FormControl('0'),
    Dienthoai_canhan_filter: new FormControl(null),
    Mail_filter: new FormControl(null),
    Ton_giao_filter: new FormControl(null),
    Nam_hoc_filter: new FormControl('2023-2024'),
  });
  Nam_hoc_arr: any = [];
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    $('.dataTables_filter').addClass('cus_ser');
    this.getList();
    this.getCount();
    this.getListTypeRoom();
    this.GetDanToc();
    this.getTinh();
  }
  getCount() {
    this.ListpeopledormService.getDanhSachRaCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }

  getTinh() {
    this.SystemService.Get_tinh(this.Token).subscribe((z) => {
      this.lstTinh = z;
    });
  }

  GetDanToc() {
    this.SystemService.Get_danh_sach_dan_toc(this.Token).subscribe((z) => {
      this.dataDanToc = z;
    });
  }
  getRequest() {
    return {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      ID_Toa_Nha: this.FillToaNha,
      ID_Co_So: this.FillCoSo,
      ID_Tang: this.FillTang,
      ID_phong: this.FillPhong,
      ID_loai_phong: this.FillLoaiPhong,
      Gioi_Tinh: this.FillPhongNamNu,
      Ma_sv: this.FillMaSV,
      Ho_ten: this.FillHoTen,
      Trang_thai_thanh_toan: this.FillLoaiDongTien,
      Thoi_gian_vao: this.FillThoiGianVao,
      Thoi_gian_ra: this.FillThoiGianRa,
      Ly_do: this.FillLy_do,
      Nam_hoc: this.Fillter.value.Nam_hoc_filter,
      Hoc_ky: 0,
      ID_tinh_tt: this.Fillter.value.ID_tinh_tt_filter,
      Dienthoai_canhan: this.Fillter.value.Dienthoai_canhan_filter,
      Mail: this.Fillter.value.Mail_filter,
      ID_dan_toc: this.Fillter.value.ID_dan_toc_filter,
      Ton_giao: this.Fillter.value.Ton_giao_filter,
    };
  }

  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = this.getRequest();
    this.ListpeopledormService.getDanhSachRa(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.router.navigate(['/admin']);
          this.spinner.hide();
        } else {
          this.dataTable = z.Data;
          this.ListpeopledormService.getDanhSachRaCountFilter(
            dataFilter,
            this.Token
          ).subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
        }
      }
    );
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 10000000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  refesh() {
    this.FillToaNha = '';
    this.FillCoSo = '';
    this.FillTang = '';
    this.FillPhong = '';
    this.FillLoaiPhong = '';
    this.FillPhongNamNu = '';
    this.FillMaSV = '';
    this.FillHoTen = '';
    this.FillLoaiDongTien = '';
    this.FillThoiGianVao = '';
    this.FillThoiGianRa = '';
    this.FillLy_do = '';
    this.Fillter.patchValue({
      ID_tinh_tt_filter: '0',
      ID_dan_toc_filter: '0',
      Dienthoai_canhan_filter: null,
      Mail_filter: null,
      Ton_giao_filter: null,
      Nam_hoc_filter: '2023-2024',
    });
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = this.getRequest();
    this.ListpeopledormService.getDanhSachRa(dataFilter, this.Token).subscribe(
      (z) => {
        if (z.Status == 10) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        } else {
          this.dataTable = z.Data;
          this.spinner.hide();
        }
      }
    );
  }
  getListTypeRoom() {
    this.RoomcategoryService.getList(this.getNull, this.Token).subscribe(
      (z) => {
        this.dataTypeRoom = z.Data;
        this.getListCampus();
      }
    );
  } // lấy danh sách loại phòng

  filterTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fill();
  }

  fill() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let bool1 = true,
        bool2 = true,
        bool3 = true,
        bool4 = true,
        bool5 = true,
        bool6 = true,
        bool7 = true,
        bool8 = true,
        bool9 = true,
        bool10 = true,
        bool11 = true,
        bool12 = true,
        bool13 = true,
        bool14 = true;
      if (this.FillMaSV != undefined) {
        bool1 = data[1].toLowerCase().includes(this.FillMaSV.toLowerCase());
      }
      if (this.FillHoTen != undefined) {
        bool2 = data[2].toLowerCase().includes(this.FillHoTen.toLowerCase());
      }
      if (this.FillLoaiDongTien != '') {
        if (this.FillLoaiDongTien == '0') {
          bool3 = data[13] == this.FillLoaiDongTien;
        }
        if (this.FillLoaiDongTien == '1') {
          bool3 = data[13] > '0';
        }
      }
      // if (this.FillQuocTich != undefined) {
      //   bool4 = data[4].toLowerCase().includes(this.FillQuocTich.toLowerCase());
      // }

      if (this.FillThoiGianRa != undefined) {
        const date1 = this.FillThoiGianRa.split('-')
          .reverse()
          .join('/')
          .substring(0, 10);
        const date2 = data[9].substring(0, 10);
        bool5 = date2.toLowerCase().includes(date1.toLowerCase());
      }
      // if (this.FillThoiGianVisa != undefined) {
      //   const date1 = this.FillThoiGianVisa.split('-')
      //     .reverse()
      //     .join('/')
      //     .substring(1, 10);
      //   const date2 = data[5].substring(1, 10);
      //   bool6 = date2.toLowerCase().includes(date1.toLowerCase());
      // }
      if (this.FillPhong != undefined) {
        let temp = data[7].split('-');
        bool7 = temp[1].toLowerCase().includes(this.FillPhong.toLowerCase());
      }
      if (this.FillCoSo != undefined) {
        bool9 = data[15].toLowerCase().includes(this.FillCoSo.toLowerCase());
      }
      if (this.FillToaNha != undefined) {
        bool8 = data[14].toLowerCase().includes(this.FillToaNha.toLowerCase());
      }
      if (this.FillTang != undefined) {
        bool10 = data[16].toLowerCase().includes(this.FillTang.toLowerCase());
      }
      if (this.FillLoaiPhong != '') {
        bool11 = data[17] == this.FillLoaiPhong;
      }
      if (this.FillPhongNamNu != '') {
        bool14 = this.removeAccents(data[18])
          .toLowerCase()
          .includes(this.removeAccents(this.FillPhongNamNu));
      }
      if (this.FillLy_do != undefined) {
        bool12 = data[22].toLowerCase().includes(this.FillLy_do);
      }
      if (this.FillThoiGianVao != undefined) {
        const date1 = this.FillThoiGianVao.split('-')
          .reverse()
          .join('/')
          .substring(0, 10);
        const date2 = data[8].substring(0, 10);
        bool13 = date2.toLowerCase().includes(date1.toLowerCase());
      }
      if (
        bool1 &&
        bool2 &&
        bool3 &&
        bool4 &&
        bool5 &&
        bool6 &&
        bool7 &&
        bool8 &&
        bool9 &&
        bool10 &&
        bool11 &&
        bool12 &&
        bool13 &&
        bool14
      ) {
        return true;
      }
      return false;
    });
  } // filter data table

  removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }

  Aprove() {
    this.dt.Trang_thai_o = 2;
    this.ListpeopledormService.UpdateRaKhoiKTX(this.dt, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('Approve');
        if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
        this.fill();
      }
    );
  } // cho sinh viên ra khỏi ktx

  // getListTypeRoom() {
  //   // this.spinner.show();
  //   this.RoomtypesService.getList(this.Token).subscribe((z) => {
  //     this.dataCampus = z.Data;
  //   });
  // }  // list all loai phong
  getListCampus() {
    // this.spinner.show();
    this.CampusService.getList(this.Token).subscribe((z) => {
      this.dataCampus = z.Data;
    });
  } // list all Cơ sở

  getListBuilding(id) {
    // this.FillToaNha = ''
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data.filter((x) => {
        return x.ID_CoSo == id;
      });
      if (this.dataBuilding.length > 0) {
        this.getListRoom(this.dataBuilding[0].ID_ToaNha);
      } else {
        this.FillPhong = '';
        this.filterTable();
      }
    });
  } // list tòa nhà

  getListRoom(id) {
    this.FillPhong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data.filter((x) => {
        return x.ID_Toa_Nha == id;
      });
      this.filterTable();
    });
  } // list phòng

  getListRoomNonLock(id) {
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoomLock = z.Data.filter((x) => {
        return x.ID_Toa_Nha == id && x.Khoa_phong == 0;
      });
    });
  } // list non lock
  changeCampus(id) {
    this.getListBuilding(id);
    this.getList();
  }
  changeRoom(id_toanha) {
    this.getListRoom(id_toanha);
    this.getList();
  }

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.onReset();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.onReset();
        }
      );
    this.dt = Data;

    if (Loai == 'Edit') {
      const date = new Date().toLocaleString();
      this.titleModal = 'Thông tin sinh viên';
      this.check = Data.So_Ho_Chieu;
      this.checkAction = true;
      if (!Data.Ngay_Het_Han_Visa) {
        Data.Ngay_Het_Han_Visa = date.substring(0, 10);
      }
      if (!Data.Ngay_bat_dau) {
        Data.Ngay_bat_dau = date.substring(0, 10);
      }
      if (!Data.Ngay_ket_thuc) {
        Data.Ngay_ket_thuc = date.substring(0, 10);
      }
      this.Action.setValue({
        So_Ho_Chieu: Data.So_Ho_Chieu,
        Ngay_Het_Han_Visa:
          Data.Ngay_Het_Han_Visa != null
            ? Data.Ngay_Het_Han_Visa.substring(0, 10)
            : null,
        Ngay_bat_dau:
          Data.Ngay_bat_dau != null ? Data.Ngay_bat_dau.substring(0, 10) : null,
        Ngay_ket_thuc:
          Data.Ngay_ket_thuc != null
            ? Data.Ngay_ket_thuc.substring(0, 10)
            : null,
        Ma_sv: Data.Ma_sv,
        Ho_ten: Data.Ho_ten,
        Ngay_sinh:
          Data.Ngay_sinh != null ? Data.Ngay_sinh.substring(0, 10) : null,
        Gioi_Tinh: Data.Gioi_Tinh,
        ID_quoc_tich: Data.Quoc_tich,
        Dan_toc: Data.Dan_toc,
        CMND: Data.CMND,
        Ngay_cap_CMND:
          Data.Ngay_cap_CMND != null
            ? Data.Ngay_cap_CMND.substring(0, 10)
            : null,
        Noi_cap_CMND: Data.ID_noi_cap_CMND,
        Ton_giao: Data.Ton_giao,
        Dia_chi_tt: Data.Dia_chi_tt,
        Dienthoai_canhan: Data.Dienthoai_canhan,
        Email: Data.Email,
        Ho_ten_cha: Data.Ho_ten_cha,
        Namsinh_cha:
          Data.Namsinh_cha != null ? Data.Namsinh_cha.substring(0, 10) : null,
        Hoat_dong_XH_CT_cha: Data.Hoat_dong_XH_CT_cha,
        Ho_ten_me: Data.Ho_ten_me,
        Namsinh_me:
          Data.Namsinh_me != null ? Data.Namsinh_me.substring(0, 10) : null,
        Hoat_dong_XH_CT_me: Data.Hoat_dong_XH_CT_me,
        NoiO_hiennay: Data.NoiO_hiennay,
        So_dien_thoai_bo: Data.So_dien_thoai_bo,
        So_dien_thoai_me: Data.So_dien_thoai_me,
      });
    } else if (Loai == 'Add') {
      this.titleModal = 'Thêm mới thông tin sinh viên';
      this.checkAction = false;
      this.Action.reset();
    } else if (Loai == 'changeRoom') {
      (this.ID_dangky = Data.ID_dang_ky), (this.ID_sv = Data.ID_sv);
      this.getListCampus();
      this.getListBuilding(Data.ID_Co_So);
      this.ActionChangeRoom.setValue({
        ID_Campus: Data.ID_Co_So,
        ID_Building: Data.ID_Toa_Nha,
        ID_Room: Data.ID_phong,
        ID_Floor: '',
      });
      this.Ngay_ra_KTX = Data.Ngay_ket_thuc;
      this.Ngay_vao_KTX = Data.Ngay_bat_dau;
      this.id_phong_ktx = Data.ID_phong;
    } else if (Loai == 'RoomLead') {
      this.ID_room = Data.ID_phong;
      this.ID_dangky = Data.ID_dang_ky;
      this.Ngay_ra_KTX = Data.Ngay_ket_thuc;
    }
  } //Modal all

  openprintf(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.onReset();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.onReset();
        }
      );
    this.dt = Data;
  } //Modal confirm  doi phong

  //check value
  get checkvalue() {
    return this.Action.controls;
  }

  ExportExcel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      let req = this.getRequest();
      this.spinner.show();
      this.ListpeopledormService.ExportDanhSachRaSinhVien(
        this.Token,
        req
      ).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'DanhSachRaChiTiet');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      });
    }
  }

  saveRoomHistory() {
    this.dt.ID_phong = this.ActionChangeRoom.value.ID_Room;
    if (this.id_phong_ktx != this.dt.ID_phong) {
      this.ListAccessHistory.Insert(this.dt, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success('Cập nhật thành công');
        } else {
          this.toastr.error(z.Message);
        }
        this.ActionChangeRoom.reset();
      });
    }
    this.modalService.dismissAll('ChangeClassModal');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.getList();
    this.fill();
  } // cập nhật thông tin vào ra phòng ktx

  onSubmit() {
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.actionUpdate();
  }
  onReset() {
    this.submitted = false;
    this.Action.reset();
    this.dataBuilding = [];
    this.dataRoom = [];
    this.dataCampus = [];
    this.ActionChangeRoom.reset();
  }
  // actionUpdate
  actionUpdate() {
    this.dt.So_Ho_Chieu = this.Action.value.So_Ho_Chieu;
    this.dt.Ngay_Het_Han_Visa = this.Action.value.Ngay_Het_Han_Visa;
    this.dt.Ngay_bat_dau = this.Action.value.Ngay_bat_dau;
    this.dt.Ngay_ket_thuc = this.Action.value.Ngay_ket_thuc;
    if (Date.parse(this.dt.Ngay_bat_dau) > Date.parse(this.dt.Ngay_ket_thuc)) {
      this.toastr.warning('Ngày ra KTX không thể nhỏ hơn ngày vào KTX');
    } else {
      this.ListpeopledormService.Update(this.dt, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.error('Cập nhật thất bại!');
        } else if (z.Status == 9) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
          this.modalService.dismissAll('ThemModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
          this.fill();
        }
        this.Action.reset();
      });
    }
  } // cập nhật số hộ chiếu quốc tịch đối với sinh viên nước ngoài

  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }

  checkHanraKTX(datetime) {
    let dateParts = datetime.split('-');
    let day = parseInt(dateParts[2]);
    let month = parseInt(dateParts[1]) - 1;
    let year = parseInt(dateParts[0]);
    let someday = new Date(year, month, day);
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    if (today > someday) {
      return true;
    }
    return false;
  }

  AproveChange() {
    if (this.Ngay_ra_KTX != null) {
      if (this.checkHanraKTX(this.Ngay_ra_KTX) == false) {
        this.ListpeopledormService.UpdateLeadRoom(
          this.ID_dangky,
          this.ID_room,
          this.Token
        ).subscribe((z) => {
          this.modalService.dismissAll('AddModal');
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
          this.fill();
        });
      } else {
        this.toastr.error('Sinh viên này đã hết hạn ở KTX kiểm tra lại !');
      }
    } else {
      this.toastr.warning('Ngày ra ký túc xá đang trống xin kiểm tra lại !');
    }
  } // kiểm tra hạn ra ktx

  Print() {
    this.dt_print = this.dt;
    setTimeout(() => {
      let printContents = document.getElementById('print-section').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 200);
    this.modalService.dismissAll('DeleteModal');
  } // in bien lai
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + '?' + this.appConfig.document_DanhSachRa;
    window.open(urlHelp, '_blank');
  }
}

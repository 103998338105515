
<div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <form [formGroup]="registerForm" class="login100-form validate-form" style="padding-top: 70px !important;">
          <img src="/assets/img/thienan.png" style="
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
              " class="center" />
          <!-- <span class="login100-form-title p- -43" style="margin-bottom: 30px;">
            UniDormitory
          </span> -->
          <label class="label_login"><i class="fas fa-id-card"></i> &nbsp; Mã học viên
          </label>
          <div class="wrap-input100 validate-input" style="height: 45px;">
            <input class="input100" type="text" formControlName="MaHocVien"
              placeholder="Nhập số mã học viên" />
            <span class="focus-input100"></span>
          </div>
          <label class="label_login"><i class="fas fa-user"></i> &nbsp; Họ và tên
          </label>
          <div class="wrap-input100 validate-input" style="height: 45px;">
            <input class="input100" placeholder="Nhập chính xác họ và tên" formControlName="HoTen" type="text" />
            <span class="focus-input100"></span>
          </div>
          <label class="label_login"><i class="fas fa-calendar-week"></i> &nbsp; Ngày sinh
          </label>
          <div class="wrap-input100 validate-input" style="height: 45px;">
            <input class="input100" type="date" formControlName="NgaySinh" />
            <span class="focus-input100"></span>
          </div>
          <label class="label_login"><i class="fas fa-calendar-week"></i> &nbsp; Đối tượng
          </label>
          <div class="" style="height: 45px;">
            <select class="form-control" formControlName="StudentCategory">
                <option value="1">Tiến sỹ</option>
                <option value="2">Thạc sĩ</option>
            </select>
            <span class="focus-input100"></span>
          </div>
          
          <div class="container-login100-form-btn" style="margin-top: 30px;">
            <button class="login100-form-btn" (click)="Confirm()" style="height: 45px;">
              Xác nhận thông tin sau Đại Học
            </button>
          </div>
          <br/>
          <div>
            <a [routerLink]="['/dang-nhap']" class="registertxt" style="text-align: center;">
              Trở về trang đăng nhập
            </a>
          </div>
          <div>
            <p  class="registertxt" style="text-align: left;">
              {{Title}}
            </p>
          </div>
        </form>
        <div class="login100-more" style="background-image: url('/assets/img/bg-login.jpg');"></div>
      </div>
    </div>
  </div>
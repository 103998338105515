<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Quy định khoản thu</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button class="btn btn-primary btn-sm" (click)="getList()" style="float: right; margin-right: 10px;">
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button class="btn btn-danger btn-sm" (click)="open(multiDeleteModal, 'xs', 'DeleteM', '')"
          style="float: right; margin-right: 10px;">
          <i class="fas fa-trash-alt"></i> Xóa
        </button>
        <button class="btn btn-success btn-sm" (click)="openModal(InsertModal, 'xl', 'Insert', '')"
          style="float: right; margin-right: 10px;">
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row mt10">
        <div class="col-md-2">
          <label>Mã/Tên sinh viên</label>
          <input (change)="getList()" type="text" class="form-control cus-fs" [(ngModel)]="Ma_sv_filter"
            placeholder="Nhập mã sinh viên ..." />
        </div>
        <div class="col-md-2">
          <label>Ngày sinh</label>
          <input (change)="getList()" type="date" class="form-control cus-fs" [(ngModel)]="Ngay_sinh_filter" />
        </div>
        <div class="col-md-2">
          <label>Học kỳ</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="Hoc_ky_filter">
            <ng-option value="">Tất cả</ng-option>
            <ng-option [value]="1">1</ng-option>
            <ng-option [value]="2">2</ng-option>
            <ng-option [value]="3">3</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Năm học</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="Nam_hoc_filter">
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let p of Nam_hoc_arr" [value]="p.value">{{
              p.name
              }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Tháng</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="Thang_filter">
            <ng-option value="">Tất cả</ng-option>
            <ng-option [value]="1">1</ng-option>
            <ng-option [value]="2">2</ng-option>
            <ng-option [value]="3">3</ng-option>
            <ng-option [value]="4">4</ng-option>
            <ng-option [value]="5">5</ng-option>
            <ng-option [value]="6">6</ng-option>
            <ng-option [value]="7">7</ng-option>
            <ng-option [value]="8">8</ng-option>
            <ng-option [value]="9">9</ng-option>
            <ng-option [value]="10">10</ng-option>
            <ng-option [value]="11">11</ng-option>
            <ng-option [value]="12">12</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Loại khoản thu</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="ID_thu_chi_filter">
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let element of dataLoaiKhoanThu" [value]="element.ID_thu_chi">{{ element.Ten_thu_chi }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>Cơ sở</label>
          <select notFoundText="Không có kết quả" (change)="getList()" class="form-control"
            [(ngModel)]="ID_co_so_filter" (change)="changeCampus($event.target.value)">
            <option value="">Chọn cơ sở</option>
            <option *ngFor="let i of dataCampus" value="{{ i.ID }}">{{
              i.name
              }}</option>
          </select>
        </div>
        <div class="col-md-2">
          <label>Tòa nhà</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="ID_toa_nha_filter"
            (change)="changeRoom()">
            <ng-option value="">Chọn tòa nhà</ng-option>
            <ng-option *ngFor="let i of dataBuilding" [value]="i.ID_ToaNha">{{
              i.Ten_ToaNha
              }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Tầng</label>
          <input (change)="getList()" class="form-control cus-fs" placeholder="Tìm kiếm tầng..."
            [(ngModel)]="Tang_filter" />
        </div>
        <div class="col-md-2">
          <label>Phòng</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="ID_phong_filter">
            <ng-option value="">Chọn phòng</ng-option>
            <ng-option *ngFor="let i of dataRoom" [value]="i.ID_Phong">{{
              i.Ten_phong
              }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Loại phòng</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="ID_loai_phong_filter">
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let p of dataTypeRoom" [value]="p.ID">{{ p.Ten_loai_phong }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Phòng Nam/Nữ</label>
          <ng-select notFoundText="Không có kết quả" (change)="getList()" [(ngModel)]="ID_gioi_tinh_filter">
            <ng-option value="" selected>Tất cả</ng-option>
            <ng-option [value]="0">Nam</ng-option>
            <ng-option [value]="1">Nữ</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button title="Ẩn/hiện bộ lọc" style="float: right; margin: 0 0 5px 5px;" (click)="buttonFilter()">
                <i class="fas fa-filter"></i>
              </button>
              <button title="Tải lại" style="float: right; margin-bottom: 5px;" (click)="refesh()">
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div class="table-responsive" style="max-height: 60vh; margin-bottom: 15px;">
            <table class="table table-bordered" id="tableData">
              <thead>
                <th>
                  <input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)" />
                </th>

                <th>Mã sinh viên</th>
                <th>Họ và tên</th>
                <th>Giới tính</th>
                <th>Ngày sinh</th>
                <th>Học kỳ</th>
                <th>Năm học</th>
                <th>Đợt</th>
                <th>Tháng</th>
                <th>Tòa nhà - Phòng</th>
                <th>Số tháng ở</th>
                <th>Tên khoản thu</th>
                <th>Số tiền</th>
                <th>
                  <div style="width: 250px;">Ghi chú</div>
                </th>
                <th>Ngày cập nhật</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i = index; let odd = odd">
                  <td>
                    <input type="checkbox" value="{{ p.ID_sv }}" [checked]="p.checked"
                      [(ngModel)]="dataTable[i].checked" />
                  </td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td class="text-center" *ngIf="p.ID_gioi_tinh == 1">Nữ</td>
                  <td class="text-center" *ngIf="p.ID_gioi_tinh == 0">Nam</td>
                  <td>{{ p.Ngay_sinh }}</td>
                  <td class="text-center">{{ p.Hoc_ky }}</td>
                  <td style="white-space: nowrap;">{{ p.Nam_hoc }}</td>
                  <td>{{ p.Dot }}</td>
                  <td>{{ p.Thang }}</td>
                  <td style="white-space: nowrap;">
                    {{ p.Ten_toa_nha }}-{{ p.Ten_phong }}
                  </td>
                  <td class="text-center">{{ p.So_thang_o }}</td>
                  <td>{{ p.Ten_thu_chi }}</td>
                  <td class="text-right">{{ p.So_tien | number }}</td>
                  <td>{{ p.Ghi_chu }}</td>
                  <td class="text-center">
                    {{ p.Ngay_cap_nhat | date: 'dd/MM/yyyy' }}
                  </td>
                  <td class="text-center" style="white-space: nowrap;">
                    <button class="btn btn-sm btn-danger" (click)="open(DeleteModal, 'xs', 'Delete', p)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                        }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)">Cuối</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i class="fas fa-trash-alt" style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "></i>
      </div>
      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        ">
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #multiDeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i class="fas fa-trash-alt" style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "></i>
      </div>
      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        ">
        Bạn chắc chắn muốn xóa {{ numberChecked.length }} sinh viên
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="buttonMultiDelete()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #InsertModal let-modal>
  <div class="row">
    <div style="width: 500px; margin: auto;">
      <div class="wrapper-progressBar">
        <ul class="progressBar">
          <li (click)="prev()" class="active">Chọn sinh viên</li>
          <li (click)="next()" [ngClass]="{ active: Display == true }">
            Quy định khoản thu
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!Display">
    <div class="row">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <label>Hệ</label>
            <select class="form-control" (change)="changeHe()" [(ngModel)]="ID_he" (change)="filterModal()">
              <option value="">Tất cả</option>
              <option *ngFor="let i of dataHe" value="{{ i.ID_he }}">{{
                i.Ten_he
                }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Khoa</label>
            <select class="form-control" [(ngModel)]="ID_khoa" (change)="changeKhoa()" (change)="filterModal()">
              <option value="">Tất cả</option>
              <option *ngFor="let i of dataKhoa" value="{{ i.ID_khoa }}">{{
                i.Ten_khoa
                }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Khóa</label>
            <select class="form-control" [(ngModel)]="Khoa_hoc" (change)="changeKhoaHoc()" (change)="filterModal()">
              <option value="">Tất cả</option>
              <option *ngFor="let i of dataKhoaHoc2" value="{{ i.Khoa_hoc }}">{{
                i.Khoa_hoc
                }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Chuyên ngành</label>
            <select class="form-control" [(ngModel)]="ID_chuyen_nganh" (change)="changeChuyenNganh()"
              (change)="filterModal()">
              <option value="">Tất cả</option>
              <option *ngFor="let i of dataChuyenNganh2" value="{{ i.ID_chuyen_nganh }}">{{ i.Chuyen_nganh }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-6">
            <label>Tìm kiếm theo mã hoặc tên sinh viên</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Tìm kiếm theo mã hoặc tên sinh viên"
                [(ngModel)]="Ma_sv" (change)="filterModal()" />
              <div class="input-group-append">
                <span class="input-group-text btn-primary" (click)="filterModal()"><i class="fas fa-search"></i></span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>Ngày sinh</label>
            <input type="date" class="form-control" [(ngModel)]="Ngay_sinh" (change)="filterModal()" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-body" *ngIf="!Display">
    <div class="row" style="font-family: sans-serif;">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane">
            <div class="row">
              <div class="col-md-6">
                Hiển thị
                <select (change)="handlePageSizeChange2($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                kết quả
              </div>
            </div>
            <div class="table-responsive" style="max-height: 50vh; margin-bottom: 15px;">
              <table class="table table-bordered" id="tableSV">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" [checked]="isAllCheckBoxChecked2()" (change)="checkAllCheckBox2($event)" />
                    </th>
                    <th>Mã SV</th>
                    <th>Tên sinh viên</th>
                    <th>Giới tính</th>
                    <th>Ngày sinh</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataSV2; let i = index; let odd = odd">
                    <td class="text-center">
                      <input type="checkbox" value="{{ p.ID_sv }}" [checked]="p.checked"
                        [(ngModel)]="dataSV2[i].checked" />
                    </td>
                    <td>{{ p.Ma_sv }}</td>
                    <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                    <td class="text-center" *ngIf="p.ID_gioi_tinh == 0">Nam</td>
                    <td class="text-center" *ngIf="p.ID_gioi_tinh == 1">Nữ</td>
                    <td class="text-center">
                      {{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>
                  <b>Tổng số bản ghi: {{ totalRecord2 | number }}</b>
                </p>
              </div>
              <div class="col-md-6">
                <nav aria-label="Page navigation example" style="float: right;">
                  <ul class="pagination">
                    <li class="page-item" [class.disabled]="page_sv == 1">
                      <a style="font-size: 14px !important;" class="page-link" (click)="changepage2(1)">Đầu</a>
                    </li>
                    <li class="page-item" [class.disabled]="page_sv == 1">
                      <a style="font-size: 14px !important;" class="page-link" (click)="changepage2('pre')">Trước</a>
                    </li>
                    <ng-container *ngIf="page_sv > 4">
                      <li class="page-item">
                        <a style="font-size: 14px !important;" class="page-link">...</a>
                      </li>
                    </ng-container>
                    <ng-container *ngFor="let element of arrNumberPage2_child">
                      <li class="page-item" [class.active]="page_sv == element">
                        <a style="font-size: 14px !important;" class="page-link" (click)="changepage2(element)">{{
                          element }}</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="page_sv < numberPage2 - 4">
                      <li class="page-item">
                        <a style="font-size: 14px !important;" class="page-link">...</a>
                      </li>
                    </ng-container>
                    <li class="page-item" [class.disabled]="page_sv == numberPage2">
                      <a style="font-size: 14px !important;" class="page-link" (click)="changepage2('next')">Sau</a>
                    </li>
                    <li class="page-item" [class.disabled]="page_sv == numberPage2">
                      <a style="font-size: 14px !important;" class="page-link"
                        (click)="changepage2(numberPage2)">Cuối</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="Display">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-2">
          <label class="require">Học kỳ</label>
          <ng-select notFoundText="Không có kết quả" formControlName="Hoc_ky"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Hoc_ky.errors }">
            <ng-option [value]="1">1</ng-option>
            <ng-option [value]="2">2</ng-option>
            <ng-option [value]="3">3</ng-option>
          </ng-select>
          <div *ngIf="submitted && checkvalue.Hoc_ky.errors" class="invalid-feedback">
            <div *ngIf="checkvalue.Hoc_ky.errors.required">
              Học kỳ không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label class="require">Năm học</label>
          <ng-select notFoundText="Không có kết quả" formControlName="Nam_hoc"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Nam_hoc.errors }">
            <ng-option *ngFor="let p of Nam_hoc_arr" [value]="p.value">{{
              p.name
              }}</ng-option>
          </ng-select>
          <div *ngIf="submitted && checkvalue.Nam_hoc.errors" class="invalid-feedback">
            <div *ngIf="checkvalue.Nam_hoc.errors.required">
              Năm học không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label class="require">Đợt</label>
          <ng-select notFoundText="Không có kết quả" formControlName="Dot"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Dot.errors }">
            <ng-option value="1">1</ng-option>
            <ng-option value="2">2</ng-option>
            <ng-option value="3">3</ng-option>
            <ng-option value="4">4</ng-option>
            <ng-option value="5">5</ng-option>
            <ng-option value="6">6</ng-option>
            <ng-option value="7">7</ng-option>
            <ng-option value="8">8</ng-option>
            <ng-option value="9">9</ng-option>
            <ng-option value="10">10</ng-option>
          </ng-select>
          <div *ngIf="submitted && checkvalue.Dot.errors" class="invalid-feedback">
            <div *ngIf="checkvalue.Dot.errors.required">
              Đợt không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Dot.errors.min">
              Đợt không được nhỏ hơn 1!
            </div>
          </div>
        </div>
        <div *ngIf="!disable" class="col-md-2">
          <label>Tháng</label>
          <ng-select  notFoundText="Không có kết quả" formControlName="Thang">
            <ng-option value="">Chọn tháng</ng-option>
            <ng-option [value]="1">1</ng-option>
            <ng-option [value]="2">2</ng-option>
            <ng-option [value]="3">3</ng-option>
            <ng-option [value]="4">4</ng-option>
            <ng-option [value]="5">5</ng-option>
            <ng-option [value]="6">6</ng-option>
            <ng-option [value]="7">7</ng-option>
            <ng-option [value]="8">8</ng-option>
            <ng-option [value]="9">9</ng-option>
            <ng-option [value]="10">10</ng-option>
            <ng-option [value]="11">11</ng-option>
            <ng-option [value]="12">12</ng-option>
            </ng-select>
        </div>
        <div class="col-md-2">
          <label class="require">Loại khoản thu</label>
          <ng-select (change)="changeKhoanThu()" notFoundText="Không có kết quả" formControlName="ID_thu_chi" [ngClass]="{
              'is-invalid': submitted && checkvalue.ID_thu_chi.errors
            }">
            <ng-option value="">Chọn loại khoản thu</ng-option>
            <ng-option *ngFor="let element of dataLoaiKhoanThu" [value]="element.ID_thu_chi">{{ element.Ten_thu_chi }}
            </ng-option>
          </ng-select>
          <div *ngIf="submitted && checkvalue.ID_thu_chi.errors" class="invalid-feedback">
            <div *ngIf="checkvalue.ID_thu_chi.errors.required">
              Loại khoản thu không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label class="require">Số tiền</label>
          <input type="number" class="form-control" formControlName="So_tien"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.So_tien.errors }" />
          <div *ngIf="submitted && checkvalue.So_tien.errors" class="invalid-feedback">
            <div *ngIf="checkvalue.So_tien.errors.required">
              Số tiền không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.So_tien.errors.min">
              Số tiền phải lớn hơn 0!
            </div>
          </div>
        </div>
        <div *ngIf="!disable" class="col-md-2">
          <label>Cơ sở</label>
          <ng-select [attr.disabled]="disable" notFoundText="Không có kết quả" formControlName="Co_so" (change)="changeCampusInsert()">
            <ng-option *ngFor="let value of dataCampus" [value]="value.ID">{{value.name}}</ng-option>
          </ng-select>
        </div>
        <div *ngIf="!disable" class="col-md-2">
          <label>Toà nhà</label>
          <ng-select [attr.disabled]="disable" notFoundText="Không có kết quả" formControlName="Toa_nha" (change)="changeBuildingInsert()">
            <ng-option value="">Chọn toà nhà</ng-option>
            <ng-option *ngFor="let value of dataBuilding" [value]="value.ID_ToaNha">{{value.Ten_ToaNha}}</ng-option>
          </ng-select>
        </div>
        <div *ngIf="!disable" class="col-md-2">
          <label>Phòng</label>
          <ng-select [attr.disabled]="disable" notFoundText="Không có kết quả" formControlName="Phong">
            <ng-option value="">Chọn phòng</ng-option>
            <ng-option *ngFor="let value of dataRoom" [value]="value.ID_Phong">{{value.Ten_phong}}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-12">
          <label>Ghi chú</label>
          <textarea class="form-control" formControlName="Ghi_chu" cols="100" rows="5"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" *ngIf="!Display" style="margin: 0 5px 0 5px;" class="btn btn-md btn-primary"
        (click)="next()">
        <i class="far fa-arrow-alt-circle-right"></i>
        Bước tiếp theo
      </button>
      <button type="button" *ngIf="Display" style="margin: 0 5px 0 5px;" class="btn btn-md btn-primary"
        (click)="prev()">
        <i class="far fa-arrow-alt-circle-left"></i>
        Quay lại
      </button>
      <button type="button" *ngIf="Display" style="margin: 0 5px 0 5px;" class="btn btn-md btn-success"
        (click)="save()">
        <i class="fas fa-plus-circle"></i>
        Lưu lại
      </button>
      <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em; margin: 0 5px 0 5px;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  TemplateRef,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
  AbstractControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { timeout } from 'rxjs/operators';
import { CampusService } from 'src/app/utils/services/campus.service';
import { CollectionReceiptService } from 'src/app/utils/services/collection-receipt.service';
import { RoomService } from 'src/app/utils/services/room.service';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'receipt-detail',
  templateUrl: './receipt-detail.component.html',
  styleUrls: ['./receipt-detail.component.scss'],
})
export class ReceiptDetailComponent implements OnInit {
  @Input() dt_print = {};
  dt_payment: any = [];
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  ID_ToaNha: any;
  dataTable: any = [];
  dataRooms: any = [];
  dataDetailReceipt: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  So_phieu: any;
  Noi_dung: any;
  address: any;
  titleModal: string;
  default: any;
  ID_bien_lai: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkAction: boolean;
  submitted = false;
  Ten_phong: any;
  Request: any;
  User: any;
  typeReceipt: any;
  tempdatatable: any;
  @ViewChild('Confirm') Confirm: TemplateRef<any>;
  Action = new FormGroup({
    So_phieu: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
    ]),
    Noi_dung: new FormControl(null, [Validators.required]),
    ID_phong: new FormControl(null, [Validators.required]),
    Ten_sv: new FormControl(null, [Validators.required]),
    Ma_sv: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    Ngay_thu: new FormControl(null, [Validators.required]),
    Nguoi_thu: new FormControl(null, [Validators.required, removeSpaces]),
    Loai_Khoan_Nop: new FormControl(null, [Validators.required]),
    // address: new FormControl(null, [Validators.maxLength(255)]),
  });

  constructor(
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private ReceiptService: CollectionReceiptService,
    private RoomService: RoomService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.ID_bien_lai = params.ID_bien_lai;
    });

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.User = a.Info;
    this.Token = a.Token;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      order: [[0, 'desc']],
    };

    this.getList();
  }

  getList() {
    this.spinner.show();
    this.ReceiptService.getList(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.dataTable = z.Data.filter((x) => {
          return x.ID_bien_lai == this.ID_bien_lai;
        });
        if (this.dataTable.length > 0) {
          this.Action.patchValue({
            So_phieu: this.dataTable[0].So_phieu,
            Noi_dung: this.dataTable[0].Noi_dung,
            ID_phong: this.dataTable[0].ID_phong,
            Ten_sv: this.dataTable[0].Ten_sv,
            Ma_sv: this.dataTable[0].Ma_sv,
            Ngay_thu:
              this.dataTable[0].Ngay_thu != undefined
                ? this.dataTable[0].Ngay_thu.substring(0, 10)
                : '',
            Nguoi_thu: this.dataTable[0].Nguoi_thu,
          });
          this.getListDetailReceipt(
            this.ID_bien_lai,
            this.dataTable[0].ID_phong
          );
        }
      } else {
        this.toastr.error(z.Message);
      }
      this.spinner.hide();
    });
  }

  filterTable() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    this.fill();
  }

  fill() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let bool1 = true;
      if (this.Action.value.Loai_Khoan_Nop != '') {
        bool1 =
          this.Action.value.Loai_Khoan_Nop ==
          (parseInt(data[11]) <= 11 ? 10 : 16);
      }
      if (bool1) {
        return true;
      }
      return false;
    });
  }

  getListDetailReceipt(ID, ID_phong) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.ReceiptService.GetAllDetailreceipt(ID, ID_phong, this.Token).subscribe(
      (z) => {
        this.dataDetailReceipt = z.Data;
        const khoanthu = this.dataDetailReceipt[0].ID_khoan_thu <= 11 ? 10 : 16;
        this.Action.patchValue({
          Loai_Khoan_Nop: khoanthu,
        });
        this.getListRoom();
        this.dtTrigger.next();
      }
    );
  }

  getListRoom() {
    // this.spinner.show();
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRooms = z.Data;
    });
  }
  //get list table all

  ModalConfim(content, sizm) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  } //modal
  //check value
  get checkvalue() {
    return this.Action.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Action.invalid) {
      return false;
    }
    this.ModalConfim(this.Confirm, 'xs');
  }
  onReset() {
    this.submitted = false;
    this.Action.reset();
  }
  // create

  checkAll(ev) {
    this.dataDetailReceipt.forEach((x) => (x.state = ev.target.checked));
  }

  isAllChecked() {
    return this.dataDetailReceipt.every((_) => _.state);
  }

  onChangePhong($event) {
    this.Ten_phong =
      $event.target.options[$event.target.options.selectedIndex].text;
  }

  Print() {
    this.typeReceipt = this.Action.value.Loai_Khoan_Nop;
    let ten_phong = this.dataRooms.filter((x) => {
      if (x.ID_Phong == this.Action.value.ID_phong) {
        return x;
      }
    });
    this.dt_print = {
      So_bl: this.Action.value.So_phieu,
      Ngay_thu: this.Action.value.Ngay_thu,
      Ho_ten: this.Action.value.Ten_sv,
      So_phong: ten_phong[0].Ten_phong,
      Ma_sv: this.Action.value.Ma_sv,
      Pament: this.dataDetailReceipt,
    };
    setTimeout(() => {
      let printContents = document.getElementById('print-section').innerHTML;
      const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
      mywindow.document.write(`
  <html>
    <body>${printContents}</body>
  </html>`);
      mywindow.document.close();
      mywindow.focus();

      mywindow.print();
      // mywindow.close();
    }, 200);
    this.modalService.dismissAll('DeleteModal');
  }

  Add() {
    let ds_khoan_nop = '';
    this.dataDetailReceipt.forEach((x) => {
      if (x.state == true) {
        this.dt_payment.push(x);
        ds_khoan_nop += x.ID_quy_dinh_khoan_nop + ',';
      }
    });

    const tempcheck = this.dataDetailReceipt
      .filter((t) => t.state == true)
      .every((x) => {
        return (
          parseInt(this.Action.value.Loai_Khoan_Nop) ===
          (x.ID_khoan_thu <= 11 ? 10 : 16)
        );
      });
    this.dt_print = {
      So_bl: this.Action.value.So_phieu,
      Ngay_thu: this.Action.value.Ngay_thu,
      Ho_ten: this.Action.value.Ten_sv,
      So_phong: this.Ten_phong,
      Ma_sv: this.Action.value.Ma_sv,
      Pament: this.dt_payment,
    };

    if (ds_khoan_nop == '') {
      this.toastr.warning('Chưa chọn khoản nộp !');
    } else {
      let obj_receipt = {
        So_phieu: this.Action.value.So_phieu,
        ID_sv: this.dataDetailReceipt[0].ID_sv,
        ID_phong: this.Action.value.ID_phong,
        Noi_dung: this.Action.value.Noi_dung,
        Nguoi_thu: this.Action.value.Nguoi_thu,
        Ngay_thu: this.Action.value.Ngay_thu,
      };
      if (tempcheck) {
        this.typeReceipt = this.Action.value.Loai_Khoan_Nop;

        this.ReceiptService.Insert(
          obj_receipt,
          ds_khoan_nop,
          this.Token
        ).subscribe((z) => {
          if (z.Status == 9) {
            this.toastr.warning(z.Message);
          }
          if (z.Status == 5) {
            this.toastr.error(z.Message);
          }
          if (z.Status == 1) {
            this.toastr.success(z.Message);
            let printContents = document.getElementById('print-section')
              .innerHTML;
            const mywindow = window.open('', 'PRINT', 'width=1023,height=1000');
            mywindow.document.write(`
            <html>
              <body>${printContents}</body>
            </html>`);
            mywindow.document.close();
            mywindow.focus();
            setTimeout(() => {
              mywindow.print();
              // mywindow.close();
            }, 400);
            this.modalService.dismissAll('DeleteModal');
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.FillSutudent();
          }
        });
      } else {
        this.toastr.error('Bạn không thể chọn cả loại khoản nộp khác nhau');
      }
    }
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  } // key close modal

  FillSutudent() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    if (!this.ID_bien_lai) {
      this.spinner.show();
      this.ReceiptService.GetAllCodeDetailreceipt(
        this.Action.value.Ma_sv,
        this.Token
      ).subscribe((z) => {
        this.dataDetailReceipt = z.Data;
        this.getListRoom();
        if (this.dataDetailReceipt.length > 0) {
          let loaikhoanthu =
            this.dataDetailReceipt[0].ID_khoan_thu <= 11 ? 10 : 16;
          this.Action.patchValue({
            Ten_sv: this.dataDetailReceipt[0].Ho_ten,
            ID_phong: this.dataDetailReceipt[0].ID_phong,
            Nguoi_thu: this.User.FullName,
            Loai_Khoan_Nop: '',
          });
        }
        this.dtTrigger.next();
        this.spinner.hide();
      });
    }
  } // tìm kiếm theo mã sinh viên

  runScript(e) {
    if (e.keyCode == 13) {
      this.FillSutudent();
    }
  } // nhạp key enter tim kiem

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
}

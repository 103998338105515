import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GrantCardService } from 'src/app/utils/services/grantcard.service';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { ExportService } from 'src/app/utils/services/export.service';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-grantcard',
  templateUrl: './grantcard.component.html',
  styleUrls: ['./grantcard.component.scss'],
})
export class GrantcardComponent implements OnInit {
  dtOptions: any;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  ID: any;
  So_the: any;
  Ngay_cap_the: any;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkInsert: boolean;
  submitted = false;
  NameTitle: string;
  Title: string = 'Đang tải danh sách...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  Insert = new FormGroup({
    ID: new FormControl(null),
    So_the: new FormControl(null, [
      Validators.required,
      Validators.maxLength(20),
      removeSpaces,
    ]),
    Ngay_cap_the: new FormControl(null, [Validators.required, removeSpaces]),
  });

  constructor(
    private modalService: NgbModal,
    private GrantCardService: GrantCardService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public exportService: ExportService
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          text: ' <i class="far fa-file-excel"></i>&nbspXuất excel ',
          titleAttr: 'Xuất excel ',
          className: 'btn-sm',
          exportOptions: {
            columns: [2, 3, 4, 5, 6, 7],
            orthogonal: 'export',
            className: 'btn  btn-success',
            format: {
              body: function (data, row, column, node) {
                return data;
              },
            },
          },
        },
      ],
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID đăng ký',
          visible: false,
        },
        {
          title: 'ID sinh viên',
          visible: false,
        },
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ tên',
        },
        {
          title: 'Ngày sinh',
        },
        {
          title: 'Trạng thái thẻ',
        },
        {
          title: 'Số thẻ',
        },
        {
          title: 'Ngày cấp thẻ',
        },
        {
          title: 'Thao tác',
          width: '18%',
          className: 'dt-center',
        },
      ],
      order: [[0, 'desc']],
    };
    this.getList(a.Token);
  }
  getList(token) {
    this.spinner.show();
    this.GrantCardService.getList(token).subscribe((z) => {
      for (let i = 0; i < z.Data.length; i++) {
        if (z.Data[i].Trang_thai_the == '0') {
          z.Data[i].Trang_thai_the = 'Hết hiệu lực';
        } else {
          z.Data[i].Trang_thai_the = 'Còn hiệu lực';
        }
      }
      this.dataTable = z.Data;
      this.dtTrigger.next();
      this.Title = '';
      this.TitleClass = '';
      this.spinner.hide();
    });
  }
  //get
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == 'Edit') {
      this.titleModal = 'Sửa thông tin thẻ';
      this.checkInsert = true;
      this.Insert.get('So_the').setValue(Data.So_the);
      if (Data.Ngay_cap_the != null) {
        this.Insert.get('Ngay_cap_the').setValue(
          Data.Ngay_cap_the.substring(0, 10)
        );
        this.Ngay_cap_the = Data.Ngay_cap_the.substring(0, 10);
      } else {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let date = yyyy + '-' + mm + '-' + dd;
        this.Insert.get('Ngay_cap_the').setValue(date);
        this.Ngay_cap_the = date;
      }
      this.check = Data.code;
      this.ID = Data.ID_dang_ky;
      this.So_the = Data.So_the;
    } else if (Loai == 'Delete') {
      this.ID = Data.ID_dang_ky;
      this.Ngay_cap_the = Data.Ngay_cap_the.substring(0, 10);
      this.So_the = Data.So_the;
    }
  }
  //check value
  get checkvalue() {
    return this.Insert.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    this.create();
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
  }
  // create
  create() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let InputDatas = {
      ID_dang_ky: this.ID,
      So_the: this.So_the,
      Ngay_cap_the: this.Ngay_cap_the,
      Trang_thai_the: 1,
    };
    this.GrantCardService.Update(InputDatas, a.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.error('Cập nhật thất bại!');
      } else if (z.Status == 1) {
        this.toastr.success('Cập nhật thành công!');
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList(a.Token);
      }
    });
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
  Delete() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let InputDatas = {
      ID_dang_ky: this.ID,
      So_the: '',
      Ngay_cap_the: '',
      Trang_thai_the: 0,
    };
    this.GrantCardService.Update(InputDatas, a.Token).subscribe((z) => {
      this.modalService.dismissAll('AddModal');
      if (z.Status == 2) {
        this.toastr.warning('Thu hồi thẻ thất bại');
      } else if (z.Status == 1) {
        this.toastr.success('Thu hồi thẻ thành công');
      } else {
        this.toastr.error(z.Message);
      }
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList(a.Token);
    });
  }
  excel() {
    var r = confirm('File của bạn sẽ được tải xuống trong giây lát!');
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.GrantCardService.Excel(a.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'Capphatthuhoithe');
          this.toastr.success('File đã được tải xuống', 'Tác vụ thành công');
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          this.spinner.hide();
        }
      });
    }
  }
}

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Danh sách ở</li>
        </ol>
      </div>
      <div class="col-sm-6">
        <button
          class="btn btn-primary btn-sm"
          (click)="getList()"
          style="float: right; margin-right: 10px;"
        >
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
        <button
          class="btn btn-sm btn-success"
          (click)="open(MultiGiaHanModal, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
          ngbTooltip="Gia hạn "
        >
          <i class="fas fa-angle-double-right"></i> Gia hạn
        </button>
        <button
          class="btn btn-sm btn-secondary"
          (click)="open(ChoRaktx, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
          ngbTooltip="Cho ra KTX"
        >
          <i class="fas fa-trash-alt"></i> Ra KTX
        </button>
        <button
          class="btn btn-sm btn-info"
          (click)="open(Guimail, 'xl', '', '')"
          style="float: right; margin-right: 10px;"
          ngbTooltip="Gửi email thông báo"
        >
          <i class="far fa-envelope"></i> Gửi thông báo
        </button>
        <button
          class="btn btn-sm btn-success"
          (click)="open(ExportExcel, 'xs', '', '')"
          style="float: right; margin-right: 10px;"
          ngbTooltip="Cho ra đúng hạn"
          ngbTooltip="Xuất excel cho danh sách ở"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label>Mã sinh viên</label>
          <input
            type="text"
            class="form-control cus-fs"
            [(ngModel)]="FillMaSV"
            placeholder="Nhập mã sinh viên ..."
            (keyup.enter)="getList()"
          />
        </div>
        <div class="col-md-2">
          <label>Tên sinh viên</label>
          <input
            type="text"
            class="form-control cus-fs"
            [(ngModel)]="FillHoTen"
            placeholder="Nhập tên sinh viên..."
            (keyup.enter)="getList()"
          />
        </div>
        <div class="col-md-2">
          <label>Thời gian vào </label>
          <span>
            <input
              type="date"
              [(ngModel)]="FillThoiGianVao"
              (keyup.enter)="getList()"
              class="form-control cus-fs"
            />
          </span>
        </div>
        <div class="col-md-2">
          <label>Thời gian ra </label>
          <span>
            <input
              type="date"
              [(ngModel)]="FillThoiGianRa"
              (keyup.enter)="getList()"
              class="form-control cus-fs"
            />
          </span>
        </div>
        <div class="col-md-2">
          <label>Phòng Nam/Nữ</label>
          <ng-select [(ngModel)]="FillPhongNamNu" (change)="getList()">
            <ng-option value="" selected>Tất cả</ng-option>
            <ng-option value="0">Nam</ng-option>
            <ng-option value="1">Nữ</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Loại phòng</label>

          <ng-select [(ngModel)]="FillLoaiPhong" (change)="getList()">
            <ng-option value="">Tất cả</ng-option>
            <ng-option *ngFor="let p of dataTypeRoom" [value]="p.ID"
              >{{ p.Ten_loai_phong }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-2">
          <label>Cơ sở</label>
          <ng-select [(ngModel)]="FillCoSo" (change)="changeCampus()">
            <ng-option value="">Chọn cơ sở</ng-option>
            <ng-option *ngFor="let i of dataCampus" [value]="i.ID">{{
              i.name
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Tòa nhà</label>
          <ng-select [(ngModel)]="FillToaNha" (change)="changeRoom()">
            <ng-option value="">Chọn tòa nhà</ng-option>
            <ng-option *ngFor="let i of dataBuilding" [value]="i.ID_ToaNha">{{
              i.Ten_ToaNha
            }}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Tầng</label>
          <input
            class="form-control cus-fs"
            placeholder="Tìm kiếm tầng..."
            [(ngModel)]="FillTang"
            (keyup.enter)="getList()"
          />
        </div>
        <div class="col-md-2">
          <label>Tên phòng</label>
          <input
            class="form-control cus-fs"
            placeholder="Tìm kiếm tên phòng..."
            [(ngModel)]="FillPhong"
            (keyup.enter)="getList()"
          />
        </div>
        <div class="col-md-4">
          <span
            ><label>Sinh viên sắp hết hạn </label> &nbsp;
            <input
              type="checkbox"
              [(ngModel)]="Sap_het_han"
              (change)="getList()"
          /></span>
        </div>
      </div>
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-2">
            <label>Dân tộc</label>
            <ng-select (change)="getList()" formControlName="ID_dan_toc_filter">
              <ng-option value="0" selected>Chọn dân tộc</ng-option>
              <ng-option *ngFor="let p of dataDanToc" [value]="p.ID_dan_toc">{{
                p.Dan_toc
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <label>Tôn giáo</label>
            <input
              type="text"
              (change)="getList()"
              formControlName="Ton_giao_filter"
              class="form-control"
              placeholder="Tôn giáo"
            />
          </div>

          <div class="col-md-2">
            <label>Tỉnh thường trú</label>
            <ng-select (change)="getList()" formControlName="ID_tinh_tt_filter">
              <ng-option value="0" selected>Tỉnh thường trú</ng-option>
              <ng-option *ngFor="let p of lstTinh" [value]="p.ID_tinh">{{
                p.Ten_tinh
              }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <label>Điện thoại</label>
            <input
              type="text"
              (change)="getList()"
              formControlName="Dienthoai_canhan_filter"
              class="form-control"
              placeholder="Điện thoại"
            />
          </div>
          <div class="col-md-2">
            <label>Địa chỉ mail</label>
            <input
              type="text"
              (change)="getList()"
              formControlName="Mail_filter"
              class="form-control"
              placeholder="Địa chỉ email"
            />
          </div>
          <div class="col-md-2">
            <label>Năm học</label>
            <ng-select (change)="getList()" formControlName="Nam_hoc_filter">
              <ng-option *ngFor="let i of Nam_hoc_arr" [value]="i.value">{{
                i.name
              }}</ng-option>
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <input
              type="text"
              [(ngModel)]="searchString"
              placeholder="Tìm kiếm nhanh"
              style="float: right; margin-bottom: 10px;"
            />
            <table class="table table-bordered">
              <thead>
                <th>
                  <input
                    type="checkbox"
                    [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)"
                  />
                </th>
                <th>Mã sinh viên</th>
                <th>Họ và tên</th>
                <th>Giới tính</th>
                <th>Chức vụ</th>
                <th>Khoa - Lớp</th>
                <th>Tòa nhà - Phòng</th>
                <th>Số tháng ở</th>
                <th>Ngày vào KTX</th>
                <th>Ngày ra KTX</th>
                <th>Số tiền phải đóng</th>
                <th>Số tiền đã đóng</th>
                <th>Số tiền thừa/thiếu</th>
                <th>Số tiền hoàn trả</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let p of dataTable | filter: searchString;
                    let i = index;
                    let odd = odd
                  "
                >
                  <td>
                    <input
                      type="checkbox"
                      value="{{ p.ID_dang_ky }}"
                      [checked]="p.checked"
                      [(ngModel)]="dataTable[i].checked"
                    />
                  </td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td *ngIf="p.Gioi_Tinh">Nữ</td>
                  <td *ngIf="!p.Gioi_Tinh">Nam</td>
                  <td *ngIf="p.Truong_Phong">
                    Trưởng phòng
                  </td>
                  <td *ngIf="!p.Truong_Phong">
                    Thành viên
                  </td>
                  <td>{{ p.Khoa_Lop }}</td>
                  <td>{{ p.ToaNha_Phong }}</td>
                  <td>{{ p.So_thang_o }}</td>
                  <td>{{ p.Ngay_bat_dau | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Ngay_ket_thuc | date: 'dd/MM/yyyy' }}</td>
                  <td class="text-right">{{ p.So_tien_phai_dong | number }}</td>
                  <td class="text-right">{{ p.So_tien_da_nop | number }}</td>
                  <td class="text-right">{{ p.So_tien_con_no | number }}</td>
                  <td class="text-right">{{ p.So_tien_hoan_tra | number }}</td>
                  <td style="white-space: nowrap;">
                    <button
                      class="btn btn-sm btn-info"
                      (click)="GetData_Print2(p)"
                      style="margin-right: 4px;"
                    >
                      <i class="fas fa-print" ngbTooltip="In hợp đồng"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-warning"
                      (click)="open(ThemModal, 'xl', 'Edit', p)"
                      style="margin-right: 4px;"
                    >
                      <i class="far fa-id-badge" ngbTooltip="Xem thông tin"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-primary"
                      (click)="open(ChangeClassModal, 'xs', 'changeRoom', p)"
                      style="margin-right: 4px;"
                    >
                      <i class="fas fa-sync-alt" ngbTooltip="Đổi phòng "></i>
                    </button>
                    <button
                      class="btn btn-sm btn-info"
                      style="margin-right: 4px;"
                      (click)="open(RoomLead, '', 'RoomLead', p)"
                    >
                      <i
                        class="fas fa-exchange-alt"
                        ngbTooltip="Đổi trưởng phòng"
                      ></i>
                    </button>
                    <button
                      class="btn btn-sm btn-success"
                      (click)="open(RaHanModal, '', 'RaHan', p)"
                    >
                      <i class="fas fa-angle-double-right"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataTable.length == 0">
                  <td colspan="15" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">{{ titleModal }}</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <form class="form-horizontal" [formGroup]="Action">
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Mã SV</label>
          <input
            class="form-control"
            placeholder="Nhập mã sv"
            formControlName="Ma_sv"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Họ tên</label>
          <input
            class="form-control"
            placeholder="Nhập họ tên"
            formControlName="Ho_ten"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày sinh</label>
          <input
            class="form-control"
            placeholder="Nhập ngày sinh"
            formControlName="Ngay_sinh"
            type="date"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Giới tính</label>
          <select class="form-control" formControlName="Gioi_Tinh" disabled>
            <option value="2">Chọn giới tính</option>
            <option value="0">Nam</option>
            <option value="1">Nữ</option>
          </select>
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Số hộ chiếu</label>
          <input
            class="form-control cus-fs"
            placeholder="Nhập số hộ chiếu"
            type="text"
            formControlName="So_Ho_Chieu"
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày hết hạn Visa</label>
          <input
            class="form-control cus-fs"
            placeholder="Nhập ngày hết hạn Visa"
            type="date"
            formControlName="Ngay_Het_Han_Visa"
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10 require">Ngày vào KTX</label>
          <input
            class="form-control cus-fs"
            disabled
            required
            type="date"
            formControlName="Ngay_bat_dau"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ngay_bat_dau.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ngay_bat_dau.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ngay_bat_dau.errors.required">
              Ngày vào không được bỏ trống!
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-md-10 require">Ngày ra KTX</label>
          <input
            class="form-control cus-fs"
            disabled
            required
            type="date"
            formControlName="Ngay_ket_thuc"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ngay_ket_thuc.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ngay_ket_thuc.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ngay_ket_thuc.errors.required">
              Ngày vào không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">CMND</label>
          <input
            class="form-control"
            placeholder="Nhập cmnd"
            formControlName="CMND"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Ngày cấp</label>
          <input
            class="form-control"
            placeholder="Nhập ngày cấp"
            formControlName="Ngay_cap_CMND"
            type="date"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Nơi cấp</label>
          <input
            class="form-control"
            placeholder="Nhập nơi cấp"
            formControlName="Noi_cap_CMND"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Tôn giáo</label>
          <input
            class="form-control"
            placeholder="Nhập tôn giáo"
            formControlName="Ton_giao"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Điện thoại</label>
          <input
            class="form-control"
            placeholder="Nhập điện thoại"
            formControlName="Dienthoai_canhan"
            type="text"
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Email</label>
          <input
            class="form-control"
            placeholder="Nhập email"
            formControlName="Email"
            type="text"
          />
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Quốc tịch</label>
          <input
            class="form-control"
            placeholder="Nhập dân tộc"
            formControlName="ID_quoc_tich"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Dân tộc</label>
          <input
            class="form-control"
            placeholder="Nhập dân tộc"
            formControlName="Dan_toc"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Họ tên cha</label>
          <input
            class="form-control"
            placeholder="Nhập họ tên cha"
            formControlName="Ho_ten_cha"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Năm sinh cha</label>
          <input
            class="form-control"
            placeholder="Nhập năm sinh cha"
            formControlName="Namsinh_cha"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Nghề nghiệp cha</label>
          <input
            class="form-control"
            placeholder="Nhập nghề nghiệp cha"
            formControlName="Hoat_dong_XH_CT_cha"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Số đt cha</label>
          <input
            class="form-control"
            placeholder="Nhập số đt cha"
            formControlName="So_dien_thoai_bo"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="row mt10">
        <div class="col-md-3">
          <label class="col-md-10">Họ tên mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập họ tên mẹ"
            formControlName="Ho_ten_me"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Năm sinh mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập năm sinh mẹ"
            formControlName="Namsinh_me"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Nghề nghiệp mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập nghề nghiệp mẹ"
            formControlName="Hoat_dong_XH_CT_me"
            type="text"
            disabled
          />
        </div>

        <div class="col-md-3">
          <label class="col-md-10">Số đt mẹ</label>
          <input
            class="form-control"
            placeholder="Nhập số đt mẹ"
            formControlName="So_dien_thoai_me"
            type="text"
            disabled
          />
        </div>
      </div>

      <div class="row mt10">
        <div class="col-md-6">
          <label class="col-md-10">Địa chỉ</label>
          <input
            class="form-control"
            placeholder="Nhập địa chỉ"
            formControlName="Dia_chi_tt"
            type="text"
          />
        </div>
        <div class="col-md-6 `">
          <label class="col-md-10">Nơi ở hiện nay</label>
          <input
            class="form-control"
            placeholder="Nhập nơi ở hiện nay"
            formControlName="NoiO_hiennay"
            type="text"
            disabled
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #RaHanModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Gia hạn thời gian ở</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <div class="row">
      <div class="col-md-12">
        <label
          >Thời gian ở: {{ dataDangKyQuaTrinh.Tu_ngay | date: 'dd/MM/yyyy' }} -
          {{ dataDangKyQuaTrinh.Den_ngay | date: 'dd/MM/yyyy' }}</label
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="col-md-10 require">Ngày gia hạn</label>
        <input
          type="date"
          class="form-control"
          (change)="changeGiaHan()"
          [(ngModel)]="Ngay_gia_han"
        />
      </div>
      <div class="col-md-12" style="margin-top: 5px;">
        <label class="col-md-10">Tổng số tháng ở : {{ So_thang_o }} </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="GiaHan()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #MultiGiaHanModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Ra hạn thời gian ở</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <div class="row">
      <div class="col-md-12">
        <label class="col-md-10 require">Ngày gia hạn</label>
        <input
          type="date"
          class="form-control"
          (change)="changeGiaHan()"
          [(ngModel)]="Ngay_gia_han"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="MultiGiaHan()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ChangeClassModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h5 class="modal-title h-title w-100">Đổi phòng {{ NameStudent }}</h5>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body cus-fs">
    <form class="form-horizontal" [formGroup]="ActionChangeRoom">
      <div class="row">
        <label style="margin-left: 5px; color: red;"
          >Thời gian ở: {{ dataDangKyQuaTrinh.Tu_ngay | date: 'dd/MM/yyyy' }}
          -
          {{ dataDangKyQuaTrinh.Den_ngay | date: 'dd/MM/yyyy' }}</label
        >
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Mã sinh viên</label>
          <input
            class="form-control"
            formControlName="Ma_sv_dp"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Họ tên</label>
          <input
            class="form-control"
            formControlName="Ho_ten_dp"
            type="text"
            disabled
          />
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Cơ sở</label>
          <select
            class="form-control"
            formControlName="ID_Campus"
            (change)="changeCampusRep()"
          >
            <option value="">Chọn cơ sở</option>
            <option *ngFor="let item of dataCampusChange" [value]="item.ID">{{
              item.name
            }}</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Tòa nhà</label>
          <select
            class="form-control"
            formControlName="ID_Building"
            (change)="changeRoomRep()"
          >
            <option value="">Chọn toà nhà</option>

            <option
              *ngFor="let item of dataBuildingChange"
              [value]="item.ID_ToaNha"
              >{{ item.Ten_ToaNha }}</option
            >
          </select>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Phòng</label>
          <select class="form-control" formControlName="ID_Room">
            <option value="">Chọn phòng</option>
            <option
              *ngFor="let item of dataRoomChange"
              [value]="item.ID_Phong"
              >{{ item.Ten_phong }}</option
            >
          </select>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12">
        <label class="col-md-12">Ngày đổi</label>
        <input
          class="form-control"
          type="date"
          [(ngModel)]="Ngay_ra_phong_cu"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onChangeRoom()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #Approve let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-arrow-circle-right"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn cho sinh viên này ra khỏi kí túc xá
      </p>
      <textarea
        class="form-control"
        placeholder="Nhập lý do "
        [(ngModel)]="Ly_do"
      ></textarea>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Aprove()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #RoomLead let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box" style="border: solid green;">
        <i
          class="fas fa-plus-circle"
          style="
            color: #177a2c;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn cho sinh viên thành trưởng phòng
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="AproveChange()"
        >
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Confirm let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-print"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn tạo biên lai
      </p>
      <div class="text-center">
        <div>
          <button
            type="button"
            class="btn btn-md btn-success"
            (click)="Print()"
          >
            Đồng ý
          </button>
          <button
            type="button"
            class="btn btn-md btn-danger"
            (click)="modal.dismiss('Cross click')"
            style="margin-left: 1em;"
          >
            Hủy bỏ
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div id="print-section" style="display: none;">
  <app-receipt-room [dt_print]="dt_print"></app-receipt-room>
</div>
<div id="print-section2" style="display: none;">
  <app-contract
    [dt_print2]="dt_print2"
    [dl_sv]="dl_sv"
    [dl_room]="dl_room"
  ></app-contract>
</div>

<ng-template #ChoRaktx let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Cho ra khỏi ký túc xá</h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-6 form-group">
          <label class="form-control">
            <input type="checkbox" class="md-textarea" [(ngModel)]="Check_ra" />
            Ra đột xuất
          </label>
        </div>
        <div class="col-md-6 form-group">
          <label class="form-control">
            <input
              type="checkbox"
              class="md-textarea"
              [(ngModel)]="Check_blacklist"
            />
            Vào sách đen
          </label>
        </div>
      </div>
      <div class="row" *ngIf="Check_ra">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Ngày ra</label>
          <input
            type="date"
            class="md-textarea form-control"
            [(ngModel)]="Ngay_ra_ktx"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Lý do cho ra</label>
          <textarea
            type="text"
            class="md-textarea form-control"
            [(ngModel)]="Ly_do"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="ChoRa()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #Guimail let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Gửi mail thông báo cho sinh viên</h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10"
            >Tài khoản mail dùng để gửi mail thông báo</label
          >
          <input
            type="text"
            class="md-textarea form-control"
            [(ngModel)]="Mail_send"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10"
            >Mật khẩu của mail dùng để gửi mail thông báo</label
          >
          <input
            type="password"
            class="md-textarea form-control"
            [(ngModel)]="Mail_send_pass"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Tiêu đề mail</label>
          <input
            type="text"
            class="md-textarea form-control"
            [(ngModel)]="Title_mail"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="col-md-10">Nội dung mail</label>
          <textarea
            type="textarea"
            class="md-textarea form-control"
            style="height: 250px;"
            [(ngModel)]="Content_mail"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group" style="font-size: small;">
          <label class="col-md-10">Kích hoạt dịch vụ gửi mail</label><br />
          Bấm
          <a
            href="https://accounts.google.com/"
            routerLinkActive="active"
            style="color: #177a2c;"
            target="_blank"
            >vào đây </a
          >để thực hiện đăng nhập vào tài khoản gmail dùng để gửi mail thông báo
          được nhập ở trên; sau đó bấm vào
          <a
            href="https://myaccount.google.com/lesssecureapps"
            routerLinkActive="active"
            style="color: blue;"
            target="_blank"
            >kích hoạt
          </a>
          để thiết lập chế độ cho phép sử dụng dịch vụ gửi mail
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="SendMail()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ExportExcel let-modal>
  <div class="modal-header">
    <h4 style="margin: auto;">Bạn có muốn xuất excel danh sách ở này</h4>
  </div>

  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="ExportExcelList()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        style="float: right; margin-left: 1em;"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

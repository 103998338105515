import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl,FormGroup,FormBuilder,Validators, MaxLengthValidator,} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-add-payment-regulations',
  templateUrl: './add-payment-regulations.component.html',
  styleUrls: ['./add-payment-regulations.component.scss']
})
export class AddPaymentRegulationsComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dtTrigger = new Subject();
  Hinh_thuc_thu:any;
  dataTable=[];
  MonthOption:any=[]
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  Insert = new FormGroup({
    Hinh_thuc_thu: new FormControl(true,[Validators.required]),
    Thang: new FormControl((new Date().getMonth() + 1),[Validators.required]),
    Nam: new FormControl((new Date().getFullYear()),[Validators.required]),
    Hoc_ky: new FormControl(1,[Validators.required]),
    Nam_hoc: new FormControl(new Date().getFullYear() + '-' + (new Date().getFullYear() + 1),[Validators.required]),
    So_tien: new FormControl(null,[Validators.required,Validators.min(0)]),
    Phan_tram_mien_giam: new FormControl(0,[Validators.min(0)]),
    So_tien_mien_giam: new FormControl(0,[Validators.min(0)]),
    Thanh_tien: new FormControl(null),
    Noi_dung: new FormControl(null,[removeSpaces]),
    ID_tham_so: new FormControl('ID_khoan_thu_tien_phong',[Validators.required]),
  });
  ngOnInit(): void {
    this.MonthOption = [
      { 'value': 1, 'name': 'Tháng 1' }, { 'value': 2, 'name': 'Tháng 2' },
      { 'value': 3, 'name': 'Tháng 3' }, { 'value': 4, 'name': 'Tháng 4' },
      { 'value': 5, 'name': 'Tháng 5' }, { 'value': 6, 'name': 'Tháng 6' },
      { 'value': 7, 'name': 'Tháng 7' }, { 'value': 8, 'name': 'Tháng 8' },
      { 'value': 9, 'name': 'Tháng 9' }, { 'value': 10, 'name': 'Tháng 10' },
      { 'value': 11, 'name': 'Tháng 11' }, { 'value': 12, 'name': 'Tháng 12' },
    ]
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,      
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          width:'30px'
        },
        {
          title: 'ID_sv',
          visible:false
        },
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ và tên',
        },
        {
          title: 'Ngày sinh',
        },
        {
          title: 'Quê quán',
        }
      ],
      "order": [[ 0, "desc" ]]
    };
    
    this.getAll();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  getAll(){
    
    this.dataTable = [
       {ID_sv:1, Ma_sinh_vien:'15130298',Ho_ten:'Nguyễn Thành Trung',Ngay_sinh:'13/1/1997',Que_quan:'Hải Dương'},
       {ID_sv:2, Ma_sinh_vien:'15124578',Ho_ten:'Lê Thanh Nghị',Ngay_sinh:'13/1/1997',Que_quan:'Hải Dương'},
       {ID_sv:3, Ma_sinh_vien:'15156847',Ho_ten:'Trần Hữu Công',Ngay_sinh:'13/1/1997',Que_quan:'Hải Dương'},
       {ID_sv:4, Ma_sinh_vien:'15142786',Ho_ten:'Lê Thị Thúy',Ngay_sinh:'13/1/1997',Que_quan:'Quảng Ninh'},
       {ID_sv:5, Ma_sinh_vien:'15197524',Ho_ten:'Lăng Văn Nhăng',Ngay_sinh:'13/1/1997',Que_quan:'Bắc Giang'},
       {ID_sv:6, Ma_sinh_vien:'15135555',Ho_ten:'Lừa Văn Quỵt',Ngay_sinh:'13/1/1997',Que_quan:'Hải Phòng'},
       {ID_sv:7, Ma_sinh_vien:'15178999',Ho_ten:'Cao Thanh Lâm',Ngay_sinh:'13/1/1997',Que_quan:'Hà Nội'},
    //   {ID_sv:1, Ma_sinh_vien:'15130298',Ho_ten:'Nguyễn Thành Trung',Ngay_sinh:'13/1/1997',Que_quan:'Hải Dương'},
     ];
  }
  changeHinh_thuc() {
    let a = this.Insert.controls.Hinh_thuc_thu.value;
    if (a == "true") {
      this.Hinh_thuc_thu = true
    } else {
      this.Hinh_thuc_thu = false
    }
  }
  changeSoTien() {
    let st = this.Insert.controls.So_tien.value
    let a = parseFloat(st ? st.toString() : '0')
    let ptmg = this.Insert.controls.Phan_tram_mien_giam.value
    let b = parseFloat(ptmg ? ptmg.toString() : '0')
    let stmg = this.Insert.controls.So_tien_mien_giam.value
    let c = parseFloat(stmg ? stmg.toString() : '0')
    let d = a - a * b / 100 - c
    this.Insert.patchValue({ Thanh_tien: d })
  }


}

import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-khoan-thu-add',
  templateUrl: './khoan-thu-add.component.html',
  styleUrls: ['./khoan-thu-add.component.scss'],
})
export class KhoanThuAddComponent extends BaseComponent implements OnInit {
  dataSinhVien = [];
  Nam_hoc_arr = [];
  KhoanThuData: any = [];
  SinhVienData: any = [];
  TongHopData: any = [];
  date_now = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  Insert = new FormGroup({
    Hoc_ky: new FormControl(1),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
    ),
    Gian_thu: new FormControl(1),
    Lan_thu: new FormControl(1),
    Ngay_chi: new FormControl(this.date_now, [Validators.required]),
    So_phieu: new FormControl(null),
    Loai_tien: new FormControl('VND'),
    code: new FormControl('', [Validators.required]),
    Noi_dung: new FormControl('Thu tiền nội trú KTX'),
  });
  ngOnInit(): void {
    if (new Date().getMonth() > 8) {
      this.Insert.get('Nam_hoc').setValue(
        new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)
      );
    } else {
      this.Insert.get('Nam_hoc').setValue(
        new Date().getFullYear() - 1 + '-' + new Date().getFullYear()
      );
    }
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.getToken();
  }
  OnSearch($event) {
    this.InvoinceService.getSinhVien($event.term, this.Token).subscribe((z) => {
      this.dataSinhVien = z.Data;
    });
  }
  OnSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    var lst = jQuery.grep(this.KhoanThuData, function (pa: any, i) {
      return pa.Selected;
    });

    if (lst.length < 1) {
      this.toastr.warning('Bạn chưa chọn khoản thu!');
    } else {
      var So_tien = 0;
      lst.forEach((value) => {
        So_tien += value.So_tien_thu;
      });

      let input = {
        lstKhoanHoanTra: lst,
        BienLaiData: {
          Hoc_ky: this.Insert.value.Hoc_ky,
          Nam_hoc: this.Insert.value.Nam_hoc,
          Lan_thu: this.Insert.value.Lan_thu,
          ID_sv: this.Insert.value.code,
          Thu_chi: true,
          ID_lop: 0,
          So_phieu: this.Insert.value.So_phieu,
          Ngay_thu: this.Insert.value.Ngay_chi,
          Noi_dung: this.Insert.value.Noi_dung,
          Ngoai_te: this.Insert.value.Loai_tien,
          So_tien: So_tien,
          Ghi_chu: '',
        },
      };
      this.spinner.show();
      this.InvoinceService.InsertKhoanThu(input, this.Token).subscribe((z) => {
        if (z.Status == 1) {
          this.toastr.success('Thêm thành công');
          this.KhoanThuData = [];
          this.TongHopData = [];
          this.dataSinhVien = [];
          this.Insert.value.code = '';
        } else {
          this.toastr.error('Thêm thất bại');
        }
        this.spinner.hide();
      });
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  changeSinhVien() {
    this.InvoinceService.getThongTinKhoanThu(
      this.Insert.value.code,
      this.Token
    ).subscribe((z) => {
      this.SinhVienData = z.SinhVienData;
      this.KhoanThuData = z.KhoanThuData;
      this.TongHopData = z.TongHopData;
      this.Insert.patchValue({ So_phieu: z.So_phieu });
    });
  }
  CheckAll() {
    if ($('#SelectALL').prop('checked')) {
      for (let index = 0; index < this.KhoanThuData.length; index++) {
        this.KhoanThuData[index].Selected = true;
      }
    } else {
      for (let index = 0; index < this.KhoanThuData.length; index++) {
        this.KhoanThuData[index].Selected = false;
      }
    }
  }
  selectHoanTraData(data, i) {
    for (let index = 0; index < this.KhoanThuData.length; index++) {
      if (index == i) {
        if ($('#ID_' + i).prop('checked')) {
          {
            this.KhoanThuData[index].Selected = true;
          }
        } else {
          this.KhoanThuData[index].Selected = false;
        }
        break;
      }
    }
  }
}

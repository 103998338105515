<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/room']">Phòng ký túc xá</a>
          </li>
          <li class="breadcrumb-item active">
            Thiết bị trong phòng {{ Maphong }}
          </li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'lg', 'Add', '')"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row" style="padding-left: 10px; padding-right: 10px;">
          <div class="col-md-3">
            <label class="col-md-10">Mã thiết bị:</label>
            <input
              class="form-control"
              type="text"
              id="Ma_thiet_bi_ft"
              formControlName="Ma_thiet_bi_ft"
              (keyup)="filterById()"
              [(ngModel)]="Ma_thiet_bi_ft"
              placeholder="Nhập mã thiết bị cần tìm"
            />
          </div>
          <div class="col-md-3">
            <label class="col-md-10">Tên thiết bị:</label>
            <input
              class="form-control"
              type="text"
              id="Ten_thiet_bi_dt"
              formControlName="Ten_thiet_bi_dt"
              (keyup)="filterById()"
              [(ngModel)]="Ten_thiet_bi_dt"
              placeholder="Nhập tên thiết bị cần tìm"
            />
          </div>
          <div class="col-md-3">
            <label class="col-md-10">Trạng thái thiết bị:</label>
            <select
              class="form-control"
              id="Trang_thai_ft"
              formControlName="Trang_thai_ft"
              [(ngModel)]="Trang_thai_ft"
              (change)="filterById()"
            >
              <option value="" selected>Chọn trạng thái</option>
              <option value="true">Đang sử dụng</option>
              <option value="false">Không sử dụng</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.ID_phong_thiet_bi }}</td>
                      <td>{{ p.ID_phong }}</td>
                      <td>{{ p.ID_thiet_bi }}</td>
                      <td>{{ p.Ma_thiet_bi }}</td>
                      <td>{{ p.Ten_thiet_bi }}</td>
                      <td>{{ p.Trang_thai }}</td>
                      <td>{{ p.Trang_thai_text }}</td>
                      <td>{{ p.So_luong }}</td>
                      <td>{{ p.Ghi_chu }}</td>
                      <td>
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'lg', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-edit" title="Sửa cơ sở"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-trash-alt" title="Xoá cơ sở"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require">Thiết bị</label>
          <select
            name=""
            class="form-control"
            id="ID_thiet_bi"
            formControlName="ID_thiet_bi"
            [(ngModel)]="ID_thiet_bi"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.ID_thiet_bi.errors
            }"
          >
            <option *ngFor="let p of DSThietBi" [value]="p.ID">{{
              p.name
            }}</option>
          </select>
          <div
            *ngIf="submitted && checkvalue.ID_thiet_bi.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.ID_thiet_bi.errors.required">
              Vui lòng chọn thiết bị
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Số lượng</label>
          <input
            class="form-control"
            required
            placeholder="Nhập số lượng thiết bị"
            type="number"
            id="So_luong"
            formControlName="So_luong"
            [(ngModel)]="So_luong"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.So_luong.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.So_luong.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.So_luong.errors.required">
              Số lượng không được để trống
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Trạng thái</label>
          <select
            name=""
            class="form-control"
            id="Trang_thai"
            formControlName="Trang_thai"
            [(ngModel)]="Trang_thai"
          >
            <option value="true">Đang sử dụng</option>
            <option value="false">Không sử dụng</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Ghi chú</label>
          <input
            class="form-control"
            placeholder="Nhập ghi chú"
            type="text"
            id="Ghi_chu"
            formControlName="Ghi_chu"
            [(ngModel)]="Ghi_chu"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.Ghi_chu.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.Ghi_chu.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ghi_chu.errors.maxlength">
              Ghi chú tối đa 500 ký tự!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="onSubmit()"
        style="margin-left: 1em;"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="Delete()"
          style="margin-left: 1em;"
        >
          Đồng ý
        </button>
      </div>
    </div>
  </div>
</ng-template>

<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Thiết bị</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'xs', 'Add', '')"
          (click)="onReset()"
          style="float: right; margin-left: 10px;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="Excel()"
          style="float: right;"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="row">
          <div class="col-md-3">
            <label>Mã thiết bị</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="code_filter"
              formControlName="code_filter"
              (keyup)="filterById()"
              placeholder="Tìm kiếm mã thiết bị ..."
            />
          </div>
          <div class="col-md-3">
            <label>Tên thiết bị</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="name_filter"
              formControlName="name_filter"
              (keyup)="filterById()"
              placeholder="Tìm kiếm tên thiết bị..."
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of dataTable">
                      <td>{{ p.ID }}</td>
                      <td>{{ p.code }}</td>
                      <td>{{ p.name }}</td>
                      <td>{{ p.unit }}</td>
                      <td class="text-right">{{ p.price | number }}</td>
                      <td>{{ p.note }}</td>
                      <td>
                        <button
                          class="btn btn-sm btn-success"
                          (click)="open(ViewModal, 'xl', 'View', p)"
                          style="margin-right: 7px;"
                        >
                          <i
                            class="fas fa-share-square"
                            title="Chia thiết bị"
                          ></i>
                        </button>
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(ThemModal, 'xs', 'Edit', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-edit" title="Sửa thiết bị"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="open(DeleteModal, '', 'Delete', p)"
                          style="margin-right: 7px;"
                        >
                          <i class="fas fa-trash-alt" title="Xoá thiết bị"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require">Mã thiết bị</label>
          <input
            class="form-control"
            required
            placeholder="Nhập mã thiết bị"
            type="text"
            id="code"
            formControlName="code"
            [(ngModel)]="code"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.code.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.code.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.code.errors.required">
              Mã thiết bị không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.code.errors.maxlength">
              Mã thiết bị tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Tên thiết bị</label>
          <input
            class="form-control"
            required
            placeholder="Nhập tên thiết bị"
            type="text"
            id="name"
            formControlName="name"
            [(ngModel)]="name"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.name.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.name.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.name.errors.required">
              Tên thiết bị không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.name.errors.maxlength">
              Tên thiết bị tối đa 70 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Đơn vị</label>
          <input
            class="form-control"
            placeholder="Nhập đơn vị"
            type="text"
            id="unit"
            formControlName="unit"
            [(ngModel)]="unit"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.unit.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.unit.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.unit.errors.required">
              Đơn vị không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.unit.errors.maxlength">
              Đơn vị tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Đơn giá</label>
          <input
            class="form-control"
            placeholder="Nhập đơn giá"
            type="number"
            id="price"
            formControlName="price"
            [(ngModel)]="price"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.price.errors }"
          />
          <div
            *ngIf="submitted && checkvalue.price.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.price.errors.required">
              Đơn giá không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.price.errors.maxlength">
              Đơn giá tối đa 20 ký tự!
            </div>
            <div *ngIf="checkvalue.price.errors.min">
              Đơn giá không được nhỏ hơn 0!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Ghi chú</label>
          <textarea
            id="note"
            rows="2"
            cols="100"
            class="form-control"
            placeholder="Nhập ghi chú"
            formControlName="note"
            [(ngModel)]="note"
            [ngClass]="{ 'is-invalid': submitted && checkvalue.note.errors }"
          >
          </textarea>
          <div
            *ngIf="submitted && checkvalue.note.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.note.errors.maxlength">
              Ghi chú tối đa 500 ký tự!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ViewModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">Phân phối thiết bị</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <label
        class="col-md-3"
        style="
          text-align: center;
          margin-bottom: 0px;
          margin-left: -27px;
          margin-right: -40px;
        "
        >Số thiết bị mỗi phòng:
      </label>
      <input placeholder="Nhập số thiết bị" type="number" id="soluongphat" />
    </div>
    <div class="row" style="margin-top: 10px;">
      <div class="col-md-12">
        <div
          class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
        >
          <table
            datatable
            [dtOptions]="dtOptions2"
            id="chitietdt"
            class="table-bordered dataTables_wrapper no-footer"
          >
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="SelectALL"
                    (change)="CheckAll()"
                    value="SelectAll"
                  />
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of datadetail">
                <td>
                  <input
                    type="checkbox"
                    (change)="SelectRow(p.ID_Phong)"
                    name="SelectData"
                    id="{{ p.ID_Phong }}"
                    [checked]="p.Selected"
                    value="{{ p.ID_Phong }}"
                  />
                </td>
                <td>{{ p.Ma_phong }}</td>
                <td>{{ p.Ten_phong }}</td>
                <td>{{ p.Tang }}</td>
                <td>{{ p.Ten_toa_nha }}</td>
                <td>{{ p.Ten_co_so }}</td>
                <td>{{ p.Loai_phong_text }}</td>
                <td>{{ p.Suc_chua }}</td>
                <td>{{ p.Ghi_chu }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.col -->
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-success"
        (click)="OnSubmit2()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

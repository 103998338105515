<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['//admin']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Tòa nhà</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(ThemModal, 'xs', 'Add', '')"
          (click)="onReset()"
          style="float: right;"
        >
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div
              class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
            >
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable">
                    <td>{{ p.ID_ToaNha }}</td>
                    <td>{{ p.Ma_ToaNha }}</td>
                    <td>{{ p.Ten_ToaNha }}</td>
                    <td>{{ p.Ten_CoSo }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-warning"
                        (click)="open(ThemModal, 'xs', 'Edit', p)"
                        style="margin-right: 7px;"
                      >
                        <i class="fas fa-edit" title="Sửa tòa nhà"></i>
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        (click)="open(DeleteModal, '', 'Delete', p)"
                        style="margin-right: 7px;"
                      >
                        <i class="fas fa-trash-alt" title="Xoá tòa nhà"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #ThemModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px;"
  >
    <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px;"
      >&times;</span
    >
  </div>
  <div class="modal-body" style="margin-top: 5px; padding-top: 5px;">
    <form class="form-horizontal" [formGroup]="Action">
      <div class="row" style="font-family: sans-serif;">
        <div class="col-md-12">
          <label class="col-md-10 require">Mã tòa nhà</label>
          <input
            class="form-control"
            required
            placeholder="Nhập mã tòa nhà"
            type="text"
            formControlName="Ma_ToaNha"
            id="Ma_ToaNha"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ma_ToaNha.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ma_ToaNha.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ma_ToaNha.errors.required">
              Mã tòa nhà không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Ma_ToaNha.errors.maxlength">
              Mã tòa nhà tối đa 20 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require" style="margin-top: 10px;"
            >Tên tòa nhà</label
          >
          <input
            class="form-control"
            required
            placeholder="Nhập tên tòa nhà"
            type="text"
            formControlName="Ten_ToaNha"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.Ten_ToaNha.errors
            }"
          />
          <div
            *ngIf="submitted && checkvalue.Ten_ToaNha.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.Ten_ToaNha.errors.required">
              Tên tòa nhà không được bỏ trống!
            </div>
            <div *ngIf="checkvalue.Ten_ToaNha.errors.maxlength">
              Tên tòa nhà tối đa 255 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require" style="margin-top: 10px;"
            >Cơ sở</label
          >
          <select
            class="form-control"
            required
            formControlName="ID_CoSo"
            [ngClass]="{
              'is-invalid': submitted && checkvalue.ID_CoSo.errors
            }"
          >
            <option *ngFor="let item of dataCampus" value="{{ item.ID }}">{{
              item.name
            }}</option>
          </select>
          <div
            *ngIf="submitted && checkvalue.ID_CoSo.errors"
            class="invalid-feedback"
          >
            <div *ngIf="checkvalue.ID_CoSo.errors.required">
              Cơ sở không được bỏ trống!
            </div>
          </div>
          <!-- <input class="form-control" placeholder="Nhập tên tòa nhà" type="text" id="address"
                        formControlTen_ToaNha="address" [(ngModel)]="address"
                        [ngClass]="{ 'is-invalid': submitted && checkvalue.address.errors }" />
                    <div *ngIf="submitted && checkvalue.address.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.address.errors.maxlength">
                            Địa chỉ tối đa 255 ký tự!
                        </div>
                    </div> -->
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i
          class="fas fa-trash-alt"
          style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "
        ></i>
      </div>
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>

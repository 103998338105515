import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-print-so-phong',
  templateUrl: './print-so-phong.component.html',
  styleUrls: ['./print-so-phong.component.scss'],
})
export class PrintSoPhongComponent extends BaseComponent implements OnInit {
  @Input() public data: any;
  baseURL: string;
  StudentImage: string;
  ngOnInit(): void {
    this.baseURL = this.appConfig.URL_Anh_SV;
  }

  updateUrl() {
    this.StudentImage =
      'https://www.kindpng.com/picc/m/22-223863_no-avatar-png-circle-transparent-png.png';
  }
}

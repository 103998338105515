import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { BaseComponent } from '../base/base.component';
@Component({
  selector: 'app-users-toa-nha',
  templateUrl: './users-toa-nha.component.html',
  styleUrls: ['./users-toa-nha.component.scss'],
})
export class UsersToaNhaComponent extends BaseComponent implements OnInit {
  FullName: any = '';
  UserName: any = '';
  Data:any
  dataUser:any
  UserID:any
  dataView:any=[]
  numberPage2
  arrNumberPage2_child
  pageSize2=2
  page2=1
  totalRecord2
  beforeFilter
  dataAfter:any=[]
  public dataTable: any = [];
  ngOnInit(): void {

    this.getToken();
    this.getCount();
    this.getList();
  }
  getCount() {
    this.spinner.show();
    this.UserToaNhaService.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
      this.spinner.hide();
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    }
    );
  }
  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      FullName: this.FullName.trim(),
      UserName: this.UserName.trim(),
    };
    this.UserToaNhaService.getList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.UserToaNhaService.getCountFilter(dataFilter, this.Token).subscribe(
        (z) => {
          this.totalItemFilter = z.Tong_so_ban_ghi_filter;
          this.numberPage = this.createNumberPage(
            z.Tong_so_ban_ghi_filter,
            this.pageSize
          ).numberPage;
          this.arrNumberPage_chil = this.createNumberPage(
            z.Tong_so_ban_ghi_filter,
            this.pageSize
          ).arrNumberPage_chil;
          this.spinner.hide();
        }
      );
      this.spinner.hide();
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  refesh() {
    this.FullName = '';
    this.UserName = '';
    this.getList();
  }
  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      FullName: this.FullName.trim(),
      UserName: this.UserName.trim(),
    };
    this.UserToaNhaService.getList(dataFilter, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      this.spinner.hide();
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  handlePageSizeChange(event) {
    if (event.target.value == 'Tất cả') {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }
  open(content, sizm, Loai, Data) {
    this.Data = Data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
      this.spinner.show()
      this.UserToaNhaService.getUser( this.Token).subscribe((z) => {
        this.dataUser = z.Data;
        this.UserID = z.Data[0].UserID;
        this.BuildingService.getList( this.Token).subscribe((z) => {
          this.dataBuilding = z.Data;
          this.spinner.hide()
        });
      });
  }
  openEdit(content, sizm, Loai, Data) {
    this.Data = Data;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
      if(Loai=='Add'){
      this.spinner.show()
      this.UserToaNhaService.getUser( this.Token).subscribe((z) => {
        this.dataUser = z.Data;
        this.UserID = Data.UserID;
        this.BuildingService.getList( this.Token).subscribe((z) => {
          this.dataBuilding = z.Data;
          this.UserToaNhaService.getDetail(Data.UserID, this.Token).subscribe((z) => {
            for(var i =0; i<z.Data.length; i++) {
              for(var j =0; j<this.dataBuilding.length; j++) {
               if(z.Data[i].ID_toa_nha == this.dataBuilding[j].ID_ToaNha){
                this.dataBuilding[j].checked=true
               }
              }
            }
            this.spinner.hide()
          });
        });
      });
    }
  }
  checkAllCheckBox2(ev) {
    this.dataBuilding.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked2() {
    if (this.dataBuilding != undefined) return this.dataBuilding.every((p) => p.checked);
  }
  OnSubmit(){
    if(this.UserID==''){
      this.toastr.warning("Bạn chưa chọn tài khoản!")
      return false
    }
    if(this.dataBuilding.filter((x) => x.checked == true).length <1){
      this.toastr.warning("Bạn chưa chọn tòa nhà!")
      return false
    }
    let dataTN = this.dataBuilding.filter((x) => x.checked == true)
    let req = {
      UserID: this.UserID,
      dataBuilding: dataTN
    }
    this.UserToaNhaService.CheckExist(this.UserID, this.Token).subscribe((z) => {
      if(z.Message >0){
        this.toastr.warning("Tài khoản này đã được quy định!")
      }else{
        this.UserToaNhaService.Insert(req, this.Token).subscribe((z) => {
          if(z.Status ==1){
            this.toastr.success(z.Message)
            this.getList()
            this.getCount()
            this.modalService.dismissAll('AddModal');
    
          }else{
            this.toastr.error("Thêm quyền thất bại!")
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        });
      }
    });
    
  }
  update(){
    if(this.UserID==''){
      this.toastr.warning("Bạn chưa chọn tài khoản!")
      return false
    }
    if(this.dataBuilding.filter((x) => x.checked == true).length <1){
      this.toastr.warning("Bạn chưa chọn tòa nhà!")
      return false
    }
    let dataTN = this.dataBuilding.filter((x) => x.checked == true)
    let req = {
      UserID: this.UserID,
      dataBuilding: dataTN
    }
    this.UserToaNhaService.Update(req, this.Token).subscribe((z) => {
      if(z.Status ==1){
        this.toastr.success(z.Message)
        this.getList()
        this.modalService.dismissAll('AddModal');

      }else{
        this.toastr.error("Thêm quyền thất bại!")
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });
  }
  Delete(){
    this.UserToaNhaService.Delete(this.Data.UserID, this.Token).subscribe((z) => {
      if(z.Status == 1){
        this.toastr.success(z.Message)
        this.getList()
        this.getCount()
        this.modalService.dismissAll('AddModal');

      }else{
        this.toastr.error(z.Message)
      }
    },
    (err) => {
      this.spinner.hide();
      if (err.status == 0) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
      if (err.status == 401) {
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
      }
    });

  }
}

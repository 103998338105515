<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Biên lai hoá đơn</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          [routerLink]="['/admin/invoince-add']"
          style="float: right;"
        >
          Lập phiếu chi
        </button>
        <button
          class="btn btn-danger btn-sm"
          [routerLink]="['/admin/KhoanThu-add']"
          style="float: right; margin-right: 5px;"
        >
          Lập phiếu thu
        </button>
        <button
          class="btn btn-success btn-sm"
          style="float: right; margin-right: 5px;"
          (click)="open(ImportKhoanThuChi, 'xs', 'ImportExcel', '')"
        >
          <i class="fas fa-upload"></i> Import khoản thu chi
        </button>
        <button
          class="btn btn-success btn-sm"
          style="float: right; margin-right: 5px;"
          (click)="print()"
        >
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" *ngIf="isDisplay">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <label>Mã/Tên sinh viên</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="Ma_ten_sv"
            (change)="getList()"
            placeholder="Tìm kiếm theo mã/tên"
          />
        </div>
        <div class="col-md-3">
          <label>Học kỳ</label>
          <ng-select
            notFoundText="Không có kết quả"
            [(ngModel)]="Hoc_ky"
            (change)="getList()"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option value="1">1</ng-option>
            <ng-option value="2">2</ng-option>
            <ng-option value="3">3</ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label>Năm học</label>
          <ng-select
            notFoundText="Không có kết quả"
            [(ngModel)]="Nam_hoc"
            (change)="getList()"
          >
            <ng-option
              *ngFor="let value of Nam_hoc_arr"
              value="{{ value.value }}"
              >{{ value.name }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label>Phiếu thu/chi</label>
          <ng-select
            notFoundText="Không có kết quả"
            [(ngModel)]="Thu_chi"
            (change)="getList()"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option value="false">Phiếu chi</ng-option>
            <ng-option value="true">Phiếu thu</ng-option>
          </ng-select>
        </div>
        <div class="col-md-3">
          <label>Từ ngày</label>
          <input
            type="date"
            class="form-control"
            [(ngModel)]="Tu_ngay"
            (change)="getList()"
          />
        </div>
        <div class="col-md-3">
          <label>Đến ngày</label>
          <input
            type="date"
            class="form-control"
            [(ngModel)]="Den_ngay"
            (change)="getList()"
          />
        </div>
        <div class="col-md-3">
          <label>Loại phiếu</label>
          <ng-select
            notFoundText="Không có kết quả"
            [(ngModel)]="Huy_phieu"
            (change)="getList()"
          >
            <ng-option value="">Tất cả</ng-option>
            <ng-option value="false">Phiếu thường</ng-option>
            <ng-option value="true">Phiếu huỷ</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button
                title="Ẩn/hiện bộ lọc"
                style="float: right; margin: 0 0 5px 5px;"
                (click)="buttonFilter()"
              >
                <i class="fas fa-filter"></i>
              </button>
              <button
                title="Tải lại"
                style="float: right; margin-bottom: 5px;"
                (click)="refesh()"
              >
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div
            class="table-responsive"
            style="max-height: 60vh; margin-bottom: 15px;"
          >
            <table class="table table-bordered">
              <thead>
                <th>Số phiếu</th>
                <th>Ký hiệu phiếu</th>
                <th>Mã sinh viên</th>
                <th>Họ tên</th>
                <th>Ngày sinh</th>
                <th>Giới tính</th>
                <th>Học kỳ</th>
                <th>Năm học</th>
                <th>Số tiền</th>
                <th>Ngày thu/chi</th>
                <th>Người thu/chi</th>
                <th>Nội dung</th>
                <th>Loại phiếu</th>
                <th>Huỷ phiếu</th>
                <th>Thao tác</th>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable">
                  <td>{{ p.So_phieu }}</td>
                  <td>{{ p.So_phieu_header }}</td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Ngay_sinh | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Gioi_tinh }}</td>
                  <td>{{ p.Hoc_ky }}</td>
                  <td style="white-space: nowrap;">{{ p.Nam_hoc }}</td>
                  <td>{{ p.So_tien | number }}</td>
                  <td>{{ p.Ngay_thu | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ p.Nguoi_thu }}</td>
                  <td style="min-width: 200px; max-width: 300px;">
                    {{ p.Noi_dung }}
                  </td>
                  <td class="text-success" *ngIf="p.Thu_chi">Phiếu thu</td>
                  <td class="text-danger" *ngIf="!p.Thu_chi">Phiếu chi</td>
                  <td class="text-center" *ngIf="p.Huy_phieu">
                    <input type="checkbox" disabled checked />
                  </td>
                  <td class="text-center" *ngIf="!p.Huy_phieu">
                    <input type="checkbox" disabled />
                  </td>
                  <td style="text-align: center; white-space: nowrap;">
                    <button
                      *ngIf="p.Thu_chi == 1"
                      class="btn btn-sm btn-success"
                      style="margin-right: 3px;"
                      title="In biên lai thu"
                      (click)="Print(p)"
                    >
                      <i class="fas fa-print"></i>
                    </button>
                    <button
                      *ngIf="p.Thu_chi == 0"
                      class="btn btn-sm btn-success"
                      style="margin-right: 3px;"
                      title="In biên lai chi"
                      (click)="Print2(p)"
                    >
                      <i class="fas fa-print"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      title="Huỷ biên lai chi"
                      (click)="open(HuyPhieu, 'xs', 'HuyPhieu', p)"
                    >
                      <i class="fas fa-times-circle"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="dataTable.length == 0">
                  <td colspan="15" class="text-center">Không có kết quả</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem | number }} kết quả, đã lọc được
                {{ totalItemFilter | number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{
                        element
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)"
                      >Cuối</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div id="print-section" style="display: none;">
  <app-receipts [dt_print]="dt_print" [dt_detail]="dt_detail"></app-receipts>
</div>
<div id="print-section2" style="display: none;">
  <app-receipt-payment
    [dt_print]="dt_print"
    [dt_detail]="dt_detail"
  ></app-receipt-payment>
</div>
<ng-template #HuyPhieu let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <p
        style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        "
      >
        Bạn có muốn huỷ biên lai
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="updateStatus()"
        >
          Đồng ý
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ImportKhoanThuChi let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="container">
        <h5 style="text-align: center;">
          Import khoản nộp ký túc xá
        </h5>
        <a
          style="
            text-align: center;
            text-decoration-line: underline;
            color: red;
          "
          download="BienLaiThuImportExcel"
          target="_blank"
          href="/assets/BienLaiThuImportExcel.xlsx"
        >
          <i class="fas fa-download"></i> Tải File mẫu Excel tại đây
        </a>

        <div class="row" style="margin-top: 10px;">
          <div class="col-md-12 form-group">
            <input
              type="file"
              class="form-control"
              (change)="fileUpload($event)"
              placeholder="Upload file"
              accept=".xlsx"
              style="padding-bottom: 36px;"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <button
              type="button"
              style="float: right;"
              class="btn btn-md btn-success"
              (click)="ImportKhoanThuChiExcel()"
            >
              <i class="fas fa-save"></i> Lưu
            </button>
          </div>
          <div class="col-md-6 form-group">
            <button
              type="button"
              class="btn btn-md btn-danger"
              (click)="modal.dismiss('Cross click')"
            >
              <i class="far fa-times-circle"></i> Hủy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoomcategoryService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) {}

  getList(getnul: any, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UniDormAPI + 'RoomCateGory/ListAll', {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  CheckExist(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'RoomCateGory/CheckExist?ma_loai_phong=' +
          req,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'RoomCateGory/Update', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UniDormAPI + 'RoomCateGory/Add', req, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID_loai_phong: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI + 'RoomCateGory/Delete?ID=' + ID_loai_phong,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LoadExtensionsByIDLoaiPhong(ID_loai_phong: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'RoomCateGory/LoadExtensionsByIDLoaiPhong?ID=' +
          ID_loai_phong,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  LoadExtensionsNotExistByIDLoaiPhong(
    ID_loai_phong: any,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'RoomCateGory/LoadExtensionsNotExistByIDLoaiPhong?ID=' +
          ID_loai_phong,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  InsertExtensionsLoaiPhong(
    ID_loai_phong: any,
    ID_tien_ich: any,
    Don_gia: any,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'RoomCateGory/InsertExtensionsLoaiPhong?ID_loai_phong=' +
          ID_loai_phong +
          '&ID_tien_ich=' +
          ID_tien_ich +
          '&Don_gia=' +
          Don_gia,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  DeleteExtensionsLoaiPhong(
    ID_loai_phong: any,
    ID_tien_ich: any,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UniDormAPI +
          'RoomCateGory/DeleteExtensionsLoaiPhong?ID_loai_phong=' +
          ID_loai_phong +
          '&ID_tien_ich=' +
          ID_tien_ich,
        {
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
<div style="margin-left: 5%;">

    <table style="height: 83px;" width="900">
        <tbody>
            <tr>
                <td style="width: 450px;">
                    <p><strong>Đơn vị: Trường Đại học Sư phạm H&agrave; Nội</strong> </p>
                    <p> <strong>Mã QHNS: 1055502</strong></p>
                </td>
                <td style="width: 450px;">
                    <p style="text-align: center;"><strong>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</strong></p>
                    <p style="text-align: center;"><strong>Độc lập - Tự do - Hạnh phúc</strong></p>
                </td>
            </tr>
        </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="height: 141px;" width="900">
        <tbody>
            <tr>
                <td style="width: 250px;">
                    <p>&nbsp;</p>
                </td>
                <td style="width: 400px;">
                    <h3 style="text-align: center;"><strong>BIÊN LAI CHI TIỀN</strong></h3>
                    <p style="text-align: center;">Ng&agrave;y {{ date | date: 'dd' }} tháng {{date | date: 'MM'}} năm
                        {{date | date: 'yyyy'}}</p>
                </td>
                <td style="width: 250px;">
                    <p>&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="width: 250px;">
                    <p>&nbsp;</p>
                </td>
                <td style="width: 400px;">
                    <p><strong>&nbsp;</strong></p>
                </td>
                <td style="width: 250px;">
                    <p>Quyển số: {{dt_print.So_phieu_header }}</p>
                    <p>Số: {{dt_print.So_phieu}}</p>
                </td>
            </tr>
        </tbody>
    </table>
    <p>Họ v&agrave; t&ecirc;n người nhận tiền: {{dt_print.Ho_ten}}</p>
    <p>M&atilde; sinh vi&ecirc;n: {{dt_print.Ma_sv}} </p>
    <p>Phòng ở: {{dt_print.Ten_phong}}</p>
    <p>Tôi có nhận của Ban Quản lý Ký túc xá – Trường Đại học Sư phạm Hà Nội số tiền với nội dung như sau:</p>
    <table style="width: 900px;"  >
        <tr style="margin: 0px;padding: 0px;">
            <td style="width: 50px; text-align: center;border:1px black solid ">STT</td>
            <td style="width: 538px; text-align: center; border:1px black solid">T&ecirc;n khoản thu</td>
            <td style="width: 312px; text-align: right; border:1px black solid">Số tiền</td>
        </tr>
        <tr *ngFor="let p of dt_detail;index as i" style="margin: 0px;padding: 0px;">
            <td style="width: 50px; text-align: center;border:1px black solid">{{i+1}}</td>
            <td style="width: 538px;border:1px black solid ">{{p.Ten_thu_chi}}</td>
            <td style="width: 312px;text-align: right;border:1px black solid">{{p.So_tien_thuc_nop|number}}</td>
        </tr>
    </table>
    <p>&nbsp;</p>
    <p>Số tiền nhận: {{dt_print.So_tien|number}} ( {{dt_print.Ngoai_te}})</p>
    <p>(Viết bằng chữ): {{dt_print.So_tien_chu}}
    </p>
    <p>&nbsp;</p>
    <table style="height: 38px;" width="900">
        <tbody>
            <tr>
                <td style="width: 300px; text-align: center;">
                    <p><strong>BAN QUẢN LÝ KTX <br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
                </td>
                <td style="width: 300px; text-align: center;">
                    <p><strong>KẾ TOÁN <br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
                </td>
                <td style="width: 300px; text-align: center;">
                    <p><strong>NGƯỜI NHẬN TIỀN<br /> </strong><em>(K&yacute;, họ t&ecirc;n)</em></p>
                </td>
            </tr>
        </tbody>
    </table>
    <p>&nbsp;</p>
</div>
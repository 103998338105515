import { extend } from 'jquery';
import { Component, OnInit, Inject } from '@angular/core';
import { ListpeopledormService } from 'src/app/utils/services/listpeopledorm.service';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampusService } from 'src/app/utils/services/campus.service';
import { BuildingService } from 'src/app/utils/services/building.service';
import { RoomService } from 'src/app/utils/services/room.service';
import { GrantCardService } from 'src/app/utils/services/grantcard.service';
import { RegisterDormService } from 'src/app/utils/services/register-dorm.service';
import { SystemService } from 'src/app/utils/services/system.service';
import { QuyDinhKhoanThuService } from 'src/app/utils/services/quy-dinh-khoan-thu.service';
import { RoomcategoryService } from 'src/app/utils/services/room-category.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from 'src/app/utils/services/export.service';
import { StudentSortService } from 'src/app/utils/services/student-sort.service';
import { AccessHistoryService } from 'src/app/utils/services/accesshistory.service';
import { RoomCategoryRegisterService } from 'src/app/utils/services/RoomCategoryRegister.service';
import { DatePipe } from '@angular/common';
import { StatisticalMoneyService } from 'src/app/utils/services/statistical-money.service';
import { LichSuSuaChuaService } from 'src/app/utils/services/lichsusuachua.service';
import { DeviceService } from 'src/app/utils/services/device.service';
import { DeXuatSinhVienService } from 'src/app/utils/services/de-xuat-sinh-vien.service';
import { DiemSinhVienService } from 'src/app/utils/services/diem-sinh-vien.service';
import { ViPhamService } from 'src/app/utils/services/vi-pham.service';
import { HinhThucService } from 'src/app/utils/services/hinhthuc.service';
import { KhenThuongKyLuatService } from 'src/app/utils/services/khenthuongkyluat.service';
import { DiemPhongService } from 'src/app/utils/services/diem-phong.service';
import { UserToaNhaService } from 'src/app/utils/services/user-toa-nha.service';
import { DangKyQuaTrinhServiceService } from 'src/app/utils/services/dang-ky-qua-trinh-service.service';
import { DonGiaDienNuocService } from 'src/app/utils/services/don-gia-dien-nuoc.service';
import { QuanTriEmailService } from 'src/app/utils/services/quan-tri-email.service';
import { InvoinceService } from 'src/app/utils/services/invoince.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { NumberElectricService } from 'src/app/utils/services/number-electric.service';
import { ListallService } from 'src/app/utils/services/listall.service';
import { NumberWaterComponent } from '../number-water/number-water.component';
import { NumberWaterService } from 'src/app/utils/services/number-water.service';
import { WebSocketService } from 'src/app/utils/services/web-socket.service';
import { ThamSoHeThongService } from 'src/app/utils/services/tham-so-he-thong.service';
import { SoPhongService } from 'src/app/utils/services/so-phong.service';
import { TemplateEmailService } from 'src/app/utils/services/template-email.service';
import { DoiTuongChinhSachComponent } from '../doi-tuong-chinh-sach/doi-tuong-chinh-sach.component';
import { DoiTuongChinhSachService } from 'src/app/utils/services/doi-tuong-chinh-sach.service';
import { StatisticalComponent } from '../statistical/statistical.component';
import { StatisticalService } from 'src/app/utils/services/statistical.service';
import { BlackListService } from 'src/app/utils/services/black-list.service';
import { DocumentService } from 'src/app/utils/services/document.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  constructor(
    @Inject(AppConfig)
    public readonly appConfig: AppConfiguration,
    public StatisticalMoneyService: StatisticalMoneyService,
    public RoomCategoryRegisterService: RoomCategoryRegisterService,
    public ListAccessHistory: AccessHistoryService,
    public ListpeopledormService: ListpeopledormService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public CampusService: CampusService,
    public BuildingService: BuildingService,
    public RoomService: RoomService,
    public RegisterDormService: RegisterDormService,
    public GrantCardService: GrantCardService,
    public SystemService: SystemService,
    public RoomcategoryService: RoomcategoryService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public ListAllService: ListallService,
    public exportService: ExportService,
    public RoomCateGoryService: RoomcategoryService,
    public Service: StudentSortService,
    public datePipe: DatePipe,
    public QuyDinhKhoanThuService: QuyDinhKhoanThuService,
    public LichSuSuaChuaService: LichSuSuaChuaService,
    public DeviceService: DeviceService,
    public DeXuatSinhVienService: DeXuatSinhVienService,
    public DiemSinhVienService: DiemSinhVienService,
    public ViPhamService: ViPhamService,
    public HinhThucService: HinhThucService,
    public KhenThuongKyLuatService: KhenThuongKyLuatService,
    public DiemPhongService: DiemPhongService,
    public UserToaNhaService: UserToaNhaService,
    public DangKyQuaTrinhServiceService: DangKyQuaTrinhServiceService,
    public DonGiaDienNuocService: DonGiaDienNuocService,
    public route: ActivatedRoute,
    public QuanTriEmailService: QuanTriEmailService,
    public InvoinceService: InvoinceService,
    public NumberElectricService: NumberElectricService,
    public NumberWaterService: NumberWaterService,
    public WebSocketService: WebSocketService,
    public ThamSoHeThongService: ThamSoHeThongService,
    public DoiTuongChinhSachService: DoiTuongChinhSachService,
    public SoPhongService: SoPhongService,
    private fb: FormBuilder,
    private fromBuilder: FormBuilder,
    public TemplateEmailService: TemplateEmailService,
    public StatisticalService: StatisticalService,
    public BlackListService: BlackListService,
    public DocumentService: DocumentService
  ) {}
  public arrNumberPage: any = [];
  arrNumberPage_chil: any = [];
  numberPage: any;
  page: any = 1;
  pages: any = 10000;
  public pageSizes1: any = 10000;
  public pageSize: any = 10;
  public pageSizes: any = [10, 20, 50, 100, 200, 500, 1000, 'Tất cả'];
  public dataTable: any = [];
  public dataChecked: any = [];
  public dataCampus: any = [];
  public dataBuilding: any = [];
  public dataRoom: any = [];
  public dataQuyetDinh: any = [];
  public dataTypeRoom: any = [];
  dataBuildingChange: any;
  public com: common;
  public Token: any;
  public ID: any = '';
  public ID_tai_lieu_chia_se: any = '';
  public ID_toa_nha: any = '';
  public ID_co_so: any = '';
  public ID_phong: any = '';
  public ID_loai_phong: any = '';
  public getNull = false;
  public totalItem: number;
  totalItemFilter: any;
  isDisplay: boolean = true;
  submitted = false;
  closeResult: string;
  dataPopup: any = {};
  searchString: any = '';

  ngOnInit(): void {}
  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  buttonFilter() {
    this.isDisplay = !this.isDisplay;
  }
  changeCampus(id) {
    this.spinner.show();
    this.getListBuilding(id);
  }
  changeRoom(id_toanha) {
    this.getListRoom(id_toanha);
  }
  //get data campus
  getListCampus() {
    this.CampusService.getList(this.Token).subscribe((z) => {
      this.dataCampus = z.Data;
    });
  }
  //get data building
  getListBuilding(id) {
    this.ID_toa_nha = '';
    this.BuildingService.getList(this.Token).subscribe((z) => {
      this.dataBuilding = z.Data.filter((x) => {
        this.spinner.hide();
        return x.ID_CoSo == id;
      });
      if (this.dataBuilding.length > 0) {
        this.getListRoom(this.dataBuilding[0].ID_ToaNha);
      } else {
        this.ID_toa_nha = '';
      }
    });
  }
  //get data room
  getListRoom(id) {
    this.ID_phong = '';
    this.RoomService.GetAll(this.Token).subscribe((z) => {
      this.dataRoom = z.Data.filter((x) => {
        return x.ID_Toa_Nha == id;
      });
    });
  }
  //get data type room
  getListHinhThuc() {
    this.HinhThucService.GetAll(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.dataQuyetDinh = z.Data;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  getListTypeRoom() {
    this.RoomcategoryService.getList(this.getNull, this.Token).subscribe(
      (z) => {
        this.dataTypeRoom = z.Data;
      }
    );
  }
  changeCampusRep(id) {
    this.dataBuildingChange = this.dataBuildingChange.filter((x) => {
      return x.ID_CoSo == id;
    });
  }
  checkAllCheckBox(ev) {
    this.dataTable.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked() {
    if (this.dataTable != undefined)
      return this.dataTable.every((p) => p.checked);
  }
  test() {
    this.dataChecked = this.dataTable.filter((x) => x.checked == true);
  }
  createNumberPage(totalItem, pageSize) {
    let numberPage = 0;
    let arrNumberPage = [];
    if (totalItem % pageSize == 0) {
      numberPage = Math.floor(totalItem / pageSize);
    } else {
      numberPage = Math.floor(totalItem / pageSize) + 1;
    }
    for (var i = 1; i <= numberPage; i++) {
      arrNumberPage.push(i);
    }
    let arrNumberPage_chil = [];
    if (arrNumberPage.length > 4) {
      for (var i = 1; i <= 4; i++) {
        arrNumberPage_chil.push(i);
      }
    } else {
      arrNumberPage_chil = arrNumberPage;
    }
    return {
      numberPage: numberPage,
      arrNumberPage_chil: arrNumberPage_chil,
    };
  }
  handlePageChange(event) {
    if (event == 'pre') {
      this.page = this.page - 1;
    } else if (event == 'next') {
      this.page = this.page + 1;
    } else {
      this.page = event;
      this.arrNumberPage_chil = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage) {
          this.arrNumberPage_chil.push(i);
        }
      }
    }
  }
  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }

  getToken() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
  }
}
